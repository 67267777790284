import { Event } from 'react-big-calendar';
import { createStandardAction } from 'typesafe-actions';
import { UserCalendar } from '@lon/shared/requests';
import { CalendarsType } from '@lon/shared/types';
import {
  CalendarListType,
  CalendarSettingsDrawerType,
  NewPacingGuideType,
  PacingGuideDrawerType,
  PacingGuideListType,
  PacingGuidesDataType,
  PlansListType,
  ScopeElement,
  TeamsDataArray,
  UserSelectionType,
} from '@lon/shared/types';

export const setViewMode = createStandardAction(
  'PLANNER/SET_VIEW_MODE'
)<string>();

export const setCalendarList = createStandardAction(
  'PLANNER/SET_CALENDAR_LIST'
)<CalendarListType>();

export const setTemplateList = createStandardAction(
  'PLANNER/SET_TEMPLATE_LIST'
)<Array<UserCalendar>>();

export const setLoadingCalendarList = createStandardAction(
  'PLANNER/SET_LOADING_CALENDAR_LIST'
)<boolean>();

export const setDefaultModalOpened = createStandardAction(
  'CALENDARSETTINGS/SET_DEFAULT_OPENED'
)<boolean>();

export const setInitializeUserSelection = createStandardAction(
  'PLANNER/INIT_USER_SELECTION'
)<boolean>();

export const setUserSelection = createStandardAction(
  'PLANNER/SET_USER_SELECTION'
)<UserSelectionType>();

export const setSuccessUserSelection = createStandardAction(
  'PLANNER/SET_SUCCESS_USER_SELECTION'
)<UserSelectionType>();

export const setCalendarSettings = createStandardAction(
  'PLANNER/SET_CALENDAR_SETTINGS'
)<CalendarSettingsDrawerType>();

export const setCalendars = createStandardAction(
  'PLANNER/SET_USER_CALENDARS'
)<CalendarsType>();

export const setPacingGuideList = createStandardAction(
  'PLANNER/SET_PACING_GUIDE_LIST'
)<PacingGuideListType>();

export const setPacingGuideListLoading = createStandardAction(
  'PLANNER/SET_PACING_GUIDE_LIST_LOADING'
)<boolean>();

export const setSelectedPacingGuideDetails = createStandardAction(
  'PLANNER/SET_NEW_PACING_GUIDE'
)<NewPacingGuideType>();

export const setSelectedPacingGuide = createStandardAction(
  'PLANNER/SET_SELECTED_PACING_GUIDE'
)<string>();

export const setPacingGuideDrawer = createStandardAction(
  'PLANNER/SET_PACING_GUIDE_DRAWER'
)<PacingGuideDrawerType>();

export const setEventList = createStandardAction('PLANNER/SET_EVENT_LIST')<
  Event[]
>();

export const setInitializeDownloadPdf = createStandardAction(
  'PLANNER/SET_INITIALIZE_DOWNLOAD_PDF'
)<boolean>();

export const setPlansList = createStandardAction(
  'PLANNER/SET_PLANS_LIST'
)<PlansListType>();

export const setSelectedPlan = createStandardAction(
  'PLANNER/SET_SELECTED_PLAN'
)<string | undefined>();

export const setSelectedPlanScopeElements = createStandardAction(
  'PLANNER/SET_SELECTED_PLAN_SCOPES_ELEMENTS'
)<ScopeElement[]>();

export const setTeamsData = createStandardAction(
  'PLANNER/SET_TEAMS_DATA'
)<TeamsDataArray>();

export const resetPlannerData = createStandardAction(
  'PLANNER/RESET_PLANNER_DATA'
)();

export const setPacingGuidesData = createStandardAction(
  'PLANNER/SET_PACING_GUIDES_DATA'
)<PacingGuidesDataType>();

export const setUserRoleCode = createStandardAction(
  'PLANNER/SET_USER_ROLE_CODE'
)<string>();

export const setCurrentMonth = createStandardAction(
  'PLANNER/SET_CURRENT_MONTH'
)<string>();
