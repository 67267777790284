import styled from '@emotion/styled';
import { sizes } from './duck/constants';

export const Wrapper = styled.span<{
  size?: keyof typeof sizes;
  width?: string;
  height?: string;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  pointer-events: none;

  & svg {
    fill: currentColor;

    ${(props) => ({
      ...(props.size && {
        width: sizes[props.size],
        height: sizes[props.size],
      }),
      ...(props.width && { width: props.width }),
      ...(props.height && { height: props.height }),
    })}
  }
`;
