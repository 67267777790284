import { useMemo } from 'react';
import { useGetActiveContentGroupsWithSchoolsQuery } from '@lon/shared/requests';

const useSchoolContentGroups = (schoolIds?: string[]) => {
  const { data, loading } = useGetActiveContentGroupsWithSchoolsQuery();

  const contentGroups = useMemo(() => {
    if (!schoolIds) {
      return [];
    }

    return (
      data?.activeContentGroupsWithSchools
        ?.filter((item) => schoolIds?.includes(item.schoolId))
        ?.reduce((acc, item) => {
          return [...acc, ...(item.contentGroups || [])];
        }, [] as string[]) || []
    );
  }, [data, schoolIds]);

  return {
    contentGroups,
    loading,
  };
};

export default useSchoolContentGroups;
