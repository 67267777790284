import * as React from 'react';
import { ContentLoader } from '@lon/shared/components';


const Skeleton: React.FC = () => (
  <ContentLoader viewBox="0 0 900 690">
    <rect x="75" y="30" width="50%" height="30" />
    <rect x="75" y="80" width="50%" height="30" />
    <rect x="75" y="130" width="50%" height="30" />
    <rect x="75" y="180" width="50%" height="30" />
    <rect x="75" y="270" width="80%" height="45" />
    <rect x="75" y="335" width="80%" height="45" />
  </ContentLoader>
);

export default Skeleton;
