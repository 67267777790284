import { paginationConstants, paginationUtils } from '../../duck';
import {
  Button,
  Popover as ChakraPopover,
  Flex,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { onNumberInputKeyDown, onNumberInputPaste } from '@lon/shared/utils';
import { PopoverProps } from './duck';

const Popover = <Data extends object>({
  size,
  currentPage,
  table,
  pageSize,
}: PopoverProps<Data>) => {
  const firstFieldRef = useRef<HTMLSelectElement>(null);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [pageNumber, setPageNumber] = useState<string | number>('');

  const { t } = useTranslation();

  if (!table) {
    return null;
  }

  return (
    <ChakraPopover
      variant="secondary"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="top"
      initialFocusRef={firstFieldRef}
    >
      <PopoverAnchor>
        <HStack
          spacing="1.5"
          align="center"
          alignSelf="normal"
          px="4"
          borderX="1px solid"
          borderColor="secondary.50"
          key={table.getPageCount()}
        >
          <Flex
            whiteSpace="pre-wrap"
            justifyContent="center"
            alignItems="center"
          >
            <Text variant="s2" zIndex={1}>
              {t('pagination.page')}
            </Text>
            <Text variant="s1" whiteSpace="nowrap" px={1}>
              <Text as="span" className="notranslate">
                {table.getState().pagination.pageIndex + 1}
              </Text>
              &nbsp;
              <Text as="span">{t('pagination.of')}</Text>&nbsp;
              <Text as="span" className="notranslate">
                {table.getPageCount()}
              </Text>
            </Text>
          </Flex>
          <PopoverTrigger>
            <IconButton
              size={size}
              display="flex"
              variant="solidDark"
              aria-label={t('pagination.openPaginationSettings')}
              icon={<Icon name="ellipsis-outlined" />}
            />
          </PopoverTrigger>
        </HStack>
      </PopoverAnchor>
      <Portal>
        <PopoverContent paddingTop={1.5} paddingBottom={4} px={0}>
          <FocusLock persistentFocus={false}>
            <PopoverHeader
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid"
              borderColor="secondary.100"
              px={4}
              py={0}
              paddingBottom={2}
            >
              <Flex align="center">
                <Text variant="n3" whiteSpace="pre-wrap">
                  {t('pagination.page')}{' '}
                </Text>
                <Text variant="n1">
                  {currentPage} {t('pagination.of')} {table.getPageCount()}
                </Text>
              </Flex>
              <IconButton
                aria-label={t('pagination.closePaginationSettings')}
                variant="solidDark"
                size="md"
                onClick={onClose}
                icon={<Icon size="lg" name="close-outlined" />}
              />
            </PopoverHeader>
            <PopoverBody py={2} px={4} paddingTop={3}>
              <VStack spacing="2" mb="4" align="normal">
                <Text as="span" variant="n2">
                  {t('pagination.showRecords')}
                </Text>
                <Select
                  ref={firstFieldRef}
                  iconColor="primary.200"
                  borderColor="primary.200"
                  borderRadius="4px"
                  defaultValue={table.getState().pagination.pageSize}
                  onChange={(e) => pageSize(e)}
                >
                  {paginationConstants.ROW_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </VStack>
              <VStack spacing="2" align="normal">
                <Text variant="n2" whiteSpace="nowrap">
                  {t('pagination.goToPage')}
                </Text>
                <HStack spacing="4">
                  <NumberInput
                    size="md"
                    w="full"
                    min={1}
                    max={table.getPageCount()}
                    inputMode="numeric"
                    value={pageNumber}
                    onChange={setPageNumber}
                    onKeyDown={onNumberInputKeyDown}
                    onPaste={onNumberInputPaste}
                    onBlur={(event) => setPageNumber(event.target.value)}
                  >
                    <NumberInputField />
                  </NumberInput>
                  <Button
                    variant="solid"
                    name={t('pagination.go')}
                    onClick={() =>
                      paginationUtils.validatePageNumber({
                        pageNumber: pageNumber || currentPage,
                        max: table.getPageCount(),
                        successCallback: (page: number) => {
                          table?.setPageIndex(page - 1);
                          onClose();
                          setPageNumber('');
                        },
                      })
                    }
                  >
                    <Text textTransform="uppercase">{t('pagination.go')}</Text>
                  </Button>
                </HStack>
              </VStack>
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Portal>
    </ChakraPopover>
  );
};

export default Popover;
