import { ThemeEnum } from '@lon/shared/enums';
import { getThemeStyleValue } from '@lon/shared/utils';

export const getKbd = (theme: ThemeEnum) => ({
  baseStyle: {
    color: 'primary.800',
    fontSize: 'xs',
    py: 1.5,
    px: 2,
    '--kbd-bg': getThemeStyleValue('secondary.100', 'white')(theme),
    backgroundColor: getThemeStyleValue('secondary.100', 'white')(theme),
    borderColor: getThemeStyleValue('secondary.200', 'secondary.500')(theme),
  },
});
