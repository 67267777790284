import { ShareResults } from '@lon/shared/requests';

interface GetGradeProps {
  grade?: number;
  shareResults?: ShareResults;
  isGraded: boolean;
}

const getGrade = ({ grade = 0, shareResults, isGraded }: GetGradeProps) => {
  const canShareResults =
    shareResults === ShareResults.WithCorrectAnswers ||
    shareResults === ShareResults.WithoutCorrectAnswers;
  if (!isGraded || !canShareResults) {
    return '';
  }
  return grade;
};

export default getGrade;
