export const Badge = {
  defaultProps: {
    size: 'sm',
    variant: 'solid',
  },
  baseStyle: {
    px: 2,
    py: 0.5,
    borderRadius: '4px',
  },
  variants: {
    solid: {
      color: 'white',
      fontWeight: 600,
    },
    recordsInfo: {
      borderRadius: '10px',
      fontWeight: 400,
      textTransform: 'unset',
      border: '1px solid',
      borderColor: 'secondary.200',
      height: '22px',
      lineHeight: '1rem',
    },
    recordsError: {
      borderRadius: '10px',
      fontWeight: 400,
      height: '22px',
      lineHeight: '1rem',
      textTransform: 'unset',
      border: '1px solid',
      borderColor: 'danger.75',
      background: 'danger.extraLight',
      color: 'danger.text',
    },
    disabled: {
      pointerEvents: 'none',
      borderRadius: '10px',
      fontWeight: 400,
      height: '22px',
      lineHeight: '1rem',
      textTransform: 'unset',
      border: '1px solid',
      borderColor: 'secondary.200',
      background: 'secondary.50',
      color: 'secondary.400',
    },
    greenBadge: {
      background: 'green.light',
      border: '1px solid',
      borderColor: 'olive.100',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'olive.100',
    },
    redBadge: {
      background: 'danger.extraLight',
      border: '1px solid',
      borderColor: 'danger.800',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'danger.800',
    },
    lightBlueBorderedBadge: {
      border: '1px solid',
      borderColor: 'primary.700',
      background: 'calendar.blue.100',
      color: 'primary.700',
      fontWeight: 400,
      textTransform: 'unset',
    },
    yellowBadge: {
      background: 'warning.extraLight',
      border: '1px solid',
      borderColor: 'calendar.yellow.800',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'calendar.yellow.800',
    },
    violetBabge: {
      background: 'calendar.violet.extraLight',
      border: '1px solid',
      borderColor: 'calendar.violet.2',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'calendar.violet.2',
    },
    adminBadge: {
      background: 'info.100',
      border: '1px solid',
      borderColor: 'blue.500',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'blue.500',
    },
    studentBadge: {
      background: '#EFEDFF',
      border: '1px solid',
      borderColor: 'calendar.violet.1',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'calendar.violet.1',
    },
    readOnlyBadge: {
      background: 'calendar.violet.extraLight',
      border: '1px solid',
      borderColor: 'calendar.violet.2',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'calendar.violet.2',
    },
    outline: {
      background: 'transparent',
      boxShadow: 'none',
      border: '1px solid',
      borderColor: 'orange.dark',
      fontWeight: 400,
      textTransform: 'uppercase',
      color: 'orange.dark',
    },
    lightBlueBadge: {
      borderRadius: '12px',
      background: 'primary.30',
      color: 'primary.700',
      fontWeight: 400,
      textTransform: 'unset',
    },
    grayBadge: {
      border: '1px solid',
      borderColor: 'gray.700',
      background: 'gray.300',
      color: 'gray.700',
      fontWeight: 400,
    },
  },
  sizes: {
    sm: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
    xs: {
      fontSize: '0.625rem',
      lineHeight: '0.75rem',
    },
  },
};
