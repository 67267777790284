// import styles from '../../AssignmentGrades.module.css';
// import { ReopenAssignment } from '../reopen-assignment';
// import { ResetAssignment } from '../reset-assignment';
// import ReturnAssignment from '../return-assignment';
// import RevokeAssignmentResults from '../revoke-assignment-results';
// import ShareAssignmentResults from '../share-assignment-results';
import {
  Box, // Button,
  // Divider,
  Flex, // Menu,
  // MenuButton,
  // MenuItem,
  // MenuList,
  Switch,
  Tag,
  Text, // Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
// import { Icon } from '@lon/shared/components';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';

const GroupActions: React.FC<types.GroupActionsProps> = ({
  table,
  showAnswersColumn,
  setShowAnswersColumn,
  // isArchived,
}) => {
  const { t } = useTranslation();
  // const selectedStudentAssignments = table.getSelectedRowModel().flatRows.length
  //   ? table.getSelectedRowModel().flatRows.map((row) => row.original)
  //   : table.getRowModel().flatRows.map((row) => row.original);

  return (
    <Flex px={8} py={5} justify="space-between" align="center">
      <Box display="flex" gap={2} alignItems="center">
        <Text variant="h5" color={useThemeStyleValue('primary.800', 'white')}>
          {t('assignments.tableTitle')}
        </Text>
        <Tag variant="primary" fontWeight={600}>
          <Text
            variant="s2"
            color={useThemeStyleValue('primary.800', 'secondary.1000')}
            lineHeight="0.875rem"
          >
            {Object.keys(table.getRowModel().rowsById).length}
          </Text>
        </Tag>
      </Box>
      <Flex gap={4} align="center">
        <Flex align="center">
          <Text color={useThemeStyleValue('primary.800', 'white')}>
            {t('assignments.showAnswers')}
          </Text>
          <Switch
            size="sm"
            ml={3}
            isChecked={showAnswersColumn}
            onChange={() => {
              setShowAnswersColumn((prevState) => !prevState);
            }}
          />
        </Flex>
        {/*TODO enable when DA actions have been developed*/}
        {/*{!isArchived && (*/}
        {/*  <Menu*/}
        {/*    variant="primary"*/}
        {/*    eventListeners={{ scroll: false }}*/}
        {/*    placement="bottom-end"*/}
        {/*  >*/}
        {/*    {({ isOpen }) => (*/}
        {/*      <>*/}
        {/*        <MenuButton*/}
        {/*          as={Button}*/}
        {/*          rightIcon={*/}
        {/*            <Box*/}
        {/*              as={Icon}*/}
        {/*              name="chevron-down"*/}
        {/*              size="xl"*/}
        {/*              transform={`rotate(${isOpen ? -180 : 0}deg)`}*/}
        {/*            />*/}
        {/*          }*/}
        {/*          size="sm"*/}
        {/*        >*/}
        {/*          <Text variant="bs">{t('assignments.actions')}</Text>*/}
        {/*        </MenuButton>*/}
        {/*        <MenuList data-focus-visible-disabled>*/}
        {/*          <MenuItem*/}
        {/*            //TODO enable when DA actions have been developed*/}
        {/*            isDisabled={true}*/}
        {/*            p={0}*/}
        {/*          >*/}
        {/*            <Tooltip*/}
        {/*              variant="dark"*/}
        {/*              label={t('assignments.viewOrGrade')}*/}
        {/*            >*/}
        {/*              <Flex flex="1" gap={3} py="2" px="6">*/}
        {/*                <Icon name="eyeDdlOutlined" className={styles.icon} />*/}
        {/*                <Text variant="s2">{t('assignments.viewOrGrade')}</Text>*/}
        {/*              </Flex>*/}
        {/*            </Tooltip>*/}
        {/*          </MenuItem>*/}
        {/*          <MenuItem*/}
        {/*            px={0}*/}
        {/*            cursor="initial"*/}
        {/*            _hover={{*/}
        {/*              backgroundColor: 'initial',*/}
        {/*            }}*/}
        {/*            _focusVisible={{*/}
        {/*              outline: 'none',*/}
        {/*            }}*/}
        {/*            _focus={{*/}
        {/*              backgroundColor: 'initial',*/}
        {/*            }}*/}
        {/*            closeOnSelect={false}*/}
        {/*          >*/}
        {/*            <Divider my={1.5} />*/}
        {/*          </MenuItem>*/}
        {/*          <ReturnAssignment*/}
        {/*            isGroupActions*/}
        {/*            studentsData={selectedStudentAssignments}*/}
        {/*          />*/}
        {/*          <ResetAssignment*/}
        {/*            isGroupActions*/}
        {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
        {/*          />*/}
        {/*          <ReopenAssignment*/}
        {/*            isGroupActions*/}
        {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
        {/*          />*/}
        {/*          <ShareAssignmentResults*/}
        {/*            isGroupActions*/}
        {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
        {/*          />*/}
        {/*          <RevokeAssignmentResults*/}
        {/*            isGroupActions*/}
        {/*            selectedStudentAssignments={selectedStudentAssignments}*/}
        {/*          />*/}
        {/*        </MenuList>*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </Menu>*/}
        {/*)}*/}
      </Flex>
    </Flex>
  );
};

export default GroupActions;
