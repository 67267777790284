import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { types } from './duck';

const FormSwitcher: React.FC<types.SwitchProps> = ({
  children,
  name,
  label,
  defaultValue,
  containerProps,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange: onChangeValue, name: fieldName, value },
  } = useController({ name, control, defaultValue });

  return (
    <FormControl display="flex" alignItems="center" gap={2} {...containerProps}>
      <Switch
        size="md"
        isChecked={value}
        id={fieldName}
        onChange={(event) => {
          onChangeValue(event.target.checked);
        }}
        mb={0}
        {...rest}
      />
      <FormLabel
        htmlFor={fieldName}
        m={0}
        mb={0}
        fontSize={rest.fontSize}
        lineHeight={rest.lineHeight}
        fontWeight={rest.fontWeight}
        color="primary.800"
      >
        {label}
      </FormLabel>
    </FormControl>
  );
};

export default FormSwitcher;
