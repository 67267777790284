const assignRefs =
  <T extends HTMLElement = HTMLElement>(
    ...refs: (React.RefObject<T> | ((node: T | null) => void) | undefined)[]
  ) =>
  (ref: T | null) => {
    refs.forEach((resolvableRef) => {
      if (typeof resolvableRef === 'function') {
        resolvableRef(ref);
      } else if (resolvableRef) {
        /* eslint-disable */
        // @ts-ignore
        (resolvableRef as { current: T }).current = ref;
        /* eslint-enable */
      }
    });
  };

export default assignRefs;
