import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';

const ArchieveModal: React.FC<any> = ({
  loading,
  isOpen,
  onClose,
  onSubmit,
  teamName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalContent
        data-focus-visible-disabled
        maxW={'600px'}
        borderWidth={{ base: 0, md: '15px' }}
        borderColor="secondary.50"
        borderRadius={{ base: 0, md: '20px' }}
      >
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={6}
          py={3}
          maxH="unset"
        >
          <Text variant="h4" fontSize="1.25rem">
            {t('userSettings.teams.modal.title')}
          </Text>
          <ModalCloseButton position="unset" w="50px" h="50px" />
        </ModalHeader>
        <ModalBody p={0}>
          <Box py={2} px={8} boxShadow="0px 2px 7px 0px #2B36461A">
            <Text variant="h6">{teamName}</Text>
          </Box>
          <Flex m={8} direction="column">
            <Flex
              justifyContent="space-between"
              p={6}
              mb={4}
              backgroundColor="warning.extraLight"
            >
              <Box mr={6}>
                <Icon
                  color="yellow.800"
                  width="50px"
                  height="50px"
                  name="archive-icon"
                />
              </Box>
              <Text variant="s3" color="#97470E">
                {t('userSettings.teams.modal.descriptionPartOne')}
              </Text>
            </Flex>
            <Box p={6} backgroundColor="info.100">
              <Text variant="s3">
                {t('userSettings.teams.modal.descriptionPartTwo')}
              </Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid transparent"
          borderColor="gray.200"
          justifyContent="space-between"
        >
          <Button
            colorScheme="blue"
            mr={3}
            onClick={onClose}
            leftIcon={<Icon name="arr-left-outlined" />}
          >
            {t('userSettings.teams.modal.cancel')}
          </Button>
          <Button
            variant="solid"
            isLoading={loading}
            type="submit"
            onClick={onSubmit}
            leftIcon={<Icon name="archived" size="lg" />}
          >
            {t('userSettings.teams.modal.archiveButton')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ArchieveModal;
