import { useEffect, useState } from 'react';

export function useFetchJsonData(opts: {
  url: string;
  options?: any;
  skip?: boolean;
}): {
  data?: Record<string, string>;
  loading: boolean;
  called: boolean;
  error: string | null;
};

export function useFetchJsonData<Data>(opts: {
  url: string;
  options?: any;
  skip?: boolean;
}): {
  data?: Data;
  loading: boolean;
  called: boolean;
  error: string | null;
};

export function useFetchJsonData<Data>({
  url,
  options,
  skip = false,
}: {
  url: string;
  options?: any;
  skip?: boolean;
}) {
  const [data, setData] = useState<Data>();
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!url || skip) {
      return;
    }
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          url.startsWith('https') ? url : `https://${url}`,
          options
        );
        const json = await response.json();
        setData(json);
        setError(null);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
        setCalled(true);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, called, error };
}
