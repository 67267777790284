import * as yup from 'yup';
import { EMAIL_VALIDATION_REGEXP } from '@lon/shared/constants';
import { getValidationMessages } from './getValidationMessages';


declare module 'yup' {
  interface StringSchema {
    noSpaces(errorMessage?: string): StringSchema;
    strongEmail(): StringSchema;
    toNull(): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(
  yup.string,
  'noSpaces',
  function (this: yup.StringSchema, errorMessage) {
    return this.test(
      'no-spaces',
      errorMessage || 'Field cannot contain spaces',
      function (value) {
        if (!value) return true; // Skip validation if value is null or undefined
        return /^\S+$/u.test(value); // Return true if value does not contain any whitespace character
      }
    );
  }
);
yup.addMethod<yup.StringSchema>(
  yup.string,
  'strongEmail',
  function (this: yup.StringSchema) {
    return this.test('strong-email', getValidationMessages().email, (value) => {
      if (!value) {
        return true;
      }
      return EMAIL_VALIDATION_REGEXP.test(value);
    });
  }
);
yup.addMethod<yup.StringSchema>(yup.string, 'toNull', function () {
  return this.transform((value) => (value === '' ? null : value));
});

export { yup };
