import { Flex } from '@chakra-ui/react';
import React from 'react';
import { UnauthorizedFooter, UnauthorizedHeader } from '@lon/shared/components';
import { BaseProps } from '@lon/shared/types';

const UnauthorizedPageWrapper: React.FC<BaseProps> = ({ children }) => {
  return (
    <Flex
      direction="column"
      minH="full"
      minW="var(--page-width-min)"
      bgColor="white"
      position="relative"
      height="100vh"
    >
      <UnauthorizedHeader />

      <Flex
        py={{ md: 6, base: 0 }}
        px={{ base: 0, md: 8 }}
        flexGrow={1}
        bgColor="blue.75"
      >
        {children}
      </Flex>

      <UnauthorizedFooter />
    </Flex>
  );
};

export default UnauthorizedPageWrapper;
