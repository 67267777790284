import { createContext } from 'react';
import {
  GetAssignmentListQuery,
  GetAssignmentQuery,
} from '@lon/shared/requests';

export const AssignmentContext = createContext<{
  assignmentData?: GetAssignmentQuery | GetAssignmentListQuery;
  assignmentLoading: boolean;
}>({
  assignmentLoading: false,
});
