export enum TabKey {
  generalSettings = 'generalSettings',
  gradingPeriods = 'gradingPeriods',
  holidays = 'holidays',
}

type SchoolDay = {
  eventId?: string | null;
  name?: string | null;
};

type Entry = {
  eventId?: string | null;
  name?: string | null;
  dateRange: {
    startDate?: string | null;
    endDate?: string | null;
  };
};

export type FormValues = {
  name?: string;
  firstDayOfSchool: SchoolDay[];
  lastDayOfSchool: SchoolDay[];
  gradingPeriods: Entry[];
  holidays: Entry[];
};
