import { utils } from '../../duck';
import AnswersSwitch from '../answers-switch';
import {
  Box,
  Button,
  Modal as ChakraModal,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { sanitize } from 'dompurify';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormTextarea, Icon, Message } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';

interface ModalProps {
  withNotes?: boolean;
  onClose: () => void;
  isOpen: boolean;
  isMultiple?: boolean;
  studentName: string;
  onCompleted?: () => void;
  loading: boolean;
  onSubmit: (
    callback: () => void
  ) => (values: { notes: string; includeCorrectAnswers: boolean }) => void;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  isOpen,
  onSubmit,
  isMultiple,
  loading,
  studentName,
  withNotes = true,
}) => {
  const { t } = useTranslation();

  const { validationSchema, defaultValues } = utils.getValidation();

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onSubmit',
  });

  const onCloseModal = () => {
    formProviderProps.reset();
    onClose();
  };

  return (
    <ChakraModal
      variant="rounded"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onCloseModal}
      onEsc={onCloseModal}
      onOverlayClick={onCloseModal}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <FormProvider {...formProviderProps}>
        <Box
          as="form"
          className="emotion-css-cache userInputForm"
          noValidate
          onSubmit={formProviderProps.handleSubmit(onSubmit(onCloseModal))}
        >
          <ModalContent data-focus-visible-disabled>
            <ModalHeader>
              <Text fontWeight={700}>{t('assignments.shareResults')}</Text>
              <ModalCloseButton
                w="44px"
                h="44px"
                sx={{
                  svg: {
                    width: 6,
                    height: 6,
                  },
                }}
              />
            </ModalHeader>
            <Box boxShadow="0px 2px 7px 0px #2B36461A" py={2} px={8}>
              <Text variant="n1">
                {isMultiple
                  ? t('assignments.toMultipleStudents')
                  : sanitize(
                      t('assignments.toSingleStudent', {
                        name: studentName,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
              </Text>
            </Box>
            <ModalBody py={8} css={sxLightScrollBar}>
              <Message
                mb="6"
                variant="info"
                icon={<Icon name="share-results-eye" />}
                css={{
                  '.chakra-alert__desc': { display: 'block', padding: 0 },
                }}
                message={
                  <Text variant="s3" whiteSpace="pre-wrap">
                    {isMultiple
                      ? t('assignments.shareResultsMessageMultiple')
                      : t('assignments.shareResultsMessageSingle')}
                  </Text>
                }
              />
              <AnswersSwitch
                name="includeCorrectAnswers"
                size="sm"
                label={
                  <Text as="span" variant="s2">
                    {t('assignments.shareIncludeCorrectAnswers')}
                  </Text>
                }
                wrapperProps={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: '1.5',
                }}
              />
              {withNotes && (
                <>
                  <FormLabel htmlFor="student-notes" mr={0}>
                    <Text as="span" variant="n1">
                      {t('assignments.studentNoteTitle')}
                    </Text>
                  </FormLabel>
                  {isMultiple && (
                    <Text variant="s3" mb={3} color="primary.300">
                      {t('assignments.multipleReturnNotice')}
                    </Text>
                  )}
                  <FormTextarea
                    placeholder={t(
                      `assignments.${
                        isMultiple
                          ? 'studentNoteGroupPlaceholder'
                          : 'studentNotePlaceholder'
                      }`
                    )}
                    id="student-notes"
                    name="notes"
                    borderColor="primary.200"
                    height="148px"
                  />
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <HStack justify="space-between" width="100%">
                <Button
                  variant="outline"
                  mr={3}
                  onClick={onCloseModal}
                  isDisabled={loading}
                >
                  <Text as="span">{t('assignments.cancelButton')}</Text>
                </Button>
                <Button
                  type="submit"
                  leftIcon={<Icon name="check-simple-outlined" size="lg" />}
                  isLoading={loading}
                  variant="solid"
                >
                  <Text as="span">{t('assignments.shareResultsButton')}</Text>
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Box>
      </FormProvider>
    </ChakraModal>
  );
};

export default Modal;
