import update from 'immutability-helper';
import { ActionType, createReducer } from 'typesafe-actions';
import { FiltersReducer } from '@lon/shared/types';
import * as actions from './actions';

type FiltersActions = ActionType<typeof actions>;

const initialState: FiltersReducer = {
  scopes: {
    search: '',
  },
  streaming: {
    search: '',
  },
  scopesWidget: {
    search: '',
  },
};

const reducer = createReducer<FiltersReducer, FiltersActions>(
  initialState
).handleAction(actions.setFilters, (state, { payload }) => {
  return update(state, { $merge: payload });
});

export default reducer;
