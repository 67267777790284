import { lazyWithRetry } from '@lon/shared/utils';

const StandardReports = lazyWithRetry(
  () => import('./standard-reports'),
  'StandardReports'
);

const DistrictAssessmentsReports = lazyWithRetry(
  () => import('./district-assessment-reports'),
  'DistrictAssessmentsReports'
);

const DistrictAssessmentDetails = lazyWithRetry(
  () => import('./district-assessment-details'),
  'DistrictAssessmentsDetails'
);

export {
  StandardReports,
  DistrictAssessmentsReports,
  DistrictAssessmentDetails,
};
