import qs from 'qs';
import prepareParse from '../prepare-parse';

export type GetParams = (
  query: string,
  prepare?: (props: any) => any,
  parseOptions?: qs.IParseOptions
) => any;

const getParams: GetParams = (query, prepare = prepareParse, parseOptions) =>
  prepare(qs.parse(query, { arrayLimit: 10000, depth: 10, ...parseOptions }));

export default getParams;
