import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RequestParams, getParams, getQuery } from '@lon/api';
import {
  useQueryParamsHooks,
  useQueryParamsOperations,
  useQueryParamsTypes,
} from './duck';
import Params from './duck/params';

export const useQueryParams = <TParams = RequestParams>(
  options?: useQueryParamsTypes.UseQueryParamsOptions<TParams>
): [
  useQueryParamsTypes.Params<TParams>,
  useQueryParamsTypes.SetParams<TParams>
] => {
  const navigate = useNavigate();
  const location = useLocation();
  const windowSearch = location.search;
  const queryUtils = {
    getParams,
    getQuery,
    updateLocation: navigate,
    ...options,
  };

  useQueryParamsHooks.usePopstateListener(!!queryUtils.updateLocation);

  const params = React.useMemo(() => {
    const currentQuery = windowSearch.startsWith('?')
      ? windowSearch.slice(1)
      : windowSearch;
    const newParams = queryUtils.getParams(currentQuery);

    return Params(newParams, queryUtils.getQuery);
  }, [windowSearch]);

  const setParams: useQueryParamsTypes.SetParams<TParams> = (
    newParams,
    updateLocationOptions
  ) => {
    const newQuery = queryUtils.getQuery(newParams);
    const newPath = `${location.pathname}?${newQuery}`;

    if (queryUtils.updateLocation) {
      queryUtils.updateLocation(newPath, updateLocationOptions);
    } else {
      useQueryParamsOperations.replacePathname(newPath);
    }
  };
  return [params, setParams];
};

export default useQueryParams;
