import {
  Badge,
  Popover as ChakraPopover,
  Flex,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { types } from './duck';

const Popover: FC<types.PopoverProps> = ({
  body,
  variant = 'primary',
  trigger,
  hasCloseButton,
  title,
  badgeText,
  hasHeader = true,
  placement = 'bottom',
  onClose,
}) => {
  return (
    <ChakraPopover variant={variant} placement={placement} onClose={onClose}>
      {trigger}
      <PopoverContent w={{ base: '250px', md: '400px' }}>
        {hasCloseButton && (
          <PopoverCloseButton top={6} right={6} w="9px" h="9px" />
        )}
        {hasHeader && (
          <PopoverHeader border={0}>
            <Flex flexDir="row" alignItems="center">
              {badgeText && (
                <Badge
                  variant="outline"
                  mr={4}
                  fontSize="0.75rem"
                  lineHeight="1.25rem"
                  fontWeight={400}
                >
                  {badgeText}
                </Badge>
              )}
              {title && <Text variant="s1">{title}</Text>}
            </Flex>
          </PopoverHeader>
        )}
        <PopoverBody>
          <Text variant="s2">{body}</Text>
        </PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
};

export default Popover;
