import {
  Box,
  BoxProps,
  Button,
  HStack,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import update from 'immutability-helper';
import { uniqueId } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Icon } from '@lon/shared/components';
import { useGetTheme, useQueryParams } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { setFilters } from '@lon/suit/configs';

interface AppliedFilters {
  filter: 'scopes' | 'streaming';
  containerProps?: BoxProps;
}

const AppliedFilters: React.FC<AppliedFilters> = ({
  filter,
  containerProps,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useGetTheme();

  const [{ pagination, ...params }, setParams] = useQueryParams();

  const initialPagination = pagination && {
    itemsPerPage: pagination.itemsPerPage,
    page: 1,
  };

  const removeFilter = (value: string, name: string): void => {
    const newFilters = update(params, {
      filters: {
        $merge: {
          [name]: params?.filters?.[name].filter(
            (filter: string) => filter !== value
          ),
        },
      },
      pagination: {
        ...(initialPagination ? { $set: initialPagination } : {}),
      },
    });

    setParams(newFilters);
    dispatch(setFilters({ [filter]: { filters: newFilters.filters } }));
  };

  const filters = params?.filters;

  const labelId = useMemo(() => uniqueId('filters_applied_'), []);

  if (!filters) {
    return null;
  }

  const appliedFilters = Object.entries(filters);

  return (
    <Box
      backgroundColor={getThemeStyleValue('white', 'secondary.1000')(theme)}
      borderRadius="6px"
      p={4}
      boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
      role="group"
      aria-labelledby={labelId}
      border={getThemeStyleValue('none', '1px solid white')(theme)}
      {...containerProps}
    >
      <Text
        as="h2"
        variant="h6"
        color={getThemeStyleValue('primary.800', 'white')(theme)}
        id={labelId}
      >
        {t('scopes.filtersApplied')}
      </Text>
      <HStack
        marginTop={4}
        flexWrap="wrap"
        as={UnorderedList}
        listStyleType="none"
        marginLeft="0px"
      >
        {appliedFilters.map(([name, values]) => {
          return (values as string[]).map((value) => (
            <ListItem key={value + name} display="flex">
              <Button
                variant="solid"
                borderRadius="12px"
                size="xs"
                rightIcon={<Icon name="close-circle-filled" size="sm" />}
                padding="0.125rem 0.625rem 0.25rem"
                margin="0 !important"
                fontSize="0.875rem"
                backgroundColor={getThemeStyleValue(
                  'blue.500',
                  'teal.500'
                )(theme)}
                _focusVisible={{
                  boxShadow: getThemeStyleValue(
                    '0 0 0 4px var(--chakra-colors-blue-600)',
                    'focus.teal'
                  )(theme),
                  border: getThemeStyleValue(
                    'none',
                    '1px solid var(--chakra-colors-secondary-1000)'
                  )(theme),
                }}
                _hover={{
                  backgroundColor: getThemeStyleValue(
                    'primary.600',
                    'teal.600'
                  )(theme),
                }}
                onClick={() => removeFilter(value, name)}
              >
                <Text as="span" isTruncated>
                  {value}
                </Text>
              </Button>
            </ListItem>
          ));
        })}
      </HStack>
    </Box>
  );
};

export default AppliedFilters;
