import {
  AspectRatio,
  CardBody,
  CardFooter,
  Card as ChakraCard,
  Link as ChakraLink,
  Image,
  Text,
} from '@chakra-ui/react';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { StudentGradeContext } from '@lon/shared/contexts';import { types } from './duck';
import styles from './StudentNotebookCard.module.css';

const StudentNotebookCard: React.FC<types.CardProps> = ({
  title,
  imageSrc,
  isActiveCard = false,
  link,
  chakraCardProps,
}) => {
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const [hasError, setHasError] = useState(false);

  return (
    <ChakraCard
      borderRadius={isStudentBelowK3 ? '10px' : 0}
      height="100%"
      objectFit="contain"
      className={clsx(styles.card, {
        [styles.active]: isActiveCard,
        [styles.isBelowK3]: isStudentBelowK3,
      })}
      as="li"
      {...chakraCardProps}
    >
      <CardBody p="0">
        <AspectRatio ratio={isStudentBelowK3 ? 11 / 14 : 26 / 33}>
          <Image
            minW="full"
            minH="full"
            alt=""
            borderTopRadius={isStudentBelowK3 ? '10px' : 0}
            backgroundColor="white"
            src={
              hasError
                ? 'suit/assets/vertical-plug.svg'
                : imageSrc || 'suit/assets/vertical-plug.svg'
            }
            onError={() => {
              if (!hasError) {
                setHasError(true);
              }
            }}
          />
        </AspectRatio>
      </CardBody>
      <CardFooter
        px={isStudentBelowK3 ? 4 : 2}
        py={2}
        flexGrow={1}
        flexDirection="column"
      >
        <ChakraLink
          as={Link}
          className={styles.link}
          to={link}
          _before={{
            content: `''`,
            position: 'absolute',
            left: 0,
            top: 0,
            width: 'full',
            height: 'full',
            zIndex: 1,
            borderRadius: isStudentBelowK3 ? '10px' : 0,
          }}
        >
          {typeof title === 'string' ? (
            <Text variant="n1">{title}</Text>
          ) : (
            title
          )}
        </ChakraLink>
      </CardFooter>
    </ChakraCard>
  );
};

export default StudentNotebookCard;
