import clsx from 'clsx';
import React from 'react';
import { Link as NativeLink } from 'react-router-dom';
import { types } from './duck';
import styles from './Link.module.css';

export const Link = React.forwardRef<HTMLAnchorElement, types.LinkProps>(
  (
    { className, children, withoutStyles = false, disabled = false, ...props },
    ref
  ) => {
    return React.createElement(
      disabled ? 'span' : React.Fragment,
      // @ts-ignore
      { ...(disabled ? { className: styles.isDisabled } : {}) },
      <NativeLink
        ref={ref}
        {...props}
        {...(disabled ? { tabIndex: -1 } : {})}
        className={clsx(className, {
          [styles.wrapper]: !disabled && !withoutStyles,
        })}
      >
        {children}
      </NativeLink>
    );
  }
);

export default Link;
