export const NODE_NAMES = {
  BUTTON: 'BUTTON',
};

export const OBSERVED_BITS = {
  ITEMS: /*        */ 0b00000000001,
  ACTIVE_INDEX: /* */ 0b00000000010,
  HOVERABLE: /*    */ 0b00000000100,
  ORIENTATION: /*  */ 0b00000001000,
  RETURN_FOCUS: /* */ 0b00000010000,
  BORDERED: /*     */ 0b00000100000,
  GROUP: /*        */ 0b00001000000,
  POPUP: /*        */ 0b00010000000,
  DEPTH: /*        */ 0b00100000000,
  INDENT: /*       */ 0b01000000000,
  SIZE: /*         */ 0b10000000000,
};

/* eslint-disable no-bitwise */
export const BIT_MASK = {
  INDEX: OBSERVED_BITS.ITEMS | OBSERVED_BITS.ACTIVE_INDEX,
  CONFIG:
    OBSERVED_BITS.HOVERABLE |
    OBSERVED_BITS.ORIENTATION |
    OBSERVED_BITS.BORDERED |
    OBSERVED_BITS.POPUP |
    OBSERVED_BITS.DEPTH |
    OBSERVED_BITS.INDENT |
    OBSERVED_BITS.SIZE,
  CALLBACK: OBSERVED_BITS.RETURN_FOCUS,
  ORIENTATION: OBSERVED_BITS.ORIENTATION,
  HOVER: OBSERVED_BITS.HOVERABLE,
  BORDERED: OBSERVED_BITS.BORDERED,
  GROUP: OBSERVED_BITS.GROUP,
  INDENT: OBSERVED_BITS.INDENT,
};
/* eslint-enable no-bitwise */
