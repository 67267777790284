import { Button, Flex } from '@chakra-ui/react';
import { useQueryParams } from '@lon/shared/hooks';
import { ShortcutButtonsProps } from './duck';

const ShortcutButtons: React.FC<ShortcutButtonsProps> = ({
  originalActivityItems,
  isLearnosityLoading,
}) => {
  const [params, setParams] = useQueryParams();
  const currentItemIndex = Number(params.questionNumber) - 1 || 0;

  return (
    <Flex flexWrap="wrap" justifyContent="center" gap="2">
      {originalActivityItems.map((item, index) => (
        <Button
          key={item.id}
          isDisabled={isLearnosityLoading}
          variant={index === currentItemIndex ? 'solid' : 'outline'}
          borderRadius="50%"
          maxWidth="44px"
          onClick={() => {
            setParams({
              questionNumber: index + 1,
            });
          }}
        >
          {index + 1}
        </Button>
      ))}
    </Flex>
  );
};

export default ShortcutButtons;
