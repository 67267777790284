import { useStudentAssignmentDetailsContext } from '../../../../../../duck';
import Grading from '../../../grading';
import GradingHeader from '../../../grading-header';
import { constants } from '../../duck';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import {
  ResponseFeedbackInput,
  useSaveGradingSessionMutation,
} from '@lon/shared/requests';
import { Answer } from '@lon/shared/types';
import { clearQueryCache } from '@lon/shared/utils';

const Form: React.FC<{
  isLti?: boolean;
  isDa?: boolean;
  items: Answer[];
  defaultValues: Record<string, string>;
  assignmentId?: string;
  backPath?: string;
}> = ({ isLti, items, defaultValues }) => {
  const { studentId, sessionId } = useParams();
  const [saveGrades] = useSaveGradingSessionMutation();
  const [savingGrades, setSavingGrades] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const formProviderProps = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const location = useLocation();

  const { isGoingBack, isGoingBackRef, onSaveCallback } =
    useStudentAssignmentDetailsContext();

  const onSubmit = (e: Record<string, string>) => {
    setSavingGrades(true);
    const responses = Object.keys(e)
      .filter((key) => key.startsWith('score_'))
      .map((key) => {
        const responseId = key.replace('score_', '');

        const isNumeric = !isNaN(parseFloat(e[key]));

        if (!isNumeric) {
          return undefined;
        }

        const score = (Number(e[key]) * constants.maxScore) / 100;

        return {
          response_id: responseId,
          score: String(score),
          max_score: String(constants.maxScore),
        };
      })
      .filter(Boolean);
    const responseFeedbacks = Object.keys(e)
      .filter((key) => key.startsWith('feedback_'))
      .map((key) => {
        const responseId = key.replace('feedback_', '');
        const feedback = e[key];
        if (!feedback) {
          return undefined;
        }
        return {
          responseId: responseId,
          feedback,
        };
      })
      .filter((item): item is ResponseFeedbackInput => item !== undefined);
    saveGrades({
      variables: {
        sessionsParams: [
          {
            sessionId: sessionId as string,
            userId: studentId as string,
            responses: responses,
            responseFeedbacks,
          },
        ],
      },
      onCompleted: (response) => {
        const isUpdated = response?.saveGradingSession?.success;
        if (isUpdated) {
          toast({
            title: location.state?.studentName
              ? t('manualGrading.successSaveWithStudentName', {
                  studentName: location.state?.studentName,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              : t('manualGrading.successSave'),
            variant: 'success-light',
          });
          clearQueryCache(['assignment', 'teacherStudentAssignments']);
          setSavingGrades(false);
          onSaveCallback(true);
        } else {
          setSavingGrades(false);
          toast({
            title: t('systemMessages.038'),
            variant: 'error-light',
            isClosable: true,
          });
          onSaveCallback();
        }
      },
      onError: () => {
        setSavingGrades(false);
        toast({
          title: t('systemMessages.038'),
          variant: 'error-light',
          isClosable: true,
        });
        onSaveCallback();
      },
    });
  };

  React.useEffect(() => {
    if (isGoingBack) {
      onSubmit(formProviderProps.getValues());
    }
  }, [isGoingBack]);

  useEffect(() => {
    // dispatch resize so that learnosity understands
    // that the initial layout changed
    window.dispatchEvent(new Event('resize'));

    return () => {
      if (!isGoingBackRef.current) {
        onSubmit(formProviderProps.getValues());
      }
    };
  }, []);

  return (
    <FormProvider {...formProviderProps}>
      <Box as="form" onSubmit={formProviderProps.handleSubmit(onSubmit)}>
        {items.map((item) => {
          const container = document
            .getElementById(item.responseId)
            ?.closest('.learnosity-question-container') as Element;

          if (!container) {
            return null;
          }

          return createPortal(
            <Grading
              responseId={item.responseId}
              isAutogradable={item.automarkable}
              loading={savingGrades || isGoingBack}
            >
              <GradingHeader response={item} />
            </Grading>,
            container
          ) as ReactElement;
        })}
        {isLti && (
          <Button
            variant="solid"
            type="submit"
            isDisabled={!formProviderProps.formState.isDirty || savingGrades}
            isLoading={savingGrades}
          >
            <Text as="span">{t('studentAssignmentDetails.save')}</Text>
          </Button>
        )}
      </Box>
    </FormProvider>
  );
};

export default Form;
