import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { hideAnswers, showAnswers } from '@lon/suit/constants';

const ShowAnswers: React.FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const isAnswersShown = queryParams.answers !== hideAnswers;
  const title = isAnswersShown
    ? t('toolbar.hideAnswers')
    : t('toolbar.showAnswers');

  const setShowAnswers = (answers = 'ON', replace = true) =>
    navigate('?' + getQuery({ ...queryParams, answers }), { replace });

  return (
    <MenuItem {...props}>
      <>
        <Tooltip variant="dark" label={title} aria-hidden={true}>
          <IconButton
            size="md"
            variant="ghost"
            className={actionsStyles.action}
            title={title}
            aria-label={title}
            onClick={() => {
              setShowAnswers(isAnswersShown ? hideAnswers : showAnswers, false);
            }}
          >
            <Icon
              name={isAnswersShown ? 'eye-outlined' : 'eye-crossed-outlined'}
              size="lg"
            />
          </IconButton>
        </Tooltip>
      </>
    </MenuItem>
  );
};

export default ShowAnswers;
