import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { infoCircle } from '@lon/shared/icons';

const NoStudents: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box py={6} px={8}>
      <Flex
        w="100%"
        alignItems="center"
        flexDir="column"
        px={8}
        py={14}
        gap={6}
        mt={4}
        color="calendar.yellow.d"
        borderRadius="6px"
        backgroundColor="calendar.yellow.extraLight"
        borderWidth="1px"
        borderColor="calendar.yellow.light"
        textAlign="center"
      >
        <Icon as={infoCircle} boxSize="2rem" />
        <Text variant="n1">{t('addStudents.messageTitle')}</Text>
        <Text variant="n3">{t('addStudents.messageText3')}</Text>
      </Flex>
    </Box>
  );
};

export default NoStudents;
