import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { FieldValues } from 'react-hook-form';
import { array, boolean, date, object, string } from 'yup';
import {
  GetScopePlanQuery,
  PacingGuideTypeEnum,
  PacingGuidesQuery,
  ScopePlanApproachEnum,
  ScopePlanTypeEnum,
} from '@lon/shared/requests';
import { PlansListArrayType } from '@lon/suit/types';
import { displayLabel, pacingGuideArray } from './types';

export const validationSchema = object({
  adaptive: boolean().optional().default(null),
  classSize: string()
    .optional()
    .nullable()
    .default(null)
    .max(
      5,
      t('validationMessages.maxLength', {
        fieldName: t('plans.adaptiveTime'),
        length: 5,
      })
    ),
  classTime: string()
    .optional()
    .nullable()
    .default(null)
    .max(
      5,
      t('validationMessages.maxLength', {
        fieldName: t('plans.adaptiveClassSize'),
        length: 5,
      })
    ),
  curriculumArea: object({
    label: string().optional(),
    value: string().optional(),
  })
    .nullable()
    .default(null),
  experience: string()
    .optional()
    .nullable()
    .default(null)
    .max(
      3,
      t('validationMessages.maxLength', {
        fieldName: t('plans.adaptiveExperience'),
        length: 3,
      })
    ),
  grade: object({
    label: string().optional(),
    value: string().optional(),
  })
    .nullable()
    .default(null),
  pacingGuideInd: object({
    value: string().optional(),
  })
    .optional()
    .nullable()
    .default(null),
  pacingGuideTeam: object({
    value: string().optional(),
  })
    .optional()
    .nullable()
    .default(null),
  pacingGuideName: string().optional().default(''),
  pacingGuideStartDate: date().optional().nullable().default(null),
  selectedCalendar: array().of(object().nullable().optional().default(null)),
  teamId: object({
    label: string().optional(),
    value: string().optional(),
  })
    .nullable()
    .default(null)
    .optional(),
  scopePlanTemplateId: object({
    label: string().optional().default(''),
    value: string().required().default(''),
  })
    .nullable()
    .default(null)
    .optional(),
});

export const getValidation = (props?: {
  grade: string;
  curriculumArea: string;
}) => {
  if (props) {
    return {
      validationSchema,
      defaultValues: validationSchema.cast({
        curriculumArea: {
          label: props?.curriculumArea,
          value: props?.curriculumArea,
        },
        grade: {
          label: props?.grade,
          value: props?.grade,
        },
      }),
    };
  }
  return {
    validationSchema,
    defaultValues: validationSchema.cast({}),
  };
};

export const validateScopePlanNoPace = (form: FieldValues, team: boolean) => {
  return (
    (!team && !isEmpty(form.curriculumArea) && !isEmpty(form.grade)) ||
    (team &&
      !isEmpty(form.curriculumArea) &&
      !isEmpty(form.grade) &&
      !isEmpty(form.teamId))
  );
};

export const validateScopePlanWithPace = (form: FieldValues, team: boolean) => {
  return (
    (team &&
      !isEmpty(form.pacingGuideTeam) &&
      form.pacingGuideTeam.value !== '') ||
    (!team && !isEmpty(form.pacingGuideInd) && form.pacingGuideInd.value !== '')
  );
};

export const getUniquePacing = (pace: pacingArray, scope: scopeArray) => {
  if (isEmpty(pace)) {
    return [];
  } else if (!isEmpty(pace) && isEmpty(scope)) {
    return pace;
  } else {
    return pace!.filter(
      (pac) =>
        scope!.findIndex(
          (sco) =>
            pac?.pacingGuideId === sco?.pacingGuideId &&
            sco!.curriculumArea === pac!.curriculumArea &&
            sco!.grade === pac!.grade &&
            JSON.stringify(sco!.scopePlanType) ===
              JSON.stringify(pac!.pacingGuideType)
        ) === -1
    );
  }
};

export const getAllowedPacing = (
  grade: labelType,
  curr: labelType,
  pacing: pacingGuideArray,
  type: string,
  selectedTeamId: labelType,
  isEdit?: boolean
) => {
  if (isEdit && grade !== null && curr !== null) {
    return (
      pacing?.reduce<displayLabel[]>((pacingGuides, pace) => {
        if (
          pace?.grade === grade.value &&
          pace?.curriculumArea === curr.value &&
          pace?.pacingGuideType === type &&
          (type === PacingGuideTypeEnum.Individual ||
            (type === PacingGuideTypeEnum.Team &&
              pace?.userId === selectedTeamId?.value))
        ) {
          pacingGuides?.push({
            label: pace?.name || '',
            value: pace?.pacingGuideId || '',
          });
        }
        return pacingGuides;
      }, []) || []
    );
  }

  if (!isEmpty(pacing) && grade !== null && curr !== null) {
    return pacing
      ?.filter(
        (pace) =>
          pace?.grade === grade.value &&
          pace?.curriculumArea === curr.value &&
          pace?.pacingGuideType === type &&
          (type === PacingGuideTypeEnum.Individual ||
            (type === PacingGuideTypeEnum.Team &&
              pace?.userId === selectedTeamId?.value))
      )
      .map((pace) => {
        if (pace?.name && pace.pacingGuideId) {
          return {
            label: pace.name || '',
            value: pace.pacingGuideId || '',
          };
        }
        return {
          label: '',
          value: '',
        };
      });
  } else if (curr !== null) {
    return pacing
      ?.filter(
        (pace) =>
          pace?.curriculumArea === curr?.value && pace?.pacingGuideType === type
      )
      .map((pace) => {
        if (pace?.name && pace.pacingGuideId) {
          return {
            label: pace.name || '',
            value: pace.pacingGuideId || '',
          };
        }
        return {
          label: '',
          value: '',
        };
      });
  } else if (grade !== null) {
    return pacing
      ?.filter(
        (pace) => pace?.grade === grade?.value && pace?.pacingGuideType === type
      )
      .map((pace) => {
        if (pace?.name && pace.pacingGuideId) {
          return {
            label: pace.name || '',
            value: pace.pacingGuideId || '',
          };
        }
        return {
          label: '',
          value: '',
        };
      });
  } else if (selectedTeamId !== null) {
    return pacing
      ?.filter(
        (pace) =>
          pace?.userId === selectedTeamId?.value &&
          pace?.pacingGuideType === type
      )
      .map((pace) => {
        if (pace?.name && pace.pacingGuideId) {
          return {
            label: pace.name || '',
            value: pace.pacingGuideId || '',
          };
        }
        return {
          label: '',
          value: '',
        };
      });
  } else if (!isEmpty(pacing)) {
    return pacing
      ?.filter((pace) => pace?.pacingGuideType === type)
      .map((pace) => {
        if (pace?.name && pace.pacingGuideId) {
          return {
            label: pace.name || '',
            value: pace.pacingGuideId || '',
          };
        }
        return {
          label: '',
          value: '',
        };
      });
  }
  return [];
};

export const getResponseFormatted = (
  scopePlanResp?: GetScopePlanQuery['scopePlan'],
  pacingGuides?: PacingGuidesQuery['pacingGuides'],
  teamsList?: displayLabel[]
) => {
  const isTeamPlan = scopePlanResp?.scopePlanType === ScopePlanTypeEnum.Team;
  const pacingFormKey = isTeamPlan ? 'pacingGuideTeam' : 'pacingGuideInd';
  const selectedTeam = isTeamPlan
    ? teamsList?.find((team) => team.value === scopePlanResp?.userId) || null
    : null;
  const selectedPacingGuide = scopePlanResp?.pacingGuideId
    ? pacingGuides?.collection?.find(
        (pace) => pace?.pacingGuideId === scopePlanResp?.pacingGuideId
      )
    : null;

  return {
    curriculumArea: getOptionForDropDown(scopePlanResp?.curriculumArea),
    grade: getOptionForDropDown(scopePlanResp?.grade),
    ...(isTeamPlan ? { teamId: selectedTeam } : {}),
    ...(selectedPacingGuide
      ? {
          [pacingFormKey]: {
            label: selectedPacingGuide?.name,
            value: selectedPacingGuide?.pacingGuideId,
          },
        }
      : {}),
    ...(scopePlanResp?.approachType === ScopePlanApproachEnum.Adaptive
      ? {
          adaptive: true,
          classSize: scopePlanResp?.classSize?.toString(),
          classTime: scopePlanResp?.classTime?.toString(),
          teacherExp: scopePlanResp?.teacherExp?.toString(),
        }
      : {}),
  };
};

export const getGradesToDisplay = (
  grades: Array<string>,
  scopePlansList: PlansListArrayType,
  curriculumArea?: string | null,
  team?: string
) => {
  const gradesForDisable = scopePlansList?.reduce<Array<string>>(
    (acc, curr) =>
      curriculumArea &&
      curr?.grade &&
      curr?.curriculumArea === curriculumArea &&
      curr?.scopePlanType === team
        ? [...acc, curr?.grade]
        : acc,
    []
  );
  const gradeOptions = grades.map((grade) => ({
    label: grade,
    value: grade,
    isDisabled: gradesForDisable?.includes(grade) || false,
  }));
  return gradeOptions;
};

export const getCurrToDisplay = (
  curriculumAreas: Array<string>,
  scopePlansList: PlansListArrayType,
  grade?: string | null,
  team?: string
) => {
  const currForDisable = scopePlansList?.reduce<Array<string>>(
    (acc, curr) =>
      grade &&
      curr?.curriculumArea &&
      curr?.grade === grade &&
      curr?.scopePlanType === team
        ? [...acc, curr?.curriculumArea]
        : acc,
    []
  );
  const currOptions = curriculumAreas.map((curr) => ({
    label: curr,
    value: curr,
    isDisabled: currForDisable?.includes(curr) || false,
  }));
  return currOptions;
};

export const mapStringToDisplay = (input: string[] | null | undefined) => {
  if (isEmpty(input)) {
    return [];
  }
  return input!.map((ele) => ({
    label: ele,
    value: ele,
    isDisabled: false,
  }));
};

export const getOptionForDropDown = (
  value?: string | null,
  label?: string | null
) => {
  if (value) {
    return {
      label: label || value,
      value: value,
    };
  }
  return null;
};
type labelType = { value: string | null | undefined } | null;

export type pacingArray =
  | Array<{
      __typename?: 'PacingGuide';
      id: string;
      _id: string;
      name?: string | null;
      pacingGuideId?: string | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
      curriculumArea?: string | null;
      grade?: string | null;
      editable?: boolean | null;
      creator?: string | null;
      schoolId?: string | null;
      scopes?: any | null;
      version?: number | null;
    } | null>
  | undefined
  | null;

type scopeArray =
  | Array<{
      __typename?: 'ScopePlan';
      _id: string;
      id: string;
      name?: string | null;
      grade?: string | null;
      curriculumArea?: string | null;
      editable?: boolean | null;
      isSelected?: boolean | null;
      version?: number | null;
      scopePlanType?: ScopePlanTypeEnum | null;
      approachType?: ScopePlanApproachEnum | null;
      pacingGuideId?: string | null;
    } | null>
  | undefined
  | null;
