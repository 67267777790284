export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const ESC = 27;
export const END = 35;
export const HOME = 36;
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_RIGHT = 39;
export const ARROW_DOWN = 40;
