import {
  Box,
  Flex,
  IconButton,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { AiOutlineEdit } from '@react-icons/all-files/ai/AiOutlineEdit';
import { RiArrowLeftSLine } from '@react-icons/all-files/ri/RiArrowLeftSLine';
import { RiArrowRightSLine } from '@react-icons/all-files/ri/RiArrowRightSLine';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ClassContext } from '@lon/suit/contexts';
import { constants, types } from './duck';
import classes from './SubgroupTabs.module.css';

const SubgroupTabs: FC<types.TabsProps> = ({
  tabs,
  onChange,
  activeTabIdx,
  openEditSubgroup,
}) => {
  const navigate = useNavigate();
  const { isMainTeacher } = useContext(ClassContext);
  const { subgroupId } = useParams();
  const { t } = useTranslation();
  const [showControls, setShowControls] = useState(false);
  const [positionLeft, setPositionLeft] = useState(0);
  const containerRef = useRef<HTMLDivElement>();
  const [tabIndex, setTabIndex] = useState(0);
  const scrollDifference =
    (containerRef?.current?.scrollWidth || 0) -
    (containerRef?.current?.offsetWidth || 0);

  const scroll = (scrollOffset: number) => {
    if (
      containerRef?.current?.scrollLeft ||
      containerRef?.current?.scrollLeft === 0
    ) {
      containerRef.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    if (tabs?.length && !tabs.find((tab) => tab.id === subgroupId)) {
      navigate(tabs[0]?.id);
    }
  }, [tabs?.length]);

  useEffect(() => {
    setTimeout(() => {
      const currentTabIdx = tabs?.findIndex((tab) => tab.id === subgroupId);
      if (currentTabIdx && containerRef.current) {
        const childElement = containerRef?.current?.children[
          currentTabIdx
        ] as HTMLElement;
        const childPosition = childElement?.offsetLeft - constants.LeftPosition;
        const childWidth = childElement?.offsetWidth;
        const containerWidth = containerRef?.current.offsetWidth;

        containerRef.current.scrollLeft +=
          childPosition + childWidth - containerWidth + constants.Paddings;
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      if (
        containerRef?.current?.offsetWidth >= containerRef?.current?.scrollWidth
      ) {
        setShowControls(false);
      } else {
        setShowControls(true);
      }
    }
  }, [containerRef.current?.offsetWidth]);

  const tabChange = (index: number) => {
    onChange(`${tabs?.[index]?.id}`);
    setTabIndex(index);

    if (containerRef?.current) {
      const childElement = containerRef?.current?.children[
        index
      ] as HTMLElement;

      const childPosition = childElement?.offsetLeft - constants.LeftPosition;
      const childWidth = childElement.offsetWidth;
      const containerScroll = containerRef?.current.scrollLeft;
      const containerWidth = containerRef?.current.offsetWidth;

      if (index > tabIndex) {
        if (childPosition + childWidth - containerScroll - containerWidth > 0) {
          containerRef.current.scrollLeft +=
            childPosition +
            childWidth -
            containerScroll -
            containerWidth +
            constants.Paddings;
        }
      }

      if (index < tabIndex) {
        if (childPosition - containerScroll < 0) {
          containerRef.current.scrollLeft = childPosition;
        }
      }
    }
  };

  return tabs?.length ? (
    <Flex position="relative">
      {showControls && (
        <IconButton
          mt={1}
          variant="outline"
          fontSize="lg"
          aria-label={t('subgroups.scrollLeft')}
          icon={<RiArrowLeftSLine />}
          position="absolute"
          onClick={() => {
            scroll(-300);
          }}
          isDisabled={positionLeft === 0}
        />
      )}
      <Tabs
        variant="buttons"
        maxW="100%"
        pl={showControls ? '58px' : 0}
        pr={showControls ? '114px' : '58px'}
        index={tabs?.findIndex((tab) => tab.id === activeTabIdx)}
        onChange={tabChange}
        as="nav"
      >
        <TabList
          ref={containerRef}
          scrollBehavior="smooth"
          overflowX={showControls ? 'scroll' : 'auto'}
          onScroll={(e) => {
            let scrollLeft = (e.target as any).scrollLeft;

            if (!Number.isInteger(scrollLeft)) {
              scrollLeft = Math.round(scrollLeft);
            }

            setPositionLeft(scrollLeft);
          }}
          className={classes.scrollBar}
          as="ul"
        >
          {tabs?.map((tab) => (
            <Tab
              key={tab.id}
              title={tab.title}
              as="li"
              cursor="pointer"
              maxW="300px"
            >
              <Text isTruncated variant="bm">
                {tab.title}
              </Text>
            </Tab>
          ))}
        </TabList>
      </Tabs>
      <Box position="absolute" right={0} mt={1}>
        {showControls && (
          <IconButton
            isDisabled={
              positionLeft === scrollDifference ||
              positionLeft === scrollDifference - 1 ||
              positionLeft === scrollDifference + 1
            }
            variant="outline"
            icon={<RiArrowRightSLine />}
            aria-label={t('subgroups.scrollRight')}
            fontSize="lg"
            onClick={() => {
              scroll(300);
            }}
          />
        )}
        <IconButton
          isDisabled={!isMainTeacher}
          ml={3}
          variant="solid"
          icon={<AiOutlineEdit />}
          aria-label={t('subgroups.manageBtn')}
          fontSize="lg"
          onClick={openEditSubgroup}
        />
      </Box>
    </Flex>
  ) : null;
};

export default SubgroupTabs;
