import { Box } from '@chakra-ui/react';
import React from 'react';
import { ContentLoader } from '@lon/shared/components';

const Skeleton: React.FC = () => (
  <Box data-testid="card-skeleton" w="full">
    <ContentLoader viewBox="0 0 1100 788">
      <rect x="0" y="0" width="150" height="40" />
      <rect x="200" y="0" width="150" height="40" />
      <rect x="400" y="0" width="150" height="40" />
      <rect x="600" y="0" width="150" height="40" />
      <rect x="800" y="0" width="150" height="40" />
      <rect x="1000" y="0" width="150" height="40" />
      <rect x="0" y="60" width="150" height="40" />
      <rect x="200" y="60" width="150" height="40" />
      <rect x="400" y="60" width="150" height="40" />
      <rect x="600" y="60" width="150" height="40" />
      <rect x="800" y="60" width="150" height="40" />
      <rect x="1000" y="60" width="150" height="40" />
      <rect x="0" y="120" width="150" height="40" />
      <rect x="200" y="120" width="150" height="40" />
      <rect x="400" y="120" width="150" height="40" />
      <rect x="600" y="120" width="150" height="40" />
      <rect x="800" y="120" width="150" height="40" />
      <rect x="1000" y="120" width="150" height="40" />
      <rect x="0" y="180" width="150" height="40" />
      <rect x="200" y="180" width="150" height="40" />
      <rect x="400" y="180" width="150" height="40" />
      <rect x="600" y="180" width="150" height="40" />
      <rect x="800" y="180" width="150" height="40" />
      <rect x="1000" y="180" width="150" height="40" />
    </ContentLoader>
  </Box>
);

export default Skeleton;
