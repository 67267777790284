import { FormTextarea } from '../../../../form-units/form-textarea';
import FormSelect from '../../../../form-units/select';
import TextInput from '../../../../form-units/text-input';
import { useToast } from '../../../../use-toast';
import { FEEDBACK_URL, REASON_OPTIONS } from '../duck';
import { Box, Flex } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@lon/shared/hooks';

const Form: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const { t } = useTranslation();
  const [{ user }] = useAuth();
  const formContext = useFormContext();
  const toast = useToast();
  const [reasons, setReasons] = React.useState<{
    issues: any;
    product_types: string[];
  }>({ product_types: [], issues: {} });

  const getReasons = () => {
    try {
      axios.get(FEEDBACK_URL).then((data) => {
        setReasons(data?.data);
      });
    } catch (e) {
      toast({
        title: t('footer.feedbackForm.error'),
        variant: 'error-light',
      });
    }
  };

  const productOptions = reasons?.product_types?.map((v: string) => ({
    value: v,
    label: v,
  }));

  React.useEffect(() => {
    formContext.reset({
      username: user?.username,
      email: user?.email,
    });
    getReasons();
  }, []);

  return (
    <Box>
      <Box
        // borderBottomWidth="1px"
        borderColor="secondary.200"
        pt={4}
        pb={6}
        px={{ base: 10, md: '150px' }}
      >
        {isAdmin && (
          <FormSelect
            isRequired
            label={t('footer.feedbackForm.product')}
            placeholder={t('footer.feedbackForm.productPlaceholder')}
            returnObject={false}
            name="product"
            options={productOptions}
          />
        )}
        <Box mt={4}>
          <FormSelect
            isRequired
            label={t('footer.feedbackForm.reason')}
            placeholder={t('footer.feedbackForm.reasonPlaceholder')}
            returnObject={false}
            name="reason"
            optionsWithDescription
            options={REASON_OPTIONS(isAdmin, reasons)}
          />
        </Box>
        <Flex my={4}>
          <TextInput
            label={t('footer.feedbackForm.name')}
            name="username"
            isDisabled
            mr={6}
            placeholder={t('footer.feedbackForm.namePlaceholder')}
            isRequired
          />
          <TextInput
            label={t('footer.feedbackForm.email')}
            isDisabled
            name="email"
            placeholder={t('footer.feedbackForm.emailPlaceholder')}
            isRequired
          />
        </Flex>
        <FormTextarea
          resize="none"
          title=""
          isRequired
          minH="180px"
          name="comments"
          placeholder={t('footer.feedbackForm.commentsPlaceholder')}
          label={t('footer.feedbackForm.comments')}
        />
      </Box>
      {/* TODO uncomment when buttons get some logic */}
      {/* <Box px={{ base: 10, md: '150px' }} pt={4}>
        <Text variant="n1" mb={2}>
          {t('footer.feedbackForm.addAttachments')}
        </Text>
        <Text variant="s3" mb={2} color="primary.400">
          {t('footer.feedbackForm.fileSize')}
        </Text>
        <Flex justify="space-between">
          <Button size="sm" leftIcon={<Icon name="upload-outline" />}>
            {t('footer.feedbackForm.upload')}
          </Button>
          <Flex>
            <Button
              size="sm"
              mr={4}
              leftIcon={<Icon name="video-camera-outlined" />}
            >
              {t('footer.feedbackForm.capture')}
            </Button>
            <Button size="sm" leftIcon={<Icon name="camera-outlined" />}>
              {t('footer.feedbackForm.screenshot')}
            </Button>
          </Flex>
        </Flex>
      </Box> */}
      {/* <Box px={{ base: 10, md: '150px' }} pt={4}>
        <Box>
          <Progress variant="blue" value={70} />
        </Box>
      </Box> */}
    </Box>
  );
};

export default Form;
