import { FieldValues } from 'react-hook-form';
import {
  GetPlanTemplatesListQuery,
  PacingGuideTypeEnum,
} from '@lon/shared/requests';

export type PlanDrawerType = {
  drawerOpen: boolean;
  handleDrawerClose: () => void;
  isEdit: boolean;
  dataAutoFill?: boolean;
  viewOnly: boolean;
  scopePlanId?: string;
  isCreatePlanFromScopes?: boolean;
  addElementToPlan?: (newScopePlanId?: string) => void;
  defaultDisabledTab?: string | null;
  addElementProps?: {
    grade: string;
    curriculumArea: string;
  };
};

export type PlanExistingType = {
  formProps: FieldValues;
  pacing: pacingGuideArray;
  viewOnly?: boolean;
  team?: boolean;
};

export type TeamIndividualPlanType = {
  formProps: FieldValues;
  pacing: pacingGuideArray;
  team: TeamIndividualEnum;
  selectedTab?: boolean;
  viewOnly?: boolean;
  isEdit: boolean;
  partialViewOnly?: boolean;
  pacingLoading?: boolean;
  addElementProps?: {
    grade: string;
    curriculumArea: string;
  };
  suggestedPlansTemplates?: GetPlanTemplatesListQuery['scopePlanTemplates'];
};

export enum TeamIndividualEnum {
  'Individual',
  'Team',
}

export type displayLabel = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

export type pacingGuideArray =
  | Array<{
      __typename?: 'PacingGuide';
      id: string;
      _id: string;
      name?: string | null;
      pacingGuideId?: string | null;
      pacingGuideType?: PacingGuideTypeEnum | null;
      curriculumArea?: string | null;
      grade?: string | null;
      editable?: boolean | null;
      creator?: string | null;
      schoolId?: string | null;
      userId?: string | null;
      scopes?: any | null;
      version?: number | null;
    } | null>
  | null
  | undefined;
