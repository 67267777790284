import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Comment: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <MenuItem {...props}>
      <IconButton
        size="md"
        variant="solidDark"
        className={actionsStyles.action}
        title={t('toolbar.comment')}
        aria-label={t('toolbar.comment')}
      >
        <Icon name="comment-outlined" size="lg" />
      </IconButton>
    </MenuItem>
  );
};

export default Comment;
