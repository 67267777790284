import getFilter from '../get-filter';
import getSort from '../get-sort';
import { PreparedRequestParams, RequestParams } from '../types';

const prepareStringify = (params: RequestParams): PreparedRequestParams => {
  const { filter = {}, sort = {}, ...restParams } = params;
  const preparedParams: PreparedRequestParams = restParams;

  if (Object.keys(filter).length) {
    preparedParams.filter = getFilter(filter);
  }
  if (Object.keys(sort).length) {
    preparedParams.sort = getSort(sort);
  }

  return preparedParams;
};

export default prepareStringify;
