import * as React from 'react';
import { Context } from './types';
import { OBSERVED_BITS } from './constants';

const calculateChangedBits = (
  prevContext: Context,
  nextContext: Context
): number => {
  const prevState = prevContext.state;
  const nextState = nextContext.state;

  let result = 0b00000000000;

  /* eslint-disable no-bitwise */
  if (prevState.items !== nextState.items) {
    result |= OBSERVED_BITS.ITEMS;
  }

  if (prevState.activeIndex !== nextState.activeIndex) {
    result |= OBSERVED_BITS.ACTIVE_INDEX;
  }

  if (prevState.hoverable !== nextState.hoverable) {
    result |= OBSERVED_BITS.HOVERABLE;
  }

  if (prevState.orientation !== nextState.orientation) {
    result |= OBSERVED_BITS.ORIENTATION;
  }

  if (prevState.returnFocus !== nextState.returnFocus) {
    result |= OBSERVED_BITS.RETURN_FOCUS;
  }

  if (prevState.bordered !== nextState.bordered) {
    result |= OBSERVED_BITS.BORDERED;
  }

  if (prevState.group !== nextState.group) {
    result |= OBSERVED_BITS.GROUP;
  }

  if (prevState.popup !== nextState.popup) {
    result |= OBSERVED_BITS.POPUP;
  }

  if (prevState.depth !== nextState.depth) {
    result |= OBSERVED_BITS.DEPTH;
  }

  if (prevState.indent !== nextState.indent) {
    result |= OBSERVED_BITS.INDENT;
  }

  if (prevState.size !== nextState.size) {
    result |= OBSERVED_BITS.SIZE;
  }
  /* eslint-enable no-bitwise */

  return result;
};

// Top level menu should not use any context.
const MenuContext = React.createContext<Context>(
  null as unknown as Context,
  //@ts-ignore
  calculateChangedBits
);

export default MenuContext;
