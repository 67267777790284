import { ThemeEnum } from '@lon/shared/enums';
import { Mark } from '@lon/shared/types';
import { getThemeStyleValue } from '@lon/shared/utils';

export const getAnswerCellSettings = (mark: Mark, currentTheme: ThemeEnum) => {
  const result = (mark.score / mark.max_score) * 100;

  if (result > 89) {
    return {
      backgroundColor: getThemeStyleValue(
        'green.light',
        'teal.500'
      )(currentTheme),
      borderColor: getThemeStyleValue(
        'green.extraLight',
        'teal.500'
      )(currentTheme),
      color: getThemeStyleValue('olive.100', 'secondary.1000')(currentTheme),
    };
  } else if (result > 79) {
    return {
      backgroundColor: getThemeStyleValue(
        'warning.extraLight',
        'yellow.hc'
      )(currentTheme),
      borderColor: getThemeStyleValue(
        'warning.light',
        'yellow.hc'
      )(currentTheme),
      color: getThemeStyleValue('warning.text', 'secondary.1000')(currentTheme),
    };
  } else if (result > 69) {
    return {
      backgroundColor: getThemeStyleValue(
        'orange.extraLight',
        'orange.600'
      )(currentTheme),
      borderColor: getThemeStyleValue('orange.300', 'orange.600')(currentTheme),
      color: getThemeStyleValue('orange.900', 'secondary.1000')(currentTheme),
    };
  }
  return {
    backgroundColor: getThemeStyleValue(
      'danger.extraLight',
      'fuchsia.400'
    )(currentTheme),
    borderColor: getThemeStyleValue('danger.1', 'fuchsia.400')(currentTheme),
    color: getThemeStyleValue('danger.text', 'secondary.1000')(currentTheme),
  };
};
