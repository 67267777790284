export const resizeImage = ({
  src,
  height = 600,
  width = 800,
}: {
  src: string;
  height: number;
  width: number;
}): string => {
  if (src.includes('.com/images/')) {
    if (src.includes('_optimized.')) {
      return `${src.replace('_optimized.', `_${width}.`)}`;
    } else {
      const pos = src.lastIndexOf('.');
      return `${src.substring(0, pos)}_${width}${src.substring(pos)}`;
    }
  } else {
    return `${src}/resize?width=${width}&height=${height}`;
  }
};
