import axios from 'axios';
import { t } from 'i18next';
import { object, string } from 'yup';
import {
  NETWORK_ERROR_CODES,
  profileTypeByApplication,
} from '@lon/shared/constants';
import { ApplicationEnum, ProfileTypeEnum } from '@lon/shared/enums';
import { getLastDistrictBySignature, hashValue } from '@lon/shared/utils';
import { ErrorResponse, SubmitLogin, SuccessResponse } from './types';
import { LOGIN_ERRORS } from './constants';

export const getValidation = (isMFARequired: boolean) => {
  const validationSchema = object({
    username: string()
      .trim()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'Username',
        })
      )
      .default(''),
    password: string()
      .trim()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'Password',
        })
      )
      .default(''),
    ...(isMFARequired && {
      mfaCode: string()
        .trim()
        .required(
          t('validationMessages.requiredField', {
            fieldName: 'Verification Code',
          })
        ),
    }),
  });

  return {
    validationSchema,
    defaultValues: validationSchema.cast({}),
  };
};

export const handleLoginSubmit: SubmitLogin = (
  successCallback,
  errorCallback,
  setMfaRequired,
  isMfaRequired,
  setAuth,
  currentApplication
) => {
  return async ({ username, password, mfaCode }) => {
    const impersonate = localStorage.getItem('impersonate');
    localStorage.removeItem('impersonate');
    const district = getLastDistrictBySignature();

    try {
      const {
        data: { data },
      } = await axios.post<SuccessResponse>(
        `${process.env.NX_REACT_APP_LOGIN_API}${isMfaRequired ? '/mfa' : ''}`,
        new URLSearchParams({
          username,
          password,
          district: district?.value,
          ...(isMfaRequired && { code: mfaCode }),
        })
      );

      localStorage.setItem(
        'availableSuites',
        JSON.stringify(data?.applicationsNames)
      );

      const userId = (data?.user?.userId as any)?.value;

      hashValue(userId).then((value) =>
        localStorage.setItem('signature', value)
      );

      localStorage.setItem(
        'availableSuites',
        JSON.stringify(data?.applicationsNames)
      );

      setAuth &&
        setAuth({
          isLoggedIn: true,
          isAuthenticating: false,
          data: {
            user: {
              ...data?.user,
              profileType:
                profileTypeByApplication[
                  currentApplication || ApplicationEnum.TEACHER_SUIT
                ] || ProfileTypeEnum.TEACHER,
            },
          } as any,
        });

      successCallback({
        userId,
        wasImpersonated: Boolean(impersonate),
      });

      if (isMfaRequired) {
        window.location.reload();
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const backendError = error as ErrorResponse;

        if (
          backendError.response?.status === 400 &&
          backendError?.response?.data?.error?.code ===
            NETWORK_ERROR_CODES.mfaRequired
        ) {
          setMfaRequired(true);

          return;
        }

        if (error.response.status < 500 && error.response.status !== 0) {
          const { code = 'GENERAL_ERROR' } =
            backendError?.response?.data.error || {};
          const message = LOGIN_ERRORS[code];

          return errorCallback(message);
        }
      }
      errorCallback(LOGIN_ERRORS.GENERAL_ERROR);
    }
  };
};
