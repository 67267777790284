import { getTheme } from '../assets/styles/chakra-theme';
import { ChakraProvider } from '@chakra-ui/react';
import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import {
  FontFamilyEnum,
  FontSizeEnum,
  fontSizesMapping,
  fontSizesMappingBelowK3,
} from '@lon/shared/constants';
import { ThemeEnum } from '@lon/shared/enums';
import { useGradeLevel } from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { parseJSON } from '@lon/shared/utils';
import { RootState } from '@lon/suit/configs';
import useCurrentRouter from './useCurrentRouter';

const AppRouter = () => {
  const { isStudentBelowK3 } = useGradeLevel();
  const applicationSettings = useSelector(
    (state: RootState) => state.applicationSettings
  );
  const router = useCurrentRouter();

  useEffect(() => {
    const html = document.documentElement;
    const preferences = applicationSettings?.preferences;
    if (preferences) {
      const fontFamily: FontFamilyEnum = (parseJSON(preferences) as any)
        ?.fontFamily;
      const fontSize: FontSizeEnum = (parseJSON(preferences) as any)?.fontSize;
      const mapping = isStudentBelowK3
        ? fontSizesMappingBelowK3
        : fontSizesMapping;
      html.style.fontSize = (mapping as any)?.[fontFamily]?.[
        fontSize
      ]?.fontSize;
    } else if (isStudentBelowK3) {
      html.style.fontSize = '1.375em';
    } else {
      html.style.fontSize = '1em';
    }
  }, [applicationSettings?.preferences, isStudentBelowK3]);

  const fontFamily: FontFamilyEnum = (
    parseJSON(applicationSettings?.preferences) as any
  )?.fontFamily;
  const currentTheme: ThemeEnum = (
    parseJSON(applicationSettings?.preferences) as any
  )?.theme;

  return (
    <ChakraProvider
      theme={getTheme({
        fontFamily,
        currentTheme,
        isKinder:
          applicationSettings?.value?.user?.type === UserTypeEnum.Parent
            ? false
            : isStudentBelowK3,
      })}
    >
      <Suspense fallback={null}>
        <RouterProvider router={router} />
      </Suspense>
    </ChakraProvider>
  );
};

export default AppRouter;
