import { Box, Divider, Flex } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import {
  UserTypeEnum,
  useAskChatbotLazyQuery,
  useCreateAiChatMutation,
  useGetScope2Query,
} from '@lon/shared/requests';
import { CustomPropTypes as ChatPropTypes } from './duck';
import { Footer, Header, Messages } from './components';

const Chat: React.FC<ChatPropTypes> = ({ onClose }) => {
  const [auth] = useAuth();
  const { scopeId, projectId } = useParams();
  const { currentSchoolId } = useContext(WorkingLocation);
  const isStaff = auth.user.type === UserTypeEnum.Staff;
  const inputRef = React.useRef(null);

  const { loading: scopeUrlLoading, data: scopeUrlData } = useGetScope2Query({
    variables: {
      schoolId: currentSchoolId as string,
      id: scopeId as string,
    },
    fetchPolicy: 'network-only',
    skip: isStaff || !currentSchoolId || !scopeId,
  });

  const [messages, setMessages] = useState([
    {
      from: 'computer',
      text: 'Welcome to the Perry Learning Bot, how can I help you?',
    },
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [threadId, setThreadId] = useState('');

  const [askChatbotQuery, { loading }] = useAskChatbotLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [createAiChat, { loading: createAiLoading }] = useCreateAiChatMutation({
    onCompleted: (data) => {
      setMessages((prev) => [
        ...prev,
        { from: 'computer', text: data.createAiChat.answer },
      ]);
      setInputMessage('');
    },
  });

  React.useEffect(() => {
    createAiLoading && setInputMessage('');
    (inputRef?.current as HTMLInputElement | null)?.focus();
  }, [createAiLoading]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim().length) {
      return;
    }

    const localMessage = inputMessage;

    setMessages((old) => [...old, { from: 'me', text: localMessage }]);
    setInputMessage('');
    try {
      askChatbotQuery({
        variables: {
          userId: auth.user.userId,
          query: localMessage,
          threadId: threadId || null,
        },
      }).then((response) => {
        const { answer, threadId: newThreadId } =
          response.data?.askChatbot || {};
        if (answer) {
          setMessages((old) => [...old, { from: 'computer', text: answer }]);
        }
        if (threadId === '' && newThreadId) {
          setThreadId(newThreadId);
        }
      });
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleSendV2Message = () => {
    if (!inputMessage.trim().length || scopeUrlLoading || createAiLoading) {
      return;
    }

    setMessages((old) => [...old, { from: 'me', text: inputMessage }]);

    createAiChat({
      variables: {
        input: {
          query: inputMessage,
          clearHistory: !(messages?.length > 1),
          gradeLevel: isStaff ? 'teacher' : auth.user.gradeLevel,
          scopeId:
            !projectId && !scopeId
              ? null
              : isStaff
              ? Number(projectId)
              : scopeUrlData?.baseScope?.projectId
              ? Number(scopeUrlData?.baseScope.projectId)
              : null,
          edition: auth.user.state || null,
        },
      },
    }).catch(() => {});
  };

  return (
    <Flex
      w="100%"
      h="60vh"
      justify="center"
      align="center"
      backgroundColor="white"
      borderRadius={4}
    >
      <Flex w="330px" h="100%" flexDir="column">
        <Header onClose={onClose} />
        <Box
          minHeight="100%"
          maxHeight="100%"
          border="5px solid #1E4176"
          backgroundColor="white"
        >
          <Messages
            messages={messages}
            loading={loading || createAiLoading || scopeUrlLoading}
          />
          <Divider
            w="100%"
            borderBottomWidth="3px"
            color="black"
            m={0}
            borderColor="secondary.400"
            tabIndex={-1}
          />
          <Footer
            loading={scopeUrlLoading || createAiLoading}
            inputMessage={inputMessage}
            inputRef={inputRef}
            setInputMessage={setInputMessage}
            handleSendMessage={handleSendMessage}
            handleSendV2Message={handleSendV2Message}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Chat;
