import { t } from 'i18next';
import { ERROR_CODES, RESERVED_MESSAGES } from '@lon/shared/constants';
import { ErrorCode, ErrorParams } from '@lon/shared/types';

export const getErrorMessageByCode = (
  code: ErrorCode,
  params?: ErrorParams,
  message?: string
): string =>
  t(`systemMessages.${ERROR_CODES[code]}`, {
    defaultValue: RESERVED_MESSAGES?.[message!] || message,
    ...params,
  });
