import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authSlice, widgetsSlice } from '@lon/shared/configs';
import { applicationSettingsSlice, sidebarSlice } from '@lon/shared/configs';
import filtersReducer from './filters';
import plannerReducer from './planner';
import scopesReducer from './scopes';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filters'],
  blacklist: ['auth'],
};

const rootReducer = combineReducers({
  sidebar: sidebarSlice.default,
  auth: authSlice.default,
  scopes: scopesReducer,
  filters: filtersReducer,
  planner: plannerReducer,
  applicationSettings: applicationSettingsSlice.default,
  widgets: widgetsSlice.default,
});

export default persistReducer(persistConfig, rootReducer);
