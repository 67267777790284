import { Icon } from '../icon';
import { useToast } from '../use-toast';
import {
  Flex,
  HStack,
  IconButton,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGINATION_SIZE } from '@lon/shared/constants';
import { useQueryParams } from '@lon/shared/hooks';
import { paginationConstants, paginationTypes } from './duck';
import { Drawer, PageJumper } from './components';

const Pagination: React.FC<paginationTypes.PaginationProps> = ({
  paginationKey,
  total,
  initialPageSize,
  unlimPages = false,
  size = 'md',
  paginationWrapperProps,
  jumperWrapperProps,
  recordsWrapperProps,
  paginationProps,
  settingsButtonProps,
  onChange,
  onChangeComplete,
  hideGoToPage,
  hidePerPageSelector,
  paginationConfig = {
    rowOptions: paginationConstants.ROW_OPTIONS,
    defaultPage: paginationConstants.DEFAULT_PAGE,
    itemsPerPageLimits: paginationConstants.ITEMS_PER_PAGE_LIMITS,
  },
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [params, setQueryParams] = useQueryParams();
  const toast = useToast();
  const paramsKey = paginationKey || 'pagination';
  const pagination = params[paramsKey];
  const { defaultPage, rowOptions, itemsPerPageLimits } = paginationConfig;
  const page = Number(pagination?.page) || defaultPage;
  const itemsPerPage =
    Number(pagination?.itemsPerPage) ||
    initialPageSize ||
    DEFAULT_PAGINATION_SIZE;

  const numberOfPages = Math.ceil(total / itemsPerPage);
  const isDisableNext = page === numberOfPages;
  const isDisabledPrev = page === 1;

  const setParams = (newParams: { page?: number; itemsPerPage?: number }) => {
    setQueryParams({
      ...params,
      [paramsKey]: {
        ...params[paramsKey],
        ...newParams,
      },
    });
  };

  React.useLayoutEffect(() => {
    if (total !== 0 && page > numberOfPages) {
      setParams({ page: numberOfPages });
      toast({
        title: t('pagination.invalidPageToastTitle'),
        description: t('pagination.beforePageLimitToastDescription', {
          page,
        }),
        variant: 'warning-light',
        isClosable: true,
      });
    }
  }, [page, total]);

  React.useEffect(() => {
    if (
      !unlimPages &&
      itemsPerPageLimits &&
      page > itemsPerPageLimits[itemsPerPage]
    ) {
      setParams({ page: 1 });
      toast({
        title: t('pagination.invalidPageToastTitle'),
        description: t('pagination.afterPageLimitToastDescription', {
          page,
        }),
        variant: 'warning-light',
        isClosable: true,
      });
    }
  }, [page]);

  const handleChange = ({
    page,
    itemsPerPage,
  }: {
    page: number;
    itemsPerPage: number;
  }) => {
    if (onChange) {
      onChange({ page, itemsPerPage });
    } else {
      setParams({ page, itemsPerPage });
    }
    onChangeComplete && onChangeComplete();
  };

  if (!total) {
    return null;
  }

  return (
    <>
      <Flex
        data-testid="pagination"
        bgColor="white"
        color="primary.800"
        borderRadius="6px"
        alignItems="center"
        gap="3"
        px={{ base: '2', lg: '8' }}
        py="1.5"
        justifyContent="space-between"
        {...paginationWrapperProps}
      >
        {!hidePerPageSelector && (
          <HStack
            display={{ base: 'none', lg: 'flex' }}
            {...recordsWrapperProps}
          >
            <Text as="span" variant="l" flexShrink={0} id="show-records">
              {t('pagination.showRecords')}
            </Text>
            <Select
              aria-labelledby="show-records"
              size={size}
              color="primary.800"
              iconColor="primary.200"
              borderColor="primary.200"
              borderRadius="4px"
              w="90px"
              defaultValue={itemsPerPage}
              autoComplete="off"
              onChange={(e) => {
                handleChange({
                  itemsPerPage: Number(e.target.value),
                  page: 1,
                });
              }}
            >
              {rowOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </HStack>
        )}
        <HStack
          spacing="2"
          justify="space-between"
          w={{ base: 'full', lg: 'fit-content' }}
          {...paginationProps}
        >
          <HStack spacing="2">
            <IconButton
              size={size}
              variant="empty"
              boxShadow="none"
              aria-label={t('pagination.firstPage')}
              onClick={() => {
                if (!isDisabledPrev) {
                  handleChange({
                    itemsPerPage,
                    page: 1,
                  });
                }
              }}
              isDisabled={isDisabledPrev}
              icon={<Icon name="arr-double-left-outlined" />}
            />
            <IconButton
              size={size}
              variant="empty"
              boxShadow="none"
              aria-label={t('pagination.previousPage')}
              onClick={() => {
                if (!isDisabledPrev) {
                  handleChange({
                    itemsPerPage,
                    page: page - 1,
                  });
                }
              }}
              isDisabled={isDisabledPrev}
              icon={<Icon name="arr-left-outlined" />}
            />
          </HStack>
          <HStack
            spacing={{ base: '0', sm: '2.5' }}
            align="center"
            alignSelf="normal"
            px={{ base: '2', sm: '4' }}
            borderX="1px solid"
            borderColor="secondary.50"
          >
            <Flex display={{ base: 'none', sm: 'flex' }} whiteSpace="pre-wrap">
              <Text variant="n3">{t('pagination.page')} </Text>
              <Text variant="n1" whiteSpace="nowrap">
                {page} {t('pagination.of')} {numberOfPages}
              </Text>
            </Flex>
            <IconButton
              size={size}
              display={{ base: 'flex', lg: 'none' }}
              variant="solidDark"
              boxShadow="none"
              aria-label={t('pagination.openPaginationSettings')}
              onClick={onOpen}
              icon={<Icon name="ellipsis-outlined" />}
              {...settingsButtonProps}
            />
          </HStack>
          <HStack spacing="2">
            <IconButton
              size={size}
              variant="empty"
              boxShadow="none"
              transform="scaleX(-1)"
              aria-label={t('pagination.nextPage')}
              colorScheme="whiteAlpha"
              onClick={() => {
                if (!isDisableNext) {
                  handleChange({
                    itemsPerPage,
                    page: page + 1,
                  });
                }
              }}
              isDisabled={isDisableNext}
              icon={<Icon name="arr-left-outlined" />}
            />
            <IconButton
              size={size}
              variant="empty"
              boxShadow="none"
              transform="scaleX(-1)"
              aria-label={t('pagination.lastPage')}
              colorScheme="whiteAlpha"
              onClick={() => {
                if (!isDisableNext) {
                  handleChange({
                    itemsPerPage,
                    page: numberOfPages,
                  });
                }
              }}
              isDisabled={isDisableNext}
              icon={<Icon name="arr-double-left-outlined" />}
            />
          </HStack>
        </HStack>
        {!hideGoToPage && (
          <HStack
            display={{ base: 'none', lg: 'flex' }}
            {...jumperWrapperProps}
          >
            <PageJumper
              title={t('pagination.goToPage')}
              itemsPerPage={itemsPerPage}
              size={size}
              numberOfPages={numberOfPages}
              handleChange={handleChange}
            />
          </HStack>
        )}
      </Flex>
      <Drawer
        isOpen={isOpen}
        total={total}
        onClose={onClose}
        itemsPerPage={itemsPerPage}
        currentPage={page}
        handleChange={handleChange}
        rowOptions={rowOptions}
      />
    </>
  );
};

export default Pagination;
