import { AclWrapper } from '../acl-wrapper';
import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '@lon/shared/components';
import { authSlice } from '@lon/shared/configs';
import {
  NETWORK_ERROR_CODES,
  profileTypeByApplication,
} from '@lon/shared/constants';
import { AclContext, CurrentSchoolContext } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { RoleCodeEnum } from '@lon/shared/gql/types';
import { useInvalidateStorages } from '@lon/shared/hooks';
import { useAuth, useCurrentSchoolId } from '@lon/shared/hooks';
import { useForwarder } from '@lon/shared/hooks';
import {
  AclApplication,
  GetAclApplicationsQuery,
  UserTypeEnum,
  useGetAclApplicationsQuery,
  useGetDistrictSettingsQuery,
} from '@lon/shared/requests';
import { ImpersonatedUserLS } from '@lon/shared/types';
import {
  REFETCH_APP_SETTINGS,
  getLastApplicationBySignature,
  getSignature,
  hashValue,
  mergeSuiteAclSettings,
  setLastApplicationBySignature,
  storeLoginData,
  parseJSON
} from '@lon/shared/utils';
import { usePreferences } from './duck';
import useUserRolesLookUp from './duck/use-user-roles-look-up';

const applicationPriority: Record<string, number> = {
  [ApplicationEnum.TEACHER_SUIT]: 0,
  [ApplicationEnum.CAMPUS_LEADER_SUIT]: 1,
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]: 1,
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]: 1,
};

type Suites = Extract<
  ApplicationEnum,
  | ApplicationEnum.CAMPUS_LEADER_SUIT
  | ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT
  | ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT
  | ApplicationEnum.STUDENT_SUIT
  | ApplicationEnum.TEACHER_SUIT
  | ApplicationEnum.PARENT_SUIT
>;

const getApplicationByPriority = (
  data: GetAclApplicationsQuery | undefined
) => {
  const suites = data?.aclApplications?.filter((suite) =>
    Object.prototype.hasOwnProperty.call(applicationPriority, suite.name)
  );

  const sortedByPrioritySuite = suites?.sort((a, b) => {
    return applicationPriority[b.name] - applicationPriority[a.name];
  });
  return sortedByPrioritySuite?.[0]?.name;
};

export const BootstrapSuiteApp: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [appLoading, setAppLoading] = useState(true);
  const [auth] = useAuth();
  const shouldRefetch = useReactiveVar(REFETCH_APP_SETTINGS);
  const dispatch = useDispatch();
  const impersonate = JSON.parse(
    localStorage.getItem('impersonate') || '{}'
  ) as ImpersonatedUserLS;
  useInvalidateStorages();
  const [application, setCurrentApplication] = useState<Suites>();
  const [currentSchoolId, setCurrentSchoolId] = useCurrentSchoolId();

  const {
    data,
    loading: aclApplicationsLoading,
    error,
    refetch,
    called,
  } = useGetAclApplicationsQuery({
    onCompleted: (data) => {
      hashValue(data.applicationSettings?.value?.user?.userId).then((value) =>
        localStorage.setItem('signature', value)
      );
      storeLoginData(data?.applicationSettings?.value);
    },
    onError: () => {
      setAppLoading(false);
    },
  });

  usePreferences();

  const { data: settingsData, loading: settingsLoading } =
    useGetDistrictSettingsQuery({
      variables: {
        withUMSettings: application !== ApplicationEnum.STUDENT_SUIT,
        withRosterSettings: application === ApplicationEnum.TEACHER_SUIT,
        withWizardStatus: false,
        withContentGroups: true,
        withKnowledgeSettings: true,
        withAISettings: application === ApplicationEnum.TEACHER_SUIT,
      },
      skip: !application,
      onCompleted: () => {
        setAppLoading(false);
      },
      onError: () => {
        setAppLoading(false);
      },
    });

  useForwarder({
    acl: (data?.aclApplications || []) as AclApplication[],
    aclApplicationsLoading,
  });

  const { lookUpRolesLoading, rolesLookUp } = useUserRolesLookUp(
    data?.applicationSettings?.value?.user?.userRoles,
    !data ||
      (data &&
        data?.applicationSettings?.value?.user?.type ===
          UserTypeEnum.Student) ||
      (data &&
        data?.applicationSettings?.value?.user?.type === UserTypeEnum.Parent)
  );

  const isSessionExpired =
    error?.message === NETWORK_ERROR_CODES.sessionExpired;
  const isPasswordExpired =
    error?.message === NETWORK_ERROR_CODES.passwordExpired;
  const isSessionInvalidated =
    error?.message === NETWORK_ERROR_CODES.sessionInvalidated;
  const showCredentialsModal =
    isSessionExpired || isPasswordExpired || isSessionInvalidated;

  useEffect(() => {
    if (!shouldRefetch) {
      if (!called) {
        refetch();
      }
    } else {
      if (called) {
        refetch();
        REFETCH_APP_SETTINGS(false);
      }
    }
  }, [shouldRefetch, called]);

  const getApplication = () => {
    const user = data?.applicationSettings?.value?.user;
    const signature = getSignature();
    const defaultApplication = data?.aclApplications?.find(
      (app) =>
        app.name ===
        (parseJSON(data?.applicationSettings?.value?.preferences) as any)
          ?.defaultApplication
    )?.name;
    const appFromLocalStorage = getLastApplicationBySignature(signature);
    // Check if the application from the LocalStorage matches the current application
    const isAppConsistent = ![
      'Admin',
      'PlatformAdmin',
      'MathnationSuit',
    ].includes(appFromLocalStorage);
    const savedApplication = showCredentialsModal
      ? appFromLocalStorage
      : appFromLocalStorage &&
        data?.aclApplications?.find((app) => app.name === appFromLocalStorage)
          ?.name;

    if (
      impersonate.type === RoleCodeEnum.Teacher &&
      impersonate.mainProfileType === RoleCodeEnum.CampusLeader
    ) {
      return ApplicationEnum.TEACHER_SUIT;
    }

    return (
      user?.type === UserTypeEnum.Student ||
      impersonate.type === RoleCodeEnum.Student
        ? ApplicationEnum.STUDENT_SUIT
        : user?.type === UserTypeEnum.Parent
        ? ApplicationEnum.PARENT_SUIT
        : savedApplication && isAppConsistent
        ? savedApplication
        : defaultApplication
        ? defaultApplication
        : getApplicationByPriority(data)
    ) as Suites;
  };

  React.useEffect(() => {
    if (aclApplicationsLoading || lookUpRolesLoading) {
      return;
    }

    const user = data?.applicationSettings?.value?.user;

    if (user) {
      const application = getApplication();

      if (application && user.userId) {
        hashValue(user.userId).then((value) =>
          setLastApplicationBySignature({ signature: value, application })
        );
      }

      dispatch(
        authSlice.setAuth({
          user: {
            type: user?.type,
            firstName: user?.firstName,
            lastName: user?.lastName,
            username: user?.username,
            schoolIds: user?.schoolIds,
            districtId: user?.districtId,
            userId: user?.userId,
            gradeLevel: user?.gradeLevel,
            email: user?.email,
            purpose: user?.purpose,
            permissions: user?.permissions,
            availableSystems: user?.availableSystems,
            userRoles: rolesLookUp!,
            state: user?.state,
            impersonated: user?.impersonated,
            profileType: user?.profileType,
            districtUuid: user?.districtUuid,
          },
          isLoggedIn: true,
          isAuthenticating: false,
          profileType: profileTypeByApplication[application],
        })
      );
    }
  }, [data, rolesLookUp, lookUpRolesLoading]);

  React.useEffect(() => {
    const user = data?.applicationSettings?.value?.user;
    if (user) {
      const application = getApplication();
      setCurrentApplication(application);
    }
  }, [data]);

  const aclContextValue = useMemo(
    () => ({
      value: (data
        ? mergeSuiteAclSettings(application, data, settingsData)
        : []) as AclApplication[],
      loading: aclApplicationsLoading || settingsLoading,
    }),

    [data, aclApplicationsLoading, settingsData, settingsLoading]
  );

  if ((appLoading && !showCredentialsModal) || lookUpRolesLoading) {
    return <Loader isImpersonate={auth?.user?.impersonated} />;
  }

  return (
    <AclContext.Provider value={aclContextValue}>
      <AclWrapper
        application={application as Suites}
        loading={aclApplicationsLoading || settingsLoading}
        showCredentialsModal={showCredentialsModal}
      >
        <CurrentSchoolContext.Provider
          value={{
            setCurrentSchoolId,
            currentSchoolId,
          }}
        >
          {children}
        </CurrentSchoolContext.Provider>
      </AclWrapper>
    </AclContext.Provider>
  );
};
