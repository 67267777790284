import { Flex, VisuallyHidden } from '@chakra-ui/react';
import { uniq } from 'lodash-es';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Pagination } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import {
  useDebounceValue,
  useGetParentStudents,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useQueryParams,
  useQueryWithPagination,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { useGetScopesQuery } from '@lon/shared/requests';
import { usePrevious } from '@lon/suit/hooks';
import { GetScopesQuery } from '@lon/suit/types';
import { RefetchContext, listConstants, listTypes } from './duck';
import { List } from './components';

const ScopesList: React.FC<listTypes.ScopesListProps> = ({
  type,
  gridGap,
  backgroundColor = 'blue.75',
  itemPath,
}) => {
  const [params] = useQueryParams();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { application } = useContext(WorkingLocation);
  const isParentSuite = application === ApplicationEnum.PARENT_SUIT;

  const { currentStudent, loading: studentsLoading } = useGetParentStudents();

  const studentGrades = useMemo(() => {
    const gradeLevels = currentStudent?.classes?.map(
      (item) => item.gradeOrCourse
    );

    return uniq(gradeLevels);
  }, [currentStudent?.classes]);

  const debouncedSearch = useDebounceValue(params.search || '', 500);
  const [attempts, setAttempts] = useState<number>(0);
  const prevAttempts = usePrevious(attempts);
  const accessibleSchoolIds = useGetSchoolIds();

  const { data, loading, refetch } = useQueryWithPagination({
    queryHook: useGetScopesQuery,
    queryOptions: {
      variables: {
        schoolIds: accessibleSchoolIds,
        search: debouncedSearch,
        filter: {
          ...params.filters,
          projectId,
          ...(isParentSuite && { gradeLevel: studentGrades || [] }),
        },
      },
      skip:
        !accessibleSchoolIds?.length ||
        (isParentSuite && !studentGrades?.length),
    },
    defaultPaginationSize: listConstants.DEFAULT_ITEMS_COUNT,
  });

  const refetchValues = useMemo(
    () => ({
      refetch,
      attempts: prevAttempts,
      setAttempts,
    }),
    [refetch, prevAttempts, setAttempts]
  );

  const scopes = (data as GetScopesQuery)?.scopes?.collection;

  return (
    <RefetchContext.Provider value={refetchValues}>
      <VisuallyHidden role="status" aria-live="polite">
        {loading || studentsLoading ? t('scopes.loadMessage') : ''}
      </VisuallyHidden>
      <VisuallyHidden role="status" aria-live="polite">
        {!loading || studentsLoading
          ? t('scopes.countOfResults', {
              count: data?.scopes.pageInfo?.totalCount || 0,
            })
          : ''}
      </VisuallyHidden>
      <Flex
        direction="column"
        grow="1"
        justify="space-between"
        backgroundColor={useThemeStyleValue(backgroundColor, 'secondary.950')}
        pb={{ md: 6, lg: 6, b1281: 0.5 }}
      >
        <List
          data={scopes}
          loading={loading || studentsLoading}
          skeletonsCount={listConstants.DEFAULT_ITEMS_COUNT}
          type={type}
          gridGap={gridGap}
          itemPath={itemPath}
        />
        <Pagination
          total={data?.scopes.pageInfo?.totalCount || 0}
          paginationWrapperProps={{ marginTop: 'auto' }}
          initialPageSize={listConstants.DEFAULT_ITEMS_COUNT}
          paginationConfig={listConstants.paginationConfig}
          onChangeComplete={() => {
            const content = document.querySelector('.page-content');
            content?.scrollTo(0, 0);
          }}
        />
      </Flex>
    </RefetchContext.Provider>
  );
};

export default ScopesList;
