import React from 'react';
import { useGetLookUpRolesQuery } from '@lon/shared/requests';

const useUserRolesLookUp = (userRoles: { roleId: string }[], skip = false) => {
  const userRolesIds = React.useMemo(() => {
    if (userRoles) {
      return userRoles.map((role) => role.roleId);
    }

    return [];
  }, [userRoles]);

  const { data: rolesData, loading: lookUpRolesLoading } =
    useGetLookUpRolesQuery({
      variables: {
        ids: userRolesIds,
      },
      skip: skip || userRolesIds.length === 0,
    });

  return {
    rolesLookUp: rolesData?.lookupRoles,
    lookUpRolesLoading,
  };
};

export default useUserRolesLookUp;
