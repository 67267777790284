import { Text } from '@chakra-ui/react';
import React from 'react';
import styles from './LoadingText.module.css';

const LoadingText: React.FC = () => {
  return (
    <Text
      fontSize="sm"
      display="inline-flex"
      className={styles.content}
      aria-label="loading response"
      tabIndex={0}
    >
      Loading<span className="loading-ellipsis">...</span>
    </Text>
  );
};

export default LoadingText;
