import { Footer } from '../../components';
import { Flex } from '@chakra-ui/react';
import { TeacherPages } from '@suit/pages';
import React from 'react';
import { sxLightScrollBar } from '@lon/shared/constants';

const Activity = () => {
  return (
    <Flex
      p={10}
      height="calc(100vh - 220px)"
      overflow="auto"
      sx={sxLightScrollBar}
      justifyContent="space-between"
      direction="column"
      gap={6}
    >
      <TeacherPages.Element hasToolbar={false} isDisabledBreadcrumbs />
      <Footer mx={0} mb={0} />
    </Flex>
  );
};

export default Activity;
