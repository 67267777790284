export const LEARNOSITY_WRAPPER = 'learnosity-wrapper';
export const RESPONSE_ANALYSIS = 'response-analysis-by-item';
export const LEARNING_OUTCOMES = 'item-scores-by-tag-by-user';
export const CONTAINER_HTML_ELEMENT = 'div';
export const NO_REPORT = 'There is a problem loading the report';
export const scores = [
  {
    label: 'Highlight low scores',
    value: 'lowScores',
  },
  {
    label: 'Highlight high scores',
    value: 'highScores',
  },
];
export const LEARNOSITY_SCORES_BY_TAG_BY_USERS = 'item-scores-by-tag-by-user';
export const LEARNOSITY_MAIN_ROW_HEADER = 'lrn-ibtbu-main-row-header';
export const LEARNOSITY_MAIN_ROW_BODY = 'lrn-ibtbu-main-row-body';
export const LEARNOSITY_HEAD_CELL_EXPANDABLE = 'lrn-ibtbu-head-cell_expandable';

export const DEFAULT_ITEMS_PER_PAGE = 100;
export const paginationConfig = {
  defaultPage: 1,
  itemsPerPageLimits: { 100: 100 },
  rowOptions: [{ label: '100', value: 100 }],
};
