import { ImpersonatedUserLS } from '@lon/shared/types';
import { CancelImpersonation } from './types';

export const cancelImpersonation: CancelImpersonation = async ({
  cancelImpersonationMutation,
}) => {
  const impersonate = JSON.parse(
    localStorage.getItem('impersonate') || '{}'
  ) as ImpersonatedUserLS;

  try {
    await cancelImpersonationMutation({
      variables: { input: {} },
    });

    localStorage.removeItem('impersonate');

    if (!impersonate?.redirectTo) {
      window.location.reload();
    } else {
      window.location.href = impersonate?.redirectTo;
    }
  } catch (error) {
    console.log(error);
  }
};
