import { tableTypes } from './duck';
import variants from './variants';

const Table = <Data extends object>({
  variant = 'primary',
  showFullSkeleton = true,
  ...rest
}: tableTypes.TableProps<Data>) => {
  const TableComponent = variants[variant];

  return (
    <TableComponent
      {...rest}
      variant={variant}
      showFullSkeleton={showFullSkeleton}
    />
  );
};

export default Table;
