import { createReducer, ActionType } from 'typesafe-actions';
import initialState from './state';
import * as actions from './actions';
import { navigatePrev } from './utils';
import * as Types from './types';

type Action = ActionType<typeof actions>;

const rootReducer = createReducer<Types.State, Action>(initialState)
  .handleAction(actions.registerItem, (state, { payload: item }) => ({
    ...state,
    items: [...state.items, item],
  }))
  .handleAction(actions.unregisterItem, (state, { payload: id }) => {
    const indexToDelete = state.items.findIndex((item) => item.id === id);

    return {
      ...state,
      items: state.items.filter((item) => item.id !== id),
      activeIndex: state.activeIndex === indexToDelete ? 0 : state.activeIndex,
    };
  })
  .handleAction(actions.setReturnFocus, (state, { payload: returnFocus }) => ({
    ...state,
    returnFocus,
  }))
  .handleAction(
    actions.toggleDisabled,
    (state, { payload: { id, disabled } }) => {
      const updatedIndex = state.items.findIndex((item) => item.id === id);

      if (updatedIndex === -1) {
        return state;
      }

      // eslint-disable-next-line no-param-reassign
      state.items[updatedIndex].disabled = disabled;

      if (disabled && state.activeIndex === updatedIndex) {
        const index = navigatePrev(state.items, state.activeIndex);
        return { ...state, activeIndex: index };
      }

      return state;
    }
  )
  .handleAction(actions.setActiveIndex, (state, { payload: activeIndex }) => ({
    ...state,
    activeIndex,
  }))
  .handleAction(actions.setConfig, (state, { payload: config }) => ({
    ...state,
    ...config,
  }));

export default rootReducer;
