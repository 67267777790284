import { List } from '../../../../../list';
import styles from '../../Section.module.css';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { types } from './duck';
import { ElementCard, Skeleton } from './components';

const ScopeElementList: React.FC<types.ScopeElementListProps> = ({
  loading,
  elements,
  elementPlacementUrl,
  className,
}) => (
  <Box role="tabpanel" id="scope-elements" aria-labelledby="all-elements">
    <List
      loading={loading}
      grid="1"
      data={elements}
      mapKey={(element) => element.identifier}
      className={clsx(styles.list, className)}
      renderSkeleton={Skeleton}
      renderItem={(element) => (
        <ElementCard
          id={element.identifier}
          sectionId={element.sectionId}
          image={element?.metadata?.elementIconType}
          title={element.metadata?.elementName || ''}
          elementPlacementUrl={elementPlacementUrl}
        />
      )}
    />
  </Box>
);

export default ScopeElementList;
