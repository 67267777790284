import { MutationFunctionOptions } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Exact,
  SignRequestForGradingMutation,
  SignRequestInput,
  useSignRequestForStudentAssignmentMutation,
} from '@lon/shared/requests';
import { skipTranslationForLernosityLabels } from '@lon/shared/utils';
import {
  prepareLearnosityReportForGradingPage,
  replaceVideoLinks,
  resizeMejsVideos,
} from '@lon/suit/utils';
import { generateQuestionHeaders, hideCorrectAnswers } from './duck';

const useRenderReportsLearnosity = ({
  setIsLearnosityLoading,
  signRequestOptions,
  reportId,
  shouldHideCorrectAnswers = false,
}: {
  setIsLearnosityLoading: React.Dispatch<React.SetStateAction<boolean>>;
  signRequestOptions: MutationFunctionOptions<
    SignRequestForGradingMutation,
    Exact<{
      params: SignRequestInput;
    }>
  >;
  reportId: string;
  shouldHideCorrectAnswers?: boolean;
}) => {
  const { t } = useTranslation();

  const [signRequest] = useSignRequestForStudentAssignmentMutation();

  const toast = useToast();
  const showGeneralError = () => {
    toast({
      title: t('systemMessages.038'),
      variant: 'error-light',
      isClosable: true,
    });
  };

  useEffect(() => {
    let mounted = true;
    setIsLearnosityLoading(true);
    const resizeObserver = new ResizeObserver(() => resizeMejsVideos());
    const videoObserver = replaceVideoLinks(document.body);
    let hideCorrectAnswersObserver: MutationObserver;

    signRequest(signRequestOptions)
      .then((response) => {
        if (!mounted) {
          return;
        }
        const reportSignedRequest = response?.data?.signRequest
          ?.signedRequest as string;

        if (!reportSignedRequest) {
          throw new Error();
        }

        const reportsApp = window.LearnosityReports.init(
          JSON.parse(reportSignedRequest),
          {
            readyListener() {
              const report1 = reportsApp.getReport(reportId);

              report1.on('ready:itemsApi', function () {
                if (!mounted) {
                  return;
                }

                if (shouldHideCorrectAnswers) {
                  hideCorrectAnswersObserver = hideCorrectAnswers(reportId);
                }

                prepareLearnosityReportForGradingPage(reportId, resizeObserver);
                generateQuestionHeaders(reportId);
                skipTranslationForLernosityLabels();
                setIsLearnosityLoading(false);
              });
            },
            errorListener() {
              if (!mounted) {
                return;
              }
              setIsLearnosityLoading(false);

              showGeneralError();
            },
          }
        );
      })
      .catch(() => {
        if (!mounted) {
          return;
        }
        setIsLearnosityLoading(false);

        showGeneralError();
      });

    return () => {
      mounted = false;
      resizeObserver.disconnect();
      videoObserver.disconnect();
      hideCorrectAnswersObserver?.disconnect();
    };
  }, []);
};

export default useRenderReportsLearnosity;
