import { CardListContext } from '../../duck';
import React, { useContext, useRef } from 'react';
import { CardProps, keyDown } from './duck';

const Card: React.FC<CardProps> = ({ children, position }) => {
  const firstElementRef = useRef<HTMLElement>(null);
  const lastElementRef = useRef<HTMLElement>(null);
  const {
    focusIndex,
    setFocusIndex,
    itemsCount,
    selectedIndex,
    setSelectedIndex,
  } = useContext(CardListContext);
  const isSelectedItem = selectedIndex === position;

  return children({
    isSelectedItem,
    firstElementRef,
    lastElementRef,
    tabIndex: focusIndex === position && focusIndex !== selectedIndex ? 0 : -1,
    _focusVisible: {
      outline: 'none',
      boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
      textDecoration: 'none',
    },
    onKeyDown: (event) => {
      keyDown({
        event,
        position,
        itemsCount,
        isSelectedItem,
        setSelectedIndex,
        setFocusIndex,
        firstElementRef,
        lastElementRef,
      });
    },
  });
};

export default Card;
