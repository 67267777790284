import { Icon } from '../../../../../../../icon';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FrostContentPlayerContext } from '@lon/shared/contexts';
import { ContentGroupType, ScopeMetaObj } from '@lon/shared/types';
import { parseList } from '@lon/shared/utils';

const ContentGroupsMenu = () => {
  const { t } = useTranslation();
  const { frostData } = useContext(FrostContentPlayerContext);
  const contentGroupsJson = parseList(frostData);
  const contentGroups = contentGroupsJson?.scopes
    ?.collection as unknown as ContentGroupType[];

  if (!contentGroups?.length) {
    return;
  }

  return (
    <Box pb="4">
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              size="sm"
              variant="outlineDark"
              rightIcon={
                <Box
                  as={Icon}
                  name="caret-right-outlined"
                  size="sm"
                  color="white"
                  transform={isOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
                />
              }
            >
              {t('header.contentGroups')}
            </MenuButton>
            <MenuList
              p="0"
              borderRadius="0"
              overflowY="auto"
              as="ul"
              borderBottomRadius="sm"
            >
              {contentGroups?.map((contentGrp) => {
                const contentGroupVal = (contentGrp?.metadata as ScopeMetaObj)
                  ?.contentGroup;
                const title =
                  typeof contentGroupVal === 'string'
                    ? contentGroupVal
                    : contentGroupVal?.find((val: string) => Boolean(val)) ||
                      '';
                return (
                  <MenuItem
                    key={contentGrp.id}
                    p="0"
                    as="li"
                    cursor="not-allowed"
                  >
                    <Button
                      size="sm"
                      variant="clear"
                      display="inline-flex"
                      justify-content="flex-start"
                      color="primary.800"
                      white-space="normal"
                      wordBreak="break-word"
                      cursor="not-allowed"
                    >
                      <Text variant="bm" as="span">
                        {title}
                      </Text>
                    </Button>
                  </MenuItem>
                );
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  );
};

export default ContentGroupsMenu;
