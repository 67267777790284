import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Body: React.FC<{ feedback?: string }> = ({ feedback }) => {
  const { t } = useTranslation();
  if (!feedback) {
    return null;
  }

  return (
    <Box>
      <Text variant="n1" color="primary.800">
        {t('studentAssignmentDetails.feedback')}
      </Text>
      <Flex
        alignItems="center"
        alignSelf="stretch"
        background="gray.25"
        justifyContent="center"
        mt="0.5rem"
        p="8"
        borderRadius="0px 40px 40px 40px"
      >
        <Text variant="s3" color="primary.800">
          {feedback}
        </Text>
      </Flex>
    </Box>
  );
};

export default Body;
