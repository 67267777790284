import format from 'date-fns/format';
import { t } from 'i18next';
import { yup } from '@lon/shared/utils';
import { studentNoteMaxLength } from '@lon/suit/constants';

export const getValidationSchema = (isEndDateRequired?: boolean) => {
  return isEndDateRequired
    ? yup.object().shape({
        endDate: yup
          .date()
          .nullable()
          .required(
            t('validationMessages.requiredField', {
              fieldName: t('resetAssignmentModal.endDateLabel'),
            })
          )
          .default(null),
        notes: yup
          .string()
          .default('')
          .max(
            studentNoteMaxLength,
            t('validationMessages.maxLength', {
              fieldName: t('assignments.studentNoteTitle'),
              length: studentNoteMaxLength,
            })
          ),
      })
    : yup.object().shape({
        notes: yup
          .string()
          .default('')
          .max(
            studentNoteMaxLength,
            t('validationMessages.maxLength', {
              fieldName: t('assignments.studentNoteTitle'),
              length: studentNoteMaxLength,
            })
          ),
      });
};

export const getValidation = (isEndDateRequired: boolean) => ({
  validationSchema: getValidationSchema(isEndDateRequired),
  defaultValues: getValidationSchema().cast(
    isEndDateRequired
      ? {
          endDate: null,
          notes: '',
        }
      : { notes: '' }
  ),
});

export const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};
