import React from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { SearchableScope, useGetScopesQuery } from '@lon/shared/requests';

type UseGetStudentScopes = {
  scopes: SearchableScope[];
  loading: boolean;
};

const useGetStudentScopes = (scopeIds?: string[]): UseGetStudentScopes => {
  const { currentSchoolId = '' } = React.useContext(WorkingLocation);

  const { data, loading } = useGetScopesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      schoolIds: [currentSchoolId],
      filter: {
        id: scopeIds || [],
      },
    },
    skip: !scopeIds,
  });

  return { loading, scopes: data?.scopes.collection as SearchableScope[] };
};

export default useGetStudentScopes;
