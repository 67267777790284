import { createStandardAction } from 'typesafe-actions';
import { PlanType, ScopesListTypes } from '@lon/shared/types';

export const setListType = createStandardAction(
  'SCOPES/SET_LIST_TYPE'
)<ScopesListTypes>();

export const setPlanData = createStandardAction(
  'SIDEBAR/SET_PLAN_DATA'
)<PlanType>();
