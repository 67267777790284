const stringToPath = (string: string): string[] => {
  const rePropName =
    /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)]|(?=(?:\.|\[])(?:\.|\[]|$))/g;
  const reEscapeChar = /\\(\\)?/g;

  const result: string[] = [];

  string.replace(rePropName, (match, number, quote, subString) => {
    result.push(
      quote ? subString.replace(reEscapeChar, '$1') : number || match
    );
    return match;
  });

  return result;
};

const params = (props: any, getQuery: (params: any) => string): any => {
  const proxy = { ...props };

  Object.defineProperty(proxy, 'get', {
    value: <TValue>(path: string | string[], defaultValue?: TValue): TValue => {
      const computedPath = Array.isArray(path) ? path : stringToPath(path);

      let nested = { ...props };
      let index = 0;

      while (nested != null && index < computedPath.length) {
        nested = nested[computedPath[index]];
        index += 1;
      }

      return nested && index && index === computedPath.length
        ? nested
        : defaultValue;
    },
  });

  Object.defineProperty(proxy, 'toString', { value: () => getQuery(proxy) });

  return proxy;
};

export default params;
