const container = {
  label: {
    mb: 2,
    fontSize: '1rem',
    lineHeight: '1.625rem',
    fontWeight: 700,
  },
};

export const Form = {
  parts: ['container', 'requiredIndicator', 'helperText', 'errorMessage'],
  baseStyle: {
    container,
    requiredIndicator: {
      color: 'danger.700',
    },
  },
  variants: {
    disabled: {
      container,
      requiredIndicator: {
        color: 'secondary.400',
      },
    },
    dark: {
      container,
      requiredIndicator: {
        color: 'orange.400',
      },
    },
  },
};
