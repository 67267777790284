import { HideInactiveQuestionsProps } from './types';

export const hideInactiveQuestions = ({
  originalActivityItems,
  currentItemIndex,
  reportId,
}: HideInactiveQuestionsProps) => {
  originalActivityItems.forEach((item, index) => {
    if (index !== currentItemIndex) {
      const questionItem = document
        .getElementById(reportId)
        ?.querySelector(`[data-reference='${item.id}']`)
        ?.closest('.learnosity-item') as HTMLDivElement;

      questionItem?.classList.add('hidden');
    }
  });
};
