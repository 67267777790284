import { t } from 'i18next';
import { object, string } from 'yup';

export const validationSchema = (isAdmin?: boolean) =>
  object({
    username: string().required(
      t('validationMessages.requiredField', {
        fieldName: 'Username',
      })
    ),
    email: string().required(
      t('validationMessages.requiredField', {
        fieldName: 'Email',
      })
    ),
    comments: string().required(
      t('validationMessages.requiredField', {
        fieldName: 'Comments',
      })
    ),
    reason: string()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'Reason',
        })
      )
      .default(''),
    product: isAdmin
      ? string()
          .required(
            t('validationMessages.requiredField', {
              fieldName: 'Product',
            })
          )
          .default('')
      : string(),
  });

export const getValidation = (isAdmin?: boolean) => ({
  validationSchema: validationSchema(isAdmin),
  defaultValues: {},
});
