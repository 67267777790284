import { FormControl, FormErrorMessage, RadioGroup } from '@chakra-ui/react';
import { get } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { types } from './duck';

const FormRadioGroup: React.FC<types.RadioGroupProps> = ({
  children,
  name,
  label,
  defaultValue,
  inputGroupLayoutProps,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });
  const errorMessage = get(errors, `${fieldName}.message`, '') as string;

  return (
    <FormControl as="fieldset" isInvalid={!!errorMessage}>
      <RadioGroup {...rest} onChange={onChange} value={value}>
        {children}
      </RadioGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default FormRadioGroup;
