import React, { useContext } from "react";
import { useMemo } from 'react';
import { routes } from '@lon/suit/configs';
import { HideElement, ScopeType } from '@lon/suit/types';
import { ScopeContext } from '@lon/suit/contexts';
import { getElements } from './duck';
import { types } from './duck';
import { LessonScopeElementList, ScopeElementList } from './components';
import { useParams } from "react-router-dom";

const Section: React.FC<types.SectionProps> = ({
  elementPlacementUrl = routes.scopes.element,
}) => {
  const { elementsBySection, projectType, loading } = useContext(ScopeContext);
  const { sectionId } = useParams<{
    sectionId: string;
  }>();

  const elements = useMemo(
    () => getElements(elementsBySection, sectionId),
    [elementsBySection, sectionId]
  ).filter((element) => !(element.metadata?.hide === HideElement.Yes));

  return projectType === ScopeType.LessonScope ? (
    <LessonScopeElementList
      loading={loading}
      elementPlacementUrl={elementPlacementUrl}
      elements={elements}
    />
  ) : (
    <ScopeElementList
      loading={loading}
      elementPlacementUrl={elementPlacementUrl}
      elements={elements}
    />
  );
};

export default Section;
