const replaceVideoLinks = (observeNode: Node) => {
  const allVideos = document.querySelectorAll('video');
  const processVideo = (video: HTMLVideoElement) => {
    if (!video) {
      return;
    }
    const crossorigin = video.getAttribute('crossorigin');
    if (crossorigin) {
      video.removeAttribute('crossorigin');
      video.src = `${video.src}?t=${Date.now()}`;
    }
  };

  allVideos.forEach((video) => {
    processVideo(video);
  });

  const callback = (mutationsList: MutationRecord[]) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeName === 'VIDEO') {
            processVideo(node as HTMLVideoElement);
          }
        });
      }
    }
  };

  const observer = new MutationObserver(callback);

  observer.observe(observeNode, {
    childList: true,
    subtree: true,
  });

  return observer;
};

export default replaceVideoLinks;
