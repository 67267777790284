import { Box, Checkbox, Flex, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorMessage, Pagination } from '@lon/shared/components';
import { isDevMode } from '@lon/shared/constants';
import { Reports } from '@lon/shared/enums';
import { useQueryParams } from '@lon/shared/hooks';
import {
  GetReportQueryVariables,
  ReportSignRequestInput,
  useGetReportLazyQuery,
  useSignRequestReportMutation,
} from '@lon/shared/requests';
import { FilterTypes } from '@lon/shared/types';
import {
  DEFAULT_ITEMS_PER_PAGE,
  LEARNOSITY_HEAD_CELL_EXPANDABLE,
  LEARNOSITY_MAIN_ROW_BODY,
  LEARNOSITY_MAIN_ROW_HEADER,
  LEARNOSITY_SCORES_BY_TAG_BY_USERS,
  LEARNOSITY_WRAPPER,
  NO_REPORT,
  createReport,
  extractSignedRequest,
  getReport,
  getVariables,
  paginationConfig,
  scores,
} from './duck';
import { ReportSkeleton } from './components';

interface ReportProps {
  data: Record<string, any>;
  type: string;
  filters?: FilterTypes;
  activityIds?: Record<string, any>;
  onChangeEmptyStandard?: (type: boolean) => void;
  listId?: string;
}

const LearnosityReport: FC<ReportProps> = ({
  data,
  type,
  filters,
  activityIds,
  onChangeEmptyStandard,
  listId,
}) => {
  const { assignmentId } = useParams();
  const [params] = useQueryParams();
  const [learnosityError, setLearnosityError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [scoreStyles, setScoreStyles] = useState<string[]>([]);
  const learnosityRef = useRef<HTMLDivElement | null>(null);
  const getId = listId ?? assignmentId;

  const [fetchReport] = useGetReportLazyQuery();
  const [fetchSignRequestReport] = useSignRequestReportMutation();

  const assignmentsToDisplay = useMemo(() => {
    if (data?.length > DEFAULT_ITEMS_PER_PAGE) {
      const pageNo = params.pagination?.page || 1;
      const itemsPerPage =
        params.pagination?.itemsPerPage || DEFAULT_ITEMS_PER_PAGE;
      return data?.slice((pageNo - 1) * itemsPerPage, pageNo * itemsPerPage);
    }
    return data;
  }, [data, params]);

  const requestReport = async (
    variables: GetReportQueryVariables | ReportSignRequestInput
  ) => {
    try {
      if (type !== Reports.RESPONSE_ANALYSIS) {
        return await fetchSignRequestReport({
          variables: {
            params: {
              reportSignedRequestInput: variables as ReportSignRequestInput,
            },
          },
        });
      }
      if (isDevMode()) {
        return await getReport(variables);
      } else {
        return await fetchReport({
          variables: variables as GetReportQueryVariables,
          fetchPolicy: 'cache-first',
        });
      }
    } catch (error: any) {
      setLearnosityError(error?.message ?? NO_REPORT);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { initReport, removeReportContainer } = createReport(
          type,
          setLoading,
          setLearnosityError
        );
        removeReportContainer();
        setLearnosityError('');
        setLoading(true);
        const report = await requestReport(
          getVariables({
            type,
            data: assignmentsToDisplay,
            getId,
            filters,
            activityIds,
          })
        );
        if (!report) {
          throw new Error(NO_REPORT);
        }
        initReport(extractSignedRequest(report));
      } catch (error: any) {
        setLoading(false);
        setLearnosityError(error?.message ?? NO_REPORT);
      }
    })();
  }, [assignmentsToDisplay, type, filters, setLoading, setLearnosityError]);

  useEffect(() => {
    if (type !== Reports.LEARNING_OUTCOMES) return;
    const intervalId = setInterval(() => {
      const reportElement = document.querySelector(
        '.lrn-itemscoresbytagbyuser'
      );
      if (reportElement || !isEmpty(learnosityError)) {
        setLoading(false);
        clearInterval(intervalId);
      } else {
        setLoading(true);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [learnosityError, assignmentsToDisplay]);

  useEffect(() => {
    if (!loading && Reports.LEARNING_OUTCOMES) {
      const intervalId = setInterval(() => {
        if (
          document
            .getElementById(LEARNOSITY_SCORES_BY_TAG_BY_USERS)
            ?.querySelector(
              `.${LEARNOSITY_MAIN_ROW_HEADER} .${LEARNOSITY_HEAD_CELL_EXPANDABLE}`
            )
        ) {
          handleDataRender('lrn-ibtbu-col_lvl0');
          clearInterval(intervalId);
        }
      }, 100);
      return () => {
        clearInterval(intervalId);
      };
    } else {
      return;
    }
  }, [loading, assignmentsToDisplay]);

  const handleDataEvent = (e: any) => {
    e.target.removeEventListener('click', handleDataEvent, false);

    const intervalId = setInterval(() => {
      const errorNode = document
        .getElementById(LEARNOSITY_SCORES_BY_TAG_BY_USERS)
        ?.querySelector<HTMLElement>(
          `.lrn-ibtbu-col-loading .lrn-ibtbu-spinner-text`
        );
      if (errorNode?.innerText === 'Error Loading data...') {
        document
          .getElementById(LEARNOSITY_SCORES_BY_TAG_BY_USERS)
          ?.querySelector('.lrn-ibtbu-col-loading')
          ?.classList?.add('hidden-column');
      }

      if (
        document
          .getElementById(LEARNOSITY_SCORES_BY_TAG_BY_USERS)
          ?.querySelector(
            `.${LEARNOSITY_MAIN_ROW_HEADER} .${LEARNOSITY_HEAD_CELL_EXPANDABLE}`
          )
      ) {
        const body = Array.from(
          document.querySelectorAll(
            `#${LEARNOSITY_WRAPPER} .${LEARNOSITY_MAIN_ROW_BODY} `
          ) as any as HTMLCollectionOf<HTMLElement>
        );

        const expectedClasses: string[] = [
          'lrn-ibtbu-col_lvl0',
          'lrn-ibtbu-col_lvl1',
          'lrn-ibtbu-col_lvl2',
          'lrn-ibtbu-col_lvl3',
          'lrn-ibtbu-col_lvl4',
        ];

        let className = expectedClasses[0];
        if (body.length > 0) {
          expectedClasses.forEach((expectedClass: string) => {
            if (body[0].lastElementChild?.classList?.contains(expectedClass)) {
              className = expectedClass;
            }
          });
        }
        handleDataRender(className);
        clearInterval(intervalId);
      }
    }, 100);
  };

  const handleDataRender = (className: string) => {
    if (onChangeEmptyStandard) {
      onChangeEmptyStandard(false);
    }
    const headNodeList = Array.from(
      document.querySelectorAll(
        `#${LEARNOSITY_WRAPPER} .${LEARNOSITY_MAIN_ROW_HEADER} .${LEARNOSITY_HEAD_CELL_EXPANDABLE}`
      ) as any as HTMLCollectionOf<HTMLElement>
    );
    const bodyNodeList = Array.from(
      document.querySelectorAll(
        `#${LEARNOSITY_WRAPPER} .${LEARNOSITY_MAIN_ROW_BODY} .${className}`
      ) as any as HTMLCollectionOf<HTMLElement>
    );

    const getEmptyStandardIndexNumber = bodyNodeList.flatMap(
      (singleBodyItem, singleBodyIndex) => {
        const childList = Array.from(
          singleBodyItem.querySelectorAll(
            '.lrn-ibtbu-percentage'
          ) as any as HTMLCollectionOf<HTMLElement>
        );
        const getList = childList.filter((x) => x.innerHTML !== '-');
        if (getList.length === 0) {
          return singleBodyIndex;
        } else {
          return [];
        }
      }
    );
    getEmptyStandardIndexNumber.forEach((singleNumber) => {
      headNodeList[singleNumber].classList.add('hidden-column');
      bodyNodeList[singleNumber].classList.add('hidden-column');
    });

    const elementValue = document.getElementById(`${LEARNOSITY_WRAPPER}`);
    elementValue?.addEventListener('click', handleDataEvent);

    if (
      getEmptyStandardIndexNumber.length === headNodeList.length &&
      onChangeEmptyStandard &&
      className !== 'lrn-ibtbu-col_lvl3' &&
      className !== 'lrn-ibtbu-col_lvl4' &&
      className !== 'lrn-ibtbu-col_lvl5'
    ) {
      onChangeEmptyStandard(true);
    }
  };

  return (
    <Flex
      gap={4}
      flexDirection="column"
      w="100%"
      sx={{
        '.hidden-column': {
          width: '0px !important',
          minWidth: '0px !important',
          padding: '0px !important',
          border: '0 !important',
          visibility: 'hidden !important',
        },
      }}
    >
      <Flex
        w="100%"
        h="100vh"
        overflow="auto"
        backgroundColor="white"
        borderRadius={6}
      >
        <Box w="100%">
          <Box id="wrapper" position="sticky" className={scoreStyles.join(' ')}>
            {type !== Reports.RESPONSE_ANALYSIS && (
              <VStack spacing={5} py={4} alignItems="flex-start">
                {scores.map((score, index) => (
                  <Checkbox
                    key={index}
                    onChange={({ target }) => {
                      if (target.checked) {
                        setScoreStyles([...scoreStyles, target.value]);
                      } else {
                        setScoreStyles((prev) =>
                          prev.filter((item) => item !== target.value)
                        );
                      }
                    }}
                    value={score.value}
                  >
                    {score.label}
                  </Checkbox>
                ))}
              </VStack>
            )}
            <Box id="learnosity-wrapper" ref={learnosityRef}></Box>
            {loading && <ReportSkeleton type={type} />}
            {!isEmpty(learnosityError) && (
              <ErrorMessage fullWidth title={learnosityError} actions={<></>} />
            )}
          </Box>
          {data?.length > DEFAULT_ITEMS_PER_PAGE && (
            <Pagination
              total={data?.length || 0}
              initialPageSize={DEFAULT_ITEMS_PER_PAGE}
              paginationConfig={paginationConfig}
              recordsWrapperProps={{
                visibility: 'hidden',
                'aria-hidden': true,
              }}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default LearnosityReport;
