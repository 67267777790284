import { filterConstants } from '../../../../duck';
import { Box } from '@chakra-ui/react';
import React from 'react';
import { ContentLoader } from '@lon/shared/components';

const ButtonSkeleton: React.FC = () => (
  <Box
    data-testid="filters-skeleton"
    height={`${filterConstants.BUTTON_HEIGHT}px`}
    borderRadius="base"
  >
    <ContentLoader dark width="100%" height="100%">
      <rect width="100%" height="100%" />
    </ContentLoader>
  </Box>
);

export default ButtonSkeleton;
