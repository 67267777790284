import qs from 'qs';
import prepareStringify from '../prepare-stringify';

type GetQuery = (
  params: any,
  prepare?: (props: any) => any,
  stringifyOptions?: qs.IStringifyOptions
) => string;

const getQuery: GetQuery = (
  params,
  prepare = prepareStringify,
  stringifyOptions
) => `${qs.stringify(prepare(params), stringifyOptions)}`;

export default getQuery;
