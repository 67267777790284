import { chakra } from '@chakra-ui/react';
import React from 'react';
import { Props } from './duck/types';

const Asterisk: React.FC<Props> = ({ color = 'danger.700' }) => (
  <chakra.abbr
    color={color}
    title="Required"
    pl={2}
    fontSize="1.125rem"
    lineHeight="1rem"
    textDecoration="none !important"
    fontStyle="none !important"
    _after={{
      content: "'⋯'",
      position: 'relative',
      right: '1em',
      top: '0.4em',
      width: 'auto',
      height: 'auto',
      fontSize: '0.7em',
      zIndex: 1,
    }}
  >
    *
  </chakra.abbr>
);

export default Asterisk;
