import { Box, IconButton, InputGroup, Text } from '@chakra-ui/react';
import { ReactElement, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, TextInput } from '@lon/shared/components';
import { useQueryParams, useThemeStyleValue } from '@lon/shared/hooks';

const ManualSearch: React.FC<{ label?: ReactElement | string }> = ({
  label,
}) => {
  const [{ search, pagination, ...params }, setParams] = useQueryParams();
  const { t } = useTranslation();
  const formProviderProps = useForm<{
    search: string;
  }>();

  const handleSearch: SubmitHandler<{
    search: string;
  }> = (e) => {
    const initialPagination = pagination && { ...pagination, page: 1 };

    setParams({
      ...params,
      search: e.search || undefined,
      ...(initialPagination ? { pagination: initialPagination } : {}),
    });
  };

  useEffect(() => {
    const value = formProviderProps.getValues('search');

    if (search === value) {
      return;
    }

    formProviderProps.setValue('search', search || '');
  }, [search]);

  return (
    <>
      <FormProvider {...formProviderProps}>
        <Box
          as="form"
          data-testid="manual-search"
          onSubmit={formProviderProps.handleSubmit(handleSearch)}
        >
          <InputGroup>
            <TextInput
              w="full"
              type="search"
              name="search"
              _placeholder={{
                fontStyle: 'italic',
              }}
              _placeholderShown={{ textOverflow: 'ellipsis' }}
              label={
                label ? (
                  label
                ) : (
                  <Text variant="n1" color="white">
                    {t('searchPanel.searchLabel')}
                  </Text>
                )
              }
              placeholder={t('searchPanel.searchPlaceholder')}
              sx={{
                paddingInlineEnd: 'calc(var(--chakra-space-1) * 13)',
                _focus: {
                  boxShadow: useThemeStyleValue('focus.blue', 'focus.teal'),
                  borderColor: useThemeStyleValue(
                    'primary.800',
                    'secondary.1000'
                  ),
                },
              }}
              inputRightElementProps={{
                children: (
                  <IconButton
                    type="submit"
                    backgroundColor={useThemeStyleValue(
                      'primary.400',
                      'secondary.1000'
                    )}
                    borderRadius="0px 3px 3px 0px"
                    variant={useThemeStyleValue('solidDark', 'outline')}
                    color="white"
                    title={t('searchPanel.manualSearchButtonLabel')}
                    aria-label={t('searchPanel.manualSearchButtonLabel')}
                    pointerEvents="auto"
                  >
                    <Icon name="search-outlined" />
                  </IconButton>
                ),
                top: 0,
                width: '44px',
                height: '44px',
              }}
            />
          </InputGroup>
        </Box>
      </FormProvider>
    </>
  );
};

export default ManualSearch;
