import { isSameDay, isWithinInterval } from 'date-fns';

export const isCurrentDateInRangeOrEqual = (
  startDate: Date,
  endDate: Date
): boolean => {
  const currentDate = new Date();
  return (
    isSameDay(currentDate, startDate) ||
    isSameDay(currentDate, endDate) ||
    isWithinInterval(currentDate, { start: startDate, end: endDate })
  );
};
