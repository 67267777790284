import { MenuDivider, MenuItem, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { ArchiveProps } from './duck';

const Archive: React.FC<ArchiveProps> = ({ isArchived }) => {
  const { t } = useTranslation();

  return (
    <>
      <MenuDivider color="secondary.200" />
      <Tooltip
        variant="dark"
        label={
          isArchived
            ? t('assignments.unarchiveMenuItemTooltip')
            : t('assignments.archiveMenuItemTooltip')
        }
      >
        <MenuItem
          //TODO enable when DA actions have been developed
          isDisabled={true}
          gap={3}
          py={2}
          aria-label={
            isArchived
              ? t('assignments.unarchiveMenuItemTitle')
              : t('assignments.archiveMenuItemTitle')
          }
        >
          <Icon name={isArchived ? 'unarchive' : 'archived'} size="lg" />
          <Text as="span" variant="s2">
            {isArchived
              ? t('assignments.unarchiveMenuItemTitle')
              : t('assignments.archiveMenuItemTitle')}
          </Text>
        </MenuItem>
      </Tooltip>
    </>
  );
};

export default Archive;
