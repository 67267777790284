import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { t } from 'i18next';
import React from 'react';
import { object, ref, string } from 'yup';
import { ERROR_CODES, specialCharacters } from '@lon/shared/constants';
import { Permission } from '@lon/shared/types';
import { getValidationMessages } from '@lon/shared/utils';
import { SubmitHandler, SubmitLoginHandler } from './types';

export const savedNewRole = JSON.parse(
  localStorage.getItem('newRole') as string
);

export const checkIsAdmin = (
  persmissions: Permission[] | undefined
): boolean => {
  if (!persmissions) {
    return false;
  }

  return persmissions.includes('PERMISSION_ADMIN_SUIT');
};

export const handleSubmit: SubmitHandler<any> = async (
  execute,
  successCallback,
  toast,
  values
) => {
  const {
    currentPassword = '',
    newPassword = '',
    repeatedPassword = '',
  } = values;

  try {
    await execute({
      variables: {
        input: { currentPassword, newPassword, repeatedPassword },
      },
    });
    successCallback();
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      const { graphQLErrors } = e;
      const valuesKeys = Object.keys(values);

      if (graphQLErrors) {
        const formErrors = (graphQLErrors as GraphQLErrors).filter((error) => {
          const [, ...pathArr] = error.path || [];
          const fieldName = pathArr.join('.');
          return valuesKeys.includes(fieldName);
        });
        if (formErrors.length) {
          graphQLErrors.forEach((error) =>
            toast({
              title: t(
                'updatePassword.invalidPasswordTitle'
              ) as React.ReactNode,
              description: (error?.path?.find((v: any) =>
                v?.includes('currentPassword')
              )
                ? t('updatePassword.invalidCurrentPasswordDescription')
                : t(
                    'updatePassword.invalidPasswordDescription'
                  )) as React.ReactNode,
              variant: 'error-light',
              isClosable: true,
            })
          );
        } else if (formErrors.length !== graphQLErrors.length) {
          // current backend may also return error that can't be mapped to the formik field
          // as it uses another layer of validation with the field names of create user from
          // user-management. In such cases we show a single alert
          graphQLErrors.forEach((error) =>
            toast({
              title: t(
                'updatePassword.invalidPasswordTitle'
              ) as React.ReactNode,
              description: t(
                `systemMessages.${
                  ERROR_CODES[
                    error?.extensions?.code as keyof typeof ERROR_CODES
                  ]
                }`
              ) as React.ReactNode,
              variant: 'error-light',
              isClosable: true,
            })
          );
        }
      }
    } else {
      toast({
        title: t('updatePassword.invalidPasswordTitle') as React.ReactNode,
        description: t(
          'updatePassword.invalidPasswordDescription'
        ) as React.ReactNode,
        variant: 'error-light',
        isClosable: true,
      });
    }
  }
};

export const getPasswordValidation = (isAdmin: boolean) => {
  const min = isAdmin ? 16 : 12;

  return string()
    .required(getValidationMessages().required)
    .matches(/^(?!.* )/, getValidationMessages().noSpaces)
    .min(min, getValidationMessages(min).passwordLength)
    .matches(/\d+/, getValidationMessages().atLeastOneDigit)
    .matches(/[a-z]+/, getValidationMessages().atLeastOneLowerCase)
    .matches(
      specialCharacters,
      getValidationMessages().atLeastOneSpecialCharacter
    )
    .matches(/[A-Z]+/, getValidationMessages().atLeastOneUpperCase);
};

export const validateNewPassword = (
  newPassword: string | undefined,
  min: number
) => {
  const result = [];
  if (!newPassword?.match(/^(?!.* )/)) {
    result.push(1);
  }
  if (!newPassword?.match(min === 12 ? /^.{12,}$/ : /^.{16,}$/)) {
    result.push(min === 12 ? 2 : 8);
  }
  if (!newPassword?.match(/\d+/)) {
    result.push(3);
  }
  if (!newPassword?.match(/[A-Z]+/)) {
    result.push(4);
  }
  if (!newPassword?.match(/[a-z]+/)) {
    result.push(5);
  }
  if (!newPassword?.match(specialCharacters)) {
    result.push(9);
  }
  return result;
};

export const validationSchema = (isAdmin: boolean, isLogin?: boolean) => {
  const requiredFields = {
    newPassword: getPasswordValidation(isAdmin),
    repeatedPassword: string()
      .trim()
      .required(
        t('validationMessages.requiredField', {
          fieldName: 'Confirm Password',
        })
      )
      .oneOf(
        [ref('newPassword'), null],
        getValidationMessages().notMatchingPasswords
      )
      .default(''),
  };
  return isLogin
    ? object(requiredFields)
    : object({
        currentPassword: string()
          .trim()
          .required(
            t('validationMessages.requiredField', {
              fieldName: 'Current Password',
            })
          )
          .default(''),
        ...requiredFields,
      });
};

export const getValidation = (isAdmin: boolean, isLogin?: boolean) => ({
  validationSchema,
  defaultValues: validationSchema(isAdmin, isLogin).cast({}),
});

export const handleLoginPasswordReset: SubmitLoginHandler = async (
  values,
  params,
  executeCreatePasswordRecovery,
  toast,
  navigate,
  districtActivation
) => {
  try {
    const response = await executeCreatePasswordRecovery({
      variables: {
        input: {
          newPassword: values.newPassword as string,
          token: params.token,
          districtActivation: !!districtActivation,
        },
      },
    });
    if (response?.data?.createPasswordRecovery) {
      toast({
        title: t('updatePassword.successReset') as React.ReactNode,
        variant: 'success-light',
        isClosable: true,
      });
      if (
        params['district-id'] &&
        params.state &&
        params?.['district-activation']
      ) {
        navigate(`/login?state=${params.state}`, {
          state: {
            districtId: params['district-id'],
            stateValue: params.state,
            districtActivation: params?.['district-activation'] === 'true',
          },
        });
      } else {
        navigate('/login');
      }
    } else {
      toast({
        title: t('updatePassword.invalidPasswordTitle') as React.ReactNode,
        description: t('updatePassword.generalError') as React.ReactNode,
        variant: 'error-light',
        isClosable: true,
      });
    }
  } catch (e: unknown) {
    if (e instanceof ApolloError) {
      const { graphQLErrors } = e;
      const valuesKeys = Object.keys(values);

      if (graphQLErrors) {
        const formErrors = (graphQLErrors as GraphQLErrors).filter((error) => {
          const [, ...pathArr] = error.path || [];
          const fieldName = pathArr.join('.');
          return valuesKeys.includes(fieldName);
        });
        if (formErrors.length) {
          graphQLErrors.forEach((error) =>
            toast({
              title: t(
                'updatePassword.invalidPasswordTitle'
              ) as React.ReactNode,
              description: (error?.path?.find((v: any) =>
                v?.includes('currentPassword')
              )
                ? t('updatePassword.invalidCurrentPasswordDescription')
                : t(
                    'updatePassword.invalidPasswordDescription'
                  )) as React.ReactNode,
              variant: 'error-light',
              isClosable: true,
            })
          );
        } else if (formErrors.length !== graphQLErrors.length) {
          // current backend may also return error that can't be mapped to the formik field
          // as it uses another layer of validation with the field names of create user from
          // user-management. In such cases we show a single alert
          graphQLErrors.forEach((error) => {
            toast({
              title: t(
                'updatePassword.invalidPasswordTitle'
              ) as React.ReactNode,
              description: t(
                `systemMessages.${
                  ERROR_CODES[
                    error?.extensions?.code as keyof typeof ERROR_CODES
                  ]
                }`
              ) as React.ReactNode,
              variant: 'error-light',
              isClosable: true,
            });
          });
        }
      }
    } else {
      toast({
        title: t('updatePassword.invalidPasswordTitle') as React.ReactNode,
        description: t(
          'updatePassword.invalidPasswordDescription'
        ) as React.ReactNode,
        variant: 'error-light',
        isClosable: true,
      });
    }
  }
};
