import { camelCase } from 'lodash-es';
import React, { Suspense, useMemo } from 'react';
// import { lazyWithRetry } from '@lon/shared/utils';
import { Wrapper } from './Icon.styles';
import { types } from './duck';

export const IconWrapper: React.FC<types.IconProps> = ({
  name,
  size,
  className = '',
  iconClassName = '',
  onClick,
  width,
  height,
  icons,
  ...props
}) => {
  const CurrentIcon = useMemo(() => icons[camelCase(name)], [name]);

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const CurrentIcon = useMemo(
  //   () =>
  //     lazyWithRetry(
  //       () => import(`libs/shared/assets/src/icons/${[camelCase(name)]}`),
  //         `Icon-${name}`
  //     ),
  //   [name]
  // );

  if (!CurrentIcon) {
    return null;
  }

  return (
    <Wrapper
      className={className}
      onClick={onClick}
      size={size}
      width={width}
      height={height}
    >
      <Suspense fallback={null}>
        <CurrentIcon
          aria-hidden="true"
          focusable={!!onClick}
          className={iconClassName}
          width={width}
          {...props}
        />
      </Suspense>
    </Wrapper>
  );
};
