// TODO: verify styles for all gradings
const generateGradeStyles = (grade: number | null) => {
  if (grade === null) {
    return {
      color: 'primary.800',
      backgroundColor: 'primary.30',
    };
  }
  switch (grade !== null) {
    case grade >= 90:
      return {
        backgroundColor: 'success.800',
        color: 'white',
      };
    case grade >= 70:
      return {
        backgroundColor: 'warning.extraLight',
        color: 'warning.text',
      };
    default:
      return {
        backgroundColor: 'danger.800',
        color: 'white',
      };
  }
};

export default generateGradeStyles;
