import { Box, TableCaptionProps } from '@chakra-ui/react';

const TableCaption: React.FC<TableCaptionProps> = ({ children, ...rest }) => {
  return (
    <Box
      placement="top"
      mt={0}
      py={0}
      px={0}
      borderBottom="1px solid"
      borderColor="secondary.200"
      tabIndex={0}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default TableCaption;
