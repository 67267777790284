import { Student } from '../../duck/types';
import { Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Message, ShowMore } from '@lon/shared/components';

const IneligibleStudentsMessage: React.FC<{
  ineligibleStudents: Student[];
}> = ({ ineligibleStudents = [] }) => {
  const { t } = useTranslation();
  return (
    <Message
      variant="warning"
      my="4"
      css={{
        '.chakra-alert__desc': { display: 'block', padding: 0 },
      }}
      message={
        <>
          <Text variant="s3">
            {t('assignments.multpleReturnAssignmentWarning')}
          </Text>
          <ShowMore mt="4" startingHeight="80px">
            {ineligibleStudents.map((student) => (
              <Tag
                variant="showMoreOutline"
                borderColor="calendar.yellow.800"
                size="txs"
              >
                {`${student?.firstName} ${student?.lastName}`}
              </Tag>
            ))}
          </ShowMore>
        </>
      }
    />
  );
};

export default IneligibleStudentsMessage;
