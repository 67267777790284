import { PathPattern, matchPath } from 'react-router-dom';

export const isActive = (
  pathname: string,
  pathsToHighlight: (string | (PathPattern & { regExp?: RegExp }))[] = []
): boolean => {
  return pathsToHighlight.some((pattern) => {
    if (typeof pattern === 'object' && pattern.regExp) {
      return pattern.regExp.test(pattern.path);
    }

    const match = matchPath(pattern, pathname);

    return match !== null;
  });
};
