// @ts-nocheck
import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import { types, utils } from './duck';

export const List = <T extends {}>({
  id,
  grid,
  mapKey,
  renderSkeleton,
  noContent,
  renderItem,
  className,
  data = [],
  loading = false,
  skeletonsCount = 1,
  innerRef,
  listProps,
}: types.ListProps<T>): React.ReactElement => {
  if (loading && typeof loading !== 'boolean') {
    return loading;
  }
  if (!loading && noContent && data.length === 0) {
    return noContent;
  }
  return (
    <Grid
      as="ul"
      id={id}
      rowGap={grid === '1' ? 1 : 4}
      columnGap={6}
      gridTemplateColumns={`repeat(auto-fill, minmax(${utils.gridWidth[grid]}, 1fr))`}
      position="relative"
      mb={4}
      className={className}
      ref={innerRef}
      listStyleType="none"
      {...listProps}
    >
      {loading
        ? new Array(skeletonsCount).fill(renderSkeleton()).map((item) => (
            <GridItem as="li" width="100%" key={Math.random()}>
              {item}
            </GridItem>
          ))
        : data.map((item, index) => (
            <GridItem as="li" width="100%" key={mapKey(item, index)}>
              {renderItem(item, index)}
            </GridItem>
          ))}
    </Grid>
  );
};

export default List;
