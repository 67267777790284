/* eslint-disable */
import window from 'global/window';
import videojs from 'video.js';

/**
 * Set up sprite thumbnails for a player.
 *
 * @function spriteThumbs
 * @param {Player} player
 *        The current player instance.
 * @param {Plugin} plugin
 *        The current spriteThumbnails plugin instance.
 * @param {Object} options
 *        Plugin configuration options.
 */
const spriteThumbs = (player, plugin, options) => {
  let url;
  let height;
  let width;
  let downlink;
  let cached;
  let dl;

  const navigator = window.navigator;
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;

  const dom = videojs.dom || videojs;
  const merge = videojs.mergeOptions;

  const sprites = {};
  const defaultState = merge({}, plugin.state);

  const controls = player.controlBar;

  // default control bar component tree is expected
  // https://docs.videojs.com/tutorial-components.html#default-component-tree
  const progress = controls && controls.progressControl;
  const seekBar = progress && progress.seekBar;
  const mouseTimeTooltip =
    seekBar && seekBar.mouseTimeDisplay && seekBar.mouseTimeDisplay.timeTooltip;
  const tooltipEl = mouseTimeTooltip && mouseTimeTooltip.el();
  const tooltipStyleOrig = tooltipEl && tooltipEl.style;

  const resetMouseTooltip = () => {
    if (tooltipEl && tooltipStyleOrig) {
      tooltipEl.style = tooltipStyleOrig;
    }
  };

  const hijackMouseTooltip = (evt) => {
    const sprite = sprites[url];
    const imgWidth = sprite.naturalWidth;
    const imgHeight = sprite.naturalHeight;

    if (sprite.complete && imgWidth && imgHeight) {
      const seekBarEl = seekBar.el();

      const videoDuration = player.duration();
      /* By default a thumbnail is generated every 5 seconds.
       *  If there are more than 50 thumbnails, the video duration is divided into equal parts. */
      const interval = videoDuration / 5 > 50 ? videoDuration / 50 : 5;

      const THUMBNAIL_WIDTH = 1920;
      const THUMBNAIL_HEIGHT = 1080;

      let position = dom.getPointerPosition(seekBarEl, evt).x * videoDuration;

      position = position / interval;

      const responsive = options.responsive;

      const scaleFactor = responsive ? 1 / responsive : 1;

      const columns = imgWidth / THUMBNAIL_WIDTH;
      const scaledWidth = THUMBNAIL_WIDTH * scaleFactor;
      const scaledHeight = THUMBNAIL_HEIGHT * scaleFactor;
      const cleft = Math.floor(position % columns) * -scaledWidth;
      const ctop = Math.floor(position / columns) * -scaledHeight;
      const bgSize = `${imgWidth * scaleFactor}px ${imgHeight * scaleFactor}px`;
      const controlsTop = dom.findPosition(controls.el()).top;
      const seekBarTop = dom.findPosition(seekBarEl).top;
      const topOffset =
        -scaledHeight - Math.max(0, seekBarTop - controlsTop) - 40;

      const tooltipStyle = {
        backgroundImage: `url("${url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `${cleft}px ${ctop}px`,
        backgroundSize: bgSize,
        top: `${topOffset}px`,
        color: 'black',
        border: '4px solid rgba(245, 247, 247)',
        borderBottomWidth: '0px',
        fontSize: '1rem',
        borderRadius: 'var(--chakra-radii-md)',
        width: `${scaledWidth + 8}px`,
        height: `${scaledHeight + 28}px`,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
      };

      Object.keys(tooltipStyle).forEach((key) => {
        tooltipEl.style[key] = tooltipStyle[key];
      });

      tooltipEl.dataset.time = tooltipEl.innerText;
    }
  };

  const init = () => {
    // if present, merge source config with current config
    const plugName = plugin.name;
    const spriteSource = player.currentSources().filter((source) => {
      return source.hasOwnProperty(plugName);
    })[0];
    const spriteOpts = spriteSource && spriteSource[plugName];

    if (spriteOpts) {
      plugin.setState(defaultState);
      options = merge(options, spriteOpts);

      // url from source always takes precedence, even if empty
      options.url = spriteOpts.url;
    }

    // update script variables
    url = options.url;
    height = options.height;
    width = options.width;
    downlink = options.downlink;
    dl = !connection || connection.downlink >= downlink;
    cached = !!sprites[url];

    plugin.setState({
      ready: !!(mouseTimeTooltip && width && height && url && (cached || dl)),
      diagnostics: true,
    });
  };

  plugin.on('statechanged', () => {
    const pstate = plugin.state;
    const spriteEvents = ['mousemove', 'touchmove'];
    const log = plugin.log || videojs.log;
    const debug = log.debug || log;

    if (pstate.ready) {
      let msg = `loading ${url}`;

      debug('ready to show thumbnails');
      if (!cached) {
        sprites[url] = dom.createEl('img', {
          src: url,
        });
      } else {
        msg = `re${msg}`;
      }
      debug(msg);
      progress.on(spriteEvents, hijackMouseTooltip);
    } else {
      progress.off(spriteEvents, hijackMouseTooltip);
      resetMouseTooltip();
      if (pstate.diagnostics) {
        debug('resetting');
        ['url', 'width', 'height'].forEach((key) => {
          if (!options[key]) {
            log(`no thumbnails ${key} given`);
          }
        });
        if (connection && !dl) {
          log.warn(`connection.downlink < ${downlink}`);
        }
      }
    }
  });

  // load configuration from a source
  player.on('loadstart', init);

  // load plugin configuration
  init();
  player.addClass('vjs-sprite-thumbnails');
};

export default spriteThumbs;
