export const orderArray = (
  firstArray: string[] = [],
  secondArray: string[] = []
): string[] => {
  const orderedArray: string[] = [];

  for (const id of firstArray) {
    const index = secondArray?.indexOf(id);
    if (index !== -1) {
      orderedArray.push(secondArray?.[index]);
    } else {
      orderedArray.push('');
    }
  }

  return orderedArray;
};
