import {
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { types } from './duck';

const FormCheckboxGroup: React.FC<types.CheckboxGroupProps> = ({
  children,
  name,
  label,
  defaultValue,
  formLabelProps,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onChange, name: fieldName, value },
    formState: { errors },
  } = useController({ name, control, defaultValue });
  const errorMessage = get(errors, `${fieldName}.message`, '') as string;

  return (
    <FormControl as="fieldset" isInvalid={!!errorMessage}>
      {label && (
        <FormLabel as="legend" {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      <CheckboxGroup {...rest} onChange={onChange} value={value}>
        {children}
      </CheckboxGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default FormCheckboxGroup;
