import {
  Classes,
  GradeLevels,
  IndividualStudents,
  Schools,
  Subgroups,
} from '../tabs';
import { t } from 'i18next';
import { Tab, TabKey } from './types';

export const TABS: Tab[] = [
  {
    key: TabKey.classes,
    tab: t('createAssignmentDrawer.classesTab'),
    TabPanel: Classes,
  },
  {
    key: TabKey.subgroups,
    tab: t('createAssignmentDrawer.subgroupsTab'),
    TabPanel: Subgroups,
  },
  {
    key: TabKey.individualStudents,
    tab: t('createAssignmentDrawer.individualStudentsTab'),
    TabPanel: IndividualStudents,
  },
];

export const DISTRICT_ASSESSMENT_TABS: Tab[] = [
  {
    key: TabKey.gradeLevels,
    tab: t('createAssignmentDrawer.gradeLevelsTab'),
    TabPanel: GradeLevels,
  },
  {
    key: TabKey.schools,
    tab: t('createAssignmentDrawer.schoolsTab'),
    TabPanel: Schools,
  },
];
