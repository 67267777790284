import { DistrictReport } from '../../duck';
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  assignmentDetails?: DistrictReport;
  onClose: () => void;
};

const MoreDetails = ({ isOpen, onClose, assignmentDetails }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      variant="base"
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        width="700px"
        maxWidth="700px"
        borderRadius={20}
        mt="auto"
        mb="auto"
        py={6}
      >
        <ModalHeader borderBottom={0} px={12}>
          {t('reportsDashboard.districtAssessmentsReports.assessmentDetails')}
        </ModalHeader>
        <ModalCloseButton mt={6} />
        <ModalBody display="flex" flexDirection="column" p={4}>
          <Divider m={0} p={0} />

          <Text
            px={8}
            variant="n1"
            boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.1)"
            py={6}
          >
            {assignmentDetails?.assessment}
          </Text>
          <Flex mt={6} flexDirection="column">
            <Text px={8} variant="s1">
              {t(
                'reportsDashboard.districtAssessmentsReports.assessmentDetailsType',
                { type: assignmentDetails?.type }
              )}
            </Text>
            <Flex px={8} mt={4} gap={2} wrap="wrap">
              {assignmentDetails?.assignees?.map((assignee) => (
                <Tag
                  variant="secondary"
                  bg="primary.30"
                  px={3}
                  borderRadius="xl"
                  key={assignee}
                  w="auto"
                  color="primary.700"
                >
                  {assignee}
                </Tag>
              ))}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MoreDetails;
