import React from 'react';
import { AssignmentFormContextProps } from './types';

export const AssignmentFormContext =
  React.createContext<AssignmentFormContextProps>({
    immediatelyShareResults: false,
    setImmediatelyShareResults: () => {},
    withOtherAssignment: false,
    isAssessment: false,
    isTeacherAssessment: false,
    isDistrictAssessment: false,
    isReadOnlyMode: false,
    isEditAssignmentMode: false,
    hasManuallyGradableQuestions: false,
    isLeaderSuit: false,
  });
