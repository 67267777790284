import { Skeleton, Stack } from '@chakra-ui/react';

const PlanDrawerSkeleton: React.FC = () => (
  <Stack gap={8} mt={8} mx={8} data-testid="plan-drawer-skeleton">
    <Skeleton width="550" height="70" />
    <Skeleton width="550" height="70" />
    <Skeleton width="550" height="70" />
    <Skeleton width="550" height="70" />
    <Skeleton width="550" height="70" />
    <Skeleton width="550" height="70" />
  </Stack>
);

export default PlanDrawerSkeleton;
