import { Link as RouterLink } from '../../../../../../../../../../../link';
import { navigationTypes } from '../../../../duck';
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Image,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { isDevMode } from '@lon/shared/constants';

const SectionCard: React.FC<navigationTypes.SectionCardPropTypes> = ({
  sectionTitle,
  sectionElements,
  handleCloseDrawer,
  iconPath,
  iconLoading,
}) => {
  const [imgError, setImgError] = useState<boolean>(false);
  const {
    scopeId = 0,
    unitId,
    projectId = 0,
  } = useParams<{ [key: string]: string }>();

  const baseUrl = isDevMode()
    ? `https://${window.location.hostname.replace('local.', '')}`
    : '';

  return (
    <Card w="full" pt="4" pb="8">
      <CardHeader py="4" px="8">
        <Flex gap="6" alignItems="center">
          <Flex w="8">
            {!iconLoading ? (
              !imgError &&
              iconPath && (
                <Image
                  src={baseUrl + iconPath}
                  maxH="8"
                  maxW="8"
                  onError={() => {
                    if (!imgError) {
                      setImgError(true);
                    }
                  }}
                />
              )
            ) : (
              <Spinner variant="dark" />
            )}
          </Flex>
          <Text variant="h6">{sectionTitle}</Text>
        </Flex>
      </CardHeader>
      <Divider h="1px" variant="solid" my="4" />
      <CardBody py="0" px="8">
        <Flex direction="column" gap="4">
          {sectionElements?.map((element) => {
            const elementPath = generatePath(routes.scopes.element, {
              scopeId,
              unitId,
              sectionId: element.sectionId,
              elementId: element.identifier || 0,
              projectId,
            });

            return (
              <Link
                key={element.identifier}
                withoutStyles
                w="fit-content"
                minH="6"
                color="primary.800"
                textDecoration="underline"
                as={RouterLink}
                to={elementPath}
                onClick={handleCloseDrawer}
              >
                {element?.metadata?.elementName || ''}
              </Link>
            );
          })}
        </Flex>
      </CardBody>
    </Card>
  );
};

export default SectionCard;
