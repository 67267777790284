import { Switch as ChakraSwitch, Flex, FormLabel } from '@chakra-ui/react';
import { memo } from 'react';
import { useFilterToggle } from '@lon/suit/hooks';
import { SwitchProps } from './duck';

const Switch: React.FC<SwitchProps> = ({ filterGroup, filterName }) => {
  const [{ on }, toggle] = useFilterToggle(filterGroup, filterName, 'scopes');

  return (
    <Flex alignItems="center">
      <ChakraSwitch
        variant="primary"
        size="sm"
        id={`${filterGroup}_${filterName}`}
        isChecked={on}
        onChange={toggle}
      />
      <FormLabel
        htmlFor={`${filterGroup}_${filterName}`}
        mb="0"
        color="white"
        fontSize="0.875rem"
        lineHeight="1.375rem"
        fontWeight={400}
        cursor="pointer"
        px={3}
      >
        {filterName}
      </FormLabel>
    </Flex>
  );
};

export default memo(Switch);
