export const findChangedObjectIndexes = (
  preparedCurrentLayout: { [key: string]: number | string }[],
  preparedWidgets: { [key: string]: number | string }[]
) => {
  return preparedCurrentLayout
    .map((currentObj: { [key: string]: number | string }, index: number) => {
      const widgetObj = preparedWidgets[index];

      if (
        currentObj.x !== widgetObj.x ||
        currentObj.y !== widgetObj.y ||
        currentObj.w !== widgetObj.w ||
        currentObj.h !== widgetObj.h
      ) {
        return index;
      }
      return null;
    })
    .filter((v) => typeof v === 'number');
};
