import { Box, Skeleton as ChakraSkeleton, Flex } from '@chakra-ui/react';
import React from 'react';

const Skeleton: React.FC = () => (
  <Box data-testid="plan-element-list-skeleton" p={2}>
    <Flex direction="column" justify-content="space-evenly" gap={2}>
      <ChakraSkeleton h="40px" />
      <ChakraSkeleton h="30px" />
      <ChakraSkeleton h="30px" />
    </Flex>
  </Box>
);

export default Skeleton;
