import { FormattedStudents } from '../../duck';
import { Box, FormControl, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormCheckbox } from '@lon/shared/components';
import { filterNullable } from '@lon/shared/utils';

const StudentsList: React.FC<{
  notAddedStudents: FormattedStudents;
  gradeLevel: string;
}> = ({ notAddedStudents, gradeLevel }) => {
  const { t } = useTranslation();
  const { setValue, trigger, watch } = useFormContext();
  const studentIds = watch('studentIds');

  const studentsCheckedInOtherGradeLevels = Object.keys(studentIds)
    .filter((item) => item !== gradeLevel)
    .map((gradeLevel) =>
      Object.entries(studentIds[gradeLevel] || {})
        .map(([key, value]) => {
          if (value === true) {
            return key;
          }
          return null;
        })
        .filter(filterNullable)
    )
    .flat();

  return (
    <Box borderTop="1px solid" borderTopColor="secondary.200" py={6} px={8}>
      <Text variant="n1">{t('addStudents.gradeTitle')}</Text>
      <Text variant="n3" isTruncated mt={4}>
        {gradeLevel === 'K' ? 'Kindergarten' : gradeLevel}
      </Text>

      <Box
        mt={6}
        borderRadius="md"
        border="1px solid"
        borderColor="secondary.200"
        overflow="hidden"
      >
        <Text px={4} py={3} bg="secondary.50">
          {t('addStudents.studentsToAdd')}
        </Text>

        <VStack gap={1} alignItems="stretch" px={4} py={3}>
          {notAddedStudents?.map((student) => (
            <FormControl key={student?.id as string}>
              <FormCheckbox
                size="md"
                key={student?.id}
                name={`studentIds.${gradeLevel}.${student?.id}`}
                isDisabled={
                  !!studentsCheckedInOtherGradeLevels.find(
                    (item) => item === student?.id
                  )
                }
                value={student?.id}
                label={
                  <Text
                    as="span"
                    variant="n3"
                    color="primary.800"
                    wordBreak="break-all"
                  >
                    {`${student?.lastName}, ${student?.firstName}`}
                  </Text>
                }
                onInputChange={(e) => {
                  const newValue = {
                    ...studentIds,
                    [gradeLevel]: {
                      ...studentIds[gradeLevel],
                      [student?.id]: e.target.checked,
                    },
                  };

                  setValue(`studentIds`, newValue, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  });
                  trigger('studentIds');
                }}
              />
            </FormControl>
          ))}
        </VStack>
      </Box>
    </Box>
  );
};

export default StudentsList;
