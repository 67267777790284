import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon, Message } from '@lon/shared/components';
import { types } from './duck';

const IntroMessage: React.FC<types.IntroMessageProps> = ({ endDate }) => {
  const { t } = useTranslation();

  return (
    <Message
      variant="info"
      icon={<Icon name="clock-detailed" />}
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      message={
        <Text variant="s3">
          {t('reopenAssignmentModal.introMessage', {
            month: new Intl.DateTimeFormat('en-US', {
              month: 'long',
            }).format(new Date(endDate)),
            day: new Date(endDate).getDate(),
          })}
        </Text>
      }
    />
  );
};

export default IntroMessage;
