import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { types } from './duck';

const AnswersSwitch: React.FC<types.SwitchAnswersProps> = ({
  name,
  label,
  wrapperProps,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onBlur, onChange, name: fieldName, value },
  } = useController({ name, control });

  return (
    <FormControl {...wrapperProps} isDisabled={rest?.isDisabled}>
      {label && <FormLabel>{label}</FormLabel>}
      <Switch
        name={fieldName}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        isChecked={value}
        {...rest}
      />
    </FormControl>
  );
};

export default AnswersSwitch;
