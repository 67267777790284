import { ValidatePageNumber } from './types';

export const validatePageNumber = ({
  pageNumber,
  max,
  min = 1,
  successCallback,
}: ValidatePageNumber) => {
  let page = Number(pageNumber);

  if (page < min) {
    page = min;
  } else if (page > max) {
    page = max;
  }

  if (page) {
    successCallback(page);
  }
};
