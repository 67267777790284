import { SyncError } from '../sync-error';
import { useRouteError } from 'react-router-dom';
import { ApplicationEnum } from '@lon/shared/enums';

const ErrorBoundary: React.FC<{
  application: Exclude<ApplicationEnum, ApplicationEnum.MATHNATION_SUIT>;
}> = ({ application }) => {
  const error = useRouteError();

  //@ts-ignore
  if (error.name === 'ChunkLoadError') {
    return null;
  }
  console.error(error);
  return <SyncError application={application} />;
};

export default ErrorBoundary;
