import { IndeterminateCheckbox } from '../components/table-content/components';
import {
  ColumnDef,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { UsePrepareData, UseServerSidePaginationAndSorting } from './types';

export const usePrepareData = <Data extends object>({
  data,
  columns,
  renderRowActions,
  enableRowSelection,
  isRowSelectionDisabled,
  hasServerSidePagination,
}: UsePrepareData<Data>) => {
  return useMemo(
    () =>
      (
        [
          enableRowSelection && {
            id: 'select',
            header: ({ table }) => (
              <IndeterminateCheckbox
                pl="4"
                isDisabled={isRowSelectionDisabled}
                isChecked={table.getIsAllRowsSelected()}
                isIndeterminate={
                  hasServerSidePagination
                    ? Object.keys(table.getState().rowSelection).length > 0 &&
                      !table.getIsAllRowsSelected()
                    : table.getIsSomeRowsSelected()
                }
                onChange={table.getToggleAllRowsSelectedHandler()}
                onKeyPress={table.getToggleAllRowsSelectedHandler()}
                aria-label={'select all rows'}
                height="100%"
                zIndex={1}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                pl="4"
                height="100%"
                tabIndex={-1}
                isDisabled={isRowSelectionDisabled}
                isChecked={row.getIsSelected()}
                isIndeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                onKeyPress={row.getToggleSelectedHandler()}
                aria-label={
                  !row.getCanExpand()
                    ? `select row ${row.id}`
                    : 'select sub rows'
                }
                zIndex={1}
              />
            ),
            enableSorting: false,
          },
          ...columns,
          renderRowActions && {
            id: 'actions',
            header: 'Actions',
            meta: {
              actionsEnabled: true,
            },
            cell: renderRowActions,
          },
        ] as ColumnDef<Data>[]
      ).filter(Boolean),
    [data, columns, enableRowSelection, isRowSelectionDisabled]
  );
};

export const useServerSidePaginationAndSorting = ({
  initialPaginationState,
  query,
  variables,
  policy,
}: UseServerSidePaginationAndSorting) => {
  const pageIndex = initialPaginationState?.pageIndex || 0;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex,
    pageSize: initialPaginationState?.pageSize || 30,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, refetch, ...rest } = query({
    variables: {
      itemsPerPage: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ...variables,
    },
    fetchPolicy: policy || 'cache-first',
  });

  useEffect(() => {
    if (pagination.pageIndex || pagination.pageSize) {
      refetch({
        ...(pagination.pageSize && { itemsPerPage: pagination.pageSize }),
        page: pagination.pageIndex + 1,
      }).catch(() => {});
    }
  }, [pagination, variables?.filter]);

  const totalCount: number =
    (Object.values(data || {})[0] as any)?.paginationInfo?.totalCount ||
    pagination.pageSize;

  const pageCount = Math.ceil(totalCount / pagination.pageSize);

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    pageCount,
    totalCount,
    data,
    refetch,
    ...rest,
  };
};
