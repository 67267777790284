export const TagKids = {
  defaultProps: {
    variant: 'primary',
  },
  baseStyle: {
    label: {
      fontSize: '1rem',
      color: 'primary.800',
    },
  },
  variants: {
    subtle: {
      container: {
        bg: 'primary.30',
        color: 'primary.800',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '1rem',
        py: 1,
        px: 2,
        minH: '20px',
        w: 'fit-content',
      },
    },
    studentStatus: {
      container: {
        bg: 'secondary.100',
        color: 'primary.800',
        fontSize: 'xs',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        borderRadius: '10px',
        py: 0.5,
        px: 3,
        minH: '24px',
        w: 'fit-content',
        whiteSpace: 'nowrap',
        pt: '4px',
        pb: '3px',
      },
    },
  },
};
