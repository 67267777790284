import { createContext } from 'react';
import { GetClassSubgroupsQuery } from '@lon/shared/requests';
import {
  AdditionalTeacher,
  ClassAccesses,
  ClassSubgroup,
  Teacher,
  TeacherResource,
  TeacherResourcePreview,
  TeacherResourceSectionsByDisplayName,
  TeachersById,
  TransformedElements,
  TransformedResourceElements,
  TransformedResources,
  TransformedSection,
} from '@lon/suit/types';

export interface JsonMetadata {
  key_name: string;
  value: string;
  contentGroup: string[];
  curriculumArea: string;
  displayName: string;
  editionName: string;
  gradeLevel: string;
  instructionalDays: string;
  internalName: string;
  positionNumber: string;
  projectType: string;
  targetEnv: string;
}

export interface JsonTaxonomy {
  HumanCodingScheme: string;
  breadcrumb: string;
  title: string;
  uuid: string;
}

export interface JsonContentStructure {
  identifier?: string;
  items?: {
    identifier?: string;
    items?: any;
    metadata?: any;
    tags?: string[];
    title?: string;
  }[];
  metadata?: JsonMetadata[];
  title?: string;
}

export interface JsonViewStructure {
  href: string;
  identifier: string;
  lorResources: {
    type: string;
    referenceId: string;
  }[];
  metadata: {
    hide: 'Yes' | 'No';
  };
  taxonomy: JsonTaxonomy[];
  title: string;
  isStudentView?: boolean;
  elementId?: string;
}

export interface JsonElementStructure {
  elementFiles: {
    href: string;
    identifier: string;
    metadata: { fileTitle: string; fileType: string[] };
    taxonomy: string[];
    title: string;
  }[];
  identifier: string;
  metadata: {
    elementName: string;
    elementIconType: string;
    scopeDisplayName: string;
    sectionTitle: string;
  };
  studentView: JsonViewStructure[];
  tags: string[];
  teacherView: JsonViewStructure;
  title: string;
}

export interface JsonSectionStructure {
  elements: JsonElementStructure[];
  identifier: string;
  metadata: { sectionType: string; sectionTitle: string };
  title: string;
}

export interface JsonScopeStructure {
  active?: boolean;
  banner?: string;
  contentGroups?: string[];
  contentLocation?: string;
  contentPaths?: string[];
  contentStructure?: JsonContentStructure[];
  created?: string;
  displayName?: string;
  editionName?: string;
  elementTaxonomy?: JsonTaxonomy;
  elementTitles?: string[];
  home?: {
    identifier: string;
    landingPage: JsonElementStructure;
    sideBar: JsonElementStructure[];
  };
  image?: string;
  internalName?: string;
  lastUpdated?: string;
  launchUrl?: string;
  metadata?: JsonMetadata;
  objectID?: string;
  platform?: string;
  positionNumber?: number;
  projectID?: string;
  projectType?: string;
  sections?: JsonSectionStructure[];
  state?: string;
  structureUrl?: string;
  tags?: string[];
  taxonomy?: JsonTaxonomy[];
  version?: number;
}

export interface IScopeContext {
  original?: JsonScopeStructure;
  elementsBySection: TransformedElements;
  loading: boolean;
  refetch: () => void;
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  sections?: TransformedSection[];
  projectType?: string;
  scopeName?: string;
  called?: boolean;
  metadata?: {
    curriculumArea: string;
    gradeLevel: string;
    displayName?: string;
  };
}

export const ScopeContext = createContext<IScopeContext>({
  elementsBySection: {},
  loading: true,
  refetch: () => {},
  attempts: 0,
  setAttempts: () => {},
  scopeName: '',
  called: false,
});

export const TeacherResourcesContext = createContext<{
  isResourcesLoading: boolean;
  sectionsByDisplayName: TeacherResourceSectionsByDisplayName;
  resources?: TeacherResourcePreview[] | null;
  resourcesByCA: TransformedResources;
  isResourcesCalled: boolean;
}>({
  isResourcesLoading: true,
  sectionsByDisplayName: {},
  resources: [],
  resourcesByCA: {},
  isResourcesCalled: false,
});

export const ResourceSectionsListContext = createContext<{
  isResourceLoading: boolean;
  elementsBySection: TransformedResourceElements;
  resource?: TeacherResource;
}>({ isResourceLoading: true, elementsBySection: {} });

export const SelectTeachersContext = createContext<{
  teachersLoading: boolean;
  teachers?: Teacher[];
  teachersById?: TeachersById;
  additionalTeachers?: AdditionalTeacher[];
  accesses: ClassAccesses[];
  subgroups: ClassSubgroup[];
}>({
  teachersLoading: false,
  teachers: [],
  teachersById: {},
  additionalTeachers: [],
  accesses: [],
  subgroups: [],
});

export const ClassContext = createContext<{
  isMainTeacher: boolean;
  className?: string;
  loading?: boolean;
  data?: GetClassSubgroupsQuery;
}>({
  isMainTeacher: true,
  className: '',
  loading: false,
  data: {},
});
