export const image = {
  verticalAlign: 'middle',
  backgroundColor: 'white',
  width: 'full',
  height: 'full',
  objectFit: 'cover',
  color: 'transparent',
  fontSize: '1px',
  _before: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '1',
    backgroundColor: 'var(--grey-2)',
    width: 'full',
    height: 'full',
    content: `''`,
  },
  _after: {
    WebkitBoxOrient: 'vertical',
    MozBoxOrient: 'vertical',
    WebkitLineClamp: '5',
    display: '-webkit-box',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1',
    py: '0',
    px: '2',
    width: 'full',
    maxHeight: 'calc(var(--typography-c2-line-height) * 5)',
    overflow: 'hidden',
    color: 'var(--basic-800)',
    fontWeight: 'var(--typography-c2-font-weight)',
    fontSize: 'var(--typography-c2-font-size)',
    lineHeight: 'var(--typography-c2-line-height)',
    fontFamily: 'var(--font-type-main)',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',

    '_global(.theme-high-contrast) &': {
      color: 'white',
    },
  },
};
