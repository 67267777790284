import { Button, Icon, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { Link, To } from 'react-router-dom';
import { arrDoubleLeftOutlined } from '@lon/shared/assets';
import { useMediaQuery, useThemeStyleValue } from '@lon/shared/hooks';

interface Props {
  to: To;
  label: string;
  tooltipLabel: string;
}

const BackButton: React.FC<Props> = ({ to, label, tooltipLabel }) => {
  const [isTablet] = useMediaQuery('(min-width: 1024px)');
  const buttonVariant = useThemeStyleValue('outlineDark', 'outline');

  return (
    <Tooltip variant="dark" label={tooltipLabel} aria-hidden={true}>
      {isTablet ? (
        <Button
          as={Link}
          to={to}
          variant={buttonVariant}
          size="sm"
          leftIcon={<Icon as={arrDoubleLeftOutlined} />}
        >
          <Text as="span" isTruncated variant="bs">
            {label}
          </Text>
        </Button>
      ) : (
        <IconButton
          as={Link}
          variant={buttonVariant}
          size="sm"
          to={to}
          aria-label={label}
        >
          {<Icon as={arrDoubleLeftOutlined} />}
        </IconButton>
      )}
    </Tooltip>
  );
};

export default BackButton;
