import { StatusLabel } from '../status-label';
import { Button, Flex, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { isEqual } from 'lodash-es';
import React, { FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ConfirmModal,
  FormSelect,
  Icon,
  TextInput,
} from '@lon/shared/components';
import { IdPart } from '@lon/shared/enums';
import { useGetClassSubgroupsQuery } from '@lon/shared/requests';
import { types } from './duck';

const FormFields: FC<types.FormFieldProps> = ({
  fields,
  setCustomDirty,
  defaultValues,
  errors,
}) => {
  const { classId } = useParams() as { classId: string };

  const { data: classData } = useGetClassSubgroupsQuery({
    variables: {
      classId: `${IdPart.Classes}${classId}`,
      activeTeachers: true,
    },
    fetchPolicy: 'no-cache',
    skip: !classId,
  });
  const { t } = useTranslation();
  const submitting = false;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { reset, getValues, setValue, formState, trigger } = useFormContext();
  const [removingIndex, setRemovingIndex] = useState<number>(0);

  const deletedStudentsCount = useMemo(() => {
    return classData?.class?.subgroups?.collection?.find(
      (subgroup) => subgroup?._id === fields[removingIndex]?._id
    )?.students?.collection?.length;
  }, [removingIndex, fields]);

  const teachersOptions = useMemo(
    () =>
      classData?.class?.classTeachers?.collection
        ?.filter((classTeacher) => !classTeacher?.mainTeacher)
        ?.map((classTeacher) => ({
          value: classTeacher?.teacher?._id || '',
          label: `${classTeacher?.teacher?.firstName} ${classTeacher?.teacher?.lastName}`,
        })) || [],
    [classData]
  );

  const remove = () => {
    const values = getValues().subgroups;
    const filteredSubgroup = values.filter(
      (item: any, idx: number) => idx !== removingIndex
    );
    const isChanged = !isEqual(defaultValues.subgroups, filteredSubgroup);

    if (fields.length === 1) {
      reset(
        {
          subgroups: [
            {
              name: '',
              teachers: [],
            },
          ],
        },
        { keepDirty: isChanged }
      );
    } else {
      reset(
        {
          subgroups: filteredSubgroup,
        },
        { keepDirty: isChanged }
      );
    }
    setCustomDirty(isChanged);
    onClose();
  };

  const handleChangeSubgroupName: React.ChangeEventHandler<
    HTMLInputElement
  > = ({ target: { value, name } }) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    if (Array.isArray(formState?.errors?.subgroups)) {
      formState?.errors?.subgroups?.forEach((_: any, index: number) => {
        trigger(`subgroups.${index}.name`);
      });
    }
  };

  return (
    <>
      {fields.map((item, index) => (
        <Flex
          flexDirection="column"
          gap={4}
          key={item.id}
          p={8}
          pt={4}
          borderBottom="1px"
          borderColor="gray.50"
          position="relative"
          data-testid="form-card"
        >
          <Flex
            w="100%"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
            position="absolute"
            top={3}
            right={8}
          >
            <StatusLabel
              subgroupId={item._id}
              defaultValues={defaultValues}
              fieldErrors={errors?.subgroups?.[index]}
            />
            <Button
              zIndex={1}
              onClick={() => {
                setRemovingIndex(index);
                onOpen();
              }}
              size="sm"
              variant="ghost"
              textDecoration="underline"
              rightIcon={<Icon name="delete-outlined" />}
              disabled={submitting}
            >
              <Text as="span" isTruncated>
                {t('subgroups.deleteCardBtn')}
              </Text>
            </Button>
          </Flex>
          <TextInput
            data-user-input="true"
            isRequired
            maxLength={100}
            name={`subgroups.${index}.name`}
            onChange={handleChangeSubgroupName}
            label={t('subgroups.titleLabel')}
            placeholder={t('subgroups.titlePlaceholder')}
            isDisabled={submitting}
          />
          <FormSelect
            isMulti
            name={`subgroups.${index}.teachers`}
            label={t('subgroups.teachersLabel')}
            placeholder={t('subgroups.teachersPlaceholder')}
            options={teachersOptions}
            menuPortalTarget={document.body}
            isInsideDrawer={true}
          />
        </Flex>
      ))}
      <ConfirmModal
        primaryHeading={sanitize(
          t('subgroups.deleteHeader', {
            name: fields[removingIndex]?.name,
            interpolation: {
              escapeValue: false,
            },
          })
        )}
        subHeading={
          <VStack gap={1.5}>
            {!!deletedStudentsCount && (
              <Text>
                {sanitize(
                  t('subgroups.studentsCount', {
                    name: fields[removingIndex]?.name,
                    count: deletedStudentsCount,
                    interpolation: {
                      escapeValue: false,
                    },
                  })
                )}
              </Text>
            )}
            <Text>{t('subgroups.restoreWarning')}</Text>
          </VStack>
        }
        isModalOpen={isOpen}
        onModalClose={onClose}
        handleSave={remove}
        handleCancel={onClose}
        submitBtnProps={{
          leftIcon: <Icon name="check-simple-outlined" size="lg" />,
        }}
        submitButtonText={t('subgroups.delete')}
      />
    </>
  );
};

export default FormFields;
