import React from 'react';
import { ContentLoader } from "@lon/shared/components";
const Skeleton: React.FC = () => (
  <div data-testid="scope-elements-skeleton">
    <ContentLoader viewBox="0 0 1135 349">
      <rect x="25" y="25" width="1085" height="60" />
      <rect x="25" y="113" width="1085" height="60" />
      <rect x="25" y="201" width="1085" height="60" />
      <rect x="25" y="289" width="1085" height="60" />
    </ContentLoader>
  </div>
);

export default Skeleton;
