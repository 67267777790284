import { isEmpty } from 'lodash-es';
import { TFunction } from 'react-i18next';
import { ApplicationEnum, ProfileTypeEnum } from '@lon/shared/enums';

export const getBadges = ({
  t,
  userType,
}: {
  t: TFunction;
  userType: string;
}) => ({
  [ApplicationEnum.TEACHER_SUIT]: {
    name: t('applicationSwitcher.teacher'),
    variant: 'greenBadge',
  },
  [ApplicationEnum.ADMIN]: {
    name: t(`applicationSwitcher.admin`),
    variant: 'adminBadge',
  },
  [ApplicationEnum.PLATFORM_ADMIN]: {
    name: t('applicationSwitcher.platformAdmin'),
    variant: 'adminBadge',
  },
  [ApplicationEnum.STUDENT_SUIT]: {
    name: t('applicationSwitcher.student'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.CAMPUS_LEADER_SUIT]: {
    name: t('applicationSwitcher.campusLeader'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]: {
    name: t('applicationSwitcher.districtCurriculumLeader'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]: {
    name: t('applicationSwitcher.campusCurriculumLeader'),
    variant: 'studentBadge',
  },
  [ApplicationEnum.MATHNATION_SUIT]: {
    name:
      userType === 'student'
        ? t('applicationSwitcher.student')
        : t('applicationSwitcher.teacher'),
    variant: userType === 'student' ? 'studentBadge' : 'greenBadge',
  },
});

export const updateLocalStorageImpersonation = (
  selectedApplication?: ApplicationEnum
) => {
  const impersonateData = JSON.parse(
    localStorage.getItem('impersonate') || '{}'
  );
  if (!isEmpty(impersonateData)) {
    let impersonatedProfileType = null;

    switch (true) {
      case selectedApplication ===
        ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT:
        impersonatedProfileType = ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER;
        break;
      case selectedApplication === ApplicationEnum.CAMPUS_LEADER_SUIT:
        impersonatedProfileType = ProfileTypeEnum.CAMPUS_LEADER;
        break;
      case selectedApplication ===
        ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT:
        impersonatedProfileType = ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER;
        break;
      case selectedApplication === ApplicationEnum.ADMIN:
        impersonatedProfileType = ProfileTypeEnum.ADMIN;
        break;
      case selectedApplication === ApplicationEnum.STUDENT_SUIT:
        impersonatedProfileType = ProfileTypeEnum.STUDENT;
        break;
      case selectedApplication === ApplicationEnum.TEACHER_SUIT:
        impersonatedProfileType = ProfileTypeEnum.TEACHER;
        break;
    }

    localStorage.setItem(
      'impersonate',
      JSON.stringify({
        ...impersonateData,
        type: impersonatedProfileType,
      })
    );
  }
};
