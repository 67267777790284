import {
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon, ReactDatePicker } from '@lon/shared/components';
import styles from './Filter.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Filter = ({ isOpen, onClose }: Props) => {
  const [archived, setArchived] = useState(false);
  const [assigneeType, setAssigneeType] = useState('all');
  const form = useForm();
  const { t } = useTranslation();

  const { watch, reset } = form;
  const startDateFrom = watch('startDateFrom');
  const startDateTo = watch('startDateTo');
  const dueDateFrom = watch('dueDateFrom');
  const dueDateTo = watch('dueDateTo');

  const defaultValue = {
    archived: false,
    assigneeType: 'all',
    startDateFrom: null,
    startDateTo: null,
    dueDateFrom: null,
    dueDateTo: null,
  };

  const isDirty = useMemo(() => {
    let changed = false;
    Object.keys(form.getValues()).every((key) => {
      if ((defaultValue as any)[key] !== form.getValues()[key]) {
        changed = true;
        return false;
      }
      return true;
    });

    if (
      archived !== defaultValue.archived ||
      assigneeType !== defaultValue.assigneeType
    ) {
      changed = true;
    }

    return changed;
  }, [form.getValues()]);

  const resetDefault = () => {
    setArchived(defaultValue.archived);
    setAssigneeType(defaultValue.assigneeType);
    reset({
      ...defaultValue,
    });
  };

  return (
    <Drawer
      closeOnEsc={false}
      closeOnOverlayClick={false}
      variant="formDrawer"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      size={{ base: 'full', md: 'md', lg: 'lg' }}
    >
      <DrawerOverlay />
      <DrawerContent minWidth={{ lg: '62rem' }}>
        <DrawerCloseButton onClick={onClose} pb={2} />

        <DrawerHeader
          px={{
            base: 3,
            md: 8,
          }}
        >
          <Text variant="formHeader">
            {t('reportsDashboard.districtAssessmentsReports.filter')}
          </Text>
        </DrawerHeader>
        <DrawerBody color="primary.800" py={6}>
          <Flex flexDirection="column" gap={4}>
            <FormProvider {...form}>
              <Flex
                px={{ base: 4, md: '60px', lg: '120px' }}
                flexDirection="column"
                maxWidth="fit-content"
              >
                <Text fontWeight="700" mr="0.75rem" mb="0.375rem">
                  {t(
                    'reportsDashboard.districtAssessmentsReports.assessmentType'
                  )}
                </Text>

                <ButtonGroup
                  className={styles.viewButtonGroup}
                  maxWidth="fit-content"
                >
                  <Button
                    className={styles.viewModeBtn}
                    leftIcon={
                      !archived ? (
                        <Icon name="checkCircleOutlined" size="xl" />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      setArchived(false);
                    }}
                  >
                    {t('reportsDashboard.districtAssessmentsReports.active')}
                  </Button>
                  <Button
                    className={styles.viewModeBtn}
                    leftIcon={
                      archived ? (
                        <Icon name="checkCircleOutlined" size="xl" />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      setArchived(true);
                    }}
                  >
                    {t('reportsDashboard.districtAssessmentsReports.archive')}
                  </Button>
                </ButtonGroup>
              </Flex>

              <Flex
                px={{ base: 4, md: '60px', lg: '120px' }}
                flexDirection={{
                  base: 'column',
                  md: 'row',
                }}
                gap={6}
              >
                <ReactDatePicker
                  showCurrentDatePicker
                  name="startDateFrom"
                  selected={startDateFrom}
                  startDate={startDateFrom}
                  label={
                    <Text as="span" isTruncated>
                      {t(
                        'reportsDashboard.districtAssessmentsReports.startFrom'
                      )}
                    </Text>
                  }
                  defaultValue={startDateFrom ?? null}
                  placeholder={'Today'}
                  inputLayoutProps={{
                    formLabelProps: {
                      display: 'flex',
                      overflow: 'hidden',
                    },
                  }}
                />
                <ReactDatePicker
                  showCurrentDatePicker
                  name="startDateTo"
                  selected={startDateTo}
                  startDate={startDateTo}
                  label={
                    <Text as="span" isTruncated>
                      {t('reportsDashboard.districtAssessmentsReports.startTo')}
                    </Text>
                  }
                  defaultValue={startDateTo ?? null}
                  placeholder={'Today'}
                  inputLayoutProps={{
                    formLabelProps: {
                      display: 'flex',
                      overflow: 'hidden',
                    },
                  }}
                />
              </Flex>
              <Flex
                px={{ base: 4, md: '60px', lg: '120px' }}
                flexDirection={{
                  base: 'column',
                  md: 'row',
                }}
                gap={6}
              >
                <ReactDatePicker
                  showCurrentDatePicker
                  name="dueDateFrom"
                  selected={dueDateFrom}
                  startDate={dueDateFrom}
                  label={
                    <Text as="span" isTruncated>
                      {t('reportsDashboard.districtAssessmentsReports.dueFrom')}
                    </Text>
                  }
                  defaultValue={dueDateFrom ?? null}
                  placeholder={'Today'}
                  inputLayoutProps={{
                    formLabelProps: {
                      display: 'flex',
                      overflow: 'hidden',
                    },
                  }}
                />
                <ReactDatePicker
                  showCurrentDatePicker
                  name="dueDateTo"
                  selected={dueDateTo}
                  startDate={dueDateTo}
                  label={
                    <Text as="span" isTruncated>
                      {t('reportsDashboard.districtAssessmentsReports.dueTo')}
                    </Text>
                  }
                  defaultValue={dueDateTo ?? null}
                  placeholder={'Today'}
                  inputLayoutProps={{
                    formLabelProps: {
                      display: 'flex',
                      overflow: 'hidden',
                    },
                  }}
                />
              </Flex>
              <Divider py="16px" />

              <Flex
                px={{ base: 4, md: '60px', lg: '120px' }}
                flexDirection="column"
                maxWidth="fit-content"
              >
                <Text fontWeight="700" mr="0.75rem" mb="0.375rem">
                  {t(
                    'reportsDashboard.districtAssessmentsReports.assigneeType'
                  )}
                </Text>

                <ButtonGroup
                  className={styles.viewButtonGroup}
                  maxWidth="fit-content"
                >
                  <Button
                    className={styles.viewModeBtn}
                    leftIcon={
                      assigneeType === 'grade' ? (
                        <Icon name="checkCircleOutlined" size="xl" />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      setAssigneeType('grade');
                    }}
                  >
                    {t(
                      'reportsDashboard.districtAssessmentsReports.gradeLevels'
                    )}
                  </Button>
                  <Button
                    className={styles.viewModeBtn}
                    leftIcon={
                      assigneeType === 'schools' ? (
                        <Icon name="checkCircleOutlined" size="xl" />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      setAssigneeType('schools');
                    }}
                  >
                    {t('reportsDashboard.districtAssessmentsReports.schools')}
                  </Button>
                  <Button
                    className={styles.viewModeBtn}
                    leftIcon={
                      assigneeType === 'all' ? (
                        <Icon name="checkCircleOutlined" size="xl" />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      setAssigneeType('all');
                    }}
                  >
                    {t('reportsDashboard.districtAssessmentsReports.all')}
                  </Button>
                </ButtonGroup>
              </Flex>
            </FormProvider>
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          <Flex justifyContent="space-between" w="full">
            <Flex gap={4}>
              <Button
                display={{
                  base: 'none',
                  md: 'inline-block',
                }}
                onClick={onClose}
                leftIcon={<Icon name="chevronLeft" />}
              >
                {t(
                  'reportsDashboard.districtAssessmentsReports.backToAssessments'
                )}
              </Button>
              <IconButton
                display={{ md: 'none' }}
                aria-label="Back to Assessments"
                onClick={onClose}
                icon={<Icon name="chevronLeft" />}
              />
              <Button isDisabled={!isDirty} onClick={() => resetDefault()}>
                {t('reportsDashboard.districtAssessmentsReports.setDefault')}
              </Button>
            </Flex>
            <Button variant="solid">
              {t('reportsDashboard.districtAssessmentsReports.apply')}
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default Filter;
