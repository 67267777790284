import { UseMediaQueryOptions, useMediaQuery } from '@chakra-ui/react';

// { ssr: false } is needed to prevent UI flickering on first render:
// https://github.com/chakra-ui/chakra-ui/pull/6157
const useCustomMediaQuery = (
  query: string | string[],
  options?: UseMediaQueryOptions
) => {
  const [isMatch] = useMediaQuery(query, { ssr: false, ...options });
  return [isMatch];
};

export default useCustomMediaQuery;
