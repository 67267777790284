import {
  Box,
  Skeleton as ChakraSkeleton,
  Flex,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { skeletonTypes } from './duck';

const Skeleton = <Data extends object>({
  columns = [],
  rowCount = 10,
  skeletonRowHeight = 'auto',
  hasEmptyRow = true,
}: skeletonTypes.SkeletonProps<Data>) => {
  const themeBgColor = useThemeStyleValue('white', 'secondary.1000');

  return (
    <tbody data-testid="table-skeleton">
      {hasEmptyRow && (
        <Tr>
          <Th colSpan={99} backgroundColor={themeBgColor}>
            <Box minH="26px" />
          </Th>
        </Tr>
      )}
      {[...Array(rowCount).keys()].map((tr) => (
        <Tr key={tr}>
          {columns.map((column, index) => (
            <Td key={`${column.header}_${index}`}>
              <Flex minH={skeletonRowHeight} align="center" w="100%">
                <ChakraSkeleton
                  startColor="blue.100"
                  endColor="gray.100"
                  height={15}
                  w="100%"
                />
              </Flex>
            </Td>
          ))}
        </Tr>
      ))}
    </tbody>
  );
};

export default Skeleton;
