import { replaceImagesBasedOnLanguage } from './replaceImagesBasedOnLanguage';
import { replaceVideosBasedOnLanguage } from './replaceVideosBasedOnLanguage';

export const effectOnChangeLang = () => {
  function onLangChange(newLang: string | null): void {
    replaceImagesBasedOnLanguage();
    replaceVideosBasedOnLanguage();
  }

  const observer = new MutationObserver((mutations: MutationRecord[]) => {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes' && mutation.attributeName === 'lang') {
        const newLang = (mutation.target as HTMLElement).getAttribute('lang');
        onLangChange(newLang);
      }
    });
  });

  const targetNode = document.documentElement;

  const config: MutationObserverInit = { attributes: true };

  observer.observe(targetNode, config);

  onLangChange(targetNode.getAttribute('lang'));
};
