export const Text = {
  variants: {
    h1: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      lineHeight: '2.75rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.625rem',
      lineHeight: '2.125rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.375rem',
      lineHeight: '1.875rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight: 700,
    },
    n1: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight: 700,
    },
    n2: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight: 600,
    },
    n3: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight: 400,
    },
    n4: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight: 400,
      fontStyle: 'italic',
    },
    n5NormalU: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight: 400,
      textDecorationLine: 'underline',
    },
    s1: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 700,
    },
    s2: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    s3: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      fontStyle: 'italic',
    },
    es2: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    bxl: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    bl: {
      fontSize: '1.125rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    bm: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    bs: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    bsu: {
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
      textDecorationLine: 'underline',
    },
    l: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    ls: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
    },
    lu: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    su4: {
      fontSize: 'sm',
      textDecorationLine: 'underline',
      lineHeight: 'shorter',
    },
    rm: {
      fontSize: '1.375rem',
      lineHeight: '2rem',
      fontWeight: 400,
    },
    lmu: {
      fontSize: '0.6875rem',
      lineHeight: '0.6875rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    tag: {
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
      fontWeight: 400,
    },
    tagXs: {
      fontSize: '0.6875rem',
      lineHeight: '0.6875rem',
      fontWeight: 400,
    },
    tagTs: {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
      fontWeight: 400,
    },
    lxxsu: {
      fontSize: '0.5625rem',
      lineHeight: '0.5625rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    tmu: {
      fontSize: '0.8rem',
      lineHeight: '0.8rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '0.1rem',
    },
    tsu: {
      fontSize: '0.6875rem',
      lineHeight: '0.6875rem',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
    formHeader: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
      fontWeight: 700,
    },
  },
};
