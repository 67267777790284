import { RefObject, useEffect, useRef, useState } from 'react';

export const useFetchContent = ({
  contentUrl,
  refetch,
}: {
  contentUrl: string;
  refetch: () => any;
}): RefObject<HTMLIFrameElement> => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [attempts, setAttempts] = useState<number>(0);
  const params = new URLSearchParams(window.location.search);
  const [tts, setTts] = useState(params.get('tts'));

  useEffect(() => {
    if (!contentUrl) {
      return;
    }

    const controller = new AbortController();

    const fetchIframe = async (controller: AbortController) => {
      try {
        // for local iframe update fetch url to "/public/test.html"
        const response = await fetch(contentUrl, {
          credentials: 'include',
          signal: controller.signal,
        });
        if (response.status === 401) {
          if (attempts < 3) {
            setAttempts(attempts + 1);
            refetch();
          }
        } else {
          if (iframeRef?.current) {
            iframeRef.current.src = contentUrl;
          }
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchIframe(controller);

    return () => {
      controller.abort();
    };
  }, [attempts, contentUrl, tts]);

  useEffect(() => {
    const handleTTSUpdate = (e: any) => {
      const searchParams = new URLSearchParams(window.parent.location.search);
      if (
        e?.data?.type === 'tts-toggle-assessment-player' ||
        e?.data?.type === 'tts-toggle-resource-player'
      ) {
        setTts(searchParams.get('tts'));
      }
    };
    window.addEventListener('message', handleTTSUpdate);

    return () => {
      window.removeEventListener('message', handleTTSUpdate, false);
    };
  }, []);

  return iframeRef;
};
