export const sizes = {
  small: '1rem',
  medium: '1.5rem',
  large: '1.75rem',
  xs: '0.5625rem',
  sm: '0.85rem',
  '0.7': '0.7rem',
  '0.75': '0.75rem',
  md: '0.94rem',
  lg: '1.25rem',
  xl: '1.125rem',
  '1.75': '1.75rem',
  xxl: '2.5rem',
  '2': '2rem',
};
