import { APPROPRIATE_CLASS_ID } from '../../../../../duck/constants';
import { ElementData } from '../../../../../duck/types';
import { GetScopesOptionsProps } from '../../../duck/types';
import { Icon } from '@chakra-ui/react';
import { t } from 'i18next';
import { bookOutlined, readOutlined } from '@lon/shared/assets';
import { OTHER_ASSIGNMENTS_ID } from '@lon/shared/constants';
import { PlanElementType } from '@lon/shared/types';

export const getScopesOptions = ({
  isAppropriateClass,
  classData,
  scopesData,
}: GetScopesOptionsProps) =>
  isAppropriateClass
    ? [
        {
          label: classData?.class?.name || '',
          value: APPROPRIATE_CLASS_ID,
          Icon: (
            <Icon
              as={bookOutlined}
              verticalAlign="middle"
              mr="2"
              width="20px"
              height="20px"
            />
          ),
        },
      ]
    : [
        {
          label: t('createAssignmentDrawer.otherAssigneesLabel'),
          value: OTHER_ASSIGNMENTS_ID,
          Icon: (
            <Icon
              as={bookOutlined}
              verticalAlign="middle"
              mr="2"
              width="20px"
              height="20px"
            />
          ),
        },
        ...(scopesData?.scopes.collection
          ?.map((cl) => ({
            label: cl?.displayName || '',
            value: cl?.id || '',
            Icon: (
              <Icon
                as={readOutlined}
                verticalAlign="middle"
                mr="2"
                width="20px"
                height="20px"
              />
            ),
          }))
          .sort((a, b) => {
            const first = a.label;
            const second = b.label;
            // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
            return first.localeCompare(second, 'en', { numeric: true });
          }) || []),
      ];

export const isMultipleElementsAssignment = (
  elementsData?: ElementData | PlanElementType[]
): elementsData is PlanElementType[] => {
  return (elementsData as PlanElementType[])?.[0]?.elementName !== undefined;
};
