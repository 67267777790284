import { Link as RouterLink } from '../../../../../../../link';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { IoBookOutline } from '@react-icons/all-files/io5/IoBookOutline';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { Scope, ScopeMetadata } from '@lon/shared/types';

export interface LessonScopeMenuProps {
  scope?: Scope;
  sectionId: string;
  metadata?: ScopeMetadata;
  scopeId: string;
  projectId: string;
}

const LessonScopeMenu: React.FC<LessonScopeMenuProps> = ({
  scope,
  sectionId,
  projectId,
}) => {
  return (
    <Flex direction="column" gap="3" bgColor="blue.500" color="white">
      {scope?.sections?.map(({ metadata, identifier }) => (
        <Box as="li" key={identifier}>
          <Button
            variant="sidebarButton"
            key={identifier}
            withoutStyles
            as={RouterLink}
            justifyContent="flex-start"
            whiteSpace="pre-wrap"
            title={metadata.sectionTitle}
            to={{
              pathname: generatePath(routes.scopes.section, {
                scopeId: scope.id,
                sectionId: identifier,
                projectId,
              }),
            }}
            leftIcon={<IoBookOutline fontSize="1.25rem" />}
            w="full"
            role="tab"
            id={identifier}
            aria-controls="lesson-scope-elements"
            aria-selected={sectionId === identifier}
            {...(sectionId === identifier && {
              isActive: true,
              className: 'active',
              css: {
                backgroundColor: 'var(--chakra-colors-primary-50) !important',
                color: 'var(--chakra-colors-primary-800) !important',
              },
            })}
          >
            <Text as="span" isTruncated>
              {metadata.sectionTitle}
            </Text>
          </Button>
        </Box>
      ))}
    </Flex>
  );
};

export default LessonScopeMenu;
