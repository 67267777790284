import {
  getAssignmentData,
  getMultipleAssignmentData,
} from '../../../../assignment/duck/utils';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { IdPart, StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import {
  useGetAssignmentClassQuery,
  useGetAssignmentListQuery,
  useGetAssignmentQuery,
} from '@lon/shared/requests';

const useStudentsNavigation = () => {
  const { studentId, sessionId, assignmentId, listId } = useParams();
  const [auth] = useAuth();
  const { userId } = auth?.user || {};
  const isSingleStudentGrading = studentId && sessionId;

  const { currentSchoolId: schoolId } = React.useContext(WorkingLocation);
  const navigate = useNavigate();

  const { data, loading } = useGetAssignmentQuery({
    variables: {
      id: assignmentId?.replace('_', '#'),
      schoolId,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !schoolId || !!listId,
  });

  const { data: listData, loading: listLoading } = useGetAssignmentListQuery({
    variables: {
      id: `${listId?.replace('_', '#')}`,
      schoolId: schoolId || '',
      teachersId: userId,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !userId,
  });

  const { data: classData, loading: classLoading } = useGetAssignmentClassQuery(
    {
      variables: {
        classId: `${IdPart.Classes}${data?.assignment?.classId}`,
      },
      skip: !data?.assignment?.classId,
    }
  );

  const students = React.useMemo(() => {
    let formattedStudents = [];

    if (listId) {
      formattedStudents = getMultipleAssignmentData(listData);
    } else {
      formattedStudents = getAssignmentData(data, classData);
    }

    return formattedStudents.filter(
      (st) =>
        st.transformedStatus &&
        [
          StudentAssignmentStatusTransformed.ToBeGraded,
          StudentAssignmentStatusTransformed.Graded,
          StudentAssignmentStatusTransformed.Returned,
        ].includes(st.transformedStatus)
    );
  }, [classData, data, listData]);

  const { currentStudent, currentStudentIndex } = React.useMemo(() => {
    if (!students) return { currentStudent: null, currentStudentIndex: null };

    const currentIndex = students.findIndex(
      (student) => student?.studentId === studentId
    );

    return {
      currentStudent: students[currentIndex],
      currentStudentIndex: currentIndex,
    };
  }, [students, studentId]) as {
    currentStudent: ReturnType<typeof getAssignmentData>[number];
    currentStudentIndex: number;
  };

  const nextStudent = students[currentStudentIndex + 1];
  const previousStudent = students[currentStudentIndex - 1];
  const studentName = currentStudent
    ? `${currentStudent.firstName} ${currentStudent.lastName}`
    : '';

  // to avoid content and extra-props for child components
  React.useEffect(() => {
    navigate('.', { replace: true, state: { studentName } });
  }, [studentName]);

  return {
    isSingleStudentGrading,
    loading: loading || classLoading || listLoading,
    currentStudentIndex,
    amountOfStudents: students?.length,
    studentName,
    nextStudent,
    previousStudent,
  };
};

export default useStudentsNavigation;
