import { navigationTypes } from '../../../../duck';
import { SectionCard } from '../section-card';

const ContentPlayerSectionCardWrapper: React.FC<
  navigationTypes.SectionCardWrapperPropTypes
> = ({ sectionIconType, ...props }) => {
  // for content-player create manual path as content-player does not have GraphQL
  const iconName = sectionIconType
    ? `${sectionIconType?.split(' ')?.[0]?.toLocaleLowerCase()}.png`
    : '';
  const iconPath = sectionIconType ? `/icons/${iconName}` : '';

  return <SectionCard {...props} iconPath={iconPath} />;
};

export default ContentPlayerSectionCardWrapper;
