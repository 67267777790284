export const replaceImagesBasedOnLanguage = () => {
  const language = document.documentElement.lang;

  const replaceImage = (target = '/EN_', to = '/ES_') => {
    const images = document.querySelectorAll('img');
    images.forEach((image) => {
      if (image.src.includes(target)) {
        image.src = image.src.replace(target, to);
      }
    });
  };

  if (language === 'es') {
    replaceImage('/EN_', '/ES_');
  }

  if (language === 'en') {
    replaceImage('/ES_', '/EN_');
  }
};
