import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorMessage } from '@lon/shared/components';
import { arrDoubleLeftOutlined } from '@lon/shared/icons';
import { routes } from '@lon/suit/configs';

const NoContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessage
      bodyWrapperProps={{
        padding: 0,
        alignSelf: 'normal',
        zIndex: 1,
      }}
      title={
        <Flex direction="column" gap={4} mt="6" mb="8">
          <Text
            variant="h5"
            as="h1"
            maxW="430px"
            alignSelf="center"
            boxSizing="content-box"
            px="6"
          >
            {t('assessmentDetails.noContent.title')}
          </Text>
          {
            <Text
              variant="n3"
              as="h2"
              maxW="450px"
              alignSelf="center"
              boxSizing="content-box"
              px="6"
            >
              {t('assessmentDetails.noContent.body')}
            </Text>
          }
        </Flex>
      }
      imageSrc="suit/assets/images/error-404.png"
      actions={
        <Button
          as={Link}
          to={routes.classes.reportDashboard.districtAssessments}
          title={t('assessmentDetails.noContent.allAssessmentsBtn')}
          variant="solid"
          leftIcon={<Icon as={arrDoubleLeftOutlined} w="18px" h="18px" />}
        >
          <Text as="span" isTruncated>
            {t('assessmentDetails.noContent.allAssessmentsBtn')}
          </Text>
        </Button>
      }
    />
  );
};

export default NoContent;
