import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { chunk } from 'lodash-es';
import React from 'react';
import { GradeTag } from '@lon/shared/components';
import { useGetTheme } from '@lon/shared/hooks';
import { LearnosityAnswer } from '@lon/shared/types';
import { getAnswerCellSettings } from '@lon/suit/utils';

interface AnswersProps {
  answers: LearnosityAnswer[] | null;
}
const Answers: React.FC<AnswersProps> = ({ answers }) => {
  const currentTheme = useGetTheme();

  return (
    <Table width="294px">
      <Tbody>
        {chunk(answers, 7).map((row, idx) => (
          <Tr key={idx}>
            {row.map((mark, index) => {
              const settings = getAnswerCellSettings(mark, currentTheme);

              return (
                <Td key={`${mark}${index}`} px={0} pb={1} pt={0} border="none">
                  <GradeTag {...settings}>{idx * 7 + index + 1}</GradeTag>
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Answers;
