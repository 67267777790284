import { Box, Flex, Skeleton } from '@chakra-ui/react';

const PageSkeleton = () => (
  <Flex direction="column" mt={1} flexGrow={1} data-testid="page-skeleton">
    <Skeleton h="44px" />
    <Flex direction="row" gap="116px" mt={6}>
      <Flex direction="column" width="100%">
        <Box py={4}>
          <Skeleton h="27px" />
        </Box>
        <Skeleton h="560px" />
      </Flex>
      <Flex direction="column" width="100%">
        <Box py={5}>
          <Skeleton h="27px" />
        </Box>
        <Skeleton h="560px" />
      </Flex>
    </Flex>
  </Flex>
);

export default PageSkeleton;
