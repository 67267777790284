import React from 'react';
import { CheckboxListProps } from './duck';
import { BaseCheckboxList, FormCheckboxList, Loader } from './components';

const CheckboxList: React.FC<CheckboxListProps> = ({
  isLoading,
  loadingMessage,
  name,
  ...rest
}) => {
  if (isLoading) {
    return <Loader isLoading={isLoading} message={loadingMessage} />;
  }

  if (name && !rest.onChange && !rest.onIndeterminateCheckboxChange) {
    return <FormCheckboxList {...rest} name={name} />;
  }
  console.log(rest.values);
  return <BaseCheckboxList {...rest} name={name} />;
};

export default CheckboxList;
