export const hash = (obj: object) => {
  const newStr = JSON.stringify(obj);
  let hash = 0;
  if (newStr.length === 0) {
    return hash;
  }
  for (let i = 0; i < newStr.length; i++) {
    const char = newStr.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }
  return String(hash);
};
