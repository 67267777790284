import { MenuItem } from '../../menu';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { useQueryParams } from '@lon/shared/hooks';
import { studentView, teacherView } from '@lon/suit/constants';
import { ScopeContext } from '@lon/suit/contexts';
import { ViewTypeProps } from './duck';
import { StudentPageSelect } from './components';

const ViewType: React.FC<ViewTypeProps> = ({
  element,
  onViewTypeChangeComplete,
  ...props
}) => {
  const [queryParams] = useQueryParams();
  const { sectionId = 'home', elementId } = useParams();
  const { elementsBySection } = useContext(ScopeContext);
  const navigate = useNavigate();

  const currentSection = elementsBySection[sectionId];
  const currentElement = element
    ? element
    : elementId
    ? currentSection[elementId]
    : Object.values(currentSection)[0];
  const isLearner = queryParams.type === studentView;

  const visibleStudentViewElements = currentElement.studentView.filter(
    (view) => view.metadata?.hide !== 'Yes'
  );

  const setTeacherView = () => {
    const paramsWithoutPageId = { ...queryParams };
    delete paramsWithoutPageId.studentElementId;
    navigate('?' + getQuery({ ...paramsWithoutPageId, type: teacherView }));
    onViewTypeChangeComplete && onViewTypeChangeComplete();
  };
  const setStudentView = (studentElementId: string) => {
    navigate(
      '?' + getQuery({ ...queryParams, type: studentView, studentElementId })
    );
    onViewTypeChangeComplete && onViewTypeChangeComplete();
  };

  if (
    !currentElement.studentView.length ||
    currentElement.studentView.every((view) => view.metadata?.hide === 'Yes')
  ) {
    return null;
  }

  return (
    <MenuItem {...props}>
      {Boolean(visibleStudentViewElements?.length) && (
        <StudentPageSelect
          isStudentView={isLearner}
          selectedStudentViewId={queryParams?.studentElementId || ''}
          options={visibleStudentViewElements?.map((view) => ({
            title:
              view.metadata?.activityTitle ||
              currentElement?.metadata?.elementName ||
              '',
            id: view.identifier || '',
          }))}
          setStudentView={(pageId) => {
            setStudentView(pageId);
          }}
          setTeacherView={setTeacherView}
        />
      )}
    </MenuItem>
  );
};

export default ViewType;
