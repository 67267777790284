import { AssignmentHeader, Footer } from '../../components';
import { useQuery } from '@apollo/client';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { TeacherPages } from '@suit/pages';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { sxLightScrollBar } from '@lon/shared/constants';
import { QueryAssignmentArgs } from '@lon/shared/gql/types';
import { useQueryParams } from '@lon/shared/hooks';
import { routes } from '@lon/suit/configs';
import { queries } from './duck';

const GradeAssignment = () => {
  const [queryParams] = useQueryParams();
  const toolConsumerCode = (queryParams.get('tool_consumer_code') ||
    '') as string;
  const navigate = useNavigate();
  const { assignmentId } = useParams();
  const { data, loading: loadingAssignment } = useQuery<
    queries.AssignmentResponse,
    QueryAssignmentArgs
  >(queries.GET_ASSIGNMENT, {
    variables: {
      // TODO remove replace when backend fix assignmentId
      id: assignmentId?.replace('_', '#'),
    },
  });

  console.log(`toolConsumerCode`, toolConsumerCode)

  return (
    <Flex direction="column" height="100vh">
      <AssignmentHeader
        loading={loadingAssignment}
        elementName={
          data?.assignment?.assignmentSource?.element?.elementName || ''
        }
        sectionName={
          data?.assignment?.assignmentSource?.element?.sectionName || ''
        }
        scopeName={data?.assignment?.assignmentSource?.element?.scopeName || ''}
        action={
          toolConsumerCode === 'schoology' && (
            <Button
              size="sm"
              variant="solid"
              alignSelf="center"
              leftIcon={<Icon name="subscription-outlined" />}
              onClick={() => {
                navigate(-1);
              }}
            >
              Assignment Grades
            </Button>
          )
        }
      />
      <VStack
        bgColor="white"
        width="100%"
        gridGap="4"
        p={8}
        borderRadius="md"
        overflow="auto"
        sx={sxLightScrollBar}
        flexGrow="1"
      >
        <TeacherPages.Grading
          backPath={
            toolConsumerCode !== 'schoology'
              ? routes.lti.progressGrading
              : undefined
          }
          isLti
        />
      </VStack>
      <Footer />
    </Flex>
  );
};

export default GradeAssignment;
