import { useEffect, useState } from 'react';
import { ReceivedMessage } from '@lon/shared/enums';

const useCheckIfContentExists = () => {
  const [contentExists, setContentExists] = useState<boolean>(false);

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (message?.data?.type === ReceivedMessage.Resize) {
        if (message?.data?.payload?.content?.length) {
          setContentExists(true);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  return contentExists;
};

export default useCheckIfContentExists;
