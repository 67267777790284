import { createContext } from 'react';

export interface CardListContextProps {
  itemsCount: number;
  selectedGrade?: string;
  focusIndex: number;
  setFocusIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedIndex: number | null;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
}

export const CardListContext = createContext<CardListContextProps>({
  itemsCount: 0,
  focusIndex: 0,
  setFocusIndex: () => {},
  selectedIndex: null,
  setSelectedIndex: () => {},
});
