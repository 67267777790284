import { gql } from '@apollo/client';
import { Assignment } from '@lon/shared/gql/types';
import { GqlResponse } from '@lon/shared/types';

export type AssignmentResponse = GqlResponse<Assignment, 'assignment'>;

export const GET_ASSIGNMENT = gql(/* GraphQL */ `
  query getAssignment($id: String) {
    assignment(id: $id, lti: true) {
      assignmentSource {
        element {
          elementName
          scopeName
          sectionName
        }
      }
    }
  }
`);
