import {
  IconButton as ChakraIconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import React from 'react';

const IconButton: React.FC<IconButtonProps> = ({ size = 'md', ...rest }) => {
  return <ChakraIconButton size={size} {...rest} />;
};

export default IconButton;
