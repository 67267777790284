import { Icon } from '../icon';
import { Tag, Text } from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { FC } from 'react';
import { EARLY_DATE } from '@lon/shared/constants';
import { useGradeLevel } from '@lon/shared/hooks';
import { StudentAssignmentStatus } from '@lon/shared/requests';

type Props = {
  status?: StudentAssignmentStatus;
  endDate?: string | null;
  submittedAt?: string | null;
};

const StudentAssignmentDate: FC<Props> = ({ endDate, submittedAt }) => {
  const { isStudentBelowK3 } = useGradeLevel();
  const isLate =
    (submittedAt
      ? parseISO(submittedAt).setHours(0, 0, 0, 0)
      : new Date().setHours(0, 0, 0, 0)) -
      parseISO(endDate || EARLY_DATE).setHours(0, 0, 0, 0) >
    0;

  if (isLate && !submittedAt) {
    return (
      <Tag variant="status" backgroundColor="white" color="danger.800">
        <Text mr={2} variant="s2" w="93px" textAlign="center">
          ---
        </Text>
        <Icon
          name={isStudentBelowK3 ? 'hourGlassK12' : 'hourGlass'}
          size="sm"
          width="16px"
          height="16px"
        />
      </Tag>
    );
  }

  if (isLate && submittedAt) {
    return (
      <Tag variant="status" backgroundColor="white" color="danger.800">
        <Text mr={2} variant="s2" w="93px" whiteSpace="nowrap">
          {format(parseISO(submittedAt), 'MM/dd/yyyy')}
        </Text>
        <Icon
          name={isStudentBelowK3 ? 'hourGlassK12' : 'hourGlass'}
          size="sm"
          width="16px"
          height="16px"
        />
      </Tag>
    );
  }

  if (!isLate && !submittedAt) {
    return (
      <Tag variant="status" backgroundColor="white" color="secondary.300">
        <Text mr={2} variant="s2" w="93px" textAlign="center">
          ---
        </Text>
        <Icon
          name={isStudentBelowK3 ? 'stopOutlinedK12' : 'stopOutlined'}
          size="sm"
          width="16px"
          height="16px"
        />
      </Tag>
    );
  }

  if (!isLate && submittedAt) {
    return (
      <Tag variant="status" backgroundColor="white" color="primary.800">
        <Text mr={2} variant="s2" w="93px" whiteSpace="nowrap">
          {format(parseISO(submittedAt), 'MM/dd/yyyy')}
        </Text>
        <Icon
          name={
            isStudentBelowK3 ? 'checkCircleOutlinedK12' : 'checkCircleOutlined'
          }
          size="md"
          width="16px"
          height="16px"
        />
      </Tag>
    );
  }

  return null;
};

export default StudentAssignmentDate;
