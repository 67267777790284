import { Flex, MenuItem, MenuItemProps, Tooltip } from '@chakra-ui/react';

export interface ItemProps extends MenuItemProps {
  label: string;
  to?: string;
}

export const Item: React.FC<ItemProps> = ({ label, children, ...rest }) => (
  <Tooltip
    variant="dark"
    label={label}
    isDisabled={rest.isDisabled}
    maxW="330px"
  >
    <MenuItem aria-label={label} p="0" {...rest}>
      <Flex flex="1" gap={3.5} py="2" px="6">
        {children}
      </Flex>
    </MenuItem>
  </Tooltip>
);
