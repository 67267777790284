import { AnyAction, Store, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import {
  createStateSyncMiddleware,
  initMessageListener,
} from 'redux-state-sync';
import reducers from './reducers';

export * from './scopes';
export * from './filters';
export * from './planner';
export * from './types';

const config = {
  whitelist: ['APP/SET_AUTH'],
};

const middlewares = [createStateSyncMiddleware(config)];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(reducers, {}, composedEnhancers);
const persistor = persistStore(store);

initMessageListener(store as Store<any, AnyAction>);

export { store, persistor };
export default store;
