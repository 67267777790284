import { TabList, Tabs, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomTab } from '@lon/shared/components';

const SpeakingRate: React.FC<{
  data: { key: string; tab: string }[];
  name: string;
  isStudentBelowK3: boolean;
}> = ({ data, name, isStudentBelowK3 }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });
  const { t } = useTranslation();

  return (
    <>
      <Text
        variant="n1"
        mb={1}
        fontWeight={600}
        lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
      >
        {t('userSettings.accessibility.speakingRate')}
      </Text>
      <Tabs
        variant="drawerInternal"
        textAlign="left"
        pb={1}
        isFitted
        index={data?.findIndex((v) => v?.key === value) || 0}
      >
        <TabList width="fit-content">
          {data?.map(({ key, tab }) => (
            <CustomTab
              name={name}
              darkschema
              key={tab}
              onClick={(event) => {
                onChange(key);
              }}
              justifyContent="left"
              overflowY="hidden"
              minWidth="fit-content"
              paddingInline={4}
            >
              {t(tab)}
            </CustomTab>
          ))}
        </TabList>
      </Tabs>
    </>
  );
};

export default SpeakingRate;
