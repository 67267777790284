/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { useGetTheme } from '@lon/shared/hooks';
import { useGetScopesQuery } from '@lon/shared/requests';
import { getThemeStyleValue } from '@lon/shared/utils';

const Banner: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const { currentSchoolId = '' } = useContext(WorkingLocation);

  const data = null;

  // TO DO after backend implementation
  // const { data, loading } = useGetScopesQuery({
  //   variables: {
  //     schoolIds: [currentSchoolId],
  //   },
  //   skip: !currentSchoolId,
  // });

  if (!data) {
    return <></>;
  }

  return (
    <Box mr={6} ml={4} pos="relative">
      <Flex
        justifyContent="space-between"
        background={getThemeStyleValue(
          'linear-gradient(0.24deg, rgba(30, 65, 124, 0) -4.07%, rgba(29, 50, 87, 0.7) 50.03%),linear-gradient(271.42deg, rgba(19, 95, 55, 0) 21.56%, rgba(7, 97, 104, 0.8) 90.39%), url(suit/assets/numeracy-banner.png) lightgray 49% 50% / 109.861% 485.104% no-repeat',
          'secondary.1000'
        )(currentTheme)}
        backgroundSize="cover"
        border={getThemeStyleValue('none', '1px solid #FFFFFF')(currentTheme)}
        color="white"
        minH="362px"
        mt={4}
        mb={10}
        borderRadius="6px"
        overflow="hidden"
        boxShadow="0px 5px 20px -2px #2B364612"
      >
        <Flex px={6} py="1.875rem" gap={4} maxW="600px" flexDir="column">
          <Text variant="h6">{t('howToScope.bannerTitle')}</Text>
          <Button
            variant="outlineDark"
            justifyContent="space-between"
            px={3}
            rightIcon={<Icon name="arrow-right-outlined" />}
            backgroundColor="transparent"
            // TO DO after backend implementation
            onClick={() => {}}
          >
            {t('howToScope.science')}
          </Button>
          <Button
            variant="outlineDark"
            justifyContent="space-between"
            px={3}
            rightIcon={<Icon name="arrow-right-outlined" />}
            backgroundColor="transparent"
            // TO DO after backend implementation
            onClick={() => {}}
          >
            {t('howToScope.math')}
          </Button>
          <Text variant="s3" fontStyle="italic" whiteSpace="pre-wrap">
            {t('howToScope.bannerDescription')}
          </Text>
        </Flex>
        <Box pos="absolute" right="50px" bottom="-60px">
          <Icon width="187px" height="200px" name="numeracyRobo" />
        </Box>
      </Flex>
    </Box>
  );
};

export default Banner;
