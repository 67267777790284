import { GradeTag } from '../grade-tag';
import { Icon } from '@chakra-ui/react';
import { FC } from 'react';
import { exclamationOutlined, minus } from '@lon/shared/assets';
import { Props } from './duck/types';

const GradeStatus: FC<Props> = ({
  grade,
  submittedAt,
  completedAt,
  isGraded,
  isAverage,
  notAssigned,
  isToBeGraded = false,
}) => {
  const isCompleted = !!submittedAt && !!completedAt;

  switch (true) {
    case notAssigned && !isAverage:
      return null;
    case isToBeGraded && !isAverage:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
        >
          <Icon as={exclamationOutlined} fontSize="1.25rem" />
        </GradeTag>
      );
    case (!isCompleted || !isGraded) && !isAverage:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
        >
          <Icon as={minus} fontSize="1.25rem" />
        </GradeTag>
      );
    case (isCompleted || isAverage) && typeof grade === 'number' && grade >= 90:
      return (
        <GradeTag
          backgroundColor="olive.50"
          borderColor="green.extraLight"
          color="olive.100"
        >
          {grade}
        </GradeTag>
      );
    case (isCompleted || isAverage) && typeof grade === 'number' && grade >= 80:
      return (
        <GradeTag
          backgroundColor="warning.extraLight"
          borderColor="yellow.dark"
          color="warning.text"
        >
          {grade}
        </GradeTag>
      );
    case (isCompleted || isAverage) && typeof grade === 'number' && grade >= 70:
      return (
        <GradeTag
          backgroundColor="orange.extraLight"
          borderColor="orange.dark"
          color="orange.900"
        >
          {grade}
        </GradeTag>
      );
    case (isCompleted || isAverage) && typeof grade === 'number' && grade < 70:
      return (
        <GradeTag
          backgroundColor="danger.extraLight"
          borderColor="danger.75"
          color="danger.text"
        >
          {grade}
        </GradeTag>
      );
    default:
      return (
        <GradeTag
          backgroundColor="primary.30"
          borderColor="primary.50"
          color="primary.800"
        >
          <Icon as={minus} fontSize="1.25rem" />
        </GradeTag>
      );
  }
};

export default GradeStatus;
