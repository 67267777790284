import { lazyWithRetry } from '@lon/shared/utils';

export * as ClassRoasterTabs from './class-roaster/tabs';

const ClassSettings = lazyWithRetry(
  () => import('./class-settings'),
  'ClassSettings'
);
const ClassRoaster = lazyWithRetry(
  () => import('./class-roaster'),
  'ClassRoaster'
);
const GradeBook = lazyWithRetry(() => import('./gradebook'), 'GradeBook');

export { ClassSettings, ClassRoaster, GradeBook };
