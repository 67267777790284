export const LOGIN_ERRORS: Record<
  string,
  {
    message: string;
    title: string;
  }
> = {
  BLANK_FIELD: {
    title: 'Error',
    message: "can't be blank",
  },
  FIND_URL_BLANK_FIELD: {
    title: 'Error',
    message: 'This field could not be empty',
  },
  INVALID_CREDENTIALS: {
    title: 'Incorrect credentials',
    message: 'Verify your Username and Password and try again.',
  },
  GENERAL_ERROR: {
    title: 'Error',
    message: 'Some error has occurred. Please try again later.',
  },
  LOGIN_LOCKED: {
    title: 'Account is temporarily blocked',
    message:
      'Your account is temporarily blocked for 15 minutes for security reasons. Please try again in 15 minutes.',
  },
  PREVIEW_ACCOUNT_PERIOD_HAS_NOT_STARTED: {
    title: 'Error',
    message: 'Preview Account period hasn’t started',
  },
  PREVIEW_ACCOUNT_HAS_EXPIRED: {
    title: 'Error',
    message: 'Preview Account has expired',
  },
  INACTIVE_USER: {
    title: 'Account is inactive',
    message:
      'Your account is inactive. Please contact your administrator for the detailed information.',
  },
  PREVIEW_ACCOUNT_HAS_NO_PRODUCTS: {
    title: 'No related Products',
    message:
      'No related Products with the Preview Account. Product deleted in the external system.',
  },
  PREVIEW_ACCOUNT_SELF_REGISTRATION_REQUIRED: {
    title: 'Preview Account registration required',
    message: '',
  },
  MFA_CODE_REQUIRED: {
    title: 'Multi Factor Authentication required',
    message: '',
  },
  INVALID_MFA_CODE: {
    title: 'Verification Code is invalid',
    message: '',
  },
  MFA_CODE_EXPIRED: {
    title: 'Verification Code is expired',
    message: '',
  },
};
