import { Flex } from '@chakra-ui/react';
import {
  sxHighContrastScrollBar,
  sxSidebarDarkScrollBar,
} from '@lon/shared/constants';
import { useScrollExisting, useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';

const MiddleHighStudentWrapper: React.FC<
  types.MiddleHighStudentWrapperProps
> = ({ leftColumnComponent, leftContentWrapperProps }) => {
  const {
    contentRef,
    contentScrolls: { vertical: hasContentScroll },
  } = useScrollExisting({
    horizontal: false,
  });

  return (
    <Flex
      ref={contentRef}
      flexGrow={1}
      direction="column"
      overflowX="hidden"
      h="full"
      pt={6}
      pl={6}
      pr={hasContentScroll ? 3.5 : 6}
      pb={6}
      sx={useThemeStyleValue(sxSidebarDarkScrollBar, sxHighContrastScrollBar)}
      {...leftContentWrapperProps}
    >
      {leftColumnComponent}
    </Flex>
  );
};

export default MiddleHighStudentWrapper;
