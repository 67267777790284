import { request } from '@lon/api';

const configureRequest = (): void => {
  request.defaults.headers.common = {
    ...request.defaults.headers.common,
    'X-Key-Inflection': 'camel',
  };

  request.defaults.baseURL = process.env.NX_API_URL;
};

export default configureRequest;
