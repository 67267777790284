export const Breadcrumb = {
  baseStyle: {
    link: {
      color: 'blue.500',
      textDecoration: 'underline',
      fontWeight: 700,
      fontSize: '0.9rem',
      lineHeight: '20px',

      _hover: {
        textDecoration: 'node',
        color: 'blue.400',
      },
    },
    list: {
      flexWrap: 'wrap',
    },
  },
};
