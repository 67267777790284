import { filterConstants } from '../../../../duck';
import { ButtonSkeleton } from '../button-skeleton';
import { Box, Button, Flex, ListItem, UnorderedList } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { useGetTheme } from '@lon/shared/hooks';
import { useGetRecentlyViewedQuery } from '@lon/shared/requests';
import { getThemeStyleValue } from '@lon/shared/utils';

const RecentlyViewed: React.FC = () => {
  const { currentSchoolId } = useContext(WorkingLocation);
  const { data: recentlyViewedData, loading } = useGetRecentlyViewedQuery({
    fetchPolicy: 'network-only',
    variables: { schoolId: currentSchoolId as string },
  });
  const theme = useGetTheme();

  const data = useMemo(
    () =>
      recentlyViewedData?.recentlyViewed?.displayedNames?.map(
        (name, index) => ({
          name,
          id: recentlyViewedData.recentlyViewed?.objectIds[index] || '',
          projectId: recentlyViewedData.recentlyViewed?.projectIds[index] || '',
        })
      ),
    [recentlyViewedData]
  );

  if (loading) {
    return (
      <Flex direction="column" gap={4}>
        {new Array(filterConstants.RECENT_SCOPES_LIMIT)
          .fill(ButtonSkeleton)
          .map((item, key) => React.createElement(item, { key }))}
      </Flex>
    );
  }

  const hasTags = !!data?.length;

  return hasTags ? (
    <Box>
      <UnorderedList
        display="flex"
        flexDir="column"
        gap={4}
        role="menu"
        listStyleType="none"
        marginLeft="0px"
      >
        {(data || []).map((item) => (
          <ListItem key={item.id}>
            <Button
              wordBreak="break-word"
              height="auto"
              whiteSpace="pre-wrap"
              role="menuitem"
              w="full"
              as={Link}
              to={`${item.projectId}/${item.id}`}
              textAlign="left"
              justifyContent="flex-start"
              {...getThemeStyleValue(
                {
                  variant: 'outline',
                  bgColor: 'white',
                  border: 'none',
                  _focusVisible: {
                    border: 'none',
                    boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
                    outline: '2px solid transparent',
                    outlineOffset: '2px',
                    backgroundColor: 'primary.300',
                    color: 'white',
                    textDecoration: 'none',
                  },
                  _hover: {
                    background: 'primary.50',
                    backgroundColor: 'primary.600',
                    color: 'white',
                  },
                },
                { variant: 'solid' }
              )(theme)}
            >
              {item.name}
            </Button>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  ) : null;
};

export default RecentlyViewed;
