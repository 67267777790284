import { FormRadioButtonListProps } from '../duck';
import { get } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BaseRadioButtonList } from './BaseRadioButtonList';

export const FormRadioButtonList: React.FC<FormRadioButtonListProps> = (
  props
) => {
  const formContext = useFormContext();
  const errorMessage = useMemo(
    () => get(formContext.formState.errors, `${props.name}.message`),
    [formContext.formState.errors, props.name]
  );
  const value = useWatch({ name: props.name });

  const handleChangeRadioButtonList = useCallback(
    (value: string) => {
      formContext.setValue(props.name, value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    [props.name, formContext.setValue]
  );

  return (
    <BaseRadioButtonList
      {...props}
      errorMessage={errorMessage as any}
      selectedValue={value}
      onChange={handleChangeRadioButtonList}
      value={value}
    />
  );
};

export default FormRadioButtonList;
