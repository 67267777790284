import { Button, Flex, Tag, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { routes } from '@lon/suit/configs';
import { useStudentsNavigation } from './duck';

const Toolbar: React.FC<{ isLoading?: boolean }> = ({ isLoading }) => {
  const { assignmentId, listId } = useParams();
  const { t } = useTranslation();

  const {
    studentName,
    loading,
    currentStudentIndex,
    amountOfStudents,
    nextStudent,
    previousStudent,
  } = useStudentsNavigation();

  const resultLoading = isLoading || loading;

  return (
    <Flex
      alignItems="center"
      px={6}
      py={2}
      backgroundColor="white"
      boxShadow="0px 5px 20px -2px #2B364612"
      borderX="1px solid"
      borderColor="blue.75"
      height="64px"
    >
      <Flex
        gap={4}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Flex gap={4} alignItems="center">
          <Text variant="n1" color="primary.700">
            {studentName}
          </Text>

          {!loading && (
            <Tag
              size="sm"
              variant="subtle"
              borderRadius="20px"
              py={0.5}
              px={2.5}
              h="fit-content"
            >
              <Text variant="tag" fontSize={12}>
                {`${currentStudentIndex + 1} of ${amountOfStudents}`}
              </Text>
            </Tag>
          )}
        </Flex>

        <Flex gap={4}>
          <Tooltip
            variant="dark"
            label={t('studentAssignmentDetails.previousStudentTooltip')}
          >
            <Button
              leftIcon={<Icon name="arr-left-outlined" />}
              size="sm"
              isDisabled={resultLoading}
              {...(previousStudent
                ? {
                    as: Link,
                    to: generatePath(routes.classes.studentAssignmentDetails, {
                      assignmentId,
                      studentId: previousStudent.studentId,
                      sessionId: previousStudent.id,
                      listId,
                    }),
                  }
                : { isDisabled: true })}
            >
              {t('studentAssignmentDetails.previousStudent')}
            </Button>
          </Tooltip>
          <Tooltip
            variant="dark"
            label={t('studentAssignmentDetails.nextStudentTooltip')}
          >
            <Button
              rightIcon={<Icon name="ArrowRight" />}
              size="sm"
              isDisabled={resultLoading}
              {...(nextStudent
                ? {
                    as: Link,
                    to: generatePath(routes.classes.studentAssignmentDetails, {
                      assignmentId,
                      studentId: nextStudent.studentId,
                      sessionId: nextStudent.id,
                      listId,
                    }),
                  }
                : { isDisabled: true })}
            >
              {t('studentAssignmentDetails.nextStudent')}
            </Button>
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Toolbar;
