import { gql } from '@apollo/client';
import { UpdateClassPayload } from '@lon/shared/gql/types';
import { GqlResponse } from '@lon/shared/types';

export type UpdateClassResponse = GqlResponse<
  UpdateClassPayload,
  'updateClass'
>;

export const UPDATE_CLASS = gql(/* GraphQL */ `
  mutation updateClass($input: updateClassInput!) {
    updateClass(input: $input) {
      class {
        _id
      }
    }
  }
`);
