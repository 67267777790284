export const Menu = {
  baseStyle: {
    button: {
      borderWidth: '3px',
      borderColor: 'gray.100',
      _hover: {
        borderColor: 'blue.100',
      },
      _focus: {
        borderColor: 'blue.100',
      },
    },
    item: {
      px: 5,
      color: 'primary.800',
      border: '4px solid transparent',
      _hover: {
        bg: 'secondary.100',
      },
      _focusVisible: {
        zIndex: 2,
        bg: 'secondary.100',
        border: 'focusBlue !important',
        boxShadow: 'none',
      },
    },
    list: {
      zIndex: 'dropdown',
      borderRadius: '0px 0px 4px 4px',
      boxShadow: '0px 5px 20px -2px rgba(43, 54, 70, 0.07)',
    },
  },
  variants: {
    secondary: {
      item: {
        border: 'none',
        p: '4px',
        fontWeight: 700,
        fontSize: 'sm',
        ':not(:last-child)': {
          borderBottom: '.5px solid black',
        },
        _focusVisible: {
          zIndex: 2,
          bg: 'secondary.100',
          border: 'focusBlue !important',
          boxShadow: 'none',
        },
      },
      groupTitle: {
        backgroundColor: 'primary.800',
        borderRadius: 0,
        color: 'white',
        display: 'flex',
        padding: '10px 8px',
        margin: 0,
        fontWeight: 700,
      },
      list: {
        padding: 0,
        borderRadius: 0,
        width: '300px',
        border: '.5px solid black',
      },
    },
    primary: {
      list: {
        borderRadius: '6px',
        border: 'none',
        boxShadow: 'pane',
      },
    },
    actions: {
      button: {
        _focusVisible: {
          zIndex: 2,
          bg: 'secondary.100',
          border: 'focusBlue !important',
          boxShadow: 'none',
        },
      },
      list: {
        borderRadius: '6px',
        border: 'none',
        boxShadow: 'pane',
        borderColor: 'secondary.200',
      },
      groupTitle: {
        color: 'primary.400',
        textTransform: 'uppercase',
        fontSize: '0.75rem',
        lineHeight: '1.125rem',
        fontWeight: 400,
        padding: '0.5rem 1.5rem',
        margin: 0,
      },
    },
  },
};
