import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { utils } from '../duck';
import {
  Box,
  Button,
  MenuItem as ChakraMenuItem,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';

/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Icon } from 'libs/shared/components/src/icon';
import { get } from 'lodash-es';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  CONTENT_ACTIONS_TOOLBAR_HEIGHT,
  DESKTOP_CONTENT_PADDING,
  PAGE_HEADER_HEIGHT,
  SITE_FOOTER_HEIGHT,
  SITE_HEADER_HEIGHT,
  TOOLBAR_HEIGHT,
  TOOLBAR_MENU_CONTENT_PADDING,
  isDevMode,
  sxLightScrollBar,
} from '@lon/shared/constants';
import { useAuth } from '@lon/shared/hooks';
import { checkContentPlayer } from '@lon/shared/utils';
import { ResourceSectionsListContext } from '@lon/suit/contexts';
import { useCurrentElement, useOutsideClick } from '@lon/suit/hooks';
import { File, HideElement } from '@lon/suit/types';
import { ViewFilesProps } from './duck';

/* eslint-disable @nrwl/nx/enforce-module-boundaries */

const getFileTitle = (title: string, metadata?: Record<string, any>) =>
  (Array.isArray(metadata?.fileTitle)
    ? metadata?.fileTitle?.[0]
    : metadata?.fileTitle) || title;

const ViewFiles: React.FC<ViewFilesProps> = ({
  element,
  isParentSuite,
  ...props
}) => {
  const { t } = useTranslation();
  const [{ user }] = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const isContentPlayer = checkContentPlayer();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams() as {
    elementId: string;
    sectionId?: string;
    scopeId: string;
    projectId: string;
  };

  const elementId = (element ? element.identifier : params.elementId) || '';
  const sectionId = params.sectionId;
  const scopeId = (element ? element.scopeId : params.scopeId) || '';
  const projectId = (element ? element.projectId : params.projectId) || '';

  const { elementsBySection } = React.useContext(ResourceSectionsListContext);

  const resourceElement = sectionId
    ? elementsBySection[sectionId]?.find(
        (value) => value.identifier === elementId
      )
    : undefined;
  const scopeContextCurrentElement = useCurrentElement();
  const currentElement = element ? element : scopeContextCurrentElement;

  const files = (resourceElement?.elementFiles ||
    get(currentElement, `elementFiles`, [])) as File[];

  const menuButtonRef = useRef<HTMLElement>(null);
  const menuRef = useOutsideClick<HTMLDivElement>((event) => {
    if (
      isOpen &&
      (event.target === window ||
        !menuButtonRef.current?.contains(event.target as Node))
    ) {
      onClose();
    }
  });

  const printPreviewParams = (
    fileTitle: string,
    metadata?: Record<string, any>
  ) => {
    const elementIcon = get(currentElement, `metadata.elementIconType`, '');
    const title = getFileTitle(fileTitle, metadata) || '';
    const editable = metadata?.editable !== HideElement.No;
    const pageOrientation = metadata?.page_orientation || '';
    const nameDateLine = metadata?.nameDateLine || 'Yes';

    return encodeURIComponent(
      `?mode=print&printType=manual&userId=${user?.userId}&projectId=${projectId}&scopeId=${scopeId}&elementName=${title}&isEpub=true&editable=${editable}&pageOrientation=${pageOrientation}&nameDateLine=${nameDateLine}&elementIconType=${elementIcon}`
    );
  };

  if (!files.length) {
    return null;
  }

  return (
    <MenuItem {...props}>
      {(menuItemProps: any) => (
        <Menu
          placement="bottom"
          gutter={4}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        >
          <Tooltip
            variant="dark"
            label={
              isOpen
                ? t('toolbar.hideFilesTooltip')
                : t('toolbar.showFilesTooltip')
            }
            aria-hidden={true}
          >
            <MenuButton
              ref={menuButtonRef}
              isActive={isOpen}
              title={t('toolbar.files')}
              as={Button}
              variant="ghost"
              className={actionsStyles.action}
              aria-label={t('toolbar.files')}
              leftIcon={<Icon name="view-files-outlined" size="lg" />}
              rightIcon={
                <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} size="lg" />
              }
              _active={{
                backgroundColor: 'primary.800',
                color: 'white',
              }}
              {...(isMobile && {
                px: '12px',
                sx: {
                  '.chakra-button__icon': {
                    mr: 0,
                  },
                },
              })}
              {...menuItemProps}
            >
              {!isMobile && (
                <Text as="span" isTruncated variant="s2">
                  {t('toolbar.viewFiles')}
                </Text>
              )}
            </MenuButton>
          </Tooltip>
          <MenuList
            wordBreak="break-word"
            onKeyDown={utils.closeMenuByPressXArrow}
            data-focus-visible-disabled
            w={{ base: '100vw', sm: '368px' }}
            backgroundColor="secondary.50"
            py={0}
            borderBottomRadius="md"
            ref={menuRef}
          >
            <Box
              px="4"
              pb="4"
              borderRadius="md"
              sx={{
                '.chakra-menu__group__title': {
                  color: 'primary.500',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                  fontWeight: '400',
                  m: '6',
                },
              }}
            >
              <MenuGroup title={t('toolbar.viewFilesGroupTitle')}>
                <Box
                  maxH={`calc(100vh - (${
                    SITE_HEADER_HEIGHT +
                    PAGE_HEADER_HEIGHT +
                    TOOLBAR_HEIGHT +
                    CONTENT_ACTIONS_TOOLBAR_HEIGHT +
                    SITE_FOOTER_HEIGHT +
                    DESKTOP_CONTENT_PADDING +
                    TOOLBAR_MENU_CONTENT_PADDING
                  }px))`}
                  overflowY="auto"
                  css={sxLightScrollBar}
                >
                  {files.map(({ title, href, metadata }, index) => {
                    const fileTitle = getFileTitle(title, metadata);

                    if (isParentSuite && fileTitle?.includes('Answer Key')) {
                      return null;
                    }

                    const isEpub =
                      !isContentPlayer && /\.html?$/i.test(href?.split('?')[0]);
                    const printQueryParams = printPreviewParams(
                      title,
                      metadata
                    );

                    const epubUrl = isDevMode()
                      ? '/public/epub.html?url=/public/test.html' +
                        printQueryParams
                      : `/suite/epub.html?url=${href}` + printQueryParams;

                    return (
                      <ChakraMenuItem
                        key={title}
                        as={Link}
                        href={isEpub ? epubUrl : href}
                        target="_blank"
                        data-testid={fileTitle}
                        p={0}
                        position="relative"
                        {...(index !== files.length - 1 && {
                          borderBottomWidth: '1px',
                          borderBottomStyle: 'solid',
                          borderBottomColor: 'secondary.100',
                        })}
                        {...(index === 0 && {
                          borderTopRadius: 'md',
                        })}
                        {...(index === files.length - 1 && {
                          borderBottomRadius: 'md',
                        })}
                      >
                        <Tooltip
                          variant="dark"
                          label={t('toolbar.openStudentPage', {
                            name: fileTitle,
                          })}
                          aria-hidden={true}
                        >
                          <Flex flex="1" px="6" py="10px">
                            <Text
                              variant="s2"
                              wordBreak="break-word"
                              maxW="290px"
                            >
                              {fileTitle}
                            </Text>
                          </Flex>
                        </Tooltip>
                      </ChakraMenuItem>
                    );
                  })}
                </Box>
              </MenuGroup>
            </Box>
          </MenuList>
        </Menu>
      )}
    </MenuItem>
  );
};

export default ViewFiles;
