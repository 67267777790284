import { Icon } from '../../../icon';
import { Box, UseRadioProps, useRadio } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { getComponents } from '@lon/shared/assets';
import { ThemeEnum } from '@lon/shared/enums';

const { Button: ButtonStyles } = getComponents(ThemeEnum.DEFAULT);

const RadioCard: FC<PropsWithChildren<UseRadioProps>> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      as="label"
      _first={{
        _notLast: {
          '& div': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      }}
      _notFirst={{
        _notLast: {
          '& div': { borderRadius: 0 },
        },
        _last: {
          '& div': { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
        },
      }}
      _notLast={{
        marginInlineEnd: '-1px',
      }}
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        px={5}
        py={3}
        {...ButtonStyles.baseStyle}
        boxSizing="border-box"
        {...ButtonStyles.variants.outline}
        transitionProperty="common"
        transitionDuration="normal"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        height={10}
        minH="44px"
        _checked={{
          bg: 'secondary.50',
          _hover: ButtonStyles.variants.outline._hover,
          _focusVisible: ButtonStyles.variants.outline._focusVisible,
          _active: ButtonStyles.variants.outline._active,
        }}
        gap={2}
      >
        {input['checked'] && <Icon name="checkCircleOutlined" size="xl" />}
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;
