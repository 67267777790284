import { Button, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { useMediaQuery } from '@lon/shared/hooks';

const Print: React.FC<{ printMethod?: () => void }> = ({ printMethod }) => {
  const { t } = useTranslation();

  const [isMobile] = useMediaQuery('(max-width: 375px)');

  return (
    <Tooltip
      variant="dark"
      label={t('helpPage.printTooltip')}
      aria-hidden={true}
      placement="bottom"
      hasArrow={false}
    >
      <Button
        aria-label={t('toolbar.print')}
        variant="ghost"
        leftIcon={<Icon name={'printer-outlined'} width="20px" height="20px" />}
        onClick={printMethod ? printMethod : () => {}}
        size="md"
        {...(isMobile && {
          px: '10px',
          sx: {
            '.chakra-button__icon': {
              mr: 0,
            },
          },
        })}
        _hover={{
          backgroundColor: 'primary.30',
        }}
      >
        {!isMobile && (
          <Text as="span" isTruncated variant="s2">
            {t('toolbar.print')}
          </Text>
        )}
      </Button>
    </Tooltip>
  );
};

export default Print;
