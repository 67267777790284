import { Icon } from '../icon';
import { Box, Button, Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hiddenElementsTypes } from './duck';

const HiddenElements: FC<hiddenElementsTypes.HiddenElementsProps> = ({
  children,
  openModal,
  maxCellHeight = '88px',
  singleLine,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [hiddenCount, setHiddenCount] = useState(0);

  const calculateHidden = () => {
    if (containerRef.current) {
      setHiddenCount(
        Array.prototype.slice
          .call(
            (containerRef.current as HTMLDivElement)?.children?.[0]?.children
          )
          .filter((item) => {
            return item.offsetTop > 80;
          })?.length || 0
      );
    }
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeLeftContentObserver = new ResizeObserver(() => {
      calculateHidden();
    });

    resizeLeftContentObserver.observe(containerRef.current);

    return () => {
      resizeLeftContentObserver.disconnect();
    };
  }, []);

  return (
    <Flex direction="column">
      <Box
        ref={containerRef}
        maxW="490px"
        maxH={maxCellHeight}
        overflow="hidden"
      >
        <Flex gap={2} wrap="wrap" direction={singleLine ? 'column' : 'unset'}>
          {children}
        </Flex>
      </Box>
      {!!hiddenCount && (
        <Box>
          <Button
            mt={1}
            w="auto"
            size="xs"
            variant="ghost"
            leftIcon={<Icon name="eyeDdlOutlined" />}
            onClick={openModal}
          >
            {t('collapsedCell.showMore', { count: hiddenCount })}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default HiddenElements;
