import { Text, useToast } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import {
  ShareResults,
  useShareDistrictAssignmentResultsMutation,
} from '@lon/shared/requests';
import { types } from './duck';
import { Modal } from './components';

const ShareDistrcitAssignmentResultsModal: React.FC<
  types.ShareDistrictAssignmentResultsModalProps
> = ({ onClose, isOpen, isMultiple = false, studentsData, onCompleted }) => {
  const { t } = useTranslation();

  const [fetch, { loading }] = useShareDistrictAssignmentResultsMutation();
  const toast = useToast();

  const handleSubmit = (onCloseModal: () => void) => {
    return (values: { notes: string; includeCorrectAnswers: boolean }) => {
      fetch({
        variables: {
          params: {
            assignmentId: studentsData[0]?.assignmentId || '',
            studentIds: studentsData.map(
              ({ studentId }) => studentId as string
            ),
            shareResults: values.includeCorrectAnswers
              ? ShareResults.WithCorrectAnswers
              : ShareResults.WithoutCorrectAnswers,
            // notes: values.notes,
          },
        },
        onCompleted: () => {
          toast({
            title: t('assignments.shareSuccessToastTitle'),
            description: (
              <Text whiteSpace="pre-wrap">
                {isMultiple
                  ? t('assignments.shareSuccessToastDescriptionMultiple', {
                      amount: studentsData.length,
                    })
                  : sanitize(
                      t('assignments.shareSuccessToastDescription', {
                        name: `${studentsData[0]?.firstName} ${studentsData[0]?.lastName}`,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
              </Text>
            ),
            variant: 'success-light',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          onCompleted?.();
          onCloseModal();
        },
        onError: () => {
          toast({
            title: t('systemMessages.038'),
            status: 'error',
            variant: 'error-light',
            isClosable: true,
            duration: null,
          });
        },
      });
    };
  };

  return (
    <Modal
      isMultiple={isMultiple}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      isOpen={isOpen}
      studentName={`${studentsData[0]?.firstName} ${studentsData[0]?.lastName}`}
      withNotes={false}
    />
  );
};

export default ShareDistrcitAssignmentResultsModal;
