import { Event } from 'react-big-calendar';
import { ActionType, createReducer } from 'typesafe-actions';
import { UserCalendar } from '@lon/shared/requests';
import { CalendarsType } from '@lon/shared/types';
import {
  CalendarListType,
  CalendarSettingsDrawerType,
  NewPacingGuideType,
  PacingGuideDrawerType,
  PacingGuideListType,
  PacingGuidesDataType,
  PlansListType,
  ScopeElement,
  SettingsType,
  TeamsDataArray,
  UserSelectionType,
} from '@lon/shared/types';
import * as actions from './actions';

type PlannerActions = ActionType<typeof actions>;

const initialState: {
  calendarList: CalendarListType;
  eventList: Event[];
  templateList: Array<UserCalendar>;
  loadingCalendarList: boolean;
  calendarSettingsOpened: boolean;
  initializeUserSelection: boolean;
  userSelection: UserSelectionType;
  successUserSelection: UserSelectionType;
  calendarSettings: CalendarSettingsDrawerType;
  calendars: CalendarsType;
  pacingGuideList: PacingGuideListType;
  pacingGuideListLoading: boolean;
  selectedPacingGuide: string;
  selectedPacingGuideDetails: NewPacingGuideType;
  pacingGuideDrawer: PacingGuideDrawerType;
  initializeDownloadPdf: boolean;
  currentMonth: string | undefined;
  plans: {
    plansList: PlansListType;
    selectedPlan: string | undefined;
    selectedPlanScopeElements: ScopeElement[];
  };
  teams: {
    teamsData: TeamsDataArray;
    teamsList: Array<{ label: string; value: string }>;
    teamIds: string[];
    loading: boolean;
  };
  pacingGuidesData: PacingGuidesDataType;
  userRoleCode: string;
} = {
  calendarList: [],
  eventList: [],
  templateList: [],
  loadingCalendarList: true,
  calendarSettingsOpened: false,
  initializeUserSelection: false,
  userSelection: {
    selectedCalendar: {},
    viewMode: 'month',
  },
  successUserSelection: {
    selectedCalendar: {},
    viewMode: 'month',
  },
  calendarSettings: {
    calendarId: '',
    isDefault: false,
    settingsType: SettingsType.NEW,
    settingDrawerOpen: false,
    editable: false,
  },
  calendars: [],
  pacingGuideList: [],
  pacingGuideListLoading: true,
  selectedPacingGuide: '',
  selectedPacingGuideDetails: {},
  pacingGuideDrawer: {
    drawerOpen: false,
    drawerType: SettingsType.NEW,
    pacingGuideId: '',
  },
  initializeDownloadPdf: false,
  currentMonth: '',
  plans: {
    plansList: {
      list: [],
      fetchingPlansList: true,
    },
    selectedPlan: '',
    selectedPlanScopeElements: [],
  },
  teams: {
    teamsData: [],
    teamsList: [],
    teamIds: [],
    loading: true,
  },
  pacingGuidesData: {},
  userRoleCode: '',
};

const reducer = createReducer<typeof initialState, PlannerActions>(initialState)
  .handleAction(
    actions.setCalendarList,
    (state, { payload: calendarList }) => ({
      ...state,
      calendarList: calendarList,
    })
  )
  .handleAction(actions.setEventList, (state, { payload: eventList }) => ({
    ...state,
    eventList: eventList,
  }))
  .handleAction(
    actions.setTemplateList,
    (state, { payload: templateList }) => ({
      ...state,
      templateList: templateList,
    })
  )
  .handleAction(
    actions.setLoadingCalendarList,
    (state, { payload: loadingCalendarList }) => ({
      ...state,
      loadingCalendarList,
    })
  )
  .handleAction(
    actions.setDefaultModalOpened,
    (state, { payload: defaultOpened }) => ({
      ...state,
      calendarSettingsOpened: defaultOpened,
    })
  )
  .handleAction(
    actions.setInitializeUserSelection,
    (state, { payload: init }) => ({
      ...state,
      initializeUserSelection: init,
    })
  )
  .handleAction(
    actions.setUserSelection,
    (state, { payload: userSelection }) => ({
      ...state,
      userSelection: { ...userSelection },
    })
  )
  .handleAction(
    actions.setSuccessUserSelection,
    (state, { payload: successUserSelection }) => ({
      ...state,
      successUserSelection: { ...successUserSelection },
    })
  )
  .handleAction(
    actions.setCalendarSettings,
    (state, { payload: calendarSettings }) => ({
      ...state,
      calendarSettings: { ...calendarSettings },
    })
  )
  .handleAction(actions.setCalendars, (state, { payload: calendars }) => ({
    ...state,
    calendars,
  }))
  .handleAction(
    actions.setPacingGuideList,
    (state, { payload: pacingGuideList }) => ({
      ...state,
      pacingGuideList,
    })
  )
  .handleAction(
    actions.setPacingGuideListLoading,
    (state, { payload: pacingGuideListLoading }) => ({
      ...state,
      pacingGuideListLoading,
    })
  )
  .handleAction(
    actions.setSelectedPacingGuide,
    (state, { payload: selectedPacingGuide }) => ({
      ...state,
      selectedPacingGuide,
    })
  )
  .handleAction(
    actions.setSelectedPacingGuideDetails,
    (state, { payload: selectedPacingGuideDetails }) => ({
      ...state,
      selectedPacingGuideDetails,
    })
  )
  .handleAction(
    actions.setPacingGuideDrawer,
    (state, { payload: pacingGuideDrawer }) => ({
      ...state,
      pacingGuideDrawer: { ...pacingGuideDrawer },
    })
  )
  .handleAction(
    actions.setPacingGuidesData,
    (state, { payload: pacingGuidesData }) => ({
      ...state,
      pacingGuidesData: { ...state.pacingGuidesData, ...pacingGuidesData },
    })
  )
  .handleAction(
    actions.setInitializeDownloadPdf,
    (state, { payload: initializeDownloadPdf }) => ({
      ...state,
      initializeDownloadPdf,
    })
  )
  .handleAction(
    actions.setCurrentMonth,
    (state, { payload: currentMonth }) => ({
      ...state,
      currentMonth,
    })
  )
  .handleAction(
    actions.setPlansList,
    (state, { payload: { list, fetchingPlansList } }) => ({
      ...state,
      plans: {
        ...state.plans,
        plansList: {
          list,
          fetchingPlansList: fetchingPlansList,
        },
      },
    })
  )
  .handleAction(
    actions.setSelectedPlan,
    (state, { payload: selectedPlan }) => ({
      ...state,
      plans: {
        ...state.plans,
        selectedPlan: selectedPlan,
      },
    })
  )
  .handleAction(
    actions.setSelectedPlanScopeElements,
    (state, { payload: scope }) => ({
      ...state,
      plans: {
        ...state.plans,
        selectedPlanScopeElements: scope,
      },
    })
  )
  .handleAction(actions.setTeamsData, (state, { payload: teamsData }) => ({
    ...state,
    teams: {
      ...state.teams,
      teamsData: teamsData,
      teamsList: teamsData
        ? teamsData?.map((team) => ({
            label: team?.name || '',
            value: team?.id || '',
          }))
        : [],
      teamIds: teamsData ? teamsData?.map((team) => team?.id || '') : [],
      loading: false,
    },
  }))
  .handleAction(
    actions.setUserRoleCode,
    (state, { payload: userRoleCode }) => ({
      ...state,
      userRoleCode,
    })
  )
  .handleAction(actions.resetPlannerData, () => ({
    ...initialState,
  }));

export default reducer;
