import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import { PlansDrawer } from '../../plans-drawer';
import actionsStyles from '../ToolbarActions.module.css';
import { IconButton, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { useGetSchoolIdsForPlanner } from '@lon/shared/hooks';
import { useCurrentElement } from '@lon/shared/hooks';
import {
  ScopePlanScopeElementActionTypeEnum,
  useUpdateScopePlanScopeMutation,
} from '@lon/shared/requests';
import { TransformedElement } from '@lon/shared/types';
import { scopesSelectors } from './duck';

const Planner: React.FC = (props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { application } = useContext(WorkingLocation);
  const userRoleCode = application?.replace('Suit', '') || '';
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();
  const plan = useSelector(scopesSelectors.plan);
  const userTeamIds = useSelector(scopesSelectors.userTeamIds);
  const params = useParams();
  const { projectId } = params as {
    projectId: string;
  };

  const [updateScopePlanScope, { loading: addElementLoading }] =
    useUpdateScopePlanScopeMutation();

  const currentElement = useCurrentElement() as TransformedElement;

  const planContainsElement: boolean = useMemo(() => {
    return (
      Boolean(plan.scopePlanId) &&
      Boolean(plan?.elements) &&
      plan?.elements!.findIndex(
        (ele) => ele.identifier === currentElement?.identifier
      ) !== -1
    );
  }, [plan]);

  const addElementToPlan = async (newScopePlanId?: string) => {
    const element = {
      identifier: currentElement?.identifier,
      elementId: currentElement.identifier,
      elementName:
        `${currentElement?.metadata?.elementName ?? ''}${
          currentElement?.teacherView?.metadata?.activityTitle
            ? `: ${currentElement?.teacherView?.metadata?.activityTitle}`
            : ''
        }` ?? '',
      teacherView: currentElement?.teacherView?.identifier ?? '',
      activityTime: currentElement?.metadata?.activityTime ?? '',
      scopeId: plan.scopeId,
      scopeName: plan?.scopeName,
      sectionId: currentElement?.sectionId,
      sectionName: currentElement?.sectionTitle || '',
      projectId: projectId,
    };
    try {
      if (
        (plan?.scopePlanId || newScopePlanId) &&
        plan.scopeId &&
        projectId &&
        currentElement?.identifier
      ) {
        await updateScopePlanScope({
          variables: {
            input: {
              scopePlanId: `${newScopePlanId || plan?.scopePlanId}`,
              scopeId: plan.scopeId,
              action: ScopePlanScopeElementActionTypeEnum.Add,
              elements: [element],
              schoolIds: accessibleSchoolIds,
              userRoleCode,
              userTeamIds,
              version: plan.version ?? 1,
            },
          },
          refetchQueries: ['getElementsFromPlan'],
          onCompleted() {
            toast({
              position: 'bottom',
              title: t('scope.addElementSuccess'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'success-light',
            });
          },
        });
      }
    } catch (error) {
      toast({
        position: 'bottom',
        title: t('scope.addElementFailed'),
        status: 'error',
        duration: 3000,
        isClosable: true,
        variant: 'error-light',
      });
    }
  };

  const handleClick = () => {
    if (plan.scopePlanId) {
      addElementToPlan();
    } else {
      onOpen();
    }
  };

  return (
    <>
      <MenuItem {...props} disabled={planContainsElement || addElementLoading}>
        <IconButton
          size="md"
          variant="ghost"
          className={actionsStyles.action}
          title={t('toolbar.addToPlanner')}
          aria-label={t('toolbar.addToPlanner')}
          onClick={handleClick}
          isLoading={addElementLoading}
        >
          <Icon name="planner" size="lg" />
        </IconButton>
      </MenuItem>
      <PlansDrawer
        drawerOpen={isOpen}
        handleDrawerClose={onClose}
        isEdit={false}
        viewOnly={false}
        isCreatePlanFromScopes={true}
        addElementToPlan={addElementToPlan}
        dataAutoFill={true}
        addElementProps={{
          grade: plan.grade,
          curriculumArea: plan.curriculumArea,
        }}
      />
    </>
  );
};

export default Planner;
