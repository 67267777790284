import { useAuth, useGetParentStudents } from '@lon/shared/hooks';
import {
  useGetFeedbacksAndScoresForDaQuery,
  useGetFeedbacksAndScoresQuery,
} from '@lon/shared/requests';
import { UserTypeEnum } from '@lon/shared/requests';
import { Answer, LearnosityAnswer } from '@lon/shared/types';

const useGetFeedbacksAndScores = ({
  isDa,
  studentId,
  sessionId,
  schoolId,
  isLti
}: {
  isDa?: boolean;
  isLti?: boolean;
  studentId?: string;
  sessionId?: string;
  schoolId?: string;
}) => {
  const [{ user }] = useAuth();
  const isParent = user?.type === UserTypeEnum.Parent;
  const { currentStudent } = useGetParentStudents();
  const studentIdValue = isParent ? currentStudent?.student?._id : studentId;

  const { data: assignmentData, loading: assignmentLoading } =
    useGetFeedbacksAndScoresQuery({
      variables: {
        sessionId: sessionId as string,
        schoolId: isLti ? undefined : schoolId,
        filter: {
          studentId: studentIdValue,
        },
      },
      fetchPolicy: 'cache-and-network',
      skip: isLti ? false : (!sessionId || !schoolId || !studentIdValue || isDa),
    });

  const { data: daData, loading: daLoading } =
    useGetFeedbacksAndScoresForDaQuery({
      variables: {
        sessionId: sessionId as string,
        schoolId: schoolId as string,
        studentId: studentIdValue,
      },
      fetchPolicy: 'cache-and-network',
      skip: !sessionId || !schoolId || !studentIdValue || !isDa,
    });

  const data = isDa ? daData : assignmentData;
  const feedbacks = data?.teacherFeedback?.responseFeedbacks || [];
  const assignment = data?.assignments?.collection?.find(
    (assignment) => assignment.id === sessionId
  );
  const answers: LearnosityAnswer[] = JSON.parse(assignment?.answers || '[]');

  const items: Answer[] = answers.map((answer) => ({
    maxScore: answer.max_score,
    score: answer.score,
    automarkable: answer.automarkable,
    responseId: answer.response_id,
  }));

  const loading = daLoading || assignmentLoading;
  return {
    items,
    feedbacks,
    loading,
  };
};

export default useGetFeedbacksAndScores;
