import { Asterisk } from '../../../../../asterisk';
import { CheckboxList } from '../../../../../checkbox-list';
import { Icon } from '../../../../../icon';
import { TABLET_TAB_PANEL_PADDING } from '../../../../duck/constants';
import { TabProps } from '../../duck/types';
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  TabPanel,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { get } from 'lodash-es';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@lon/shared/hooks';
import { useGetGrades } from './duck';

const GradeLevels: React.FC<TabProps> = ({
  isDistrictAssessmentForOneSchool,
}) => {
  const { t } = useTranslation();
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [isTablet] = useMediaQuery('(max-width: 1024px)');
  const [showGradesList, setShowGradesList] = useState(
    !!isDistrictAssessmentForOneSchool
  );
  const watchedGradeLevels = useWatch({ name: 'gradeLevels' });
  const selectedGradeLevels = watchedGradeLevels?.filter(Boolean);
  const gradeLevelsErrorMessage = get(errors, `gradeLevels.message`);

  const { grades, loading } = useGetGrades();

  const gradeOptions =
    grades
      ?.map((grade) => ({
        label: Number(grade) > 0 ? `Grade ${grade}` : grade,
        value: grade,
      }))
      .sort((a, b) => {
        const first = a.label;
        const second = b.label;
        // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
        return first.localeCompare(second, 'en', { numeric: true });
      }) || [];

  const handleChangeIndeterminateCheckbox = (checked: boolean) => {
    setValue(
      'gradeLevels',
      checked
        ? gradeOptions?.map((grade) => grade?.value || '')
        : new Array(gradeOptions.length).fill(''),
      { shouldDirty: true }
    );
    trigger('gradeLevels');
  };

  const handleChangeCheckboxList = (
    value: number,
    checked: boolean,
    index: number
  ) => {
    setValue(`gradeLevels.${index}`, checked ? value : '', {
      shouldDirty: true,
    });
    trigger('gradeLevels');
  };

  return (
    <TabPanel
      pb="6"
      {...(isTablet ? { px: TABLET_TAB_PANEL_PADDING } : { maxWidth: '700px' })}
    >
      <Text
        variant="s3"
        color="primary.400"
        whiteSpace="pre-wrap"
        textAlign="left"
      >
        {showGradesList
          ? t('createAssignmentDrawer.assessmentsGradeLevelsIntro')
          : t('createAssignmentDrawer.assessmentsWithoutGradeLevelsIntro')}
      </Text>
      <Box mt="6">
        <Divider orientation="horizontal" position="absolute" left="0" mt="0" />
        {isDistrictAssessmentForOneSchool ? (
          <Box pt="4">
            <Text variant="n1" color="primary.800" align="left">
              {t('createAssignmentDrawer.gradeLevelsTab')}
              <Text variant="n2" as="span">
                <Asterisk />
              </Text>
            </Text>
            <CheckboxList
              isLoading={loading}
              data={gradeOptions}
              selectedCount={selectedGradeLevels.length}
              title={t('createAssignmentDrawer.selectAllGrades')}
              errorMessage={gradeLevelsErrorMessage as any}
              name="gradeLevels"
              onIndeterminateCheckboxChange={handleChangeIndeterminateCheckbox}
              onChange={handleChangeCheckboxList}
              loadingMessage={t('createAssignmentDrawer.loadingGradeLevels')}
              sx={{ li: { mb: 3 }, '.chakra-accordion__panel': { pb: 1 } }}
              mt="2"
            />
          </Box>
        ) : (
          <>
            {!showGradesList ? (
              <Flex justifyContent="flex-end" w="100%" pt="6">
                <Tooltip
                  variant="dark"
                  label={t('createAssignmentDrawer.showGradesBtnTooltip')}
                  aria-hidden={true}
                >
                  <Button
                    size="sm"
                    variant="outline"
                    leftIcon={<AiOutlinePlus />}
                    onClick={() => {
                      setShowGradesList(true);
                    }}
                  >
                    {t('createAssignmentDrawer.addGradeLevelsBtn')}
                  </Button>
                </Tooltip>
              </Flex>
            ) : (
              <Box pt="4">
                <Text variant="n1" color="primary.800" align="left">
                  {t('createAssignmentDrawer.gradeLevelsTab')}
                  <Text variant="n2" as="span">
                    <Asterisk />
                  </Text>
                </Text>
                <Flex justifyContent="space-between" gap="4" mt="2">
                  <CheckboxList
                    isLoading={loading}
                    data={gradeOptions}
                    selectedCount={selectedGradeLevels.length}
                    title={t('createAssignmentDrawer.selectAllGrades')}
                    errorMessage={gradeLevelsErrorMessage as any}
                    name="gradeLevels"
                    onIndeterminateCheckboxChange={
                      handleChangeIndeterminateCheckbox
                    }
                    onChange={handleChangeCheckboxList}
                    loadingMessage={t(
                      'createAssignmentDrawer.loadingGradeLevels'
                    )}
                    sx={{
                      li: { mb: 3 },
                      '.chakra-accordion__panel': { pb: 1 },
                    }}
                  />
                  <IconButton
                    aria-label="Remove"
                    size={isTablet ? 'sm' : 'md'}
                    variant="ghost"
                    onClick={() => {
                      setShowGradesList(false);
                      setValue('gradeLevels', []);
                    }}
                    icon={
                      <Icon name="deleteOutlined" width="20px" height="20px" />
                    }
                  />
                </Flex>
              </Box>
            )}
          </>
        )}
      </Box>
    </TabPanel>
  );
};

export default GradeLevels;
