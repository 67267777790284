import { useDisclosure } from '@chakra-ui/react';
import * as React from 'react';
import { usePrompt } from './duck';
import { PromptModal } from './components';

interface AssignmentPromptProps {
  hasUnsavedChanges: boolean;
  onOk(nextLocation: string): void;
}

const AssignmentPrompt: React.FC<AssignmentPromptProps> = ({
  hasUnsavedChanges,
  onOk,
}) => {
  const redirect = React.useRef('');
  const shown = React.useRef(false);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const cb = React.useCallback(
    ({ nextLocation }: any) => {
      if (
        hasUnsavedChanges &&
        !shown.current &&
        !nextLocation?.state?.skipPrompt
      ) {
        onOpen();
        const pathname =
          nextLocation.pathname === '/suite'
            ? '/'
            : nextLocation.pathname.replace('/suite', '');
        redirect.current = pathname;
        return true;
      }

      return false;
    },
    [hasUnsavedChanges]
  );
  usePrompt(cb, hasUnsavedChanges);

  return (
    <PromptModal
      isOpen={isOpen}
      onOk={() => {
        shown.current = true;
        onOk(redirect.current);
      }}
      onCancel={onClose}
    />
  );
};

export default AssignmentPrompt;
