import {
  dateDisplayFormat,
  dateValueFormat,
  parseStringifiedDate,
} from '../../duck';
import { Divider, Flex } from '@chakra-ui/react';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactDatePicker, TextInput } from '@lon/shared/components';
import { TemplateTitleValidationStatusType } from '@lon/suit/types';

const CalendarTemplateGeneralSettings: React.FC<{
  titleValidationStatus: TemplateTitleValidationStatusType;
  loadingValidation: boolean;
  handleValidateTitle: () => void;
  setTitleValidationStatus: (
    value: React.SetStateAction<TemplateTitleValidationStatusType>
  ) => void;
  editOriginTitle?: string | null;
}> = ({ loadingValidation }) => {
  const { t } = useTranslation();

  const firstDayValue = useWatch({
    name: 'firstDayOfSchool.0.startDate',
  });
  const lastDayValue = useWatch({
    name: 'lastDayOfSchool.0.startDate',
  });
  //TODO uncomment when the "Validate" button is fixed
  // const { getFieldState } = useFormContext();
  // const { error: titleError } = getFieldState('name');
  // const templateTitleValue = useWatch({ name: 'name' })?.trim();
  // useEffect(() => {
  //   if (editOriginTitle?.trim() === templateTitleValue?.trim()) {
  //     setTitleValidationStatus(TemplateTitleValidationStatusType.ORIGIN);
  //   } else if (
  //     titleValidationStatus !== TemplateTitleValidationStatusType.REMAIN
  //   ) {
  //     setTitleValidationStatus(TemplateTitleValidationStatusType.REMAIN);
  //   }
  // }, [templateTitleValue]);

  return (
    <Flex direction="column" align="center" py="4">
      <Flex
        direction="column"
        maxW="700px"
        w="full"
        gap="4"
        px={{ base: '4', md: '0' }}
      >
        <TextInput
          isRequired
          name="name"
          label={t('calendarTemplate.title')}
          placeholder={t('calendarTemplate.titlePlaceHolder')}
          width="full"
          isDisabled={loadingValidation}
          //TODO uncomment when the "Validate" button is fixed
          // {...inputFieldStyles[titleValidationStatus]}
        />
        {/* TODO uncomment when the "Validate" button is fixed
         <Flex w="full" justify="space-between" pt="2">
          <Flex align="center" pl="3" gap="2.5">
            {!loadingValidation && (
              <TemplateUniqueTitleValidationMsg
                type={titleValidationStatus}
                successMsg={t('calendarTemplate.uniqueTitleSuccess')}
                failedMsg={t('calendarTemplate.uniqueTitleFailed')}
                errorMsg={t('calendarTemplate.uniqueTitleError')}
              />
            )}
          </Flex>
          <Button
            variant="solid"
            size="sm"
            isLoading={loadingValidation}
            isDisabled={
              !templateTitleValue ||
              Boolean(titleError?.message) ||
              titleValidationStatus === TemplateTitleValidationStatusType.ORIGIN
            }
            leftIcon={<Icon name="refresh" size="lg" />}
            onClick={() => {
              handleValidateTitle();
            }}
          >
            {t('buttons.validate')}
          </Button>
        </Flex> */}
      </Flex>
      <Divider orientation="horizontal" />
      <Flex maxW="700px" w="full" gap="6" px={{ base: '4', md: '0' }}>
        <ReactDatePicker
          name="firstDayOfSchool.0.startDate"
          label={t('calendarTemplate.firstDayOfSchoolTitle')}
          placeholder={t('calendarTemplate.datePlaceholder')}
          selectsStart
          selected={parseStringifiedDate(firstDayValue)}
          startDate={parseStringifiedDate(firstDayValue)}
          endDate={parseStringifiedDate(lastDayValue)}
          maxDate={parseStringifiedDate(lastDayValue)}
          dateFormat={dateDisplayFormat}
          valueFormat={dateValueFormat}
        />
        <ReactDatePicker
          name="lastDayOfSchool.0.startDate"
          label={t('calendarTemplate.lastDayOfSchoolTitle')}
          placeholder={t('calendarTemplate.datePlaceholder')}
          selectsEnd
          selected={parseStringifiedDate(lastDayValue)}
          startDate={parseStringifiedDate(firstDayValue)}
          endDate={parseStringifiedDate(lastDayValue)}
          minDate={parseStringifiedDate(firstDayValue)}
          dateFormat={dateDisplayFormat}
          valueFormat={dateValueFormat}
          defaultValue={null}
        />
      </Flex>
    </Flex>
  );
};

export default CalendarTemplateGeneralSettings;
