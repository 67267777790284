import Spinner from '../../../spinner';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RoleCodeEnum } from '@lon/shared/gql/types';
import { types } from './duck';

const loadingTextByRole = {
  [RoleCodeEnum.PlatformAdmin]: 'impersonate.platformAdminLoading',
  [RoleCodeEnum.SystemAdmin]: 'impersonate.adminLoading',
  [RoleCodeEnum.CampusLeader]: 'impersonate.leaderViewLoading',
  [RoleCodeEnum.CampusCurriculumLeader]: 'impersonate.leaderViewLoading',
  [RoleCodeEnum.DistrictCurriculumLeader]: 'impersonate.leaderViewLoading',
  [RoleCodeEnum.Teacher]: 'impersonate.teacherSuitLoading',
  [RoleCodeEnum.Parent]: 'impersonate.parentSuitLoading',
};

const Loader: React.FC<types.LoaderProps> = ({
  isLoading,
  mainProfileType,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isLoading} onClose={() => {}} isCentered>
      <ModalOverlay bgColor="rgba(255, 255, 255, 0.9)" />
      <ModalContent boxShadow="none" bgColor="transparent">
        <ModalBody display="flex" flexDir="column" alignItems="center">
          <Spinner variant="dark" />
          <Text variant="n3" mt="8">
            {t(loadingTextByRole[mainProfileType])}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Loader;
