export const getAverageLineHighContrastVariant = (value: number) => {
  if (value > 89) {
    return 'teal';
  } else if (value > 79) {
    return 'yellowHc';
  } else if (value > 69) {
    return 'orange600';
  } else {
    return 'fuchsia';
  }
};
