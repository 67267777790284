const addAudioDescriptive = (video: HTMLVideoElement) => {
  const videoUrl = video.src;
  const [filePart, queryParams] = videoUrl.split('?');

  const baseUrl = filePart
    .replace(/_1080|_720|_480_|_360|_240/, '')
    .replace(/\.(mp3|mp4)$/, '');

  const baseAdUrl = baseUrl.endsWith('_AD') ? baseUrl : `${baseUrl}_AD`;

  const mp3url = `${baseAdUrl}.mp3${queryParams ? '?' + queryParams : ''}`;
  const mp4url = `${baseAdUrl}.mp4${queryParams ? '?' + queryParams : ''}`;

  Promise.all(
    [mp3url, mp4url].map((url) =>
      fetch(url, { method: 'HEAD', credentials: 'include' })
    )
  ).then((responses) => {
    const successfulResponse = responses.find((response) => response.ok);
    if (successfulResponse) {
      video.dataset.originalUrl = video.src;
      video.src = successfulResponse.url;
    }
  });
};

export default addAudioDescriptive;
