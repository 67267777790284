import { Icon } from '@chakra-ui/react';
import { AiOutlineRight } from '@react-icons/all-files/ai/AiOutlineRight';
import { AiOutlineShareAlt } from '@react-icons/all-files/ai/AiOutlineShareAlt';
import React from 'react';
import { Icon as CustomIcon } from '../../../icon';
import { IconVariantType } from '@lon/shared/types';

export const DynamicIcon: React.FC<{ iconVariant?: IconVariantType }> = ({
  iconVariant,
}) => {
  switch (iconVariant) {
    case IconVariantType.Share:
      return <Icon as={AiOutlineShareAlt} />;
    case IconVariantType.RightArrow:
      return <Icon as={AiOutlineRight} />;
    case IconVariantType.SquareGrid:
      return <CustomIcon name="square-grid" />;
    case IconVariantType.Hidden:
      return <></>;
    default:
      return <CustomIcon name="arrow-down" />;
  }
};
