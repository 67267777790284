import { Icon } from '../../icon';
import { GeneralSettings } from '../tabs/general-settings';
import {
  immediatelyShareResultsOptions,
  teacherActionsOptions,
} from '../tabs/general-settings/tabs/assignment/duck/constants';
import { ManageAssignees } from '../tabs/manage-assignees';
import { TabKey as ManageTabKey } from '../tabs/manage-assignees/duck/types';
import { MyPlan } from '../tabs/my-plan';
import { Badge, Box, Text } from '@chakra-ui/react';
import format from 'date-fns/format';
import { t } from 'i18next';
import { array, bool, date, object, string } from 'yup';
import { ImmediatelyShareResults } from '@lon/shared/requests';
import {
  AssignmentFormDrawerProps,
  AssignmentType,
  FormValues,
  GetFieldLocation,
  GetFormLabelProps,
  TabKey as MainTabKey,
  Tab,
} from './types';
import { NO_CLASSES_MESSAGE, NO_GRADES_MESSAGE } from './constants';

export const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

const getValidationSchema = (
  currentManageTab: ManageTabKey,
  assignmentTypeTabIndex: AssignmentType,
  isMultipleAssignments: boolean,
  withOtherAssignment: boolean,
  isTeacherAssessment: boolean,
  isDistrictAssessment: boolean,
  isEditAssignmentMode: boolean
) => {
  const isAssessment = isTeacherAssessment || isDistrictAssessment;

  return object({
    ...(isMultipleAssignments
      ? {
          elementsIds: array()
            .of(string())
            .test(
              'subgroupIds',
              t('validationMessages.atLeastOne', {
                itemName: 'element',
                from: 'list',
              }),
              (ids) => ids?.filter(Boolean).length !== 0
            )
            .required(),
        }
      : {}),
    ...(assignmentTypeTabIndex === AssignmentType.GradeableAssignment
      ? {
          startDate: date()
            .nullable()
            .required(
              t('validationMessages.requiredField', {
                fieldName: 'Start Date',
              })
            )
            .default(new Date()),
          endDate: date()
            .nullable()
            .required(
              t('validationMessages.requiredField', {
                fieldName: 'End Date',
              })
            )
            .default(null),
          immediatelyShareResults: string().default(
            immediatelyShareResultsOptions[0].value
          ),
          ...(isAssessment
            ? { randomizeQuestionsOrder: bool().default(isDistrictAssessment) }
            : {}),
          ...(isDistrictAssessment
            ? {
                teacherAccess: array()
                  .of(string())
                  .default([
                    teacherActionsOptions[0].value,
                    teacherActionsOptions[2].value,
                  ]),
              }
            : {
                lateTurnIn: bool().default(false),
              }),
        }
      : {}),
    ...(!isEditAssignmentMode
      ? {
          ...(currentManageTab === ManageTabKey.classes
            ? {
                classes: array()
                  .of(
                    object().shape({
                      classId: string().required(
                        t('validationMessages.requiredField', {
                          fieldName: 'Class',
                        })
                      ),
                      ...(withOtherAssignment && !isAssessment
                        ? {
                            scopeId: string().required(
                              t('validationMessages.requiredField', {
                                fieldName: 'Digital Notebook Location',
                              })
                            ),
                          }
                        : {}),
                    })
                  )
                  .min(1, NO_CLASSES_MESSAGE),
              }
            : {}),
          ...(currentManageTab === ManageTabKey.subgroups
            ? {
                subgroups: array()
                  .of(
                    object().shape({
                      classId: string().required(
                        t('validationMessages.requiredField', {
                          fieldName: 'Class',
                        })
                      ),
                      subgroupIds: array()
                        .of(string())
                        .test(
                          'subgroupIds',
                          t('validationMessages.atLeastOne', {
                            itemName: 'subgroup',
                            from: 'class',
                          }),
                          (ids) => ids?.filter(Boolean).length !== 0
                        )
                        .required(),
                      ...(withOtherAssignment && !isAssessment
                        ? {
                            scopeId: string().required(
                              t('validationMessages.requiredField', {
                                fieldName: 'Digital Notebook Location',
                              })
                            ),
                          }
                        : {}),
                    })
                  )
                  .min(1, NO_CLASSES_MESSAGE),
              }
            : {}),
          ...(currentManageTab === ManageTabKey.individualStudents
            ? {
                individualStudents: array()
                  .of(
                    object().shape({
                      classId: string().required(
                        t('validationMessages.requiredField', {
                          fieldName: 'Class',
                        })
                      ),
                      studentIds: array()
                        .of(string())
                        .test(
                          'studentIds',
                          t('validationMessages.atLeastOne', {
                            itemName: 'student',
                            from: 'class',
                          }),
                          (ids) => ids?.filter(Boolean).length !== 0
                        )
                        .required(),
                      ...(withOtherAssignment && !isAssessment
                        ? {
                            scopeId: string().required(
                              t('validationMessages.requiredField', {
                                fieldName: 'Digital Notebook Location',
                              })
                            ),
                          }
                        : {}),
                    })
                  )
                  .min(1, NO_CLASSES_MESSAGE),
              }
            : {}),
          ...(currentManageTab === ManageTabKey.gradeLevels
            ? {
                gradeLevels: array()
                  .of(string())
                  .test(
                    'gradeLevels',
                    NO_GRADES_MESSAGE,
                    (gradeLevels) => gradeLevels?.filter(Boolean).length !== 0
                  )
                  .min(1, NO_GRADES_MESSAGE),
              }
            : {}),
          ...(currentManageTab === ManageTabKey.schools
            ? {
                schools: array()
                  .of(
                    object().shape({
                      schoolId: string().required(
                        t('validationMessages.requiredField', {
                          fieldName: 'School',
                        })
                      ),
                      schoolGradeLevels: array()
                        .of(string())
                        .test(
                          'gradeLevels',
                          NO_GRADES_MESSAGE,
                          (gradeLevels) =>
                            gradeLevels?.filter(Boolean).length !== 0
                        )
                        .required(),
                    })
                  )
                  .min(1, NO_GRADES_MESSAGE),
              }
            : {}),
        }
      : {}),
  });
};

export const getValidation = (
  currentManageTab: ManageTabKey,
  assignmentTypeTabIndex: AssignmentType,
  isMultipleAssignments: boolean,
  withOtherAssignment: boolean,
  isTeacherAssessment: boolean,
  isDistrictAssessment: boolean,
  isEditAssignmentMode: boolean,
  defaultFormValues: AssignmentFormDrawerProps['defaultFormValues']
) => {
  const validationSchema = getValidationSchema(
    currentManageTab,
    assignmentTypeTabIndex,
    isMultipleAssignments,
    withOtherAssignment,
    isTeacherAssessment,
    isDistrictAssessment,
    isEditAssignmentMode
  );

  return {
    validationSchema,
    defaultValues: {
      elementsIds: [],
      startDate: new Date(),
      endDate: null,
      ...(isTeacherAssessment || isDistrictAssessment
        ? { randomizeQuestionsOrder: isDistrictAssessment }
        : {}),
      ...(isDistrictAssessment
        ? {
            teacherAccess: [
              teacherActionsOptions[0].value,
              teacherActionsOptions[2].value,
            ],
            gradeLevels: [],
            schools: [],
          }
        : {
            lateTurnIn: false,
            classes: [],
            subgroups: [],
            individualStudents: [],
          }),
      ...(defaultFormValues ? defaultFormValues : {}),
      immediatelyShareResults:
        !defaultFormValues ||
        defaultFormValues.immediatelyShareResults ===
          ImmediatelyShareResults.Disabled
          ? immediatelyShareResultsOptions[0].value
          : defaultFormValues.immediatelyShareResults,
    } as FormValues,
  };
};

export const getTabs = ({
  isMultipleAssignments,
  isMobile,
}: {
  isMultipleAssignments: boolean;
  isMobile?: boolean;
}): Tab[] => {
  const isIconsOnly = isMobile && isMultipleAssignments;

  return [
    ...(isMultipleAssignments
      ? [
          {
            key: MainTabKey.myPlan,
            title: t('createAssignmentDrawer.myPlan'),
            tab: (
              <>
                <Box
                  as={Icon}
                  name="calendar-outlined"
                  size="lg"
                  mr={{ base: 0, md: 2 }}
                  w="5"
                  h="5"
                />
                <Text
                  variant="bm"
                  whiteSpace="nowrap"
                  overflowX="hidden"
                  textOverflow="ellipsis"
                  display={{ base: 'none', md: 'inline-flex' }}
                >
                  {t('createAssignmentDrawer.myPlan') as string}
                </Text>
              </>
            ),
            TabPanel: MyPlan,
          },
        ]
      : []),
    {
      key: MainTabKey.generalSettings,
      title: t('createAssignmentDrawer.generalSettings'),
      tab: (
        <>
          <Box
            as={Icon}
            name="settings"
            size="lg"
            w="5"
            h="5"
            {...(!isIconsOnly && {
              mr: '2',
            })}
          />
          {!isIconsOnly && (
            <Text
              variant="bm"
              whiteSpace="nowrap"
              overflowX="hidden"
              textOverflow="ellipsis"
            >
              {
                (isMobile
                  ? t('createAssignmentDrawer.generalSettingsMobile')
                  : t('createAssignmentDrawer.generalSettings')) as string
              }
            </Text>
          )}
        </>
      ),
      TabPanel: GeneralSettings,
    },
    {
      key: MainTabKey.manageAssignees,
      title: t('createAssignmentDrawer.manageAssignees'),
      tab: (
        <>
          <Box
            as={Icon}
            name="usergroup-edit"
            size="lg"
            w="5"
            h="5"
            {...(!isIconsOnly && {
              mr: '2',
            })}
          />
          {!isIconsOnly && (
            <Text
              variant="bm"
              whiteSpace="nowrap"
              overflowX="hidden"
              textOverflow="ellipsis"
            >
              {
                (isMobile
                  ? t('createAssignmentDrawer.manageAssigneesMobile')
                  : t('createAssignmentDrawer.manageAssignees')) as string
              }
            </Text>
          )}
        </>
      ),
      TabPanel: ManageAssignees,
    },
  ];
};

export const getFieldLocation: GetFieldLocation = (
  field,
  isMultipleAssignments
) => {
  const tabs = getTabs({ isMultipleAssignments });
  const getMainTabIndex = (tabKey: MainTabKey) =>
    tabs.findIndex((tab) => tab.key === tabKey) || 0;

  const fieldsLocation: { [key: string]: any } = {
    elementsIds: {
      mainTab: getMainTabIndex(MainTabKey.myPlan),
    },
    startDate: {
      mainTab: getMainTabIndex(MainTabKey.generalSettings),
    },
    endDate: {
      mainTab: getMainTabIndex(MainTabKey.generalSettings),
    },
    classes: {
      mainTab: getMainTabIndex(MainTabKey.manageAssignees),
      manageTab: 0,
    },
    subgroups: {
      mainTab: getMainTabIndex(MainTabKey.manageAssignees),
      manageTab: 1,
    },
    individualStudents: {
      mainTab: getMainTabIndex(MainTabKey.manageAssignees),
      manageTab: 2,
    },
    gradeLevels: {
      mainTab: getMainTabIndex(MainTabKey.manageAssignees),
      manageTab: 0,
    },
    schools: {
      mainTab: getMainTabIndex(MainTabKey.manageAssignees),
      manageTab: 1,
    },
  };
  return fieldsLocation[field];
};

export const getFormLabel: GetFormLabelProps = ({
  isEditAssignmentMode,
  isReadOnlyMode,
  isDirty,
}) => {
  switch (true) {
    case isReadOnlyMode:
      return (
        <Badge ml="6" variant="violetBabge">
          {t('tags.readOnly') as string}
        </Badge>
      );
    case !isEditAssignmentMode:
      return (
        <Badge ml="6" variant="greenBadge">
          {t('tags.new') as string}
        </Badge>
      );
    case isDirty:
      return (
        <Badge ml="6" variant="yellowBadge">
          {t('tags.edited') as string}
        </Badge>
      );
    default:
      return null;
  }
};
