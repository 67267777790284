export enum ReceivedMessage {
  Resize = 'resizeContent',
}

export enum HideElement {
  Yes = 'Yes',
  No = 'No',
}

export enum ScopeType {
  Scope = 'SCOPE',
  LessonScope = 'LESSON',
  HowTo = 'HOW_TO',
  FactFluency = 'FACT_FLUENCY',
  DailyNumeracy = 'NUMERACY',
}

export enum SettingsType {
  INITIAL = 'INITIAL',
  NEW = 'NEW',
  EDIT = 'EDIT',
}

export enum TemplateTitleValidationStatusType {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REMAIN = 'REMAIN',
  ORIGIN = 'ORIGIN', // for edit template original name
  ERROR = 'ERROR', // when API throw error
}
