import { KEYBOARD } from '@lon/shared/constants';
import { navigateNext, navigatePrev } from '@lon/shared/utils';
import * as actions from './actions';
import { Focus, KeyDown, SetReturnFocus } from './types';
import * as utils from './utils';
import { NODE_NAMES } from './constants';

export const keyDown: KeyDown = ({
  event,
  orientation,
  onExit,
  onKeyDown,
  returnFocus,
  state,
  dispatch,
  skippedKeyboardButtons,
}) => {
  onKeyDown(event);
  // eslint-disable-next-line
  // @ts-ignore
  if (event.nativeEvent.stopedPropagation === true) {
    return;
  }

  let shouldStopPropagation = false;

  switch (event.keyCode) {
    case KEYBOARD.ARROW_LEFT: {
      if (skippedKeyboardButtons?.ARROW_LEFT) {
        break;
      }

      event.preventDefault();
      shouldStopPropagation = true;

      if (orientation === 'vertical') {
        onExit();
        returnFocus();
        break;
      }

      utils.setActiveIndex('previous', state, dispatch);
      break;
    }
    case KEYBOARD.ARROW_RIGHT: {
      if (skippedKeyboardButtons?.ARROW_RIGHT) {
        break;
      }

      event.preventDefault();

      if (orientation === 'vertical') {
        const target = event.target as HTMLElement;
        if (target.nodeName === NODE_NAMES.BUTTON) {
          target.click();
          break;
        }

        onExit();
        returnFocus('next', true);
        break;
      }

      utils.setActiveIndex('next', state, dispatch);
      break;
    }
    case KEYBOARD.ARROW_DOWN: {
      if (skippedKeyboardButtons?.ARROW_DOWN) {
        break;
      }

      event.preventDefault();
      shouldStopPropagation = true;

      if (orientation === 'horizontal') {
        const target = event.target as HTMLElement;
        if (target.nodeName === NODE_NAMES.BUTTON) {
          target.click();
          break;
        }

        onExit();
        returnFocus();
        break;
      }

      utils.setActiveIndex('next', state, dispatch);
      break;
    }
    case KEYBOARD.ARROW_UP: {
      if (skippedKeyboardButtons?.ARROW_UP) {
        break;
      }
      event.preventDefault();
      shouldStopPropagation = true;

      if (orientation === 'horizontal') {
        onExit();
        returnFocus();
        break;
      }

      utils.setActiveIndex('previous', state, dispatch);
      break;
    }
    case KEYBOARD.END:
    case KEYBOARD.HOME: {
      if (skippedKeyboardButtons?.HOME) {
        break;
      }

      event.preventDefault();
      shouldStopPropagation = true;

      const { index: newIndex, item } =
        event.keyCode === KEYBOARD.END
          ? navigatePrev(state.items, state.items.length)
          : navigateNext(state.items, -1);
      if (item) {
        dispatch(actions.setActiveIndex(newIndex));
        utils.focusChild(newIndex, state);
      }
      break;
    }
    case KEYBOARD.ESC: {
      if (skippedKeyboardButtons?.ESC) {
        break;
      }

      event.preventDefault();
      shouldStopPropagation = true;

      onExit();
      returnFocus();
      break;
    }
    case KEYBOARD.TAB: {
      if (skippedKeyboardButtons?.TAB) {
        break;
      }

      if (state.depth === 1) break;
      shouldStopPropagation = true;
      onExit();
      returnFocus(undefined, true);
      break;
    }
    case KEYBOARD.ENTER: {
      break;
    }
    default: {
      shouldStopPropagation = true;

      const char = event.key;

      const index = state.items.findIndex((item) => {
        const node = item.ref.current;
        if (node && node.textContent) {
          return node.innerText?.toLowerCase().startsWith(char);
        }
        return false;
      });

      if (index !== -1) {
        dispatch(actions.setActiveIndex(index));
        utils.focusChild(index, state);
      }

      break;
    }
  }

  if (shouldStopPropagation) {
    // event.stopPropagation is avoided because there might be cases when you
    // need to handle keyDown event in components that wrap menu

    /* eslint-disable */
    // @ts-ignore
    event.nativeEvent.stopedPropagation = true;
    /* eslint-enable */
  }
};

export const focus: Focus = (event, state, dispatch) => {
  const node = event.target as Node;

  const index = state.items.findIndex(
    (item) => item.ref.current && item.ref.current.contains(node)
  );

  if (index !== -1 && state.activeIndex !== index) {
    dispatch(actions.setActiveIndex(index));
  }
};

export const setReturnFocus: SetReturnFocus = (
  returnFocus,
  onExit,
  state,
  dispatch
) =>
  dispatch(
    actions.setReturnFocus((dir, propagate) => {
      if (propagate) {
        onExit();
        if (returnFocus) {
          returnFocus(dir, true);
        }
      } else {
        utils.returnFocus(dir, state, dispatch);
      }
    })
  );
