import { Icon } from '../../../../icon';
import actionsStyles from '../../../ToolbarActions.module.css';
import {
  Button,
  IconButton,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TriggerProps } from './duck';

const Trigger: React.FC<TriggerProps> = ({ isResponsive, ...rest }) => {
  const { t } = useTranslation();
  const [isTablet] = useMediaQuery('(min-width: 768px)');

  return (
    <Tooltip variant="dark" label={t('toolbar.standards')} aria-hidden={true}>
      {isTablet && isResponsive ? (
        <Button
          size="md"
          variant="ghost"
          className={actionsStyles.action}
          aria-label={t('toolbar.standards')}
          leftIcon={<Icon name="safety-certificate" size="lg" />}
          {...rest}
        >
          <Text as="span" isTruncated variant="bs">
            {t('toolbar.standards')}
          </Text>
        </Button>
      ) : (
        <IconButton
          icon={<Icon name="safety-certificate" size="lg" />}
          aria-label={t('toolbar.standards')}
          variant="ghost"
          size="md"
          className={actionsStyles.action}
          {...rest}
        />
      )}
    </Tooltip>
  );
};

export default Trigger;
