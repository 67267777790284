import { AsyncOutlet } from '../../../../../async-outlet';
import { BackButton } from '../../../../../back-button';
import { PageContent } from '../../../../../page-content';
import LessonScopeHeader from '../lesson-scope-header';
import Menubar from '../menubar';
import Sidebar from '../sidebar';
import { Flex } from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import get from 'lodash-es/get';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { ScopeContext } from '@lon/shared/contexts';
import { Element, ScopeType } from '@lon/shared/types';
import { Standard } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';

const ScopeView: React.FC<{
  loading: boolean;
  elementsBySection: Record<string, any>;
  refetch: () => {};
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
  projectType: string;
  called: boolean;
  metadata: any;
  scope: any;
  scopeStandards?: Standard[];
}> = ({
  loading,
  elementsBySection,
  refetch,
  attempts,
  setAttempts,
  projectType,
  called,
  metadata,
  scope,
  scopeStandards,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const isContentPlayer = checkContentPlayer();

  const { elementId, sectionId, projectId } = params as {
    scopeId: string;
    elementId: string;
    sectionId: string;
    projectId: string;
  };

  const currentElement = get(
    elementsBySection as any,
    `[${sectionId}][${elementId}]`
  ) as Element;

  const backButtonPath = !isContentPlayer
    ? routes.scopes.root
    : generatePath(routes.scopes.projectScopes, {
        projectId,
      });

  return (
    <ScopeContext.Provider
      value={{
        loading: loading,
        elementsBySection: elementsBySection,
        refetch: refetch,
        attempts: attempts,
        setAttempts: setAttempts,
        projectType: projectType,
        called: called,
        metadata: metadata,
      }}
    >
      <Sidebar
        isLoading={loading || !called}
        scope={scope as any}
        standards={scopeStandards}
      />
      <PageContent
        portalContentHeight={elementId ? 62 : 0}
        pageTitle={sanitize(
          t('scopes.name', {
            scopeName: scope?.displayName || '',
            elementName: currentElement?.metadata?.elementName,
            separator: currentElement?.metadata?.elementName ? '|' : '',
            interpolation: {
              escapeValue: false,
            },
          })
        )}
        headerElements={
          <BackButton
            to={{
              pathname: backButtonPath,
            }}
            label={t('backButton.scope.label')}
            tooltipLabel={t('backButton.scope.tooltipLabel')}
          />
        }
        toolbar={
          scope?.sections ? (
            <Flex
              minW="0"
              width="full"
              bgColor="var(--grey-6)"
              direction="column"
            >
              {scope?.projectType === ScopeType.LessonScope ? (
                <LessonScopeHeader sections={scope?.sections} />
              ) : (
                <Menubar sections={scope?.sections} sectionsLoading={loading} />
              )}
            </Flex>
          ) : null
        }
      >
        <Helmet>
          <title>
            {sanitize(
              t('scopes.name', {
                scopeName: scope?.displayName || '',
                elementName: currentElement?.metadata?.elementName || '',
                separator: currentElement?.metadata?.elementName ? '|' : '',
                interpolation: {
                  escapeValue: false,
                },
              })
            )}
          </title>
        </Helmet>
        <Suspense fallback={null}>
          <AsyncOutlet />
        </Suspense>
      </PageContent>
    </ScopeContext.Provider>
  );
};

export default ScopeView;
