export const Drawer = {
  variants: {
    formDrawer: {
      overlay: {
        backgroundColor: 'rgba(231, 232, 235, 0.8)',
        zIndex: 1300,
      },
      dialogContainer: {
        zIndex: 9999,
        boxShadow: 'none',
      },
      closeButton: {
        color: 'primary.800',
        fontSize: '1rem',
        top: 7,
        right: 12,
      },
      dialog: {
        maxW: 'formDrawer',
        boxShadow: 'none',
      },
      body: {
        px: 0,
        py: 0,
        boxShadow: 'none',
      },
      content: {
        boxShadow: 'none',
      },
      header: {
        borderBottomWidth: '1px',
        borderColor: 'secondary.200',
        px: 8,
        py: 6,
        color: 'primary.800',
        fontSize: '1.625rem',
        fontWeight: 700,
      },
      footer: {
        borderTopWidth: '1px',
        borderColor: 'secondary.200',
        px: 8,
        py: 6,
      },
    },
  },
};
