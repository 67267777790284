import format from 'date-fns/format';
import { t } from 'i18next';
import { yup } from '@lon/shared/utils';
import { studentNoteMaxLength } from '@lon/suit/constants';

export const validationSchema = yup.object().shape({
  endDate: yup
    .date()
    .nullable()
    .required(
      t('validationMessages.requiredField', {
        fieldName: t('reopenAssignmentModal.endDateLabel'),
      })
    )
    .default(null),
  notes: yup
    .string()
    .default('')
    .max(
      studentNoteMaxLength,
      t('validationMessages.maxLength', {
        fieldName: t('assignments.studentNoteTitle'),
        length: studentNoteMaxLength,
      })
    ),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({
    endDate: null,
    notes: '',
  }),
});

export const formatDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};
