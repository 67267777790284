import { KEYBOARD } from '@lon/shared/constants';

export const closeMenuByPressXArrow = (
  event: React.KeyboardEvent<HTMLDivElement>
) => {
  if (
    event.keyCode === KEYBOARD.ARROW_LEFT ||
    event.keyCode === KEYBOARD.ARROW_RIGHT ||
    event.keyCode === KEYBOARD.TAB
  ) {
    event.currentTarget.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
      })
    );
  }
};
