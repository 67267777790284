import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FormTextarea, NumberInput } from '@lon/shared/components';

const Grading: React.FC<{
  responseId: string;
  isAutogradable: boolean;
  children?: React.ReactNode;
  loading: boolean;
}> = ({ responseId, isAutogradable = false, children, loading }) => {
  const { t } = useTranslation();

  return (
    // temporary styles same as learnosity apply for .col-md-6 class name
    <Flex
      flexDir="column"
      position="relative"
      minH="1px"
      ml="1rem"
      mr="1rem"
      sx={{
        '.lrn_scrollablepassage &': { ml: { lg: 0 }, mr: { lg: 0 } },
        '.two-columns-layout &': { ml: 0, mr: 0 },
      }}
    >
      {children}
      <Flex direction="column" color="primary.800" mb="1rem">
        <Text id={`label_${responseId}`} variant="n1" as="span">
          {t('studentAssignmentDetails.score')}
        </Text>
        {isAutogradable && (
          <Text variant="s3" color="primary.400" as="span">
            {t('studentAssignmentDetails.autogradedPlaceholder')}
          </Text>
        )}
        <Flex
          gap="4"
          maxW="fit-content"
          alignItems="center"
          mb="4"
          {...(isAutogradable && { mt: 3 })}
        >
          <NumberInput
            isDisabled={loading}
            name={`score_${responseId}`}
            placeholder={t('studentAssignmentDetails.scorePlaceholder')}
            max={100}
            min={0}
            maxW="130px"
            aria-labelledby={`label_${responseId}`}
            borderRadius="4px !important"
          />
          <Text variant="n3" whiteSpace="nowrap" mb="0 !important">
            {t('studentAssignmentDetails.of', { amount: 100 })}
          </Text>
        </Flex>
        <FormTextarea
          isDisabled={loading}
          maxLength={255}
          name={`feedback_${responseId}`}
          placeholder={t('studentAssignmentDetails.feedbackPlaceholder')}
          borderColor="primary.200"
          height="122px"
          label={
            <Text variant="n1" as="span">
              {t('studentAssignmentDetails.feedback')}
            </Text>
          }
        />
      </Flex>
    </Flex>
  );
};

export default Grading;
