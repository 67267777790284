import { yup } from '@lon/shared/utils';

export const validationSchema = yup.object().shape({
  fontSize: yup.string(),
  fontFamily: yup.string(),
  theme: yup.string(),
  speakingRate: yup.string(),
  highlight: yup.boolean(),
});

export const getValidation = () => ({
  validationSchema,
  defaultValues: validationSchema.cast({
    fontSize: 'default',
    fontFamily: 'verdana',
    theme: 'default',
    speakingRate: 'regular',
    highlight: true,
  }),
});
