import { Skeleton as ChakraSkeleton, SimpleGrid } from '@chakra-ui/react';
import { SKELETONS_COUNT } from '@lon/suit/constants';

const Skeleton: React.FC<{ isFilter?: boolean }> = ({ isFilter }) =>
  isFilter ? (
    <ChakraSkeleton minH="110" w="100%" borderRadius="md" />
  ) : (
    <SimpleGrid
      columns={{ md: 2, lg: 3 }}
      spacing={4}
      px={{ base: 6, md: 0 }}
      flexGrow={1}
    >
      {new Array(SKELETONS_COUNT).fill('').map((_, index) => (
        <ChakraSkeleton
          h="100%"
          minH="255px"
          w="100%"
          borderRadius="base"
          key={index}
          data-testid="card-skeleton"
        />
      ))}
    </SimpleGrid>
  );

export default Skeleton;
