import { useEffect, useState } from 'react';

const useFetchJson = ({
  url,
  options,
}: {
  url: string;
  options?: RequestInit;
}) => {
  const [data, setData] = useState<Record<string, string>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [fetchedScopeUrl, setFetchedScopeUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!url) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          url.startsWith('https') ? url : `https://${url}`,
          options
        );
        const json = await response.json();
        setData(json);
        setError(null);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
        setFetchedScopeUrl(url);
      }
    };

    fetchData();
  }, [url]);

  return { data, loading, error, fetched: fetchedScopeUrl === url };
};

export default useFetchJson;
