import { lazyWithRetry } from '@lon/shared/utils';

const Streaming = lazyWithRetry(() => import('./Streaming'), 'Streaming');

const StreamingContent = lazyWithRetry(
  () => import('./pages/streaming-content'),
  'StreamingContent'
);

export { Streaming, StreamingContent };
