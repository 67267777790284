import { AssignmentFormContext } from '../../duck/context';
import { TabProps } from '../../duck/types';
import { Box, Flex, TabPanel, Text } from '@chakra-ui/react';
import { uniq } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AssignStudentElements } from './components';

const MyPlan: React.FC<TabProps> = ({
  ownerAssignments,
  loadingOwnerAssignments,
}) => {
  const { t } = useTranslation();
  const { elementsData } = useContext(AssignmentFormContext);

  const data = useMemo(
    () =>
      Array.isArray(elementsData)
        ? elementsData.map((element, i) => {
            const assignedElementIds = uniq(
              ownerAssignments?.ownerAssignments?.assignments.map(
                (assignment) => assignment.assignmentSource?.element?.viewId
              )
            );
            return {
              value: element?.identifier ?? '',
              label: element?.elementName ?? '',
              isAssigned: assignedElementIds?.includes(element.identifier),
            };
          })
        : [],
    [elementsData, ownerAssignments]
  );

  return (
    <TabPanel p="0">
      <Flex justify="center" pt="6" px="8">
        <Box maxW="700px" w="full" color="primary.800" textAlign="left">
          <Text variant="n1" pb="4">
            {t('createAssignmentDrawer.plannedActivities')}
          </Text>
          <AssignStudentElements
            name="elementsIds"
            data={data}
            title={t('createAssignmentDrawer.elements')}
            isLoading={loadingOwnerAssignments}
            loadingMessage={t(
              'createAssignmentDrawer.plannedActivitiesLoadingMessage'
            )}
            isDisabled={loadingOwnerAssignments}
          />
        </Box>
      </Flex>
    </TabPanel>
  );
};

export default MyPlan;
