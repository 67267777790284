import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { hideAnswers, showAnswers } from '@lon/shared/constants';
import { useQueryParams } from '@lon/shared/hooks';

const ShowAnswers: React.FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const isAnswersShown = queryParams.answers !== hideAnswers;
  const title = isAnswersShown
    ? t('toolbar.hideAnswers')
    : t('toolbar.showAnswers');

  const setShowAnswers = (answers = 'ON', replace = true) =>
    navigate('?' + getQuery({ ...queryParams, answers }), { replace });

  return (
    <MenuItem {...props}>
      <IconButton
        size="md"
        variant="solidDark"
        className={actionsStyles.action}
        title={title}
        aria-label={title}
        onClick={() => {
          setShowAnswers(isAnswersShown ? hideAnswers : showAnswers, false);
        }}
      >
        <Icon
          name={isAnswersShown ? 'eye-outlined' : 'eye-crossed-outlined'}
          size="lg"
        />
      </IconButton>
    </MenuItem>
  );
};

export default ShowAnswers;
