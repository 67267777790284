// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';

export const getThemeStyleValue =
  <TDefault = unknown, THighContrast = unknown>(
    defaultValue: TDefault,
    highContrastValue: THighContrast
  ) =>
  (theme: ThemeEnum) => {
    switch (theme) {
      case ThemeEnum.HIGH_CONTRAST:
        return highContrastValue;
      default:
        return defaultValue;
    }
  };
