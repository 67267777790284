import { lazy } from 'react';

export const lazyWithRetry = (
  componentImport: () => Promise<any>,
  name: string
) =>
  lazy(async () => {
    const key = `page-${name}-has-been-force-refreshed`;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem(key) || 'false'
    );

    try {
      const component = await componentImport();

      window.sessionStorage.setItem(key, 'false');

      return component;
    } catch (error) {
      console.log({ error, pageHasAlreadyBeenForceRefreshed });
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.sessionStorage.setItem(key, 'true');
        return window.location.reload();
      }

      throw error;
    }
  });
