import { Footer, SectionCard } from '../../components';
import { Box, Flex } from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { sxLightScrollBar } from '@lon/shared/constants';
import { JsonViewStructure, ScopeContext } from '@lon/suit/contexts';

const Scope = () => {
  const scope = useContext(ScopeContext);

  const sections = useMemo(
    () =>
      scope?.original?.sections?.map((item) => {
        return {
          ...item,
          views: [
            ...item.elements.reduce<JsonViewStructure[]>((acc, element) => {
              const studentActivities = element.studentView.filter(
                (view) => view.metadata.hide !== 'Yes'
              );

              return [
                ...acc,
                ...studentActivities.map((view, index) => ({
                  ...view,
                  title:
                    (studentActivities.length > 1 ? `[${index + 1}] ` : '') +
                    element.metadata.elementName,
                  elementId: element.identifier,
                  isStudentView: true,
                })),
              ];
            }, []),
          ],
        };
      }),
    [scope]
  );

  return (
    <Flex
      gap={6}
      p={10}
      direction="column"
      height="calc(100vh - 220px)"
      overflow="auto"
      sx={sxLightScrollBar}
      justifyContent="space-between"
    >
      <Box>
        {sections?.map((section) => (
          <SectionCard key={section?.identifier} {...section} />
        ))}
      </Box>

      <Footer mx={0} mb={0} />
    </Flex>
  );
};

export default Scope;
