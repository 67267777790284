import { Flex, Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Message, ShowMore } from '@lon/shared/components';
import { types } from './duck';

const WarningMessage: React.FC<types.WarningMessageProps> = ({
  students = [],
}) => {
  const { t } = useTranslation();

  return (
    <Message
      variant="formWarning"
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      message={
        <Flex direction="column" gap="4">
          <Text variant="s3">{t('resetAssignmentModal.warningMessage')}</Text>
          <ShowMore startingHeight={'80px'}>
            {students.map((student) => (
              <Tag variant="yellowOutline" size="xs" key={student.studentId}>
                <Text isTruncated variant="tagXs">
                  {`${student.firstName} ${student.lastName}`}
                </Text>
              </Tag>
            ))}
          </ShowMore>
        </Flex>
      }
    />
  );
};

export default WarningMessage;
