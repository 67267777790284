import { CalculateStatusObject } from "@lon/shared/types";
import { StudentAssignmentStatus as StudentAssignmentStatusEnum } from "@lon/shared/requests";
import { t } from "i18next";

export const getStatus = (status: CalculateStatusObject) => {
  switch (true) {
    case status.type === StudentAssignmentStatusEnum.Assigned &&
    !status.returnedAt:
      return t('assignments.assigned');
    case status.type === StudentAssignmentStatusEnum.Started:
      return t('assignments.started');
    case status.type === StudentAssignmentStatusEnum.Submitted:
      return t('assignments.toBeGraded');
    case status.type === StudentAssignmentStatusEnum.Assigned &&
    !!status.returnedAt:
      return t('assignments.returned');
    case status.type === StudentAssignmentStatusEnum.Completed:
      return t('assignments.graded');

    default:
      return null;
  }
};
