import { FooterPropTypes } from '../../duck';
import { Button, Flex, Input } from '@chakra-ui/react';
import React from 'react';

const showV1 = window.localStorage.getItem('CHATBOT_V1_ENABLE');

const Footer: React.FC<FooterPropTypes> = ({
  inputMessage,
  setInputMessage,
  handleSendMessage,
  handleSendV2Message,
  loading,
  inputRef,
}) => {
  return (
    <Flex w="100%" borderRadius={4} mt={2} pl={2}>
      <Input
        ref={inputRef}
        disabled={loading}
        placeholder="Type..."
        border="none"
        borderRadius="none"
        _focusVisible={{
          border: '1px solid var(--chakra-colors-blue-600)',
          backgroundColor: 'transparent',
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSendV2Message();
          }
        }}
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        aria-label="Type here to ask Perry a question"
      />
      <Button
        isDisabled={loading || inputMessage.trim().length <= 0}
        mr={2}
        borderRadius={4}
        backgroundColor="#1E4176"
        color="white"
        onClick={handleSendV2Message}
      >
        Send
      </Button>
      {showV1 && (
        <Button
          backgroundColor="#1E4176"
          color="white"
          borderRadius={4}
          disabled={inputMessage.trim().length <= 0}
          onClick={handleSendMessage}
          mr={2}
        >
          Send V1
        </Button>
      )}
    </Flex>
  );
};

export default Footer;
