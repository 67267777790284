import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { authSlice } from '@lon/shared/configs';
import { Auth, SetAuth } from '@lon/shared/types';

type UseAuth = () => [Auth, SetAuth];

const useAuth: UseAuth = () => {
  const dispatch = useDispatch();

  const auth = useSelector<{ auth: Auth }>(
    (state: any) => state.auth,
    shallowEqual
  ) as Auth;

  const updateAuth = React.useCallback((update: Auth) => {
    dispatch(authSlice.setAuth(update));
  }, []);

  return [auth || {}, updateAuth];
};

export default useAuth;
