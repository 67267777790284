import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { Link as RouterLink } from '@lon/shared/components';
import { styles, types } from './duck';

const ElementCardItem: React.FC<types.ElementCardItemProps> = ({
  id,
  title,
  sectionId = '',
  elementPlacementUrl,
  curriculumArea,
}) => {
  const { t } = useTranslation();
  const { scopeId, projectId } = useParams() as {
    scopeId: string;
    projectId: string;
  };

  return (
    <Box data-testid="lesson-scope-element-card" sx={styles.wrapper}>
      <Flex pt="3.5" px="2" pb="2.5">
        <Box mr="3" minW="40px" minH="40px">
          <Icon name={curriculumArea?.toLocaleLowerCase() || ''} />
        </Box>
        <Flex as={Text} fontSize="md" variant="h6" alignItems="center">
          <Link
            as={RouterLink}
            withoutStyles
            to={{
              pathname: generatePath(elementPlacementUrl, {
                scopeId,
                elementId: id,
                sectionId,
                projectId,
              }),
            }}
            title={t('scope.openElement', { title })}
            sx={styles.link}
          >
            {title}
          </Link>
        </Flex>
      </Flex>
      <Box mt="auto" borderTop="1px solid #858585" pt="4" px="3" pb="2">
        <Text variant="s2" color="blue.500"></Text>
      </Box>
    </Box>
  );
};

export default ElementCardItem;
