import { createContext } from 'react';

export const RefetchContext = createContext<{
  refetch: () => void;
  attempts: number;
  setAttempts: React.Dispatch<React.SetStateAction<number>>;
}>({
  refetch: () => {},
  attempts: 0,
  setAttempts: () => {},
});
