import { Box, Flex } from '@chakra-ui/react';
import { RequiredNotification } from '@lon/shared/components';

const DrawerRequiredFieldsInstruction = () => {
  return (
    <Flex py="6" justify="center" borderBottomWidth="1px" borderColor="#E1E7F0">
      <Box mx={{ base: '4', md: '8' }} w="700px" textAlign="left">
        <RequiredNotification />
      </Box>
    </Flex>
  );
};

export default DrawerRequiredFieldsInstruction;
