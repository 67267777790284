import { HighlightDates } from './types';

export const getProcessedHighlightDates = (
  highlightDates?: HighlightDates
): { [key: string]: Date[] }[] | undefined =>
  highlightDates
    ? [
        {
          'react-datepicker__day--highlighted-custom-1':
            highlightDates.blue?.dates || [],
        },
        {
          'react-datepicker__day--highlighted-custom-2':
            highlightDates.olive?.dates || [],
        },
        {
          'react-datepicker__day--highlighted-custom-3':
            highlightDates.yellow?.dates || [],
        },
        {
          'react-datepicker__day--highlighted-custom-4':
            highlightDates.orange?.dates || [],
        },
        {
          'react-datepicker__day--highlighted-custom-5':
            highlightDates.fuchsia?.dates || [],
        },
        {
          'react-datepicker__day--highlighted-custom-6':
            highlightDates.violet?.dates || [],
        },
      ]
    : undefined;
