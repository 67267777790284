import { HStack, Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon, Message } from '@lon/shared/components';

const AllIneligibleMessage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Message
        variant="formError"
        css={{
          '.chakra-alert__desc': { display: 'block', padding: 0 },
        }}
        message={
          <Text variant="s3">
            {t('assignments.multipleReturnAssignmentError')}
          </Text>
        }
      />
      <Message
        variant="info"
        mt="4"
        css={{
          '.chakra-alert__desc': { display: 'block', padding: 0 },
        }}
        title={
          <Text variant="s3" fontWeight={700}>
            {t('assignments.returnAssignmentStatusTitle')}
          </Text>
        }
        message={
          <>
            <HStack gap={4}>
              <Tag
                variant="studentStatus"
                color="white"
                backgroundColor="blue.750"
                px={2}
                m={0}
                py={1}
                borderRadius="4px"
              >
                <Icon name="audit-outlined" size="sm" />
                <Text ml={2} variant="lmu">
                  {t('assignments.toBeGraded')}
                </Text>
              </Tag>
              <Tag
                variant="studentStatus"
                backgroundColor="success.800"
                color="white"
                px={2}
                py={1}
                m="0 !important"
                borderRadius="4px"
              >
                <Icon name="check-simple-outlined" size="sm" />
                <Text ml={2} variant="lmu">
                  {t('assignments.graded')}
                </Text>
              </Tag>
            </HStack>
            <Text variant="s3" mt={4}>
              {t('assignments.returnAssignmentStatusNotice')}
            </Text>
          </>
        }
      />
    </>
  );
};

export default AllIneligibleMessage;
