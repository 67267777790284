import { SimpleGrid } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CardListContext, CardListProps } from './duck';
import { Card } from './components';

const CardList: React.FC<CardListProps> = ({ children, listProps }) => {
  const [focusIndex, setFocusIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  return (
    <CardListContext.Provider
      value={{
        itemsCount: children.length,
        focusIndex,
        setFocusIndex,
        selectedIndex,
        setSelectedIndex,
      }}
    >
      <SimpleGrid
        as="ul"
        columns={{ md: 2, lg: 3, b1920: 4 }}
        spacing={4}
        {...listProps}
      >
        {children.map((child, position) => (
          <Card key={position} position={position}>
            {child}
          </Card>
        ))}
      </SimpleGrid>
    </CardListContext.Provider>
  );
};

export default CardList;
