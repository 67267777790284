import { gradesOrder } from "@lon/shared/constants";

export const sortGrades = (grades?: string[]) =>
  [...(grades || [])].sort((a, b) => {
    const firstIndex = gradesOrder.indexOf(a.toLowerCase());
    const secondIndex = gradesOrder.indexOf(b.toLowerCase());

    if (firstIndex < 0 && secondIndex < 0) {
      return a.localeCompare(b, 'en', { numeric: true });
    }

    if (firstIndex < 0) {
      return 1;
    }

    if (secondIndex < 0) {
      return -1;
    }

    return firstIndex - secondIndex;
  });
