import { UnorderedList } from '@chakra-ui/react';
import clsx from 'clsx';
import * as React from 'react';
import { assignRefs, navigateNext } from '@lon/shared/utils';
import {
  MenuContext,
  MenuTypes,
  menuActions,
  menuConstants,
  menuOperations,
  menuReducer,
  menuSelectors,
  menuState,
  menuUtils,
} from './duck';
import styles from './Menu.module.css';

const WrappedMenu = ({
  onKeyDown = () => {},
  hoverable,
  bordered = false,
  popup = false,
  size = 'medium',
  orientation = 'horizontal',
  indent = 16,
  className = '',
  children,
  innerRef,
  context,
  onExit = () => {},
  opened = true,
  group,
  prevent,
  skippedKeyboardButtons,
  ...rest
}: MenuTypes.WrappedMenuProps): React.ReactElement => {
  const [state, dispatch] = React.useReducer(menuReducer, menuState);

  const currentDepth = context === null ? 0 : context.state.depth;
  const computedHoverable =
    typeof hoverable !== 'undefined'
      ? hoverable
      : popup && orientation === 'horizontal';

  React.useEffect(
    () =>
      menuOperations.setReturnFocus(
        context ? context.state.returnFocus : null,
        onExit,
        state,
        dispatch
      ),
    [state.activeIndex, state.items]
  );

  React.useLayoutEffect(
    () =>
      dispatch(
        menuActions.setConfig({
          depth: currentDepth + 1,
          popup,
          size,
          hoverable: computedHoverable,
          orientation,
          bordered,
          indent,
        })
      ),
    [
      currentDepth,
      computedHoverable,
      size,
      popup,
      orientation,
      bordered,
      indent,
    ]
  );

  React.useLayoutEffect(() => {
    if (!prevent) {
      const { index } = navigateNext(state.items, -1);
      menuUtils.focusChild(index, state);
    }
  }, [opened]);

  return (
    <MenuContext.Provider value={{ state, dispatch }}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <UnorderedList
        {...rest}
        role={menuSelectors.getRole(state, orientation)}
        onFocus={(event) => menuOperations.focus(event, state, dispatch)}
        onKeyDown={(event) =>
          menuOperations.keyDown({
            event,
            state,
            dispatch,
            orientation,
            onExit,
            onKeyDown,
            returnFocus: context ? context.state.returnFocus : () => {},
            skippedKeyboardButtons,
          })
        }
        className={clsx(styles.menu, className, styles[orientation], {
          [styles.bordered]: bordered,
          [styles.closed]: !opened,
        })}
        ref={assignRefs(innerRef)}
      >
        {React.Children.toArray(children)
          .filter(Boolean)
          //@ts-ignore
          .filter((child) => child.type(child.props))
          .map((child, position) =>
            React.cloneElement(
              child as React.ReactElement<
                any,
                string | React.JSXElementConstructor<any>
              >,
              { opened, position }
            )
          )}
      </UnorderedList>
    </MenuContext.Provider>
  );
};

const Menu = (props: any): React.ReactElement => (
  <MenuContext.Consumer
    //@ts-ignore
    unstable_observedBits={
      menuConstants.BIT_MASK.CALLBACK |
      menuConstants.BIT_MASK.BORDERED |
      menuConstants.BIT_MASK.GROUP |
      menuConstants.BIT_MASK.INDENT
    }
  >
    {(context) => <WrappedMenu {...props} context={context} />}
  </MenuContext.Consumer>
);

export default Menu;
