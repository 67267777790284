export function parseJSON<ReturnType extends object>(data: string): ReturnType;

export function parseJSON(data: string): {};

export function parseJSON(data: string) {
  let parsed = {};

  if (data) {
    try {
      parsed = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }

  return parsed;
}
