import states from 'states-us';

const paths = window.location.pathname.split('/');
const stateIndex = 2;
const stateLetters = 2;
export const parseQueryParams = (search: string) =>
  new URLSearchParams(search.substring(search.indexOf('?')));
export const isStateExist = (
  states: { abbreviation: string; name: string }[],
  checkedValue: string
) => {
  return Boolean(
    states?.find((v) => v?.abbreviation?.toLowerCase() === checkedValue)
  );
};

export const getStateFromQueryString = (): string => {
  const result = parseQueryParams(window.location.search);
  const state = result.get('state')?.toLowerCase() || '';

  if (isStateExist(states, state?.toLowerCase())) {
    return state?.toLowerCase();
  }

  return '';
};

const isState = (state: string): boolean => state?.length === stateLetters;

export const hasStateInUrl = (value = paths[stateIndex]): boolean =>
  isState(value) && isStateExist(states, value?.toLowerCase());

export const hasSavedState = () => {
  try {
    const hasState = !!JSON.parse(localStorage.getItem('state') || '""');
    return hasState;
  } catch (error) {
    console.error(error);
    return false;
  }
};
