import { Cache } from '@apollo/client';
import { MODALS, NETWORK_ERROR_CODES } from '@lon/shared/constants';
import apolloClient from './apolloClient';
import { OPENED_MODAL } from './reactiveVars';

export const clearQueryCache = (
  queryName: string | string[],
  args?: Record<string, any>
): void => {
  if (Array.isArray(queryName)) {
    queryName.forEach((query) => {
      apolloClient.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: query,
        args,
      });
    });
  }
  if (typeof queryName === 'string') {
    apolloClient.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: queryName,
      args,
    });
  }

  apolloClient.cache.gc();
};

export const clearCache = (id: string, options?: Cache.EvictOptions): void => {
  apolloClient.cache.evict({
    id,
    ...options,
  });

  apolloClient.cache.gc();
};

export const refetchFallenQueries = () => {
  apolloClient
    .refetchQueries({
      include: 'active',
    })
    .catch((error) => {
      if (error.message === NETWORK_ERROR_CODES.sessionExpired) {
        OPENED_MODAL(MODALS.SessionExpired);
      } else if (error.message === NETWORK_ERROR_CODES.passwordExpired) {
        OPENED_MODAL(MODALS.PasswordUpdate);
      }
    });
};
