import { FormCheckbox } from '../../form-units';
import { BaseCheckboxListProps } from '../duck';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  List,
  ListItem,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';

export const BaseCheckboxList: React.FC<BaseCheckboxListProps> = ({
  data,
  values = [],
  selectedCount = 0,
  errorMessage,
  emptyMessage,
  name,
  onIndeterminateCheckboxChange = () => {},
  onChange = () => {},
  title,
  isDisabled = false,
  renderCheckboxLabel,
  isOneColumn,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const renderCheckboxes = data?.map((item, index) => (
    <ListItem key={item?.value}>
      <FormCheckbox
        key={item?.value}
        size="md"
        name={`${name}.${index}`}
        isChecked={values?.includes(item.value)}
        defaultValue=""
        onInputChange={(e: any) => {
          onChange(item?.value, e.target.checked, index);
        }}
        isDisabled={isDisabled}
        label={
          renderCheckboxLabel ? (
            renderCheckboxLabel(item?.label)
          ) : (
            <Text variant="n3" ml={-2} color="primary.800">
              {item?.label}
            </Text>
          )
        }
      />
    </ListItem>
  ));

  return (
    <FormControl isInvalid={!!errorMessage}>
      <Accordion defaultIndex={[0]} allowMultiple color="primary.800" {...rest}>
        <AccordionItem
          isDisabled={isDisabled}
          borderRadius="6px"
          border="1px solid"
          borderWidth={!errorMessage ? '1px' : '2px'}
          borderColor={!errorMessage ? '#E1E7F0' : 'danger.600'}
          _last={{
            borderBottomWidth: '2px',
          }}
        >
          {({ isExpanded }) => (
            <>
              <Flex
                align="center"
                borderBottomRadius={isExpanded ? '0' : 'inherit'}
                borderTopRadius="inherit"
                bgColor="secondary.50"
                px="4"
              >
                <Flex
                  flex="1"
                  gap="4"
                  color={isDisabled ? 'secondary.400' : 'primary.800'}
                >
                  <Checkbox
                    as="span"
                    mb="0"
                    isInvalid={false}
                    isDisabled={isDisabled}
                    isIndeterminate={
                      selectedCount > 0 && selectedCount < (data?.length || 10)
                    }
                    isChecked={
                      selectedCount === data?.length && data?.length !== 0
                    }
                    onChange={(e) => {
                      onIndeterminateCheckboxChange(e.target.checked);
                    }}
                  />
                  <Text variant="n3">{title}</Text>
                  <Badge
                    variant={
                      isDisabled
                        ? 'disabled'
                        : selectedCount === 0
                        ? 'recordsError'
                        : 'recordsInfo'
                    }
                  >
                    {t('checkboxList.selectedOf', {
                      selectedCount,
                      dataLength: data?.length,
                    })}
                  </Badge>
                </Flex>
                <h5>
                  <AccordionButton _hover={{ background: 'none' }}>
                    <Text variant="s2" textDecor="underline">
                      {isExpanded
                        ? t('checkboxList.showLess')
                        : t('checkboxList.showMore')}
                    </Text>
                  </AccordionButton>
                </h5>
              </Flex>
              <AccordionPanel
                maxHeight="650px"
                overflowY="scroll"
                pb={4}
                sx={sxLightScrollBar}
              >
                {data?.length ? (
                  <List
                    textAlign="left"
                    sx={{ columnCount: isMobile || isOneColumn ? '1' : '2' }}
                  >
                    {renderCheckboxes}
                  </List>
                ) : (
                  <Box color="danger.800" textAlign="left">
                    <Text variant="n2">{t('checkboxList.note')}</Text>
                    <Text variant="n3">{emptyMessage}</Text>
                  </Box>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
