import { Badge } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StatusLabelProps } from './duck';

const StatusLabel: FC<StatusLabelProps> = ({ memberId, teams }) => {
  const formProviderProps = useFormContext();
  const { t } = useTranslation();

  const teamsData = formProviderProps.watch('teams');
  const teamValue = teamsData?.find((team: any) => {
    return team?.identifier === memberId;
  });
  const currentTeacherInitial = teams?.teams?.collection?.find(
    (team: any) => team?.id === memberId
  );

  if (!currentTeacherInitial) {
    return <Badge variant="greenBadge">{t('form.new')}</Badge>;
  } else {
    let isChanged = false;

    if (currentTeacherInitial?.members.length !== teamValue?.members.length) {
      isChanged = true;
    }

    if (currentTeacherInitial?.name !== teamValue?.name) {
      isChanged = true;
    }

    currentTeacherInitial?.members?.forEach((member: any, i: number) => {
      if (!teamValue?.members?.find((v: any) => v?.value === member?.id)) {
        isChanged = true;
      }
    });

    return isChanged ? (
      <Badge variant="yellowBadge">{t('form.edit')}</Badge>
    ) : null;
  }
};

export default StatusLabel;
