import Modal from '../../modal/Modal';
import UpdatePasswordForm from '../update-password-form/UpdatePasswordForm';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@lon/shared/hooks';

const UpdatePassword: React.FC = () => {
  const { t } = useTranslation();
  const [{ user }] = useAuth();

  return (
    <Modal
      data-testid="session-expired-modal"
      withoutCloseButton
      closeOnOverlayClick={false}
      closeOnEsc={false}
      modalContentProps={{ maxWidth: '900px' }}
      autoFocus={false}
    >
      <Modal.Header>
        <Text variant="h4" fontSize="1.25rem">
          {t('updatePassword.header')}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <UpdatePasswordForm username={user?.username} />
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePassword;
