import {
  Box,
  Skeleton as ChakraSkeleton,
  Flex,
  SkeletonCircle,
} from '@chakra-ui/react';
import { skeletonTypes } from './duck';

const Skeleton: React.FC<skeletonTypes.SkeletonProps> = ({ amount }) => (
  <Box sx={{ marginTop: '1rem !important' }}>
    {Array.from({ length: amount }).map((_, index) => (
      <Box key={index} px={6} paddingTop={2} paddingBottom="0.75rem">
        <Flex alignItems="center">
          <SkeletonCircle size="4" />
          <ChakraSkeleton height={6} flex="1" marginInlineStart={4} />
        </Flex>
        <ChakraSkeleton h="18px" my={2} marginInlineStart={8} w="50px" />
      </Box>
    ))}
  </Box>
);

export default Skeleton;
