import { ActionType, createReducer } from 'typesafe-actions';
import { ApplicationSettingsReducer } from '@lon/shared/types';
import * as actions from './actions';

type ApplicationSettingsActions = ActionType<typeof actions>;

const initialState: ApplicationSettingsReducer = {};

const reducer = createReducer<
  ApplicationSettingsReducer,
  ApplicationSettingsActions
>(initialState).handleAction(
  actions.setApplicationSettings,
  (state, { payload }) => ({
    ...state,
    ...payload,
  })
);

export default reducer;
