export const replaceVideosBasedOnLanguage = () => {
  const language = document.documentElement.lang;

  const replaceLanguage = (from = '/EN_', to = '/ES_') => {
    const videos = document.querySelectorAll('video');
    videos.forEach((video) => {
      if (video.src.includes(from)) {
        video.src = video.src.replace(from, to);
      }

      const videoSources = video.querySelectorAll('source');
      videoSources.forEach((source) => {
        if (source.src.includes(from)) {
          source.src = source.src.replace(from, to);
        }
      });
    });
  };

  if (language === 'es') {
    replaceLanguage('/EN_', '/ES_');
  }

  if (language === 'en') {
    replaceLanguage('/ES_', '/EN_');
  }
};
