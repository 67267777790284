import { DistrictAssignmentContext } from '../../duck/context';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { PAGE_HEADER_HEIGHT } from '@lon/shared/constants';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { AddStudents, Archive, Settings } from './components';

const PageActions: React.FC = () => {
  const { t } = useTranslation();
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const { isLoading, isArchived } = useContext(DistrictAssignmentContext);
  const menuButtonVariant = useThemeStyleValue('outlineDark', 'solid');

  return (
    <Menu
      variant="actions"
      autoSelect={false}
      gutter={
        (PAGE_HEADER_HEIGHT - (menuButtonRef.current?.clientHeight || 0)) / 2
      }
    >
      {({ isOpen }) => (
        <>
          <Tooltip
            variant="dark"
            label={t('assignments.assignmentActionsTooltip')}
          >
            <MenuButton
              ref={menuButtonRef}
              as={Button}
              isLoading={isLoading}
              rightIcon={
                <Box
                  as={Icon}
                  name="chevron-down"
                  size="xl"
                  transform={`rotate(${isOpen ? -180 : 0}deg)`}
                />
              }
              size="sm"
              variant={menuButtonVariant}
            >
              <Text as="span" variant="bs">
                {t('assignments.actions')}
              </Text>
            </MenuButton>
          </Tooltip>
          <MenuList
            data-focus-visible-disabled
            borderTopRadius="0"
            minW="268px"
          >
            <Settings />
            <AddStudents />
            <Archive isArchived={isArchived} />
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default PageActions;
