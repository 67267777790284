import {
  MenuContext,
  MenuTypes,
  menuActions,
  menuConstants,
} from '../menu/duck';
import { AnyDispatch } from '../menu/duck/types';
import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import * as React from 'react';
import { assignRefs } from '@lon/suit/utils';
import styles from './MenuItem.module.css';

interface ContextProps {
  state: MenuTypes.State;
  dispatch: AnyDispatch;
}

const WrappedMenuItem = ({
  onClick = () => {},
  className = '',
  disabled = false,
  children,
  innerRef,
  state,
  dispatch,
  // For internal usage only.
  // eslint-disable-next-line
  // @ts-ignore
  group = false,
  // eslint-disable-next-line
  // @ts-ignore
  opened,
  // eslint-disable-next-line
  // @ts-ignore
  position,
  ...rest
}: any & ContextProps): React.ReactElement => {
  const id = React.useMemo(() => uniqueId('menu-item'), []);
  const ref = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    dispatch(menuActions.registerItem({ id, ref, disabled, position }));
    return () => {
      dispatch(menuActions.unregisterItem(id));
    };
  }, [position]);

  React.useEffect(
    () => () => {
      dispatch(menuActions.unregisterItem(id));
    },
    []
  );

  React.useLayoutEffect(() => dispatch(menuActions.setGroup(group)), []);

  React.useLayoutEffect(
    () => dispatch(menuActions.toggleDisabled({ id, disabled })),
    [disabled]
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      {...rest}
      role="menuitem"
      onClick={(event) => {
        if (disabled) return;
        event.stopPropagation();
        onClick(event);

        const index = state.items.findIndex((item: any) => item.id === id);
        if (index === -1) return;

        if (index !== state.activeIndex) {
          dispatch(menuActions.setActiveIndex(index));
        }
      }}
      className={clsx(styles.wrapper, className)}
      ref={assignRefs(ref, innerRef)}
    >
      {typeof children === 'function'
        ? children({
            isDisabled: disabled,
            tabIndex: disabled
              ? undefined
              : state.activeIndex !== position
              ? 0
              : undefined,
          })
        : Array.isArray(children)
        ? children
        : React.cloneElement(children, {
            disabled,
            isDisabled: disabled,
            size: state?.size,
            tabIndex: disabled
              ? undefined
              : state.activeIndex !== position
              ? 0
              : undefined,
            style:
              state.orientation === 'vertical' &&
              !state.popup &&
              state.indent !== 0
                ? {
                    paddingLeft: state.depth * state.indent,
                    ...children.props.style,
                  }
                : children.props.style,
            className: clsx(styles.item, children.props.className, {
              [styles.itemSmall]: state?.size === 'small',
              [styles.itemMedium]: state?.size === 'medium',
              [styles.itemLarge]: state?.size === 'large',
              [styles.verticalItem]: state.orientation === 'vertical',
              [styles.verticalBorderItem]:
                state.orientation === 'vertical' && state.bordered,
              [styles.horizontalBorderItem]:
                state.orientation === 'horizontal' && state.bordered,
            }),
            ...children.props,
          })}
    </li>
  );
};

const MenuItem = (props: any): React.ReactElement => (
  <MenuContext.Consumer
    /* eslint-disable no-bitwise */
    //@ts-ignore
    unstable_observedBits={
      menuConstants.BIT_MASK.INDEX | menuConstants.BIT_MASK.CONFIG
    }
    /* eslint-enable no-bitwise */
  >
    {(context) => <WrappedMenuItem {...context} {...props} />}
  </MenuContext.Consumer>
);

export default MenuItem;
