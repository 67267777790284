import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  CloseButton,
  Text,
} from '@chakra-ui/react';
import { types } from './duck';

const Message: React.FC<types.MessageProps> = ({
  title,
  message,
  onClose,
  variant = 'info',
  icon,
  alertTitleProps,
  alertDescriptionProps,
  iconWrapperProps,
  ...rest
}) => (
  <Alert variant={variant} {...rest}>
    {icon && (
      <Box mr="6" lineHeight={0} {...iconWrapperProps}>
        {icon}
      </Box>
    )}
    <Box>
      {title && (
        <AlertTitle mb="4" {...alertTitleProps}>
          {typeof title === 'string' ? (
            <Text variant="h5">{title}</Text>
          ) : (
            title
          )}
        </AlertTitle>
      )}
      {message && (
        <AlertDescription {...alertDescriptionProps}>
          {typeof message === 'string' ? (
            <Text variant="n3">{message}</Text>
          ) : (
            message
          )}
        </AlertDescription>
      )}
    </Box>
    {onClose && (
      <Box flexShrink="0" ml="auto" alignSelf="flex-start">
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </Box>
    )}
  </Alert>
);

export default Message;
