import { exists, t } from 'i18next';
import { FieldPath, UseFormSetError, UseFormSetFocus } from 'react-hook-form';
import { NETWORK_ERROR_CODES } from '@lon/shared/constants';
import { ToastFn } from '@lon/shared/types';
import { getErrorPath } from './getErrorPath';

type HandleError = (opts: {
  error: { graphQLErrors: { message: NETWORK_ERROR_CODES }[] };
  setError?: UseFormSetError<any>;
  setFocus?: UseFormSetFocus<any>;
  toast: ToastFn;
  mapFieldName?: Record<string, string>;
}) => void;

export const handleError: HandleError = ({
  error,
  setError,
  toast,
  setFocus,
  mapFieldName,
}) => {
  const fieldErrors = {} as Record<string, string>;

  error?.graphQLErrors?.forEach((e, idx) => {
    if (
      [
        NETWORK_ERROR_CODES.sessionExpired,
        NETWORK_ERROR_CODES.internalServerError,
      ].includes(e.message)
    ) {
      return;
    }
    const errorPath = getErrorPath(e, mapFieldName);
    const errorDescriptionPath = `${errorPath}_DESCRIPTION`;
    const errorDescription = exists(errorDescriptionPath)
      ? t(errorDescriptionPath)
      : undefined;
    const errorMessage = exists(errorPath)
      ? t(errorPath)
      : t('validationMessages.unexpected');
    let [field] = e.message.split(': ');

    if (mapFieldName?.[field]) {
      field = mapFieldName[field];
    }

    if (field && setError) {
      if (fieldErrors[field]) {
        if (!fieldErrors[field]?.includes(errorMessage)) {
          fieldErrors[field] = `${fieldErrors[field]}\n${errorMessage}`;
        }
      } else {
        fieldErrors[field] = errorMessage;
      }

      if (idx === 0 && typeof setFocus === 'function') {
        try {
          setFocus(field as FieldPath<any>);
        } catch (e) {
          toast({
            title: errorMessage,
            isClosable: true,
            variant: 'error-light',
            duration: null,
          });
          return;
        }
      }
    } else {
      toast({
        title: errorMessage,
        isClosable: true,
        variant: 'error-light',
        duration: null,
        description: errorDescription,
      });
    }
  });

  if (setError) {
    Object.keys(fieldErrors)?.forEach((key) => {
      setError(key as never, {
        type: 'custom',
        message: fieldErrors[key],
      });
    });
  }
};
