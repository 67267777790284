import { lazyWithRetry } from '@lon/shared/utils';
import { ScopeSection } from "@lon/suit/components"
const Scopes = lazyWithRetry(() => import('./Scopes'), 'Scopes');
const Scope = lazyWithRetry(
  () => import('./components/all-scopes/components/show'),
  'Scope'
);
const Element = lazyWithRetry(
  () => import('./components/all-scopes/components/show/components/element'),
  'Element'
);

export { Scopes, Scope, Element, ScopeSection };
