import { Modal as ChakraModal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { OPENED_MODAL } from '@lon/shared/utils';
import { types } from './duck';
import { Body, Footer, Header } from './components';


const Modal: React.FC<types.ModalProps> & types.NamespaceComponents = ({
  isOpen = true,
  withoutOverlay = false,
  variant = 'base',
  onClose,
  children,
  modalContentProps,
  withoutCloseButton = false,
  ...rest
}) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      OPENED_MODAL(null);
    }
  };

  return (
    <ChakraModal
      variant={variant}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      {...rest}
    >
      {!withoutOverlay && <ModalOverlay />}
      <ModalContent data-testid="modal" {...modalContentProps}>
        {!withoutCloseButton && <ModalCloseButton />}
        {children}
      </ModalContent>
    </ChakraModal>
  );
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
