import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { ThemeEnum } from '@lon/shared/enums';
import { getThemeStyleValue } from '@lon/shared/utils';

export const StyledNavLink = styled(NavLink, {
  shouldForwardProp: (propName) =>
    propName !== 'hoverBgColor' && propName !== 'currentTheme',
})<{
  hoverBgColor?: string;
  currentTheme?: ThemeEnum;
}>`
  &:hover {
    background-color: ${(props: any) =>
      props.hoverBgColor && props.hoverBgColor};
  }
  ${(props: any) =>
    getThemeStyleValue(
      '',
      `
        &.active {
          background-color: var(--chakra-colors-teal-500);
          &:hover {
            background-color: var(--chakra-colors-teal-600);
          }
        }
      `
    )(props.currentTheme)}
  &.yNavLink {
    justify-content: flex-start;
    border-radius: 6px;

    &.active::before {
      position: absolute;
      left: 0;
      opacity: 0.6;
      z-index: 1;
      border-radius: 0 4px 4px 0;
      background-color: ${(props: any) =>
        getThemeStyleValue(
          'white',
          'var(--chakra-colors-secondary-1000)'
        )(props.currentTheme)};
      width: 4px;
      height: 31px;
      content: '';
    }
  }
`;
