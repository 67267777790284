import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { GetData, GetMeta, GetError } from './types';

// `response` and `error` have `any` type because they both could be prepared
// in interceptors and, therefore, might not match `AxiosResponse` and `AxiosError`.

export const setFirstLoading = createStandardAction(
  'USE_REQUEST/SET_FIRST_LOADING'
)<boolean>();

export const fetch = createAsyncAction(
  'USE_REQUEST/REQUEST',
  'USE_REQUEST/SUCCESS',
  'USE_REQUEST/FAILURE'
)<
  never,
  { response: any; getData: GetData; getMeta: GetMeta },
  { error: any; getError: GetError }
>();
