import { paginationUtils } from '../../duck';
import { NumberInput, NumberInputField, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { onNumberInputPaste } from '@lon/shared/utils';
import { PageJumperTypes } from './duck';

const PageJumper: React.FC<PageJumperTypes.Props> = ({
  title,
  itemsPerPage,
  size,
  numberOfPages,
  handleChange,
}) => {
  const [pageInputValue, setPageInputValue] = useState<string>('');

  return (
    <>
      <Text as="label" htmlFor="page-jumper" variant="l" whiteSpace="nowrap">
        {title}
      </Text>
      <NumberInput
        id="page-jumper"
        size={size}
        maxW="90px"
        min={1}
        max={numberOfPages}
        inputMode="numeric"
        value={pageInputValue}
        onChange={(value) => setPageInputValue(value)}
        onKeyDown={paginationUtils.onPageInputKeydown(
          numberOfPages,
          (page: number) => {
            handleChange({ itemsPerPage, page });
            setPageInputValue('');
          }
        )}
        onPaste={onNumberInputPaste}
        onBlur={(event) =>
          paginationUtils.goToPage(event, numberOfPages, (page: number) => {
            handleChange({ itemsPerPage, page });
            setPageInputValue('');
          })
        }
      >
        <NumberInputField />
      </NumberInput>
    </>
  );
};
export default PageJumper;
