import { GetDistrictAssignmentsQuery } from '@lon/shared/requests';

type DA = (
  | NonNullable<
      GetDistrictAssignmentsQuery['districtAssignments']
    >['collection'][0]
  | null
)[];

export const getGradeLevelsFromDAs = (districtAssignments: DA) => {
  if (!districtAssignments) {
    return [];
  }

  return [
    ...new Set(districtAssignments.map((da) => da?.gradeLevel).filter(Boolean)),
  ].sort((a, b) => {
    if (a && b) {
      if (a === 'K') {
        return -1;
      }

      if (b === 'K') {
        return 1;
      }

      return a.localeCompare(b);
    }

    return 0;
  }) as string[];
};
