// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getTabs = (theme: ThemeEnum) => ({
  baseStyle: {
    tablist: {
      bg: 'blue.450',
      height: '44px',
    },
    tab: {
      color: 'white',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '1rem',
      _selected: {
        color: 'blue.500',
        bg: 'blue.75',
        borderBottom: 'none',
      },
      _focusVisible: {
        backgroundColor: 'primary.300',
        color: 'white',
        boxShadow: getThemeStyleValue('focus.blue', 'focus.teal')(theme),
        textDecoration: 'none',
      },
    },
  },
  variants: {
    buttons: {
      tablist: {
        maxW: '100%',
        bg: 'transparent',
        overflowX: 'scroll',
        minH: '44px',
        h: 'auto',
        gap: 2.5,
        pt: 1,
        pb: 2,
        flex: '1 0 auto',
      },
      tab: {
        mx: '3px',
        h: '44px',
        flexShrink: 0,
        borderRadius: 4,
        color: 'primary.800',
        fontSize: '1rem',
        border: '1px solid var(--chakra-colors-primary-800)',
        bg: 'transparent',
        _hover: {
          color: 'primary.500',
        },
        _active: {
          color: 'primary.300',
        },
        _focusVisible: {},

        _selected: {
          color: 'white',
          bg: 'primary.800',
          borderBottom: '1px solid var(--chakra-colors-primary-800)',
          _hover: {
            color: 'white',
            bg: 'primary.600',
          },
          _active: {
            color: 'white',
            bg: 'primary.700',
          },
        },
      },
    },
    fitted: {
      tablist: {
        borderWidth: '1px',
        borderColor: 'blue.400',
      },
      tab: {
        paddingInline: '8',
        color: 'blue.500',
        fontWeight: '600',
        fontSize: '1rem',
        _selected: {
          bg: 'primary.500',
          color: 'white',
        },
      },
    },
    drawer: {
      tablist: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: '700px',
        justifyContent: 'space-between',
        bgColor: 'white',
        paddingTop: '4',
        paddingBottom: '4',
        height: 'auto',
      },
      tab: {
        paddingInline: '8',
        color: 'blue.800',
        fontWeight: '400',
        fontSize: '1rem',
        paddingLeft: '0',
        paddingRight: '0',
        paddingTop: '10px',
        paddingBottom: '9px',
        borderBottom: '3px solid transparent',
        _selected: {
          bg: 'white',
          borderBottom: '3px solid #415984',
        },
      },
    },
    drawerInternal: {
      tablist: {
        bgColor: getThemeStyleValue('white', 'transparent')(theme),
      },
      tab: {
        paddingInline: { base: 1, md: 8 },
        color: getThemeStyleValue('blue.800', 'white')(theme),
        fontWeight: '400',
        fontSize: '1rem',
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderY: `1px solid ${getThemeStyleValue('#1E4176', 'white')(theme)}`,
        borderRight: `1px solid ${getThemeStyleValue(
          '#1E4176',
          'white'
        )(theme)}`,
        _first: {
          borderStartRadius: '4px',
          borderLeft: `1px solid ${getThemeStyleValue(
            '#1E4176',
            'white'
          )(theme)}`,
        },
        _last: {
          borderEndRadius: '4px',
        },
        _selected: {
          color: getThemeStyleValue('blue.500', 'black')(theme),
          bg: getThemeStyleValue('secondary.50', 'teal.500')(theme),
          borderBottom: `1px solid ${getThemeStyleValue(
            '#1E4176',
            'white'
          )(theme)}`,
        },
        _focusVisible: {
          bg: getThemeStyleValue('secondary.50', 'teal.500')(theme),
          color: getThemeStyleValue('blue.500', 'black')(theme),
          boxShadow: getThemeStyleValue('focus.blue', 'focus.teal')(theme),
          textDecoration: 'none',
          borderLeft: `1px solid ${getThemeStyleValue(
            '#1E4176',
            'white'
          )(theme)}`,
        },
      },
      tabpanel: {
        padding: '0',
      },
    },
    clear: {
      tablist: {
        bg: 'transparent',
        display: 'flex',
        gap: '6',
        justifyContent: 'center',
        py: '4',
        height: 'auto',
        boxShadow: '0px 2px 7px rgba(43, 54, 70, 0.1)',
      },
      tab: {
        fontWeight: 400,
        bg: 'white',
        fontSize: '1rem',
        height: '42px',
        p: 0,
        color: 'primary.800',
        _disabled: {
          color: 'secondary.400',
        },
        _selected: {
          bg: 'white',
          borderBottom: '3px solid',
          color: 'secondary.800',
          borderColor: 'primary.600',
        },
      },
    },
    simple: {
      tablist: {
        alignSelf: 'center',
        width: '100%',
        maxWidth: '700px',
        justifyContent: 'space-between',
        bgColor: getThemeStyleValue('primary.800', 'secondary.1000')(theme),
        // paddingTop: '4',
        // paddingBottom: '4',
        padding: 0,
        height: 'auto',
      },
      tab: {
        // paddingInline: '8',
        // color: 'blue.800',
        // fontWeight: '400',
        // fontSize: '1rem',
        // paddingLeft: '0',
        // paddingRight: '0',
        // paddingTop: '10px',
        // paddingBottom: '9px',
        // borderBottom: '3px solid transparent',
        // _selected: {
        //   bg: 'white',
        //   borderBottom: '3px solid #415984',
        // },
        // flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '1rem',
        fontWeight: 700,
        lineHeight: '26px',
        paddingTop: 2,
        paddingBottom: 'calc(var(--chakra-space-2) - 3px)',
        paddingLeft: '0',
        paddingRight: '0',
        borderBottom: '3px solid transparent',
        _selected: {
          borderBottom: '3px solid white',
          bgColor: getThemeStyleValue('primary.800', 'secondary.1000')(theme),
          color: 'white',
        },
      },
    },
    outlinedButtons: {
      tablist: {
        maxW: '100%',
        bg: 'transparent',
        overflowX: 'scroll',
        minH: '44px',
        h: 'auto',
        gap: 2.5,
        pt: 1,
        pb: 2,
        flex: '1 0 auto',
      },
      tab: {
        mx: '3px',
        h: '44px',
        flexShrink: 0,
        borderRadius: 4,
        color: 'primary.800',
        fontSize: '1rem',
        border: '1px solid var(--chakra-colors-primary-800)',
        bg: 'transparent',
        _hover: {
          backgroundColor: 'primary.600',
          color: 'white',
          border: '1px solid var(--chakra-colors-primary-600)',
        },
        _active: {
          backgroundColor: 'primary.700',
          color: 'white',
        },
        _focusVisible: {
          boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
          textDecoration: 'none',
          zIndex: 1,
        },
        _disabled: {
          opacity: 1,
          backgroundColor: 'secondary.300',
          color: 'secondary.100',
        },
        _selected: {
          color: 'white',
          bg: 'primary.800',
          borderBottom: '1px solid var(--chakra-colors-primary-800)',
          _hover: {
            color: 'white',
            bg: 'primary.600',
          },
          _active: {
            color: 'white',
            bg: 'primary.700',
          },
        },
      },
    },
  },
});
