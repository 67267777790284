import { lazyWithRetry } from '@lon/shared/utils';

const Resources = lazyWithRetry(() => import('./Resources'), 'Resources');
const ResourceContent = lazyWithRetry(
  () => import('./components/resource-content/ResourceContent'),
  'ResourceContent'
);
const ResourceSectionsList = lazyWithRetry(
  () => import('./components/resource-sections-list/ResourceSectionsList'),
  'ResourceSectionsList'
);
const ResourceSectionElementList = lazyWithRetry(
  () =>
    import(
      './components/resource-section-element-list/ResourceSectionElementList'
    ),
  'ResourceSectionElementList'
);
const ResourcesPage = lazyWithRetry(
  () => import('./components/resources-page/ResourcesPage'),
  'ResourcesPage'
);

export {
  Resources,
  ResourceContent,
  ResourceSectionsList,
  ResourceSectionElementList,
  ResourcesPage,
};
