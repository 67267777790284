import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from '@lon/admin/configs';
import { useCompleteDistrictSetupStatusMutation } from '@lon/admin/requests';
import { Icon, useToast } from '@lon/shared/components';
import styles from './Wizard.module.css';

const links = [
  { title: 'wizard.links.welcome', path: routes.wizard.welcome },
  {
    title: 'wizard.links.rostering',
    path: routes.integrations.rostering.root,
  },
  { title: 'wizard.links.sso', path: routes.integrations.sso.root },
  { title: 'wizard.links.lms', path: routes.integrations.lmsLti.root },
  {
    title: 'wizard.links.setting',
    path: routes.districtManagement.districtSettings,
  },
  { title: 'wizard.links.review', path: routes.wizard.root },
];

const Wizard = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [complete, { loading }] = useCompleteDistrictSetupStatusMutation();

  const completeWizard = () => {
    complete({
      variables: {
        input: {},
      },
      refetchQueries: ['getDistrictSettings'],
      awaitRefetchQueries: true,
    }).then(() => {
      navigate(routes.wizard.root);
      toast({
        title: t('wizard.completedTitle'),
        description: t('wizard.completed'),
        variant: 'success-light',
        isClosable: true,
      });
    });
  };

  return (
    <Flex
      direction="row"
      backgroundColor="green.900"
      w="full"
      justify="center"
      py={4}
      px={6}
      zIndex={1}
    >
      <Flex w="full" maxW={2000} justify="space-between" gap="100px">
        <Box>
          <Text variant="n1" color="white">
            {t('wizard.pageTitle')}
          </Text>
        </Box>
        <Flex gap={2}>
          {links.map((link) => (
            <Button
              key={link.path as string}
              as={NavLink}
              to={link.path as string}
              size="sm"
              variant="outlineDark"
              className={styles.button}
              _activeLink={{
                borderColor: 'var(--chakra-colors-white)',
              }}
            >
              {t(link.title)}
            </Button>
          ))}
        </Flex>
        <Button
          color="green.900"
          size="sm"
          variant="solidDark"
          onClick={completeWizard}
          isDisabled={loading}
          isLoading={loading}
          leftIcon={<Icon size="sm" name="check-outlined" />}
        >
          {t('wizard.complete')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Wizard;
