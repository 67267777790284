export const NumberInput = {
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    root: {
      borderColor: 'primary.200',
    },
  },
  variants: {
    outline: {
      field: {
        bg: 'white',
        border: '1px',
        borderColor: 'primary.200',
        borderRadius: '4px',

        '&::placeholder': {
          fontStyle: 'italic',
        },

        _hover: {
          borderColor: 'primary.200',
        },

        _focusVisible: {
          borderColor: 'blue.100',
          outline: 'none',
          background: 'white',
          boxShadow: '0 0 0 4px #4ECDFE',
        },

        _disabled: {
          opacity: 1,

          _hover: {
            opacity: 1,
          },
        },
      },
      stepper: {
        color: 'primary.700',
      },
    },
  },
  sizes: {
    xs: {
      field: {
        height: '22px',
      },
    },
    md: {
      field: {
        height: '44px',
      },
    },
  },
};
