import { Flex } from '@chakra-ui/react';
import React from 'react';
import { types } from './duck';
import { Actions } from './components';
import styles from './Toolbar.module.css';

export const Toolbar: React.FC<types.ToolbarProps> = ({ title, ...rest }) => {
  return (
    <Flex className={styles.wrapper}>
      <Actions {...rest} />
    </Flex>
  );
};

export default Toolbar;
