import { MenuItem } from './types';

export const MENU_ITEMS: MenuItem[] = [
  {
    name: 'gradeLevel',
    label: 'filters.grades',
    key: 'grades',
    hoverable: false,
  },
  {
    name: 'curriculumArea',
    label: 'scopes.curriculumArea',
    key: 'curriculumAreas',
    hoverable: true,
  },
  {
    name: 'topic',
    label: 'filters.topics',
    key: 'topics',
    hoverable: true,
  },
];

export const TABS = [{ name: 'Filters' }, { name: 'Recents' }];

export const data = {};
