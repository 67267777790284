import React from 'react';

type UsePopstateListener = (prevent: boolean) => void;

export const usePopstateListener: UsePopstateListener = (prevent) => {
  const [rerenderCount, setRerenderCount] = React.useState(0);

  React.useEffect(() => {
    if (prevent) {
      return;
    }

    const listener = (): void => setRerenderCount(rerenderCount + 1);

    window.addEventListener('popstate', listener);
    return () => window.removeEventListener('popstate', listener);
  }, [prevent]);
};
