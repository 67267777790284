import { extendTheme } from '@chakra-ui/react';
import '@fontsource/open-sans/600.css';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
import { colors } from './colors';
import { shadows } from './shadows';
import { fontSizes, lineHeights } from './text';
import { breakpoints } from './breakpoints';
import { getComponents } from './components';

const theme = extendTheme({
  fonts: {
    heading: `'verdana' | 'report' | 'public-sans'`,
    body: `'verdana' | 'report' | 'public-sans'`,
  },
  colors,
  breakpoints,
  fontSizes,
  lineHeights,
  components: getComponents(ThemeEnum.DEFAULT),
  shadows,
});

export default theme;
