import { Flex, Icon, Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { assignment } from '@lon/shared/assets';
import { Message } from '@lon/shared/components';

const InfoMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Message
      variant="info"
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      title={
        <Text variant="s1" fontStyle="italic">
          {t('reopenAssignmentModal.infoMessageTitle')}
        </Text>
      }
      message={
        <Flex direction="column" gap="4">
          <Tag variant="solid" display="flex" gap="1">
            <Icon as={assignment} h="13px" />
            <Text variant="tagXs" textTransform="uppercase">
              {t('reopenAssignmentModal.assigned')}
            </Text>
          </Tag>
          <Text variant="s3">{t('reopenAssignmentModal.infoMessageBody')}</Text>
        </Flex>
      }
    />
  );
};

export default InfoMessage;
