export const resizeInputs = () => {
  window.dispatchEvent(new Event('resize'));

  const inputs = document.querySelectorAll<HTMLInputElement>(
    '.lrn_cloze_response'
  );

  // replace hardcoded width with '100%' when input overflows its container
  [...inputs].forEach((input) => {
    const container = input.closest<HTMLElement>(
      '.lrn_textinput > .lrn_cloze_response:first-child'
    );

    if (!container) {
      return;
    }
    if (input.offsetWidth >= container.offsetWidth) {
      // full width - padding
      input.style.width = 'calc(100% - 4px)';
    }
  });
};
