import { Icon } from '../icon';
import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import clsx from 'clsx';
import React, { FocusEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@lon/shared/configs';
import { sidebarSlice } from '@lon/shared/configs';
import { SIDEBAR_Z_INDEX, isMobileDevice } from '@lon/shared/constants';
import { StudentGradeContext } from '@lon/shared/contexts';
import { ThemeEnum } from '@lon/shared/enums';
import {
  useAuth,
  useGetTheme,
  useMediaQuery,
  useThemeStyleValue,
} from '@lon/shared/hooks';
import { UserTypeEnum } from '@lon/shared/requests';
import { checkContentPlayer } from '@lon/shared/utils';
import { types } from './duck';
import styles from './CollapsibleSidebar.module.css';

const CollapsibleSidebar: React.FC<types.CollapsibleSidebarProps> = ({
  children,
  skeleton,
  isLoading,
  contentClassName = '',
  contentWrapperProps,
  parentWrapperProps,
}) => {
  const { isOpen } = useSelector((state: RootState) => state.sidebar);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ user }] = useAuth();
  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const isStudent = checkContentPlayer()
    ? false
    : user?.type === UserTypeEnum.Student;
  const studentContext = React.useContext(StudentGradeContext);
  const isStudentBelowK3 = isStudent && studentContext.isStudentBelowK3;

  const sidebarBorder = useThemeStyleValue('none', '1px solid #fff');
  const theme = useGetTheme();
  const isHighContrast = theme === ThemeEnum.HIGH_CONTRAST;

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isOpen) {
      timeoutId = setTimeout(() => buttonRef.current?.focus(), 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  useOutsideClick({
    enabled: isOpen && !isLargerThan768,
    ref: sidebarRef,
    handler: () => {
      if (isOpen && !isLargerThan768) {
        dispatch(sidebarSlice.closeSidebar());
      }
    },
  });

  const onOutsideBlur = (e: FocusEvent<HTMLDivElement, Element>) => {
    if (!e.relatedTarget) {
      return;
    }
    if (
      !e.currentTarget?.contains(e.relatedTarget) &&
      isOpen &&
      !isLargerThan768
    ) {
      dispatch(sidebarSlice.closeSidebar());
    }
  };

  return (
    <Box
      as="aside"
      onBlur={onOutsideBlur}
      ref={sidebarRef}
      data-testid="sidebar"
      className={clsx(styles.sidebar, {
        [styles.open]: isOpen,
        [styles.childSize]: isStudentBelowK3,
        [styles.mobile]: isMobileDevice,
        [styles.highContrast]: isHighContrast,
      })}
      zIndex={SIDEBAR_Z_INDEX}
      borderTop={sidebarBorder}
      {...(isOpen && {
        borderLeft: sidebarBorder,
        borderRight: {
          base: sidebarBorder,
          b1281: 'unset',
        },
      })}
      {...parentWrapperProps}
    >
      <Flex
        right="-14"
        position="absolute"
        minH="56px"
        minW="8"
        alignItems="center"
        display={{ base: 'none', b1281: 'flex' }}
      >
        <Tooltip
          variant="dark"
          aria-hidden={true}
          label={isOpen ? t('scope.closeSidebar') : t('common.openSidebar')}
          placement="bottom"
        >
          <IconButton
            onFocus={(e) => e.preventDefault()}
            size="sm"
            variant="solid"
            borderRadius="full"
            backgroundColor={useThemeStyleValue('primary.600', '#fff')}
            aria-label={
              isOpen ? t('scope.closeSidebar') : t('common.openSidebar')
            }
            fontSize="2xl"
            w="8"
            h="8"
            icon={<Icon name="swapOutlined" />}
            onClick={() => dispatch(sidebarSlice.toggleOpen())}
          />
        </Tooltip>
      </Flex>

      <Box h="100%" overflow="hidden">
        <div className={clsx(styles.content, contentClassName)}>
          <Flex
            display={{ base: 'flex', b1281: 'none' }}
            align="center"
            minH="14"
            backgroundColor={useThemeStyleValue('blue.500', 'secondary.1000')}
            borderBottom={useThemeStyleValue('none', '1px solid #fff')}
            px={6}
            justify="end"
          >
            <Tooltip
              variant="dark"
              aria-hidden={true}
              label={
                isOpen ? t('common.closeSidebar') : t('common.openSidebar')
              }
            >
              <IconButton
                onFocus={(e) => e.preventDefault()}
                ref={buttonRef}
                data-testid="openCloseButton"
                size="sm"
                variant={useThemeStyleValue('solid', 'ghost')}
                aria-label={t('common.closeSidebar')}
                icon={<Icon name="closeOutlined" size="sm" />}
                onClick={() => dispatch(sidebarSlice.toggleOpen())}
              />
            </Tooltip>
          </Flex>
          <Flex
            flexGrow={1}
            w="full"
            direction="column"
            overflow="auto"
            className={clsx(styles.scrollBar, {
              [styles.highContrastScrollBar]: isHighContrast,
            })}
            {...contentWrapperProps}
          >
            {isLoading && skeleton ? <>{skeleton}</> : <>{children}</>}
          </Flex>
        </div>
      </Box>
    </Box>
  );
};

export default CollapsibleSidebar;
