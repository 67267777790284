import { ActionType, createReducer } from 'typesafe-actions';
import { Sidebar } from '@lon/shared/types';
import * as actions from './actions';

type SidebarActions = ActionType<typeof actions>;

const initialState: Sidebar = {
  isOpen: window.innerWidth >= 1280,
};

const reducer = createReducer<Sidebar, SidebarActions>(
  initialState
).handleAction(actions.toggleOpen, (state) => ({
  ...state,
  isOpen: !state.isOpen,
}));
export default reducer;
