import { Box, Button, Text } from '@chakra-ui/react';
import { IoBookOutline } from '@react-icons/all-files/io5/IoBookOutline';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { HideElement } from '@lon/shared/types';
import { ScopeMenuTypes } from './duck';

const ScopeMenu: React.FC<ScopeMenuTypes.ScopeMenuProps> = ({
  sidebarElements,
  loading,
}) => {
  const { elementId } = useParams() as { elementId: string };

  return (
    <>
      {!loading &&
        sidebarElements
          ?.filter((element) => element?.metadata?.hide !== HideElement.Yes)
          .map(({ metadata, teacherView, identifier }) => {
            const title = `${metadata?.elementName || ''}${
              teacherView?.metadata?.activityTitle
                ? `: ${teacherView.metadata.activityTitle}`
                : ''
            }`;

            return (
              <Box as="li" key={identifier}>
                <Button
                  variant="sidebarButton"
                  key={identifier}
                  as={Link}
                  justifyContent="flex-start"
                  whiteSpace="pre-wrap"
                  to={{
                    pathname: `home/${identifier}`,
                  }}
                  leftIcon={<IoBookOutline fontSize="1.25rem" />}
                  w="full"
                  title={title}
                  role="tab"
                  id={identifier}
                  aria-controls="scope-element"
                  aria-selected={elementId === identifier}
                  {...(elementId === identifier && {
                    isActive: true,
                    className: 'active',
                    css: {
                      backgroundColor:
                        'var(--chakra-colors-primary-50) !important',
                      color: 'var(--chakra-colors-primary-800) !important',
                    },
                  })}
                >
                  <Text as="span" noOfLines={5}>
                    {title}
                  </Text>
                </Button>
              </Box>
            );
          })}
    </>
  );
};

export default ScopeMenu;
