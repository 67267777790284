import { ClipboardEvent, KeyboardEvent } from 'react';
import { isStringNumber } from './isStringNumber';

export const onNumberInputKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
  const allowedKeys = ['Backspace', 'Tab'];
  const ctrlDown = event.ctrlKey || event.metaKey;
  const isCutCopyPaste =
    ctrlDown && (event.key === 'x' || event.key === 'c' || event.key === 'v');

  if (
    !isStringNumber(event.key) &&
    !allowedKeys.includes(event.key) &&
    !isCutCopyPaste
  ) {
    event.preventDefault();
  }
};

export const onNumberInputPaste = (event: ClipboardEvent<HTMLDivElement>) => {
  if (event.clipboardData.getData('text').match(/[^\d]/)) {
    event.preventDefault();
  }
};
