import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';

const ErrorMessage: React.FC<types.ErrorMessageProps> = ({
  hasBack = true,
  hasHome = true,
  fullWidth = false,
  title: outerTitle,
  body,
  actions,
  testId,
  wrapperProps,
  bodyWrapperProps,
  showCloudBackground = false,
  imageSrc = '',
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title =
    typeof outerTitle === 'string' || !outerTitle ? (
      <Heading as="h2" variant="h4" pb="4">
        {outerTitle || t('errorMessage.notFound')}
      </Heading>
    ) : (
      outerTitle
    );

  const goBack = () => {
    navigate(-1);
  };
  const themeStyles = useThemeStyleValue(
    {
      background: 'white',
      backgroundImage: 'suit/assets/cloud_background.svg',
      color: 'primary.800',
    },
    {
      background: 'secondary.1000',
      backgroundImage: 'suit/assets/clouds_contrast.svg',
      color: 'white',
    }
  );

  return (
    <Flex direction="column" flexGrow="1">
      <Flex
        data-testid={testId}
        align="center"
        justify="center"
        flexGrow="1"
        bgColor="white"
        borderRadius="md"
        height="full"
        bg={themeStyles.background}
        {...(showCloudBackground && {
          backgroundImage: themeStyles.backgroundImage,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
        })}
        {...wrapperProps}
      >
        <Flex
          direction="column"
          align="center"
          justify="center"
          w="full"
          maxW={fullWidth ? 'full' : '600px'}
          p="12"
          color={themeStyles.color}
          textAlign="center"
          {...bodyWrapperProps}
        >
          {imageSrc ? <Image src={imageSrc} /> : null}
          {title}
          {body ? body : <></>}
          {actions ? (
            actions
          ) : (
            <Flex
              justify={!hasHome || !hasBack ? 'center' : 'space-between'}
              w="full"
            >
              {hasHome ? (
                <Button
                  as={Link}
                  to={'/'}
                  title={t('errorMessage.goToHome')}
                  variant="outline"
                >
                  <Text as="span" isTruncated>
                    {t('errorMessage.goToHome')}
                  </Text>
                </Button>
              ) : null}
              {hasBack ? (
                <Button
                  variant="solid"
                  onClick={() => goBack()}
                  title={t('errorMessage.previousPage')}
                >
                  <Text as="span" isTruncated>
                    {t('errorMessage.previousPage')}
                  </Text>
                </Button>
              ) : null}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ErrorMessage;
