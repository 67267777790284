import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NoContent } from '@lon/shared/components';

const NoReport: FC = () => {
  const { t } = useTranslation();

  return (
    <NoContent
      title={t('reportsDashboard.districtAssessmentsReports.noReports')}
      description={t(
        'reportsDashboard.districtAssessmentsReports.contactAdmin'
      )}
      actions={<></>}
      bodyWrapperProps={{
        mt: '-200px',
      }}
    />
  );
};

export default NoReport;
