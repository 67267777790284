import { KEYBOARD } from '@lon/shared/constants';
import * as Types from './types';

export const keyDown: Types.KeyDown = ({
  event,
  state,
  setOpened,
  setPrevent,
}): void => {
  if (state.hoverable) {
    return;
  }

  let shouldToggle = false;

  switch (event.keyCode) {
    case KEYBOARD.ESC: {
      setOpened(false);
      setPrevent(false);
      break;
    }
    case KEYBOARD.ARROW_RIGHT: {
      if (state.orientation === 'vertical') {
        shouldToggle = true;
      }
      break;
    }
    case KEYBOARD.ARROW_DOWN: {
      if (state.orientation === 'horizontal') {
        shouldToggle = true;
      }
      break;
    }
    case KEYBOARD.ENTER: {
      shouldToggle = true;
      break;
    }
    default: {
      break;
    }
  }

  if (shouldToggle) {
    event.preventDefault();
    event.stopPropagation();
    setOpened(true);
    setPrevent(false);
  }
};

export const outsideClick: Types.OutsideClick = (
  event,
  buttonRef,
  setOpened
) => {
  if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
    setOpened(false);
  }
};

export const click: Types.Click = (setOpened, setPrevent, state) => {
  if (state.hoverable) {
    setOpened(true);
    setPrevent(false);
  } else {
    setOpened((prevOpened) => !prevOpened);
    setPrevent(true);
  }
};
