import { Icon } from '../icon';
import {
  Box,
  Link,
  Text,
  chakra,
  useToast as useToastChakra,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ToastFn } from '@lon/shared/types';
import { icons } from './duck';

export function useToast(): ToastFn {
  const toast = useToastChakra();

  return (state) => {
    const {
      duration,
      isClosable = false,
      title,
      description,
      status,
      position = 'bottom',
      variant = 'error-light',
      firstLink = '',
      firstLinkText = '',
      secondLink = '',
      secondLinkText = '',
    } = state;

    toast({
      title: (
        <chakra.span>
          {ReactHtmlParser(title as string) as ReactNode}
        </chakra.span>
      ),
      description: (
        <Box minW="280px" role="alert" aria-label={title as string}>
          <Text variant="s2" mb={1}>
            {description}
          </Text>
          {firstLink && (
            <Link textDecor="underline" href={firstLink} mr="180px">
              {firstLinkText}
            </Link>
          )}
          {secondLink && (
            <Link textDecor="underline" href={secondLink}>
              {secondLinkText}
            </Link>
          )}
        </Box>
      ),
      status,
      duration,
      position: position,
      isClosable,
      variant,
      icon: <Icon name={(icons as any)[variant as any]} />,
      containerStyle: {
        maxW: '500px',
        zIndex: 10000,
      },
    });
  };
}
