import { Flex } from '@chakra-ui/react';
import {
  sxRoundedDarkContentScrollbar,
  sxRoundedHighContrastScrollbar,
} from '@lon/shared/constants';
import { useScrollExisting, useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';

const LowerStudentWrapper: React.FC<types.LowerStudentWrapperProps> = ({
  leftColumnComponent,
  leftContentWrapperProps,
}) => {
  const {
    contentRef,
    contentScrolls: { vertical: hasContentScroll },
  } = useScrollExisting({
    horizontal: false,
  });

  return (
    <Flex
      ref={contentRef}
      flexGrow={1}
      direction="column"
      overflowX="hidden"
      h="full"
      mr={hasContentScroll ? 'calc(var(--chakra-space-1) * 2.4)' : 0}
      pr={hasContentScroll ? 'calc(var(--chakra-space-1) * 2.4)' : 8}
      pl={8}
      pt={8}
      pb={8}
      sx={useThemeStyleValue(
        sxRoundedDarkContentScrollbar,
        sxRoundedHighContrastScrollbar
      )}
      {...leftContentWrapperProps}
    >
      {leftColumnComponent}
    </Flex>
  );
};

export default LowerStudentWrapper;
