import i18next from 'i18next';
import { widgetDashboard } from '@lon/shared/assets';
import { initReactI18next } from 'react-i18next';
import { enConfig } from '@lon/suit/assets';

export const initTranslationService = () => {
  i18next.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: { translation: { ...enConfig, ...widgetDashboard } },
    },
  });
};
