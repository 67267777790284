import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { prodStemDomain } from '@lon/shared/constants';
import { isProdCluster, stemDomain } from '@lon/shared/constants';
import { useMediaQuery } from '@lon/shared/hooks';
import { headerConstants } from './duck';

const UnauthorizedHeader: React.FC<{ isSuite?: boolean }> = ({ isSuite }) => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  return (
    <Box
      as="header"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      backgroundColor="white"
    >
      <Flex
        justify="space-between"
        alignItems="center"
        id="navigation"
        role="navigation"
        px={{ base: 4, sm: 8 }}
        pt={5}
        pb={4}
        maxH="70px"
      >
        <Link
          href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
          h="34px"
          aria-label="Link to STEM scopes home"
        >
          <Image
            width="auto"
            height="100%"
            src={
              isSuite
                ? 'assets/acceleratelearning-login.svg'
                : '/login/assets/acceleratelearning-login.svg'
            }
            alt="Accelerate Learning"
          />
        </Link>
        <Box zIndex="99">
          <Popover>
            <PopoverTrigger>
              <Button
                size="sm"
                variant="ghost"
                aria-label={`${headerConstants.headerLinks.title} menu`}
              >
                {isLargerThan768
                  ? headerConstants.headerLinks.title
                  : headerConstants.headerLinks.mobileTitle}
              </Button>
            </PopoverTrigger>
            <PopoverContent maxW="200px" bg="primary.800" zIndex="999">
              <PopoverBody p={0}>
                <VStack spacing={2} align="start">
                  {headerConstants.headerLinks.submenu
                    .filter((s) => !s.hidden)
                    .map((row) => (
                      <HStack spacing={0} key={row.name} align="start">
                        <Link href={row.href}>
                          <Text variant="s2">{row.name}</Text>
                        </Link>
                      </HStack>
                    ))}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      </Flex>
    </Box>
  );
};

export default UnauthorizedHeader;
