export const icons = {
  'error-dark': 'close-circle-outlined',
  'warning-dark': 'exclamation-outlined',
  'success-dark': 'check-circle-outlined',
  'info-dark': 'info-circle',
  'error-light': 'close-circle-outlined',
  'warning-light': 'exclamation-outlined',
  'success-light': 'check-circle-outlined',
  'info-light': 'info-circle',
};
