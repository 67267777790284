import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import {
  StudentAssignmentStatus,
  StudentAssignmentStatus as StudentAssignmentStatusEnum,
} from '@lon/shared/requests';

export const getStudentAssignmentStatus = (
  status?: StudentAssignmentStatusEnum,
  returnedAt?: Date
) => {
  const isAssigned = status === StudentAssignmentStatusEnum.Assigned;
  const isToBeGraded = status === StudentAssignmentStatusEnum.Submitted;
  const isCompleted = status === StudentAssignmentStatusEnum.Completed;
  const isStarted = status === StudentAssignmentStatusEnum.Started;
  const isReturned = !!returnedAt;

  switch (true) {
    case isCompleted:
      return StudentAssignmentStatusTransformed.Graded;
    case isReturned:
      return StudentAssignmentStatusTransformed.Returned;
    case isToBeGraded:
      return StudentAssignmentStatusTransformed.ToBeGraded;
    case isAssigned:
      return StudentAssignmentStatusTransformed.Assigned;
    case isStarted:
      return StudentAssignmentStatusTransformed.Started;

    default:
      return null;
  }
};

export const isCompletedAssignment = (status: StudentAssignmentStatus) =>
  status === StudentAssignmentStatus.Completed ||
  status === StudentAssignmentStatus.Submitted;
