import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { IoCloseSharp } from '@react-icons/all-files/io5/IoCloseSharp';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { sidebarSlice } from '@lon/shared/configs';
import { PAGE_HEADER_HEIGHT } from '@lon/shared/constants';
import { StudentGradeContext } from '@lon/shared/contexts';
import { CHILD_PAGE_HEADER_HEIGHT } from '@lon/suit/constants';

const LeftContentHeader: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isOpen) {
      timeoutId = setTimeout(() => buttonRef.current?.focus(), 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  return (
    <Box
      display={{ b1281: 'none' }}
      zIndex={4}
      w="full"
      minH={`${
        isStudentBelowK3 ? CHILD_PAGE_HEADER_HEIGHT : PAGE_HEADER_HEIGHT
      }px`}
      boxShadow="0px 2px 15px 0px #2b364659"
    >
      <Flex
        align="center"
        h="full"
        backgroundColor="#193A6B"
        px={6}
        justify="end"
      >
        <Tooltip
          variant="dark"
          aria-hidden={true}
          label={t('common.closeSidebar')}
        >
          <IconButton
            ref={buttonRef}
            data-testid="openCloseButton"
            size="sm"
            w="8"
            h="8"
            variant="solid"
            backgroundColor="#193A6B"
            aria-label={t('common.closeSidebar')}
            fontSize="2xl"
            icon={<IoCloseSharp />}
            onClick={() => dispatch(sidebarSlice.toggleOpen())}
          />
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default LeftContentHeader;
