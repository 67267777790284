import { Box, Flex, TabList, Tabs, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTab } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { ClassesFilters } from './duck';

const Filters: React.FC = () => {
  const { t } = useTranslation();
  const [params, setParams] = useQueryParams<ClassesFilters>();

  // TODO uncomment when grade filter will be implemented
  // const { currentSchoolId } = useContext(WorkingLocation);
  // const { selectedGrade } = useContext(ClassesListContext);
  // const { data: gradesData, loading: gradesLoading } = useGetGradesQuery({
  //   variables: { schoolId: currentSchoolId as string },
  // });
  // const options = [
  //   allGradesOption,
  //   ...(gradesData?.grades?.grades.map((value) => ({
  //     label: value,
  //     value: value,
  //   })) || []),
  // ];

  return (
    <Flex
      borderRadius={{ b768: 'md' }}
      backgroundColor="white"
      px={8}
      pt={4}
      pb={8}
      w="full"
      gap={6}
      flexWrap="wrap"
    >
      {/* // TODO uncomment when grade filter will be implemented
      <Box flexGrow={1}>
        <Select
          size="s"
          label={t('classList.selectGradeLabel')}
          placeholder={t('classList.selectGradePlaceholder')}
          value={
            selectedGrade
              ? {
                  label: selectedGrade,
                  value: selectedGrade,
                }
              : allGradesOption
          }
          isDisabled={gradesLoading}
          isLoading={gradesLoading}
          options={options}
          formLabelProps={{
            mb: '2 !important',
          }}
          onChange={(item) => {
            setParams(
              {
                ...params,
                grade:
                  item?.value !== allGradesOption.value
                    ? item?.value
                    : undefined,
              },
              { replace: true }
            );
          }}
        />
      </Box> */}
      <Box color="primary.800" textAlign="left">
        <Text
          variant="n1"
          textAlign="left"
          color="primary.800"
          lineHeight="1.375rem"
        >
          {t('classList.statusTabsLabel')}
        </Text>
        <Tabs
          variant="drawerInternal"
          textAlign="left"
          marginTop="2"
          isFitted
          index={Number(params.get('isArchived') === 'true')}
          onChange={(value) => {
            setParams(
              {
                ...params,
                isArchived: Boolean(value),
              },
              { replace: true }
            );
          }}
        >
          <TabList height="32px" display="inline-flex">
            <CustomTab
              size="sm"
              w="50%"
              paddingInline={3}
              minW="fit-content"
              iconProps={{
                width: '16px',
                height: '16px',
              }}
            >
              {t('classList.activeTab')}
            </CustomTab>
            <CustomTab
              size="sm"
              w="50%"
              paddingInline={3}
              minW="fit-content"
              iconProps={{
                width: '16px',
                height: '16px',
              }}
            >
              {t('classList.archivedTab')}
            </CustomTab>
          </TabList>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default Filters;
