import { Dispatch, SetStateAction, createContext } from 'react';

export const OnlineModeContext = createContext<{
  isOnline: boolean;
  setIsOnline: Dispatch<SetStateAction<boolean>>;
  isLogged: boolean;
  setIsLogged: Dispatch<SetStateAction<boolean>>;
}>({
  isOnline: true,
  setIsOnline: () => {},
  isLogged: false,
  setIsLogged: () => {},
});
