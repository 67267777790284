import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import clsx from 'clsx';
import { AnswersTemplateProps } from './duck';
import styles from './AnswersTemplate.module.css';

const AnswersTemplate: React.FC<AnswersTemplateProps> = ({
  isLearnosityLoading,
  studentAssignments,
  students,
}) => (
  <Box className="student-answers" w="full" flexGrow="1">
    {studentAssignments.map((assignment, index) => {
      const student = students?.find(
        (student) => student?._id === assignment?.studentId
      );

      return (
        <Flex
          key={assignment.id}
          direction="column"
          className={clsx(styles.studentAnswerTemplate, {
            [styles.hideReport]: isLearnosityLoading,
          })}
        >
          <Text variant="h3" my="5">{`${student?.lastName || ''} ${
            student?.firstName || ''
          }`}</Text>
          {isLearnosityLoading && <Spinner alignSelf="center" />}
          <Box id={`report-${index + 1}`} />
        </Flex>
      );
    })}
  </Box>
);

export default AnswersTemplate;
