export enum UserSettingsTabEnum {
  Accessibility = 0,
  Courses = 1,
  Teams = 2,
  Credentials = 3,
}

export const TABS: any[] = [
  {
    name: 'userSettings.accessibility.title',
    icon: 'accessibility-outlined',
    disabled: false,
    value: UserSettingsTabEnum.Accessibility,
  },
  {
    name: 'userSettings.courses.title',
    icon: 'bookOutlined',
    disabled: false,
    value: UserSettingsTabEnum.Courses,
  },
  {
    name: 'userSettings.teams.title',
    icon: 'user-group',
    disabled: false,
    value: UserSettingsTabEnum.Teams,
  },
  {
    name: 'userSettings.credentials.title',
    icon: 'key-outlined',
    disabled: false,
    value: UserSettingsTabEnum.Credentials,
  },
];
