import { ActionsContext } from '../../duck/context';
import { Item } from '../item';
import { useMutation } from '@apollo/client';
import { Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { IdPart } from '@lon/shared/enums';
import { MutationUpdateClassArgs } from '@lon/shared/requests';
import { clearQueryCache } from '@lon/shared/utils';
import { UPDATE_CLASS, UpdateClassResponse } from './duck';

const ArchiveClass: React.FC = () => {
  const { t } = useTranslation();
  const { classItem } = useContext(ActionsContext);
  const isArchivedClass = classItem?.archived;
  const [updateClass] = useMutation<
    UpdateClassResponse,
    MutationUpdateClassArgs
  >(UPDATE_CLASS);

  const toggleArchiveStatus = async () => {
    await updateClass({
      variables: {
        input: {
          id: `${IdPart.Classes}${classItem?._id}`,
          archived: !classItem?.archived,
        },
      },
      onCompleted: () => clearQueryCache('classes'),
    });
  };

  return (
    <Item
      label={
        isArchivedClass
          ? t('classList.classActions.unarchiveClassTooltip')
          : t('classList.classActions.archiveClassTooltip')
      }
      onClick={toggleArchiveStatus}
    >
      <Icon name="archived" size="lg" />
      <Text as="span" variant="s2">
        {isArchivedClass
          ? t('classList.classActions.unarchiveClass')
          : t('classList.classActions.archiveClass')}
      </Text>
    </Item>
  );
};

export default ArchiveClass;
