import { Icon } from '../../icon';
import { MenuItem } from '../../menu';
import actionsStyles from '../ToolbarActions.module.css';
import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher: React.FC = (props) => {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState(true);
  const title = language
    ? t('toolbar.switchToSpanish')
    : t('toolbar.switchToEnglish');

  return (
    <MenuItem {...props}>
      <IconButton
        size="md"
        variant="solidDark"
        className={actionsStyles.action}
        title={title}
        aria-label={title}
        onClick={() => setLanguage(!language)}
      >
        <Icon
          name={language ? 'language-switcher-es' : 'language-switcher-en'}
          size="lg"
        />
      </IconButton>
    </MenuItem>
  );
};

export default LanguageSwitcher;
