import { DrawerRequiredFieldsInstruction } from '../drawer-required-fields-instruction';
import { Icon } from '../icon';
import { ConfirmModal } from '../modals';
import { TeamIndividualTabs } from '../team-individual-tabs';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  setPacingGuideList,
  setPacingGuideListLoading,
} from '@lon/shared/configs';
import { FontSizeEnum, sxLightScrollBar } from '@lon/shared/constants';
import { planIdPrefix } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { DistrictPermission } from '@lon/shared/enums';
import {
  useGetIsLeaderSuitFlag,
  useGetSchoolIdsForPlanner,
  usePermission,
} from '@lon/shared/hooks';
import { useUpdatePlansList } from '@lon/shared/hooks';
import {
  CreateScopePlanInput,
  GetScopePlanQuery,
  UpdateScopePlanInput,
  useCreateScopePlanMutation,
  useGetGradesFromSchoolIdQuery,
  useGetPlanTemplatesListLazyQuery,
  useGetPlannerCurriculumAreasQuery,
  useGetScopePlanLazyQuery,
  usePacingGuidesLazyQuery,
  useRemoveScopePlanMutation,
  useScopePlansLazyQuery,
  useUpdateScopePlanMutation,
} from '@lon/shared/requests';
import * as plannerSelectors from '@lon/shared/selectors';
import { parseJSON } from '@lon/shared/utils';
import { sanitizeWithSpecialChars } from '@lon/shared/utils';
import { types, utils } from './duck';
import { TeamIndividualEnum } from './duck/types';
import { Skeleton, TeamIndividualPlan } from './components';

const PlansDrawer: React.FC<types.PlanDrawerType> = ({
  drawerOpen,
  handleDrawerClose,
  isEdit,
  viewOnly,
  scopePlanId,
  isCreatePlanFromScopes = false,
  addElementToPlan,
  addElementProps,
  defaultDisabledTab,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [canAccessAIPlanner] = usePermission([
    {
      permission: DistrictPermission.TEACHER_SUIT,
      module: 'lessonPlanner',
      operation: 'access',
    },
  ]);
  const preferences = useSelector(
    (state: RootState) => state.applicationSettings?.preferences
  );
  const isLargeFontSize = useMemo(() => {
    const fontSize: FontSizeEnum = (parseJSON(preferences) as any)?.fontSize;
    return fontSize === FontSizeEnum.medium || fontSize === FontSizeEnum.large;
  }, [preferences]);
  const { application, currentSchoolId } = useContext(WorkingLocation);
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();
  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const { teamIds: userTeamIds, teamsList } = useSelector(
    plannerSelectors.teams
  );
  const selectedPlanScopeElements = useSelector(plannerSelectors.planScopes);

  const { validationSchema, defaultValues } =
    utils.getValidation(addElementProps);
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
  });
  const {
    getValues,
    watch,
    resetField,
    reset,
    formState: { isDirty: isDirtyState, dirtyFields },
  } = formProviderProps;
  const formChange = watch();
  const dispatch = useDispatch();

  const isDirty = isDirtyState && Boolean(Object.keys(dirtyFields).length);

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const {
    isOpen: isConfirmSaveOpen,
    onOpen: onConfirmSaveOpen,
    onClose: onConfirmSaveClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmationOpen,
    onClose: onDeleteConfirmationClose,
    onOpen: onDeleteConfirmationOpen,
  } = useDisclosure();

  const userRoleCode =
    useSelector(plannerSelectors.userRoleCode) ||
    application?.replace('Suit', '') ||
    '';

  const { data: curriculumAreas } = useGetPlannerCurriculumAreasQuery({
    ...(!isLeaderSuit
      ? {
          skip: !currentSchoolId,
          variables: { schoolId: currentSchoolId as string },
        }
      : {}),
  });
  const { data: grades } = useGetGradesFromSchoolIdQuery({
    ...(!isLeaderSuit
      ? { skip: !currentSchoolId, variables: { schoolId: currentSchoolId } }
      : {}),
  });

  const [getPacingGuideList, { data: pacingRaw, loading: pacingLoading }] =
    usePacingGuidesLazyQuery({
      variables: {
        schoolIds: accessibleSchoolIds,
        userTeamIds,
        userRoleCode,
      },
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data?.pacingGuides?.collection) {
          dispatch(setPacingGuideList(data?.pacingGuides?.collection));
          dispatch(setPacingGuideListLoading(false));
        }
      },
    });

  const [getScopePlans, { data: scopesRaw, loading: plansLoading }] =
    useScopePlansLazyQuery({
      variables: {
        schoolIds: accessibleSchoolIds,
        userRoleCode,
        userTeamIds,
      },
      fetchPolicy: 'no-cache',
    });

  const [
    getSingleScopePlan,
    { data: scopePlanResp, loading: editPlanLoading },
  ] = useGetScopePlanLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [getPlanTemplates, { data: planTemplatesResp }] =
    useGetPlanTemplatesListLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [updateScopePlan] = useUpdateScopePlanMutation();

  const [executeCreateScopePlan, { loading }] = useCreateScopePlanMutation({
    refetchQueries: ['getPlanFromFields'],
  });

  const [deleteScopePlan, { loading: deletePlanLoading }] =
    useRemoveScopePlanMutation();
  const [updatePlansList] = useUpdatePlansList(!isCreatePlanFromScopes);

  const pacingInfo = useMemo(() => {
    return pacingRaw?.pacingGuides?.collection;
  }, [scopesRaw, pacingRaw]);

  const [isValid, setIsValid] = useState<boolean>(false);
  const [team, setTeam] = useState<boolean>(false);
  const [planData, setPlanData] = useState<GetScopePlanQuery | null>();

  const handleCreateSave = async () => {
    const form = getValues();

    if (utils.validateScopePlanWithPace(form, team)) {
      await createScopePlanWithPace(form);
    } else if (utils.validateScopePlanNoPace(form, team)) {
      await createScopePlanNoPace(form);
    } else {
      toastMessage(true, t('plans.badScopeInput'));
    }
    handleClose();
  };

  const handleEditSave = async () => {
    const form = getValues();
    const updateInput = {
      version: planData?.scopePlan?.version || 1,
      schoolIds: accessibleSchoolIds,
      userRoleCode,
      userTeamIds,
      id: planIdPrefix + planData?.scopePlan?._id || '',
      scopes: selectedPlanScopeElements,
      pacingGuideId: team
        ? form.pacingGuideTeam?.value
        : form.pacingGuideInd?.value,
      approachType: form.adaptive ? 'Adaptive' : 'Traditional',
      ...(canAccessAIPlanner && {
        classSize: form.classSize ? parseInt(form.classSize) : 0,
        classTime: form.classTime ? parseInt(form.classTime) : 0,
        teacherExp: form.experience ? parseInt(form.experience) : 0,
      }),
    } as UpdateScopePlanInput;

    try {
      await updateScopePlan({
        variables: {
          input: updateInput,
        },
        refetchQueries: ['getScopePlan'],
        onCompleted() {
          toastMessage(
            false,
            t('plans.updatePlanSuccessTitle'),
            t('plans.updatePlanSuccessDesc')
          );
        },
      });
    } catch (e) {
      toastMessage(
        true,
        t('plans.updatePlanFailedTitle'),
        t('plans.updatePlanFailedDesc')
      );
    }

    handleClose();
  };

  const handleDelete = async () => {
    try {
      if (scopePlanResp?.scopePlan?._id) {
        await deleteScopePlan({
          variables: {
            input: {
              id: planIdPrefix + scopePlanResp?.scopePlan?._id,
              schoolIds: accessibleSchoolIds,
              userRoleCode,
              userTeamIds,
            },
          },
          onCompleted() {
            updatePlansList(null, true);
            toastMessage(false, t('plans.planDeleteSuccess'));
          },
        });
      }
    } catch (error) {
      toastMessage(true, t('plans.planDeleteFailed'));
    }
    handleClose();
  };

  const handleClose = () => {
    reset(defaultValues);
    resetField('grade');
    resetField('curriculumArea');
    setTeam(false);
    onConfirmClose();
    onDeleteConfirmationClose();
    onConfirmSaveClose();
    handleDrawerClose();
    setPlanData(null);
  };

  const createScopePlanNoPace = async (form: FieldValues) => {
    const scopeInput = {
      name: `${form.curriculumArea?.value} ${form.grade?.value}`,
      curriculumArea: form.curriculumArea?.value,
      grade: form.grade?.value,
      scopePlanType: team ? t('plans.team') : t('plans.individual'),
      schoolIds: accessibleSchoolIds,
      userRoleCode,
      userTeamIds,
      teamId: team ? form.teamId?.value : '',
      scopePlanTemplateId: form?.scopePlanTemplateId?.value || '',
      approachType: form.adaptive ? 'Adaptive' : 'Traditional',
      ...(canAccessAIPlanner && {
        classSize: form.classSize ? parseInt(form.classSize) : 0,
        classTime: form.classTime ? parseInt(form.classTime) : 0,
        teacherExp: form.experience ? parseInt(form.experience) : 0,
      }),
    } as CreateScopePlanInput;

    await executeCreateScopePlan({
      variables: {
        input: scopeInput,
      },
      async onCompleted(data) {
        toastMessage(
          false,
          t('plans.scopeCreateSuccess'),
          sanitizeWithSpecialChars(
            t('plans.scopeCreateSuccessDescription', {
              name: `${scopeInput.curriculumArea} ${scopeInput.grade}`,
              interpolation: {
                escapeValue: false,
              },
            })
          )
        );

        if (!isCreatePlanFromScopes) {
          updatePlansList(data.createScopePlan?.scopePlan?.id);
        } else if (addElementToPlan && data.createScopePlan?.scopePlan?._id) {
          addElementToPlan(data.createScopePlan?.scopePlan?._id);
        }
      },
      onError(error) {
        if (error && error.message === t('plans.accessDenied')) {
          toastMessage(true, t('plans.accessDeniedMsg'));
        } else {
          toastMessage(true, t('plans.scopeCreateFailed'));
        }
      },
    });
  };

  const createScopePlanWithPace = async (form: FieldValues) => {
    const scopeInput = {
      pacingGuideId: team
        ? form.pacingGuideTeam?.value
        : form.pacingGuideInd?.value,
      scopePlanType: team ? t('plans.team') : t('plans.individual'),
      schoolIds: accessibleSchoolIds,
      userRoleCode,
      userTeamIds,
      teamId: team ? form.teamId?.value : '',
      approachType: form.adaptive ? 'Adaptive' : 'Traditional',
      scopePlanTemplateId: form?.scopePlanTemplateId?.value || '',
      ...(canAccessAIPlanner && {
        classSize: form.classSize ? parseInt(form.classSize) : 0,
        classTime: form.classTime ? parseInt(form.classTime) : 0,
        teacherExp: form.experience ? parseInt(form.experience) : 0,
      }),
    } as CreateScopePlanInput;

    await executeCreateScopePlan({
      variables: {
        input: scopeInput,
      },
      async onCompleted(data) {
        toastMessage(
          false,
          t('plans.scopeCreateSuccess'),
          sanitizeWithSpecialChars(
            t('plans.scopeCreateSuccessDescription', {
              name: `${form.curriculumArea?.value} ${form.grade?.value}`,
              interpolation: {
                escapeValue: false,
              },
            })
          )
        );
        if (!isCreatePlanFromScopes) {
          updatePlansList(data.createScopePlan?.scopePlan?.id);
        } else if (addElementToPlan && data.createScopePlan?.scopePlan?._id) {
          addElementToPlan(data.createScopePlan?.scopePlan?._id);
        }
      },
      onError(error) {
        if (error && error.message === t('plans.accessDenied')) {
          toastMessage(true, t('plans.accessDeniedMsg'));
        } else {
          toastMessage(true, t('plans.scopeCreateFailed'));
        }
      },
    });
  };

  const toastMessage = (
    error: boolean,
    message: string,
    description?: string
  ) => {
    toast({
      position: 'bottom',
      title: message,
      status: error ? 'error' : 'success',
      duration: 3000,
      isClosable: true,
      variant: error ? 'error-light' : 'success-light',
      description: description ? description : undefined,
    });
  };

  useEffect(() => {
    const form = getValues();
    setIsValid(
      utils.validateScopePlanWithPace(form, team) ||
        utils.validateScopePlanNoPace(form, team)
    );
  }, [formChange]);

  useEffect(() => {
    if (scopePlanId && drawerOpen) {
      getSingleScopePlan({
        variables: {
          id: scopePlanId,
        },
      });
    }
  }, [scopePlanId, drawerOpen]);

  useEffect(() => {
    if (accessibleSchoolIds && userRoleCode && drawerOpen) {
      getScopePlans();
      getPacingGuideList();
    }
  }, [drawerOpen, userTeamIds, accessibleSchoolIds]);

  useEffect(() => {
    if (
      isEdit &&
      drawerOpen &&
      ((scopePlanResp?.scopePlan?.pacingGuideId && pacingRaw?.pacingGuides) ||
        (scopePlanResp?.scopePlan && !scopePlanResp?.scopePlan?.pacingGuideId))
    ) {
      const formattedResponse = utils.getResponseFormatted(
        scopePlanResp?.scopePlan,
        pacingRaw?.pacingGuides,
        teamsList
      );
      reset(formattedResponse);
      setPlanData(scopePlanResp);
    }
  }, [pacingRaw, scopePlanResp, teamsList]);

  useEffect(() => {
    if (
      grades &&
      curriculumAreas &&
      accessibleSchoolIds &&
      userTeamIds &&
      drawerOpen &&
      !isCreatePlanFromScopes
    ) {
      getPlanTemplates({
        variables: {
          userRoleCode,
          curriculumAreas: curriculumAreas?.curriculumAreas?.curriculumAreas,
          grades: grades?.grades?.grades,
          schoolIds: accessibleSchoolIds,
          userTeamIds,
        },
      });
    }
  }, [
    grades,
    curriculumAreas,
    accessibleSchoolIds,
    userTeamIds,
    userRoleCode,
    drawerOpen,
  ]);

  useEffect(() => {
    // for leader only team plan is enabled
    if (isLeaderSuit) {
      setTeam(true);
    }
    if (defaultDisabledTab) {
      setTeam(defaultDisabledTab !== t('planner.team'));
    }
  }, [isLeaderSuit, defaultDisabledTab]);

  return (
    <Drawer
      variant="formDrawer"
      isOpen={drawerOpen}
      onClose={() => {
        if (isDirty && isValid) {
          onConfirmOpen();
        } else {
          handleClose();
        }
      }}
    >
      <DrawerOverlay />
      <DrawerContent border="none" boxShadow="none">
        <DrawerHeader p={{ base: '4', md: '6' }} px={{ md: '8' }}>
          <Flex justifyContent="space-between" py={{ base: '2', md: '0' }}>
            <Text fontSize="1.25rem" color="primary.800">
              {isEdit ? t('plans.editHeader') : t('plans.newHeader')}
            </Text>
            {isDirty && isEdit ? (
              <Flex
                mr={{ base: '4.5rem', md: '3.5rem' }}
                sx={{
                  border: `0.5px solid`,
                  borderColor: 'yellow.d',
                  color: 'yellow.d',
                  backgroundColor: 'yellow.extraLight',
                  fontSize: '0.8rem',
                  padding: '1px 10px',
                  borderRadius: 4,
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text variant="s2">{t('tags.edited')}</Text>
              </Flex>
            ) : null}
            {!isEdit ? (
              <Flex
                mr={{ base: '4.5rem', md: '3.5rem' }}
                sx={{
                  border: `0.5px solid`,
                  borderColor: 'green.text',
                  color: 'green.text',
                  backgroundColor: 'green.light',
                  fontSize: '0.8rem',
                  padding: '1px 10px',
                  borderRadius: 4,
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text variant="s2" pt={0}>
                  {t('tags.new')}
                </Text>
              </Flex>
            ) : null}
          </Flex>

          <DrawerCloseButton
            data-testid="close-drawer"
            onClick={() => {
              if (isDirty && isValid) {
                onConfirmOpen();
              } else {
                handleClose();
              }
            }}
            pb={2}
          />
        </DrawerHeader>

        <DrawerBody css={sxLightScrollBar} pb={5}>
          <DrawerRequiredFieldsInstruction />
          {isEdit && (editPlanLoading || pacingLoading) ? (
            <Skeleton />
          ) : (
            <TeamIndividualTabs
              editDrawerTabType={
                isEdit ? scopePlanResp?.scopePlan?.scopePlanType : null
              }
              defaultDisabledTab={defaultDisabledTab}
              selectTypeTitle="plans.planType"
              setTeamState={setTeam}
              isLeader={isLeaderSuit}
              individualTabPanel={
                <FormProvider {...formProviderProps}>
                  <TeamIndividualPlan
                    viewOnly={viewOnly}
                    formProps={formProviderProps}
                    pacing={pacingInfo}
                    selectedTab={team}
                    isEdit={isEdit}
                    team={TeamIndividualEnum.Individual}
                    partialViewOnly={isCreatePlanFromScopes}
                    addElementProps={addElementProps}
                    pacingLoading={pacingLoading || plansLoading}
                    suggestedPlansTemplates={
                      planTemplatesResp?.scopePlanTemplates
                    }
                  />
                </FormProvider>
              }
              teamTabPanel={
                <FormProvider {...formProviderProps}>
                  <TeamIndividualPlan
                    viewOnly={viewOnly}
                    formProps={formProviderProps}
                    pacing={pacingInfo}
                    selectedTab={team}
                    isEdit={isEdit}
                    team={TeamIndividualEnum.Team}
                    partialViewOnly={isCreatePlanFromScopes}
                    addElementProps={addElementProps}
                    pacingLoading={pacingLoading || plansLoading}
                    suggestedPlansTemplates={
                      planTemplatesResp?.scopePlanTemplates
                    }
                  />
                </FormProvider>
              }
            />
          )}
        </DrawerBody>

        <HStack
          bottom={0}
          left={0}
          width="100%"
          justifyContent="space-between"
          px={{ base: 4, md: 8 }}
          py={6}
          borderTop="1px solid secondary.200"
          background="white"
        >
          <Flex gap={{ base: 4, md: 6 }}>
            <Button
              onClick={() => {
                if (isDirty && isValid) {
                  onConfirmOpen();
                } else {
                  handleClose();
                }
              }}
            >
              <Text as="span">{t('buttons.cancel')}</Text>
            </Button>
            <IconButton
              size="md"
              variant="ghost"
              icon={<Icon name="deleteOutlined" />}
              aria-label={t('buttons.deletePlan')}
              display={{ base: 'inline-flex', md: 'none' }}
              onClick={onDeleteConfirmationOpen}
              isLoading={deletePlanLoading}
              isDisabled={!isEdit || loading || editPlanLoading || isDirty}
            />
            <Button
              isDisabled={!isEdit || loading || editPlanLoading}
              isLoading={deletePlanLoading}
              onClick={onDeleteConfirmationOpen}
              variant="ghost"
              leftIcon={<Icon name="deleteOutlined" />}
              display={{ base: 'none', md: 'inline-flex' }}
            >
              <Text as="span">
                {t(
                  isLargeFontSize
                    ? 'buttons.deletePlanShort'
                    : 'buttons.deletePlan'
                )}
              </Text>
            </Button>
          </Flex>
          <Button
            onClick={onConfirmSaveOpen}
            variant="solid"
            isDisabled={
              !(
                isValid &&
                (isDirty ||
                  (addElementProps?.curriculumArea && addElementProps?.grade))
              ) ||
              editPlanLoading ||
              deletePlanLoading
            }
            isLoading={loading}
            leftIcon={<Icon name="check-simple-outlined" />}
            px={2}
          >
            <Text as="span">{isEdit ? t('form.save') : t('form.create')}</Text>
          </Button>
        </HStack>
      </DrawerContent>
      <ConfirmModal
        primaryHeading={t('plans.savePromptPrimaryHeading')}
        subHeading={t('plans.savePromptSubHeading')}
        hasDontSaveBtn
        isModalOpen={isConfirmOpen}
        isLoading={loading}
        onModalClose={onConfirmClose}
        handleSave={isEdit ? handleEditSave : handleCreateSave}
        handleDontSave={handleClose}
        handleCancel={onConfirmClose}
        modalVariant="formDrawerModal"
        submitButtonText={t('buttons.createBtn')}
        submitBtnProps={{ leftIcon: <Icon name="check-simple-outlined" /> }}
        dontSaveBtnProps={{
          leftIcon: <Icon name="deleteOutlined" />,
          variant: 'link',
        }}
        dontSaveBtnText={t('buttons.dontCreateBtn')}
      />
      <ConfirmModal
        primaryHeading={t('plans.createPromptPrimaryHeading')}
        subHeading={t('plans.createPromptSubHeading')}
        hasDontSaveBtn
        isModalOpen={isConfirmSaveOpen}
        isLoading={loading}
        onModalClose={onConfirmSaveClose}
        handleSave={isEdit ? handleEditSave : handleCreateSave}
        handleDontSave={handleClose}
        handleCancel={onConfirmSaveClose}
        modalVariant="formDrawerModal"
        submitButtonText={isEdit ? t('buttons.save') : t('buttons.createBtn')}
        submitBtnProps={{ leftIcon: <Icon name="check-simple-outlined" /> }}
        dontSaveBtnProps={{
          leftIcon: <Icon name="deleteOutlined" />,
          variant: 'link',
        }}
        dontSaveBtnText={t('buttons.dontCreateBtn')}
      />
      <ConfirmModal
        primaryHeading={t('plans.deletePrompt')}
        subHeading={t('plans.deletePromptSubHeading')}
        isModalOpen={isDeleteConfirmationOpen}
        onModalClose={onDeleteConfirmationClose}
        isLoading={deletePlanLoading}
        handleSave={handleDelete}
        handleCancel={onDeleteConfirmationClose}
        submitButtonText={t('buttons.deletePlan')}
        modalVariant="formDrawerModal"
      />
    </Drawer>
  );
};

export default PlansDrawer;
