import update from 'immutability-helper';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { FiltersReducer } from './types';

type FiltersActions = ActionType<typeof actions>;

const initialState: FiltersReducer = {};

const reducer = createReducer<FiltersReducer, FiltersActions>(initialState)
  .handleAction(actions.setFilters, (state, { payload }) => {
    return update(state, { $merge: payload });
  })
  .handleAction(
    actions.removeFilter,
    (state, { payload: { field, filtersKey } }) =>
      update(state, {
        [filtersKey]: {
          filter: {
            $unset: [field],
          },
        } as any,
      })
  )
  .handleAction(
    actions.removeAllFilters,
    (state, { payload: { filtersKey } }) => ({
      ...state,
      [filtersKey]: {} as any,
    })
  )
  .handleAction(
    actions.disableAllFilters,
    (state, { payload: { filtersKey } }) => ({
      ...state,
      [filtersKey]: {} as any,
    })
  )
  .handleAction(
    actions.setPagination,
    (state, { payload: { filtersKey, pagination } }) => ({
      ...state,
      [filtersKey]: {
        filter: state[filtersKey]?.filter,
        pagination,
      } as any,
    })
  );

export default reducer;
