import { SortingState } from '@tanstack/react-table';
import { SortEnum } from '@lon/shared/requests';

export const transformSorting = (
  sorting: SortingState,
  sortingMap?: { [key: string]: string }
): { [key: string]: SortEnum } => {
  return sorting.reduce((acc, item) => {
    const key = sortingMap ? sortingMap[item.id] || item.id : item.id;
    return {
      ...acc,
      [key]: item.desc ? SortEnum.Desc : SortEnum.Asc,
    };
  }, {});
};
