import React from 'react';
import { types } from './duck';
import { Navigation } from './components';
import { Skeleton as NavigationSkeleton } from './components/navigation/components';

const Menubar: React.FC<types.ScopeMenubarProps> = ({
  sections = [],
  sectionsLoading,
}) => {
  if (sectionsLoading) {
    return <NavigationSkeleton />;
  }
  return <Navigation sections={sections} sectionsLoading={sectionsLoading} />;
};

export default Menubar;
