import { MAX_NUMBER_OF_SCOPES_PER_QUERY } from '@lon/shared/constants';

export const ROW_OPTIONS = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

export const DEFAULT_PAGE = 1;

export const ITEMS_PER_PAGE_LIMITS: {
  [key: number]: number;
} = {
  10: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 10),
  30: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 30),
  50: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 50),
};
