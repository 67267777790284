import { KEYBOARD } from '@lon/shared/constants';
import { onNumberInputKeyDown } from '@lon/shared/utils';
import { ValidatePageNumber } from './types';


export const validatePageNumber = ({
  pageNumber,
  max,
  min = 1,
  successCallback,
}: ValidatePageNumber) => {
  if (pageNumber === '') {
    return;
  }

  let page = Number(pageNumber);

  if (page < min) {
    page = min;
  } else if (page > max) {
    page = max;
  }

  if (page) {
    successCallback(page);
  }
};

export const goToPage = (
  event: any,
  numberOfPages: number,
  successCallback: (page: number) => void
) => {
  validatePageNumber({
    pageNumber: (event.target as HTMLInputElement).value,
    max: numberOfPages,
    successCallback,
  });
};

export const onPageInputKeydown = (
  numberOfPages: number,
  successCallback: (page: number) => void
) => {
  return (event: React.KeyboardEvent<HTMLInputElement>) => {
    onNumberInputKeyDown(event);

    switch (event.keyCode) {
      case KEYBOARD.ENTER: {
        goToPage(event, numberOfPages, successCallback);
      }
    }
  };
};
