import { Button, Flex, Tag, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Icon, PageContent } from '@lon/shared/components';
import { sxDarkScrollBar } from '@lon/shared/constants';
import { useAuth, useGetIsLeaderSuitFlag } from '@lon/shared/hooks';
import { Audience, useGetHelpPageQuery } from '@lon/shared/requests';
import { BackButton } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { ContentStructure, Root, useFetchJsonData } from './duck';
import { NestedAccordion, Skeleton } from './components';

const HelpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { articleId } = useParams();
  const [selectedItem, setSelectedItem] = React.useState<ContentStructure>();
  const [{ profileType }] = useAuth();

  const isLeaderSuit = useGetIsLeaderSuitFlag();

  const { data, loading: loadingHelpPage } = useGetHelpPageQuery({
    skip: !profileType,
    variables: {
      input: {
        audience: isLeaderSuit
          ? Audience.Leader
          : (profileType as string)?.toLocaleLowerCase() ===
            (Audience.Teacher as string).toLocaleLowerCase()
          ? Audience.Teacher
          : Audience.Student,
      },
    },
  });

  const { data: jsonData, loading: loadingHelpPageData } = useFetchJsonData({
    url: (window.location.origin + data?.helpPage.link) as string,
    skip: !data?.helpPage.link,
  });

  React.useEffect(() => {
    const item = (jsonData as unknown as Root)?.contentStructure?.find(
      (i) =>
        i.items?.find((j) => j.identifier === articleId) ||
        i.identifier === articleId
    );
    if (item && item.identifier !== selectedItem?.identifier) {
      setSelectedItem(item);

      const element = window.document.getElementById(articleId as string);
      const button = window.document.getElementById(item.identifier as string);

      element?.scrollIntoView();
      button?.scrollIntoView();
    }
    if (!item) {
      setSelectedItem((jsonData as unknown as Root)?.contentStructure?.[0]);
    }
  }, [articleId, jsonData]);

  return (
    <PageContent
      pageTitle={t('helpPage.title')}
      headerElements={
        <BackButton
          label={
            (profileType as string)?.toLocaleLowerCase() ===
              (Audience.Teacher as string).toLocaleLowerCase() || isLeaderSuit
              ? t('backButton.additionalResource.moreDashboardLabel')
              : t('backButton.additionalResource.playGroundDashboardLabel')
          }
          tooltipLabel={
            (profileType as string)?.toLocaleLowerCase() ===
              (Audience.Teacher as string).toLocaleLowerCase() || isLeaderSuit
              ? t('backButton.additionalResource.moreDashboardToolTipLabel')
              : t(
                  'backButton.additionalResource.playGroundDashboardToolTipLabel'
                )
          }
          to={
            (profileType as string)?.toLocaleLowerCase() ===
              (Audience.Teacher as string).toLocaleLowerCase() || isLeaderSuit
              ? routes.additionalResources.root
              : routes.student.playground
          }
        />
      }
    >
      <Flex
        backgroundImage={'suit/assets/search-help-page.png'}
        bgRepeat="no-repeat"
        bgSize="cover"
        minHeight="180px"
        width="full"
        borderRadius={6}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text variant="h3" color="blue.500">
          {t('helpPage.howCanWeHelp')}
        </Text>

        <Button
          mt={2}
          rightIcon={<Icon name="searchOutlined" />}
          variant="solid"
        >
          {t('helpPage.clickToSearch')}
        </Button>
      </Flex>
      {loadingHelpPageData || loadingHelpPage ? (
        <Skeleton />
      ) : (
        <Flex>
          <Flex
            width="full"
            gap={4}
            height="calc(100vh - 420px)"
            overflow="hidden"
          >
            <Flex
              flexDirection="column"
              background={'white'}
              borderRadius="10px 10px"
            >
              <Flex
                boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.1)"
                width="full"
                p={'16px 24px'}
                minWidth="252px"
                borderRadius="10px 10px 0px 0px"
              >
                <Flex
                  width="full"
                  height="100%"
                  alignItems="center"
                  dropShadow="rgba(43, 54, 70, 0.1)"
                >
                  <Text variant="h5" color="blue.500">
                    {t('helpPage.topic')}
                  </Text>
                  <Tag
                    variant="counter"
                    border="none"
                    ml="12px"
                    color="primary.700"
                    p="0px 12px"
                  >
                    {(jsonData as unknown as Root)?.contentStructure.length}
                  </Tag>
                </Flex>
              </Flex>

              <Flex
                flexDirection="column"
                gap={2}
                p="16px 24px"
                overflowY="auto"
                css={sxDarkScrollBar}
              >
                {jsonData &&
                  (jsonData as unknown as Root)?.contentStructure?.map(
                    (content) => {
                      return (
                        <Button
                          isActive={
                            selectedItem?.identifier === content.identifier
                          }
                          id={content.identifier}
                          justifyContent="flex-start"
                          onClick={() => {
                            setSelectedItem(content);
                            navigate(
                              generatePath(
                                routes.additionalResources.help.root,
                                {
                                  articleId: content.identifier,
                                }
                              )
                            );
                          }}
                        >
                          {content?.title}
                        </Button>
                      );
                    }
                  )}
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              background="white"
              borderRadius="10px 10px"
              width="full"
              height="100%"
            >
              <Flex
                boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.1)"
                width="full"
                p="16px 32px"
                borderRadius="10px 10px 0px 0px"
              >
                <Flex
                  width="full"
                  alignItems="center"
                  dropShadow="rgba(43, 54, 70, 0.1)"
                >
                  <Text variant="h5" color="blue.500">
                    {t('helpPage.Articles')}
                  </Text>
                  <Tag
                    variant="counter"
                    border="none"
                    ml="12px"
                    color="primary.700"
                    p="0px 12px"
                  >
                    {(jsonData as unknown as Root)?.contentPaths?.length}
                  </Tag>
                </Flex>
              </Flex>

              <Flex
                height="100%"
                flexDirection="column"
                gap={2}
                overflowY="auto"
                css={sxDarkScrollBar}
              >
                <Flex
                  height="100%"
                  flexDirection="column"
                  width="full"
                  background="white"
                >
                  {selectedItem && (
                    <NestedAccordion
                      items={selectedItem.items}
                      selectedItem={selectedItem}
                      level={1}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </PageContent>
  );
};

export default HelpPage;
