import { clearQueryCache } from '../apolloClientUtils';
import { hashValue } from '../hashValue';
import { patchLocalStorage } from '../patchLocalStorage';
import redirectToApplication from '../redirectToApplication';
import { ApplicationEnum } from '@lon/shared/enums';
import { RoleCodeEnum } from '@lon/shared/gql/types';
import { types } from './duck';

const impersonateUser: types.ImpersonateUser = async ({
  createImpersonation,
  auth,
  userId,
  mainProfileType,
  impersonatedProfileType,
  currentSchoolId,
  impersonatedApp,
}) => {
  if (userId) {
    try {
      const createImpersonationResponse = await createImpersonation({
        variables: {
          input: {
            userId,
          },
        },
      });

      const impersonatedUser = {
        ...createImpersonationResponse?.data?.createImpersonation?.impersonation
          ?.user,
        ...(mainProfileType === RoleCodeEnum.PlatformAdmin && {
          state: auth?.data?.user?.state || 'TX',
        }),
      };
      clearQueryCache(['applicationSettings', 'aclApplications']);

      localStorage.setItem(
        'impersonate',
        JSON.stringify({
          type: impersonatedProfileType,
          id: impersonatedUser?._id || '',
          redirectTo: window.location.href || '',
          mainProfileType,
        })
      );

      if (impersonatedUser?._id) {
        hashValue(impersonatedUser?._id).then((value) => {
          patchLocalStorage('lastSchoolIdsBySignature', {
            [value]: currentSchoolId || '1',
          });
        });
      }

      if (impersonatedApp) {
        return redirectToApplication(impersonatedApp);
      }

      if (
        [RoleCodeEnum.PlatformAdmin, RoleCodeEnum.SystemAdmin].includes(
          mainProfileType
        ) &&
        impersonatedProfileType === RoleCodeEnum.SystemAdmin
      ) {
        return redirectToApplication(ApplicationEnum.ADMIN);
      }

      if (
        [RoleCodeEnum.PlatformAdmin].includes(mainProfileType) &&
        [
          RoleCodeEnum.Adoptions,
          RoleCodeEnum.CustomerSupport,
          RoleCodeEnum.Finance,
        ].includes(impersonatedProfileType)
      ) {
        return redirectToApplication(ApplicationEnum.PLATFORM_ADMIN);
      }

      if (
        [RoleCodeEnum.PlatformAdmin, RoleCodeEnum.SystemAdmin].includes(
          mainProfileType
        ) &&
        [RoleCodeEnum.Parent].includes(impersonatedProfileType)
      ) {
        return redirectToApplication(ApplicationEnum.PARENT_SUIT);
      }

      if (
        mainProfileType &&
        [RoleCodeEnum.SystemAdmin, RoleCodeEnum.PlatformAdmin].includes(
          mainProfileType
        ) &&
        [RoleCodeEnum.Teacher, RoleCodeEnum.Student].includes(
          impersonatedProfileType
        )
      ) {
        return redirectToApplication(ApplicationEnum.TEACHER_SUIT);
      }

      return redirectToApplication(ApplicationEnum.STUDENT_SUIT);
    } catch (err) {
      console.error(err);
    }
  }
};

export default impersonateUser;
