import { useAcl } from '../use-acl';
import useGetIsLeaderSuitFlag from '../use-get-is-leader-suit-flag';
import { uniq } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { AclApplication } from '@lon/shared/requests';

export const useGetSchoolIdsForPlanner = () => {
  const { application, currentSchoolId } = useContext(WorkingLocation);
  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const aclInfo = useAcl<AclApplication[]>({ application });

  const accessibleSchoolIds = useMemo(() => {
    if (isLeaderSuit && aclInfo?.length) {
      const leaderSchoolIds = aclInfo.reduce<string[]>((allSchools, aclApp) => {
        if (aclApp?.schools) {
          const schoolIds = aclApp.schools.flatMap(
            (school) => school.schoolIds
          );
          return [...allSchools, ...schoolIds];
        }
        return allSchools;
      }, []);
      return uniq(leaderSchoolIds);
    }
    return currentSchoolId ? [currentSchoolId] : null;
  }, [aclInfo, isLeaderSuit, currentSchoolId]);

  return accessibleSchoolIds;
};

export default useGetSchoolIdsForPlanner;
