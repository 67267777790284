import {
  GetClassForAssignmentFormQuery,
  GetClassStudentsForAssignmentFormQuery,
  GetClassSubgroupsForAssignmentFormQuery,
  GetScopesForAssignmentFormQuery,
} from '@lon/shared/requests';

export enum TabKey {
  classes = 'classes',
  subgroups = 'subgroups',
  individualStudents = 'individualStudents',
  gradeLevels = 'gradeLevels',
  schools = 'schools',
}

export interface Tab {
  key: TabKey;
  tab: string;
  TabPanel: React.FC<TabProps>;
}

export interface ManageAssigneesProps {
  setCurrentManageTab: React.Dispatch<React.SetStateAction<TabKey>>;
  scopeNames: { [key: string]: string };
}

export interface TabProps {
  classesOptions?: { label: string; value: string }[];
  classesLoading?: boolean;
  isDistrictAssessmentForOneSchool?: boolean | null;
  isDistrictAssessment?: boolean;
}

export interface GetScopesOptionsProps {
  classData?:
    | GetClassForAssignmentFormQuery
    | GetClassSubgroupsForAssignmentFormQuery
    | GetClassStudentsForAssignmentFormQuery;
  isAppropriateClass: boolean;
  scopesData?: GetScopesForAssignmentFormQuery;
}
