import { Box, Divider, Flex, Tag, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkingLocation } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import { useGetStudentTeachersQuery } from '@lon/shared/requests';
import { Skeleton } from './components';

const Teachers: React.FC<{
  isStudentBelowK3: boolean;
}> = ({ isStudentBelowK3 }) => {
  const { t } = useTranslation();
  const [{ user }] = useAuth();
  const { currentSchoolId } = React.useContext(WorkingLocation);

  const { data, loading } = useGetStudentTeachersQuery({
    variables: {
      filter: {
        studentsId: user?.userId,
        schoolId: currentSchoolId,
      },
    },
  });

  const teachers = data?.classes?.collection?.flatMap((classObj) =>
    classObj?.classTeachers?.collection?.map((teacherObj) => ({
      id: teacherObj?.teacher?.id,
      name: `${teacherObj?.teacher?.firstName} ${teacherObj?.teacher?.lastName}`,
    }))
  );

  const uniqueTeachers = [
    ...new Map(teachers?.map((teacher) => [teacher?.id, teacher])).values(),
  ];

  return (
    <>
      <Divider />
      <Box px={{ base: 6, lg: '150px' }}>
        <VStack align="left" w="full">
          <Text
            variant="h6"
            fontWeight={isStudentBelowK3 ? 600 : 400}
            lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
          >
            {t('userSettings.credentials.teachers')?.toUpperCase()}
          </Text>
          <Text variant="s2" color="primary.400" whiteSpace="pre-line">
            {t('userSettings.credentials.teacherDescription')}
          </Text>
        </VStack>
        {loading ? (
          <Skeleton />
        ) : (
          <Flex wrap="wrap" gap={2} mt={6}>
            {uniqueTeachers?.map((val) => (
              <Tag
                key={val?.name}
                minH="22px"
                px={3}
                fontWeight={400}
                bg="primary.30"
                color="primary.700"
                borderRadius="0.75rem"
                cursor="default"
                className="notranslate"
              >
                <Box pl={2}>{val?.name}</Box>
              </Tag>
            ))}
          </Flex>
        )}
      </Box>
    </>
  );
};

export default Teachers;
