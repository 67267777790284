import isBefore from 'date-fns/isBefore';
import isSameOrBefore from './isSameOrBefore';

export const isAssignmentActive = (
  startDate: Date,
  endDate: Date,
  lateTurnIn?: boolean
) => {
  const currentDate = new Date();
  return (
    !isBefore(currentDate, startDate) &&
    (lateTurnIn || isSameOrBefore(currentDate, endDate))
  );
};

export default isAssignmentActive;
