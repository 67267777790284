import { Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { Answer } from '@lon/shared/types';
import { Body, Header } from './components';

const Grading: React.FC<{
  items: Answer[];
  feedbacks: {
    feedback: string;
    responseId: string;
  }[];
}> = ({ items, feedbacks }) => {
  return (
    <Flex>
      {items.map((item) => {
        const container = document
          .getElementById(item.responseId)
          ?.closest('.learnosity-question-container') as Element;

        if (!container) {
          return null;
        }

        const feedbackObj = feedbacks.find(
          (feedback) => feedback.responseId === item.responseId
        );

        return createPortal(
          <Flex
            flexDir="column"
            position="relative"
            minH="1px"
            ml="1rem"
            mr="1rem"
            sx={{
              '.lrn_scrollablepassage &': { ml: { lg: 0 }, mr: { lg: 0 } },
              '.two-columns-layout &': { ml: 0, mr: 0 },
            }}
          >
            <Header response={item} />
            <Body feedback={feedbackObj?.feedback} />
          </Flex>,
          container
        ) as ReactElement;
      })}
    </Flex>
  );
};

export default Grading;
