import { Box, Icon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Trans, useTranslation } from 'react-i18next';
import { returnAssignment } from '@lon/shared/assets';
import { Message } from '@lon/shared/components';
import { IntroMessageProps } from './duck';

const IntroMessage: React.FC<IntroMessageProps> = ({
  isMultiple,
  studentAssignments,
}) => {
  const { t } = useTranslation();
  const endDates = [
    ...new Set(
      studentAssignments.map((assignment) =>
        format(parseISO(assignment.time.endDate), 'MMMM, do')
      )
    ),
  ];
  const passedEndDates = [
    ...new Set(
      studentAssignments
        .filter((assignment) => !assignment.lateTurnIn)
        .filter((assignment) => {
          const endDate = assignment.time?.endDate;
          return (
            endDate &&
            parseISO(endDate).setHours(0, 0, 0, 0) <
              new Date().setHours(0, 0, 0, 0)
          );
        })
        .map((assignment) =>
          format(parseISO(assignment.time.endDate), 'MMMM, do')
        )
    ),
  ];

  return (
    <Message
      mb="4"
      variant="info"
      icon={<Icon as={returnAssignment} w="50px" h="50px" />}
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      message={
        <Box whiteSpace="pre-line">
          <Text variant="s3" whiteSpace="pre-line">
            {t(
              `assignments.${isMultiple ? 'introMessageGroup' : 'introMessage'}`
            )}
          </Text>
          {!isMultiple && passedEndDates.length === 1 && (
            <Text variant="s3" whiteSpace="pre-line">
              {'\n'}
              {t('assignments.introMessageDate', {
                originalEndDate: passedEndDates[0],
              })}
            </Text>
          )}
          {isMultiple && !!passedEndDates.length && (
            <>
              {'\n'}
              <Trans i18nKey="assignments.introMessageMultipleDates">
                <Text variant="s3" whiteSpace="pre-line">
                  The original end date for all or some of the selected students
                  has past. Selected students have multiple original assignment
                  end dates:
                </Text>
                <UnorderedList ml={6}>
                  {endDates.map((date) => (
                    <ListItem key={date}>
                      <Text variant="s3">{date}</Text>
                    </ListItem>
                  ))}
                </UnorderedList>
                <Text variant="s3" whiteSpace="pre-line">
                  Select a new assignment end date below. The same new date will
                  be applied to all selected students.
                </Text>
              </Trans>
            </>
          )}
        </Box>
      }
    />
  );
};

export default IntroMessage;
