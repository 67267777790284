import { getEnvVariable } from '@lon/shared/utils';

export const MENU_BUTTON_SIZE = 48;

export const stemDomain = getEnvVariable(
  'NX_STEMSCOPES_URL',
  'https://dev2019.stemscopes.com'
);

export const prodStemDomain = 'https://www.acceleratelearning.com';
