import { navigationTypes } from '../../../../duck';
import { SectionCard } from '../section-card';
import { useGetIconQuery } from '@lon/shared/requests';

const SuitSectionCardWrapper: React.FC<
  navigationTypes.SectionCardWrapperPropTypes
> = ({ sectionIconType, ...props }) => {
  const { data: iconResp, loading } = useGetIconQuery({
    skip: !sectionIconType,
    variables: {
      filter: {
        type: sectionIconType,
      },
    },
  });

  const iconPath = iconResp?.icons?.collection?.find(
    (icon) => icon?.filePath
  )?.filePath;

  return <SectionCard {...props} iconPath={iconPath} iconLoading={loading} />;
};

export default SuitSectionCardWrapper;
