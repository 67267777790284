import { Button, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';

const NoClassesByFilter: FC = () => {
  const { t } = useTranslation();
  const [params, setParams] = useQueryParams();
  const isArchived = params.isArchived === 'true';
  const isDefaultFilters = !params.grade && !isArchived;

  return (
    <Flex
      flexGrow="1"
      direction="column"
      align="center"
      justify="center"
      gap="6"
      color="primary.800"
      bgColor="white"
      borderRadius="6px"
      px="8"
    >
      <Flex direction="column" align="center" gap="4" textAlign="center">
        <Text variant="h5" maxW="300px">
          {t('classes.noClassesByFilterTitle')}
        </Text>
        <Text variant="n3" maxW="250px">
          {t('classes.noClassesByFilterDesc')}
        </Text>
      </Flex>
      <Icon name="rocket-no-data" />
      <Button
        variant="solid"
        isDisabled={isDefaultFilters}
        onClick={() => {
          setParams(
            {
              isArchived: undefined,
              grade: undefined,
            },
            { replace: true }
          );
        }}
      >
        {t('classes.setDefaultFilters')}
      </Button>
    </Flex>
  );
};

export default NoClassesByFilter;
