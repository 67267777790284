import { lazyWithRetry } from '@lon/shared/utils';

const TeachersList = lazyWithRetry(
  () => import('./teachers-list'),
  'TeachersList'
);
const EditTeacher = lazyWithRetry(
  () => import('./edit-teacher'),
  'EditTeacher'
);
const StudentRoster = lazyWithRetry(
  () => import('./student-roster'),
  'StudentRoster'
);
const EditStudent = lazyWithRetry(
  () => import('./edit-student'),
  'EditStudent'
);

const DistrictAssessmentResults = lazyWithRetry(
  () => import('./district-assessment-results'),
  'DistrictAssessmentResults'
);

export {
  TeachersList,
  EditTeacher,
  StudentRoster,
  EditStudent,
  DistrictAssessmentResults,
};
