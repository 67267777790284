import { Button, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { hideTTS, showTTS } from '@lon/suit/constants';

const Speech = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const isTTSVisible = queryParams.tts === showTTS;

  const setShowTTS = (tts = 'ON', replace = true) =>
    navigate('?' + getQuery({ ...queryParams, tts }), { replace });

  return (
    <Tooltip
      variant="dark"
      label={
        isTTSVisible
          ? t('helpPage.textToSpeechTooltipOff')
          : t('helpPage.textToSpeechTooltip')
      }
      aria-hidden={true}
    >
      <Button
        variant="ghost"
        aria-label={
          isTTSVisible ? t('toolbar.disableSpeech') : t('toolbar.enableSpeech')
        }
        /* eslint-disable-next-line jsx-a11y/aria-props */
        aria-description={t('toolbar.ariaSpeechDescription')}
        leftIcon={<Icon name={isTTSVisible ? 'noSound' : 'sound'} size="lg" />}
        onClick={() => {
          setShowTTS(isTTSVisible ? hideTTS : showTTS, false);
          window.postMessage({ type: 'tts-toggle-resource-player' });
        }}
        _hover={{
          backgroundColor: 'primary.30',
        }}
      >
        <Text as="span" isTruncated>
          {t('helpPage.textToSpeech')}
        </Text>
      </Button>
    </Tooltip>
  );
};

export default Speech;
