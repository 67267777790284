import React from 'react';
import styles from './Skeleton.module.css';
import { ContentLoader } from "@lon/shared/components";

const Skeleton: React.FC = () => (
  <div className={styles.container}>
    <ContentLoader viewBox="0 0 1350 770">
      <rect x="0" y="0" width="100%" height="20" />
      <rect x="0" y="36" width="100%" height="20" />
      <rect x="0" y="72" width="100%" height="20" />
      <rect x="0" y="108" width="100%" height="20" />
      <rect x="0" y="142" width="100%" height="20" />
      <rect x="0" y="178" width="100%" height="20" />
      <rect x="0" y="214" width="100%" height="20" />
      <rect x="0" y="250" width="100%" height="20" />
      <rect x="0" y="286" width="400" height="300" />
      <rect x="0" y="618" width="100%" height="30" />
      <rect x="0" y="664" width="100%" height="20" />
      <rect x="0" y="700" width="360" height="20" />
    </ContentLoader>
  </div>
);

export default Skeleton;
