import { ModalBody, ModalBodyProps } from '@chakra-ui/react';

export interface BodyProps extends ModalBodyProps {
  children: React.ReactNode;
}

const Body: React.FC<BodyProps> = ({ children, ...rest }) => {
  return <ModalBody {...rest}>{children}</ModalBody>;
};

export default Body;
