import { ErrorMessage } from '../../../../../errors';
import { Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScopeContext } from '@lon/shared/contexts';
import { useCurrentElement } from '@lon/shared/hooks';
import { Main, Skeleton } from './components';

const Element: React.FC<{ hasToolbar?: boolean }> = ({ hasToolbar = true }) => {
  const { t } = useTranslation();
  const currentElement = useCurrentElement();
  const { loading, refetch, attempts, setAttempts, projectType } =
    React.useContext(ScopeContext);

  if (loading) {
    return <Skeleton />;
  }
  if (!currentElement) {
    return (
      <ErrorMessage
        body={
          <Text as="p" variant="n3" pb="12">
            {t('errorMessage.404Body')}
          </Text>
        }
      />
    );
  }

  return (
    <Main
      element={currentElement as any}
      refetch={refetch}
      setAttempts={setAttempts}
      attempts={attempts}
      projectType={projectType}
      hasToolbar={hasToolbar}
    />
  );
};

export default Element;
