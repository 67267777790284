import { Box, Skeleton as ChakraSkeleton, Flex } from '@chakra-ui/react';
import React from 'react';

const Skeleton: React.FC = () => (
  <Box data-testid="sidebar-skeleton" p={6}>
    <Flex direction="column" gap={2}>
      <ChakraSkeleton h="22px" />
      <ChakraSkeleton h="20px" />
      <ChakraSkeleton h="20px" />
      <ChakraSkeleton h="96px" mt={3} />
    </Flex>
    <Flex direction="column" gap={3} mt={4}>
      <ChakraSkeleton h="44px" />
      <ChakraSkeleton h="44px" />
      <ChakraSkeleton h="44px" />
      <ChakraSkeleton h="44px" />
    </Flex>
  </Box>
);

export default Skeleton;
