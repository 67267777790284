import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

const SkeletonInput = () => (
  <Box width="100%">
    <Skeleton height="22px" mb={2.5} width="50%" />
    <Skeleton height="44px" />
  </Box>
);

export default SkeletonInput;
