import {
  StudentAssignment,
  getAssignmentData,
} from '../../../../report-dashboard/district-assessment-details/duck';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import {
  useGetClassesForDaDetailsQuery,
  useGetDistrictAssignmentsQuery,
  useGetUsersQuery,
} from '@lon/shared/requests';

const useStudentsNavigation = () => {
  const { studentId, sessionId, assignmentId } = useParams();
  const isSingleStudentGrading = studentId && sessionId;

  const [{ user }] = useAuth();

  const { currentSchoolId: schoolId = '' } = React.useContext(WorkingLocation);
  const navigate = useNavigate();

  const { data: districtAssignmentsData, loading: districtAssignmentsLoading } =
    useGetDistrictAssignmentsQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          teacherFilter: {
            schoolId,
            daId: assignmentId,
          },
        },
      },
      skip: !schoolId,
    });

  const {
    studentAssignments,
    districtAssignmentClasses,
    districtAssignmentStudents,
  } = useMemo(() => {
    const districtAssignmentsList =
      districtAssignmentsData?.districtAssignments?.collection || [];
    const studentAssignmentsList = districtAssignmentsList
      .flatMap((districtAssessment) => districtAssessment?.studentAssignments)
      .filter(Boolean) as StudentAssignment[];

    return {
      studentAssignments: studentAssignmentsList,
      districtAssignmentClasses: [
        ...new Set(
          studentAssignmentsList
            ?.map((studentAssignment) => studentAssignment?.classId)
            .filter(Boolean) as string[]
        ),
      ],
      districtAssignmentStudents: [
        ...new Set(
          studentAssignmentsList
            ?.map((studentAssignment) => studentAssignment?.studentId)
            .filter(Boolean) as string[]
        ),
      ],
    };
  }, [districtAssignmentsData]);

  const { data: studentsData, loading: studentsLoading } = useGetUsersQuery({
    variables: {
      id_list: districtAssignmentStudents,
      itemsPerPage: 9999,
    },
    skip: !districtAssignmentStudents.length,
  });

  const { data: classesData, loading: classesLoading } =
    useGetClassesForDaDetailsQuery({
      variables: {
        teachersId: user.userId,
        schoolId: schoolId,
        id_list: districtAssignmentClasses,
      },
      skip: !schoolId || !user?.userId || !districtAssignmentClasses.length,
    });

  const students = useMemo(() => {
    const formattedData = getAssignmentData({
      studentAssignments,
      students: studentsData?.users?.collection,
      classes: classesData?.classes?.collection,
    });

    return (
      formattedData?.filter(
        (st) =>
          st?.transformedStatus &&
          [
            StudentAssignmentStatusTransformed.ToBeGraded,
            StudentAssignmentStatusTransformed.Graded,
            StudentAssignmentStatusTransformed.Returned,
          ].includes(st.transformedStatus)
      ) || []
    );
  }, [studentAssignments, studentsData, classesData]);

  const { currentStudent, currentStudentIndex } = React.useMemo(() => {
    if (!students) return { currentStudent: null, currentStudentIndex: null };

    const currentIndex = students.findIndex(
      (student) => student?.studentId === studentId
    );

    return {
      currentStudent: students[currentIndex],
      currentStudentIndex: currentIndex,
    };
  }, [students, studentId]) as {
    // @ts-ignore
    currentStudent: ReturnType<typeof getAssignmentData>[number];
    currentStudentIndex: number;
  };

  const nextStudent = students[currentStudentIndex + 1];
  const previousStudent = students[currentStudentIndex - 1];
  const studentName = currentStudent
    ? `${currentStudent.firstName} ${currentStudent.lastName}`
    : '';

  // to avoid content and extra-props for child components
  React.useEffect(() => {
    navigate('.', { replace: true, state: { studentName } });
  }, [studentName]);

  return {
    isSingleStudentGrading,
    loading: districtAssignmentsLoading || studentsLoading || classesLoading,
    currentStudentIndex,
    amountOfStudents: students?.length,
    studentName,
    nextStudent,
    previousStudent,
  };
};

export default useStudentsNavigation;
