import { Checkbox, CheckboxProps } from '@chakra-ui/react';

const IndeterminateCheckbox = ({
  isIndeterminate,
  isChecked,
  ...rest
}: CheckboxProps) => (
  <Checkbox
    {...rest}
    isChecked={isChecked}
    isIndeterminate={!isChecked && isIndeterminate}
    verticalAlign="middle"
    colorScheme="blue"
    size="md"
    mr={1}
  />
);

export default IndeterminateCheckbox;
