import { Dispatch, SetStateAction, createContext } from 'react';
import { PreparedDistrictAssignmentData } from '@lon/suit/types';
import { AreasTypes, DistrictAssignment } from './types';

export const DistrictAssignmentContext = createContext<{
  districtAssignment?: DistrictAssignment;
  districtAssignments: DistrictAssignment[];
  preparedDistrictAssignmentData?: PreparedDistrictAssignmentData[];
  isArchived: boolean;
  isLoading: boolean;
  showAnswersColumn: boolean;
  setShowAnswersColumn: Dispatch<SetStateAction<boolean>>;
  resetAreas: () => void;
  updateAreas: (type: keyof AreasTypes, value: boolean) => void;
  refetch: () => Promise<any>;
}>({
  isArchived: false,
  isLoading: false,
  showAnswersColumn: false,
  districtAssignments: [],
  setShowAnswersColumn: () => {},
  resetAreas: () => {},
  updateAreas: () => {},
  refetch: async () => {},
});
