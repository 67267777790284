import {
  AspectRatio,
  Flex,
  Image,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { StudentGradeContext } from '@lon/shared/contexts';
import { resizeImage } from '@lon/shared/utils';
import { types } from './duck';

const LeftContentCard: React.FC<types.LeftContentCardProps> = ({
  title,
  imageUrl,
  alt,
  children,
  imageWidth = '252px',
  isLoading = false,
  ...rest
}) => {
  const { isStudentBelowK3 } = useContext(StudentGradeContext);
  const [hasError, setHasError] = useState(false);

  return (
    <Flex direction={'column'} w="full" {...rest}>
      <Flex>
        <AspectRatio ratio={16 / 9} minW="150px" w={imageWidth} mb={6}>
          {isLoading ? (
            <Skeleton
              w="252px"
              h="142px"
              borderRadius={isStudentBelowK3 ? '10px' : 0}
            />
          ) : (
            <Image
              src={
                hasError || !imageUrl
                  ? 'suit/assets/horizontal-plug.svg'
                  : resizeImage({ src: imageUrl, height: 600, width: 800 })
              }
              alt={alt}
              objectFit="cover"
              onError={() => {
                if (!hasError) {
                  setHasError(true);
                }
              }}
              borderRadius={isStudentBelowK3 ? '10px' : 0}
            />
          )}
        </AspectRatio>
      </Flex>
      <Flex overflow="hidden" flexDir="column" gap={6} color="white">
        {isLoading ? (
          <VStack alignItems="flex-start" spacing={4}>
            <Skeleton w="200px" h="30px" />
            <VStack alignItems="flex-start" spacing={2}>
              <Skeleton w="150px" h="20px" />
              <Skeleton w="100px" h="20px" />
            </VStack>
          </VStack>
        ) : (
          <>
            <Text title={title} variant="h5" flex="1">
              {title}
            </Text>
            {children}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default LeftContentCard;
