import { useDispatch } from 'react-redux';
import { applicationSettingsSlice } from '@lon/shared/configs';
import {
  FontFamilyEnum,
  FontSizeEnum,
  defaultVoices,
  fontSizesMapping,
} from '@lon/shared/constants';
import { ThemeEnum } from '@lon/shared/enums';
import { useGetAclApplicationsQuery } from '@lon/shared/requests';
import { Preferences } from '@lon/shared/types';
import { parseJSON } from '@lon/shared/utils';

const usePreferences = () => {
  const dispatch = useDispatch();
  useGetAclApplicationsQuery({
    onCompleted: (data) => {
      const preferences = parseJSON<Preferences>(
        data?.applicationSettings?.value?.preferences
      );

      if (!preferences) {
        return;
      }
      const fontFamily = preferences?.fontFamily || FontFamilyEnum.verdana;
      const fontSize = preferences?.fontSize || FontSizeEnum.default;

      localStorage.setItem(
        'voicePreferences',
        JSON.stringify({
          spanishVoice: preferences?.spanishVoice || defaultVoices.spanish,
          englishVoice: preferences?.englishVoice || defaultVoices.english,
        })
      );

      localStorage.setItem(
        'videoSettings',
        JSON.stringify({
          descriptiveAudio: !!preferences?.descriptiveAudio,
        })
      );

      dispatch(
        applicationSettingsSlice.setApplicationSettings(
          data?.applicationSettings?.value
        )
      );

      const themeChangeEvent = new CustomEvent('THEME_CHANGE_EVENT', {
        detail: {
          theme:
            preferences?.theme === ThemeEnum.HIGH_CONTRAST
              ? ThemeEnum.HIGH_CONTRAST
              : ThemeEnum.DEFAULT,
        },
      });
      dispatchEvent(themeChangeEvent);

      document.documentElement.style.fontSize =
        fontSizesMapping?.[fontFamily]?.[fontSize]?.fontSize;

      document.documentElement.style.fontFamily = fontFamily;
    },
  });
};

export default usePreferences;
