import { sanitize } from 'dompurify';

export const sanitizeWithSpecialChars = (text: string) => {
  const entities: { [key: string]: string } = {
    amp: '&',
    apos: "'",
    '#x27': "'",
    '#x2F': '/',
    '#39': "'",
    '#47': '/',
    lt: '<',
    gt: '>',
    nbsp: ' ',
    quot: '"',
  };

  return sanitize(text).replace(/&([^;]+);/gm, (match, entity) => {
    return entities[entity] || match;
  });
};

export default sanitizeWithSpecialChars;
