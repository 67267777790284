import { Alert, Box, CloseButton, Icon } from '@chakra-ui/react';
import { HiOutlineCheckCircle } from '@react-icons/all-files/hi/HiOutlineCheckCircle';
import React, { ReactNode } from 'react';
import styles from './SuccessAlert.module.css';

const SuccessAlert = ({
  text,
  onClose,
}: {
  text: string | ReactNode;
  onClose: any;
}) => {
  return (
    <Alert
      status="success"
      borderRadius="6px"
      bgColor="green.700"
      color="white"
      fontWeight={700}
      gap={4}
      className={styles.root}
    >
      <Icon as={HiOutlineCheckCircle} fontSize="2rem" />
      <Box>{text}</Box>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
      />
    </Alert>
  );
};

export default SuccessAlert;
