import { Badge } from '@chakra-ui/react';
import { isEqual } from 'lodash-es';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StatusLabelProps } from './duck';

const StatusLabel: FC<StatusLabelProps> = ({ index, data }) => {
  const formProviderProps = useFormContext();
  const { t } = useTranslation();

  const coursesData = formProviderProps.watch('courses');

  if (!coursesData?.[index]?.saved) {
    return <Badge variant="greenBadge">{t('form.new')}</Badge>;
  } else {
    let isChanged = false;

    if (!isEqual(coursesData?.[index], data?.[index])) {
      isChanged = true;
    }

    return isChanged ? (
      <Badge variant="yellowBadge">{t('form.edit')}</Badge>
    ) : null;
  }
};

export default StatusLabel;
