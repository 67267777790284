import ButtonGroup from '../button-group/ButtonGroup';
import { IconButton, ListItem, UnorderedList } from '@chakra-ui/react';
import { BsGrid } from '@react-icons/all-files/bs/BsGrid';
import { FaListUl } from '@react-icons/all-files/fa/FaListUl';
import { FC, useState } from 'react';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { ViewTypes } from '@lon/shared/types';
import { ViewSwitcherProps } from './duck/types';

const ViewSwitcher: FC<ViewSwitcherProps> = ({
  onChange = () => {},
  initial = ViewTypes.Tile,
}) => {
  const [view, setView] = useState<ViewTypes>(initial);

  const changeView = (type: ViewTypes) => {
    setView(type);
    onChange(type);
  };

  return (
    <ButtonGroup
      as={UnorderedList}
      listStyleType="none"
      role={undefined}
      p="1px"
      marginLeft="0px"
      backgroundColor={useThemeStyleValue('white', 'inherit')}
      gap="0"
    >
      <ListItem>
        <IconButton
          data-testid="tile-view-button"
          size="sm"
          variant={
            view === ViewTypes.Tile ? 'groupButtonActive' : 'groupButton'
          }
          aria-label="Tile view"
          icon={<BsGrid />}
          onClick={() => changeView(ViewTypes.Tile)}
          borderTopRightRadius="0"
          borderBottomRightRadius="0"
          borderRight="none"
        />
      </ListItem>
      <ListItem>
        <IconButton
          data-testid="list-view-button"
          size="sm"
          variant={
            view === ViewTypes.List ? 'groupButtonActive' : 'groupButton'
          }
          aria-label="List view"
          icon={<FaListUl />}
          onClick={() => changeView(ViewTypes.List)}
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          borderLeft="none"
        />
      </ListItem>
    </ButtonGroup>
  );
};

export default ViewSwitcher;
