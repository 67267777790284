import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import { WidgetReducer } from './types';

type WidgetsActions = ActionType<typeof actions>;

const initialState: WidgetReducer = {
  weekAtAGlanceWidgetsWeeks: {},
};

const reducer = createReducer<WidgetReducer, WidgetsActions>(
  initialState
).handleAction(
  actions.setWeekAtAGlanceWeek,
  (state, { payload: { widgetId, selectedWeek } }) => ({
    ...state,
    weekAtAGlanceWidgetsWeeks: {
      ...state.weekAtAGlanceWidgetsWeeks,
      [widgetId]: selectedWeek,
    },
  })
);
export default reducer;
