export const wrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  bgColor: 'blue.500',
  p: '4',
  h: '16',
  color: 'white',
};

export const title = {
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
