import { useContext, useMemo } from 'react';
import { AclContext } from '@lon/shared/contexts';
import { UseAcl } from './duck/types';

//@ts-ignore
export const useAcl: UseAcl = (opts) => {
  const { application, permission, module, operation, schoolId } = opts || {};

  const { value: aclApplications } = useContext(AclContext);

  if (!module && operation) {
    throw new Error('Operation cannot be passed without the module');
  }
  if (!application && !permission && module) {
    throw new Error(
      'Module cannot be passed without the application or permission'
    );
  }
  if (!application && schoolId) {
    throw new Error('SchoolId cannot be passed without the application');
  }

  const applications = useMemo(() => {
    // Select acl application
    if (application && !module && !operation) {
      return aclApplications.filter(
        (aclApplication) => aclApplication.name === application
      );
    }

    // Select module without school
    if (application && module && !schoolId && !operation) {
      const selectedApplications = aclApplications.filter(
        (aclApplication) => aclApplication.name === application
      );

      return selectedApplications.find((aclApp) =>
        aclApp.modules.find((aclModule) => aclModule.name === module)
      );
    }

    // Select module from school
    if (application && module && schoolId && !operation) {
      const selectedApplication = aclApplications.find(
        (aclApplication) => aclApplication.name === application
      );
      const selectedSchool = selectedApplication?.schools.find((aclSchool) =>
        aclSchool.schoolIds.includes(schoolId)
      );

      return selectedSchool?.modules.find(
        (aclModule) => aclModule.name === module
      );
    }

    if (application && module && !schoolId && operation) {
      const selectedApplication = aclApplications.find(
        (aclApplication) => aclApplication.name === application
      );
      const selectedModule = selectedApplication?.modules.find(
        (aclModule) => aclModule.name === module
      );

      return selectedModule?.operations.find(
        (aclOperation) => aclOperation.name === operation
      );
    }

    if (permission && module && operation) {
      const selectedPermissions = aclApplications.find(
        (aclApplication) => aclApplication.permissionName === permission
      );
      const selectedModule = selectedPermissions?.modules.find(
        (aclModule) => aclModule.name === module
      );

      return selectedModule?.operations.find(
        (aclOperation) => aclOperation.name === operation
      );
    }

    if (application && module && schoolId && operation) {
      const selectedApplication = aclApplications.find(
        (aclApplication) => aclApplication.name === application
      );
      const selectedSchool = selectedApplication?.schools.find((aclSchool) =>
        aclSchool.schoolIds.includes(schoolId)
      );
      const selectedModule = selectedSchool?.modules.find(
        (aclModule) => aclModule.name === module
      );

      return selectedModule?.operations.find(
        (aclOperation) => aclOperation.name === operation
      );
    }

    return aclApplications;
  }, [application, permission, module, operation, schoolId, aclApplications]);

  return applications;
};
