import { Dropdown } from '../../../../../inputs';
import { Flex, Heading } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generatePath } from 'react-router-dom';
import { routes } from '@lon/shared/configs';
import { ScopeContext } from '@lon/shared/contexts';
import { styles, types } from './duck';

const LessonContentScopeHeader: React.FC<
  types.LessonContentScopeHeaderProps
> = ({ sections }) => {
  const navigate = useNavigate();

  const { elementsBySection } = useContext(ScopeContext);
  const { sectionId, elementId, scopeId, projectId } = useParams() as {
    sectionId: string;
    elementId: string;
    scopeId: string;
    projectId: string;
  };

  const headerTitle = React.useMemo(() => {
    return elementId
      ? elementsBySection?.[sectionId]?.[elementId]?.metadata?.elementName
      : sections?.find((section) => section.identifier === sectionId)?.metadata
          ?.sectionTitle;
  }, [elementsBySection, elementId, sectionId, sections]);

  const selectOptions: types.HeaderOption[] = React.useMemo(() => {
    if (!sectionId || !elementId) {
      return [];
    }

    return Object.values(elementsBySection?.[sectionId])?.map((item) => {
      return {
        label: item.metadata?.elementName || '',
        value: item.identifier,
      };
    });
  }, [elementsBySection, sectionId, elementId]);

  return (
    <Flex
      h="48px"
      px={6}
      align="center"
      justify="space-between"
      backgroundColor="blue.450"
    >
      <Heading as="h1" size="md" sx={styles.title}>
        {headerTitle}
      </Heading>
      {elementId ? (
        <Dropdown
          onChange={(option) => {
            navigate({
              pathname: generatePath(routes.scopes.element, {
                scopeId,
                sectionId,
                elementId: option.value,
                projectId,
              }),
            });
          }}
          selectOptions={selectOptions}
          value={selectOptions?.find((option) => option.value === elementId)}
        />
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default LessonContentScopeHeader;
