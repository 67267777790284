import { Box, Card, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { config } from './duck';

const ReportSkeleton: React.FC<{ type: string }> = ({ type }) => {
  return (
    <Box
      display="grid"
      alignSelf="flex-start"
      alignItems="flex-start"
      gridTemplateColumns={config[type].gridCol}
      gridTemplateRows={config[type].gridRow}
      gap={1}
    >
      {config[type].cols.map((col, i) => (
        <Card key={i} display="flex" gap={1}>
          {col.rows.map((row, y) => (
            <Skeleton key={y} h={row.h} w={row.w} />
          ))}
        </Card>
      ))}
    </Box>
  );
};

export default ReportSkeleton;
