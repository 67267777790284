export enum CreateAssignmentRespTypeEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
}

export type CreateAssignmentCustomResp = {
  status: CreateAssignmentRespTypeEnum;
  elementId: string;
  elementName: string;
};
