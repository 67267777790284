import { Assignment, Resource } from '../tabs';
import { Flex, Link, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { generatePath } from 'react-router-dom';
import { routes } from '@lon/suit/configs';
import { studentView } from '@lon/suit/constants';
import { GetTitleLinks } from './types';

export const getTabs = () => {
  return [
    {
      key: 'assignment',
      tab: t('createAssignmentDrawer.gradeableAssignment'),
      TabPanel: Assignment,
      confirmButtonText: t(
        'createAssignmentDrawer.switchTypeModal.confirmButton',
        {
          newType: 'Gradeable',
        }
      ),
    },
    {
      key: 'resource',
      tab: t('createAssignmentDrawer.resource'),
      TabPanel: Resource,
      confirmButtonText: t(
        'createAssignmentDrawer.switchTypeModal.confirmButton',
        {
          newType: 'Resource',
        }
      ),
    },
  ];
};

export const getTitleLinks: GetTitleLinks = ({
  assessmentData,
  elementsData,
  assessmentTitle,
  isAssessment,
  isEditAssignmentMode,
}) => {
  switch (true) {
    case isAssessment && isEditAssignmentMode:
      return (
        <Link
          target="_blank"
          href={`/suite${generatePath(routes.assessments.show, {
            id: assessmentData?.assessmentId || '',
          })}`}
        >
          <Text variant="n5NormalU">{assessmentData?.name}</Text>
        </Link>
      );
    case isAssessment:
      return <Text variant="n3">{assessmentTitle}</Text>;
    case !!elementsData?.projectId &&
      !!elementsData?.scopeId &&
      !!elementsData?.sectionId &&
      !!elementsData?.elementId &&
      !!elementsData?.viewId:
      return (
        <Flex gap="2">
          <Link
            target="_blank"
            href={`/suite${generatePath(routes.scopes.show, {
              projectId: elementsData?.projectId,
              scopeId: elementsData?.scopeId,
            })}`}
          >
            <Text variant="n5NormalU" whiteSpace="pre-wrap">
              {elementsData?.scopeName}
            </Text>
          </Link>
          {` > `}
          <Link
            target="_blank"
            href={`/suite${generatePath(routes.scopes.element, {
              projectId: elementsData?.projectId,
              scopeId: elementsData?.scopeId,
              sectionId: elementsData?.sectionId,
              elementId: elementsData?.elementId,
            })}?type=${studentView}&studentElementId=${elementsData?.viewId}`}
          >
            <Text variant="n5NormalU" whiteSpace="pre-wrap">
              {elementsData?.elementName}
            </Text>
          </Link>
        </Flex>
      );
    default:
      return (
        <Text variant="s2" whiteSpace="pre-wrap">
          {elementsData?.scopeName} {` > `} {elementsData?.sectionName} {` > `}{' '}
          {elementsData?.elementName}
        </Text>
      );
  }
};
