import { Box, Flex, useOutsideClick } from '@chakra-ui/react';
import React, { FocusEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarSlice } from '@lon/shared/configs';
import {
  CHILD_SITE_HEADER_HEIGHT,
  SIDEBAR_Z_INDEX,
  SITE_HEADER_HEIGHT,
  isMobileDevice,
} from '@lon/shared/constants';
import { StudentGradeContext } from '@lon/shared/contexts';
import { useMediaQuery, useThemeStyleValue } from '@lon/shared/hooks';
import { RootState } from '@lon/suit/configs';
import { types } from './duck';
import {
  LeftContentHeader,
  LowerStudentWrapper,
  MiddleHighStudentWrapper,
} from './components';

const LeftContent: React.FC<types.LeftContentProps> = ({
  leftContentDesktopWidth,
  leftColumnComponent,
  leftContentProps,
  leftContentWrapperProps,
}) => {
  const { isStudentBelowK3 } = React.useContext(StudentGradeContext);
  const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);

  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  const leftContentBorder = useThemeStyleValue('none', '1px solid #fff');

  useOutsideClick({
    enabled: isOpen && !isLargerThan768,
    ref: sidebarRef,
    handler: () => {
      if (isOpen && !isLargerThan768) {
        dispatch(sidebarSlice.closeSidebar());
      }
    },
  });

  const onOutsideBlur = (e: FocusEvent<HTMLDivElement, Element>) => {
    if (!e.relatedTarget) {
      return;
    }
    if (
      !e.currentTarget?.contains(e.relatedTarget) &&
      isOpen &&
      !isLargerThan768
    ) {
      dispatch(sidebarSlice.closeSidebar());
    }
  };

  const fullHeight = isMobileDevice ? '100dvh' : '100vh';

  return (
    <Box
      onBlur={onOutsideBlur}
      ref={sidebarRef}
      as="aside"
      position={{
        base: 'absolute',
        b1281: 'relative',
      }}
      top={{
        base: isStudentBelowK3
          ? -CHILD_SITE_HEADER_HEIGHT
          : -SITE_HEADER_HEIGHT,
        md: 0,
      }}
      h={{
        base: fullHeight,
        md: 'full',
      }}
      w={0}
      minW={{
        base: isOpen ? '100vw' : 0,
        b321: isOpen ? leftContentDesktopWidth : 0,
      }}
      transition="width, min-width 0.3s ease-out"
      boxShadow="4px 0px 7px 0px #2b364659"
      zIndex={SIDEBAR_Z_INDEX}
      {...(isOpen && {
        borderRight: leftContentBorder,
      })}
      backgroundColor={useThemeStyleValue('primary.800', 'secondary.1000')}
      {...leftContentProps}
    >
      <Box h="full" overflow="hidden">
        <Flex
          direction="column"
          transition="visibility 0.3s"
          visibility={isOpen ? 'visible' : 'hidden'}
          h="full"
          minW={{
            base: '100vw',
            b321: leftContentDesktopWidth,
          }}
        >
          <LeftContentHeader isOpen={isOpen} />
          {isStudentBelowK3 ? (
            <LowerStudentWrapper
              leftColumnComponent={leftColumnComponent}
              leftContentWrapperProps={leftContentWrapperProps}
            />
          ) : (
            <MiddleHighStudentWrapper
              leftColumnComponent={leftColumnComponent}
              leftContentWrapperProps={leftContentWrapperProps}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default LeftContent;
