import { createStandardAction } from 'typesafe-actions';
import * as Types from './types';

export const registerItem =
  createStandardAction('MENU/REGISTER_ITEM')<Types.MenuItem>();

export const unregisterItem = createStandardAction(
  'MENU/UNREGISTER_ITEM'
)<string>();

export const setActiveIndex = createStandardAction(
  'MENU/SET_ACTIVE_INDEX'
)<number>();

export const setReturnFocus = createStandardAction(
  'MENU/SET_RETURN_FOCUS'
)<Types.StateReturnFocus>();

export const toggleDisabled = createStandardAction('MENU/TOGGLE_DISABLED')<{
  id: string;
  disabled: boolean;
}>();

export const setConfig =
  createStandardAction('MENU/SET_CONFIG')<Types.MenuConfig>();
