import React from 'react';

type UseDebounce = (
  callback: (...args: any[]) => void,
  options?: { timeoutMs: number }
) => (...args: any[]) => void;

const useDebounce: UseDebounce = (callback, options) => {
  const { timeoutMs = 400 } = options || {};

  const timeoutRef = React.useRef<number>();

  return (...args: any[]): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => callback(...args), timeoutMs);
  };
};

export default useDebounce;
