import { GraphQLErrors } from "@apollo/client/errors";
import { UseFormSetError, UseFormSetFocus } from "react-hook-form";
import { t } from "i18next";
import { ERROR_CODES } from "@lon/shared/constants";

export const displayFormErrors = (
  graphQLErrors: GraphQLErrors = [],
  setError: UseFormSetError<any>,
  setFocus?: UseFormSetFocus<any>
): void => {
  graphQLErrors.forEach((error, idx) => {
    if (error.path && error.path.length > 1) {
      const [fieldName, code] = error?.message?.split(': ') || [];
      if (idx === 0 && typeof setFocus === 'function') {
        setFocus(fieldName);
      }

      setError(fieldName, {
        type: 'validation',
        message: t(
          `systemMessages.${ERROR_CODES[code as keyof typeof ERROR_CODES]}`
        ),
      });
    }
  });
};
