import { Answer } from '@lon/shared/types';

export const getDefaultValuesForGradingForm = (
  answers: Answer[],
  feedbacks: { feedback: string; responseId: string }[]
) => {
  return answers.reduce<Record<string, string>>((acc, item) => {
    acc[`score_${item.responseId}`] =
      typeof item.score === 'number'
        ? String(Math.round((item.score * 100) / (item.maxScore || 1)))
        : '';
    acc[`feedback_${item.responseId}`] =
      feedbacks.find((feedback) => feedback.responseId === item.responseId)
        ?.feedback || '';
    return acc;
  }, {});
};
