import {
  Badge,
  Box,
  Button,
  DrawerFooter,
  Flex,
  HStack,
  Radio,
  TabPanel,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import { useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ConfirmModal,
  FormRadioGroup,
  FormSwitcher,
  Icon,
} from '@lon/shared/components';
import {
  FontFamilyEnum,
  FontSizeEnum,
  fontFamilyMapping,
  fontSizesMapping,
  fontSizesMappingBelowK3,
  sxLightScrollBar,
} from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useGradeLevel } from '@lon/shared/hooks';
import { useUpdateUserMutation } from '@lon/shared/requests';
import { ApplicationSettingsReducer, Auth } from '@lon/shared/types';
import {
  clearQueryCache,
  handleError,
  isIpad,
  parseJSON,
} from '@lon/shared/utils';
import {
  englishVoices,
  fontSizes,
  getFontFamilies,
  spanishVoices,
  speakingRateTabs,
  themes,
  types,
  utils,
} from './duck';
import { ReadspeakerButton, SpeakingRate } from './components';

const AccessibilitySettings = React.forwardRef<
  {
    closeSettings: () => void;
    changeTab: (index: number) => void;
  },
  types.Props
>(({ setIsPreferencesOpen, setIsDirtyForm, setCurrentTab }, externalRef) => {
  const { userId } = useSelector((state: { auth: Auth }) => state.auth.user);
  const applicationSettings = useSelector(
    (state: { applicationSettings: ApplicationSettingsReducer }) =>
      state.applicationSettings
  );
  const { t } = useTranslation();
  const [changeTab, setChangeTab] = useState<number | null>(null);
  const toast = useToast();
  const isStudent = applicationSettings?.user?.type === 'student';
  const { isStudentBelowK3 } = useGradeLevel();
  const mapping = isStudentBelowK3 ? fontSizesMappingBelowK3 : fontSizesMapping;

  const { application } = React.useContext(WorkingLocation);

  const isAdminOrPlatformAdmin =
    application === ApplicationEnum.PLATFORM_ADMIN ||
    application === ApplicationEnum.ADMIN;

  const { defaultValues, validationSchema } = React.useMemo(
    () =>
      utils.getValidation({
        isStudentBelowK3,
      }),
    [isStudentBelowK3]
  );

  const form = useForm<types.Form>({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const fields = form.watch();
  const ttsCookie = Cookies.get('ReadSpeakerSettings');

  const [updateUser, { loading }] = useUpdateUserMutation();

  React.useEffect(() => {
    setIsDirtyForm(!isEmpty(form.formState.dirtyFields));
  }, [fields]);

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  React.useEffect(() => {
    const preferences = applicationSettings?.preferences;

    if (preferences) {
      form.reset(parseJSON(preferences));
    }
  }, [applicationSettings?.preferences]);

  React.useEffect(() => {
    if (isConfirmOpen) {
      onConfirmClose();
    }
  }, [form.formState.errors]);

  const handleCloseSettings = () => {
    setChangeTab(null);

    if (!isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      setIsPreferencesOpen(false);
    }
  };

  const handleChangeTab = (index: number) => {
    setChangeTab(index);

    if (!isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      setCurrentTab(index);
    }
  };

  useImperativeHandle(
    externalRef,
    () => ({
      closeSettings: handleCloseSettings,
      changeTab: handleChangeTab,
    }),
    []
  );

  const onSubmit = form.handleSubmit(async (values) => {
    updateUser({
      variables: {
        input: {
          id: `/api/user-management/api/users/${userId}`,
          preferences: JSON.stringify({
            ...parseJSON(applicationSettings?.preferences),
            ...values,
          }),
        },
      },
    })
      .then(() => {
        clearQueryCache('applicationSettings');
        utils.setDescriptiveAudio(values.descriptiveAudio);
      })
      .catch((err) => {
        handleError({ err, toast } as any);
      });
    localStorage.setItem(
      'voicePreferences',
      JSON.stringify({
        spanishVoice: values.spanishVoice,
        englishVoice: values.englishVoice,
      })
    );
  });

  const closeForm = () => {
    if (form.formState.isDirty || !isEmpty(form.formState.dirtyFields)) {
      onConfirmOpen();
    } else {
      onClose(true);
    }
  };

  const onClose = (shouldCloseForm?: boolean) => {
    if (shouldCloseForm) {
      setIsPreferencesOpen(false);
    } else if (typeof changeTab === 'number') {
      setCurrentTab(changeTab);
    } else {
      setIsPreferencesOpen(false);
    }

    Cookies.set('ReadSpeakerSettings', ttsCookie || '');
    form.reset(parseJSON(applicationSettings?.preferences));
    onConfirmClose();
  };

  return (
    <TabPanel
      as="form"
      width="100% !important"
      p={0}
      noValidate
      onSubmit={onSubmit}
    >
      <FormProvider {...form}>
        <Flex
          direction="column"
          color="primary.800"
          height={
            isIpad() ? 'calc(100vh - 11.25rem)' : 'calc(100dvh - 9.25rem)'
          }
        >
          <Box overflow="auto" flexGrow={1} sx={sxLightScrollBar}>
            <Flex
              py={6}
              justify="space-between"
              pl={{ base: 5, md: 7, lg: '150px' }}
              pr={{ base: 5, md: 7, lg: '125px' }}
              flexDir={{ base: 'column', md: 'row' }}
              borderBottom="1px solid"
              borderColor="secondary.200"
            >
              <VStack
                w={{ base: '100%', lg: '30%' }}
                align="left"
                pt={4}
                pr={6}
              >
                <Text
                  variant="h6"
                  fontWeight={isStudentBelowK3 ? 600 : 400}
                  lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                >
                  {t(
                    'userSettings.accessibility.appearanceSettings'
                  )?.toUpperCase()}
                </Text>
                <Text
                  variant={isStudentBelowK3 ? 's2' : 's3'}
                  color="primary.400"
                >
                  {t('userSettings.accessibility.appearanceSettingsText')}
                </Text>
              </VStack>
              <Flex
                px={6}
                flexDir={{ base: 'column', sm: 'row' }}
                align="flex-start"
                w={{ base: '100%', lg: '70%' }}
                pt={4}
                pb={6}
                boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
              >
                <VStack pr={{ base: 0, sm: 6 }}>
                  <Text
                    variant="n1"
                    pt={{ base: 3, sm: 0 }}
                    fontWeight={600}
                    lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                  >
                    {t('userSettings.accessibility.fontSize')}
                  </Text>
                  <FormRadioGroup
                    name="fontSize"
                    defaultValue={
                      isStudentBelowK3
                        ? FontSizeEnum.large
                        : FontSizeEnum.default
                    }
                  >
                    {fontSizes.map((fontSize, i) => (
                      <Flex
                        key={fontSize.value}
                        direction="column"
                        pt={i ? 2 : 0}
                      >
                        <Radio
                          gap={isStudentBelowK3 ? '6px' : 3}
                          value={fontSize.value}
                        >
                          <Text fontWeight={400}>{t(fontSize.name)}</Text>
                        </Radio>
                      </Flex>
                    ))}
                  </FormRadioGroup>
                </VStack>
                <VStack align="left">
                  <Text
                    variant="n1"
                    pt={{ base: 3, sm: 0 }}
                    fontWeight={600}
                    lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                  >
                    {t('userSettings.accessibility.fontFamily')}
                  </Text>
                  <FormRadioGroup
                    name="fontFamily"
                    defaultValue={
                      isStudentBelowK3
                        ? FontFamilyEnum.report
                        : FontFamilyEnum.verdana
                    }
                  >
                    {getFontFamilies({ isStudentBelowK3 }).map(
                      (fontFamily, i) => (
                        <Flex
                          gap={2}
                          pt={i ? '0.375rem' : 0}
                          alignItems="center"
                          key={fontFamily.value}
                        >
                          <Radio
                            gap={isStudentBelowK3 ? '6px' : 3}
                            value={fontFamily.value}
                          >
                            <Text fontWeight={400}>{t(fontFamily.name)}</Text>
                          </Radio>
                          <Badge variant="adminBadge">
                            {t(fontFamily.label)}
                          </Badge>
                        </Flex>
                      )
                    )}
                  </FormRadioGroup>
                  <Text
                    variant="n1"
                    mt="0.25rem !important"
                    mb="-1px !important"
                    pt={{ base: 3, sm: 0 }}
                    fontWeight={600}
                    lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                  >
                    {t('userSettings.accessibility.theme')}
                  </Text>
                  <FormRadioGroup name="theme" defaultValue="default">
                    {themes.map((theme, i) => (
                      <Flex key={theme.value} direction="column" pt={i ? 2 : 0}>
                        <Radio value={theme.value}>
                          <Text fontWeight={400}>{t(theme.name)}</Text>
                        </Radio>
                      </Flex>
                    ))}
                  </FormRadioGroup>
                </VStack>
              </Flex>
            </Flex>
            {!isStudent && (
              <Flex
                py={6}
                justify="flex-start"
                flexDir={{ base: 'column', md: 'row' }}
                pl={{ base: 5, md: 7, lg: '150px' }}
                pr={{ base: 5, md: 7, lg: '125px' }}
                borderBottom="1px solid"
                borderColor="secondary.200"
              >
                <VStack
                  w={{ base: '100%', lg: '30%' }}
                  align="left"
                  pt={4}
                  pr={6}
                >
                  <Text
                    variant="h6"
                    fontWeight={isStudentBelowK3 ? 600 : 400}
                    lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                  >
                    {t(
                      'userSettings.accessibility.textToSpeechSettings'
                    )?.toUpperCase()}
                  </Text>
                  <Text
                    variant={isStudentBelowK3 ? 's2' : 's3'}
                    color="primary.400"
                  >
                    {t('userSettings.accessibility.textToSpeechSettingsText')}
                  </Text>
                </VStack>
                <Flex
                  w={{ base: '100%', lg: '70%' }}
                  px={6}
                  pt={4}
                  pb={6}
                  boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
                >
                  <VStack align="left">
                    <SpeakingRate
                      isStudentBelowK3={isStudentBelowK3}
                      data={speakingRateTabs}
                      name="speakingRate"
                    />
                    <FormSwitcher
                      label={t('userSettings.accessibility.highlightWords')}
                      name="highlight"
                      fontSize="0.875rem !important"
                      fontWeight="400 !important"
                      lineHeight="0.875rem !important"
                    />
                    <HStack w="100%" alignItems="start">
                      <VStack align="left" pr="3rem">
                        <Text
                          variant="n1"
                          fontWeight={600}
                          lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                        >
                          {t('userSettings.accessibility.englishVoice')}
                        </Text>
                        <FormRadioGroup name="englishVoice">
                          {englishVoices.map((voice, i) => (
                            <Flex
                              key={voice.value}
                              direction="column"
                              pt={i ? 2 : 0}
                            >
                              <Radio value={voice.value}>
                                <Text fontWeight={400}>{t(voice.name)}</Text>
                              </Radio>
                            </Flex>
                          ))}
                        </FormRadioGroup>
                      </VStack>
                      <VStack align="left">
                        <Text
                          variant="n1"
                          fontWeight={600}
                          lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                        >
                          {t('userSettings.accessibility.spanishVoice')}
                        </Text>
                        <FormRadioGroup name="spanishVoice">
                          {spanishVoices.map((voice, i) => (
                            <Flex
                              key={voice.value}
                              direction="column"
                              pt={i ? 2 : 0}
                            >
                              <Radio value={voice.value}>
                                <Text fontWeight={400}>{t(voice.name)}</Text>
                              </Radio>
                            </Flex>
                          ))}
                        </FormRadioGroup>
                      </VStack>
                    </HStack>
                  </VStack>
                </Flex>
              </Flex>
            )}

            {!isAdminOrPlatformAdmin && (
              <Flex
                py={6}
                justify="space-between"
                pl={{ base: 5, md: 7, lg: '150px' }}
                pr={{ base: 5, md: 7, lg: '125px' }}
                flexDir={{ base: 'column', md: 'row' }}
                borderBottom="1px solid"
                borderColor="secondary.200"
              >
                <VStack
                  w={{ base: '100%', lg: '30%' }}
                  align="left"
                  pt={4}
                  pr={6}
                >
                  <Text
                    variant="h6"
                    fontWeight={isStudentBelowK3 ? 600 : 400}
                    lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                    textTransform="uppercase"
                  >
                    {t('userSettings.accessibility.videoSettings')}
                  </Text>
                  <Text
                    variant={isStudentBelowK3 ? 's2' : 's3'}
                    color="primary.400"
                  >
                    {t('userSettings.accessibility.videoSettingsText')}
                  </Text>
                </VStack>
                <Flex
                  px={6}
                  w={{ base: '100%', lg: '70%' }}
                  pt={4}
                  pb={6}
                  boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
                >
                  <VStack
                    pr={{ base: 0, sm: 6 }}
                    justifyContent="space-between"
                    align="start"
                  >
                    <Text
                      variant="n1"
                      mb={1}
                      fontWeight={600}
                      lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                    >
                      {t(
                        'userSettings.accessibility.videoContentPreferredFormat'
                      )}
                    </Text>

                    <Text
                      variant={isStudentBelowK3 ? 's2' : 's3'}
                      color="primary.400"
                      mb={4}
                    >
                      {t(
                        'userSettings.accessibility.activateAudioDescriptionText'
                      )}
                    </Text>
                    <FormSwitcher
                      label={t(
                        'userSettings.accessibility.activateAudioDescription'
                      )}
                      name="descriptiveAudio"
                      fontSize="0.875rem !important"
                      fontWeight="400 !important"
                      lineHeight="0.875rem !important"
                    />
                  </VStack>
                </Flex>
              </Flex>
            )}

            {!isStudent && (
              <>
                <Box pt={6} pl={{ base: 5, md: 7, lg: '150px' }}>
                  <ReadspeakerButton
                    speakingRate={form.getValues().speakingRate}
                    englishVoice={form.getValues().englishVoice}
                    spanishVoice={form.getValues().spanishVoice}
                    highlightWords={form.getValues().highlight}
                  />
                </Box>
                <Flex
                  justify="space-between"
                  flexDir={{ base: 'column', md: 'row' }}
                  pl={{ base: 5, md: 7, lg: '150px' }}
                  pr={{ base: 5, md: 7, lg: '125px' }}
                >
                  <VStack
                    w={{ base: '100%', lg: '30%' }}
                    align="left"
                    pt={{ base: 0, lg: 4 }}
                    pr={6}
                  >
                    <Text
                      variant="h6"
                      fontWeight={isStudentBelowK3 ? 600 : 400}
                      lineHeight={isStudentBelowK3 ? '1.5rem' : '1.625rem'}
                    >
                      {t(
                        'userSettings.accessibility.settingsExample'
                      )?.toUpperCase()}
                    </Text>
                    <Text
                      variant={isStudentBelowK3 ? 's2' : 's3'}
                      color="primary.400"
                    >
                      {t('userSettings.accessibility.settingsExampleText')}
                    </Text>
                  </VStack>
                  <Flex
                    w={{ base: '100%', lg: '70%' }}
                    px={6}
                    pt={4}
                    pb={6}
                    borderRadius={1}
                    boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
                    id="reading-accessibility-example"
                  >
                    <Text
                      variant="n3"
                      mb={1}
                      color="primary.800"
                      fontWeight={
                        fields.fontFamily === 'report' ? '600' : '400'
                      }
                      fontFamily={fontFamilyMapping[fields.fontFamily]}
                      fontSize={
                        (mapping as any)?.[fields.fontFamily]?.[fields.fontSize]
                          ?.fontSize || '1rem'
                      }
                      lineHeight={
                        (mapping as any)?.[fields.fontFamily]?.[fields.fontSize]
                          ?.lineHeight || '1.625rem'
                      }
                    >
                      {t('userSettings.accessibility.example')}
                    </Text>
                  </Flex>
                </Flex>
              </>
            )}
          </Box>
          <DrawerFooter
            h="5.75rem"
            borderTopWidth="1px"
            justifyContent="flex-start"
            py={0}
            flex="none"
          >
            <HStack justify="space-between" width="100%">
              <Button
                leftIcon={<Icon name="arr-left-outlined" />}
                onClick={closeForm}
              >
                {t('userSettings.cancel')}
              </Button>
              <Button
                leftIcon={<Icon name="check-outlined" />}
                variant="solid"
                type="submit"
                isDisabled={loading || isEmpty(form.formState.dirtyFields)}
              >
                {t('userSettings.save')}
              </Button>
            </HStack>
          </DrawerFooter>
        </Flex>
      </FormProvider>
      <ConfirmModal
        primaryHeading={t('userSettings.modal.title')}
        subHeading={t('userSettings.modal.description', {
          entity: 'Accessibility Settings',
        })}
        dontSaveBtnText={t('userSettings.modal.cancel')}
        submitButtonText={t('userSettings.accessibility.apply')}
        hasDontSaveBtn
        isModalOpen={isConfirmOpen}
        handleSave={() => {
          onSubmit();
          onConfirmClose();
          onClose();
        }}
        handleDontSave={onClose}
        handleCancel={onConfirmClose}
        isLoading={loading}
        dontSaveBtnProps={{
          variant: 'solidDark',
          leftIcon: <Icon name="delete-outlined" size="lg" />,
        }}
        submitBtnProps={{
          leftIcon: <Icon name="check-outlined" size="md" />,
        }}
      />
    </TabPanel>
  );
});

export default AccessibilitySettings;
