import { createContext } from 'react';
import { Class } from '@lon/shared/requests';

interface ActionsContextProps {
  classItem: Class | null;
}

export const ActionsContext = createContext<ActionsContextProps>({
  classItem: null,
});
