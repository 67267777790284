import { t } from "i18next";

export const getValidationMessages = (limit = 12) => ({
  required: t('validationMessages.requiredField', {
    fieldName: 'Password',
  }),
  email: t('systemMessages.005'),
  passwordLength: t('systemMessages.007', { limit }),
  wrongLanguage: t('wrongLanguage'),
  noSpaces: t('systemMessages.008'),
  noFirstOrLastName: t('systemMessages.009'),
  atLeastOneLowerCase: t('systemMessages.010'),
  atLeastOneSpecialCharacter: t('systemMessages.011'),
  atLeastOneUpperCase: t('systemMessages.012'),
  atLeastOneDigit: t('systemMessages.014'),
  notMatchingPasswords: t('systemMessages.013'),
});
