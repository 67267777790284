const commonStyle = {
  textDecoration: 'underline',
  display: 'inline-flex',
  alignItems: 'center',
  gap: 2,
  _visited: {
    color: 'info.1000',
    textDecoration: 'underline',
  },
  _hover: {
    color: 'info.700',
    textDecoration: 'underline',
  },
  _focusVisible: {
    borderRadius: '6px',
    border: '1px solid var(--primary-800, #1E4176)',
    boxShadow: '0px 0px 0px 3px #4ECDFE',
  },
};

export const Link = {
  baseStyle: {
    textDecoration: 'none',
    _hover: {
      textDecoration: 'none',
    },
    _focusVisible: {
      boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
    },
  },
  variants: {
    primary: {
      color: 'primary.800',
      ...commonStyle,
    },
    white: {
      color: 'white',
      ...commonStyle,
    },
    navy: {
      textDecoration: 'underline',
      alignItems: 'center',
      gap: 2,
      _hover: {
        color: 'info.400',
        textDecoration: 'underline',
      },
      _active: {
        color: 'info.600',
      },
      _visited: {
        color: '#D5B4FF',
        _hover: {
          color: 'info.400',
          textDecoration: 'underline',
        },
        _active: {
          color: 'info.600',
        },
      },
      _focusVisible: {
        borderRadius: '6px',
        border: '1px solid var(--primary-800, #1E4176)',
        boxShadow: '0px 0px 0px 3px #4ECDFE',
      },
    },
  },

  sizes: {
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
  },
};
