import { Box, ChakraProps } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';
import classes from './Spinner.module.css';

interface SpinnerProps extends ChakraProps {
  variant?: 'light' | 'dark';
  background?: string;
  size?: 'sm';
}

const Spinner: React.FC<SpinnerProps> = ({
  variant = 'light',
  background = 'white',
  size,
  ...props
}) => {
  return (
    <Box
      {...props}
      data-testid="loading-spinner"
      className={clsx(classes.spinner, { [classes.spinnerSm]: size === 'sm' })}
      background={
        variant === 'light'
          ? `conic-gradient(rgba(255, 255, 255, 0), #FFF)`
          : 'conic-gradient(rgba(255, 255, 255, 0), #1E4176)'
      }
      _after={{ backgroundColor: background }}
    />
  );
};

export default Spinner;
