import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GetFeedbacksDocument } from '@lon/shared/requests';
import { LearnosityAnswer } from '@lon/shared/types';
import { Feedback, Props, ScoreItem } from './duck';

const useGetFeedbacksAndScoresByQuestions = ({
  studentAssignmentsData,
}: Props) => {
  const client = useApolloClient();
  const [feedbacksAndScores, setFeedbacksAndScoresData] = useState({
    items: [] as ScoreItem[],
    feedbacks: [] as Feedback[],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    setLoading(true);

    Promise.all(
      studentAssignmentsData.map(({ id }) => {
        if (!id) {
          return undefined;
        }

        return client.query({
          query: GetFeedbacksDocument,
          variables: {
            sessionId: id as string,
          },
          fetchPolicy: 'network-only',
        });
      })
    )
      .then((resp) => {
        if (!mounted) {
          return;
        }
        const feedbacksAndScoresData = resp.reduce(
          (acc, res, index) => {
            const data = res?.data;
            const feedbacks = data?.teacherFeedback?.responseFeedbacks || [];
            const assignment = studentAssignmentsData[index];
            const answers: LearnosityAnswer[] = JSON.parse(
              assignment?.answers || '[]'
            );

            const items: ScoreItem[] = answers.map((answer) => ({
              maxScore: answer.max_score,
              score: answer.score,
              automarkable: answer.automarkable,
              responseId: answer.response_id,
              reportContainerId: `report-${index + 1}`,
            }));

            return {
              items: [...acc.items, ...items],
              feedbacks: [...acc.feedbacks, ...feedbacks],
            };
          },
          {
            items: [] as ScoreItem[],
            feedbacks: [] as Feedback[],
          }
        );

        setFeedbacksAndScoresData(feedbacksAndScoresData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      mounted = false;
    };
  }, [studentAssignmentsData]);

  return { ...feedbacksAndScores, loading };
};

export default useGetFeedbacksAndScoresByQuestions;
