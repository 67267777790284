import { Box, Button, Flex } from '@chakra-ui/react';
import { memo } from 'react';
import { Icon } from '@lon/shared/components';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { useFilterToggle } from '@lon/suit/hooks';
import { FilterButtonProps } from './duck';

const FilterButton: React.FC<FilterButtonProps> = ({
  filterName,
  filterGroup,
}) => {
  const [{ on }, toggle] = useFilterToggle(filterGroup, filterName, 'scopes');
  const theme = useGetTheme();

  return (
    <Button
      w="full"
      title={filterName}
      justifyContent="space-between"
      bgColor={
        on
          ? `${getThemeStyleValue('blue.50', 'teal.500')(theme)}`
          : 'transparent'
      }
      onClick={toggle}
      aria-checked={on}
      role="checkbox"
      {...(on &&
        getThemeStyleValue(
          {},
          {
            border: 'none',
            color: 'secondary.1000',
            _focusVisible: {
              boxShadow: 'focus.teal',
              border: '1px solid var(--chakra-colors-secondary-1000)',
              color: 'secondary.1000',
            },
            _hover: {
              backgroundColor: 'teal.600',
            },
          }
        )(theme))}
    >
      <Box as="span" isTruncated>
        {filterName}
      </Box>
      <Flex
        transform={on ? 'rotate(45deg)' : 'none'}
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="plus-outlined" />
      </Flex>
    </Button>
  );
};

export default memo(FilterButton);
