import { NotFound } from '../errors';
import React, { FC } from 'react';
import { DistrictPermission } from '@lon/shared/enums';
import { AclApplication } from '@lon/shared/gql/types';
import { useAcl } from '@lon/shared/hooks';

interface Props {
  children: React.ReactElement;
  application?: string;
  permission?: DistrictPermission;
  module?: string;
  operation?: string;
}

const ProtectedRoute: FC<Props> = ({
  children,
  application,
  permission,
  module,
  operation,
}) => {
  const hasAccess = useAcl<AclApplication[]>({
    application,
    permission,
    module,
    operation,
  });

  if (!hasAccess) {
    return <NotFound />;
  }

  return children;
};

export default ProtectedRoute;
