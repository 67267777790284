import { ApplicationEnum } from "@lon/shared/enums";
import redirectToApplication from "./redirectToApplication";
import { AclApplication } from "@lon/shared/requests";

type RedirectToAvailableApplication = (
  aclApplications: AclApplication[]
) => void;

const redirectToAvailableApplication: RedirectToAvailableApplication = (
  aclApplications
) => {
  const application = aclApplications?.find(
    (application) => application.name !== ApplicationEnum.MATHNATION_SUIT
  );

  if (!application) {
    return;
  }

  redirectToApplication(application.name as ApplicationEnum);
};


export default redirectToAvailableApplication;
