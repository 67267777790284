import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useResizeObserver(
  ref: React.RefObject<HTMLElement>
): number {
  const [width, setWidth] = React.useState(
    ref.current ? ref.current.clientWidth : 0
  );

  const handleWidth = (): void => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  };

  React.useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(handleWidth);
    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref]);

  return width;
}
