import { FormSelect } from '../../../../../form-units';
import { AssignmentFormContext } from '../../../../duck/context';
import { useContext, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WorkingLocation } from '@lon/shared/contexts';
import { useGetScopesForAssignmentFormQuery } from '@lon/shared/requests';
import { types, utils } from './duck';
import { DigitalNotebookPopover } from './components';

const NotebookLocationSelect: React.FC<types.NotebookLocationSelectProps> = ({
  name,
  classData,
  classLoading,
}) => {
  const { t } = useTranslation();
  const { currentSchoolId } = useContext(WorkingLocation);
  const formContext = useFormContext();
  const { elementsData } = useContext(AssignmentFormContext);
  const elementData = utils.isMultipleElementsAssignment(elementsData)
    ? elementsData?.[0]
    : elementsData;

  const isAppropriateClass =
    classData?.class?.curriculumArea === elementData?.curriculumArea &&
    classData?.class?.gradeOrCourse === elementData?.gradeLevel;

  const { data: scopesData, loading: scopesLoading } =
    useGetScopesForAssignmentFormQuery({
      variables: {
        filter: {
          gradeLevel: [classData?.class?.gradeOrCourse || ''],
          curriculumArea: [classData?.class?.curriculumArea || ''],
        },
        schoolId: currentSchoolId as string,
      },
      skip: !classData || isAppropriateClass,
    });

  const scopesOptions = useMemo(
    () => utils.getScopesOptions({ isAppropriateClass, scopesData, classData }),
    [isAppropriateClass, scopesData, classData]
  );

  useEffect(() => {
    if (isAppropriateClass) {
      formContext.setValue(name, 'appropriate-class', {
        shouldValidate: true,
      });
    }
  }, [isAppropriateClass, name]);

  return (
    <FormSelect
      topRightLabel={<DigitalNotebookPopover />}
      label={t('createAssignmentDrawer.notebookLocationLabel')}
      inputId={name}
      placeholder={t('createAssignmentDrawer.notebookLocationPlaceholder')}
      disabled={!classData || isAppropriateClass}
      isLoading={scopesLoading || classLoading}
      isRequired
      returnObject={false}
      options={scopesOptions}
      name={name}
      formControlProps={{
        marginTop: '4',
      }}
    />
  );
};

export default NotebookLocationSelect;
