import { Box, Spinner, useToast } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from '@lon/shared/hooks';
import {
  AssignmentLearnosityRenderingType,
  AssignmentLearnosityState,
  AssignmentLearnosityType,
  useSignRequestForStudentAssignmentMutation,
} from '@lon/shared/requests';
import {
  replaceImagesBasedOnLanguage,
  replaceVideosBasedOnLanguage,
} from '@lon/shared/utils';
import { QuestionProps } from './duck';

const Question: React.FC<QuestionProps> = ({
  originLearnosityId,
  setOriginalActivityItems,
  originalActivityItems,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [signRequest] = useSignRequestForStudentAssignmentMutation();
  const [params] = useQueryParams();
  const inlineContainer = useRef<HTMLDivElement>(null);
  const currentQuestionNumber = Number(params.questionNumber) || 1;
  const [isLearnosityQuestionLoading, setIsLearnosityQuestionLoading] =
    useState(false);

  useEffect(() => {
    let mounted = true;

    if (originLearnosityId) {
      setIsLearnosityQuestionLoading(true);
      signRequest({
        variables: {
          params: {
            itemSignedRequestInput: {
              referenceId: originLearnosityId,
              renderingType: AssignmentLearnosityRenderingType.Inline,
              type: AssignmentLearnosityType.LocalPractice,
              state: AssignmentLearnosityState.Initial,
            },
          },
        },
      })
        .then((res) => {
          if (!mounted) {
            return;
          }

          const itemsSignRequest = JSON.parse(
            res.data?.signRequest?.signedRequest
          );

          itemsSignRequest?.request?.items.forEach(
            (item: any, index: number) => {
              const isCurrentQuestion = index === currentQuestionNumber - 1;
              const child = document.createElement('span');

              child.dataset.reference = item.reference;
              child.classList.add('learnosity-item');
              if (!isCurrentQuestion) {
                child.classList.add('hidden');
              }

              inlineContainer.current?.appendChild(child);
            }
          );

          window.LearnosityItems.init(itemsSignRequest, {
            readyListener() {
              setIsLearnosityQuestionLoading(false);
              replaceVideosBasedOnLanguage();
              replaceImagesBasedOnLanguage();
            },
            errorListener() {
              toast({
                title: t('systemMessages.038'),
                variant: 'error-light',
                isClosable: true,
              });
              setIsLearnosityQuestionLoading(false);
            },
          });

          setOriginalActivityItems(itemsSignRequest.request.items);
        })
        .catch(() => {
          setIsLearnosityQuestionLoading(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [originLearnosityId]);

  useEffect(() => {
    if (!originalActivityItems.length) {
      return;
    }

    const changeQuestion = () => {
      const learnosityItems = Array.from(
        inlineContainer.current?.querySelectorAll(
          '.learnosity-item'
        ) as NodeListOf<HTMLDivElement>
      );
      learnosityItems.forEach((item, index) => {
        const isHiddenQuestion = item.classList.contains('hidden');
        const isCurrentQuestion = currentQuestionNumber - 1 === index;

        if (!isHiddenQuestion && !isCurrentQuestion) {
          item.classList.add('hidden');
        } else if (isHiddenQuestion && isCurrentQuestion) {
          item.classList.remove('hidden');
        }
      });
    };

    changeQuestion();
  }, [currentQuestionNumber]);

  return (
    <>
      {isLearnosityQuestionLoading && <Spinner />}
      <Box
        ref={inlineContainer}
        className="inline-container"
        mb="5"
        maxW="full"
        display={isLearnosityQuestionLoading ? 'none' : 'block'}
      />
    </>
  );
};

export default Question;
