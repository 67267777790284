import { ApolloError } from "@apollo/client/errors";
import { accessDenied } from "@lon/shared/constants";
import { createStandaloneToast } from "@chakra-ui/react";
import { getErrorMessageByCode } from "./getErrorMessageByCode";

export const errorHandler =
  (cb: any) =>
    (error: ApolloError): void => {
      if (error.message?.toLowerCase().includes(accessDenied)) {
        createStandaloneToast({
          defaultOptions: {
            position: 'bottom',
            variant: 'error-light',
            isClosable: true,
          },
        }).toast({
          title: getErrorMessageByCode('access_denied'),
          variant: 'error-light',
          isClosable: true,
        });
      } else {
        cb(error);
      }
    };
