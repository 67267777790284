import { Box, Flex, Progress, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { Props } from './duck/types';

const ProgressLine: FC<Props> = ({ title, value, variant }) => (
  <Flex
    direction="column"
    gap={2}
    color={useThemeStyleValue('primary.800', 'white')}
  >
    <Flex justify="space-between">
      <Text variant="n1">{title}</Text>
      <Text variant="n1">{value}%</Text>
    </Flex>
    <Box borderRadius="6px" overflow="hidden">
      <Progress variant={variant} value={value} />
    </Box>
  </Flex>
);

export default ProgressLine;
