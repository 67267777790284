export default {
  // <-------------------- Shared -------------------->
  home: '/',
  parentSuitHome: '/resources',
  root: () => '/suite',
  navigation: '/navigation',
  testError: '/test-error',
  previewStudentPlatform: '/preview-student-platform/:classId',

  scopes: {
    root: '/scopes',
    projectScopes: '/scopes/:projectId',
    show: '/scopes/:projectId/:scopeId',
    scopeNav: '/scopes/:projectId/:scopeId/*',
    section: '/scopes/:projectId/:scopeId/:sectionId',
    element: '/scopes/:projectId/:scopeId/:sectionId/:elementId',
    viewAll: '/scopes/:projectId/:scopeId/view-all',
  },
  teacherResources: {
    root: '/teacher-resources',
    resourceSections: '/teacher-resources/:projectId/:resourceId/:sectionId',
    showResourceContent:
      '/teacher-resources/:projectId/:resourceId/:sectionId/:elementId',
  },
  teachers: {
    root: '/teachers',
    editTeacher: '/teachers/edit-teacher/:teacherId',
    studentRoster: '/teachers/:teacherId/students',
    editStudent: '/teachers/:teacherId/students/edit-student/:studentId',
    reports: {
      root: '/teachers/reports/user-reports',
      districtAssessment: '/teachers/reports/district-assessments',
      districtAssessmentDetails: '/teachers/reports/district-assessments/:daId',
    },
    districtAssessmentResult:
      '/teachers/district-assessments/:daId/:studentId/:sessionId',
  },
  classes: {
    root: '/classes',
    create: '/classes/create',
    show: {
      root: '/classes/:classId',
      settings: '/classes/:classId/settings',
      gradebook: '/classes/:classId/gradebook',
      roster: {
        root: '/classes/:classId/roster',
        manageStudents: '/classes/:classId/roster/students-page',
        manageSubgroups: {
          root: '/classes/:classId/roster/manage-subgroups',
          subgroup: '/classes/:classId/roster/manage-subgroups/:subgroupId',
        },
        manageSubgroup: '/classes/:classId/roster/manage-subgroups/:subgroupId',
      },
    },
    assignments: {
      root: '/classes/assignments',
      view: '/classes/assignments/:assignmentId',
      viewList: '/classes/assignments/assignment-list/:listId',
      gradeByQuestion: '/classes/assignments/:assignmentId/grade',
    },
    students: '/classes/students',
    studentAssignments: '/classes/student-assignments/:studentId',
    studentAssignmentDetails:
      '/classes/student-assignments/:assignmentId/:studentId/:sessionId',
    districtAssessmentGrading:
      '/classes/district-assessments/:assignmentId/:studentId/:sessionId',
    reportDashboard: {
      standard: '/classes/report-dashboard/standards',
      districtAssessments: '/classes/report-dashboard/district-assessments',
      districtAssessment:
        '/classes/report-dashboard/district-assessments/:assignmentId',
      districtAssessmentGradeByQuestion:
        '/classes/report-dashboard/district-assessments/:assignmentId/grade',
    },
    createStudent: '/classes/create-student',
    editStudent: '/classes/edit-student/:studentId',
    settings: '/classes/settings',
  },
  planner: {
    root: '/planner',
    pacingGuide: '/planner/pacing-guide',
    selectedPacingGuide: '/planner/pacing-guide/:selectedPacingGuideId',
    plans: '/planner/plans',
    selectedPlan: '/planner/plans/:selectedPlanId',
    demo: '/planner/demo',
    adaptivePlans: '/planner/adaptivePlans',
    selectedAdaptivePlan: '/planner/adaptivePlans/:selectedPlanId',
  },
  student: {
    resources: '/resources',
    playground: '/playground',
    help: {
      root: '/help/:articleId',
      details: ':id',
    },
  },
  parent: {
    scopes: {
      root: '/scopes',
      show: '/scopes/:projectId/:scopeId',
      section: '/scopes/:projectId/:scopeId/:sectionId',
      element: '/scopes/:projectId/:scopeId/:sectionId/:elementId',
      viewAll: '/scopes/:projectId/:scopeId/view-all',
    },
  },
  grades: {
    root: '/grades',
    class: '/grades/:classId',
    assignment: '/grades/:notebookId/scopes/:scopeId/assignments/:assignmentId',
    otherAssignment: '/grades/other-assignments/assignment/:assignmentId',
    districtAssignment:
      '/grades/other-assignments/district-assignment/:assignmentId',
  },
  notebook: {
    root: '/notebook',
    scopes: '/notebook/:notebookId/scopes',
    assignments: '/notebook/:notebookId/scopes/:scopeId/assignments',
    otherAssignments: '/notebook/other-assignments',
    otherAssignment: '/notebook/other-assignments/assignment/:assignmentId',
    districtAssignment:
      '/notebook/other-assignments/district-assignment/:assignmentId',
    assignment:
      '/notebook/:notebookId/scopes/:scopeId/assignments/:assignmentId',
    resource: '/notebook/:notebookId/scopes/:scopeId/resources/:resourceId',
  },
  resources: {
    root: '/resources',
    show: '/resources/:id',
  },
  playground: {
    root: '/playground',
  },
  additionalResources: {
    root: '/more',
    help: {
      root: '/more/help/:articleId',
      details: ':id',
    },
    streaming: {
      root: '/more/streaming',
      show: '/more/streaming/:projectId/:streamId',
    },
  },
  files: {
    root: '/pdf',
    show: '/pdf/:id',
  },
  assessments: {
    root: '/assessments',
    show: '/assessments/:id',
  },
  coding: {
    root: '/coding',
  },
  lti: {
    root: '/lti',
    determineUserType:
      '/lti/determine/:projectId/:scopeId/:sectionId/:elementId',
    previewAssignment: '/lti/preview/:projectId/:scopeId/:sectionId/:elementId',
    studentAssignment: '/lti/class/:classId/assignment/:assignmentId',
    showAssignment: '/lti/assignment/:assignmentId',
    gradeAssignment:
      '/lti/grade-assignment/:studentId/:assignmentId/:sessionId',
    scopes: {
      root: '/lti/scopes',
      show: '/lti/scopes/:projectId/:scopeId',
      activity: '/lti/scopes/:projectId/:scopeId/:sectionId/:elementId',
    },
  },
  googleClassRoom: {
    root: '/google-classroom/class/:classId/assignments/:assignmentId',
  },
};
