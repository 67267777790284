import { useSelector } from 'react-redux';
import { useUpdateUserSelectionMutation } from '@lon/shared/requests';
import * as plannerSelectors from '@lon/shared/selectors';
import { useGetSchoolIdsForPlanner } from './use-get-school-ids-for-planner';

export function useUpdateSelectedPlan() {
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();
  const { teamIds: userTeamIds } = useSelector(plannerSelectors.teams);
  const userRoleCode = useSelector(plannerSelectors.userRoleCode);
  const [updateSelection] = useUpdateUserSelectionMutation();

  const updateSelectedPlan = (planId: string) => {
    updateSelection({
      variables: {
        input: {
          selectedScopePlan: [planId],
          schoolIds: accessibleSchoolIds,
          userRoleCode,
          userTeamIds,
        },
      },
    });
  };
  return [updateSelectedPlan];
}
