import { applicationSortOrder, isDevMode } from '@lon/shared/constants';
import { ApplicationEnum } from '@lon/shared/enums';
import { hashValue } from './hashValue';
import { getLastApplicationBySignature } from './lastApplication';
import redirect from './redirect';

const { protocol, hostname } = window.location;
const envSuitPath =
  process.env['NX_SUIT_URL'] || `${protocol}//${hostname}:4200/suite`;
const envAdminPath =
  process.env['NX_ADMIN_URL'] || `${protocol}//${hostname}:4203/admin`;
const envPlatformAdminPath =
  process.env['NX_PLATFORM_ADMIN_URL'] ||
  `${protocol}//${hostname}:4207/platform-admin`;
const envMathNationPath =
  process.env['NX_MATHNATION_URL'] ||
  `${protocol}//${hostname}:4206/mathnation`;

const redirectToPriorityApplication = async (
  userId: string,
  defaultApplication?: ApplicationEnum
): Promise<void> => {
  const availableSuites: ApplicationEnum[] = JSON.parse(
    localStorage.getItem('availableSuites') || '[]'
  );

  const signature = await hashValue(userId);
  const lastApp: ApplicationEnum = getLastApplicationBySignature(signature);

  const sortedSuits = availableSuites?.sort((a, b) => {
    return applicationSortOrder.indexOf(a) - applicationSortOrder.indexOf(b);
  });

  // @ts-ignore
  const prioritySuite: Exclude<ApplicationEnum, ApplicationEnum.LOGIN> =
    availableSuites.includes(lastApp)
      ? lastApp
      : defaultApplication && availableSuites.includes(defaultApplication)
      ? defaultApplication
      : sortedSuits[0] || ApplicationEnum.TEACHER_SUIT;

  const paths: Record<
    Exclude<ApplicationEnum, ApplicationEnum.LOGIN>,
    string
  > = {
    [ApplicationEnum.PARENT_SUIT]: isDevMode()
      ? `${envSuitPath}/resources`
      : '/suite/resources',
    [ApplicationEnum.STUDENT_SUIT]: isDevMode() ? envSuitPath : '/suite',
    [ApplicationEnum.TEACHER_SUIT]: isDevMode() ? envSuitPath : '/suite',
    [ApplicationEnum.CAMPUS_LEADER_SUIT]: isDevMode() ? envSuitPath : '/suite',
    [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]: isDevMode()
      ? envSuitPath
      : '/suite',
    [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]: isDevMode()
      ? envSuitPath
      : '/suite',
    [ApplicationEnum.ADMIN]: isDevMode() ? envAdminPath : '/admin',
    [ApplicationEnum.PLATFORM_ADMIN]: isDevMode()
      ? envPlatformAdminPath
      : '/platform-admin',
    [ApplicationEnum.MATHNATION_SUIT]: isDevMode()
      ? envMathNationPath
      : '/mathnation',
  };

  const redirectTo = new URLSearchParams(window.location.search).get(
    'redirectTo'
  );

  if (redirectTo) {
    redirect(redirectTo);
  } else {
    redirect(paths[prioritySuite]);
  }
};

export default redirectToPriorityApplication;
