import { Asterisk } from '../asterisk';
import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { types } from './duck';

const RequiredNotification: FC<types.Props> = ({ markColor, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Text aria-hidden="true">
      <Text variant="s3" color="primary.400" as="span" {...rest}>
        {/*TODO: temporary solution until i18n is connected to the storybook*/}
        {t('form.requiredNotification')}
      </Text>
      <Text variant="n2" as="span">
        <Asterisk color={markColor} />
      </Text>
    </Text>
  );
};

export default RequiredNotification;
