import { AssignmentFormContext } from '../../../../../../duck/context';
import { NotebookLocationSelect } from '../../../../components/notebook-location-select';
import { Text } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxList } from '@lon/shared/components';
import { FormSelect } from '@lon/shared/components';
import { useGetClassStudentsForAssignmentFormQuery } from '@lon/shared/requests';
import { types } from './duck';

const IndividualStudentInput: React.FC<types.IndividualStudentInputProps> = ({
  classesOptions,
  classesLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const { withOtherAssignment, isTeacherAssessment } = useContext(
    AssignmentFormContext
  );
  const selectedClasses = useWatch({ name: 'individualStudents' });
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const studentsErrorMessage = get(
    errors,
    `individualStudents.${props.index}.studentIds.message`
  );
  const classId = selectedClasses[props.index]?.classId;
  const { data: classData, loading: classLoading } =
    useGetClassStudentsForAssignmentFormQuery({
      variables: {
        classId,
      },
      skip: !classId,
    });

  const selectedStudents =
    selectedClasses?.[props.index]?.studentIds?.filter(Boolean);
  const students = useMemo(
    () =>
      classData?.class?.classStudents?.collection
        ?.map((student) => ({
          label: `${student?.student.lastName} ${student?.student.firstName}`,
          id: student?.student?._id,
        }))
        .sort((a, b) => {
          const first = a.label;
          const second = b.label;
          // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
          return first.localeCompare(second, 'en', { numeric: true });
        }) || [],
    [classData]
  );

  const options = useMemo(() => {
    const selectedClassesIds = selectedClasses.map(
      ({ classId }: { classId: string }) => classId
    );
    const currentClass = classesOptions.find(({ value }) => value === classId);

    return [
      ...(currentClass ? [currentClass] : []),
      ...classesOptions.filter((cl) => !selectedClassesIds.includes(cl.value)),
    ];
  }, [classesOptions, selectedClasses]);

  const handleChangeIndeterminateCheckbox = (checked: boolean) => {
    setValue(
      `individualStudents.${props.index}.studentIds`,
      checked
        ? students?.map((s) => s?.id || '')
        : new Array(students.length).fill('')
    );
    trigger(`individualStudents.${props.index}.studentIds`);
  };

  const handleChangeCheckboxList = (
    value: number,
    checked: boolean,
    index: number
  ) => {
    setValue(
      `individualStudents.${props.index}.studentIds.${index}`,
      checked ? value : ''
    );
    trigger(`individualStudents.${props.index}.studentIds`);
  };

  const onSelectChange = () => {
    setValue(`individualStudents.${props.index}.studentIds`, []);
  };

  return (
    <>
      <FormSelect
        menuPortalTarget={document.body}
        isInsideDrawer={true}
        display="flex"
        lineHeight={10}
        isLoading={classesLoading}
        isRequired
        returnObject={false}
        options={options}
        noOptionsMessage={() => t('createAssignmentDrawer.noClassesMessage')}
        onChange={onSelectChange}
        {...(props as any)}
        name={`individualStudents.${props.index}.classId`}
      />
      {withOtherAssignment && !isTeacherAssessment && (
        <NotebookLocationSelect
          name={`individualStudents.${props.index}.scopeId`}
          classData={classData}
          classLoading={classLoading}
        />
      )}
      {classId ? (
        <>
          <CheckboxList
            isLoading={classLoading}
            data={students.map((item) => ({ ...item, value: item.id || '' }))}
            selectedCount={selectedStudents.length}
            errorMessage={studentsErrorMessage as any}
            emptyMessage={t('createAssignmentDrawer.studentsEmptyMessage')}
            title={t('createAssignmentDrawer.studentsTitle')}
            name={`individualStudents.${props.index}.studentIds`}
            loadingMessage={t('createAssignmentDrawer.studentsLoadingMessage')}
            mt="6"
            onIndeterminateCheckboxChange={handleChangeIndeterminateCheckbox}
            onChange={handleChangeCheckboxList}
            renderCheckboxLabel={(label) => (
              <Text
                className="notranslate"
                variant="n3"
                ml={-2}
                color="primary.800"
              >
                {label}
              </Text>
            )}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default IndividualStudentInput;
