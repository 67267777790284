import { AssignStudentElementsPropTypes } from '../../duck';
import { ElementRow } from '../element-row';
import { Loader } from '../loader';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  List,
  Text,
  VStack,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AssignStudentElements: React.FC<AssignStudentElementsPropTypes> = ({
  data,
  name,
  title,
  loadingMessage,
  isLoading,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const values = useWatch({ name: name });
  const selectedCount = useMemo(() => values.filter(Boolean).length, [values]);
  const errorMessage = useMemo(
    () => get(formContext.formState.errors, `${name}.message`) || '',
    [formContext.formState.errors, name]
  ) as string;

  const handleChangeIndeterminateCheckbox = (checked: boolean) => {
    formContext.setValue(
      name,
      checked
        ? data?.map((s) => s?.value || '')
        : new Array(data?.length).fill(''),
      { shouldDirty: true }
    );
  };

  const handleChangeCheckbox = (
    value: string,
    checked: boolean,
    index: number
  ) => {
    formContext.setValue(`${name}.${index}`, checked ? value : '', {
      shouldDirty: true,
    });
  };

  const handleSelectUnassigned = () => {
    formContext.setValue(
      name,
      data.map((element) => (element.isAssigned ? '' : element.value)),
      { shouldDirty: true }
    );
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} message={loadingMessage} />;
  }

  return (
    <FormControl isInvalid={!!errorMessage}>
      <Box color="primary.800">
        <Box
          borderRadius="6px"
          border="1px solid"
          borderWidth={!errorMessage ? '1px' : '2px'}
          borderColor={!errorMessage ? '#E1E7F0' : 'danger.600'}
          _last={{
            borderBottomWidth: '2px',
          }}
        >
          <Flex
            align="center"
            borderBottomRadius="0"
            borderTopRadius="inherit"
            bgColor="secondary.50"
            justify="space-between"
            gap={{ base: '2', sm: '4' }}
            px={{ base: '3', sm: '4' }}
            py="2"
          >
            <Flex
              gap={{ base: '2', sm: '4' }}
              color={isDisabled ? 'secondary.400' : 'primary.800'}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Flex gap={{ base: '2', sm: '4' }}>
                <Checkbox
                  mb="0"
                  isInvalid={false}
                  isDisabled={isDisabled}
                  isIndeterminate={
                    selectedCount > 0 && selectedCount < (data?.length || 10)
                  }
                  isChecked={
                    selectedCount === data?.length && data?.length !== 0
                  }
                  onChange={(e) => {
                    handleChangeIndeterminateCheckbox(e.target.checked);
                  }}
                />
                <Text variant="n3">{title}</Text>
              </Flex>
              <Badge
                variant={
                  isDisabled
                    ? 'disabled'
                    : selectedCount === 0
                    ? 'recordsError'
                    : 'recordsInfo'
                }
                textAlign="center"
              >
                {t('checkboxList.selectedOf', {
                  selectedCount,
                  dataLength: data?.length,
                })}
              </Badge>
            </Flex>
            <Flex direction={{ base: 'column', sm: 'row' }}>
              <Text
                variant="n3"
                _before={{
                  content: {
                    base: 'none',
                    sm: `"${t('elementCheckboxList.previously')} "`,
                  },
                }}
                alignSelf="end"
              >
                {t('elementCheckboxList.assigned')}
              </Text>
            </Flex>
          </Flex>
          <Box>
            {data?.length ? (
              <List textAlign="left">
                {data?.map((item, index) => (
                  <ElementRow
                    key={item.value}
                    name={name}
                    item={item}
                    index={index}
                    values={values}
                    isDisabled={isDisabled}
                    handleChangeCheckbox={handleChangeCheckbox}
                  />
                ))}
              </List>
            ) : (
              <Box color="danger.800" textAlign="left">
                <Text variant="n2">{t('elementCheckboxList.note')}</Text>
                <Text variant="n3">{t('elementCheckboxList.noElements')}</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
      <VStack w="full" alignItems="flex-end" my="6" gap="2">
        <Text variant="s2" color="blue.500">
          {t('elementCheckboxList.selectUnassignedInfo')}
        </Text>
        <Button size="sm" variant="outline" onClick={handleSelectUnassigned}>
          <Text as="span" isTruncated>
            {t('elementCheckboxList.selectUnassignedBtn')}
          </Text>
        </Button>
      </VStack>
    </FormControl>
  );
};

export default AssignStudentElements;
