import clsx from 'clsx';
import { InputProps, components } from 'react-select';
import { MultiSelectOption } from '@lon/shared/types';
import styles from './Input.module.css';
const Input = <
  // eslint-disable-next-line
  TOption extends any = MultiSelectOption,
  IsMulti extends boolean = false
>(
  props: JSX.IntrinsicAttributes & InputProps<TOption, IsMulti>
) => {
  return (
    <components.Input
      tabIndex={0}
      className={clsx({
        [styles.wrapper]: !props.isMulti,
      })}
      {...props}
    />
  );
};

export default Input;
