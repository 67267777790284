import { useState } from 'react';
import { AreasTypes } from './types';

export const useAreas = (defaults: AreasTypes) => {
  const [areas, setValues] = useState<AreasTypes>(defaults);

  const resetAreas = () => {
    setValues(defaults);
  };

  const updateAreas = (type: keyof AreasTypes, value: boolean) => {
    setValues((prevState) => ({ ...prevState, [type]: value }));
  };

  return { areas, updateAreas, resetAreas };
};
