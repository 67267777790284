import { ReplacePathname } from './types';

export const replacePathname: ReplacePathname = (pathname) => {
  const supportsHistory = (): boolean => {
    const ua = window.navigator.userAgent;

    if (
      (ua.includes('Android 2.') || ua.includes('Android 4.0')) &&
      ua.includes('Mobile Safari') &&
      !ua.includes('Chrome') &&
      !ua.indexOf('Windows Phone')
    ) {
      return false;
    }

    return window.history && 'replaceState' in window.history;
  };

  if (supportsHistory()) {
    window.history.replaceState({}, '', pathname);
    const event = new PopStateEvent('popstate', { state: pathname });
    dispatchEvent(event);
  } else {
    window.location.href = pathname;
  }
};
