import {
  dateDisplayFormat,
  dateValueFormat,
  defaultEventField,
  parseStringifiedDate,
} from '../../duck';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputsList, ReactDatePicker, TextInput } from '@lon/shared/components';
import { useMediaQuery } from '@lon/shared/hooks';

const GradingHolidaysTab: React.FC<{
  fieldName: string;
  heading: string;
  fieldTitle: string;
  fieldPlaceholder: string;
  initialText: string;
  addFieldLabel: string;
}> = ({
  fieldName,
  heading,
  fieldTitle,
  fieldPlaceholder,
  initialText,
  addFieldLabel,
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const isTablet = useMediaQuery('(max-width: 900px)')[0];

  const renderEventField = useCallback((props: any) => {
    const startDate = watch(`${fieldName}.${props.index}.dateRange.startDate`);
    const endDate = watch(`${fieldName}.${props.index}.dateRange.endDate`);
    return (
      <Flex
        gap={{ base: '4', md: '6' }}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box minW={{ base: 'full', sm: '338px' }}>
          <TextInput
            isRequired
            name={`${fieldName}.${props.index}.name`}
            w="full"
            label={t(fieldTitle, {
              number: props.index + 1,
            })}
            placeholder={t(fieldPlaceholder, {
              number: props.index + 1,
            })}
          />
        </Box>
        <Flex gap="6">
          <ReactDatePicker
            name={`${fieldName}.${props.index}.dateRange.startDate`}
            label={t('calendarTemplate.startDateTitle')}
            placeholder={t('calendarTemplate.datePlaceholder')}
            selectsStart
            isRequired
            selected={parseStringifiedDate(startDate)}
            startDate={parseStringifiedDate(startDate)}
            endDate={parseStringifiedDate(endDate)}
            maxDate={parseStringifiedDate(endDate)}
            dateFormat={dateDisplayFormat}
            valueFormat={dateValueFormat}
          />
          <ReactDatePicker
            name={`${fieldName}.${props.index}.dateRange.endDate`}
            label={t('calendarTemplate.endDateTitle')}
            placeholder={t('calendarTemplate.datePlaceholder')}
            selectsEnd
            isRequired={fieldName === 'gradingPeriods' ? true : false}
            selected={parseStringifiedDate(endDate)}
            startDate={parseStringifiedDate(startDate)}
            endDate={parseStringifiedDate(endDate)}
            minDate={parseStringifiedDate(startDate)}
            dateFormat={dateDisplayFormat}
            valueFormat={dateValueFormat}
          />
        </Flex>
      </Flex>
    );
  }, []);

  return (
    <Flex
      py="4"
      gap="2"
      w="full"
      px={{ base: '4', md: '0' }}
      maxW={{ md: '700px' }}
      flexDirection="column"
    >
      <Text
        variant="n3"
        maxW="700px"
        w="full"
        color="primary.800"
        textTransform="uppercase"
        textAlign="left"
      >
        {t(heading)}
      </Text>
      <InputsList
        name={fieldName}
        defaultValue={defaultEventField}
        mobile={isTablet}
        input={renderEventField}
        addButtonTitle={t(addFieldLabel)}
        renderLabel={(index) =>
          t(fieldTitle, {
            number: index + 1,
          })
        }
        renderPlaceholder={(index) =>
          t(fieldPlaceholder, {
            number: index + 1,
          })
        }
        noFieldMessage={t(initialText)}
        isRemoveButtonDisabled={({ index }) => false}
      />
    </Flex>
  );
};

export default GradingHolidaysTab;
