import { t } from 'i18next';
import { ImmediatelyShareResults } from '@lon/shared/requests';

export const immediatelyShareResultsOptions = [
  {
    value: ImmediatelyShareResults.WithCorrectAnswers,
    label: t('createAssignmentDrawer.withCorrectAnswers'),
  },
  {
    value: ImmediatelyShareResults.WithoutCorrectAnswers,
    label: t('createAssignmentDrawer.withoutCorrectAnswers'),
  },
];

export const calculatorOptions = [
  {
    value: 'fourFunction',
    label: t('createAssignmentDrawer.fourFunction'),
  },
  {
    value: 'scientific',
    label: t('createAssignmentDrawer.scientific'),
  },
  { value: 'grading', label: t('createAssignmentDrawer.grading') },
];

export const assignmentLabelsOptions = [
  { value: 'acceleration', label: 'Acceleration' },
  { value: 'extraCredit', label: 'Extra Credit' },
  { value: 'homework', label: 'Homework' },
  { value: 'intervention', label: 'Intervention' },
  { value: 'testPreparation', label: 'Test Preparation' },
  { value: 'tutorial', label: 'Tutorial' },
];

export const teacherActionsOptions = [
  {
    value: 'viewResults',
    label: t('createAssignmentDrawer.viewResults'),
    description: t('createAssignmentDrawer.viewResultsDescription'),
  },
  {
    value: 'assignmentActions',
    label: t('createAssignmentDrawer.assignmentActions'),
    description: t('createAssignmentDrawer.assignmentActionsDescription'),
  },
  {
    value: 'viewAnalytics',
    label: t('createAssignmentDrawer.viewAnalytics'),
    description: t('createAssignmentDrawer.viewAnalyticsDescription'),
  },
  {
    value: 'manualGrading',
    label: t('createAssignmentDrawer.manualGrading'),
    description: t('createAssignmentDrawer.manualGradingDescription'),
  },
];
