import { useSelector } from 'react-redux';
import { ThemeEnum } from '@lon/shared/enums';
import { parseJSON } from '@lon/shared/utils';

export const useThemeStyleValue = <TDefault = unknown, THighContrast = unknown>(
  defaultValue: TDefault,
  highContrastValue: THighContrast
) => {
  const applicationSettings = useSelector(
    (state: any) => state.applicationSettings
  );
  const currentTheme: ThemeEnum = (
    parseJSON(applicationSettings?.preferences) as any
  )?.theme;

  switch (currentTheme) {
    case ThemeEnum.HIGH_CONTRAST:
      return highContrastValue;
    default:
      return defaultValue;
  }
};
