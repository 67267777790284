import { FontFamilyEnum, FontSizeEnum } from '@lon/shared/constants';
import { addAudioDescriptive, yup } from '@lon/shared/utils';

export const validationSchema = yup.object().shape({
  fontSize: yup.mixed<FontSizeEnum>(),
  fontFamily: yup.mixed<FontFamilyEnum>(),
  theme: yup.string(),
  speakingRate: yup.string(),
  highlight: yup.boolean(),
  englishVoice: yup.string(),
  spanishVoice: yup.string(),
});

export const getValidation = ({
  isStudentBelowK3 = false,
}: {
  isStudentBelowK3: boolean;
}) => {
  return {
    validationSchema,
    defaultValues: validationSchema.cast({
      fontSize: FontSizeEnum.default,
      fontFamily: isStudentBelowK3
        ? FontFamilyEnum.report
        : FontFamilyEnum.verdana,
      theme: 'default',
      speakingRate: 'regular',
      highlight: true,
      englishVoice: 'Sophie',
      spanishVoice: 'Francisco',
    }),
  };
};

export const setDescriptiveAudio = (descriptiveAudio: boolean) => {
  localStorage.setItem('videoSettings', JSON.stringify({ descriptiveAudio }));

  const videoPlayers = (
    document.querySelector('[data-id="content-iframe"]') as HTMLIFrameElement
  )?.contentDocument?.querySelectorAll('.plyr, .lrn_videoplayer.lrn_mp4_video');

  if (videoPlayers?.length) {
    videoPlayers.forEach((videoWrapper) => {
      const video = videoWrapper.querySelector('video');

      if (!video) {
        return;
      }

      if (descriptiveAudio) {
        addAudioDescriptive(video);
      } else {
        const originalUrl = video.dataset.originalUrl;
        if (!originalUrl) {
          return;
        }
        video.src = originalUrl;
      }
    });
  }
};
