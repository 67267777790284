export const wrapper = {
  position: 'relative',
  transition: 'var(--global-transition)',
  boxShadow: 'base',
  borderRadius: 'var(--global-border-radius)',
  backgroundColor: 'white',
  p: '1.375rem',
  pb: '1.375rem',
  h: 'full',
  _hover: {
    transform: 'translateY(calc(-1 * var(--global-grid-size)))',
  },
  _focusWithin: {
    transform: 'translateY(calc(-1 * var(--global-grid-size)))',
  },
};

export const imageWrapper = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '9',
  height: '9',
  flexShrink: '0',
  m: 'calc(-1 * (32px - var(--typography-s1-line-height)) / 2) calc(var(--global-grid-size) * 2) calc(-1 * (32px - var(--typography-s1-line-height)) / 2) 0',
};

export const image = {
  display: 'block',
  width: 'auto',
  height: 'auto',
  maxWidth: '7',
  maxHeight: '7',
};

export const link = {
  color: 'blue.500',
  textDecoration: 'none',
  _hover: {
    textDecoration: 'none',
  },
  _focusVisible: {
    outlineStyle: 'solid',
    outlineColor: 'transparent',
    _before: {
      boxShadow: 'var(--global-outline)',
    },
  },
  _before: {
    content: `''`,
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '1',
    borderRadius: 'var(--global-border-radius)',
  },
};
