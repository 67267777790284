import { State } from './types';

const state: State = {
  items: [],
  activeIndex: 0,
  group: false,
  hoverable: false,
  bordered: false,
  size: 'medium',
  orientation: 'horizontal',
  popup: false,
  depth: 0,
  indent: 16,
  returnFocus: () => {},
};

export default state;
