import { AssignmentFormContext } from '../../../../../../duck/context';
import { NotebookLocationSelect } from '../../../../components/notebook-location-select';
import { get } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxList } from '@lon/shared/components';
import { FormSelect } from '@lon/shared/components';
import { useGetClassSubgroupsForAssignmentFormQuery } from '@lon/shared/requests';
import { types } from './duck';

const SubgroupInput: React.FC<types.SubgroupInputProps> = ({
  classesOptions,
  classesLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const { withOtherAssignment, isTeacherAssessment } = useContext(
    AssignmentFormContext
  );
  const selectedClasses = useWatch({ name: 'subgroups' });
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const subgroupsErrorMessage = get(
    errors,
    `subgroups.${props.index}.subgroupIds.message`
  );
  const classId = selectedClasses?.[props.index]?.classId;
  const { data: classData, loading: classLoading } =
    useGetClassSubgroupsForAssignmentFormQuery({
      variables: {
        classId,
      },
      skip: !classId,
    });

  const selectedSubgroups =
    selectedClasses?.[props.index]?.subgroupIds?.filter(Boolean);
  const subgroups = useMemo(() => {
    return (
      classData?.class?.subgroups?.collection
        ?.map((subgroup) => ({
          label: subgroup?.name || '',
          id: subgroup?._id,
        }))
        .sort((a, b) => {
          const first = a.label;
          const second = b.label;
          // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
          return first.localeCompare(second, 'en', { numeric: true });
        }) || []
    );
  }, [classData]);

  const options = useMemo(() => {
    const selectedClassesIds = selectedClasses.map(
      ({ classId }: { classId: string }) => classId
    );
    const currentClass = classesOptions.find(({ value }) => value === classId);

    return [
      ...(currentClass ? [currentClass] : []),
      ...classesOptions.filter((cl) => !selectedClassesIds.includes(cl.value)),
    ];
  }, [classesOptions, selectedClasses]);

  const handleChangeIndeterminateCheckbox = (checked: boolean) => {
    setValue(
      `subgroups.${props.index}.subgroupIds`,
      checked
        ? subgroups?.map((s) => s?.id || '')
        : new Array(subgroups.length).fill('')
    );
    trigger(`subgroups.${props.index}.subgroupIds`);
  };

  const handleChangeCheckboxList = (
    value: number,
    checked: boolean,
    index: number
  ) => {
    setValue(
      `subgroups.${props.index}.subgroupIds.${index}`,
      checked ? value : ''
    );
    trigger(`subgroups.${props.index}.subgroupIds`);
  };

  return (
    <>
      <FormSelect
        menuPortalTarget={document.body}
        isInsideDrawer={true}
        display="flex"
        lineHeight={10}
        isLoading={classesLoading}
        isRequired
        returnObject={false}
        options={options}
        noOptionsMessage={() => t('createAssignmentDrawer.noClassesMessage')}
        onChange={() => {
          setValue(`subgroups.${props.index}.subgroupIds`, []);
        }}
        {...(props as any)}
        name={`subgroups.${props.index}.classId`}
      />
      {withOtherAssignment && !isTeacherAssessment && (
        <NotebookLocationSelect
          name={`subgroups.${props.index}.scopeId`}
          classData={classData}
          classLoading={classLoading}
        />
      )}
      {classId ? (
        <>
          <CheckboxList
            isLoading={classLoading}
            data={subgroups.map((item) => ({ ...item, value: item.id || '' }))}
            selectedCount={selectedSubgroups.length}
            errorMessage={subgroupsErrorMessage as any}
            emptyMessage={t('createAssignmentDrawer.subgroupsEmptyMessage')}
            title={t('createAssignmentDrawer.subgroupsTitle')}
            name={`subgroups.${props.index}.subgroupIds`}
            loadingMessage={t('createAssignmentDrawer.subgroupsLoadingMessage')}
            mt="6"
            onIndeterminateCheckboxChange={handleChangeIndeterminateCheckbox}
            onChange={handleChangeCheckboxList}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SubgroupInput;
