import { useFetchImage } from '../../../../duck/hooks';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { resizeImage } from '@lon/shared/utils';
import { ListItem as ListItemUI } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { SearchableScope } from '@lon/suit/types';

const ListItem: React.FC<{ scope: SearchableScope }> = ({ scope }) => {
  const imageUrl = useFetchImage(
    resizeImage({ src: scope.image, height: 600, width: 800 }),
    { mode: 'no-cors' }
  );

  return (
    <ListItemUI
      title={scope.displayName}
      link={generatePath(routes.scopes.show, {
        scopeId: scope.id,
        projectId: scope.projectId,
      })}
      image={imageUrl}
      content={scope.metadata?.gradeLevel}
    />
  );
};

export default memo(ListItem);
