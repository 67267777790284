import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import Spinner from '../spinner';

const ApplicationSuitLoader: React.FC<{
  isLoading: boolean;
  loaderTitle: string;
}> = ({ isLoading, loaderTitle }) => {
  return (
    <Modal isOpen={isLoading} onClose={() => {}} isCentered>
      <ModalOverlay bgColor="rgba(255, 255, 255, 0.9)" />
      <ModalContent boxShadow="none" bgColor="transparent">
        <ModalBody display="flex" flexDir="column" alignItems="center">
          <Spinner variant="dark" />
          <Text variant="n3" mt="8">
            {loaderTitle}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ApplicationSuitLoader;
