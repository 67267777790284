import { request } from '../../utils';
import React from 'react';

export type UseInterceptor = (
  type: 'request' | 'response',
  success?: (res: any) => any,
  failure?: (res: any) => any
) => void;

export const useInterceptor: UseInterceptor = (
  type,
  success,
  failure
): void => {
  // Axios uses only positive integers for interceptor ids.
  const interceptorRef = React.useRef(-1);

  request.interceptors[type].eject(interceptorRef.current);
  interceptorRef.current = request.interceptors[type].use(success, failure);

  React.useEffect(
    () => () => {
      (request.interceptors[type] as any).handlers = [];
    },
    []
  );
};
