import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './actions';
import initialState from './state';
import { State } from './types';

type Action = ActionType<typeof actions>;

const requestReducer = createReducer<State, Action>(initialState)
  .handleAction(
    actions.setFirstLoading,
    (state, { payload: firstLoading }) => ({ ...state, firstLoading })
  )
  .handleAction(actions.fetch.request, (state) => ({
    ...state,
    loadingCount: state.loadingCount + 1,
  }))
  .handleAction(
    actions.fetch.success,
    (state, { payload: { response, getData, getMeta } }) => ({
      ...state,
      data: getData(response),
      meta: getMeta(response),
      firstLoading: false,
      loadingCount: Math.max(state.loadingCount - 1, 0),
    })
  )
  .handleAction(
    actions.fetch.failure,
    (state, { payload: { error, getError } }) => ({
      ...state,
      error: getError(error),
      firstLoading: false,
      loadingCount: Math.max(state.loadingCount - 1, 0),
    })
  );

export default requestReducer;
