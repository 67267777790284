import { Button, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon } from '@lon/shared/components';
import { useMediaQuery, useQueryParams } from '@lon/shared/hooks';
import { hideTTS, showTTS } from '@lon/suit/constants';

const Speech: React.FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const isTTSVisible = queryParams.tts === showTTS;
  const title = isTTSVisible
    ? t('toolbar.disableSpeech')
    : t('toolbar.enableSpeech');
  const [isMobile] = useMediaQuery('(max-width: 375px)');

  const setShowTTS = (tts = 'ON', replace = true) =>
    navigate('?' + getQuery({ ...queryParams, tts }), { replace });

  return (
    <Tooltip
      variant="dark"
      label={
        isTTSVisible
          ? t('helpPage.textToSpeechTooltipOff')
          : t('helpPage.textToSpeechTooltip')
      }
      aria-hidden={true}
      placement="bottom"
      hasArrow={false}
    >
      <Button
        aria-label={title}
        /* eslint-disable-next-line jsx-a11y/aria-props */
        aria-description={t('toolbar.ariaSpeechDescription')}
        variant="ghost"
        leftIcon={
          <Icon
            name={isTTSVisible ? 'noSound' : 'sound'}
            width="20px"
            height="20px"
          />
        }
        onClick={() => {
          setShowTTS(isTTSVisible ? hideTTS : showTTS, false);
        }}
        size="md"
        {...(isMobile && {
          px: '10px',
          sx: {
            '.chakra-button__icon': {
              mr: 0,
            },
          },
        })}
        _hover={{
          backgroundColor: 'primary.30',
        }}
      >
        {!isMobile && (
          <Text as="span" isTruncated variant="s2">
            {t('helpPage.textToSpeech')}
          </Text>
        )}
      </Button>
    </Tooltip>
  );
};

export default Speech;
