import { ActionType, createReducer } from 'typesafe-actions';
import { navigateNext } from '@lon/shared/utils';
import * as actions from './actions';
import initialState from './state';
import * as Types from './types';

type Action = ActionType<typeof actions>;

const rootReducer = createReducer<Types.State, Action>(initialState)
  .handleAction(actions.setConfig, (state, { payload: config }) => ({
    ...state,
    ...config,
  }))
  .handleAction(actions.registerItem, (state, { payload: item }) => {
    const activeItemDisabled =
      state.items[state.activeIndex] && state.items[state.activeIndex].disabled;

    return {
      ...state,
      items: [...state.items, item],
      activeIndex:
        (state.activeIndex > item.position || activeItemDisabled) &&
        !item.disabled
          ? item.position
          : state.activeIndex,
    };
  })
  .handleAction(actions.unregisterItem, (state, { payload: id }) => {
    const indexToDelete = state.items.findIndex((item) => item.id === id);

    return {
      ...state,
      items: state.items.filter((item) => item.id !== id),
      activeIndex: state.activeIndex === indexToDelete ? 0 : state.activeIndex,
    };
  })
  .handleAction(
    actions.toggleDisabled,
    (state, { payload: { id, disabled } }) => {
      const updatedIndex = state.items.findIndex((item) => item.id === id);

      if (updatedIndex === -1) {
        return state;
      }

      // eslint-disable-next-line no-param-reassign
      state.items[updatedIndex].disabled = disabled;

      if (disabled && state.activeIndex === updatedIndex) {
        const { index } = navigateNext(state.items, state.activeIndex);
        return { ...state, activeIndex: index };
      }

      return state;
    }
  )
  .handleAction(actions.setReturnFocus, (state, { payload: returnFocus }) => ({
    ...state,
    returnFocus,
  }))
  .handleAction(actions.setActiveIndex, (state, { payload: activeIndex }) => ({
    ...state,
    activeIndex,
  }))
  .handleAction(actions.setGroup, (state, { payload: group }) => ({
    ...state,
    group,
  }));

export default rootReducer;
