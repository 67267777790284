export const resizeMejsVideos = () => {
  const videoWrappers = document.querySelectorAll('.mejs__container');

  [...videoWrappers].forEach((wrapper) => {
    const video = wrapper.querySelector('video');
    const videoLayers = wrapper.querySelector('.mejs__layers');
    const videoControls = wrapper.querySelector('.mejs__controls');

    if (video) {
      const player = (video as any).player;

      if (player && videoLayers && videoControls && wrapper.parentElement) {
        wrapper.parentElement.style.maxWidth = '480px';
        wrapper.parentElement.style.overflowY = 'hidden';
        (videoControls as HTMLElement).style.padding = '0px 6px';

        const containerWidth = wrapper.parentElement.offsetWidth - 8;

        player.setPlayerSize(containerWidth, containerWidth / (16 / 9));

        [...videoLayers.children].forEach((layer) => {
          (layer as HTMLElement).style.width = '100%';
          (layer as HTMLElement).style.height = '100%';
        });
      }
    }
  });
};
