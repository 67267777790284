import { TooltipProps, Tooltip as TooltipUI } from '@chakra-ui/react';
import React from 'react';

const Tooltip: React.FC<TooltipProps> = ({ children, ...rest }) => {
  return (
    <TooltipUI
      hasArrow
      // isOpen
      placement="left"
      size="md"
      bg="white"
      arrowShadowColor="#FFFFFF"
      trigger
      gutter={16}
      shouldWrapChildren
      arrowSize={15}
      {...rest}
    >
      {children}
    </TooltipUI>
  );
};

export default Tooltip;
