import { Box, Flex } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { FixedSizeList as _FixedSizeList } from 'react-window';

const FixedSizeList = _FixedSizeList as any;

const MenuList: FC<any> = (props) => {
  const listRef = useRef(null);
  const [optionSelected, setOptionSelected] = useState(false);

  const setSelected = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      setOptionSelected(true);
    }
  };

  useEffect(() => {
    if (props.selectRef?.current?.controlRef) {
      props.selectRef?.current?.controlRef.addEventListener(
        'keydown',
        setSelected
      );

      return () => {
        props.selectRef?.current?.controlRef.removeEventListener(
          'message',
          setSelected
        );
      };
    }

    return () => {};
  }, [props.selectRef?.current?.controlRef]);

  useEffect(() => {
    if (optionSelected) {
      setOptionSelected(false);
      return;
    }

    if (props?.children?.length) {
      const idx = props.children?.findIndex(
        (option: any) =>
          option?.props?.data?.value === props.focusedOption?.value
      );

      (listRef.current as any)?.scrollToItem(idx);
    }
  }, [props.focusedOption]);

  return (
    <Box
      py={1}
      sx={{
        '& > div': {
          '::-webkit-scrollbar': {
            backgroundColor: 'rgba(30, 65, 118, 0.1)',
            width: '6px',
          },
          '::-webkit-scrollbar-track': {
            width: '6px',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-primary-200)',
            height: '135px',
          },
        },
      }}
    >
      {props.children.length ? (
        <FixedSizeList
          height={
            props.children.length > 7 ? 300 : (props.children.length || 1) * 40
          }
          itemCount={props.children.length}
          itemSize={40}
          width="auto"
          ref={listRef}
        >
          {({ index, style }: any) => {
            const item = props?.children[index]?.props?.data;
            const isFocused = item?.value === props?.focusedOption?.value;

            return (
              <Flex
                style={style}
                cursor="default"
                display="flex"
                direction="row"
                alignItems="center"
                justify="flex-start"
                fontSize="1rem"
                w="full"
                userSelect="none"
                color="primary.800"
                px={3}
                height="40px"
                boxSizing="border-box"
                align="center"
                backgroundColor={isFocused ? 'blue.50' : 'white'}
                _hover={{
                  backgroundColor: 'blue.50',
                }}
                onMouseMove={() => {
                  props.selectRef.current?.setState({ focusedOption: item });
                }}
                onMouseEnter={() => {
                  if (optionSelected) {
                    props.selectRef.current?.setState({ focusedOption: item });
                  }
                }}
                onClick={() => {
                  setOptionSelected(true);
                  props.selectOption(item);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setOptionSelected(true);
                    props.selectOption(item);
                  }
                }}
              >
                {item?.label}
              </Flex>
            );
          }}
        </FixedSizeList>
      ) : (
        <components.MenuList {...props} children={props.children} isMulti />
      )}
    </Box>
  );
};

export default MenuList;
