import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { uniqueId } from 'lodash-es';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeacherPresetsProps } from './duck';
import { Switch } from './components';

const TeacherPresets: React.FC<TeacherPresetsProps> = ({ data }) => {
  const { t } = useTranslation();

  const predefinedFilters = Object.entries(data).map(([key, values]) =>
    values.map((value) => ({ name: key, value }))
  );

  const labelId = useMemo(() => uniqueId('teacher_presets_'), []);

  if (!predefinedFilters.length) {
    return null;
  }

  return (
    <Flex flexDir="column" gap={2} role="group" aria-labelledby={labelId}>
      <Text variant="n1" color="white" id={labelId}>
        {t('scopes.teacherPresets')}
      </Text>
      <UnorderedList
        display="flex"
        flexDir="column"
        gap={4}
        p={4}
        borderRadius="md"
        border="1px solid"
        borderColor="primary.600"
        listStyleType="none"
        marginLeft="0px"
      >
        {predefinedFilters.flat().map((filter) => (
          <ListItem key={`${filter.name}_${filter.value}`}>
            <Switch filterGroup={filter.name} filterName={filter.value} />
          </ListItem>
        ))}
      </UnorderedList>
    </Flex>
  );
};

export default memo(TeacherPresets);
