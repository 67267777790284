import isEmpty from '../isEmpty';
import { Filter, PreparedFilterField, FilterField } from '../types';

type IsPlainObject = (value: any) => boolean;
type FormatFilter = (filter: Filter) => { [key: string]: FilterField };
type GetFilter = (filter: Filter) => PreparedFilterField[];

const isPlainObject: IsPlainObject = (value) => {
  const isObjectLike = typeof value === 'object' && value !== null;

  return (
    isObjectLike && Object.prototype.toString.call(value) === '[object Object]'
  );
};

const formatFilter: FormatFilter = (filter) => {
  const formatedFilter: any = Object.keys(filter).reduce(
    (result, key) =>
      isPlainObject(filter[key])
        ? { ...result, [key]: filter[key] }
        : { ...result, [key]: { value: filter[key] } },
    {}
  );

  return Object.keys(formatedFilter).reduce((result, key) => {
    const filterOperator = formatedFilter[key].operator;
    const shouldConsiderOperator = !filterOperator || filterOperator !== 'null';

    return isEmpty(formatedFilter[key].value) && shouldConsiderOperator
      ? result
      : { ...result, [key]: formatedFilter[key] };
  }, {});
};

const getFilter: GetFilter = (filter) => {
  const formatedFilter = formatFilter(filter);

  return Object.keys(formatedFilter).map((key) => {
    const filterField: PreparedFilterField = {
      value: formatedFilter[key].value,
      field: key,
    };

    if (formatedFilter[key].operator) {
      filterField.operator = formatedFilter[key].operator;
    }

    return filterField;
  });
};

export default getFilter;
