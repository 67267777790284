import Chat from '../../../chat';
import {
  Box,
  Button,
  Icon,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useThemeStyleValue } from '@lon/shared/hooks';
import { ReactComponent as BotIcon } from './bot.svg';

const ChatPopup: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [isDesktop] = useMediaQuery('(min-width: 1280px)');
  const [isTablet] = useMediaQuery('(min-width: 768px)');

  return (
    <Box width="fit-content" right="3%">
      <Box
        width="fit-content"
        bottom="5%"
        right="3%"
        zIndex="5"
        display="flex"
        alignItems="center"
      >
        <Tooltip
          variant="dark"
          aria-hidden={true}
          label={t('footer.perryBotTooltip')}
        >
          <Button
            aria-label={t('footer.perryBotTooltip')}
            onClick={onOpen}
            variant={useThemeStyleValue('ghost', 'outline')}
            boxShadow="0px 5px 20px -2px #2B364612"
            size={!isTablet ? 'md' : 'sm'}
            px={!isTablet ? 1 : 2}
          >
            <Icon
              as={BotIcon}
              height={!isTablet ? '21px !important' : '31px !important'}
              width={!isTablet ? '24px !important' : '34px !important'}
              aria-hidden="true"
            />
            {isTablet && (
              <Text as="span" ml="1.5">
                {isDesktop
                  ? t('footer.perryBot')
                  : isTablet
                  ? t('footer.perryBotShort')
                  : ''}
              </Text>
            )}
          </Button>
        </Tooltip>
      </Box>
      {isOpen && (
        <Box
          position="absolute"
          width="fit-content"
          bottom="4rem"
          right="0"
          zIndex="10"
          mr={2}
        >
          <Chat onClose={onClose} />
        </Box>
      )}
    </Box>
  );
};

export default ChatPopup;
