export const setAnchor = (id: string) => {
  const currentUrl = window.location.href;

  const urlParts = currentUrl.split('#');

  const baseUrl = urlParts[0];

  window.location.href = id ? `${baseUrl}#${id}` : baseUrl;
};

export const simulateHotkeyCombination = (modifierKey: string, key: string) => {
  const keydown = new KeyboardEvent('keydown', {
    bubbles: true,
    cancelable: true,
    key: key,
    code: `Key${key.toUpperCase()}`,
    [`${modifierKey.toLowerCase()}Key`]: true,
  });
  const keyup = new KeyboardEvent('keyup', {
    bubbles: true,
    cancelable: true,
    key: key,
    code: `Key${key.toUpperCase()}`,
    [`${modifierKey.toLowerCase()}Key`]: true,
  });

  document.dispatchEvent(keydown);
  document.dispatchEvent(keyup);
};
