import { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import {
  UserTypeEnum,
  useCheckAssignmentsOnExistenceQuery,
  useGetClassesNamesQuery,
  useGetNotebooksQuery,
} from '@lon/shared/requests';

interface Notebook {
  _id: string;
  id: string;
  name: string;
  schoolId: string;
  curriculumArea?: string | null | undefined;
  gradeOrCourse?: string | null | undefined;
  scopeIds?: string[];
}

const useGetNotebooks = ({
  withOtherAssignments = false,
  studentId = '',
}: {
  withOtherAssignments?: boolean;
  studentId?: string;
} = {}) => {
  const { currentSchoolId: schoolId = '' } = useContext(WorkingLocation);
  const [{ user }] = useAuth();

  const { data: notebooksData, loading: notebooksLoading } =
    useGetNotebooksQuery({
      fetchPolicy: 'cache-and-network',
      variables:
        user?.type === UserTypeEnum.Parent
          ? { schoolId, studentId }
          : { schoolId },
      skip: user?.type === UserTypeEnum.Parent && !studentId,
    });
  const { data: schoolsData, loading: schoolsLoading } =
    useGetClassesNamesQuery({
      fetchPolicy: 'cache-and-network',
      variables:
        user?.type === UserTypeEnum.Parent
          ? { schoolId, studentsId: studentId }
          : { schoolId },
      skip: !schoolId || (user?.type === UserTypeEnum.Parent && !studentId),
    });
  const { data: otherAssignmentsData, loading: otherAssignmentsLoading } =
    useCheckAssignmentsOnExistenceQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          otherAssignment: true,
          ...(user?.type === UserTypeEnum.Parent && studentId
            ? { studentId }
            : {}),
        },
        schoolId,
        studentId: user?.type === UserTypeEnum.Parent ? studentId : user.userId,
      },
      skip:
        !schoolId ||
        !withOtherAssignments ||
        (user?.type === UserTypeEnum.Parent && !studentId),
    });

  const notebooks =
    schoolsData?.classes?.collection?.reduce<Notebook[]>((acc, val) => {
      const notebook = notebooksData?.studentNotebooks?.collection?.find(
        ({ classId }) => classId === val?._id
      );
      if (!!val?._id && !!notebook) {
        return [
          ...acc,
          {
            ...val,
            ...(notebook.scopeIds && { scopeIds: [...notebook.scopeIds] }),
          },
        ];
      }
      return acc;
    }, []) || [];

  const loading = notebooksLoading || schoolsLoading || otherAssignmentsLoading;

  return {
    loading,
    notebooks,
    hasOtherAssignments:
      !!otherAssignmentsData?.studentStudentAssignments?.collection?.filter(
        (item) => !item?.archived
      )?.length ||
      !!otherAssignmentsData?.districtStudentAssignments?.collection?.length,
    values: notebooksData,
  };
};

export default useGetNotebooks;
