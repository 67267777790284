import { useAcl } from '../use-acl';
import useAuth from '../use-auth';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { applicationSortOrder } from '@lon/shared/constants';
import { ApplicationEnum } from '@lon/shared/enums';
import { AclApplication, useGetSchoolsQuery } from '@lon/shared/requests';

export const useGetUserApplications = (props?: { skip: boolean }) => {
  const { skip } = props || {};
  const { t } = useTranslation();
  const [{ user }] = useAuth();
  const aclApplications = useAcl<AclApplication[]>();
  // TODO Temporary solution for MathNation 4.0
  const isAvailableMathNation =
    user?.availableSystems?.includes('MathNation4_0');
  const { loading, data } = useGetSchoolsQuery({
    skip,
    variables: {
      id_list: user?.schoolIds,
    },
  });

  const applications = useMemo(
    () =>
      aclApplications
        ?.reduce<
          {
            value: string;
            label: string;
            application: ApplicationEnum;
          }[]
        >((acc, application) => {
          if (!application.name) {
            return acc;
          }
          const alreadyExist = acc.some(
            (app) => app.application === application?.name
          );

          if (alreadyExist) {
            return acc;
          }

          const hasMathnationInAcl = [ApplicationEnum.MATHNATION_SUIT].includes(
            application?.name as ApplicationEnum
          );

          if (isAvailableMathNation && hasMathnationInAcl) {
            return [
              ...acc,
              {
                value: application.name,
                label: t(`applicationSwitcher.${application.name}`),
                application: application?.name as ApplicationEnum,
              },
            ];
          } else if (!isAvailableMathNation && hasMathnationInAcl) {
            return acc;
          }

          if (
            !isEmpty(application?.schools) &&
            (application?.name === ApplicationEnum.TEACHER_SUIT ||
              application?.name === ApplicationEnum.STUDENT_SUIT)
          ) {
            const prepareSchools = application.schools.reduce<
              {
                label: string;
                value: string;
                application: ApplicationEnum;
              }[]
            >((acc, applicationSchool) => {
              const currentSchools = applicationSchool.schoolIds
                .map((schoolId) => {
                  const currentSchool = data?.schools?.collection?.find(
                    (school) => school?._id === schoolId
                  );

                  return {
                    label: currentSchool?.globalName || '',
                    value: currentSchool?._id || '',
                    application: application?.name as ApplicationEnum,
                  };
                })
                .filter(Boolean);

              return [...acc, ...currentSchools];
            }, []);

            return [...acc, ...prepareSchools];
          }
          return [
            ...acc,
            {
              value: application.name,
              label: t(`applicationSwitcher.${application.name}`),
              application: application?.name as ApplicationEnum,
            },
          ];
        }, [])
        ?.sort((a, b) => {
          return (
            applicationSortOrder.indexOf(a.application) -
            applicationSortOrder.indexOf(b.application)
          );
        }),
    [aclApplications, data, isAvailableMathNation]
  );

  const filterAclApplication = applications.filter(
    (x) =>
      x.application === ApplicationEnum.TEACHER_SUIT ||
      x.application === ApplicationEnum.STUDENT_SUIT
  );

  const uniqueAclApplication = [
    ...new Map(
      filterAclApplication.map((item) => [item['value'], item])
    ).values(),
  ];

  return { applications, uniqueAclApplication, loading };
};
