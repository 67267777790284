import { Button, Collapse, Flex } from '@chakra-ui/react';
import React, { FC, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon';
import { ConditionalWrapper } from '../conditional-wrapper';
import { types } from './duck';

const ShowMore: FC<types.ShowMoreProps> = ({
  children,
  startingHeight = '80px',
  ...rest
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const [show, setShow] = React.useState(false);

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const numberStartingHeight =
      typeof startingHeight === 'string'
        ? parseInt(startingHeight, 10)
        : startingHeight;

    const calculateHidden = () => {
      if (tagContainerRef.current) {
        setHiddenCount(
          Array.prototype.slice
            .call(tagContainerRef.current?.children)
            .filter((item) => {
              return item.offsetTop > numberStartingHeight;
            })?.length || 0
        );
      }
    };

    let prevWidth = 0;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        if (typeof width === 'number' && width !== prevWidth) {
          prevWidth = width;
          calculateHidden();
          if (
            (tagContainerRef.current?.offsetHeight || 0) > numberStartingHeight
          ) {
            setShouldCollapse(true);
          } else {
            setShouldCollapse(false);
          }
        }
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Flex ref={containerRef} direction="column" overflow="hidden" {...rest}>
      <ConditionalWrapper
        condition={shouldCollapse}
        wrapper={(children) => (
          <Collapse startingHeight={startingHeight} in={show}>
            {children}
          </Collapse>
        )}
      >
        <Flex ref={tagContainerRef} gap={2} wrap="wrap" pos="relative">
          {children}
        </Flex>
      </ConditionalWrapper>
      {!!hiddenCount && shouldCollapse && (
        <Button
          mt={show ? '2' : '0'}
          pos="unset"
          w="auto"
          size="xs"
          py="1"
          px="3"
          variant="label"
          leftIcon={
            <Icon
              name={show ? 'eyeInvisible' : 'eyeDdlOutlined'}
              width="0.875rem"
              height="0.875rem"
            />
          }
          color="calendar.yellow.800"
          alignSelf="flex-start"
          onClick={() => {
            setShow((show) => !show);
          }}
        >
          {show
            ? t('assignments.showLess')
            : t('assignments.showMore', { amount: hiddenCount })}
        </Button>
      )}
    </Flex>
  );
};

export default ShowMore;
