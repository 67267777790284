import { FontFamilyEnum, FontSizeEnum } from '@lon/shared/constants';

export const fontSizes = [
  {
    name: 'userSettings.accessibility.small',
    value: FontSizeEnum.small,
  },
  {
    name: 'userSettings.accessibility.default',
    value: FontSizeEnum.default,
  },
  {
    name: 'userSettings.accessibility.medium',
    value: FontSizeEnum.medium,
  },
  {
    name: 'userSettings.accessibility.large',
    value: FontSizeEnum.large,
  },
];

const kindergardenFontFamilies = [
  {
    name: 'userSettings.accessibility.report',
    value: FontFamilyEnum.report,
    label: 'userSettings.accessibility.sansSerif',
  },
  {
    name: 'userSettings.accessibility.timesNewRoman',
    value: FontFamilyEnum.timesNewRoman,
    label: 'userSettings.accessibility.serif',
  },
];

const commonFontFamilies = [
  {
    name: 'userSettings.accessibility.verdana',
    value: FontFamilyEnum.verdana,
    label: 'userSettings.accessibility.sansSerif',
  },
  {
    name: 'userSettings.accessibility.timesNewRoman',
    value: FontFamilyEnum.timesNewRoman,
    label: 'userSettings.accessibility.serif',
  },
];

export const getFontFamilies = ({
  isStudentBelowK3,
}: {
  isStudentBelowK3: boolean;
}) => (isStudentBelowK3 ? kindergardenFontFamilies : commonFontFamilies);

export const themes = [
  {
    name: 'userSettings.accessibility.default',
    value: 'default',
  },
  {
    name: 'userSettings.accessibility.highContrast',
    value: 'highContrast',
  },
];

export const speakingRateTabs = [
  {
    key: 'slow',
    tab: 'userSettings.accessibility.slow',
  },
  {
    key: 'regular',
    tab: 'userSettings.accessibility.regular',
  },
  {
    key: 'fast',
    tab: 'userSettings.accessibility.fast',
  },
];

export const spanishVoices = [
  {
    name: 'Francisco',
    value: 'Francisco',
  },
  {
    name: 'Gloria',
    value: 'Gloria',
  },
  {
    name: 'Pilar',
    value: 'Pilar',
  },
];

export const englishVoices = [
  {
    name: 'Ashley',
    value: 'Ashley',
  },
  {
    name: 'James',
    value: 'James',
  },
  {
    name: 'Julie',
    value: 'Julie',
  },
  {
    name: 'Molly',
    value: 'Molly',
  },
  {
    name: 'Samuel',
    value: 'Samuel',
  },
  {
    name: 'Sophie',
    value: 'Sophie',
  },
];

export const Pilar = 'Pilar';

export const langEnum = {
  english: 'en_us',
  spanish_us: 'es_us',
  spanish: 'es_es',
};
