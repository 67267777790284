import { prodStemDomain } from '@lon/shared/constants';
import { isProdCluster, stemDomain } from '@lon/shared/constants';

export const headerLinks = {
  title: 'Customer Support',
  mobileTitle: 'Support',
  size: 'medium',
  submenu: [
    {
      name: 'GET SUPPORT',
      href: `${isProdCluster() ? prodStemDomain : stemDomain}/tutorials`,
      local: false,
      hidden: false,
    },
    {
      name: 'CONTACT US',
      href: `${stemDomain}/contact`,
      local: false,
      hidden: isProdCluster(),
    },
    {
      name: 'FAQ',
      href: `${stemDomain}/faq`,
      local: false,
      hidden: isProdCluster(),
    },
  ],
};
