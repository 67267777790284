import { Box } from '@chakra-ui/react';
import React from 'react';
import { sxLightScrollBar } from '@lon/shared/constants';
import { StudentContent } from '@lon/suit/components';
import { usePrepareStudentAssignment } from '@lon/suit/hooks';

const StudentAssignment = () => {
  const { contentUrl, learnosityReferenceId } = usePrepareStudentAssignment({
    isLti: true,
  });

  return (
    <Box height="100vh" overflow="auto" sx={sxLightScrollBar}>
      <StudentContent
        contentUrl={contentUrl}
        learnosityReferenceId={learnosityReferenceId}
      />
    </Box>
  );
};

export default StudentAssignment;
