import { CustomTab } from '../../../custom-tab';
import { Icon } from '../../../icon';
import { Message } from '../../../message';
import { RequiredNotification } from '../../../required-notificaton';
import { TABLET_TAB_PANEL_PADDING } from '../../duck/constants';
import { AssignmentFormContext } from '../../duck/context';
import { TabProps } from '../../duck/types';
import { constants as assigneesConstants } from '../manage-assignees/duck';
import {
  Box,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from '@lon/shared/hooks';
import { utils } from './duck';
import { Assignment, Resource } from './tabs';
import { AssignmentTypeConfirmation } from './components';

const GeneralSettings: React.FC<TabProps> = ({
  assignmentTypeTabIndex,
  setAssignmentTypeTabIndex,
  setMainTabIndex,
  assessmentTitle,
  isAssignmentWithoutLearnosity,
}) => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const {
    assessmentData,
    elementsData,
    isAssessment,
    isEditAssignmentMode,
    isReadOnlyMode,
  } = useContext(AssignmentFormContext);
  const [nextTabIndex, setNextTabIndex] = useState<number>(0);
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const [isTablet] = useMediaQuery('(max-width: 1024px)');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const tabs = utils.getTabs();

  const onAssignmentTypeChange = (index: number) => {
    const isClassesSelected = assigneesConstants.TABS.some(
      ({ key }) => formContext.formState.dirtyFields[key]?.length
    );

    if (isClassesSelected) {
      setNextTabIndex(index);
      onConfirmOpen();
    } else {
      setAssignmentTypeTabIndex(index);
    }
  };

  return (
    <>
      <TabPanel p="0" pt="6">
        {!isReadOnlyMode && !isAssignmentWithoutLearnosity && (
          <>
            <Box
              textAlign="left"
              {...(isTablet
                ? { px: TABLET_TAB_PANEL_PADDING }
                : { maxW: '700px' })}
            >
              <RequiredNotification />
            </Box>
            <Divider orientation="horizontal" />
          </>
        )}
        {!Array.isArray(elementsData) && (
          <>
            <Flex
              justify="center"
              {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}
            >
              <Box
                w="full"
                color="primary.800"
                textAlign="left"
                {...(!isTablet && { maxW: '700px' })}
              >
                <Text variant="n1" mb="4">
                  {isAssessment
                    ? t('createAssignmentDrawer.assignedAssessmentTitle')
                    : t('createAssignmentDrawer.scopeElementAssigned')}
                </Text>
                {utils.getTitleLinks({
                  assessmentData,
                  elementsData,
                  assessmentTitle,
                  isAssessment,
                  isEditAssignmentMode,
                })}
                {isAssessment && !isEditAssignmentMode && (
                  <Box mt="4">
                    <Text variant="n1" mb="4">
                      {t('createAssignmentDrawer.assignmentType')}
                    </Text>
                    <Text variant="n3">
                      {t('createAssignmentDrawer.gradeableAssignment')}
                    </Text>
                  </Box>
                )}
              </Box>
            </Flex>
            <Divider orientation="horizontal" />
          </>
        )}
        <Flex
          justify="center"
          pb="6"
          flexDir="column"
          align="center"
          {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}
        >
          {(isEditAssignmentMode ||
            (!isAssessment && !isAssignmentWithoutLearnosity)) && (
            <Flex
              direction="column"
              justify="center"
              w="full"
              gap="4"
              {...(!isTablet && { maxW: '700px' })}
            >
              <Text variant="n1" textAlign="left" color="primary.800">
                {t('createAssignmentDrawer.assignmentType')}
              </Text>
              {isEditAssignmentMode && (
                <Text variant="n3" textAlign="left" color="primary.800">
                  {t('createAssignmentDrawer.gradeableAssignment')}
                </Text>
              )}
            </Flex>
          )}
          {!isEditAssignmentMode &&
          (isAssessment || isAssignmentWithoutLearnosity) ? (
            <>
              <Box
                width="full"
                textAlign="left"
                {...(!isTablet && { w: '700px' })}
              >
                {isAssignmentWithoutLearnosity && (
                  <Flex
                    flexDir="column"
                    gap={4}
                    mb={6}
                    sx={{
                      '.chakra-alert': { py: 6 },
                    }}
                  >
                    <Text variant="n1" color="primary.800">
                      {t('createAssignmentDrawer.assignmentType')}
                    </Text>
                    <Text variant="n3" color="primary.800">
                      {t('createAssignmentDrawer.resource')}
                    </Text>
                    <Message
                      variant="info"
                      icon={<Icon name="lamp" />}
                      css={{
                        '.chakra-alert__desc': { display: 'block', padding: 0 },
                      }}
                      message={
                        <Trans i18nKey="createAssignmentDrawer.resourceAssignmentDescription">
                          <Text as="span" variant="s3" whiteSpace="pre-wrap">
                            0
                          </Text>
                          <Text
                            as="span"
                            variant="s3"
                            cursor="pointer"
                            textDecoration="underline"
                            onClick={() => setMainTabIndex(1)}
                          >
                            1
                          </Text>
                          <Text as="span" variant="s3">
                            2
                          </Text>
                        </Trans>
                      }
                    />
                  </Flex>
                )}
              </Box>
              <Box width="full" textAlign="left">
                {isAssignmentWithoutLearnosity ? <Resource /> : <Assignment />}
              </Box>
            </>
          ) : (
            <Tabs
              variant="drawerInternal"
              textAlign="left"
              index={assignmentTypeTabIndex}
              onChange={onAssignmentTypeChange}
              marginTop={2}
              isFitted
              display="flex"
              flexDir="column"
              alignItems="center"
              w="full"
            >
              {!isEditAssignmentMode && (
                <Flex justify="center" w="full">
                  <Box
                    w="full"
                    display="flex"
                    justifyContent="space-between"
                    {...(!isTablet && {
                      maxW: '700px',
                    })}
                  >
                    {isMobile ? (
                      <VStack spacing="4" alignItems="flex-start">
                        <RadioGroup
                          onChange={(nextValue) => {
                            onAssignmentTypeChange(Number(nextValue));
                          }}
                          value={`${assignmentTypeTabIndex}`}
                        >
                          <Stack
                            direction="column"
                            borderWidth="1px"
                            borderColor="secondary.200"
                            borderStyle="solid"
                            borderRadius="4px"
                            p="4"
                          >
                            {tabs.map(({ tab }, index) => (
                              <Radio key={tab} value={`${index}`} gap={3}>
                                {tab}
                              </Radio>
                            ))}
                          </Stack>
                        </RadioGroup>
                      </VStack>
                    ) : (
                      <>
                        <TabList display="inline-flex">
                          {tabs.map(({ tab }) => (
                            <CustomTab
                              key={tab}
                              flex="0 1 auto"
                              paddingInline={2}
                            >
                              {tab}
                            </CustomTab>
                          ))}
                        </TabList>
                      </>
                    )}
                  </Box>
                </Flex>
              )}
              <TabPanels>
                {tabs.map(({ TabPanel, tab }) => (
                  <TabPanel key={tab} />
                ))}
              </TabPanels>
            </Tabs>
          )}
        </Flex>
      </TabPanel>
      <AssignmentTypeConfirmation
        isOpen={isConfirmOpen}
        onClose={onConfirmClose}
        nextTabIndex={nextTabIndex}
        setAssignmentTypeTabIndex={setAssignmentTypeTabIndex}
      />
    </>
  );
};

export default GeneralSettings;
