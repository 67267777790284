import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export type AxiosRequest = AxiosInstance;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RequestConfig extends AxiosRequestConfig {}

const request: AxiosRequest = axios.create({ withCredentials: true });

export default request;
