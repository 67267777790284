import { useMemo, useState } from 'react';
import { SignAssignmentServices } from '@lon/shared/requests';
import {
  useGetFeedbacksAndScoresByQuestions,
  useRenderReportsLearnosityByQuestions,
} from '@lon/suit/hooks';
import { getDefaultValuesForGradingForm } from '@lon/suit/utils';
import { GradingByQuestionsProps } from './duck';
import { AnswersTemplate, Form } from './components';

const StudentAnswers: React.FC<GradingByQuestionsProps> = ({
  isDa,
  isLearnosityLoading,
  setIsLearnosityLoading,
  studentAssignments,
  originalActivityItems,
  students,
}) => {
  const [isLearnosityCalled, setIsLearnosityCalled] = useState(false);

  const signRequestOptions = useMemo(
    () =>
      studentAssignments.map((sa, index) => ({
        variables: {
          params: {
            reportSignedRequestInput: {
              service: SignAssignmentServices.Reports,
              reports: JSON.stringify([
                {
                  id: `report-${index + 1}`,
                  type: 'session-detail-by-item',
                  user_id: sa.studentId,
                  session_id: sa.id,
                },
              ]),
              studentId: sa.studentId,
              sessionId: sa.id,
            },
          },
        },
      })),
    [studentAssignments]
  );

  useRenderReportsLearnosityByQuestions({
    setIsLearnosityCalled,
    setIsLearnosityLoading,
    signRequestOptions,
    originalActivityItems,
  });

  const {
    loading,
    items: answerItems,
    feedbacks,
  } = useGetFeedbacksAndScoresByQuestions({
    studentAssignmentsData: studentAssignments,
  });

  const defaultValues = getDefaultValuesForGradingForm(answerItems, feedbacks);

  return (
    <>
      <AnswersTemplate
        isLearnosityLoading={isLearnosityLoading}
        studentAssignments={studentAssignments}
        students={students}
      />
      {!loading && !isLearnosityLoading && isLearnosityCalled && (
        <Form
          isDa={isDa}
          defaultValues={defaultValues}
          items={answerItems}
          originalActivityItems={originalActivityItems}
          studentAssignments={studentAssignments}
        />
      )}
    </>
  );
};

export default StudentAnswers;
