import { ActionsContext } from '../../duck/context';
import { Item } from '../item';
import { Text } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { Icon } from '@lon/shared/components';
import { routes } from '@lon/suit/configs';

const ManageStudents: React.FC = () => {
  const { t } = useTranslation();
  const { classItem } = useContext(ActionsContext);

  return (
    <Item
      as={Link}
      label={t('classList.classActions.manageStudentsTooltip')}
      to={generatePath(routes.classes.show.roster.manageStudents, {
        classId: classItem?._id,
      })}
    >
      <Icon name="user-edit" size="lg" />
      <Text as="span" variant="s2">
        {t('classList.classActions.manageStudents')}
      </Text>
    </Item>
  );
};

export default ManageStudents;
