import {
  Box,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CustomTab } from '@lon/shared/components';
import { TeamIndividualTabsType } from './duck/types';

const TeamIndividualTabs: React.FC<TeamIndividualTabsType> = ({
  editDrawerTabType = null,
  defaultDisabledTab = null,
  individualTabPanel,
  teamTabPanel,
  setTeamState,
  selectTypeTitle,
  isLeader = false,
}) => {
  const { t } = useTranslation();

  const isTabDisabled = (type: string) => {
    return (
      Boolean(
        editDrawerTabType &&
          editDrawerTabType.toLowerCase() !== type.toLowerCase()
      ) ||
      Boolean(
        defaultDisabledTab &&
          defaultDisabledTab.toLowerCase() === type.toLowerCase()
      )
    );
  };

  return (
    <Tabs
      isFitted
      variant="drawerInternal"
      w="full"
      defaultIndex={
        isLeader ? 1 : isTabDisabled(t('planner.individual')) ? 1 : 0
      }
      onChange={(index) => (setTeamState ? setTeamState(Boolean(index)) : '')}
    >
      <Box pt="6" px={{ base: 4, md: 8 }} color="primary.800" maxW="340px">
        {selectTypeTitle && (
          <Text variant="n1" align="left" pb="2">
            {t(selectTypeTitle)}
          </Text>
        )}
        <TabList width="fit-content">
          <CustomTab
            isDisabled={isLeader || isTabDisabled(t('planner.individual'))}
            justifyContent="left"
            minWidth="fit-content"
            paddingInline={4}
            overflowY="hidden"
            flex={2}
          >
            {t('planner.individual')}
          </CustomTab>
          <CustomTab
            isDisabled={isTabDisabled(t('planner.team'))}
            justifyContent="left"
            minWidth="fit-content"
            paddingInline={4}
            overflowY="hidden"
            flex={1}
          >
            {t('planner.team')}
          </CustomTab>
        </TabList>
      </Box>

      <TabPanels>
        <TabPanel pt={0}>{individualTabPanel}</TabPanel>
        <TabPanel pt={0}>{teamTabPanel}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default TeamIndividualTabs;
