import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Reports } from '@lon/shared/enums';

const AssignmentNotStarted = ({ type }: { type: string }) => {
  const { t } = useTranslation();

  return (
    <Flex
      background="white"
      boxShadow="pane"
      borderRadius="md"
      flexDirection="column"
    >
      <Flex width="full" minHeight="80px" boxShadow="pane" alignItems="center">
        <Heading color="primary.800" pl={4} as="h5">
          {type === Reports.RESPONSE_ANALYSIS
            ? t('assessmentDetails.noContent.responseAnalysis')
            : t('assessmentDetails.noContent.learningOutcome')}
        </Heading>
      </Flex>
      <Flex
        minHeight="400px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="full"
      >
        <Heading
          whiteSpace="pre-wrap"
          as="h5"
          textAlign="center"
          color="primary.800"
        >
          {t('assessmentDetails.noContent.assessmentNotStartedHeading', {
            type:
              type === Reports.RESPONSE_ANALYSIS
                ? t('assessmentDetails.noContent.responseAnalysisTitle')
                : t('assessmentDetails.noContent.learningOutcomeTitle'),
          })}
        </Heading>
        <Text
          mt={4}
          whiteSpace="pre-wrap"
          variant="n3"
          textAlign="center"
          color="primary.800"
        >
          {t('assessmentDetails.noContent.assessmentNotStartedHeadingDetail')}
        </Text>
      </Flex>
    </Flex>
  );
};

export default AssignmentNotStarted;
