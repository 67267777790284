export const Select = {
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    root: {
      borderColor: 'primary.200',
    },
  },
  sizes: {
    xs: {
      field: {
        height: '22px',
      },
    },
    md: {
      field: {
        height: '44px',
      },
    },
  },
};
