import { useEffect, useRef } from 'react';
import { useUpdateEffectTypes } from './duck';

const useUpdateEffect: useUpdateEffectTypes.UseUpdateEffect = (
  callback,
  dependencies = []
) => {
  const isMountedRef = useRef(true);

  useEffect(() => {
    if (isMountedRef.current) {
      isMountedRef.current = false;
    } else {
      return callback();
    }
  }, dependencies);
};

export default useUpdateEffect;
