import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Icon, Message } from '@lon/shared/components';
import {
  ShareResults,
  useShareAssignmentResultsMutation,
} from '@lon/shared/requests';
import { types } from './duck';

const RevokeAssignmentResultsModal: React.FC<
  types.RevokeAssignmentResultsModalProps
> = ({ onClose, isOpen, isMultiple = false, studentsData, onCompleted }) => {
  const { t } = useTranslation();

  const [fetch, { loading }] = useShareAssignmentResultsMutation();
  const toast = useToast();

  const handleRevoke = () => {
    fetch({
      variables: {
        params: {
          assignmentId: studentsData[0]?.assignmentId,
          studentIds: studentsData.map(({ studentId }) => studentId as string),
          shareResults: ShareResults.Disabled,
        },
      },
      onCompleted: (data) => {
        if (data?.putActivitiesShareResults?.success) {
          toast({
            title: t('assignments.revokeSuccessToastTitle'),
            description: (
              <Text whiteSpace="pre-wrap">
                {isMultiple
                  ? t('assignments.revokeSuccessToastDescriptionMultiple', {
                      amount: studentsData.length,
                    })
                  : sanitize(
                      t('assignments.revokeSuccessToastDescription', {
                        name: `${studentsData[0]?.firstName} ${studentsData[0]?.lastName}`,
                        interpolation: {
                          escapeValue: false,
                        },
                      })
                    )}
              </Text>
            ),
            variant: 'success-light',
            status: 'success',
            isClosable: true,
            duration: 3000,
          });
          onCompleted?.();
          onClose();
        } else {
          toast({
            title: t('systemMessages.038'),
            status: 'error',
            variant: 'error-light',
            isClosable: true,
            duration: null,
          });
        }
      },
      onError: () => {
        toast({
          title: t('systemMessages.038'),
          status: 'error',
          variant: 'error-light',
          isClosable: true,
          duration: null,
        });
      },
    });
  };

  return (
    <Modal
      variant="rounded"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent data-focus-visible-disabled>
        <ModalHeader>
          <Text fontWeight={700}>{t('assignments.revokeResults')}</Text>
          <ModalCloseButton
            w="44px"
            h="44px"
            sx={{
              svg: {
                width: 6,
                height: 6,
              },
            }}
          />
        </ModalHeader>
        <Box boxShadow="0px 2px 7px 0px #2B36461A" py={2} px={8}>
          <Text variant="n1">
            {isMultiple
              ? t('assignments.fromMultipleStudents')
              : sanitize(
                  t('assignments.fromSingleStudent', {
                    name: `${studentsData[0].firstName} ${studentsData[0].lastName}`,
                    interpolation: {
                      escapeValue: false,
                    },
                  })
                )}
          </Text>
        </Box>
        <ModalBody py={8}>
          <Message
            variant="info"
            icon={<Icon name="revoke-results" />}
            css={{
              '.chakra-alert__desc': { display: 'block', padding: 0 },
            }}
            message={
              <Text
                variant="s3"
                whiteSpace="pre-wrap"
                data-testid={
                  isMultiple ? 'multipleRevokeMessage' : 'singleRevokeMessage'
                }
              >
                {isMultiple
                  ? t('assignments.revokeResultsMessageMultiple')
                  : t('assignments.revokeResultsMessageSingle')}
              </Text>
            }
          />
        </ModalBody>
        <ModalFooter>
          <HStack justify="space-between" width="100%">
            <Button
              variant="outline"
              mr={3}
              onClick={onClose}
              isDisabled={loading}
            >
              <Text as="span">{t('assignments.cancelButton')}</Text>
            </Button>
            <Button
              leftIcon={<Icon name="check-simple-outlined" size="lg" />}
              onClick={handleRevoke}
              isLoading={loading}
              variant="solid"
            >
              <Text as="span">{t('assignments.revokeResultsButton')}</Text>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RevokeAssignmentResultsModal;
