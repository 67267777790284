import { ScopeMenu } from '../scope-menu';
import { useDispatch } from 'react-redux';
import { usePlannerTeamsQuery } from '@lon/shared/requests';

/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { setTeamsData } from '@lon/suit/configs';
import { Element } from '@lon/suit/types';

/* eslint-disable @nrwl/nx/enforce-module-boundaries */

const SuitScopeMenuWrapper: React.FC<{ sidebarElements?: Element[] }> = ({
  sidebarElements,
}) => {
  const dispatch = useDispatch();
  const { loading: teamsLoading } = usePlannerTeamsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      itemsPerPage: 300,
      page: 1,
    },
    onCompleted(data) {
      if (data?.teams?.collection?.length) {
        dispatch(setTeamsData(data?.teams?.collection));
      }
    },
  });

  return <ScopeMenu loading={teamsLoading} sidebarElements={sidebarElements} />;
};

export default SuitScopeMenuWrapper;
