import { getIconPathForContentPlayer } from '../../duck';
import { BreadcrumbsContainer } from '../breadcrumbs-container';
import Cookies from 'js-cookie';

/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useCurrentElement } from '@lon/suit/hooks';
import { TransformedElement } from '@lon/suit/types';

/* eslint-disable @nrwl/nx/enforce-module-boundaries */

const ContentPlayerBreadcrumbsWrapper = () => {
  const currentElement = useCurrentElement() as TransformedElement;
  const selectedLanguage = Cookies.get('langPref')?.split('-')[0] || 'en';
  const activityTime = currentElement?.metadata?.activityTime || '';

  const iconPathJson = getIconPathForContentPlayer(activityTime);
  const iconPath = iconPathJson?.languageFilePath?.find(
    (icon) => icon?.languageCode === selectedLanguage
  )?.filePath;
  return <BreadcrumbsContainer activityTimeIconPath={iconPath} />;
};

export default ContentPlayerBreadcrumbsWrapper;
