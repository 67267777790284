import { PreparedRequestParams, RequestParams, Sort } from '../types';

type PrepareParse = (params: PreparedRequestParams) => RequestParams;

const prepareParse: PrepareParse = (params) => {
  const { filter, sort, page, ...parsed } = params;
  const formatted: RequestParams = parsed;

  if (sort) {
    formatted.sort = sort
      .split(',')
      .reduce(
        (result: Sort, item: string) =>
          item.startsWith('-')
            ? ({ ...result, [item.slice(1)]: 'desc' } as Sort)
            : ({ ...result, [item]: 'asc' } as Sort),
        {}
      );
  }

  if (filter) {
    formatted.filter = filter.reduce((result, item) => {
      const { field, ...rest } = item;
      return { ...result, [field]: rest };
    }, {});
  }

  if (page) {
    formatted.page = { size: +page.size, number: +page.number };
  }

  return formatted;
};

export default prepareParse;
