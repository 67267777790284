import React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import { Icon as CustomIcon } from '../../../icon';
import { IconVariantType, MultiSelectOption } from '@lon/shared/types';
import { DynamicIcon } from './DynamicIcon';

interface DropdownIndicator<
  TOption = MultiSelectOption,
  IsMulti extends boolean = false
> extends DropdownIndicatorProps<TOption, IsMulti> {
  iconVariant?: IconVariantType;
}

const DropdownIndicator = <TOption, IsMulti extends boolean>({
  iconVariant,
  ...props
}: DropdownIndicator<TOption, IsMulti>) => {
  return (
    <components.DropdownIndicator {...props}>
      {props?.selectProps?.menuIsOpen ? (
        <CustomIcon name="arrow-up" />
      ) : (
        <DynamicIcon iconVariant={iconVariant} />
      )}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
