import { MenuItem } from '../../menu';
import { StandardsModal } from '../../standards-modal';
import React from 'react';
import { standardsTypes } from './duck';
import { Trigger } from './components';

const Standards: React.FC<standardsTypes.StandardsProps> = ({
  standards,
  isResponsive,
  ...rest
}) => {
  return (
    <MenuItem {...rest}>
      <StandardsModal
        standards={standards}
        trigger={<Trigger isResponsive={isResponsive} />}
      />
    </MenuItem>
  );
};

export default Standards;
