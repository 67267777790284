import { PasswordInput, TextInput } from '../../form-units';
import { Icon } from '../../icon';
import { Message } from '../../message';
import Modal from '../../modal/Modal';
import { Button, Flex, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import {
  logout,
  redirectToPriorityApplication,
  refetchFallenQueries,
} from '@lon/shared/utils';
import { loginTypes, loginUtils } from './duck';
import { SuccessCallback } from './duck/types';

const SessionExpired = () => {
  const { t } = useTranslation();
  const [, setAuth] = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [isMfaRequired, setMfaRequired] = useState(false);
  const { application: currentApplication } = useContext(WorkingLocation);
  const { validationSchema, defaultValues } =
    loginUtils.getValidation(isMfaRequired);
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const [mfaCode, username, password] = formProviderProps.watch([
    'mfaCode',
    'username',
    'password',
  ]);

  const [errorMessage, setErrorMessage] =
    React.useState<loginTypes.ErrorObject | null>();

  useEffect(() => {
    setErrorMessage(null);
  }, [mfaCode, username, password]);

  const successCallback: SuccessCallback = ({ wasImpersonated, userId }) => {
    if (wasImpersonated) {
      redirectToPriorityApplication(userId);
    } else {
      refetchFallenQueries();
      navigate(0);
    }
  };

  const navigateToLogin = async () => {
    try {
      await axios.post(`${process.env.NX_API_URL}/login/papi/logout`);
      logout();
    } catch (error) {
      toast({
        title: t('systemMessages.038'),
        status: 'error',
        isClosable: true,
        position: 'top',
        duration: 3000,
      });
    }
  };

  return (
    <Modal
      data-testid="session-expired-modal"
      withoutCloseButton
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <Modal.Header>{t('sessionExpiredModal.header')}</Modal.Header>
      <FormProvider {...formProviderProps}>
        <Flex
          as="form"
          data-testid="login-form"
          noValidate
          onSubmit={formProviderProps.handleSubmit(
            loginUtils.handleLoginSubmit(
              successCallback,
              setErrorMessage,
              setMfaRequired,
              isMfaRequired,
              setAuth,
              currentApplication
            )
          )}
          display="flex"
          flexDirection="column"
        >
          <Modal.Body>
            <Message
              mb="4"
              variant="info"
              icon={
                <Icon
                  name={isMfaRequired ? 'key-detailed' : 'clock-detailed'}
                />
              }
              title={t(
                `sessionExpiredModal.${
                  isMfaRequired ? 'mfaHeader' : 'contentHeader'
                }`
              )}
              message={t(
                `sessionExpiredModal.${
                  isMfaRequired ? 'mfaDescription' : 'description'
                }`
              )}
            />
            {isMfaRequired ? (
              <TextInput
                isRequired
                name="mfaCode"
                label={t('sessionExpiredModal.codeLabel')}
                placeholder={t('sessionExpiredModal.codePlaceholder')}
              />
            ) : (
              <>
                <TextInput
                  inputLayoutProps={{ mb: '4' }}
                  isRequired
                  data-testid="username"
                  name="username"
                  label={t('sessionExpiredModal.userNameLabel')}
                  placeholder={t('sessionExpiredModal.userNamePlaceholder')}
                />
                <PasswordInput
                  isRequired
                  data-testid="password"
                  name="password"
                  label={t('sessionExpiredModal.passwordLabel')}
                  placeholder={t('sessionExpiredModal.passwordPlaceholder')}
                />
              </>
            )}

            {errorMessage && (
              <Message
                mt="6"
                variant="error"
                icon={<Icon name="key-detailed" />}
                title={errorMessage.title}
                message={errorMessage.message}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Flex
              w="100%"
              flexDir="row"
              justify="space-between"
              alignItems="center"
            >
              {currentApplication !== ApplicationEnum.PLATFORM_ADMIN && (
                <Button variant="outline" onClick={navigateToLogin}>
                  {t('sessionExpiredModal.returnToLogin')}
                </Button>
              )}
              <Button
                variant="solid"
                data-testid="submit"
                isLoading={formProviderProps.formState.isSubmitting}
                type="submit"
                isDisabled={formProviderProps.formState.isSubmitting}
              >
                {t('sessionExpiredModal.submit')}
              </Button>
            </Flex>
          </Modal.Footer>
        </Flex>
      </FormProvider>
    </Modal>
  );
};

export default SessionExpired;
