import { ContentLoader } from '../../../content-loader';
import React from 'react';

const Skeleton: React.FC = () => (
  <div>
    <ContentLoader viewBox="0 0 970 400" data-testid="element-content-skeleton">
      <rect x="0" y="30" width="970" height="100" />
      <rect x="0" y="150" width="970" height="100" />
      <rect x="0" y="270" width="970" height="100" />
    </ContentLoader>
  </div>
);

export default Skeleton;
