import { ThemeEnum } from '@lon/shared/enums';
import { getThemeStyleValue } from '@lon/shared/utils';

export const getRadio = (theme: ThemeEnum) => {
  return {
    baseStyle: {
      control: {
        borderWidth: '1px',
        borderColor: getThemeStyleValue('primary.800', 'white')(theme),
        _checked: {
          background: 'none',
          _before: {
            background: getThemeStyleValue('primary.800', 'white')(theme),
            width: '8px',
            height: '8px',
          },
        },
        _disabled: {
          borderColor: 'secondary.400',
          background: 'none',
          _checked: {
            _before: {
              background: 'secondary.400',
            },
          },
        },
        _focusVisible: {
          outline: 'none',
          borderColor: 'primary.500',
          boxShadow: '0px 0px 0px 4px #BFEFFF',
        },
        '&[data-checked]': {
          borderColor: getThemeStyleValue(
            'primary.800',
            'secondary.1000'
          )(theme),
          _before: {
            background: getThemeStyleValue(
              'primary.800',
              'secondary.1000'
            )(theme),
          },
        },
        '&.chakra-radio__control[data-disabled-stable="true"]': {
          bg: 'secondary.100',
          borderColor: 'primary.100',

          '&:before': {
            background: 'primary.800',
          },

          '& + .chakra-radio__label': {
            color: 'primary.800',
            '&:hover': {
              color: 'primary.800',
            },

            '&[data-disabled]': {
              color: 'primary.800',
            },
          },
        },
      },
      container: {
        gap: 4,
        '&.chakra-radio': {
          mb: 0,
        },
        '& :focus:not(.focus-visible), & :focus:not(.focus-visible) + [data-focus]':
          {
            outline: 'none',
            boxShadow: 'none',
          },

        '& .chakra-radio__label': {
          marginInlineStart: 0,
        },

        '&:has(> .chakra-radio__control[data-disabled-stable="true"])': {
          cursor: 'default',
        },

        _hover: {
          color: 'primary.500',
          '.chakra-radio__control:not([data-disabled-stable="true"])': {
            borderColor: getThemeStyleValue(
              'primary.500',
              'secondary.500'
            )(theme),
            _checked: {
              background: 'none',
              borderColor: 'primary.500',
              _before: {
                background: 'primary.500',
              },
            },
            _disabled: {
              borderColor: 'secondary.400',
              _checked: {
                _before: {
                  background: 'secondary.400',
                },
              },
            },
          },
          '& .chakra-radio__label': {
            _disabled: {
              color: 'secondary.400',
            },
          },
        },
      },
      label: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        _disabled: {
          color: 'secondary.400',
          opacity: '1',
        },
      },
    },
  };
};
