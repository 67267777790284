import { Asterisk } from '../../asterisk';
import { Icon } from '../../icon';
import { Input } from '../../inputs/select/components';
import DropdownIndicator from '../../inputs/select/components/DropdownIndicator';
import { styles } from '../../inputs/select/duck';
import { Flex, FormControl, FormErrorMessage, FormLabel, IconButton, Tooltip } from '@chakra-ui/react';
import { get } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { components } from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import { MultiSelectOption } from '@lon/shared/types';
import { AsyncSelectProps } from './duck/types';


const AsyncSelect = <
  // eslint-disable-next-line
  TOption extends any = MultiSelectOption,
  IsMulti extends boolean = false
>({
  backspaceRemovesValue = true,
  isDisabled,
  name,
  label,
  isRequired,
  isMulti,
  menuPlacement = 'auto',
  testId,
  defaultValue,
  formControlProps,
  formLabelProps,
  topRightLabel,
  isDisabledStable,
  showClearIndicator = false,
  variant = 'base',
  iconVariant,
  withoutDropdownIndicator,
  tooltipEntity,
  ...rest
}: AsyncSelectProps<TOption, IsMulti>): React.ReactElement => {
  const { control } = useFormContext();
  const {
    field: { onBlur, onChange, name: fieldName, value, ref },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue,
  });
  const errorMessage = get(errors, `${fieldName}.message`) as unknown as string;

  const customStyles = isDisabledStable
    ? styles.disabledStableCustomStyles
    : styles.getCustomStyles({
        disabled: isDisabled,
        errorMessage,
        variant,
        showClearIndicator,
      });

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={!!errorMessage}
      isRequired={isRequired}
      data-testid={testId}
      {...formControlProps}
    >
      <Flex justifyContent="space-between" direction="row" alignItems="center">
        {label && (
          <FormLabel
            htmlFor={name}
            requiredIndicator={<Asterisk />}
            {...formLabelProps}
          >
            {label}
          </FormLabel>
        )}
        {topRightLabel && topRightLabel}
      </Flex>
      <ReactAsyncSelect
        ref={ref}
        defaultValue={defaultValue}
        backspaceRemovesValue={backspaceRemovesValue}
        inputId={name}
        menuPlacement={menuPlacement}
        styles={customStyles}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        components={{
          DropdownIndicator: withoutDropdownIndicator
            ? null
            : (props) => (
                <DropdownIndicator {...props} iconVariant={iconVariant} />
              ),
          Input,
          MultiValueRemove: (props) => {
            return (
              <Tooltip
                label={
                  tooltipEntity
                    ? `Remove ${props.data.label} from the ${tooltipEntity}`
                    : `Remove ${props.data.label}`
                }
                color="white"
                placement="bottom"
                py={2}
                px={4}
                fontSize="0.875rem"
                lineHeight="1.675rem"
                fontWeight="400"
                borderRadius="4px"
                background="#1A2C49 !important"
              >
                <Flex alignItems="center">
                  <components.MultiValueRemove {...props}>
                    <IconButton
                      size="xs"
                      bg="red"
                      aria-label="delete"
                      icon={<Icon name="deleteCircleOutlined" />}
                      variant="round"
                      minW="unset"
                      height="unset"
                      background="unset"
                      backgroundColor="unset"
                      _hover={{ backgroundColor: 'unset' }}
                    />
                  </components.MultiValueRemove>
                </Flex>
              </Tooltip>
            );
          },
          ClearIndicator: (props) => {
            const { innerProps, getStyles } = props;

            return (
              <IconButton
                {...(innerProps as any)}
                style={getStyles('clearIndicator', props)}
                size="sm"
                aria-label="Clear"
                icon={<Icon name="close-outlined" />}
                variant="ghost"
              />
            );
          },
        }}
        {...rest}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default AsyncSelect;
