import { CSSObjectWithLabel, ControlProps, OptionProps } from 'react-select';
import { colors, shadows } from '@lon/shared/assets';
import { GetCustomStyles } from './types';

export const disabledStableCustomStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    minHeight: '44px',
    padding: '2px 4px',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: colors.primary['800'],
    paddingLeft: '0',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  loadingIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingLeft: '0',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: colors.primary['800'],
    fontSize: '1rem',
    textAlign: 'left' as const,
    marginLeft: '0',
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: colors.blue[150],
    padding: '0 10px',
    borderRadius: '4px',
    margin: '0 8px 0 0',
    height: '32px',
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: '1rem',
    color: colors.primary['800'],
    paddingLeft: '0',
  }),
  multiValueRemove: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
};

export const getCustomStyles: GetCustomStyles = ({
  disabled,
  errorMessage,
  variant,
  showClearIndicator,
  selectSizes,
  size,
  isInsideDrawer,
}) => ({
  control: (provided: CSSObjectWithLabel, state: ControlProps) => {
    return {
      ...provided,
      boxShadow:
        state.isFocused && variant === 'dark'
          ? '0 0 0 2px var(--chakra-colors-blue-600)'
          : state.isFocused
          ? shadows.focus.blue
          : 'none',
      minHeight: selectSizes?.minHeight || '44px',
      ...(selectSizes?.height && { height: selectSizes.height }),
      borderColor: disabled
        ? colors.secondary['300']
        : errorMessage && variant === 'dark'
        ? colors.orange['400']
        : errorMessage && variant !== 'dark'
        ? colors.danger['600']
        : state.isFocused
        ? colors.blue['100']
        : colors.primary['200'],
      borderWidth: errorMessage ? '2px' : '1px',
      borderRadius: '4px',
      padding: selectSizes?.padding || '2px 4px',
      backgroundColor: disabled ? colors.secondary['100'] : 'transparent',
      pointerEvents: disabled ? 'none' : 'auto',

      '&:hover': {
        borderColor:
          errorMessage && variant === 'dark'
            ? colors.orange['400']
            : errorMessage && variant !== 'dark'
            ? colors.danger['600']
            : colors.blue['100'],
      },
      ...(size === 's' ? { padding: '0 6px', minHeight: '32px' } : {}),
    };
  },
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    ...(size === 's' ? { height: '30px', padding: '0 6px' } : {}),
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    ...(size === 's' ? { margin: 0 } : {}),
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginTop: '0px',
    marginBottom: '0px',
    zIndex: 3,
  }),
  menuPortal: (provided: CSSObjectWithLabel) => ({
    ...provided,
    zIndex: isInsideDrawer ? 9999 : 20,
  }),
  option: (provided: CSSObjectWithLabel, state: OptionProps) => ({
    ...provided,
    fontSize: selectSizes?.fontSize || '1rem',
    textAlign: 'start',
    color: state.isDisabled ? colors.gray['100'] : colors.primary['800'],
    backgroundColor: state.isDisabled
      ? colors.secondary['100']
      : state.isSelected
      ? colors.blue['150']
      : state.isFocused
      ? colors.blue['50']
      : 'transparent',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    '&:hover': {
      backgroundColor: state.isDisabled
        ? colors.secondary['100']
        : state.isSelected
        ? colors.blue['150']
        : colors.blue['50'],
    },
    ...(size === 's' ? { fontSize: '0.875rem' } : {}),
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: showClearIndicator ? 'flex' : 'none',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: disabled ? colors.blue['400'] : colors.primary['800'],
    fontSize: selectSizes?.fontSize || '1rem',
    textAlign: 'left' as const,
    ...(size === 's' ? { fontSize: '0.875rem' } : {}),
  }),
  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderRadius: '0.75rem',
    paddingTop: '2.5px',
    paddingBottom: '2.5px',
    borderWidth: '1px',
    borderColor: 'transparent',
    alignItems: 'center',
    backgroundColor: colors.primary['30'],

    '&:hover': {
      cursor: 'pointer',
      borderWidth: '1px',
      borderColor: colors.primary['700'],
      backgroundColor: 'white',
    },
  }),
  multiValueLabel: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: selectSizes?.fontSize || '0.875rem',
    lineHeight: '1rem',
    padding: 'unset',
    paddingLeft: '0.75rem',
    color: colors.primary['700'],
    fontWeight: '400',
    ...(size === 's' ? { fontSize: '0.875rem' } : {}),
  }),
  multiValueRemove: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: 'white',
    borderRadius: '50%',
    marginRight: '5px',
    marginLeft: '6px',
    padding: 'unset',
    height: '100%',
    svg: {
      width: '14px',
      height: '14px',
    },
    ':hover': {
      color: 'white',
    },
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: selectSizes?.fontSize || '1rem',
    color: colors.primary['400'],
    textAlign: 'left' as const,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(size === 's' ? { fontSize: '0.875rem' } : {}),
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    '::-webkit-scrollbar': {
      backgroundColor: 'rgba(30, 65, 118, 0.1)',
      width: '6px',
    },
    '::-webkit-scrollbar-track': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--chakra-colors-primary-200)',
      height: '135px',
    },
  }),
});
