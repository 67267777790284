import { Tag, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { colors } from '@lon/shared/assets';
import { Icon } from '../icon';
import { StudentAssignmentAllStatusTransformed } from '@lon/shared/enums';


type Props = {
  status: StudentAssignmentAllStatusTransformed;
  late: boolean;
  endDate: string;
  submittedAt?: string | null;
};

const StudentDashboardAssignmentDate: FC<Props> = ({
  status,
  late = false,
  endDate,
}) => {
  if (late) {
    return (
      <Tag
        variant="status"
        backgroundColor="var(--chakra-colors-white)"
        color={colors.warning[400]}
        borderWidth="1px"
        borderColor={colors.warning[400]}
        px={2}
      >
        <Icon name="hourGlass" size="sm" />
        <Text ml={2} variant="tag">
          {endDate}
        </Text>
      </Tag>
    );
  }

  switch (true) {
    case status === StudentAssignmentAllStatusTransformed.Assigned:
      return (
        <Tag
          variant="status"
          color="primary.800"
          borderWidth="1px"
          borderColor="primary.800"
          backgroundColor="var(--chakra-colors-white)"
          px={2}
        >
          <Text variant="tag">{endDate}</Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Started:
      return (
        <Tag
          variant="status"
          color="calendar.violet.1"
          borderWidth="1px"
          borderColor="calendar.violet.1"
          backgroundColor="var(--chakra-colors-white)"
          px={2}
        >
          <Text variant="tag">{endDate}</Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Returned:
      return (
        <Tag
          variant="status"
          color={colors.yellow[800]}
          borderWidth="1px"
          borderColor={colors.yellow[800]}
          backgroundColor="var(--chakra-colors-white)"
          px={2}
        >
          <Text variant="tag">{endDate}</Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Submitted:
      return (
        <Tag
          variant="status"
          color={colors.calendar.blue[800]}
          borderWidth="1px"
          borderColor={colors.calendar.blue[800]}
          backgroundColor="var(--chakra-colors-white)"
          px={2}
        >
          <Text variant="tag">{endDate}</Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Archived:
      return (
        <Tag
          variant="status"
          color={colors.secondary[800]}
          borderWidth="1px"
          borderColor={colors.secondary[800]}
          backgroundColor="var(--chakra-colors-white)"
          px={2}
        >
          <Text variant="tag">{endDate}</Text>
        </Tag>
      );

    default:
      return null;
  }
};

export default StudentDashboardAssignmentDate;
