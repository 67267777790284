import React, { useEffect, useRef } from 'react';

const GoogleTranslate: React.FC = () => {
  const embedded = useRef(false);

  useEffect(() => {
    if (embedded.current) return;

    const source =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

    if (!document.querySelector(`script[src="${source}"]`)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = source;
      document.body.appendChild(script);

      script.onload = () => {
        setTimeout(() => {
          function googleTranslateElementInit() {
            if ((window as any).google.translate.TranslateElement) {
              embedded.current = true;
              new (window as any).google.translate.TranslateElement(
                { pageLanguage: 'fr', autoDisplay: false },
                'google_translate_element'
              );
            }
          }

          (window as any).googleTranslateElementInit =
            googleTranslateElementInit();
        }, 250);
      };
    }
  }, []);

  return <div id="google_translate_element" />;
};

export default GoogleTranslate;
