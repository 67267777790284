import { useContext, useEffect, useState } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';
import {
  Class,
  ParentStudent,
  useGetParentStudentsQuery,
} from '@lon/shared/requests';

interface CurrentStudent extends ParentStudent {
  classes?: Class[];
}

type UseGetParentStudents = () => {
  loading: boolean;
  students: ParentStudent[];
  currentStudent: CurrentStudent | null;
  changeStudent: (value: string) => void;
};

const useGetParentStudents: UseGetParentStudents = () => {
  const [currentStudentId, setCurrentStudentId] = useState<string>('');
  const { application } = useContext(WorkingLocation);
  const isParentSuite = application === ApplicationEnum.PARENT_SUIT;

  const changeStudent = (studentId: string) => {
    setCurrentStudentId(studentId);
    localStorage.setItem('selectedStudent', studentId);
    window.location.href = '/suite/resources';
  };

  const { loading, data: studentsData } = useGetParentStudentsQuery({
    skip: !isParentSuite,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const savedStudent = localStorage.getItem('selectedStudent');

    const existedStudent = studentsData?.parentStudents?.find(
      (parentStudent) => parentStudent?.student?._id === savedStudent
    );

    if (existedStudent) {
      setCurrentStudentId(savedStudent || '');
    } else {
      const validStudent = studentsData?.parentStudents?.find(
        (value) => value?.student?._id
      );
      setCurrentStudentId(validStudent?.student?._id as string);
    }
  }, [studentsData?.parentStudents]);

  const currentStudent = {
    ...studentsData?.parentStudents?.find(
      (parentStudent) => parentStudent?.student?._id === currentStudentId
    ),
  } as CurrentStudent;

  return {
    loading,
    students: studentsData?.parentStudents as ParentStudent[],
    currentStudent: currentStudent,
    changeStudent,
  };
};

export default useGetParentStudents;
