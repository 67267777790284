import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { getQuery } from '@lon/api';
import { Icon, Link } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { useThemeStyleValue } from '@lon/shared/hooks';
import {
  useGetAssignmentQuery,
  useGetDistrictAssignmentsQuery,
} from '@lon/shared/requests';
import { routes } from '@lon/suit/configs';
import { studentView } from '@lon/suit/constants';

const Description: React.FC<{ isDa?: boolean }> = ({ isDa }) => {
  const { assignmentId } = useParams();
  const { currentSchoolId: schoolId = '' } = React.useContext(WorkingLocation);

  const { t } = useTranslation();

  const { data: districtAssignmentsData, loading: daLoading } =
    useGetDistrictAssignmentsQuery({
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        filter: {
          teacherFilter: {
            schoolId,
            daId: assignmentId,
          },
        },
      },
      skip: !schoolId || !isDa,
    });

  const districtAssignment = React.useMemo(() => {
    const districtAssignmentsList =
      districtAssignmentsData?.districtAssignments?.collection || [];

    return (
      districtAssignmentsList.find(
        (districtAssessment) => districtAssessment.studentAssignments?.length
      ) || districtAssignmentsList[0]
    );
  }, [districtAssignmentsData]);

  const { data: assignmentData, loading: assignmentLoading } =
    useGetAssignmentQuery({
      variables: {
        id: assignmentId?.replace('_', '#'),
        schoolId,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip: !schoolId || isDa,
    });

  const assignment = assignmentData?.assignment?.assignmentSource?.element;
  const data = isDa ? districtAssignment : assignment;

  const loading = assignmentLoading || daLoading;

  const textColor = useThemeStyleValue('primary.800', 'white');
  const menuButtonVariant = useThemeStyleValue('solidDark', 'solid');
  const bgColor = useThemeStyleValue('white', 'secondary.1000');

  return (
    <Popover placement="bottom-end" autoFocus={false}>
      {({ isOpen }) => (
        <>
          <Tooltip
            variant="dark"
            label={t('studentAssignmentDetails.showAssignmentInformation')}
          >
            <Box>
              <PopoverTrigger>
                <Button
                  variant={menuButtonVariant}
                  size="sm"
                  leftIcon={<Icon name="info-circle" />}
                  rightIcon={
                    <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
                  }
                  display="flex"
                  gap={2}
                  sx={{
                    span: {
                      margin: 0,
                    },
                  }}
                ></Button>
              </PopoverTrigger>
            </Box>
          </Tooltip>

          <PopoverContent
            backgroundColor={bgColor}
            boxShadow="0px 3px 20px 15px #2B364633"
            p={8}
            width="auto"
            maxWidth="368px"
          >
            {loading ? (
              <Spinner color={textColor} />
            ) : (
              <Flex
                direction="column"
                gap={4}
                color={textColor}
                borderColor="secondary.200"
              >
                <Text variant="n1" fontSize="1.125rem">
                  {t('assignments.aboutAssignment')}
                </Text>

                {isDa && <Text variant="n1">{districtAssignment?.name}</Text>}

                {!isDa && (
                  <>
                    <Text variant="n1">
                      {data
                        ? t('assignments.assignedScopeElementTitle')
                        : t('assignments.assignedAssessmentTitle')}
                    </Text>
                    <Box>
                      <Link
                        to={
                          data
                            ? {
                                pathname: generatePath(routes.scopes.element, {
                                  scopeId: `${assignment?.scopeId}`,
                                  sectionId: `${assignment?.sectionId}`,
                                  elementId: `${assignment?.elementId}`,
                                  projectId: `${assignment?.projectId}`,
                                }),
                                search: getQuery({
                                  type: studentView,
                                  studentElementId: assignment?.viewId,
                                }),
                              }
                            : {
                                pathname: generatePath(
                                  routes.assessments.show,
                                  {
                                    id: `${assignmentData?.assignment?.studentAssignments?.[0]?.assessmentId}`,
                                  }
                                ),
                              }
                        }
                      >
                        <Text
                          as="span"
                          variant="n5"
                          color={textColor}
                          textDecoration="underline"
                          _hover={{ textDecoration: 'none' }}
                        >
                          {assignment
                            ? assignment.elementName
                            : assignmentData?.assignment?.name}
                        </Text>
                      </Link>
                    </Box>
                  </>
                )}

                {data && !isDa && (
                  <Flex mb={4} gap={2}>
                    <Text as="span">{t('grades.from', { name: '' })}</Text>
                    <Link
                      to={{
                        pathname: generatePath(routes.scopes.show, {
                          scopeId: `${assignment?.scopeId}`,
                          projectId: `${assignment?.projectId}`,
                        }),
                      }}
                    >
                      <Text
                        as="span"
                        variant="n5"
                        color={textColor}
                        textDecoration="underline"
                        _hover={{ textDecoration: 'none' }}
                      >
                        {assignment?.scopeName}
                      </Text>
                    </Link>
                  </Flex>
                )}

                <Flex align="center" gap={6}>
                  <Box
                    height="45px"
                    sx={{ svg: { width: '45px', height: '45px' } }}
                  >
                    <Icon name="clock-detailed" />
                  </Box>
                  <Flex align="flex-end" gap={1}>
                    <Flex direction="column" gap={1}>
                      <Text variant="s2">{t('assignments.startDate')}</Text>
                      <Tag
                        variant="info"
                        fontSize="0.875rem"
                        lineHeight="1.25rem"
                      >
                        {isDa
                          ? districtAssignment?.startDate &&
                            format(
                              parseISO(districtAssignment?.startDate),
                              'MM/dd/yyyy'
                            )
                          : assignmentData?.assignment?.startDate &&
                            format(
                              parseISO(assignmentData?.assignment?.startDate),
                              'MM/dd/yyyy'
                            )}
                      </Tag>
                    </Flex>
                    <Box>-</Box>
                    <Flex direction="column" gap={1}>
                      <Text variant="s2">{t('assignments.dueDate')}</Text>
                      <Tag
                        variant="info"
                        fontSize="0.875rem"
                        lineHeight="1.25rem"
                      >
                        {isDa
                          ? districtAssignment?.endDate &&
                            format(
                              parseISO(districtAssignment?.endDate),
                              'MM/dd/yyyy'
                            )
                          : assignmentData?.assignment?.endDate &&
                            format(
                              parseISO(assignmentData?.assignment?.endDate),
                              'MM/dd/yyyy'
                            )}
                      </Tag>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default Description;
