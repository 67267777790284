// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getSwitch = (theme: ThemeEnum) => ({
  baseStyle: {
    thumb: {
      background: getThemeStyleValue('white', 'secondary.1000')(theme),
    },
    track: {
      background: getThemeStyleValue('gray.300', 'white')(theme),
      _checked: {
        background: getThemeStyleValue('blue.500', 'teal.500')(theme),
      },
    },
  },
  variants: {
    primary: {
      thumb: {
        background: 'primary.800',
      },
      track: {
        background: 'gray.300',
        _checked: {
          background: 'white',
        },
      },
    },
  },
});
