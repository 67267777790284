import { ContentWrapperProps } from '../../duck/types';
import { Box, Flex } from '@chakra-ui/react';
import { Footer } from '@lon/shared/components';
import {
  isMobileDevice,
  sxHighContrastScrollBar,
  sxLightScrollBar,
} from '@lon/shared/constants';
import {
  useAuth,
  useMediaQuery,
  useScrollExisting,
  useThemeStyleValue,
} from '@lon/shared/hooks';

const MiddleHighStudentWrapper: React.FC<ContentWrapperProps> = ({
  rightColumnComponent,
  pageContentProps,
  hasTabletPaddings,
  isEmpty,
  emptyMessage,
}) => {
  const {
    contentRef,
    contentScrolls: { vertical: hasContentScroll },
  } = useScrollExisting({
    horizontal: false,
  });
  const [{ user }] = useAuth();
  const [isTablet] = useMediaQuery('(min-width: 768px)');
  const scrollbar = useThemeStyleValue(
    sxLightScrollBar,
    sxHighContrastScrollBar
  );

  return (
    <Flex
      ref={contentRef}
      flexGrow={1}
      flexDir="column"
      className="page-content"
      overflowY="auto"
      backgroundColor={useThemeStyleValue('none', 'secondary.950')}
      sx={isMobileDevice ? {} : scrollbar}
    >
      <Flex
        position="relative"
        pt={{
          base: isEmpty ? 0 : 4,
          md: 6,
        }}
        pr={{
          base: hasTabletPaddings
            ? isEmpty
              ? 0
              : hasContentScroll
              ? 1.5
              : 4
            : 0,
          md: hasTabletPaddings || isEmpty ? (hasContentScroll ? 3.5 : 6) : 0,
          lg: hasContentScroll ? 3.5 : 6,
        }}
        pl={{
          base: hasTabletPaddings ? (isEmpty ? 0 : 4) : 0,
          md: hasTabletPaddings || isEmpty ? 6 : 0,
          lg: 6,
        }}
        pb={{ base: 0, md: 6 }}
        gap={{ base: 4, md: 0 }}
        direction="column"
        justify="space-between"
        flexGrow={1}
        {...pageContentProps}
      >
        {isEmpty && emptyMessage ? emptyMessage : rightColumnComponent}
      </Flex>
      {!isTablet && !user.impersonated && (
        <Box mt="4">
          <Footer />
        </Box>
      )}
    </Flex>
  );
};

export default MiddleHighStudentWrapper;
