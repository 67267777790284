import { uniqueId } from 'lodash-es';

export const addElementsId = (
  container: Element,
  elements: string,
  clb: (element: Element) => void,
  prefix = ''
): void => {
  const elementsCollection = container.querySelectorAll(elements) || [];
  const filteredElements = [].filter.call(
    elementsCollection,
    (item: Element) => !!item.textContent
  );
  filteredElements.forEach((element: Element) => {
    element.id = `${prefix}_${uniqueId()}`;
    clb(element);
  });
};

export const getIconPathForContentPlayer = (activityTime?: string) => {
  const iconName = `${activityTime?.replace(' ', '_')}.png`;
  return activityTime
    ? {
        filePath: `/icons/en/${iconName}`,
        languageFilePath: [
          {
            languageCode: 'en',
            filePath: `/icons/en/${iconName}`,
          },
          {
            languageCode: 'es',
            filePath: `/icons/es/${iconName}`,
          },
        ],
      }
    : {};
};
