import { DrawerRequiredFieldsInstruction } from '../drawer-required-fields-instruction';
import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfirmModal, Icon, Spinner } from '@lon/shared/components';
import { useToast } from '@lon/shared/components';
import { calendarIdPrefix } from '@lon/shared/constants';
import { sxLightScrollBar } from '@lon/shared/constants';
import { useGetSchoolIdsForPlanner } from '@lon/shared/hooks';
import {
  CalendarTypeEnum,
  useCreateCalendarMutation,
  useGetCalendarLazyQuery,
  useGetUniqueTemplateByNameLazyQuery,
  useRemoveCalendarMutation,
  useUpdateCalendarMutation,
} from '@lon/shared/requests';
import { TemplateTitleValidationStatusType } from '@lon/suit/types';
import { sanitizeWithSpecialChars } from '@lon/suit/utils';
import {
  FormValues,
  TABS,
  TabKey,
  fieldLocations,
  getTemplatePayload,
  getTemplateRespFormatted,
  getToast,
  getValidation,
  userRoleCode as userRoleCodeSelector,
} from './duck';
import {
  CalendarTemplateGeneralSettings,
  GradingHolidaysTab,
} from './components';

const CalendarTemplateDrawer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  editTemplateId?: string;
}> = ({ isOpen, onClose, editTemplateId }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const {
    isOpen: isEditWarnOpen,
    onOpen: onEditWarnOpen,
    onClose: onEditWarnClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: onDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
  } = useDisclosure();
  const userRoleCode = useSelector(userRoleCodeSelector);
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();

  const [isEditDrawer, setIsEditDrawer] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [titleValidationStatus, setTitleValidationStatus] =
    useState<TemplateTitleValidationStatusType>(
      TemplateTitleValidationStatusType.REMAIN
    );

  const { validationSchema, defaultValues } = getValidation();
  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onChange',
  });
  const { getValues, handleSubmit, formState, clearErrors, reset } =
    formProviderProps;

  const isEditTemplate = useMemo(() => {
    return Boolean(editTemplateId) || isEditDrawer;
  }, [editTemplateId, isEditDrawer]);

  const isDirty = useMemo(
    () => Boolean(Object.keys(formState.dirtyFields).length),
    [formState]
  );

  const [getTemplate, { data: template, loading: templateLoading }] =
    useGetCalendarLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data?.calendar) {
          setIsEditDrawer(true);
          const fieldData = getTemplateRespFormatted(data.calendar);
          reset(fieldData);
          //TODO uncomment when the "Validate" button is fixed
          // setTitleValidationStatus(TemplateTitleValidationStatusType.ORIGIN);
        }
      },
    });

  const [getUniqueTemplate, { loading: loadingTemplates }] =
    useGetUniqueTemplateByNameLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [createTemplate, { loading: creatingTemplate }] =
    useCreateCalendarMutation({
      refetchQueries: ['userCalendars'],
      awaitRefetchQueries: true,
    });
  const [updateTemplate, { loading: updatingTemplate }] =
    useUpdateCalendarMutation({
      refetchQueries: ['userCalendars'],
      awaitRefetchQueries: true,
    });
  const [deleteTemplate, { loading: deletingTemplate }] =
    useRemoveCalendarMutation({
      refetchQueries: ['userCalendars'],
      awaitRefetchQueries: true,
    });

  const handleFetchTemplate = (id?: string) => {
    if (id) {
      getTemplate({
        variables: {
          id: '/api/lesson-planner/api/calendars/' + id,
        },
      });
    }
  };

  const validateTemplateTitle = async () => {
    const title = getValues('name').trim() ?? '';
    clearErrors('name');
    let successValidated = false;
    try {
      await getUniqueTemplate({
        variables: {
          name: title,
          schoolIds: accessibleSchoolIds,
          userRoleCode,
        },
        onCompleted(data) {
          if (
            !isEditTemplate ||
            !data?.userCalendars?.collection?.length ||
            template?.calendar?._id !==
              data?.userCalendars?.collection?.[0]?._id
          ) {
            setTitleValidationStatus(
              data?.userCalendars?.collection?.length
                ? TemplateTitleValidationStatusType.FAILED
                : TemplateTitleValidationStatusType.SUCCESS
            );
            successValidated = data.userCalendars?.collection?.length
              ? false
              : true;
          } else {
            successValidated = true;
          }
        },
        onError() {
          getToast(
            toast,
            false,
            t('calendarTemplate.nameValidationFailed'),
            t('calendarTemplate.nameValidationFailedDesc')
          );
          setTitleValidationStatus(TemplateTitleValidationStatusType.ERROR);
        },
      });
    } catch (e) {
      console.error(e);
      getToast(
        toast,
        false,
        t('calendarTemplate.nameValidationFailed'),
        t('calendarTemplate.nameValidationFailedDesc')
      );
    }
    return successValidated;
  };

  const closeAndResetForm = () => {
    if (template?.calendar?._id) {
      setIsEditDrawer(false);
    }
    setSelectedTabIndex(0);
    reset(defaultValues);
    onClose();
    onEditWarnClose();
    onConfirmClose();
    onDeleteConfirmClose();
  };

  const closeForm = () => {
    if (isDirty) {
      onConfirmOpen();
    } else {
      closeAndResetForm();
    }
  };

  const onSubmit = async (values: FormValues, isFromConfirm?: boolean) => {
    //TODO uncomment when the "Validate" button is fixed
    // if (titleValidationStatus === TemplateTitleValidationStatusType.REMAIN) {
    //   setError('name', {
    //     type: 'custom',
    //     message: t('calendarTemplate.validationRemainError'),
    //   });
    //   return;
    // }
    const payload = getTemplatePayload(values);
    try {
      if (!isEditTemplate) {
        await createTemplate({
          variables: {
            input: {
              ...payload,
              schoolIds: accessibleSchoolIds,
              userRoleCode: userRoleCode,
              calendarType: CalendarTypeEnum.Template,
            },
          },
          onCompleted(data) {
            getToast(
              toast,
              true,
              t('calendarTemplate.createSuccess'),
              sanitizeWithSpecialChars(
                t('calendarTemplate.createSuccessDesc', {
                  name: payload.name,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              )
            );
            if (isFromConfirm) {
              closeAndResetForm();
            } else {
              setIsEditDrawer(true);
              handleFetchTemplate(data?.createCalendar?.calendar?._id);
            }
          },
          onError: async () => {
            //TODO uncomment when the "Validate" button is fixed
            // const validated = await validateTemplateTitle();
            // const errorMsgDesc = validated
            //   ? 'calendarTemplate.createFailedDesc'
            //   : 'calendarTemplate.createTitleAlreadyUsed';
            getToast(
              toast,
              false,
              t('calendarTemplate.Error'),
              sanitizeWithSpecialChars(
                t('calendarTemplate.createFailedDesc', {
                  name: payload.name,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              )
            );
            onConfirmClose();
          },
        });
      } else {
        await updateTemplate({
          variables: {
            input: {
              id: calendarIdPrefix + template?.calendar?._id ?? '',
              version: template?.calendar?.version ?? 1,
              schoolIds: accessibleSchoolIds,
              userRoleCode: userRoleCode,
              ...payload,
            },
          },
          onCompleted(data) {
            getToast(
              toast,
              true,
              t('calendarTemplate.updateSuccess'),
              sanitizeWithSpecialChars(
                t('calendarTemplate.updateSuccessDes', {
                  name: payload.name,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              )
            );
            if (isFromConfirm) {
              closeAndResetForm();
            } else {
              setIsEditDrawer(true);
              onEditWarnClose();
              onConfirmClose();
              handleFetchTemplate(data?.updateCalendar?.calendar?._id);
            }
          },
          onError: async () => {
            //TODO uncomment when the "Validate" button is fixed
            // const validated = await validateTemplateTitle();
            // const errorMsgDesc = validated
            //   ? 'calendarTemplate.updateFailedDesc'
            //   : 'calendarTemplate.updateTitleAlreadyUsed';
            getToast(
              toast,
              false,
              t('calendarTemplate.Error'),
              sanitizeWithSpecialChars(
                t('calendarTemplate.updateFailedDesc', {
                  name: payload.name,
                  interpolation: {
                    escapeValue: false,
                  },
                })
              )
            );
            onConfirmClose();
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      await deleteTemplate({
        variables: {
          input: {
            id: calendarIdPrefix + template?.calendar?._id ?? '',
            schoolIds: accessibleSchoolIds,
            userRoleCode: userRoleCode,
          },
        },
        onCompleted() {
          getToast(
            toast,
            true,
            t('calendarTemplate.deleteSuccess'),
            sanitizeWithSpecialChars(
              t('calendarTemplate.deleteSuccessDesc', {
                name: template?.calendar?.name,
                interpolation: {
                  escapeValue: false,
                },
              })
            )
          );
          closeAndResetForm();
        },
        onError() {
          getToast(
            toast,
            false,
            t('calendarTemplate.Error'),
            sanitizeWithSpecialChars(
              t('calendarTemplate.deleteFailedDesc', {
                name: template?.calendar?.name,
                interpolation: {
                  escapeValue: false,
                },
              })
            )
          );
          onDeleteConfirmClose();
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isEmpty(formState.errors)) {
      const firstErrorField = Object.keys(formState.errors)[0];
      const tabIndex = fieldLocations[firstErrorField];
      setSelectedTabIndex(tabIndex);
      if (isConfirmOpen) {
        onConfirmClose();
      }
      onEditWarnClose();
    }
  }, [formState.errors]);

  useEffect(() => {
    if (editTemplateId) {
      handleFetchTemplate(editTemplateId);
    }
  }, [editTemplateId]);

  useEffect(() => {
    return () => {
      closeForm();
    };
  }, []);

  const renderTabPanel = (tabKey: TabKey) => {
    const panelProps = {
      generalSettings: (
        <CalendarTemplateGeneralSettings
          handleValidateTitle={validateTemplateTitle}
          loadingValidation={loadingTemplates}
          titleValidationStatus={titleValidationStatus}
          setTitleValidationStatus={setTitleValidationStatus}
          editOriginTitle={isEditTemplate ? template?.calendar?.name : ''}
        />
      ),
      gradingPeriods: (
        <GradingHolidaysTab
          fieldName="gradingPeriods"
          heading="calendarTemplate.gradingPeriods"
          fieldTitle="calendarTemplate.gradingPeriodTitle"
          fieldPlaceholder="calendarTemplate.gradingPeriodPlaceHolder"
          initialText="calendarTemplate.noGradingFields"
          addFieldLabel="calendarTemplate.addGradingBtn"
        />
      ),
      holidays: (
        <GradingHolidaysTab
          fieldName="holidays"
          heading="calendarTemplate.holidays"
          fieldTitle="calendarTemplate.holidayTitle"
          fieldPlaceholder="calendarTemplate.holidayPlaceHolder"
          initialText="calendarTemplate.noHolidayFields"
          addFieldLabel="calendarTemplate.addHolidayBtn"
        />
      ),
    };
    return panelProps[tabKey];
  };

  return (
    <Drawer
      closeOnEsc={false}
      closeOnOverlayClick={false}
      variant="formDrawer"
      isOpen={isOpen}
      placement="right"
      onClose={closeForm}
      onEsc={closeForm}
      onOverlayClick={closeForm}
    >
      <DrawerOverlay />
      <FormProvider {...formProviderProps}>
        <Box
          as="form"
          noValidate
          onSubmit={handleSubmit(
            (values) =>
              !isEditTemplate ? onSubmit(values, false) : onEditWarnOpen(),
            console.log
          )}
        >
          <DrawerContent boxShadow="none" maxW="1000px">
            <DrawerCloseButton pb={2} />
            <DrawerHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text fontSize="1.25rem" lineHeight="1.875rem">
                {t('calendarTemplate.drawerHeader')}
              </Text>
              {!isEditTemplate ? (
                <Badge variant="greenBadge" marginRight="16">
                  {t('tags.new')}
                </Badge>
              ) : (
                isDirty && (
                  <Badge variant="yellowBadge" marginRight="16">
                    {t('tags.edited')}
                  </Badge>
                )
              )}
            </DrawerHeader>
            <DrawerBody>
              {templateLoading ? (
                <Flex boxSize="full" justify="center" align="center">
                  <Spinner variant="dark" />
                </Flex>
              ) : (
                <Tabs
                  display="flex"
                  flexDir="column"
                  height="full"
                  align="center"
                  variant="drawer"
                  index={selectedTabIndex}
                  onChange={setSelectedTabIndex}
                >
                  <Box boxShadow="0px 2px 7px rgba(43, 54, 70, 0.1)">
                    <TabList mx="4" width={{ sm: '700px' }}>
                      {TABS.map((tab) => {
                        return (
                          <Tab key={tab.key} title={tab.title} gap="3">
                            <Icon name={tab.icon} size="lg" />
                            <Text
                              variant="bm"
                              color="primary.800"
                              display={{ base: 'none', md: 'flex' }}
                            >
                              {tab.title}
                            </Text>
                            <Text
                              variant="bm"
                              color="primary.800"
                              display={{
                                base: 'none',
                                b430: 'flex',
                                md: 'none',
                              }}
                            >
                              {tab.shortTitle}
                            </Text>
                          </Tab>
                        );
                      })}
                    </TabList>
                  </Box>
                  <DrawerRequiredFieldsInstruction />
                  <TabPanels flex="1" overflowY="auto" css={sxLightScrollBar}>
                    {TABS.map((tab) => {
                      return (
                        <TabPanel p="0" key={tab.key}>
                          {renderTabPanel(tab.key)}
                        </TabPanel>
                      );
                    })}
                  </TabPanels>
                </Tabs>
              )}
            </DrawerBody>
            <DrawerFooter>
              <HStack justify="space-between" width="100%">
                <Flex gap={{ base: '4', md: '6' }}>
                  <Button
                    variant="outline"
                    onClick={closeForm}
                    p={{ base: '3', md: '0.75rem 1rem' }}
                  >
                    <Icon name="arr-left-outlined" />
                    <Text ml="2" display={{ base: 'none', md: 'inline-flex' }}>
                      {t('buttons.cancel')}
                    </Text>
                  </Button>
                  <Button
                    variant="ghost"
                    isDisabled={
                      !isEditTemplate ||
                      templateLoading ||
                      creatingTemplate ||
                      updatingTemplate ||
                      deletingTemplate ||
                      isDirty
                    }
                    p={{ base: '3', md: '0.75rem 1rem' }}
                    onClick={onDeleteConfirmOpen}
                  >
                    <Icon name="deleteOutlined" />
                    <Text ml="2" display={{ base: 'none', md: 'inline-flex' }}>
                      {t('calendarTemplate.deleteTemplateBtn')}
                    </Text>
                  </Button>
                </Flex>
                <Button
                  type="submit"
                  variant="solid"
                  isDisabled={
                    loadingTemplates || !isDirty
                    //TODO uncomment when the "Validate" button is fixed
                    // || (titleValidationStatus !==
                    //   TemplateTitleValidationStatusType.SUCCESS &&
                    //   titleValidationStatus !==
                    //     TemplateTitleValidationStatusType.ORIGIN)
                  }
                  isLoading={creatingTemplate || updatingTemplate}
                  leftIcon={<Icon name="check-outlined" size="md" />}
                >
                  {isEditTemplate ? t('buttons.save') : t('buttons.createBtn')}
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
          <ConfirmModal
            modalVariant="bigFormConfirmModal"
            primaryHeading={
              !isEditTemplate
                ? t('calendarTemplate.confirmHeader')
                : t('calendarTemplate.confirmEditHeader')
            }
            subHeading={
              !isEditTemplate
                ? t('calendarTemplate.confirmText')
                : t('calendarTemplate.confirmEditText')
            }
            dontSaveBtnText={
              !isEditTemplate
                ? t('buttons.dontCreateBtn')
                : t('buttons.dontSaveBtn')
            }
            submitButtonText={
              !isEditTemplate ? t('buttons.createBtn') : t('buttons.save')
            }
            hasDontSaveBtn
            isModalOpen={isConfirmOpen}
            handleSave={
              !isEditTemplate
                ? handleSubmit((data) => onSubmit(data, true), console.log)
                : onEditWarnOpen
            }
            handleDontSave={closeAndResetForm}
            handleCancel={onConfirmClose}
            isLoading={creatingTemplate || updatingTemplate}
            dontSaveBtnProps={{
              variant: 'link',
              leftIcon: <Icon name="delete-outlined" size="lg" />,
            }}
            submitBtnProps={{
              leftIcon: <Icon name="check-outlined" size="md" />,
            }}
          />

          <ConfirmModal
            modalVariant="bigFormConfirmModal"
            primaryHeading={t('calendarTemplate.editConfirmHeader')}
            subHeading={t('calendarTemplate.confirmEditDesc')}
            submitButtonText={t('buttons.save')}
            isModalOpen={isEditWarnOpen}
            handleSave={handleSubmit(
              (data) => onSubmit(data, false),
              console.log
            )}
            handleDontSave={closeAndResetForm}
            handleCancel={() => {
              onEditWarnClose();
              onConfirmClose();
            }}
            isLoading={updatingTemplate}
            submitBtnProps={{
              leftIcon: <Icon name="check-outlined" size="md" />,
            }}
          />

          <ConfirmModal
            modalVariant="bigFormConfirmModal"
            primaryHeading={t('calendarTemplate.deleteConfirmTitle')}
            subHeading={sanitizeWithSpecialChars(
              t('calendarTemplate.deleteConfirmDesc', {
                name: template?.calendar?.name ?? '',
                interpolation: {
                  escapeValue: false,
                },
              })
            )}
            submitButtonText={t('buttons.yesDelete')}
            isModalOpen={isDeleteConfirmOpen}
            handleSave={handleDeleteTemplate}
            handleCancel={onDeleteConfirmClose}
            isLoading={deletingTemplate}
            submitBtnProps={{
              leftIcon: <Icon name="check-outlined" size="md" />,
            }}
          />
        </Box>
      </FormProvider>
    </Drawer>
  );
};

export default CalendarTemplateDrawer;
