import { State } from './types';

const initialState: State = {
  loadingCount: 0,
  firstLoading: true,
  data: undefined,
  meta: undefined,
  error: undefined,
};

export default initialState;
