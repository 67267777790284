import parse from 'date-fns/parse';
import { t } from 'i18next';
import { isEmpty, omitBy } from 'lodash-es';
import { array, object, string } from 'yup';
import { Entry, GetCalendarQuery } from '@lon/shared/requests';
import { ToastFn } from '@lon/shared/types';
import {
  dateValueFormat,
  defaultSchoolDay,
  eventFieldKeys,
  multiEventFields,
} from './constants';

export const validationSchema = object({
  name: string()
    .trim()
    .default('')
    .required(t('calendarTemplate.requiredFieldMsg', { fieldName: 'name' }))
    .max(
      100,
      t('validationMessages.maxLength', {
        fieldName: t('calendarTemplate.title'),
        length: 100,
      })
    ),
  firstDayOfSchool: array()
    .of(
      object({
        eventId: string().optional().default('').nullable(),
        startDate: string().nullable().optional().default(''),
      })
    )
    .default([defaultSchoolDay]),
  lastDayOfSchool: array()
    .of(
      object({
        eventId: string().optional().default('').nullable(),
        startDate: string().nullable().optional().default(''),
      })
    )
    .default([defaultSchoolDay]),
  gradingPeriods: array()
    .of(
      object().shape({
        eventId: string().optional().default('').nullable(),
        name: string()
          .trim()
          .default('')
          .nullable()
          .required(
            t('calendarTemplate.requiredFieldMsg', {
              fieldName: 'Period Name',
            })
          )
          .max(
            100,
            t('validationMessages.maxLength', {
              fieldName: t('calendarTemplate.gradingPeriodTitle', {
                number: '',
              }),
              length: 100,
            })
          ),
        dateRange: object().shape({
          startDate: string()
            .default('')
            .nullable()
            .required(
              t('calendarTemplate.requiredFieldMsg', {
                fieldName: 'Start Date',
              })
            ),
          endDate: string()
            .default('')
            .nullable()
            .required(
              t('calendarTemplate.requiredFieldMsg', {
                fieldName: 'End Date',
              })
            ),
        }),
      })
    )
    .default([]),
  holidays: array()
    .of(
      object().shape({
        eventId: string().optional().default('').nullable(),
        name: string()
          .trim()
          .default('')
          .nullable()
          .required(
            t('calendarTemplate.requiredFieldMsg', { fieldName: 'Holiday' })
          )
          .max(
            100,
            t('validationMessages.maxLength', {
              fieldName: t('calendarTemplate.holidayTitle', { number: '' }),
              length: 100,
            })
          ),
        dateRange: object().shape({
          startDate: string()
            .default('')
            .nullable()
            .required(
              t('calendarTemplate.requiredFieldMsg', {
                fieldName: 'Start Date',
              })
            ),
          endDate: string().default('').nullable(),
        }),
      })
    )
    .default([]),
});

export const getValidation = () => {
  return {
    validationSchema,
    defaultValues: validationSchema.cast({}),
  };
};

export const parseStringifiedDate = (dateString: string) => {
  return dateString
    ? parse(dateString, dateValueFormat, new Date())
    : undefined;
};

export const getToast = (
  toast: ToastFn,
  isSuccess: boolean,
  title: string,
  desc: string
) => {
  if (isSuccess) {
    toast({
      title,
      description: desc,
      position: 'bottom',
      status: 'success',
      variant: 'success-light',
      duration: 5000,
      isClosable: true,
    });
  } else {
    toast({
      title,
      description: desc,
      position: 'bottom',
      status: 'error',
      variant: 'error-light',
      duration: null,
      isClosable: true,
    });
  }
};

export const getEntryFormatted = (entry: Entry) => {
  if (multiEventFields.includes(entry.type)) {
    return {
      eventId: entry?.eventId,
      name: entry?.name,
      dateRange: {
        startDate: entry?.startDate || '',
        endDate: entry?.endDate || entry?.startDate || '',
      },
    };
  }
  return {
    eventId: entry?.eventId || '',
    startDate: entry?.startDate || '',
  };
};

export const getTemplateRespFormatted = (
  templateResp: GetCalendarQuery['calendar']
) => {
  const template = templateResp;
  const sortedEvents = template?.calendarEntries?.collection?.reduce<{
    [key: string]: Array<{}>;
  }>((allEvent, event) => {
    const fieldKey = event?.type ? eventFieldKeys[event?.type] : '';
    if (event?.type && allEvent[fieldKey]) {
      allEvent[fieldKey].push(getEntryFormatted(event as Entry));
    } else if (event?.type) {
      allEvent[fieldKey] = [getEntryFormatted(event as Entry)];
    }
    return allEvent;
  }, {});
  return {
    ...sortedEvents,
    name: template?.name ?? '',
  };
};

export const getTemplatePayload = (formValues: { [key: string]: any }) => {
  const payload = Object.keys(formValues).reduce<{ [key: string]: {} }>(
    (allValues, fieldName) => {
      const fieldValue = formValues[fieldName];
      if (Array.isArray(fieldValue) && fieldName) {
        allValues[fieldName] = fieldValue
          .map((field) => omitBy(field, isEmpty))
          .filter((field) => !isEmpty(field));
      } else if (fieldName) {
        allValues[fieldName] = fieldValue;
      }
      return allValues;
    },
    {}
  );
  return payload;
};
