import { CollapsibleSidebar } from '../../../../../collapsible-sidebar';
import { Icon } from '../../../../../icon';
import { scopeTypes } from '../../duck';
import { Box, Button, Collapse, Flex } from '@chakra-ui/react';
import { AiOutlineHome } from '@react-icons/all-files/ai/AiOutlineHome';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { sxSidebarDarkScrollBar } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { useScrollExisting } from '@lon/shared/hooks';
import { ScopeType } from '@lon/shared/types';
import { checkContentPlayer } from '@lon/shared/utils';
import PlanElementList from './components/plan-element-list/PlanElementList';
import {
  ContentGroupsMenu,
  Description,
  LessonScopeMenu,
  ScopeMenu,
  Skeleton,
  SuitScopeMenuWrapper,
} from './components';

const Sidebar: React.FC<scopeTypes.SidebarProps> = ({
  scope,
  standards = [],
  ...rest
}) => {
  const {
    contentRef,
    contentScrolls: { vertical: hasContentScroll },
  } = useScrollExisting({
    horizontal: false,
    dependencies: [scope],
  });
  const { projectType } = useContext(ScopeContext);
  const { sectionId, scopeId, projectId } = useParams() as {
    sectionId: string;
    scopeId: string;
    projectId: string;
  };
  const { t } = useTranslation();
  const isContentPlayer = checkContentPlayer();
  const RenderScopeMenu = !isContentPlayer ? SuitScopeMenuWrapper : ScopeMenu;
  const [show, setShow] = React.useState(true);

  const handleToggle = () => setShow(!show);

  return (
    <CollapsibleSidebar
      {...rest}
      skeleton={<Skeleton />}
      contentWrapperProps={{ sx: { scrollbarGutter: 'unset' } }}
      parentWrapperProps={{
        height: '100vh !important',
      }}
    >
      <>
        <Box bgColor="blue.500" p={6}>
          {isContentPlayer && <ContentGroupsMenu />}
          <Description scope={scope} standards={standards} />
        </Box>
        <Box
          ref={contentRef}
          as="nav"
          py={1}
          overflowY="auto"
          overflowX="hidden"
          pl={6}
          pr={hasContentScroll ? 2.5 : 'unset'}
          mb={6}
          flexGrow={1}
          sx={{ ...sxSidebarDarkScrollBar }}
          mr={6}
        >
          <Flex
            as="ul"
            direction="column"
            gap="3"
            listStyleType="none"
            role="tablist"
            aria-orientation="vertical"
            aria-label={t('scope.scopeSidebarNavigation')}
          >
            <Box as="li">
              <Button
                justifyContent="flex-start"
                variant="sidebarButton"
                aria-label="Home"
                leftIcon={<AiOutlineHome fontSize="1.25rem" />}
                as={Link}
                to={{
                  pathname: `/scopes/${projectId}/${scopeId}`,
                }}
                w="full"
                role="tab"
                id={scope?.home?.landingPage?.identifier}
                aria-controls="scope-element"
                aria-selected={
                  !sectionId && !window.location.pathname.includes('view-all')
                }
                {...(!sectionId && {
                  isActive: true,
                  className: 'active',
                  css: {
                    backgroundColor:
                      'var(--chakra-colors-primary-50) !important',
                    color: 'var(--chakra-colors-primary-800) !important',
                  },
                })}
              >
                {t('scope.homeButton')}
              </Button>
            </Box>
            {projectType === ScopeType.LessonScope ? (
              <LessonScopeMenu
                scope={scope}
                sectionId={sectionId}
                metadata={scope?.metadata}
                scopeId={scopeId}
                projectId={projectId}
              />
            ) : (
              <RenderScopeMenu
                scope={scope}
                sidebarElements={scope?.home?.sideBar}
                metadata={scope?.metadata}
                scopeId={scopeId}
              />
            )}
            {!isContentPlayer && (
              <>
                <Button
                  w="full"
                  role="menuitem"
                  onClick={handleToggle}
                  variant="sidebarButton"
                  position="relative"
                  justifyContent="flex-start"
                  fontWeight={400}
                  leftIcon={<Icon name={'user-group'} size="small" />}
                  iconSpacing="3"
                  sx={
                    show
                      ? {
                          backgroundColor: 'primary.50',

                          _before: {
                            position: 'absolute',
                            my: '7px',
                            top: '0',
                            left: '0',
                            zIndex: '1',
                            borderRadius: '0 4px 4px 0',
                            width: '4px',
                            height: 'calc(100% - 14px)',
                            backgroundColor: 'primary.400',

                            content: `''`,
                          },

                          _hover: {
                            backgroundColor: 'primary.30',
                          },

                          _active: {
                            textDecoration: 'none',
                            backgroundColor: 'primary.700',
                            color: 'white',
                          },
                        }
                      : {}
                  }
                >
                  {'My Plans'}
                </Button>

                <Collapse in={show}>
                  <PlanElementList
                    metadata={scope?.metadata}
                    scopeId={scopeId}
                    scope={scope}
                  />
                </Collapse>
              </>
            )}
          </Flex>
        </Box>
      </>
    </CollapsibleSidebar>
  );
};

export default Sidebar;
