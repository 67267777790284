import { lazyWithRetry } from '@lon/shared/utils';

const StudentScopes = lazyWithRetry(
  () => import('./student-scopes'),
  'StudentScopes'
);
const Assignments = lazyWithRetry(() => import('./assignments'), 'Assignments');

const StudentResource = lazyWithRetry(
  () => import('./student-resource'),
  'StudentResource'
);

const StudentAssignment = lazyWithRetry(
  () => import('./student-assignment'),
  'StudentAssignment'
);

const DistrictAssignment = lazyWithRetry(
  () => import('./district-assignment'),
  'DistrictAssignment'
);

export {
  Assignments,
  StudentScopes,
  StudentResource,
  StudentAssignment,
  DistrictAssignment,
};
