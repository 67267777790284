import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@lon/shared/hooks';
import { confirmModalTypes } from './duck';
import styles from './ConfirmModal.module.css';

const ConfirmModal = ({
  primaryHeading,
  subHeading,
  submitButtonText,
  dontSaveBtnText,
  variant,
  isTitleDanger = false,
  saveVariant,
  isModalOpen,
  onModalClose = () => {},
  handleCancel,
  hasDontSaveBtn = false,
  handleDontSave = () => {},
  handleSave,
  hasSaveBtn = true,
  isLoading,
  dontSaveBtnProps,
  submitBtnProps,
  cancelButtonText,
  modalVariant = 'simple',
  modalSize = { base: 'full', sm: 'md' },
  isCentered = true,
  modalContentProps,
  drawerRef,
  saveBtnTooltipLabel,
  cancelBtnTooltipLabel,
}: confirmModalTypes.ConfirmModalProps) => {
  const { t } = useTranslation();
  const initialFocusRef = useRef<HTMLDivElement>(null);
  const isDangerVariant = variant === 'danger';
  const [isMobile] = useMediaQuery('(max-width: 767px)');

  return (
    <Modal
      variant={modalVariant}
      isOpen={isModalOpen}
      onClose={onModalClose}
      isCentered={isCentered}
      size={modalSize}
      initialFocusRef={initialFocusRef}
      {...(drawerRef
        ? {
            portalProps: {
              containerRef: drawerRef,
            },
          }
        : {})}
    >
      <ModalOverlay />
      <ModalContent
        data-focus-visible-disabled
        borderRadius="none"
        maxW={{ base: 'initial', md: '500px' }}
        {...modalContentProps}
      >
        <ModalBody className={styles.confirmContent}>
          <Flex
            direction="column"
            className={
              isDangerVariant
                ? styles.dangerTextContainer
                : styles.bodyTextContainer
            }
          >
            <Text
              as="h1"
              variant="h5"
              lineHeight="1.625rem"
              className={
                isTitleDanger ? styles.dangerHeading : styles.textHeading
              }
            >
              {primaryHeading}
            </Text>
            {subHeading && <Box className={styles.infoText}>{subHeading}</Box>}
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTopWidth="0"
          justifyContent="space-between"
          px="6"
          pt={{ base: '6', md: '0' }}
        >
          <Box
            ref={initialFocusRef}
            tabIndex={0}
            aria-hidden="true"
            position="absolute"
            opacity="0"
          />
          <Flex display="flex" flexDirection="row" gap={4}>
            <Tooltip
              variant="dark"
              aria-hidden={true}
              isDisabled={isLoading}
              label={cancelBtnTooltipLabel}
              placement="bottom-start"
            >
              <Button
                size="md"
                variant="outline"
                onClick={handleCancel}
                isDisabled={isLoading}
                onFocus={() => {
                  if (initialFocusRef.current) {
                    initialFocusRef.current.tabIndex = -1;
                  }
                }}
                _hover={{
                  backgroundColor: 'primary.30',
                  borderColor: 'primary.30',
                }}
              >
                <Text as="span">
                  {cancelButtonText || t('confirmModal.cancelBtn')}
                </Text>
              </Button>
            </Tooltip>
            {hasDontSaveBtn && (
              <Button
                size="md"
                variant="outline"
                onClick={handleDontSave}
                isDisabled={isLoading}
                {...(isMobile && {
                  'aria-label': t('confirmModal.dontSaveBtn'),
                  w: '44px',
                  sx: { '.chakra-button__icon': { mr: 0 } },
                })}
                {...dontSaveBtnProps}
              >
                <Text as="span">
                  {!isMobile &&
                    (dontSaveBtnText || t('confirmModal.dontSaveBtn'))}
                </Text>
              </Button>
            )}
          </Flex>

          {hasSaveBtn && (
            <Tooltip
              variant="dark"
              aria-hidden={true}
              isDisabled={isLoading}
              label={saveBtnTooltipLabel}
              placement="bottom-end"
            >
              <Button
                size="md"
                variant={saveVariant ? saveVariant : 'solid'}
                onClick={handleSave}
                isLoading={isLoading}
                isDisabled={isLoading}
                {...submitBtnProps}
              >
                <Text variant="bm">
                  {submitButtonText || t('confirmModal.saveBtn')}
                </Text>
              </Button>
            </Tooltip>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
