import { TFunction } from 'i18next';
import { isEmpty, toArray } from 'lodash-es';
import omit from 'lodash-es/omit';
import { PlanElementType, TransformedElements } from '@lon/shared/types';
import { GetElements, PlanStudentViewElementType } from './types';

export const getElements: GetElements = (elementsBySection = {}, sectionId) => {
  if (isEmpty(elementsBySection)) {
    return [];
  }

  if (!sectionId) {
    return toArray(elementsBySection).reduce(
      (acc, item) => [...acc, ...toArray(item)],
      []
    );
  }

  return toArray(elementsBySection?.[sectionId]);
};

export const getStudentViewElements = (
  element: PlanElementType,
  elementsBySection: TransformedElements,
  sectionId?: string
) => {
  const allElements = getElements(elementsBySection, sectionId);
  const elementInfo = allElements?.find(
    (scopeElement) => scopeElement.identifier === element?.identifier
  );

  const commonElementInfo = omit(
    element,
    'studentView',
    'teacherView',
    'identifier',
    'metadata'
  );

  return elementInfo?.studentView?.reduce<PlanStudentViewElementType[]>(
    (acc, studentViewElement) => {
      const referenceId =
        studentViewElement?.lorResources?.find(
          (resource) => resource?.type === 'activities'
        )?.referenceId || null;
      if (referenceId && studentViewElement?.metadata?.hide !== 'Yes') {
        acc.push({
          ...commonElementInfo,
          identifier: studentViewElement.identifier || '',
          viewId: studentViewElement.identifier || '',
          elementName: `${elementInfo?.metadata?.elementName ?? ''}${
            studentViewElement?.metadata?.activityTitle
              ? `: ${studentViewElement?.metadata?.activityTitle}`
              : ''
          }`,
          href: studentViewElement.href,
          referenceId:
            studentViewElement?.lorResources?.find(
              (resource) => resource?.type === 'activities'
            )?.referenceId || '',
        });
      }
      return acc;
    },
    []
  );
};

export const getDefaultDisabledTab = (
  t: TFunction,
  isIndividual?: boolean,
  isTeam?: boolean
) => {
  if ((!isIndividual && !isTeam) || (isIndividual && isTeam)) {
    return null;
  } else {
    return isIndividual ? t('planner.individual') : t('planner.team');
  }
};
