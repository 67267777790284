import resizeInputs from '../resize-inputs';
import { removeVerticalScrolls, wrap } from './duck';

const prepareLearnosityReportForGradingPage = (
  reportId: string,
  resizeObserver: ResizeObserver
) => {
  const isTwoColumnsLayout =
    document
      .getElementById(reportId)
      ?.querySelector('[class^="col-xs-"] + [class^="col-xs-"]') !== null;

  if (isTwoColumnsLayout) {
    document.getElementById(reportId)?.classList.add('two-columns-layout');
  }

  const widgets =
    document.getElementById(reportId)?.querySelectorAll('.lrn_widget') || [];

  [...widgets].forEach((widget) => {
    const isScrollablePassage = [...widget.classList].includes(
      'lrn_scrollablepassage'
    );
    if (isScrollablePassage) {
      widget.parentElement?.classList.add('observe-height');
      return;
    }

    if (isTwoColumnsLayout) {
      const wrapper = document.createElement('div');
      wrapper.classList.add('learnosity-question-container');
      wrap(widget, wrapper);
    } else {
      const questionWrapper = document.createElement('div');
      questionWrapper.classList.add('row', 'learnosity-question-container');

      const scrollablePassages =
        document.getElementsByClassName('lrn_scrollablepassage') || [];
      const insideScrollablePassage = [...scrollablePassages].some((element) =>
        element.contains(widget)
      );

      if (insideScrollablePassage) {
        // increasing 15px cause the questionWrapper having margin left-right -15px
        // and inside scrollablePassage the Grading component does not contain margin left-right
        questionWrapper.style.maxWidth = 'calc(100% + 15px)';
      }

      const colMd6 = document.createElement('div');
      colMd6.classList.add('col-md-6');
      wrap(widget, questionWrapper);
      wrap(widget, colMd6);
    }
  });

  const tables = document.getElementById(reportId)?.querySelectorAll('table');

  if (tables?.length) {
    tables.forEach((table) => {
      const container = table.closest('.lrn_cloze_response_container');
      container?.classList.add('table-question');
    });
  }

  const textAreas = document.querySelectorAll('.lrn_texteditor_editable');

  [...textAreas].forEach((area) => {
    area.dispatchEvent(new Event('input'));
  });

  removeVerticalScrolls();
  resizeInputs();

  const pageContent = document.querySelector('.page-content');

  if (pageContent) {
    resizeObserver.observe(pageContent);
  }
};

export default prepareLearnosityReportForGradingPage;
