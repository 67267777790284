import {
  ChakraStylesConfig,
  Select,
  chakraComponents,
} from 'chakra-react-select';
import React from 'react';
import { Icon } from '../../icon';
import { types } from './duck';

const chakraStyles: ChakraStylesConfig = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    boxShadow: 'base',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    border: 'none',
    width: '100%',
    minWidth: '400px',
    color: '#1E4176',
    fontWeight: 'bold',
    _focus: {
      boxShadow: 'base',
      outline: '4px solid #61BAF4',
      outlineOffset: '-2px',
    },

    '@media only screen and (max-width: 768px)': {
      minWidth: 'calc(100vw - 266px)',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '2px',
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: '0',
    boxShadow: 'base',
    padding: 0,
    minHeight: 'auto',
    height: 'auto',
  }),
  container: (provided) => ({
    ...provided,
    borderRadius: '0',
    boxShadow: 'base',
    backgroundColor: 'white',
    border: 'none',
    color: '#1E4176',
    fontWeight: 'bold',
    margin: 0,
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    minHeight: '38px',
    pl: 5,
    backgroundColor: isSelected ? '#EBF5FF' : 'inherit',
    borderBottom: '0.5px solid #E2E8F0',
    outlineOffset: '-2px',
    ...(isSelected || isFocused ? { outline: '2px solid #61BAF4' } : {}),
    color: '#1E4176',
    _hover: {
      backgroundColor: '#ececec',
    },
    _last: {
      borderBottom: 'none',
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    bg: 'white',
    border: 'none',
  }),
};

const Dropdown: React.FC<types.CaptionProps> = ({
  onChange,
  selectOptions,
  value,
  placeholder = '',
  ...rest
}) => {
  return (
    <div data-testid="dropdown-component">
      <Select
        onChange={onChange}
        value={value}
        options={selectOptions}
        placeholder={placeholder}
        chakraStyles={chakraStyles}
        components={{
          DropdownIndicator: (props) => (
            <chakraComponents.DropdownIndicator {...props}>
              <Icon size="medium" name="down-outlined" />
            </chakraComponents.DropdownIndicator>
          ),
        }}
        {...rest}
      />
    </div>
  );
};

export default Dropdown;
