import { ConfigTypes } from './types';

export const config: ConfigTypes = {
  'item-scores-by-tag-by-user': {
    gridCol: '202px 175px repeat(10, 35px)',
    gridRow: '147px repeat(10, 40px)',
    cols: [
      {
        rows: [
          { h: '147px', w: '202px' },
          { h: '40px', w: '202px' },
          { h: '40px', w: '202px' },
          { h: '40px', w: '202px' },
          { h: '40px', w: '202px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '175px' },
          { h: '40px', w: '175px' },
          { h: '40px', w: '175px' },
          { h: '40px', w: '175px' },
          { h: '40px', w: '175px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
      {
        rows: [
          { h: '147px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
          { h: '40px', w: '35px' },
        ],
      },
    ],
  },
  'response-analysis-by-item': {
    gridCol: '250px repeat(7, 80px)',
    gridRow: '136px repeat(7, 30px)',
    cols: [
      {
        rows: [
          { h: '136px', w: '250px' },
          { h: '30px', w: '250px' },
          { h: '30px', w: '250px' },
          { h: '30px', w: '250px' },
          { h: '30px', w: '250px' },
        ],
      },
      {
        rows: [
          { h: '136px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
        ],
      },
      {
        rows: [
          { h: '136px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
        ],
      },
      {
        rows: [
          { h: '136px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
        ],
      },
      {
        rows: [
          { h: '136px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
        ],
      },
      {
        rows: [
          { h: '136px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
          { h: '30px', w: '80px' },
        ],
      },
    ],
  },
};
