import { extendTheme } from '@chakra-ui/react';
import {
  colors,
  fontSizes,
  getComponents,
  lineHeights,
  shadows,
  sizes,
} from '@lon/shared/assets';
import { FontFamilyEnum, fontFamilyMapping } from '@lon/shared/constants';
import { ThemeEnum } from '@lon/shared/enums';
import { breakpoints } from './breakpoints';

const theme = extendTheme({
  sizes,
  colors,
  breakpoints,
  fontSizes,
  lineHeights,
  shadows: shadows,
  borders: {
    focusBlue: '4px solid var(--chakra-colors-blue-600)',
  },
});

export const getTheme = ({
  fontFamily,
  currentTheme = ThemeEnum.DEFAULT,
  isKinder = false,
}: {
  fontFamily: FontFamilyEnum;
  currentTheme: ThemeEnum;
  isKinder: boolean;
}) => {
  const defaultFont = isKinder ? FontFamilyEnum.report : FontFamilyEnum.verdana;

  return extendTheme({
    ...theme,
    components: getComponents(currentTheme, isKinder),
    fonts: {
      heading: fontFamilyMapping[fontFamily || defaultFont],
      body: fontFamilyMapping[fontFamily || defaultFont],
    },
  });
};
