import useQueryParams from '../use-query-params';
import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useEffect } from 'react';
import { DEFAULT_PAGINATION_SIZE } from '@lon/shared/constants';

const useQueryWithPagination = <Query = unknown, Variables = unknown>({
  queryHook,
  queryOptions,
  defaultPaginationSize,
}: {
  queryHook: (
    baseOptions: QueryHookOptions<Query, Variables>
  ) => QueryResult<Query, Variables>;
  queryOptions?: QueryHookOptions<Query, Variables>;
  defaultPaginationSize?: number;
}) => {
  const [params] = useQueryParams();
  const page = Number(params.get('pagination.page'));
  const size = Number(params.get('pagination.itemsPerPage'));

  const paginationVariables = {
    perPage: size ? size : defaultPaginationSize || DEFAULT_PAGINATION_SIZE,
    page: page ? page : 1,
  };

  const skip = queryOptions?.skip || false;

  const queryResult = queryHook({
    ...queryOptions,
    variables: {
      ...queryOptions?.variables,
      ...paginationVariables,
    } as Variables,
    fetchPolicy: queryOptions?.fetchPolicy || 'cache-first',
  });

  useEffect(() => {
    if (skip) {
      return;
    }

    if (page || size) {
      queryResult
        .refetch({
          ...(size && { perPage: size }),
          page: page ? page : 1,
        } as Variables)
        .catch(() => {});
    }
  }, [page, size, skip]);

  return queryResult;
};

export default useQueryWithPagination;
