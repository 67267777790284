import { MutableRefObject, createContext, useContext } from 'react';

const StudentAssignmentDetailsContext = createContext<{
  isGoingBack: boolean;
  onSaveCallback(success?: boolean): void;
  isGoingBackRef: MutableRefObject<boolean>;
}>({
  isGoingBack: false,
  isGoingBackRef: { current: false },
  onSaveCallback: () => {},
});

export const useStudentAssignmentDetailsContext = () =>
  useContext(StudentAssignmentDetailsContext);

export const StudentAssignmentDetailsProvider =
  StudentAssignmentDetailsContext.Provider;
