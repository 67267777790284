import { isDevMode } from '@lon/shared/constants';
import { ApplicationEnum } from '@lon/shared/enums';
import redirect from './redirect';

const redirectToApplication = (
  applicationName: ApplicationEnum,
  impersonatedRedirectUrl?: string
) => {
  const envAdminPath = process.env['NX_ADMIN_URL'];
  const envPlatformAdminPath = process.env['NX_PLATFORM_ADMIN_URL'];
  const envSuitPath = process.env['NX_SUIT_URL'];
  const envMathnationPath = process.env['NX_MATHNATION_URL'];

  const suitPath = isDevMode() ? envSuitPath : '/suite';
  const mathnationPath = isDevMode() ? envMathnationPath : '/mathnation';
  const adminPath = isDevMode() ? envAdminPath : '/admin';
  const platformAdminPath = isDevMode()
    ? envPlatformAdminPath
    : '/platform-admin';

  if (applicationName === ApplicationEnum.ADMIN) {
    redirect(
      impersonatedRedirectUrl
        ? `${adminPath}${impersonatedRedirectUrl}`
        : adminPath
    );
    return;
  }

  if (applicationName === ApplicationEnum.MATHNATION_SUIT) {
    redirect(
      impersonatedRedirectUrl
        ? `${mathnationPath}${impersonatedRedirectUrl}`
        : mathnationPath
    );
    return;
  }

  if (applicationName === ApplicationEnum.PARENT_SUIT) {
    redirect(`${suitPath}/resources`);
    return;
  }

  if (
    [ApplicationEnum.TEACHER_SUIT, ApplicationEnum.STUDENT_SUIT].includes(
      applicationName as ApplicationEnum
    )
  ) {
    redirect(
      impersonatedRedirectUrl
        ? `${suitPath}${impersonatedRedirectUrl}`
        : suitPath
    );
    return;
  }

  if (applicationName === ApplicationEnum.PLATFORM_ADMIN) {
    redirect(
      impersonatedRedirectUrl
        ? `${platformAdminPath}${impersonatedRedirectUrl}`
        : platformAdminPath
    );
    return;
  }

  redirect(
    impersonatedRedirectUrl ? `${suitPath}${impersonatedRedirectUrl}` : suitPath
  );
};

export default redirectToApplication;
