import { LearnosityAnswer } from '@lon/shared/types';

const getGradesFromAnswers = (answers: LearnosityAnswer[] | null) => {
  const isGraded = Array.isArray(answers)
    ? answers.every((answer) => typeof answer?.score === 'number')
    : false;
  const grade =
    isGraded && answers !== null
      ? answers.reduce((acc, val) => {
          return acc + Math.round((val.score / val.max_score) * 100);
        }, 0) / answers.length
      : undefined;
  return { grade, isGraded };
};

export default getGradesFromAnswers;
