import { useContext } from 'react';
import { AclContext } from '@lon/shared/contexts';
import { UsePermission } from './duck/types';

export const usePermission: UsePermission = (opts) => {
  const { value: aclApplications } = useContext(AclContext);

  return (
    opts?.reduce<boolean[]>((acc, option) => {
      const { application, permission, module, operation, schoolId } =
        option || {};
      if (!module && operation) {
        throw new Error('Operation cannot be passed without the module');
      }
      if (!application && !permission && module) {
        throw new Error(
          'Module cannot be passed without the application or permission'
        );
      }
      if (!application && schoolId) {
        throw new Error('SchoolId cannot be passed without the application');
      }

      // Check if application exists
      if (application && !module && !operation) {
        const applicationExist = aclApplications.some(
          (aclApplication) => aclApplication.name === application
        );
        acc.push(!!applicationExist);

        return acc;
      }

      // Check if module without school exists
      if (application && module && !schoolId && !operation) {
        const selectedApplications = aclApplications.filter(
          (aclApplication) => aclApplication.name === application
        );

        const moduleExist = selectedApplications.some((aclApp) =>
          aclApp.modules.some((aclModule) => aclModule.name === module)
        );

        acc.push(!!moduleExist);

        return acc;
      }

      // Check if module with school exists
      if (application && module && schoolId && !operation) {
        const selectedApplication = aclApplications.find(
          (aclApplication) => aclApplication.name === application
        );
        const selectedSchool = selectedApplication?.schools.find((aclSchool) =>
          aclSchool.schoolIds.includes(schoolId)
        );

        const moduleExist = selectedSchool?.modules.some(
          (aclModule) => aclModule.name === module
        );

        acc.push(!!moduleExist);

        return acc;
      }

      // Check if operation without school exists
      if (application && module && !schoolId && operation) {
        const selectedApplication = aclApplications.find(
          (aclApplication) => aclApplication.name === application
        );
        const selectedModule = selectedApplication?.modules.find(
          (aclModule) => aclModule.name === module
        );

        const operationExist = selectedModule?.operations.some(
          (aclOperation) => aclOperation.name === operation
        );

        acc.push(!!operationExist);

        return acc;
      }

      // Check if operation without school exists
      if (application && module && schoolId && operation) {
        const selectedApplication = aclApplications.find(
          (aclApplication) => aclApplication.name === application
        );
        const selectedSchool = selectedApplication?.schools.find((aclSchool) =>
          aclSchool.schoolIds.includes(schoolId)
        );
        const selectedModule = selectedSchool?.modules.find(
          (aclModule) => aclModule.name === module
        );

        const operationExist = selectedModule?.operations.some(
          (aclOperation) => aclOperation.name === operation
        );

        acc.push(!!operationExist);

        return acc;
      }

      // Check if permission exists
      if (permission && module && operation) {
        const selectedPermissions = aclApplications.find(
          (aclApplication) => aclApplication.permissionName === permission
        );
        const selectedModule = selectedPermissions?.modules.find(
          (aclModule) => aclModule.name === module
        );

        const permissionExist = selectedModule?.operations.some(
          (aclOperation) => aclOperation.name === operation
        );

        acc.push(!!permissionExist);

        return acc;
      }

      acc.push(false);

      return acc;
    }, []) || []
  );
};
