import { Icon } from '../../../icon';
import { paginationUtils } from '../../duck';
import {
  Button,
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  HStack,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { onNumberInputKeyDown, onNumberInputPaste } from '@lon/shared/utils';
import { types } from './duck';

const Drawer: React.FC<types.DrawerProps> = ({
  total,
  isOpen,
  onClose,
  itemsPerPage,
  currentPage,
  handleChange,
  rowOptions,
}) => {
  const { t } = useTranslation();
  const [rowsCount, setRowsCount] = useState(itemsPerPage);
  const [pageNumber, setPageNumber] = useState<string | number>(currentPage);
  const numberOfPages = Math.ceil(total / rowsCount);
  const isFullHeight = useBreakpointValue({ base: true, lg: false });

  return (
    <ChakraDrawer
      data-testid="pagination-settings"
      isFullHeight={isFullHeight}
      isOpen={isOpen}
      placement="bottom"
      onClose={onClose}
    >
      <DrawerContent color="primary.800">
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid"
          borderColor="secondary.100"
        >
          <Flex align="center">
            <Text variant="n3" whiteSpace="pre-wrap">
              {t('pagination.page')}{' '}
            </Text>
            <Text variant="n2">
              {currentPage} {t('pagination.of')} {numberOfPages}
            </Text>
          </Flex>
          <IconButton
            aria-label={t('pagination.closePaginationSettings')}
            variant="solidDark"
            size="sm"
            boxShadow="none"
            onClick={onClose}
            icon={<Icon size="sm" name="close-outlined" />}
          />
        </DrawerHeader>

        <DrawerBody>
          <VStack spacing="2" mb="4" align="normal">
            <Text as="span" variant="l" id="drawer-show-records">
              {t('pagination.showRecords')}
            </Text>
            <Select
              aria-labelledby="drawer-show-records"
              iconColor="primary.200"
              borderColor="primary.200"
              borderRadius="4px"
              defaultValue={itemsPerPage}
              value={rowsCount}
              autoComplete="off"
              onChange={(e) => setRowsCount(Number(e.target.value))}
            >
              {rowOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </VStack>

          <VStack spacing="2" align="normal">
            <Text variant="l" whiteSpace="nowrap" id="drawer-page-jumper">
              {t('pagination.goToPage')}
            </Text>
            <HStack spacing="4">
              <NumberInput
                aria-labelledby="drawer-page-jumper"
                size="md"
                w="full"
                min={1}
                max={numberOfPages}
                inputMode="numeric"
                onKeyDown={onNumberInputKeyDown}
                onPaste={onNumberInputPaste}
                onBlur={(event) => setPageNumber(event.target.value)}
              >
                <NumberInputField />
              </NumberInput>
              <Button
                variant="solid"
                onClick={() =>
                  paginationUtils.validatePageNumber({
                    pageNumber: pageNumber || currentPage,
                    max: numberOfPages,
                    successCallback: (page) => {
                      handleChange({ itemsPerPage: rowsCount, page });
                      onClose();
                    },
                  })
                }
              >
                <Text textTransform="uppercase">{t('pagination.go')}</Text>
              </Button>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
