import { useContext, useEffect, useState } from 'react';
import { isDevMode } from '@lon/shared/constants';
import { RefetchContext } from './context';

export const useFetchImage = (
  image?: string,
  options?: RequestInit
): string | undefined => {
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const context = useContext(RefetchContext);

  useEffect(() => {
    const controller = new AbortController();

    const fetchImage = async (controller: AbortController) => {
      if (typeof image !== 'string') {
        return;
      }
      const url = image.startsWith('https') ? image : `https://${image}`;
      // const myRequest = new Request(url);

      try {
        const response = await fetch(url, {
          credentials: 'include',
          signal: controller.signal,
          ...options,
        });

        if (response.status === 401) {
          if (context.attempts < 3) {
            context.setAttempts(context.attempts + 1);
            context.refetch();
          } else {
            setImageUrl(undefined);
          }
        } else if (!isDevMode() && !url.includes(window.location.hostname)) {
          setImageUrl(url);
        } else {
          const imageBlob = await response.blob();
          const imageSrc = URL.createObjectURL(imageBlob);

          setImageUrl(imageSrc);
        }
      } catch (e) {
        setImageUrl(undefined);
      }
    };

    fetchImage(controller);

    return () => {
      controller.abort();
    };
  }, [context.attempts]);

  return imageUrl;
};
