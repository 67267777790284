import { Heading, ModalHeader, ModalHeaderProps, Text } from '@chakra-ui/react';

export interface HeaderProps extends ModalHeaderProps {
  children: React.ReactNode | string;
}

const Header: React.FC<HeaderProps> = ({ children, ...rest }) => {
  return (
    <ModalHeader {...rest}>
      {typeof children === 'string' ? (
        <Heading>
          <Text variant="h3" textTransform="capitalize">
            {children}
          </Text>
        </Heading>
      ) : (
        children
      )}
    </ModalHeader>
  );
};

export default Header;
