import { useAuth as sharedAuthHook } from '@lon/shared/hooks';

export const authLibrary = {
  'platform-admin': sharedAuthHook,
  staff: sharedAuthHook,
  parent: sharedAuthHook,
  student: sharedAuthHook,
  admin: sharedAuthHook,
  other: sharedAuthHook,
};
