import { GridItem, SimpleGrid } from '@chakra-ui/react';
import React, { memo } from 'react';
import { NoContent } from '@lon/suit/components';
import { CardSkeletons, ListItemSceleton } from '@lon/suit/components';
import { useTranslation } from 'react-i18next';
import { types } from './duck';
import { CardItem, ListItem } from './components';

const List: React.FC<types.ListProps> = ({
  data,
  loading = false,
  skeletonsCount = 1,
  type,
  gridGap = 4,
  itemPath,
}) => {
  const { t } = useTranslation();

  if (loading && typeof loading !== 'boolean') {
    return loading;
  }

  if (!loading && data?.length === 0) {
    return (
      <NoContent
        image="no-search"
        imageAlt={t('scopes.noScopes')}
        description={t('scopes.noScopes')}
        containerProps={{ p: 2 }}
      />
    );
  }

  return (
    <SimpleGrid
      as="ul"
      gap={gridGap}
      position="relative"
      mb={4}
      listStyleType="none"
      columns={type === 'list' ? 1 : { base: 1, b375: 2, md: 3, lg: 4 }}
      role="log"
      aria-live="polite"
      aria-relevant="additions"
    >
      {loading
        ? new Array(skeletonsCount)
            .fill(
              type === 'tile' ? <CardSkeletons.Medium /> : <ListItemSceleton />
            )
            .map((item, index) => (
              <GridItem as="li" width="100%" key={index}>
                {item}
              </GridItem>
            ))
        : data?.map((item) =>
            type === 'tile' ? (
              <CardItem scope={item} key={item.id} itemPath={itemPath} />
            ) : (
              <ListItem scope={item} key={item.id} />
            )
          )}
    </SimpleGrid>
  );
};

export default memo(List);
