import { t } from 'i18next';
import { GetMessageData } from './types';

export const getMessageData: GetMessageData = ({
  isStartDateToday,
  isStartDateMissed,
  isDueDateMissed,
}) => {
  switch (true) {
    case isStartDateToday:
      return {
        variant: 'info',
        title: t('assessmentDetails.startAssessment.stardDateTodayTitle'),
        body: t('assessmentDetails.startAssessment.stardDateTodayBody'),
        startButtonVariant: 'solid',
      };
    case isDueDateMissed:
      return {
        variant: 'formError',
        title: t('assessmentDetails.startAssessment.dueDateMissedTitle'),
        body: t('assessmentDetails.startAssessment.dueDateMissedBody'),
        startButtonVariant: 'errorMessage',
      };
    case isStartDateMissed:
      return {
        variant: 'formWarning',
        title: t('assessmentDetails.startAssessment.stardDateMissedTitle'),
        body: t('assessmentDetails.startAssessment.stardDateMissedBody'),
        startButtonVariant: 'warningMessage',
      };
    default:
      return {
        variant: 'info',
        body: t('assessmentDetails.startAssessment.beforeStartDate'),
        startButtonVariant: 'solid',
      };
  }
};
