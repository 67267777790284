import {
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const DigitalNotebookPopover: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Popover
      placement="bottom-end"
      variant="tableLegend"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button variant="text">
          <Text as="span" isTruncated variant="su4">
            {t('createAssignmentDrawer.digitalNotebookPopoverButton')}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent data-focus-visible-disabled w="400px" textAlign="left">
        <PopoverHeader>
          <Text variant="s1">
            {t('createAssignmentDrawer.digitalNotebookPopoverTitle')}
          </Text>
          <PopoverCloseButton />
        </PopoverHeader>
        <PopoverBody>
          <Text variant="s2">
            {t('createAssignmentDrawer.digitalNotebookPopoverBody')}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default DigitalNotebookPopover;
