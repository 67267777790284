import { Asterisk } from '../../asterisk';
import { Icon } from '../../icon';
import { Input } from '../../inputs/select/components';
import DropdownIndicator from '../../inputs/select/components/DropdownIndicator';
import { styles } from '../../inputs/select/duck';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ActionMeta, components } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { MultiSelectOption } from '@lon/shared/types';
import { AsyncPaginatedSelectProps } from './duck/types';

const ControlledInput = <
  // eslint-disable-next-line
  TOption extends any = MultiSelectOption,
  IsMulti extends boolean = false
>({
  isDisabled,
  label,
  isRequired,
  isMulti,
  menuPlacement = 'auto',
  testId,
  defaultValue,
  formControlProps,
  formLabelProps,
  topRightLabel,
  isDisabledStable,
  showClearIndicator = false,
  variant = 'base',
  iconVariant,
  withoutDropdownIndicator,
  loadOptions,
  id,
  onChange,
  value,
  ...rest
}: Omit<
  AsyncPaginatedSelectProps<TOption, IsMulti>,
  'name'
>): React.ReactElement => {
  const customStyles = isDisabledStable
    ? styles.disabledStableCustomStyles
    : styles.getCustomStyles({
        disabled: isDisabled,
        variant,
        showClearIndicator,
      });

  return (
    <FormControl
      isDisabled={isDisabled}
      isRequired={isRequired}
      data-testid={testId}
      {...formControlProps}
    >
      <Flex justifyContent="space-between" direction="row" alignItems="center">
        {label && (
          <FormLabel
            htmlFor={id}
            requiredIndicator={<Asterisk />}
            {...formLabelProps}
          >
            {label}
          </FormLabel>
        )}
        {topRightLabel && topRightLabel}
      </Flex>
      <AsyncPaginate
        defaultValue={defaultValue}
        inputId={id}
        menuPlacement={menuPlacement}
        styles={customStyles}
        value={value}
        onChange={onChange}
        isDisabled={isDisabled}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        loadOptions={loadOptions}
        menuPortalTarget={document.body}
        components={{
          DropdownIndicator: withoutDropdownIndicator
            ? null
            : (props) => (
                <DropdownIndicator {...props} iconVariant={iconVariant} />
              ),
          Input,
          MultiValueRemove: (props) => {
            return (
              <Flex alignItems="center">
                <components.MultiValueRemove {...props}>
                  <IconButton
                    size="xs"
                    aria-label="delete"
                    icon={<Icon name="deleteCircleOutlined" />}
                    variant="round"
                    minW="unset"
                    height="unset"
                    background="unset"
                    backgroundColor="unset"
                    _hover={{ backgroundColor: 'unset' }}
                  />
                </components.MultiValueRemove>
              </Flex>
            );
          },
          ClearIndicator: (props) => {
            const { innerProps, getStyles } = props;

            return (
              <IconButton
                {...(innerProps as any)}
                style={getStyles('clearIndicator', props)}
                size="sm"
                aria-label="Clear"
                icon={<Icon name="close-outlined" />}
                variant="ghost"
              />
            );
          },
        }}
        {...rest}
      />
    </FormControl>
  );
};

const FormInput = <
  // eslint-disable-next-line
  TOption extends any = MultiSelectOption,
  IsMulti extends boolean = false
>({
  isDisabled,
  name,
  label,
  isRequired,
  isMulti,
  menuPlacement = 'auto',
  testId,
  defaultValue,
  formControlProps,
  formLabelProps,
  topRightLabel,
  isDisabledStable,
  showClearIndicator = false,
  variant = 'base',
  iconVariant,
  withoutDropdownIndicator,
  loadOptions,
  onChange: onAsyncSelectChange,
  returnObject,
  ...rest
}: AsyncPaginatedSelectProps<TOption, IsMulti>): React.ReactElement => {
  const { control } = useFormContext();
  const {
    field: { onBlur, onChange: onChangeHookForm, name: fieldName, value },
    formState: { errors },
  } = useController({
    name: name || '',
    control,
    defaultValue,
  });

  const errorMessage = get(errors, `${fieldName}.message`) as unknown as string;

  const customStyles = isDisabledStable
    ? styles.disabledStableCustomStyles
    : styles.getCustomStyles({
        disabled: isDisabled,
        errorMessage,
        variant,
        showClearIndicator,
      });

  const changeHandler = (values: any, meta: ActionMeta<any>) => {
    const plainValue = Array.isArray(values)
      ? values.map((value) => value.value)
      : values?.value;
    const returnedValue = returnObject ? values : plainValue;

    onChangeHookForm(returnedValue);
    onAsyncSelectChange && onAsyncSelectChange(returnedValue);
  };

  return (
    <FormControl
      isDisabled={isDisabled}
      isInvalid={!!errorMessage}
      isRequired={isRequired}
      data-testid={testId}
      {...formControlProps}
    >
      <Flex justifyContent="space-between" direction="row" alignItems="center">
        {label && (
          <FormLabel
            htmlFor={name}
            requiredIndicator={<Asterisk />}
            color="primary.800"
            {...formLabelProps}
          >
            {label}
          </FormLabel>
        )}
        {topRightLabel && topRightLabel}
      </Flex>
      <AsyncPaginate
        defaultValue={defaultValue}
        inputId={name}
        menuPlacement={menuPlacement}
        styles={customStyles}
        name={name}
        value={value}
        onChange={changeHandler}
        onBlur={onBlur}
        isDisabled={isDisabled}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        loadOptions={loadOptions}
        components={{
          DropdownIndicator: withoutDropdownIndicator
            ? null
            : (props) => (
                <DropdownIndicator {...props} iconVariant={iconVariant} />
              ),
          Input,
          MultiValueRemove: (props) => {
            return (
              <Flex alignItems="center">
                <components.MultiValueRemove {...props}>
                  <IconButton
                    size="xs"
                    aria-label="delete"
                    icon={<Icon name="deleteCircleOutlined" />}
                    variant="round"
                    minW="unset"
                    height="unset"
                    background="unset"
                    backgroundColor="unset"
                    _hover={{ backgroundColor: 'unset' }}
                  />
                </components.MultiValueRemove>
              </Flex>
            );
          },
          ClearIndicator: (props) => {
            const { innerProps, getStyles } = props;

            return (
              <IconButton
                {...(innerProps as any)}
                style={getStyles('clearIndicator', props)}
                size="sm"
                aria-label="Clear"
                icon={<Icon name="close-outlined" />}
                variant="ghost"
              />
            );
          },
        }}
        {...rest}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

const AsyncPaginatedSelect: React.FC<AsyncPaginatedSelectProps> = ({
  name,
  value,
  ...rest
}) => {
  if (name) {
    return <FormInput name={name} {...rest} />;
  }

  return <ControlledInput {...rest} value={value} />;
};

export default AsyncPaginatedSelect;
