import { KeyDownProps } from './types';

export const keyDown = ({
  event,
  position,
  itemsCount,
  isSelectedItem,
  setSelectedIndex,
  setFocusIndex,
  firstElementRef,
  lastElementRef,
}: KeyDownProps) => {
  switch (event.code) {
    case 'Space':
    case 'Enter': {
      if (!isSelectedItem) {
        event.preventDefault();
        setSelectedIndex(position);
        firstElementRef.current?.focus();
      }
      break;
    }
    case 'Tab': {
      const isShiftTab = event.shiftKey;
      const isTab = !event.shiftKey;

      if (isShiftTab) {
        const isNotFirstItem = position > 0;
        const isFirstItem = position === 0;
        const isFirstElementOfTheSelectedItem =
          isSelectedItem && firstElementRef.current === event.target;

        if (
          isNotFirstItem &&
          (!isSelectedItem || isFirstElementOfTheSelectedItem)
        ) {
          setFocusIndex(position - 1);
          setSelectedIndex(null);
        }

        if (isFirstItem && isFirstElementOfTheSelectedItem) {
          setSelectedIndex(null);
        }
      }

      if (isTab) {
        const isNotLastItem = position < itemsCount - 1;
        const isLastItem = position === itemsCount - 1;
        const isLastElementOfTheSelectedItem =
          isSelectedItem && lastElementRef.current === event.target;

        if (
          isNotLastItem &&
          (!isSelectedItem || isLastElementOfTheSelectedItem)
        ) {
          setFocusIndex(position + 1);
          setSelectedIndex(null);
        }

        if (isLastItem && isLastElementOfTheSelectedItem) {
          setSelectedIndex(null);
        }
      }

      break;
    }
  }
};
