import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useGetSchoolIdsForPlanner as useGetSchoolIds } from '@lon/shared/hooks';
import {
  GetGradesDocument,
  GetGradesQuery,
  GetGradesQueryVariables,
} from '@lon/shared/requests';

export const useGetGrades = () => {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const accessibleSchoolIds = useGetSchoolIds();
  const [grades, setGrades] = useState<
    undefined | NonNullable<GetGradesQuery['grades']>['grades']
  >(undefined);

  useEffect(() => {
    if (accessibleSchoolIds?.length) {
      Promise.all(
        accessibleSchoolIds.map((id) => {
          return client.query<GetGradesQuery, GetGradesQueryVariables>({
            query: GetGradesDocument,
            variables: {
              schoolId: id,
            },
          });
        })
      )
        .then((res) => {
          const grades = res?.reduce(
            (acc, res) => [...acc, ...(res.data.grades?.grades || [])],
            [] as NonNullable<GetGradesQuery['grades']>['grades']
          );

          setGrades([...new Set(grades)]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [accessibleSchoolIds]);

  return { grades, loading };
};
