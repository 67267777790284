export const Modal = {
  defaultProps: {
    variant: 'base',
  },
  baseStyle: {
    overlay: {
      bg: 'grayAlpha.500',
      zIndex: 9999,
    },
    dialogContainer: {
      zIndex: 9999,
    },
    closeButton: {
      top: '20px',
      right: '20px',
    },
  },
  variants: {
    base: {
      dialog: {
        color: 'primary.800',
        borderRadius: '1px',
        boxShadow: '0px 3px 20px 15px rgba(43, 54, 70, 0.2)',
        maxW: '500px',
      },
      header: {
        py: '5',
        px: '8',
        maxHeight: '76px',
        borderBottom: '1px solid #e1e7f0',
      },
      body: {
        px: '8',
        py: '6',
      },
      footer: {
        px: '8',
        py: '6',
        borderTop: '1px solid #e1e7f0',
      },
    },
    secondary: {
      dialog: {
        bg: 'gray.600',
        padding:
          'var(--padding-6) var(--padding-5) var(--padding-5) var(--padding-5)',
      },
    },
    simple: {
      dialog: {
        borderRadius: '1px',
        boxShadow: '0px 3px 20px 15px rgba(43, 54, 70, 0.2)',
        maxW: '500px',
      },
      header: {
        py: '5',
        px: '8',
        maxHeight: '76px',
      },
      body: {
        px: '8',
        py: '6',
        display: 'flex',
        flexDirection: 'column',
      },
      footer: {
        px: '8',
        py: '6',
        borderTop: '1px solid #e1e7f0',
      },
    },
    simpleWithPortal: {
      dialogContainer: {
        color: 'primary.800',
        w: '100%',
      },
      dialog: {
        borderRadius: '1px',
        boxShadow: '0px 3px 20px 15px rgba(43, 54, 70, 0.2)',
        maxW: '500px',
        alignSelf: 'flex-end',
        mb: '116px',
      },
      header: {
        py: '5',
        px: '8',
        maxHeight: '76px',
      },
      body: {
        px: '8',
        py: '6',
        display: 'flex',
        flexDirection: 'column',
      },
      footer: {
        px: '8',
        py: '6',
        borderTop: '1px solid #e1e7f0',
      },
    },
    formDrawerModal: {
      dialog: {
        width: { base: '100vw', md: '435px' },
        maxWidth: { base: '100vw', md: '435px' },
        marginBottom: 24,
        alignSelf: 'flex-end',
        boxShadow: 'unset',
      },
      dialogContainer: {
        justifyContent: 'flex-end',
        padding: { base: '0', md: '0px 32px' },
      },
      body: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    bigFormConfirmModal: {
      dialog: {
        marginBottom: '100px',
        marginRight: { lg: '250px' },
        borderRadius: '1px',
        boxShadow: '0px 3px 20px 15px rgba(43, 54, 70, 0.2)',
        maxW: '500px',
      },
      dialogContainer: {
        justifyContent: { base: 'center', lg: 'end' },
        alignItems: 'end',
      },
    },
    rounded: {
      dialog: {
        color: 'primary.800',
        maxW: '650px',
        borderRadius: '30px',
        borderWidth: '15px',
        borderColor: 'secondary.50',
        boxShadow: '0px 3px 20px 15px rgba(43, 54, 70, 0.2)',
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        py: '4',
        px: '8',
        borderBottom: '1px solid #e1e7f0',
        fontWeight: 700,
      },
      closeButton: {
        position: 'unset',
        w: '44px',
        h: '44px',
      },
      body: {
        py: '6',
        px: '8',
      },
      footer: {
        py: '6',
        px: '8',
        borderTop: '1px solid #e1e7f0',
        justifyContent: 'space-between',
      },
    },
    confirmation: {
      dialogContainer: {
        color: 'primary.800',
        w: '100%',
      },
      dialog: {
        p: 6,
        gap: 4,
      },
      body: {
        p: 6,
        borderRadius: '6px',
      },
      footer: {
        borderTop: 0,
        p: 0,
        justifyContent: 'space-between',
      },
    },
  },
};
