import { Flex, Icon, Tag, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { auditOutlined, checkSimpleOutlined } from '@lon/shared/assets';
import { Message } from '@lon/shared/components';

const InfoMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Message
      variant="info"
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      title={
        <Text variant="s1" fontStyle="italic">
          {t('resetAssignmentModal.infoMessageTitle')}
        </Text>
      }
      message={
        <Flex direction="column" gap="4">
          <Flex gap="4">
            <Tag
              variant="solid"
              display="flex"
              gap="1"
              backgroundColor="calendar.blue.800"
              color="white"
            >
              <Icon as={auditOutlined} w="3" h="3" />
              <Text variant="tagXs" textTransform="uppercase">
                {t('resetAssignmentModal.toBeGraded')}
              </Text>
            </Tag>
            <Tag
              variant="solid"
              display="flex"
              gap="1"
              backgroundColor="success.800"
              color="white"
            >
              <Icon as={checkSimpleOutlined} w="3" h="3" />
              <Text variant="tagXs" textTransform="uppercase">
                {t('resetAssignmentModal.graded')}
              </Text>
            </Tag>
          </Flex>
          <Text variant="s3">{t('resetAssignmentModal.infoMessageBody')}</Text>
        </Flex>
      }
    />
  );
};

export default InfoMessage;
