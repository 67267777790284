import states from 'states-us';
export const stateList = () => {
  const statesOptions = states.map((v) => ({
    value: v.abbreviation,
    label: v.abbreviation === 'DC' ? 'Washington, DC' : v.name,
    territory: v.territory,
  }));

  return statesOptions
    .filter((x) => !x.territory)
    .sort((a, b) => {
      const first = a.label;
      const second = b.label;
      return first.localeCompare(second);
    });
};
