import { useEffect } from 'react';
import { useQueryParams } from '@lon/shared/hooks';

export const useCheckCurrentPage = (originalActivityItemsLength: number) => {
  const [params, setParams] = useQueryParams();

  useEffect(() => {
    const isLessThanMinValue = Number(params.questionNumber) < 1;
    const isInvalidValue =
      params.questionNumber && !Number(params.questionNumber);
    const isGreaterThanMaxValue =
      originalActivityItemsLength &&
      Number(params.questionNumber) > originalActivityItemsLength;

    if (isLessThanMinValue || isInvalidValue) {
      setParams({
        questionNumber: 1,
      });
    } else if (isGreaterThanMaxValue) {
      setParams({
        questionNumber: originalActivityItemsLength,
      });
    }
  }, [params.questionNumber, originalActivityItemsLength]);
};
