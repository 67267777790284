import { IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';

const ContentMenu: React.FC<any> = ({ iframeRef }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      variant="dark"
      label={t('helpPage.openContentMenu')}
      aria-hidden={true}
      placement="bottom"
      hasArrow={false}
    >
      <IconButton
        icon={<Icon name="unordered-list-outlined" />}
        aria-label="content-menu"
        variant="ghost"
      />
    </Tooltip>
  );
};

export default ContentMenu;
