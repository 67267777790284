import SessionExpiredModal from '../session-expired';
import UpdatePassword from '../update-password';
import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { MODALS } from '@lon/shared/constants';
import { OPENED_MODAL } from '@lon/shared/utils';

const ModalContainer: React.FC<{
  withoutSessionExpired?: boolean;
  withoutUpdatePassword?: boolean;
}> = ({ withoutSessionExpired = false, withoutUpdatePassword = false }) => {
  const currentModal = useReactiveVar(OPENED_MODAL);

  if (currentModal === MODALS.PasswordUpdate && !withoutUpdatePassword) {
    return <UpdatePassword />;
  }

  if (currentModal === MODALS.SessionExpired && !withoutSessionExpired) {
    return <SessionExpiredModal />;
  }

  return null;
};

export default ModalContainer;
