import { ActionType, createReducer } from 'typesafe-actions';
import { Auth, User } from '@lon/shared/types';
import * as actions from './actions';

type AuthActions = ActionType<typeof actions>;

const initialState: Auth = {
  isLoggedIn: false,
  isAuthenticating: true,
  user: {} as User,
};

const reducer = createReducer<Auth, AuthActions>(initialState)
  .handleAction(actions.setAuth, (state, { payload: newAuth }) => ({
    ...state,
    ...newAuth,
  }))
  .handleAction(
    actions.setAccessibilitySettings,
    (state, { payload: newAcc }) => ({
      ...state,
      user: {
        ...state.user,
        ...newAcc,
      },
    })
  );

export default reducer;
