import * as actions from './actions';
import * as Types from './types';

export const navigateNext = <T extends { disabled: boolean } = any>(
  list: T[],
  startIndex: number
): number => {
  for (let i = startIndex + 1; i < list.length; i += 1) {
    if (list[i] && !list[i].disabled) {
      return i;
    }
  }
  for (let i = 0; i < startIndex; i += 1) {
    if (list[i] && !list[i].disabled) {
      return i;
    }
  }

  return startIndex;
};

export const navigatePrev = <T extends { disabled: boolean } = any>(
  list: T[],
  startIndex: number
): number => {
  for (let i = startIndex - 1; i >= 0; i -= 1) {
    if (list[i] && !list[i].disabled) {
      return i;
    }
  }
  for (let i = list.length - 1; i >= startIndex; i -= 1) {
    if (list[i] && !list[i].disabled) {
      return i;
    }
  }
  return startIndex;
};

export const focusChild: Types.FocusChild = (index, state) => {
  if (!state.items.length) {
    return;
  }
  const { ref } = state.items[index];

  if (ref.current) {
    const menuItem = ref.current.firstElementChild as HTMLElement;
    menuItem.focus();
  }
};

export const setActiveIndex: Types.SetActiveIndex = (
  direction,
  state,
  dispatch
) => {
  let newIndex = 0;

  if (direction === 'previous') {
    newIndex = navigatePrev(state.items, state.activeIndex);
  } else {
    newIndex = navigateNext(state.items, state.activeIndex);
  }

  dispatch(actions.setActiveIndex(newIndex));
  focusChild(newIndex, state);
};

export const returnFocus: Types.ReturnFocus = (direction, state, dispatch) => {
  if (!direction) {
    focusChild(state.activeIndex, state);
  } else {
    setActiveIndex(direction, state, dispatch);
  }
};
