import {
  Box,
  Button,
  Flex,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';
import { PlannerNotificationPopupPropTypes } from './duck';

const PlannerNotificationPopup: React.FC<PlannerNotificationPopupPropTypes> = ({
  isOpen,
  onClose,
  heading,
  notifications,
}) => {
  const { t } = useTranslation();

  if (!notifications?.length) {
    return;
  }

  return (
    <Modal
      variant="simple"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent alignSelf="center">
        <ModalBody px="6" pt="6" pb="4">
          <Flex
            borderRadius="6"
            background="#eff8ff"
            padding="6"
            gap="3"
            flexDirection="column"
          >
            {heading && (
              <Text variant="h5" color="primary.800">
                {heading}
              </Text>
            )}
            <Box
              overflowY="auto"
              maxH="calc(100vh - 22rem)"
              py="2"
              sx={sxLightScrollBar}
            >
              <List gap="2" display="flex" flexDirection="column">
                {notifications?.map((notification, index) => {
                  return (
                    <ListItem key={index}>
                      <Text variant="n3" color="primary.800">
                        {notification}
                      </Text>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter borderTopWidth="0" px="6" pt={{ base: '4', md: '0' }}>
          <Button variant="solid" onClick={onClose}>
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PlannerNotificationPopup;
