import styles from '../../AssignmentGrades.module.css';
import { Flex, MenuItem, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { ShareResults } from '@lon/shared/gql/types';
import { clearQueryCache } from '@lon/shared/utils';
import { ShareDistrictAssignmentResultsModal } from '@lon/suit/components';
import { PreparedDistrictAssignmentData } from '@lon/suit/types';

const ShareAssignmentResults: React.FC<{
  isGroupActions?: boolean;
  selectedStudentAssignments: PreparedDistrictAssignmentData[];
}> = ({ selectedStudentAssignments, isGroupActions }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isDisabled = selectedStudentAssignments.every(
    (selectedStudentAssignment) =>
      selectedStudentAssignment.shareResults !== ShareResults.Disabled
  );

  return (
    <>
      <Tooltip
        isDisabled={isDisabled}
        variant="dark"
        label={
          isGroupActions
            ? t('assignments.multipleShareAssignmentResultsTooltip')
            : t('assignments.shareAssignmentResultsTooltip', {
                name: `${selectedStudentAssignments[0]?.firstName} ${selectedStudentAssignments[0]?.lastName}`,
                interpolation: { escapeValue: false },
              })
        }
      >
        <MenuItem isDisabled={isDisabled} p={0} onClick={onOpen}>
          <Flex flex="1" gap={3} py="2" px="6">
            <Icon name="shareResultsOutlined" className={styles.icon} />
            <Text variant="s2">{t('assignments.shareResults')}</Text>
          </Flex>
        </MenuItem>
      </Tooltip>
      <ShareDistrictAssignmentResultsModal
        onClose={onClose}
        isOpen={isOpen}
        studentsData={selectedStudentAssignments}
        onCompleted={() => {
          clearQueryCache(['districtAssignmentsForLeader']);
        }}
      />
    </>
  );
};

export default ShareAssignmentResults;
