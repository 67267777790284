export const generateQuestionHeaders = (reportId: string) => {
  const learnosityQuestions = document
    .getElementById(reportId)
    ?.querySelectorAll('.learnosity-question-container');

  if (!learnosityQuestions) {
    return;
  }

  [...learnosityQuestions].forEach((question, index) => {
    const container =
      question?.querySelector('.lrn_question') ||
      question?.querySelector('.lrn_response_wrapper');

    if (!container) {
      return;
    }

    const header = document.createElement('div');
    header.classList.add('learnosity-question-header');

    const questionTag = document.createElement('div');
    const questionTagContent = document.createElement('span');
    questionTag.appendChild(questionTagContent);
    questionTagContent.textContent = `Question ${index + 1}`;

    questionTag.classList.add('learnosity-tag');
    questionTag.classList.add('learnosity-tag-primary');
    header.appendChild(questionTag);

    container?.parentNode?.insertBefore(header, container);
  });
};

export const hideCorrectAnswers = (reportId: string) => {
  const removeCorrectAnswerStyles = (node: HTMLElement | Element) => {
    node.classList.remove('lrn_correct');
    node.classList.remove('lrn_incorrect');
  };

  const removeMarkersInlineStyles = (node: HTMLElement | Element) => {
    node.classList.remove('lrn_correct_marker');
    node.classList.remove('lrn_incorrect_marker');

    const mask = node.querySelector<HTMLElement>('.lrn_dropzone_mask');

    if (mask) {
      mask.style.backgroundColor = '#fcfcd3';
    }
  };

  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      const { target } = mutation;
      const typedTarget = target as HTMLElement;
      const questions = typedTarget?.querySelectorAll(
        '.lrn_incorrect,.lrn_correct'
      );
      const questionsWithMarkers = typedTarget?.querySelectorAll(
        '.lrn_correct_marker, .lrn_incorrect_marker'
      );

      if (
        typedTarget.classList.contains('lrn_incorrect') ||
        typedTarget.classList.contains('lrn_correct')
      ) {
        removeCorrectAnswerStyles(typedTarget);
      }
      if (questions.length) {
        questions.forEach(removeCorrectAnswerStyles);
      }

      if (
        typedTarget.classList.contains('lrn_correct_marker') ||
        typedTarget.classList.contains('lrn_incorrect_marker')
      ) {
        removeMarkersInlineStyles(typedTarget);
      }
      if (questionsWithMarkers.length) {
        questionsWithMarkers.forEach(removeMarkersInlineStyles);
      }
    }
  });

  const container = document.getElementById(reportId);

  if (container) {
    observer.observe(container, {
      attributes: true,
      attributeFilter: ['class'],
      childList: true,
      subtree: true,
    });
  }

  return observer;
};
