import { RootState } from '@lon/shared/configs';
import { NewPacingGuideType } from '@lon/shared/types';

export const calendarList = (state: RootState) =>
  state.planner.calendarList!.sort((cal) => (cal?.isDefault ? -1 : 1));
export const templateList = (state: RootState) => state.planner.templateList;
export const loadingCalendarList = (state: RootState) =>
  state.planner.loadingCalendarList;
export const calendarSettingsOpened = (state: RootState) =>
  state.planner.calendarSettingsOpened;
export const initUserSelection = (state: RootState) =>
  state.planner.initializeUserSelection;
export const eventList = (state: RootState) => state.planner.eventList;
export const userSelection = (state: RootState) => state.planner.userSelection;
export const userRoleCode = (state: RootState) => state.planner.userRoleCode;
export const successUserSelection = (state: RootState) =>
  state.planner.successUserSelection;
export const calendarSettings = (state: RootState) =>
  state.planner.calendarSettings;
export const calendars = (state: RootState) => state.planner.calendars;
export const editableCalendarList = (state: RootState) =>
  state.planner.calendarList?.filter((calendar) => calendar?.editable);
export const pacingGuideList = (state: RootState) =>
  state.planner.pacingGuideList;
export const pacingGuideListLoading = (state: RootState) =>
  state.planner.pacingGuideListLoading;
export const selectedPacingGuide = (state: RootState) =>
  state.planner.selectedPacingGuide;
export const selectedPacingGuideDetails = (state: RootState) =>
  state.planner.selectedPacingGuideDetails;
export const selectPacingGuideById = (
  state: RootState,
  selectedPacingGuide: NewPacingGuideType
) =>
  state.planner.pacingGuideList?.find(
    (e) => e?._id === selectedPacingGuide?._id
  );
export const plansList = (state: RootState) => state.planner.plans.plansList;
export const selectedPlanId = (state: RootState) =>
  state.planner.plans.selectedPlan;
export const initializeDownloadPdf = (state: RootState) =>
  state.planner.initializeDownloadPdf;
export const currentMonth = (state: RootState) => state.planner.currentMonth;
export const teams = (state: RootState) => state.planner.teams;
export const pacingGuidesData = (state: RootState) =>
  state.planner.pacingGuidesData;

export const planScopes = (state: RootState) =>
  state.planner.plans.selectedPlanScopeElements;
