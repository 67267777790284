import { Breadcrumbs } from '../breadcrumbs';
import { Flex, Image } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { isDevMode } from '@lon/shared/constants';
import { ScopeContext } from '@lon/shared/contexts';
import { ScopeType } from '@lon/shared/types';

const BreadcrumbsContainer: React.FC<{
  activityTimeIconPath?: string;
}> = ({ activityTimeIconPath }) => {
  const { projectType } = useContext(ScopeContext);

  const isDailyNumeracy = projectType === ScopeType.DailyNumeracy;

  const [imgError, setImgError] = useState<boolean>(false);

  const baseUrl = isDevMode()
    ? `https://${window.location.hostname.replace('local.', '')}`
    : '';

  return (
    <Flex
      w="full"
      py="4"
      px="8"
      mb="4"
      gap={{ base: '4', md: '8' }}
      bg="white"
      borderRadius="6"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={{ base: 'wrap', md: 'unset' }}
      boxShadow="pane"
    >
      <Breadcrumbs />
      {!imgError && activityTimeIconPath && !isDailyNumeracy && (
        <Image
          h="32px"
          maxW="150px"
          src={`${baseUrl}${activityTimeIconPath}`}
          onError={() => {
            if (!imgError) {
              setImgError(true);
            }
          }}
        />
      )}
    </Flex>
  );
};

export default BreadcrumbsContainer;
