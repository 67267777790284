export const IconButton = {
  defaultProps: {
    size: 'md',
    colorScheme: 'primary',
    variant: 'outline',
  },
  baseStyle: {
    boxShadow: 'sm',
    boxSizing: 'border-box',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '4px',
    background: 'transparent',
    backgroundColor: 'transparent',
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      '&[not(:focus-visible)]': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    _focusVisible: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  variants: {
    solid: {
      backgroundColor: 'blue.500',
      color: 'white',
      _hover: {
        backgroundColor: 'primary.600',

        _disabled: {
          opacity: 1,
          boxShadow: 'sm',
          backgroundColor: 'basic.400',
          color: 'white',
        },
      },

      _focusVisible: {
        backgroundColor: 'primary.800',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        textDecoration: 'none',
      },
      _active: {
        backgroundColor: 'primary.700',
        color: 'white',
      },
      _disabled: {
        opacity: 1,
        boxShadow: 'sm',
        backgroundColor: 'basic.400',
        color: 'white',
      },
    },
    outline: {
      backgroundColor: 'white',
      color: 'primary.800',
      border: '2px solid var(--chakra-colors-primary-800)',
      _hover: {
        backgroundColor: 'primary.600',
        color: 'white',
        border: '2px solid var(--chakra-colors-primary-600)',

        _disabled: {
          opacity: 1,
          border: '2px solid',
          borderColor: 'secondary.400',
          backgroundColor: 'white',
          color: 'secondary.400',
        },
      },

      _focusVisible: {
        backgroundColor: 'primary.300',
        color: 'white',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        border: '2px solid var(--chakra-colors-primary-800)',
        textDecoration: 'none',
      },
      _active: {
        backgroundColor: 'primary.700',
        color: 'white',
        border: '2px solid var(--chakra-colors-primary-700)',
      },

      _disabled: {
        opacity: 1,
        border: '2px solid',
        borderColor: 'secondary.400',
        backgroundColor: 'white',
        color: 'secondary.400',
      },
    },
    solidDark: {
      backgroundColor: 'white',
      color: 'blue.500',
      _hover: {
        backgroundColor: 'primary.100',
        _disabled: {
          backgroundColor: 'primary.400',
          color: 'primary.100',
        },
      },
      _focusVisible: {
        backgroundColor: 'primary.100',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        textDecoration: 'none',
      },
      _active: {
        backgroundColor: 'primary.200',
      },
      _disabled: {
        backgroundColor: 'primary.400',
        color: 'primary.100',
      },
    },
    outlineDark: {
      backgroundColor: 'blue.500',
      border: '2px solid var(--chakra-colors-white)',
      _hover: {
        backgroundColor: 'primary.100',
        color: 'blue.500',
        border: '2px solid var(--chakra-colors-primary-100)',
        _disabled: {
          backgroundColor: 'blue.500',
          color: 'primary.200',
          border: '2px solid var(--chakra-colors-primary-200)',
        },
      },
      _focusVisible: {
        backgroundColor: 'primary.100',
        color: 'blue.500',
        border: '2px solid var(--chakra-colors-blue-500)',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        textDecoration: 'none',
      },
      _active: {
        backgroundColor: 'primary.200',
        color: 'blue.500',
        border: '2px solid var(--chakra-colors-primary-200)',
      },
      _disabled: {
        backgroundColor: 'blue.500',
        color: 'primary.200',
        border: '2px solid var(--chakra-colors-primary-200)',
      },
    },
    ghost: {
      px: 2,
      py: 1,
      boxShadow: 'none',
      color: 'primary.800',

      _hover: {
        background: 'blue.150',
        borderColor: 'transparent',
      },
    },
    danger: {
      backgroundColor: 'danger.100',
      color: 'danger.800',
      border: '2px solid var(--chakra-colors-danger-800)',
      _hover: {
        backgroundColor: 'danger.400',
        color: 'danger.800',
        border: '2px solid var(--chakra-colors-danger-400)',
        _disabled: {
          backgroundColor: 'secondary.300',
          color: 'primary.400',
        },
      },
      _focusVisible: {
        backgroundColor: 'danger.400',
        color: 'danger.800',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        border: '2px solid var(--chakra-colors-danger-800)',
        textDecoration: 'none',
      },
      _active: {
        backgroundColor: 'danger.400',
        color: 'danger.800',
        border: '2px solid var(--chakra-colors-danger-400)',
      },
      _disabled: {
        backgroundColor: 'secondary.300',
        color: 'primary.400',
      },
    },
  },
  sizes: {
    sm: {
      minHeight: '32px',
      minWidth: '32px',
      paddingX: 3,
      paddingY: 1.5,
      fontSize: 'sm',
      lineHeight: '5',
    },
    md: {
      minHeight: '44px',
      minWidth: '44px',
      paddingX: 4,
      paddingY: 3,
      fontSize: 'md',
      lineHeight: '5',
    },
    lg: {
      minHeight: '50px',
      minWidth: '50px',
      paddingX: 6,
      paddingY: 3.5,
      fontSize: 'lg',
      lineHeight: '1.375rem',
    },
    xl: {
      minHeight: '56px',
      minWidth: '56px',
      paddingX: 8,
      paddingY: 4,
      fontSize: 'xl',
      lineHeight: '6',
    },
  },
};
