import 'focus-visible';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getCheckbox = (theme: ThemeEnum) => ({
  baseStyle: {
    icon: {
      w: '20px',
      h: '20px',
    },
    container: {
      '&.chakra-checkbox': {
        mb: 0,
      },

      '& :focus:not(.focus-visible), & :focus:not(.focus-visible) + [data-focus]':
        {
          outline: 'none',
          boxShadow: 'none',
        },

      '&[data-disabled-stable="true"]': {
        cursor: 'default',

        '& .chakra-checkbox__control': {
          bg: 'secondary.100',
          borderColor: 'primary.100',
          color: 'primary.800',
        },

        '& .chakra-checkbox__label': {
          opacity: 1,
        },
      },
    },
    control: {
      border: '1px',
      borderColor: getThemeStyleValue('primary.800', 'white')(theme),
      bgColor: getThemeStyleValue('white', 'secondary.1000')(theme),
      borderRadius: 'base',
      _indeterminate: {
        background: getThemeStyleValue('blue.500', 'secondary.1000')(theme),
        borderColor: getThemeStyleValue('blue.500', 'white')(theme),
      },
      _checked: {
        bgColor: getThemeStyleValue('blue.500', 'secondary.1000')(theme),
        borderColor: getThemeStyleValue('blue.500', 'white')(theme),
        _hover: {
          bgColor: getThemeStyleValue('blue.600', 'secondary.1000')(theme),
          borderColor: getThemeStyleValue('blue.600', 'white')(theme),
        },
        _disabled: {
          bg: 'secondary.400',
          color: 'white',
        },
      },
      _disabled: {
        borderColor: 'gray.300',
        bg: 'gray.200',
      },
    },
  },
});
