import { List } from '../../../../../list';
import styles from '../../Section.module.css';
import { Box } from '@chakra-ui/react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { types } from './duck';
import { ElementCardItem, Skeleton } from './components';

const LessonScopeElementList: React.FC<types.LessonScopeElementListProps> = ({
  loading,
  elements,
  elementPlacementUrl,
  className,
}) => {
  const { sectionId } = useParams() as {
    sectionId: string;
  };

  return (
    <Box role="tabpanel" id="lesson-scope-elements" aria-labelledby={sectionId}>
      <List
        loading={loading}
        grid="4"
        data={elements}
        mapKey={(element) => element.identifier}
        className={clsx(styles.list, className)}
        renderSkeleton={Skeleton}
        renderItem={(element) => (
          <ElementCardItem
            id={element.identifier}
            sectionId={element.sectionId}
            title={element.metadata?.elementName || ''}
            elementPlacementUrl={elementPlacementUrl}
            curriculumArea={element.metadata?.curriculumArea}
          />
        )}
      />
    </Box>
  );
};

export default LessonScopeElementList;
