import { lazyWithRetry } from '@lon/shared/utils';

export * from './student';
export * from './show';
export * from './students';
export * from './assignments';
export * from './assignment';
export * from './student-assignments';
export * as ReportDashboard from './report-dashboard';
export { Grading } from './student-assignment-details/components';

const StudentAssignmentDetails = lazyWithRetry(
  () => import('./student-assignment-details'),
  'StudentAssignmentDetails'
);
const ClassesList = lazyWithRetry(() => import('./list'), 'ClassesList');

export { ClassesList, StudentAssignmentDetails };
