import { useEffect } from 'react';
import { clearStorage } from '@lon/shared/utils';

const imageTag = process.env?.['NX_IMAGE_TAG'] || '';

const useInvalidateStorages = () => {
  useEffect(() => {
    const previousImageTag = localStorage.getItem('image-tag');

    if (previousImageTag !== imageTag) {
      clearStorage({
        pathPattern: 'persist:',
      });
    }
    localStorage.setItem('image-tag', imageTag);
  }, []);
};

export default useInvalidateStorages;
