import React from 'react';

type UseWindowSize = (options?: { timeoutMs: number }) => {
  width: number;
  height: number;
};

const useWindowSize: UseWindowSize = (options) => {
  const timeoutRef = React.useRef<number>();
  const [size, setSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    const handleResize = (): void => {
      const update = (): void =>
        setSize({ width: window.innerWidth, height: window.innerHeight });

      if (options) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(update, options.timeoutMs);
      } else {
        update();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return size;
};

export default useWindowSize;
