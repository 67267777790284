import { TABLET_TAB_PANEL_PADDING } from '../../duck/constants';
import { TabProps } from '../../duck/types';
import { useQuery } from '@apollo/client';
import {
  Box,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTab, RequiredNotification } from '@lon/shared/components';
import { WorkingLocation } from '@lon/shared/contexts';
import { QueryClassesArgs } from '@lon/shared/gql/types';
import { useAuth, useMediaQuery } from '@lon/shared/hooks';
import { constants, queries } from './duck';

const ManageAssignees: React.FC<TabProps> = ({
  manageTabIndex,
  setManageTabIndex,
  isDistrictAssessment,
  isDistrictAssessmentForOneSchool,
}) => {
  const { t } = useTranslation();
  const { currentSchoolId } = useContext(WorkingLocation);
  const [{ user }] = useAuth();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [isTablet] = useMediaQuery('(max-width: 1024px)');

  const { data: classesData, loading: classesLoading } = useQuery<
    queries.ClassResponse,
    QueryClassesArgs
  >(queries.GET_CLASSES_FOR_SELECT_INPUT, {
    variables: {
      teachersId: `${user?.userId}`,
      schoolId: currentSchoolId,
    },
    skip: isDistrictAssessment,
  });

  const classesOptions = useMemo(
    () =>
      classesData?.classes?.collection
        ?.filter((cl) => !cl?.archived)
        ?.map((cl) => ({
          label: cl?.name || '',
          value: cl?.id || '',
        }))
        .sort((a, b) => {
          const first = a.label;
          const second = b.label;
          // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
          return first.localeCompare(second, 'en', { numeric: true });
        }) || [],
    [classesData, currentSchoolId]
  );

  return (
    <TabPanel p="0">
      <Box
        textAlign="left"
        pt="6"
        {...(isTablet ? { px: TABLET_TAB_PANEL_PADDING } : { maxW: '700px' })}
      >
        <RequiredNotification />
      </Box>
      <Divider orientation="horizontal" />
      <Tabs
        variant="drawerInternal"
        isFitted
        index={manageTabIndex}
        onChange={setManageTabIndex}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        {!isDistrictAssessmentForOneSchool && (
          <>
            <Flex
              justify="center"
              w="full"
              {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}
            >
              <Box
                color="primary.800"
                w="full"
                {...(!isTablet && { maxW: '700px' })}
              >
                <Text variant="n1" align="left" pb="2">
                  {t('createAssignmentDrawer.assignTo')}
                </Text>
                {isMobile ? (
                  <RadioGroup
                    onChange={(nextValue) => {
                      setManageTabIndex(Number(nextValue));
                    }}
                    value={`${manageTabIndex}`}
                  >
                    <Stack
                      direction="column"
                      borderWidth="1px"
                      borderColor="secondary.200"
                      borderStyle="solid"
                      borderRadius="4px"
                      p="4"
                    >
                      {isDistrictAssessment
                        ? constants.DISTRICT_ASSESSMENT_TABS.map(
                            ({ tab }, index) => (
                              <Radio key={tab} value={`${index}`} gap={3}>
                                {tab}
                              </Radio>
                            )
                          )
                        : constants.TABS.map(({ tab }, index) => (
                            <Radio key={tab} value={`${index}`} gap={3}>
                              {tab}
                            </Radio>
                          ))}
                    </Stack>
                  </RadioGroup>
                ) : (
                  <TabList>
                    {isDistrictAssessment
                      ? constants.DISTRICT_ASSESSMENT_TABS.map(({ tab }) => (
                          <CustomTab key={tab}>{tab}</CustomTab>
                        ))
                      : constants.TABS.map(({ tab }) => (
                          <CustomTab key={tab}>{tab}</CustomTab>
                        ))}
                  </TabList>
                )}
              </Box>
            </Flex>
            <Divider orientation="horizontal" />
          </>
        )}
        <TabPanels>
          {isDistrictAssessment
            ? constants.DISTRICT_ASSESSMENT_TABS.map(({ TabPanel, tab }) => (
                <TabPanel
                  key={tab}
                  isDistrictAssessmentForOneSchool={
                    isDistrictAssessmentForOneSchool
                  }
                  isDistrictAssessment={isDistrictAssessment}
                />
              ))
            : constants.TABS.map(({ TabPanel, tab }) => (
                <TabPanel
                  key={tab}
                  classesOptions={classesOptions}
                  classesLoading={classesLoading}
                />
              ))}
        </TabPanels>
      </Tabs>
    </TabPanel>
  );
};

export default ManageAssignees;
