import { ListItem, ListItemProps } from '@chakra-ui/react';
import React, { LegacyRef } from 'react';
import { ThemeEnum } from '@lon/shared/enums';
import { useGetTheme } from '@lon/shared/hooks';

export const CustomListItem: React.FC<
  ListItemProps & { internalRef?: LegacyRef<HTMLLIElement> }
> = ({ internalRef, ...props }) => {
  const theme = useGetTheme();
  const isHighContrast = theme === ThemeEnum.HIGH_CONTRAST;

  return (
    <ListItem
      tabIndex={0}
      py={2}
      px={6}
      color={isHighContrast ? 'white' : 'secondary.700'}
      _focusVisible={{
        outline: '-webkit-focus-ring-color auto 1px',
      }}
      _hover={{
        bg: isHighContrast ? 'secondary.500' : 'secondary.100',
      }}
      _active={{
        bg: isHighContrast ? 'secondary.400' : 'secondary.200',
      }}
      cursor="pointer"
      fontSize="xs"
      ref={internalRef}
      {...props}
    />
  );
};
