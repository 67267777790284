import useAuth from '../use-auth';
import { belowK3GradeLevels } from '@lon/shared/constants';

const useGradeLevel = (): {
  isStudentBelowK3: boolean;
} => {
  const [{ user }] = useAuth();

  return {
    isStudentBelowK3:
      user?.gradeLevel && belowK3GradeLevels.includes(user?.gradeLevel),
  };
};

export default useGradeLevel;
