import { Icon } from '../icon';
import { Tag, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@lon/shared/assets';
import { StudentGradeContext } from '@lon/shared/contexts';
import { StudentAssignmentAllStatusTransformed } from '@lon/shared/enums';
import { Props } from './duck/types';

const StudentSuiteAssignmentStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const { isStudentBelowK3 } = useContext(StudentGradeContext);

  switch (true) {
    case status === StudentAssignmentAllStatusTransformed.Graded:
      return (
        <Tag
          variant="studentStatus"
          backgroundColor="success.800"
          color="white"
          px={2}
          borderRadius={isStudentBelowK3 ? '10px' : '4px'}
        >
          <Icon
            name={
              isStudentBelowK3 ? 'k2-check-outlined' : 'check-simple-outlined'
            }
            size="0.75"
          />
          <Text as="span" ml={1} variant={isStudentBelowK3 ? 'tmu' : 'tsu'}>
            {t('assignments.graded')}
          </Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Assigned:
      return (
        <Tag
          variant="studentStatus"
          backgroundColor="primary.700"
          color="white"
          px={2}
        >
          <Icon
            name={isStudentBelowK3 ? 'fileTextFilledWhite' : 'assignment'}
            size="0.75"
          />
          <Text as="span" ml={1} variant={isStudentBelowK3 ? 'tmu' : 'tsu'}>
            {t('assignments.assigned')}
          </Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.ToBeGraded:
      return (
        <Tag
          variant="studentStatus"
          backgroundColor="blue.750"
          color="white"
          px={2}
        >
          <Icon
            name={
              isStudentBelowK3 ? 'k2-rocket-outlined' : 'std-rocket-outlined'
            }
            size="0.75"
          />
          <Text as="span" ml={1} variant={isStudentBelowK3 ? 'tmu' : 'tsu'}>
            {t('assignments.submitted')}
          </Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Started:
      return (
        <Tag
          variant="studentStatus"
          backgroundColor="calendar.violet.2"
          color="white"
          px={2}
        >
          <Icon name={isStudentBelowK3 ? 'fileEdit' : 'report'} size="sm" />
          <Text as="span" ml={1} variant={isStudentBelowK3 ? 'tmu' : 'tsu'}>
            {t('assignments.started')}
          </Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Returned:
      return (
        <Tag
          variant="studentStatus"
          backgroundColor={colors.yellow[800]}
          color="white"
          px={2}
        >
          <Icon
            name={isStudentBelowK3 ? 'fileOutput' : 'returnAssignmentOutlined'}
            size="0.75"
          />
          <Text as="span" ml={1} variant={isStudentBelowK3 ? 'tmu' : 'tsu'}>
            {t('assignments.returned')}
          </Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Submitted:
      return (
        <Tag
          variant={isStudentBelowK3 ? 'studentStatusLower' : 'studentStatus'}
          backgroundColor={colors.calendar.blue[800]}
          color="white"
          px={2}
        >
          <Icon
            name={isStudentBelowK3 ? 'k2RocketOutlined' : 'stdRocketOutlined'}
            size="sm"
          />
          <Text ml={2} variant="lmu">
            {t('assignments.submitted')}
          </Text>
        </Tag>
      );
    case status === StudentAssignmentAllStatusTransformed.Archived:
      return (
        <Tag
          variant={isStudentBelowK3 ? 'studentStatusLower' : 'studentStatus'}
          backgroundColor={colors.secondary[800]}
          color="white"
          px={2}
        >
          <Icon
            name={isStudentBelowK3 ? 'k2ArchiveOutlined' : 'inboxOutlined'}
            size="sm"
          />
          <Text ml={2} variant="lmu">
            {t('assignments.archived')}
          </Text>
        </Tag>
      );

    default:
      return null;
  }
};

export default StudentSuiteAssignmentStatus;
