import UnauthorizedPageWrapper from '../unauthorized-page-wrapper';
import { Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { ApplicationEnum } from '@lon/shared/enums';
import { types } from './duck';
import { ReactComponent as homeOutlined } from './home-outlined.svg';

const imageByApplication: Record<
  Exclude<
    ApplicationEnum,
    ApplicationEnum.LOGIN | ApplicationEnum.MATHNATION_SUIT
  >,
  string
> = {
  [ApplicationEnum.TEACHER_SUIT]: 'suit/assets/images/error-403.png',
  [ApplicationEnum.PARENT_SUIT]: 'suit/assets/images/error-403.png',
  [ApplicationEnum.STUDENT_SUIT]: 'suit/assets/images/error-403-student.png',
  [ApplicationEnum.CAMPUS_LEADER_SUIT]:
    'suit/assets/images/errors/error-403.png',
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]:
    'suit/assets/images/errors/error-403.png',
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]:
    'suit/assets/images/errors/error-403.png',
  [ApplicationEnum.ADMIN]: 'admin/assets/errors/error-403-admin.png',
  [ApplicationEnum.PLATFORM_ADMIN]:
    'platform-admin/assets/errors/error-403-admin.png',
};

const Forbidden: React.FC<types.ForbiddenProps> = ({
  onClickHome,
  application = ApplicationEnum.TEACHER_SUIT,
}) => {
  return (
    <UnauthorizedPageWrapper>
      <Flex
        direction="column"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        borderRadius="md"
        px={6}
        py={10}
        height="auto"
        gap={6}
      >
        <Image src={imageByApplication[application]} />
        <Flex
          direction="column"
          gap={4}
          alignItems="center"
          color="primary.800"
        >
          <Text
            variant="h5"
            as="h1"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            Access Forbidden.
          </Text>
          <Text variant="n3" as="h2" textAlign="center">
            Sorry, you do not have access to this page or resource.
          </Text>

          <Button
            onClick={onClickHome}
            title="Go to Dashboard"
            variant="solid"
            width={{ base: 'full', md: 'auto' }}
            leftIcon={<Icon as={homeOutlined} />}
          >
            <Text as="span" isTruncated>
              Go to Dashboard
            </Text>
          </Button>
        </Flex>
      </Flex>
    </UnauthorizedPageWrapper>
  );
};

export default Forbidden;
