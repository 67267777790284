import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Icon } from '@lon/shared/components';

const AssignmentHeader = ({
  elementName,
  sectionName,
  scopeName,
  action,
  loading = false,
}: {
  elementName: string;
  sectionName: string;
  scopeName: string;
  action?: React.ReactNode;
  loading?: boolean;
}) => {
  if (loading) {
    return null;
  }

  return (
    <Flex
      height="100px"
      maxH="100px"
      py="4"
      px={8}
      color="primary.800"
      gap={4}
      borderBottom="1px solid #F0F1F3"
      boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.10)"
      justifyContent="space-between"
    >
      <Flex gap={4} alignItems="center">
        <Icon name="share-results-eye" width="50px" height="50px" />

        <Flex direction="column" gap={2}>
          <Text variant="h5">{elementName}</Text>
          <Text variant="n3">
            {sectionName} | {scopeName}
          </Text>
        </Flex>
      </Flex>

      {action}
    </Flex>
  );
};

export default AssignmentHeader;
