import { Box, Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import {
  isProdCluster,
  prodStemDomain,
  stemDomain,
} from '@lon/shared/constants';

const UnauthorizedFooter: React.FC = () => (
  <Box
    id="footer"
    as="footer"
    aria-label="footer"
    boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.15)"
  >
    <Flex justify="center" align="center" m="0 auto" py="15px">
      <Flex
        gap={{ base: 1, md: 8 }}
        direction={{ base: 'column', md: 'row' }}
        justify="center"
        align="center"
      >
        <Flex gap={1.5} direction="row">
          <Text
            color="primary.800"
            aria-hidden="true"
            fontSize={{ base: '14px', md: 'sm' }}
            lineHeight={{ md: 'short' }}
            fontWeight={500}
          >
            &copy; 2024{' '}
          </Text>
          <Link
            href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
            isExternal
            color="primary.800"
            aria-label="Accelerate Learning Inc."
            textDecoration="underline"
            fontSize={{ base: '14px', md: 'sm' }}
            lineHeight={{ md: 'short' }}
            fontWeight={500}
          >
            Accelerate Learning Inc.
          </Link>
        </Flex>
        <Link
          href={
            isProdCluster() ? `${prodStemDomain}/terms` : `${stemDomain}/terms`
          }
          isExternal
          color="primary.800"
          textDecoration="underline"
          fontSize={{ base: '14px', md: 'sm' }}
          lineHeight={{ md: 'short' }}
          fontWeight={500}
          aria-label="Terms and Conditions"
        >
          Terms and Conditions
        </Link>
      </Flex>
    </Flex>
  </Box>
);

export default UnauthorizedFooter;
