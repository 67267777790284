import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  AccordionProps,
  Box,
} from '@chakra-ui/react';
import React from 'react';

export interface InformativeAccordionProps extends AccordionProps {
  titleText: string | React.ReactElement;
  accordionPanelProps?: AccordionPanelProps;
}

export const InformativeAccordion: React.FC<InformativeAccordionProps> = ({
  titleText,
  accordionPanelProps,
  children,
  ...props
}) => {
  return (
    <Accordion allowToggle variant="informative" {...props}>
      <AccordionItem>
        <AccordionButton pt="1" pb="3px">
          <Box flex="1" textAlign="left">
            {titleText}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel pb={4} {...accordionPanelProps}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
