import { appOperations } from '../../app/duck';
import React, { useMemo } from 'react';
import { useInterceptor } from '@lon/api';
import { WorkingLocation } from '@lon/shared/contexts';
import { useAuth, useCurrentSchoolId } from '@lon/shared/hooks';
import { getCurrentSuite } from '@lon/suit/utils';
import LtiScopeContainer from './LtiScopeContainer';

const LtiContainer = () => {
  const [auth] = useAuth();
  const [currentSchoolId, setCurrentSchoolId] = useCurrentSchoolId();

  useInterceptor('request', appOperations.prepareRequest);

  useInterceptor('response', appOperations.prepareSuccess, (error) =>
    appOperations.prepareFailure({ error })
  );

  const workingLocation = useMemo(() => {
    return {
      application: getCurrentSuite(auth?.profileType),
      currentSchoolId,
      setCurrentSchoolId,
      permissions: (auth?.user.permissions || []) as any,
      schoolIds: auth?.user?.schoolIds || [],
      userType: auth?.user?.type,
    };
  }, [currentSchoolId, auth?.user]);

  return (
    <WorkingLocation.Provider key={currentSchoolId} value={workingLocation}>
      <LtiScopeContainer />
    </WorkingLocation.Provider>
  );
};

export default LtiContainer;
