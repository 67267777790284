import { useSelector } from 'react-redux';
import { ThemeEnum } from '@lon/shared/enums';
import { parseJSON } from '@lon/shared/utils';

type UseGetTheme = () => ThemeEnum;

export const useGetTheme: UseGetTheme = () => {
  const applicationSettings = useSelector(
    (state: any) => state.applicationSettings
  );

  const currentTheme: ThemeEnum = (
    parseJSON(applicationSettings?.preferences) as any
  )?.theme;

  return currentTheme;
};
