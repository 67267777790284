import { Sort } from '../types';

type GetSort = (sort: Sort) => string;

const getSort: GetSort = (sort) =>
  Object.keys(sort)
    .filter((key) => sort[key].toLowerCase() !== 'none')
    .map((key) => `${sort[key].toLowerCase() === 'desc' ? '-' : ''}${key}`)
    .join(',');

export default getSort;
