import { VStack } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { resizeInputs } from './duck';
import { GradingByQuestions, LearnosityGrading } from './components';
import styles from './Main.module.css';

interface MainProps {
  isDa?: boolean;
  backPath?: string;
  isLti?: boolean;
}

const Main: React.FC<MainProps> = ({ isDa, backPath, isLti }) => {
  const { studentId, sessionId } = useParams();
  const isSingleStudentGrading = studentId && sessionId;
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let prevWidth = 0;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        if (typeof width === 'number' && width !== prevWidth) {
          prevWidth = width;
          resizeInputs();
        }
      }
    });

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, {
        box: 'border-box',
      });
    }

    return () => {
      if (wrapperRef.current) {
        observer?.unobserve(wrapperRef.current);
      }
    };
  }, []);

  return (
    <VStack
      ref={wrapperRef}
      background="white"
      width="100%"
      flexGrow={1}
      gridGap="4"
      p={4}
      borderRadius="md"
      className={styles.wrapper}
    >
      {isSingleStudentGrading ? (
        <>
          <div id="report-1" />
          <LearnosityGrading
            key={sessionId}
            isDa={isDa}
            backPath={backPath}
            isLti={isLti}
          />
        </>
      ) : (
        <GradingByQuestions isDa={isDa} />
      )}
    </VStack>
  );
};

export default Main;
