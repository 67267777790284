import { DistrictAssignmentContext } from '../../duck/context';
import { FC, useContext } from 'react';
import { Table } from '@lon/shared/modules/table';
import { AssignmentGradesProps } from './duck';
import { GroupActions, RowActions } from './components';

const AssignmentGrades: FC<AssignmentGradesProps> = ({ columns }) => {
  const {
    isLoading,
    preparedDistrictAssignmentData,
    showAnswersColumn,
    setShowAnswersColumn,
    isArchived,
  } = useContext(DistrictAssignmentContext);

  return (
    <Table
      data={preparedDistrictAssignmentData || []}
      loading={isLoading}
      columns={columns}
      enableClientSideSorting
      enableStickyColumns="first"
      enableVerticalBorders
      enableRowSelection
      enableYScroll={false}
      headerProps={{
        bgColor: 'white',
        color: 'primary.800',
        whiteSpace: 'pre-wrap',
      }}
      renderGroupActions={(table) => (
        <GroupActions
          table={table}
          showAnswersColumn={showAnswersColumn}
          setShowAnswersColumn={setShowAnswersColumn}
          isArchived={isArchived}
        />
      )}
      // TODO enable when DA actions have been developed
      {...(!isArchived && {
        renderRowActions: ({ row: { original } }: any) => (
          <RowActions rowData={original} />
        ),
      })}
    />
  );
};

export default AssignmentGrades;
