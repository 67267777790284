import { t } from 'i18next';
import { object, ref, string } from 'yup';
import { specialCharacters } from '@lon/shared/constants';
import { getValidationMessages } from '@lon/shared/utils';

export const getPasswordValidation = (isAdmin: boolean) => {
  const min = isAdmin ? 16 : 12;

  return string()
    .required(getValidationMessages().required)
    .matches(/^(?!.* )/, getValidationMessages().noSpaces)
    .min(min, getValidationMessages(min).passwordLength)
    .matches(/\d+/, getValidationMessages().atLeastOneDigit)
    .matches(/[a-z]+/, getValidationMessages().atLeastOneLowerCase)
    .matches(
      specialCharacters,
      getValidationMessages().atLeastOneSpecialCharacter
    )
    .matches(/[A-Z]+/, getValidationMessages().atLeastOneUpperCase);
};

export const getValidationSchema = (resetPasswordExpanded: boolean) =>
  object({
    currentPassword: string()
      .trim()
      .when([], {
        is: () => resetPasswordExpanded,
        then: string().required(
          t('validationMessages.requiredField', {
            fieldName: 'Current Password',
          })
        ),
      })
      .default(''),
    newPassword: string()
      .when([], {
        is: () => resetPasswordExpanded,
        then: getPasswordValidation(true).default(''),
      })
      .default(''),
    repeatedPassword: string()
      .trim()
      .when([], {
        is: () => resetPasswordExpanded,
        then: string().required(
          t('validationMessages.requiredField', {
            fieldName: 'Confirm Password',
          })
        ),
      })
      .oneOf(
        [ref('newPassword'), null],
        getValidationMessages().notMatchingPasswords
      )
      .default(''),
    defaultApplication: string(),
    defaultSchool: string(),
  });

export const getDefaultValues = ({
  defaultApplication,
  defaultSchool,
}: any) => ({
  currentPassword: '',
  newPassword: '',
  repeatedPassword: '',
  defaultApplication,
  defaultSchool,
});
