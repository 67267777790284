// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getTooltip = (theme: ThemeEnum) => ({
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    color: 'black',
    backgroundColor: 'white',
    boxShadow: '0 10px 20px rgba(0,0,0,.15);',
  },
  variants: {
    dark: {
      backgroundColor: getThemeStyleValue('#1A2C49', 'white')(theme),
      color: getThemeStyleValue('white', 'secondary.1000')(theme),
      borderRadius: '4px',
      py: '2',
      px: '4',
    },
  },
  sizes: {
    md: {
      py: '4',
      px: '4',
    },
  },
});
