import { useCurrentElement } from '@lon/suit/hooks';
import { TransformedElement } from '@lon/suit/types';

const useGoogleClassroom = () => {
  const currentElement = useCurrentElement() as TransformedElement;

  const { elementData } = {
    elementData: {
      scopeName: currentElement?.metadata?.scopeDisplayName || '',
      sectionName: currentElement?.metadata?.sectionTitle || '',
      elementName: currentElement?.metadata?.elementName || '',
    },
  };

  const handleSelectToGoogleClassroom = ({
    assignmentId,
    classId,
    queryParams,
    title,
  }: {
    assignmentId: string;
    classId: string;
    queryParams: string;
    title?: string;
  }) => {
    const params = new URLSearchParams({
      title:
        title ||
        `${elementData?.scopeName} - ${elementData?.sectionName} - ${elementData?.elementName}`,
      url: `${window.location.origin}/suite/google-classroom/class/${classId}/assignments/${assignmentId}?${queryParams}`,
    });

    const url = `https://classroom.google.com/u/1/share?${params}`;

    window.open(
      `${url}`,
      'googleclassroom',
      'width=700,height=600,left=100,top=75'
    );
  };

  return { handleSelectToGoogleClassroom };
};

export default useGoogleClassroom;
