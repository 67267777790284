import { Forbidden } from '../forbidden';
import { ChakraProvider } from '@chakra-ui/react';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import { theme } from '@lon/shared/assets';
import { emotionCache } from '@lon/shared/constants';
import { AclContext } from '@lon/shared/contexts';
import { useAuth } from '@lon/shared/hooks';
import { redirectToAvailableApplication } from '@lon/shared/utils';
import { AclWrapperInterface } from './duck/types';

export const AclWrapper: React.FC<AclWrapperInterface> = ({
  children,
  application,
  loading: loadingAcl,
  showCredentialsModal,
}) => {
  const [auth] = useAuth();
  const { value: aclApplications } = React.useContext(AclContext);

  const isApplicationAvailable = aclApplications.find(
    (app) => app.name === application
  );

  if (
    auth.isLoggedIn &&
    !loadingAcl &&
    !showCredentialsModal &&
    !isApplicationAvailable
  ) {
    return (
      <CacheProvider value={emotionCache}>
        <ChakraProvider theme={theme}>
          <Forbidden
            application={application}
            onClickHome={() => redirectToAvailableApplication(aclApplications)}
          />
        </ChakraProvider>
      </CacheProvider>
    );
  }

  return children;
};
