import { Icon } from '../icon';
import { Tag, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '@lon/shared/assets';
import { StudentAssignmentStatusTransformed } from '@lon/shared/enums';
import { useGetTheme } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import { Props } from './duck/types';

const StudentAssignmentStatus: FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const currentTheme = useGetTheme();

  switch (true) {
    case status === StudentAssignmentStatusTransformed.Graded:
      return (
        <Tag
          variant="status"
          backgroundColor={getThemeStyleValue(
            'success.800',
            'white'
          )(currentTheme)}
          color={getThemeStyleValue('white', 'secondary.1000')(currentTheme)}
        >
          <Icon name="checkOutlined" width="12px" height="12px" />
          <Text ml={2}>{t('assignments.graded')}</Text>
        </Tag>
      );
    case status === StudentAssignmentStatusTransformed.ToBeGraded:
      return (
        <Tag
          variant="status"
          backgroundColor={getThemeStyleValue(
            'blue.750',
            'white'
          )(currentTheme)}
          color={getThemeStyleValue('white', 'secondary.1000')(currentTheme)}
        >
          <Icon name="auditOutlined" width="16px" height="16px" />
          <Text ml={2}>{t('assignments.toBeGraded')}</Text>
        </Tag>
      );
    case status === StudentAssignmentStatusTransformed.Assigned:
      return (
        <Tag
          variant="status"
          backgroundColor={getThemeStyleValue(
            'primary.700',
            'white'
          )(currentTheme)}
          color={getThemeStyleValue('white', 'secondary.1000')(currentTheme)}
        >
          <Icon name="assignment" width="16px" height="16px" />
          <Text ml={2}>{t('assignments.assigned')}</Text>
        </Tag>
      );
    case status === StudentAssignmentStatusTransformed.Started:
      return (
        <Tag
          variant="status"
          backgroundColor={getThemeStyleValue(
            'calendar.violet.2',
            'white'
          )(currentTheme)}
          color={getThemeStyleValue('white', 'secondary.1000')(currentTheme)}
        >
          <Icon name="report" width="14px" height="14px" />
          <Text ml={2}>{t('assignments.started')}</Text>
        </Tag>
      );

    case status === StudentAssignmentStatusTransformed.Returned:
      return (
        <Tag
          variant="status"
          backgroundColor={getThemeStyleValue(
            colors.yellow[800],
            'white'
          )(currentTheme)}
          color={getThemeStyleValue('white', 'secondary.1000')(currentTheme)}
        >
          <Icon name="returnAssignmentOutlined" width="16px" height="16px" />
          <Text ml={2}>{t('assignments.returned')}</Text>
        </Tag>
      );

    default:
      return null;
  }
};

export default StudentAssignmentStatus;
