import { ModalFooter, ModalFooterProps } from '@chakra-ui/react';

export interface FooterProps extends ModalFooterProps {
  children: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children, ...rest }) => {
  return <ModalFooter {...rest}>{children}</ModalFooter>;
};

export default Footer;
