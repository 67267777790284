import { Skeleton, SkipNavTo, TableContent } from '../../components';
import { tableHooks, tableTypes } from '../../duck';
import { Box, Table as ChakraTable } from '@chakra-ui/react';
import {
  ExpandedState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isEqual, uniqueId } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { sxLightScrollBar } from '@lon/shared/constants';

const SecondaryTable = <Data extends object>({
  data = [],
  columns,
  loading = false,
  onRowSelectStateChange = () => {},
  renderRowActions,
  renderGroupActions,
  enableClientSidePagination,
  enableClientSideSorting,
  serverSidePagination,
  serverSideSorting,
  enableStickyColumns,
  enableRowSelection,
  isRowSelectionDisabled,
  paginationInitialState,
  tableSize = 'md',
  resetRows,
  skeletonRowCount = 30,
  skeletonRowHeight,
  containerProps,
  captionProps,
  headerProps,
  bodyProps,
  footerProps,
  emptyMessage,
  fullWidth,
  enableVerticalBorders = true,
  useDefaultSortingIcon = true,
  innerTableRef,
  innerTheadRef,
  activeRowId,
  tableOrder,
  hasNextTable,
  hasPreviousTable,
  expandedState: externalExpandedState,
  setExpandedState: externalSetExpandedState,
  initialSelection = {},
  navToConfig,
}: tableTypes.TableProps<Data>) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState(initialSelection);
  const [internalExpandedState, setInternalExpandedState] =
    useState<ExpandedState>(true);
  const isEmpty = !loading && !data.length;
  const hasServerSidePagination = typeof serverSidePagination !== 'undefined';
  const hasServerSideSorting = typeof serverSideSorting !== 'undefined';
  const preparedColumns = tableHooks.usePrepareData({
    data,
    columns,
    renderRowActions,
    enableRowSelection,
    isRowSelectionDisabled,
  });

  useEffect(() => {
    if (!isEqual(initialSelection, rowSelection)) {
      setRowSelection(initialSelection);
    }
  }, []);

  const table = useReactTable({
    columns: preparedColumns,
    data,
    onExpandedChange: externalSetExpandedState || setInternalExpandedState,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: hasServerSideSorting
      ? serverSideSorting.setSorting
      : setSorting,
    pageCount: hasServerSidePagination
      ? serverSidePagination.pageCount
      : undefined,
    getSortedRowModel: enableClientSideSorting
      ? getSortedRowModel()
      : undefined,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: enableClientSidePagination
      ? getPaginationRowModel()
      : undefined,
    getSubRows: (row: any) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    ...(hasServerSidePagination
      ? { onPaginationChange: serverSidePagination.setPagination }
      : {}),
    manualPagination: hasServerSidePagination,
    manualSorting: hasServerSideSorting,
    initialState: {
      ...paginationInitialState,
    },
    state: {
      sorting,
      rowSelection,
      expanded: externalExpandedState || internalExpandedState,
      ...(hasServerSidePagination
        ? { pagination: serverSidePagination.pagination }
        : {}),
      ...(hasServerSideSorting ? { sorting: serverSideSorting.sorting } : {}),
    },
  });

  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }, [loading]);

  const tableId = useMemo(() => uniqueId('table-'), []);

  return (
    <Box
      display="flex"
      position="relative"
      flexGrow={1}
      borderRadius="md"
      boxShadow="0px 5px 20px -2px rgba(43, 54, 70, 0.07)"
      color="blue.500"
      bg="white"
      overflowX={fullWidth ? 'initial' : 'auto'}
      {...containerProps}
      sx={sxLightScrollBar}
    >
      <SkipNavTo
        tableId={tableId}
        hasNextTable={hasNextTable}
        hasPreviousTable={hasPreviousTable}
        tableOrder={tableOrder}
        hasPagination={navToConfig?.hasPagination}
      />
      {isEmpty && emptyMessage ? (
        emptyMessage
      ) : (
        <ChakraTable
          ref={innerTableRef}
          size={tableSize}
          h="1px"
          {...(enableStickyColumns && {
            css: { borderCollapse: 'collapse', borderSpacing: 0 },
          })}
        >
          {loading ? (
            <Skeleton
              columns={preparedColumns}
              rowCount={
                paginationInitialState?.pagination?.pageSize || skeletonRowCount
              }
              skeletonRowHeight={skeletonRowHeight}
            />
          ) : (
            <TableContent
              innerTheadRef={innerTheadRef}
              table={table}
              onRowSelectStateChange={onRowSelectStateChange}
              renderGroupActions={renderGroupActions}
              enableClientSidePagination={enableClientSidePagination}
              enableClientSideSorting={enableClientSideSorting}
              enableStickyColumns={enableStickyColumns}
              serverSidePagination={serverSidePagination}
              serverSideSorting={serverSideSorting}
              resetRows={resetRows}
              captionProps={captionProps}
              headerProps={headerProps}
              bodyProps={bodyProps}
              footerProps={footerProps}
              enableVerticalBorders={enableVerticalBorders}
              emptyMessage={emptyMessage}
              hasData={!!data.length}
              useDefaultSortingIcon={useDefaultSortingIcon}
              enableRowSelection={enableRowSelection}
              activeRowId={activeRowId}
              tableId={tableId}
            />
          )}
        </ChakraTable>
      )}
    </Box>
  );
};

export default SecondaryTable;
