import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

const GradeTag: FC<FlexProps> = ({
  backgroundColor,
  borderColor,
  children,
  color,
  borderWidth = '1px',
  w = '38px',
  ...rest
}) => (
  <Flex
    align="center"
    justify="center"
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    color={color}
    borderWidth={borderWidth}
    borderRadius="4px"
    w={w}
    minH="28px"
    h="full"
    {...rest}
  >
    {children}
  </Flex>
);

export default GradeTag;
