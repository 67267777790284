import { TFunction } from 'i18next';
import { array, object, string } from 'yup';

const getValidationSchema = (t: TFunction) => {
  return object({
    courses: array().of(
      object().shape({
        name: string().required(
          t('validationMessages.requiredField', {
            fieldName: 'Course Name',
          })
        ),
        gradeLevel: string().required(
          t('validationMessages.requiredField', {
            fieldName: 'Grade Level',
          })
        ),
        curriculumArea: string().required(
          t('validationMessages.requiredField', {
            fieldName: 'Curriculum Area',
          })
        ),
      })
    ),
  });
};

export const getInitialData = (data?: any) => {
  if (!data?.courses?.length) {
    return {
      courses: [],
    };
  }

  return {
    courses: data?.courses,
  };
};
export const getValidation = (t: TFunction, data?: any) => ({
  validationSchema: getValidationSchema(t),
  defaultValues: getInitialData(data),
});
