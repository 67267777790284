import { InputsList } from '../../../../../inputs-list';
import { TABLET_TAB_PANEL_PADDING } from '../../../../duck/constants';
import { AssignmentFormContext } from '../../../../duck/context';
import { TabProps } from '../../duck/types';
import { Box, TabPanel, Text } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@lon/shared/hooks';
import { IndividualStudentInput } from './componnets';

const IndividualStudents: React.FC<TabProps> = ({
  classesOptions,
  classesLoading,
}) => {
  const { t } = useTranslation();
  const { withOtherAssignment, isTeacherAssessment } = useContext(
    AssignmentFormContext
  );
  const [isTablet] = useMediaQuery('(max-width: 1024px)');

  const renderIndividualStudentInput = useCallback(
    (props: any) => (
      <IndividualStudentInput
        classesOptions={classesOptions}
        classesLoading={classesLoading}
        {...props}
      />
    ),
    [classesOptions, classesLoading]
  );

  return (
    <TabPanel pb="4" {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}>
      <Box pb="4">
        <Text
          variant="s3"
          color="primary.400"
          whiteSpace="pre-wrap"
          textAlign="left"
          {...(!isTablet && { maxWidth: '700px' })}
        >
          {isTeacherAssessment
            ? t('createAssignmentDrawer.assessmentsIndividualStudentsIntro')
            : t('createAssignmentDrawer.individualStudentsIntro')}
        </Text>
      </Box>
      <InputsList
        canBeEmpty
        withDivider={withOtherAssignment}
        name="individualStudents"
        defaultValue={{ classId: '', studentIds: [] }}
        mobile={isTablet}
        input={renderIndividualStudentInput}
        addButtonTitle={t('createAssignmentDrawer.addClassBtn')}
        renderLabel={(index) =>
          t('createAssignmentDrawer.class', { count: index + 1 })
        }
        renderPlaceholder={() => t('createAssignmentDrawer.classPlaceholder')}
        isRemoveButtonDisabled={() => false}
        addBtnTooltip={
          isTeacherAssessment
            ? t('createAssignmentDrawer.addAssessmentsClassesBtnTooltip')
            : t('createAssignmentDrawer.addAssignmentsClassesBtnTooltip')
        }
        {...(isTablet
          ? {
              dividerStyles: {
                ml: `-${TABLET_TAB_PANEL_PADDING}`,
                w: `calc(100% + 2 * ${TABLET_TAB_PANEL_PADDING})`,
              },
            }
          : { maxWidth: '700px' })}
      />
    </TabPanel>
  );
};

export default IndividualStudents;
