import {
  Modal as ChakraModal,
  Divider,
  Grid,
  GridItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { sanitize } from 'dompurify';
import { Fragment, MouseEvent, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';
import { useMediaQuery } from '@lon/shared/hooks';
import { Standard } from '@lon/shared/types';

export interface ModalProps {
  standards: Standard[] | undefined;
  trigger: React.ReactElement;
}

const StandardsModal: React.FC<ModalProps> = ({ standards, trigger }) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {cloneElement(trigger, {
        onClick: (e: MouseEvent<HTMLElement>) => {
          e.currentTarget.blur();
          onOpen();
        },
      })}
      <ChakraModal
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        {...(isMobile ? { size: 'full' } : {})}
      >
        <ModalContent
          data-focus-visible-disabled
          maxW={{ b768: '800px' }}
          borderWidth={{ base: 0, b768: '15px' }}
          borderColor="secondary.50"
          borderRadius={{ base: 0, b768: '20px' }}
        >
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={8}
            py={4}
            maxH="unset"
          >
            <Text variant="formHeader">{t('toolbar.scopeStandards')}</Text>
            <ModalCloseButton position="unset" w="50px" h="50px" />
          </ModalHeader>
          <ModalBody p={0} css={sxLightScrollBar}>
            <Grid
              templateColumns="minmax(auto, 200px) 1fr"
              columnGap={6}
              pt="4"
              pb="6"
            >
              {standards?.map((standard, index) => (
                <Fragment key={standard.HumanCodingScheme}>
                  <GridItem>
                    <Text as="span" variant="n1" pl="8">
                      {standard.HumanCodingScheme}
                    </Text>
                  </GridItem>
                  <GridItem w="full" pr="8">
                    <Text
                      as="span"
                      variant="n3"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(standard.title),
                      }}
                    />
                  </GridItem>
                  {index + 1 !== standards.length && (
                    <Divider gridColumn="span 2" mb="4" mt="6" />
                  )}
                </Fragment>
              ))}
            </Grid>
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default StandardsModal;
