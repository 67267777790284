import useAuth from '../use-auth';
import { useCallback, useEffect, useState } from 'react';
import { getSignature, hashValue, patchLocalStorage, parseJSON } from '@lon/shared/utils';
import { useSelector } from 'react-redux';

const useCurrentSchoolId = (): [string, (schoolId: string) => void] => {
  const [{ user }] = useAuth();
  const applicationSettings = useSelector(
    (state: any) => state.applicationSettings
  );
  const preferences = parseJSON(applicationSettings?.preferences) as any;
  const [currentSchoolId, setCurrentSchoolId] = useState<string>('');

  useEffect(() => {
    if (user?.userId && user?.schoolIds && !currentSchoolId) {
      const lastSchoolIdsBySignature =
        JSON.parse(localStorage.getItem('lastSchoolIdsBySignature') || '{}') ||
        {};
      const signature = getSignature();
      const school =
        lastSchoolIdsBySignature[signature] || preferences?.defaultSchool;


      if (
        school &&
        user.schoolIds.includes(school)
      ) {
        setCurrentSchoolId(school);
      } else {
        setCurrentSchoolId(user.schoolIds[0]);
      }
    }
  }, [user, preferences]);

  const updateCurrentSchoolId = useCallback(
    (schoolId: string) => {
      setCurrentSchoolId(schoolId);

      hashValue(user.userId).then((value) => {
        patchLocalStorage('lastSchoolIdsBySignature', {
          [value]: schoolId,
        });
      });
    },
    [user?.userId]
  );

  return [currentSchoolId, updateCurrentSchoolId] as [
    string,
    (schoolId: string) => void
  ];
};

export default useCurrentSchoolId;
