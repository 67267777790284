export const wrap = (el: Element, wrapper: Element) => {
  el?.parentNode?.insertBefore(wrapper, el);
  wrapper.appendChild(el);
};

export const removeVerticalScrolls = () => {
  const scrollablePassages = document.querySelectorAll(
    '.lrn_scrollablepassage'
  );

  scrollablePassages.forEach((passage) => {
    if (passage?.parentElement) {
      passage.parentElement.style.height = 'unset';
    }
  }, []);
};
