import LoadingText from '../../../loading-text';
import { MessagesPropTypes } from '../../duck';
import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

const Messages: React.FC<MessagesPropTypes> = ({ messages, loading }) => {
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = elRef.current;
    const scrollHeight = el?.scrollHeight || 1000;
    el!.scrollTop = scrollHeight;

    ((el?.lastChild as HTMLElement).firstChild as HTMLElement).focus();
  }, [messages]);

  return (
    <Flex
      w="100%"
      minH="calc(88% - 1vh)"
      maxH="calc(88% - 1vh)"
      overflowY="scroll"
      flexDirection="column"
      p="3"
      borderRadius={4}
      ref={elRef}
    >
      {messages?.map((item, index) => {
        if (item.from === 'me') {
          return (
            <Flex key={index} w="100%" justify="flex-end">
              <Flex
                bg="black"
                color="white"
                minW="100px"
                maxW="350px"
                my="1"
                p="3"
                tabIndex={0}
                wordBreak="break-word"
              >
                <Text>{item.text}</Text>
              </Flex>
            </Flex>
          );
        } else {
          return (
            <Flex key={index} w="100%">
              <Flex
                fontWeight="bold"
                color="#1E4176"
                minW="100px"
                maxW="350px"
                my="1"
                p="3"
                tabIndex={0}
                wordBreak="break-word"
              >
                <Text>{item.text}</Text>
              </Flex>
            </Flex>
          );
        }
      })}
      {loading && (
        <Flex w="100%" justifyContent="flex-end">
          <LoadingText />
        </Flex>
      )}
    </Flex>
  );
};

export default Messages;
