import * as ReactDOM from 'react-dom/client';
import { configureRequest, initTranslationService } from '@lon/suit/configs';
import AppProvider from './app/AppProvider';

configureRequest();
initTranslationService();

if ((module as any)?.hot) {
  (module as any)?.hot.accept();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<AppProvider />);
