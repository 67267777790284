export const NewButton = {
  defaultProps: {
    size: 'md',
    colorScheme: 'primary',
    variant: 'solid-light',
  },
  baseStyle: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    boxSizing: 'border-box',
    color: 'white',
    borderRadius: '0.25rem',
    background: 'transparent',
    backgroundColor: 'transparent',
    _active: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focus: {
      '&[not(:focus-visible)]': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    _focusVisible: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  variants: {
    'solid-light': {
      backgroundColor: 'blue.500',
      _hover: {
        backgroundColor: 'primary.600',

        _disabled: {
          backgroundColor: 'secondary.300',
          color: 'secondary.100',
          opacity: 1,
        },
      },

      _focusVisible: {
        backgroundColor: 'primary.800',
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        border: '1px solid var(--chakra-colors-primary-1000)',
      },
      _active: {
        backgroundColor: 'primary.400',
        color: 'white',
      },
      _disabled: {
        backgroundColor: 'secondary.300',
        color: 'secondary.100',
        opacity: 1,
      },
    },
    'solid-dark': {
      backgroundColor: 'white',
      color: 'primary.800',
      _hover: {
        backgroundColor: 'primary.100',
        _disabled: {
          backgroundColor: 'primary.400',
          color: 'primary.200',
          opacity: 1,
        },
      },
      _focusVisible: {
        backgroundColor: 'white',
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        border: '1px solid var(--chakra-colors-primary-800)',
      },
      _active: {
        backgroundColor: 'primary.200',
        color: 'primary.900',
      },
      _disabled: {
        backgroundColor: 'primary.400',
        color: 'primary.200',
        opacity: 1,
      },
    },
    'outline-light': {
      color: 'primary.800',
      border: '1px solid var(--chakra-colors-primary-800)',
      _hover: {
        backgroundColor: 'primary.30',
        border: '1px solid var(--chakra-colors-primary-30)',

        _disabled: {
          opacity: 1,
          border: '1px solid var(--chakra-colors-secondary-300)',
          color: 'secondary.300',
        },
      },

      _focusVisible: {
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        border: '1px solid var(--chakra-colors-primary-800)',
      },
      _active: {
        backgroundColor: 'primary.50',
        border: '1px solid var(--chakra-colors-primary-50)',
      },

      _disabled: {
        border: '1px solid var(--chakra-colors-secondary-300)',
        color: 'secondary.300',
        opacity: 1,
      },
    },
    'outline-dark': {
      color: 'white',
      border: '1px solid var(--chakra-colors-white)',
      _hover: {
        backgroundColor: 'primary.700',
        border: '1px solid var(--chakra-colors-primary-700)',
        _disabled: {
          color: 'primary.400',
          border: '1px solid var(--chakra-colors-primary-400)',
          opacity: 1,
        },
      },
      _focusVisible: {
        backgroundColor: 'primary.800',
        border: '1px solid var(--chakra-colors-info-700)',
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
      },
      _active: {
        backgroundColor: 'primary.400',
        border: '1px solid var(--chakra-colors-primary-400)',
      },
      _disabled: {
        color: 'primary.400',
        border: '1px solid var(--chakra-colors-primary-400)',
        opacity: 1,
      },
    },
    'ghost-light': {
      color: 'blue.800',
      _hover: {
        backgroundColor: 'primary.30',
        _disabled: {
          color: 'secondary.300',
          opacity: 1,
        },
      },
      _focusVisible: {
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        color: 'primary.800',
        border: '1px solid var(--chakra-colors-primary-800)',
      },
      _active: {
        backgroundColor: 'primary.50',
      },
      _disabled: {
        color: 'secondary.300',
        opacity: 1,
      },
    },
    'ghost-dark': {
      color: 'white',
      _hover: {
        backgroundColor: 'primary.700',
        _disabled: {
          color: 'secondary.400',
          opacity: 1,
        },
      },
      _focusVisible: {
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        backgroundColor: 'primary.800',
        border: '1px solid var(--chakra-colors-info-700)',
      },
      _active: {
        backgroundColor: 'primary.400',
      },
      _disabled: {
        color: 'secondary.400',
        opacity: 1,
      },
    },
    'link-light': {
      color: 'primary.800',
      textDecoration: 'underline',
      paddingX: '0.1875rem',
      paddingY: 0,
      minHeight: 'unset',
      minWidth: 'unset',
      height: 'unset',
      _hover: {
        color: 'primary.400',
        _disabled: {
          color: 'secondary.300',
          opacity: 1,
        },
      },
      _focusVisible: {
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        border: '1px solid var(--chakra-colors-primary-800)',
      },
      _active: {
        color: 'primary.300',
      },
      _disabled: {
        color: 'secondary.300',
        opacity: 1,
      },
    },
    'link-dark': {
      color: 'white',
      textDecoration: 'underline',
      paddingX: '0.1875rem',
      paddingY: 0,
      minHeight: 'unset',
      minWidth: 'unset',
      height: 'unset',
      _hover: {
        color: 'primary.200',
        _disabled: {
          color: 'primary.400',
          opacity: 1,
        },
      },
      _focusVisible: {
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        border: '1px solid var(--chakra-colors-info-700)',
      },
      _active: {
        color: 'primary.300',
      },
      _disabled: {
        color: 'primary.400',
        opacity: 1,
      },
    },
    sidebar: {
      backgroundColor: 'white',
      borderRadius: '0.25rem',
      color: 'primary.800',
      height: 'auto',
      userSelect: 'none',
      WebkitUserDrag: 'none',

      '&.active': {
        backgroundColor: 'primary.50',

        _before: {
          position: 'absolute',
          my: '7px',
          top: '0',
          left: '0',
          zIndex: '1',
          borderRadius: '0 4px 4px 0',
          width: '0.25rem',
          height: 'calc(100% - 0.875rem)',
          backgroundColor: 'primary.400',

          content: `''`,
        },

        _hover: {
          backgroundColor: 'primary.30',
        },

        _active: {
          textDecoration: 'none',
          backgroundColor: 'primary.700',
          color: 'white',
        },
      },

      _hover: {
        backgroundColor: 'primary.30',
      },

      _focusVisible: {
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
      },

      _active: {
        textDecoration: 'none',
        backgroundColor: 'primary.700',
        color: 'white',
      },
    },
    'sidebar-dark-menu': {
      backgroundColor: 'primary.700',
      borderRadius: '4px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      _hover: {
        backgroundColor: 'primary.500',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      _focusVisible: {
        backgroundColor: 'primary.100',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        textDecoration: 'none',
        borderRadius: 0,
        zIndex: 2,
      },
    },
    'sidebar-light-menu': {
      backgroundColor: 'primary.30',
      borderRadius: '4px',
      color: 'blue.500',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      _hover: {
        backgroundColor: 'primary.30',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      _focusVisible: {
        backgroundColor: 'primary.100',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        borderRadius: 0,
        textDecoration: 'none',
        zIndex: 2,
      },
    },
    'sidebar-dark-submenu': {
      backgroundColor: 'primary.700',
      borderRadius: 0,
      _hover: {
        backgroundColor: 'primary.500',
        borderRadius: 0,
      },
      _focusVisible: {
        backgroundColor: 'primary.100',
        boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
        borderRadius: 0,
        textDecoration: 'none',
        zIndex: 2,
      },
    },
    rlNavigationSolid: {
      backgroundColor: 'rgba(0,50,106,0.4)',
      border: '1px solid var(--chakra-colors-white)',
      fontSize: 'lg',
      fontWeight: 'bold',
      lineHeight: '1.125rem',
      fontStyle: 'normal',
      borderRadius: 0,

      _hover: {
        backgroundColor: '#00326A',
      },

      _focusVisible: {
        backgroundColor: '#00326A',
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        textDecoration: 'none',
      },
    },
    rlSolid: {
      display: 'inline-block',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 0,
      textTransform: 'uppercase',
      textDecoration: 'none',
      fontSize: '1.125rem',
      fontWeight: 'bold',
      cursor: 'pointer',
      padding: '10px 17px',
      '-webkit-transition': 'all 0.5s ease-out',
      '-o-transition': 'all 0.5s ease-out',
      transition: 'all 0.5s ease-out',
      backgroundColor: '#F2444D',
      borderColor: '#F2444D',

      '&:hover': {
        textDecoration: 'none',
        backgroundColor: 'rgba(242, 68, 77, 0.5)',
      },
    },
    round: {
      backgroundColor: 'primary.600',
      borderRadius: '3.125rem',
      _hover: {
        backgroundColor: 'primary.600',

        _disabled: {
          opacity: 1,
          boxShadow: 'sm',
          backgroundColor: 'basic.400',
        },
      },

      _focusVisible: {
        backgroundColor: 'primary.800',
        boxShadow: '0 0 0 4px var(--chakra-colors-info-500)',
        textDecoration: 'none',
      },
      _active: {
        backgroundColor: 'primary.700',
      },
      _disabled: {
        opacity: 1,
        boxShadow: 'sm',
        backgroundColor: 'basic.400',
      },
    },
  },
  sizes: {
    xs: {
      minHeight: '1.5rem',
      minWidth: '1.5rem',
      paddingX: 2,
      paddingY: 1,
      fontSize: 'xs',
      lineHeight: '4',
      '& svg': {
        width: '0.8rem',
        height: '0.8rem',
      },
    },
    sm: {
      minHeight: '2rem',
      minWidth: '2rem',
      paddingX: 3,
      paddingY: 1.5,
      fontSize: 'sm',
      lineHeight: '5',
      '& svg': {
        width: '0.84375rem',
        height: '0.84375rem',
      },
    },
    md: {
      minHeight: '2.75rem',
      minWidth: '2.75rem',
      paddingX: 4,
      paddingY: 3,
      fontSize: 'md',
      lineHeight: '5',
      '& svg': {
        width: '0.9375rem',
        height: '0.9375rem',
      },
    },
    lg: {
      minHeight: '3.125rem',
      minWidth: '3.125rem',
      paddingX: 6,
      paddingY: 3.5,
      fontSize: 'lg',
      lineHeight: '1.375rem',
      '& svg': {
        width: '1.03125rem',
        height: '1.03125rem',
      },
    },
    xl: {
      minHeight: '3.5rem',
      minWidth: '3.5rem',
      paddingX: 8,
      paddingY: 4,
      fontSize: 'xl',
      lineHeight: '6',
      '& svg': {
        width: '1.125rem',
        height: '1.125rem',
      },
    },
    sidebarSize: {
      minHeight: '2.375rem',
      minWidth: '2.375rem',
      paddingX: '9px',
      fontSize: 'sm',
      lineHeight: 'shorter',
    },
  },
};
