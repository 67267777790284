import { UserPurpose } from '@lon/shared/enums';

export const storeLoginData = (settings: any) => {
  if (settings?.user?.purpose === UserPurpose.PREVIEW_ACCOUNT) {
    localStorage.setItem(
      'previewLoginState',
      JSON.stringify(settings?.user?.state)
    );
  } else {
    localStorage.setItem('state', JSON.stringify(settings?.user?.state));
  }
};
