export const wrapper = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  transition: 'var(--global-transition)',
  boxShadow: 'base',
  borderRadius: '3px',
  backgroundColor: 'white',
  padding: '0.5',
  h: 'full',
  color: 'blue.500',
  _hover: {
    outline: '3px solid',
    outlineColor: 'blue.100',
    backgroundColor: 'blue.50',
    transform: 'translateY(calc(-1 * var(--global-grid-size)))',
  },
  _focusWithin: {
    transform: 'translateY(calc(-1 * var(--global-grid-size)))',
  },
};

export const link = {
  textDecoration: 'none',
  wordBreak: 'break-word',
  _hover: {
    textDecoration: 'none',
  },
  _before: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '1',
    borderRadius: 'var(--global-border-radius)',
    width: 'full',
    height: 'full',
    content: `''`,
  },
  _focusVisible: {
    outlineColor: 'transparent',
    outlineStyle: 'solid',
    _before: {
      boxShadow: 'var(--global-outline)',
    },
  },
};

export const icon = {
  mr: '3',
  '&>svg': {
    width: '10 !important',
    height: '10 !important',
  },
};
