import { ProfileTypeEnum } from '@lon/shared/enums';
import {
  CreateResourceInput,
  DaAssignType,
  DistrictCreateInput,
  GetAllOwnerAssignmentsQuery,
  ImmediatelyShareResults,
  StemscopesCreateInput,
  TeacherAssignmentsCreateInput,
} from '@lon/shared/requests';
import { PlanElementType } from '@lon/suit/types';

export interface FormValues {
  elementsIds: string[];
  classes?: {
    classId: string;
    scopeId?: string;
  }[];
  subgroups?: {
    classId: string;
    subgroupIds: string[];
    scopeId?: string;
  }[];
  individualStudents?: {
    classId: string;
    studentIds: string[];
    scopeId?: string;
  }[];
  startDate?: Date;
  endDate?: Date | null;
  lateTurnIn?: boolean;
  immediatelyShareResults?: ImmediatelyShareResults;
  randomizeQuestionsOrder?: boolean;
  teacherAccess?: string[];
  gradeLevels?: string[];
  schools?: { schoolId: string; schoolGradeLevels: string[] }[];
}

export interface ElementData {
  scopeName: string;
  elementName: string;
  sectionName: string;
  curriculumArea?: string;
  gradeLevel?: string;
  projectId?: string;
  scopeId?: string;
  sectionId?: string;
  elementId?: string;
  viewId?: string;
}

export interface AssessmentData {
  name?: string;
  assessmentId?: string | null;
}

export type ElementsData = ElementData | PlanElementType[];

export type GeneralInputData = Omit<StemscopesCreateInput, 'name'> &
  Omit<TeacherAssignmentsCreateInput, 'name'> &
  Omit<DistrictCreateInput, 'name'>;

export interface AssignmentFormDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  createAssignment?: (props: {
    values: FormValues;
    generalInputData: GeneralInputData;
    assignType: DaAssignType;
    isGoogle?: boolean;
  }) => Promise<any>;
  assignmentLoading: boolean;
  createResource?: (props: {
    values: FormValues;
    generalInputData: Omit<CreateResourceInput, 'name' | 'resourceSource'>;
  }) => Promise<any>;
  resourceLoading?: boolean;
  assessmentData?: AssessmentData;
  elementsData?: ElementsData;
  drawerHeader?: string;
  backBtnText?: string;
  saveBtnText?: string;
  dontSaveBtnText?: string;
  confirmHeader?: string;
  confirmText?: React.ReactNode;
  assessmentAssignerType?: ProfileTypeEnum;
  assessmentTitle?: string;
  isReadOnlyMode?: boolean;
  isEditAssignmentMode?: boolean;
  defaultFormValues?: Partial<FormValues>;
  isAssignmentWithoutLearnosity?: boolean;
  isPlanElement?: boolean;
  hasManuallyGradableQuestions?: boolean;
}

export interface TabProps {
  manageTabIndex: number;
  assignmentTypeTabIndex: number;
  ownerAssignments: GetAllOwnerAssignmentsQuery | undefined;
  loadingOwnerAssignments: boolean;
  assessmentTitle: string;
  setManageTabIndex: React.Dispatch<React.SetStateAction<number>>;
  setAssignmentTypeTabIndex: React.Dispatch<React.SetStateAction<number>>;
  setMainTabIndex: React.Dispatch<React.SetStateAction<number>>;
  closeForm: () => void;
  isDistrictAssessment?: boolean;
  isDistrictAssessmentForOneSchool?: boolean | null;
  isAssignmentWithoutLearnosity?: boolean;
}

export type GetFieldLocation = (
  field: string,
  isMultipleAssignments: boolean
) => {
  mainTab: number;
  manageTab?: number;
};

export enum AssignmentType {
  GradeableAssignment = 0,
  Resource = 1,
}

export interface Classes {
  classId: string;
  studentIds?: string[];
  subgroupIds?: string[];
  scopeId?: string;
}

export interface AssignmentFormContextProps {
  immediatelyShareResults: boolean;
  setImmediatelyShareResults: React.Dispatch<React.SetStateAction<boolean>>;
  assessmentData?: AssessmentData;
  elementsData?: ElementsData;
  withOtherAssignment: boolean;
  isAssessment: boolean;
  isTeacherAssessment?: boolean;
  isDistrictAssessment?: boolean;
  defaultValues?: FormValues;
  isReadOnlyMode?: boolean;
  isEditAssignmentMode: boolean;
  hasManuallyGradableQuestions: boolean;
  isLeaderSuit: boolean;
}

export enum TabKey {
  myPlan = 'myPlan',
  generalSettings = 'generalSettings',
  manageAssignees = 'manageAssignees',
}

export interface Tab {
  key: TabKey;
  title: string;
  tab: React.ReactElement;
  TabPanel: React.FC<TabProps>;
}

export type GetFormLabelProps = (props: {
  isEditAssignmentMode: boolean;
  isReadOnlyMode: boolean;
  isDirty: boolean;
}) => React.ReactNode;
