import { MenuItem, Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';

const Settings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Tooltip variant="dark" label={t('assignments.settingsTooltip')}>
      <MenuItem
        //TODO enable when DA actions have been developed
        isDisabled={true}
        gap={3}
        py={2}
        aria-label={t('assignments.settings')}
      >
        <Icon name="settings" size="lg" />
        <Text as="span" variant="s2">
          {t('assignments.settings')}
        </Text>
      </MenuItem>
    </Tooltip>
  );
};

export default Settings;
