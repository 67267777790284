import Spinner from '../../../../../spinner/Spinner';
import { ElementsLoaderProps } from '../../duck';
import { Flex, Text } from '@chakra-ui/react';

const Loader: React.FC<ElementsLoaderProps> = ({ isLoading, message }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Flex
      height="180px"
      color="primary.800"
      direction="column"
      justify="center"
      align="center"
      border="1px solid #e1e6f0"
      borderRadius="4px"
      mt="6"
    >
      <Text variant="n3" pb="8">
        {message}
      </Text>
      <Spinner variant="dark" />
    </Flex>
  );
};

export default Loader;
