import { lazyWithRetry } from '@lon/shared/utils';

const CreateStudent = lazyWithRetry(
  () => import('./create-student'),
  'CreateStudent'
);
const EditStudent = lazyWithRetry(
  () => import('./edit-student'),
  'EditStudent'
);

export { CreateStudent, EditStudent };
