import { BaseRadioButtonListProps } from '../duck';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const BaseRadioButtonList: React.FC<BaseRadioButtonListProps> = ({
  data,
  value = '',
  selectedValue,
  errorMessage,
  emptyMessage,
  name,
  onChange = () => {},
  title,
  isDisabled = false,
  renderRadioButtonLabel,
  renderSelectedValueBadge,
  direction = 'column',
  ...rest
}) => {
  const { t } = useTranslation();

  const renderRadioButtons = data?.map((item, index) => (
    <ListItem key={item?.value} mb={index === data?.length - 1 ? '0' : '3'}>
      <Radio
        key={item.value}
        value={item.value}
        isChecked={item.value === value}
        isDisabled={item?.isDisabled}
        w="fit-content"
      >
        {renderRadioButtonLabel ? (
          renderRadioButtonLabel(item.label)
        ) : (
          <Text variant="n3" ml="1">
            {item.label}
          </Text>
        )}
      </Radio>
    </ListItem>
  ));

  return (
    <FormControl isInvalid={!!errorMessage}>
      {direction === 'column' && (
        <Accordion
          defaultIndex={[0]}
          allowMultiple
          color="primary.800"
          {...rest}
        >
          <AccordionItem
            isDisabled={isDisabled}
            borderRadius="6px"
            border="1px solid"
            borderWidth={!errorMessage ? '1px' : '2px'}
            borderColor={!errorMessage ? '#E1E7F0' : 'danger.600'}
            _last={{
              borderBottomWidth: '2px',
            }}
          >
            {({ isExpanded }) => (
              <>
                <Flex
                  align="center"
                  borderBottomRadius={isExpanded ? '0' : 'inherit'}
                  borderTopRadius="inherit"
                  bgColor="secondary.50"
                  px="6"
                >
                  <Flex
                    flex="1"
                    gap="4"
                    color={isDisabled ? 'secondary.400' : 'primary.800'}
                  >
                    <Text variant="n3">{title}</Text>
                    {renderSelectedValueBadge ? (
                      renderSelectedValueBadge(selectedValue, isDisabled)
                    ) : (
                      <Badge
                        variant={
                          isDisabled
                            ? 'disabled'
                            : !selectedValue
                            ? 'recordsError'
                            : 'recordsInfo'
                        }
                      >
                        {selectedValue || t('radioButtonList.undefined')}
                      </Badge>
                    )}
                  </Flex>
                  <h5>
                    <AccordionButton _hover={{ background: 'none' }}>
                      <Text variant="s2" textDecor="underline">
                        {isExpanded
                          ? t('radioButtonList.showLess')
                          : t('radioButtonList.showMore')}
                      </Text>
                    </AccordionButton>
                  </h5>
                </Flex>
                <AccordionPanel py="4" px="6">
                  {data?.length ? (
                    <List textAlign="left">
                      <RadioGroup onChange={onChange} value={value}>
                        {renderRadioButtons}
                      </RadioGroup>
                    </List>
                  ) : (
                    <Box color="danger.800" textAlign="left">
                      <Text variant="n2">{t('radioButtonList.note')}</Text>
                      <Text variant="n3">{emptyMessage}</Text>
                    </Box>
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      )}
      {direction === 'row' && (
        <Flex
          direction="column"
          color={isDisabled ? 'secondary.400' : 'primary.800'}
        >
          <Text
            variant="n3"
            mb="2"
            fontSize="1rem"
            lineHeight="1.625rem"
            fontWeight="700"
          >
            {title}
          </Text>
          <RadioGroup
            onChange={onChange}
            value={value}
            display="flex"
            gap={8}
            p={4}
            borderRadius="4px"
            border="1px solid"
            borderColor="secondary.200"
          >
            {data?.map((item, index) => (
              <Radio
                key={item.value}
                value={item.value}
                isChecked={item.value === value}
                isDisabled={item?.isDisabled}
                w="fit-content"
              >
                {renderRadioButtonLabel ? (
                  renderRadioButtonLabel(item.label)
                ) : (
                  <Text variant="n3" ml="1">
                    {item.label}
                  </Text>
                )}
              </Radio>
            ))}
          </RadioGroup>
        </Flex>
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default BaseRadioButtonList;
