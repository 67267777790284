import { BlockerFunction } from '@remix-run/router';
import { useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

export const usePrompt = (
  onLocationChange: BlockerFunction,
  hasUnsavedChanges: boolean
): void => {
  const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }

    return () => {
      if (blocker.state === 'blocked') {
        blocker.reset();
      }
    };
  }, [blocker.state]);
};
