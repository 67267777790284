export const breakpoints = {
  xs: '320px',
  b320: '320px',
  b321: '321px',
  b375: '375px',
  b430: '430px',
  sm: '480px',
  b480: '480px',
  b744: '744px',
  md: '768px',
  b768: '768px',
  lg: '1024px',
  b1024: '1024px',
  xl: '1280px',
  b1280: '1280px',
  b1281: '1281px',
  b1440: '1440px',
  b1680: '1680px',
  '2xl': '1920px',
  b1920: '1920px',
};
