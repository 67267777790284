import { Flex, Skeleton } from '@chakra-ui/react';
import { useThemeStyleValue } from '@lon/shared/hooks';

const DescriptionSkeleton = () => (
  <Flex
    borderRadius="md"
    backgroundColor={useThemeStyleValue('white', 'secondary.1000')}
    boxShadow="xs"
    direction={{ base: 'column', md: 'row' }}
    {...useThemeStyleValue(
      {},
      {
        border: '1px solid',
        borderColor: 'white',
      }
    )}
  >
    <Flex
      direction="column"
      w={{ base: 'full', md: '50%' }}
      px={8}
      py={7}
      borderRightWidth={{ base: 0, md: '1px' }}
      borderBottomWidth={{ base: '1px', md: 0 }}
      borderColor="secondary.200"
    >
      <Skeleton h="26px" mb={4} />
      <Skeleton h="24px" mb={2} />
      <Skeleton h="48px" mb={4} />

      <Flex align="flex-end" gap={6}>
        <Skeleton h="50px" w="100px" />
        <Skeleton h="50px" w="100px" />
      </Flex>
    </Flex>
    <Flex direction="column" w={{ base: 'full', md: '50%' }} p={8} gap={6}>
      <Skeleton h="46px" />
      <Skeleton h="46px" />
      <Skeleton h="46px" />
    </Flex>
  </Flex>
);

export default DescriptionSkeleton;
