export const Card = {
  baseStyle: {
    container: {
      color: 'primary.800',
      boxShadow: '0px 5px 20px -2px rgba(43, 54, 70, 0.07)',
    },
  },
  variants: {
    tile: {
      container: {
        bgColor: 'white',
        boxShadow: '0px 5px 20px -2px rgba(43, 54, 70, 0.07)',
        cursor: 'pointer',
        borderRadius: 'md',
        _hover: {
          bgColor: 'secondary.50',
        },
        '&.active': {
          position: 'relative',
          bgColor: 'secondary.50',
          _before: {
            content: `''`,
            position: 'absolute',
            top: '9%',
            right: 0,
            zIndex: 1,
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            background: 'primary.400',
            width: '4px',
            height: '82%',
          },
        },
      },
    },
  },
};
