import { StudentAssignment } from '@lon/shared/requests';
import getGradesFromAnswers from './getGradesFromAnswers';

export const calcCSVGrade = (
  assignment?: StudentAssignment | null,
  isAverage?: boolean
) => {
  const answers =
    typeof assignment?.answers === 'string' && assignment?.answers?.length
      ? JSON.parse(assignment?.answers || '[]')
      : assignment?.answers;
  const { grade: gradeFromAnswers } = getGradesFromAnswers(answers);
  const grade = isAverage
    ? Number(assignment?.grade)
    : typeof gradeFromAnswers === 'number'
    ? Math.round(gradeFromAnswers)
    : gradeFromAnswers;
  const isCompleted = !!assignment?.submittedAt && !!assignment?.completedAt;

  if (!assignment && !isAverage) {
    return ' ';
  } else if (
    (isCompleted || isAverage) &&
    !isNaN(grade as any) &&
    typeof grade === 'number'
  ) {
    return grade;
  } else {
    return '-';
  }
};
