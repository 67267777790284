import { isEmpty, toArray } from 'lodash-es';
import { Element, TransformedElement } from '@lon/suit/types';

type GetElements = (
  elementsBySection?: any,
  sectionId?: string
) => TransformedElement[];

export const getElements: GetElements = (elementsBySection = {}, sectionId) => {
  if (isEmpty(elementsBySection)) {
    return [];
  }

  if (!sectionId) {
    return toArray(elementsBySection)
      .reduce((acc, item) => [...acc, ...toArray(item)], [])
      .filter(
        (element: Element) => element.metadata?.sectionType !== 'Landing Page'
      );
  }

  return toArray(elementsBySection?.[sectionId]);
};
