const newColors = {
  red: {
    50: '#FFF8F8',
    100: '#FFF5F5',
    200: '#FFEEEC',
    300: '#FFD8D8',
    400: '#FFA5A5',
    500: '#FF9393',
    600: '#F65763',
    700: '#E00027',
    800: '#C10013',
    900: '#A80000',
    1000: '#860000',
  },
  secondary: {
    50: '#F8F9FC',
    100: '#F1F5FB',
    200: '#E1E7F0',
    300: '#C8D1DE',
    400: '#A0AEC2',
    500: '#8A99AF',
    700: '#64758E',
    800: '#45546A',
    900: '#2B3646',
    950: '#1A222E',
    1000: '#0A0F16',
  },
  primary: {
    30: '#E4EDF8',
    50: '#D9E3F0',
    100: '#B7C5DE',
    200: '#7F94B4',
    300: '#6D80A1',
    400: '#586F96',
    500: '#4B638E',
    600: '#415984',
    700: '#36507F',
    800: '#1E4176',
    900: '#1E365F',
    1000: '#1A2C49',
  },
  orange: {
    100: '#FFF7EF',
    200: '#FFF1DC',
    300: '#FEDEBC',
    400: '#FFBE5E',
    500: '#FFB05F',
    600: '#FF9A03',
    700: '#F58D22',
    800: '#BB502F',
    900: '#9C3615',
    1000: '#7F2B07',
  },
  yellow: {
    100: '#FFF7DC',
    200: '#FFF0BC',
    500: '#FFE58B',
    600: '#FFDB5E',
    800: '#97470E',
    900: '#863800',
    1000: '#652A00',
  },
  green: {
    100: '#F6FFF9',
    200: '#D5F3E6',
    500: '#63D29D',
    600: '#24A668',
    800: '#0C7A4C',
    900: '#04653D',
    1000: '#0B462A',
  },
  olive: {
    100: '#F1F8EC',
    200: '#E4EEDC',
    500: '#C6D7B9',
    600: '#AABF98',
    800: '#5B7944',
    900: '#41592D',
    1000: '#2E421E',
  },
  beige: {
    100: '#FBF8EE',
    200: '#F4F0DE',
    500: '#E9E2CB',
    600: '#D0C7A9',
    800: '#877F63',
    900: '#50472A',
    1000: '#3C3316',
  },
  sky: {
    100: '#F4FAFF',
    200: '#E0F7FF',
    300: '#C0EFFF',
    400: '#97E2FF',
    500: '#4ECDFE',
    600: '#26B3E9',
    700: '#149CD2',
    800: '#0784B5',
    900: '#00709D',
    1000: '#005679',
  },
  blue: {
    100: '#F2F9FF',
    200: '#DFF0FF',
    500: '#ABCAE7',
    600: '#8FB7DC',
    800: '#225B90',
    900: '#1E486F',
    1000: '#11304D',
  },
  violet: {
    100: '#EFEDFF',
    200: '#E3DEFF',
    500: '#D7CDF2',
    600: '#C1AEDA',
    800: '#664B88',
    900: '#462D67',
    1000: '#321B4E',
  },
  plum: {
    100: '#FFF3FD',
    200: '#FBDEF5',
    500: '#E5AEDA',
    600: '#D591C6',
    800: '#682769',
    900: '#4E174F',
    1000: '#3B0D3C',
  },
  fuchsia: {
    100: '#FFF5FC',
    200: '#FFECFA',
    500: '#FFA5EB',
    600: '#E554C6',
    800: '#A72E8C',
    900: '#780A60',
    1000: '#570044',
  },
  white: '#FFFFFF',
};

export default newColors;
