export const clearStorage = ({
  exceptions,
  blacklist,
  pathPattern,
}: {
  exceptions?: string[];
  blacklist?: string[];
  pathPattern?: string;
}) => {
  if (exceptions) {
    Object.keys(localStorage).forEach((key) => {
      if (!exceptions.includes(key)) {
        delete localStorage[key];
      }
    });
    return;
  }

  if (blacklist) {
    Object.keys(localStorage).forEach((key) => {
      if (blacklist.includes(key)) {
        delete localStorage[key];
      }
    });
    return;
  }

  if (pathPattern) {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes(pathPattern)) {
        delete localStorage[key];
      }
    });
    return;
  }
};
