import { useFetchImage } from '../../../../duck/hooks';
import { Text } from '@chakra-ui/react';
import { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { resizeImage } from '@lon/shared/utils';
import { LinkCard } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { SearchableScope } from '@lon/suit/types';

const CardItem: React.FC<{ scope: SearchableScope; itemPath?: string }> = ({
  scope,
  itemPath = routes.scopes.show,
}) => {
  const imageUrl = useFetchImage(
    resizeImage({ src: scope?.image, height: 600, width: 800 })
  );

  return (
    <LinkCard
      linkContent={
        <Text
          variant="h6"
          py={2}
          px={4}
          color={useThemeStyleValue('primary.800', 'white')}
          flex="1"
          borderTop={useThemeStyleValue('none', '1px solid white')}
        >
          {scope.displayName}
        </Text>
      }
      to={{
        pathname: generatePath(itemPath, {
          scopeId: scope.id,
          projectId: scope.projectId,
        }),
      }}
      imageUrl={imageUrl}
      plugImageUrl={'suit/assets/horizontal-plug.svg'}
      bgColor={useThemeStyleValue('white', 'secondary.1000')}
      as="li"
      data-testid="card"
      border={useThemeStyleValue('none', '1px solid white')}
    >
      <Text
        variant="s2"
        py={2}
        px={4}
        color={useThemeStyleValue('secondary.700', 'white')}
        borderTop="1px solid"
        borderTopColor={useThemeStyleValue('secondary.100', 'white')}
      >
        {scope.metadata?.gradeLevel}
      </Text>
    </LinkCard>
  );
};

export default memo(CardItem);
