import { ApplicationEnum } from '@lon/shared/enums';
import { patchLocalStorage } from './patchLocalStorage';

export const getLastApplicationBySignature = (signature: string) => {
  const lastApplicationBySignature = JSON.parse(
    localStorage.getItem('lastApplicationBySignature') || '{}'
  );

  return lastApplicationBySignature[signature];
};

export const setLastApplicationBySignature = ({
  signature,
  application,
}: {
  signature: string;
  application: ApplicationEnum;
}) => {
  patchLocalStorage('lastApplicationBySignature', {
    [signature]: application,
  });
};

export const getSignature = (): string =>
  localStorage.getItem('signature') as string;

export const getLastDistrictBySignature = () => {
  const signature = getSignature();

  return JSON.parse(localStorage.getItem('lastDistrictBySignature') || '{}')?.[
    signature
  ];
};
