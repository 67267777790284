export const handleSelectToGoogleClassroom = ({
  assignmentId,
  classId,
  queryParams,
  title,
  index = 1,
}: {
  assignmentId: string;
  classId: string;
  queryParams: string;
  title: string;
  index?: number;
}) => {
  const params = new URLSearchParams({
    title,
    url: `${window.location.origin}/suite/google-classroom/class/${classId}/assignments/${assignmentId}?${queryParams}`,
  });

  const url = `https://classroom.google.com/u/1/share?${params}`;

  // browser sometimes doesn't open new tabs instead it opens 1 tab
  setTimeout(() => {
    window.open(`${url}`, '_blank', 'width=700,height=600,left=100,top=75');
  }, index * 200);
};
