import { Badge } from '@chakra-ui/react';
import { isEqual } from 'lodash-es';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StatusLabelProps } from './duck';

const StatusLabel: FC<StatusLabelProps> = ({
  subgroupId,
  defaultValues,
  fieldErrors,
}) => {
  const formProviderProps = useFormContext();
  const { t } = useTranslation();

  const subgroups = formProviderProps.watch('subgroups');

  const currentSubgroup = subgroups?.find((subgroup: any) => {
    return subgroup?._id === subgroupId;
  });

  const currentSubgroupInitial = defaultValues?.subgroups?.find(
    (subgroup: any) =>
      subgroup?._id && subgroupId && subgroup?._id === subgroupId
  );

  if (fieldErrors) {
    return <Badge colorScheme="red">{t('subgroups.errorTag')}</Badge>;
  }

  if (!currentSubgroupInitial) {
    return <Badge colorScheme="green">{t('subgroups.newTag')}</Badge>;
  } else {
    const isChanged = !isEqual(currentSubgroupInitial, currentSubgroup);

    return isChanged ? (
      <Badge colorScheme="orange">{t('subgroups.editTag')}</Badge>
    ) : null;
  }
};

export default StatusLabel;
