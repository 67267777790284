// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getAlert = (theme: ThemeEnum) => {
  const contrastCloseBtn = {
    top: '16px',
    right: '16px',
    bgColor: 'secondary.1000',
    color: 'white',
    _hover: {
      opacity: 0.7,
    },
    _focusVisible: {
      boxShadow: '0 0 0 4px var(--chakra-colors-teal-500)',
    },
  };

  const contrastTheme = getThemeStyleValue(
    {
      info: {},
      error: {},
      success: {},
      warning: {},
    },
    {
      info: {
        bgColor: 'white',
        color: 'secondary.1000',
        borderColor: 'white',
        '[type="button"]': contrastCloseBtn,
      },
      error: {
        bgColor: 'fuchsia.400',
        color: 'secondary.1000',
        borderColor: 'fuchsia.400',
        '[type="button"]': contrastCloseBtn,
      },
      success: {
        bgColor: 'teal.500',
        color: 'secondary.1000',
        borderColor: 'teal.500',
        '[type="button"]': {
          ...contrastCloseBtn,
          _focusVisible: {
            boxShadow: '0 0 0 4px var(--chakra-colors-yellow-hc)',
          },
        },
      },
      warning: {
        bgColor: 'yellow.hc',
        color: 'secondary.1000',
        borderColor: 'yellow.hc',
        '[type="button"]': contrastCloseBtn,
      },
    }
  )(theme);

  return {
    baseStyle: {
      container: {
        borderRadius: '6px',
        p: '4',
        pl: '6',
        '[type="button"]': {
          top: '16px',
          right: '16px',
        },
        ...contrastTheme.error,
        color: 'black',
      },
      title: {
        paddingRight: '5',
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: 700,
        pr: '56px',
        color: getThemeStyleValue('inherit', '#0A0F16FF')(theme),
      },
      description: {
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontWeight: 400,
        pt: 1,
        pr: '56px',
        color: getThemeStyleValue('inherit', '#0A0F16FF')(theme),
      },
      icon: {
        marginRight: '18px',
        paddingBottom: 1,
        color: getThemeStyleValue('inherit', '#0A0F16FF')(theme),
      },
    },
    variants: {
      info: {
        container: {
          bgColor: 'info.100',
          color: 'primary.800',
          ...contrastTheme.info,
        },
      },
      error: {
        container: {
          bgColor: 'danger.100',
          color: 'danger.700',
          ...contrastTheme.error,
        },
      },
      success: {
        container: {
          bgColor: 'success.100',
          color: 'success.700',
          ...contrastTheme.success,
        },
      },
      'error-dark': {
        container: {
          bgColor: 'danger.text',
          color: 'white',
          ...contrastTheme.error,
        },
      },
      warning: {
        container: {
          bgColor: 'calendar.yellow.extraLight',
          color: 'calendar.yellow.800',
          ...contrastTheme.warning,
        },
      },
      'warning-dark': {
        container: {
          bgColor: 'yellow.800',
          color: 'white',
          ...contrastTheme.warning,
        },
      },
      'success-dark': {
        container: {
          bgColor: 'success.800',
          color: 'white',
          ...contrastTheme.success,
        },
      },
      'info-dark': {
        container: {
          bgColor: '#225B90',
          color: 'white',
          ...contrastTheme.info,
        },
      },
      'error-light': {
        container: {
          bgColor: 'danger.extraLight',
          color: 'danger.800',
          border: '1px',
          borderColor: 'danger.1',
          ...contrastTheme.error,
        },
      },
      'warning-light': {
        container: {
          bgColor: 'warning.extraLight',
          color: 'yellow.d',
          border: '1px',
          borderColor: 'warning.light',
          ...contrastTheme.warning,
        },
      },
      'success-light': {
        container: {
          bgColor: 'olive.50',
          color: 'green.text',
          border: '1px',
          borderColor: 'green.extraLight',
          ...contrastTheme.success,
        },
      },
      'info-light': {
        container: {
          bgColor: '#F2F9FF',
          color: '#1E486F',
          border: '1px',
          borderColor: '#DFF0FF',
          ...contrastTheme.info,
        },
      },
      formWarning: {
        container: {
          bgColor: 'calendar.yellow.extraLight',
          color: 'calendar.yellow.800',
          '[type="button"]': {
            top: 'unset',
            right: 'unset',
          },
        },
      },
      formError: {
        container: {
          bgColor: 'danger.100',
          color: 'danger.800',
          ...contrastTheme.error,
        },
      },
    },
  };
};
