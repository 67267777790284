import { GetClassAndSubgroupQuery } from '@lon/shared/requests';

export const getClassStudents = (data?: GetClassAndSubgroupQuery) => {
  return (
    data?.class?.classStudents?.collection
      ?.filter(
        (item) =>
          !data?.studentSubgroup?.students?.collection?.find(
            (subgroupStudent) => subgroupStudent?._id === item?.student?._id
          )
      )
      .map((item) => ({
        id: item?.student?._id,
        grade: item?.student?.gradeLevel,
        name: {
          firstName: item?.student?.firstName,
          lastName: item?.student?.lastName,
          userName: item?.student?.username,
        },
      }))
      .sort((a, b) => {
        // TODO: update selection the sorting value when the student name display setting is implemented
        const first = (a.name.lastName || '') + a.name.firstName;
        const second = (b.name.lastName || '') + b.name.firstName;
        // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
        return first.localeCompare(second, 'en', { numeric: true });
      }) || []
  );
};

export const getSubgroupStudents = (data?: GetClassAndSubgroupQuery) => {
  return (
    data?.studentSubgroup?.students?.collection
      ?.map((subgroupStudent) => {
        const currentStudent = data?.class?.classStudents?.collection?.find(
          (classStudent) => subgroupStudent?._id === classStudent?.studentId
        );

        return {
          id: currentStudent?.student?._id,
          grade: currentStudent?.student?.gradeLevel,
          name: {
            firstName: currentStudent?.student?.firstName,
            lastName: currentStudent?.student?.lastName,
            userName: currentStudent?.student?.username,
          },
        };
      })
      .sort((a, b) => {
        // TODO: update selection the sorting value when the student name display setting is implemented
        const first = (a.name.lastName || '') + a.name.firstName;
        const second = (b.name.lastName || '') + b.name.firstName;
        // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
        return first.localeCompare(second, 'en', { numeric: true });
      }) || []
  );
};
