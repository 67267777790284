import { random, shuffle } from "lodash-es";
import { PASSWORD_CHARS } from "@lon/shared/constants";

export const generatePassword = (passwordLength: number) => {
  const symbolsLength = random(1, 3);
  const numsLength = random(2, passwordLength - symbolsLength - 4);
  const upperLength = random(
    1,
    passwordLength - numsLength - symbolsLength - 2
  );
  const charsLength = passwordLength - numsLength - upperLength - symbolsLength;
  const variationsCount = [numsLength, upperLength, charsLength, symbolsLength];

  const generatedPassword = [];

  for (let i = 0; i < variationsCount.length; i++) {
    for (let j = 0; j < variationsCount[i]; j++) {
      generatedPassword.push(
        PASSWORD_CHARS[i][Math.floor(Math.random() * PASSWORD_CHARS[i].length)]
      );
    }
  }

  return shuffle(generatedPassword).join('');
};
