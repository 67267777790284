import { inputsTypes } from '../duck';
import { InputLayout } from '../input-layout';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import * as React from 'react';
import { RefCallBack, useController, useFormContext } from 'react-hook-form';

interface ControlledNumberInputProps
  extends Omit<inputsTypes.BaseInputProps, 'name'>,
    Omit<NumberInputProps, 'onChange'> {
  name?: string;
  hasStepper?: boolean;
  innerRef?: React.RefObject<HTMLInputElement> | RefCallBack;
  errorMessage?: string;
  onChange?(value: number | null): void;
}

const ControlledNumberInput: React.FC<ControlledNumberInputProps> = ({
  name,
  hasStepper,
  label,
  inputLayoutProps,
  isRequired,
  innerRef,
  errorMessage,
  onChange,
  value,
  placeholder,
  borderRadius,
  ...rest
}) => (
  <InputLayout
    errorMessage={errorMessage}
    id={name || ''}
    label={label}
    isRequired={isRequired}
    input={
      <NumberInput
        min={0}
        value={value === null ? '' : value}
        onChange={(_, valueAsNumber) => {
          if (onChange) {
            onChange(Number.isNaN(valueAsNumber) ? null : valueAsNumber);
          }
        }}
        {...rest}
      >
        <NumberInputField
          ref={innerRef}
          placeholder={placeholder}
          borderRadius={borderRadius}
        />
        {hasStepper && (
          <NumberInputStepper>
            <NumberIncrementStepper border={0} />
            <NumberDecrementStepper border={0} />
          </NumberInputStepper>
        )}
      </NumberInput>
    }
    {...inputLayoutProps}
  />
);

const FormNumberInput: React.FC<
  ControlledNumberInputProps & { name: string }
> = ({ name, ...rest }) => {
  const { control } = useFormContext();

  const {
    field: { onBlur, onChange, name: fieldName, ref, value },
    formState: { errors },
  } = useController({ name, control });

  return (
    <ControlledNumberInput
      onChange={onChange}
      onBlur={onBlur}
      name={fieldName}
      innerRef={ref}
      value={value}
      errorMessage={get(errors, `${name}.message`) as unknown as string}
      {...rest}
    />
  );
};

const Input: React.FC<ControlledNumberInputProps> = ({ name, ...props }) => {
  if (name) {
    return <FormNumberInput name={name} {...props} />;
  }
  return <ControlledNumberInput {...props} />;
};

export default Input;
