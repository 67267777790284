import { ScopeSection } from '../scopes';
import { lazyWithRetry } from '@lon/shared/utils';
import { ScopeView } from './components/show/components';

const Scope = lazyWithRetry(() => import('./components/show'), 'Scope');
const Element = lazyWithRetry(
  () => import('./components/show/components/element'),
  'Element'
);

export * from './AllScopes';
export { Scope, Element, ScopeSection, ScopeView };
