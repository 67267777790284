import { Text } from '@chakra-ui/react';
import React from 'react';
import { Icon } from '@lon/shared/components';
import { TemplateTitleValidationStatusType } from '@lon/suit/types';

const TemplateUniqueTitleValidationMsg: React.FC<{
  type: TemplateTitleValidationStatusType;
  successMsg: string;
  failedMsg: string;
  errorMsg: string;
}> = ({ type, successMsg, failedMsg, errorMsg }) => {
  switch (type) {
    case TemplateTitleValidationStatusType.SUCCESS:
      return (
        <>
          <Icon name="check-circle-outlined" color="#41592D" size="lg" />
          <Text variant="s2" color="olive.100">
            {successMsg}
          </Text>
        </>
      );
    case TemplateTitleValidationStatusType.FAILED:
      return (
        <>
          <Icon name="close-circle-outlined" color="#C10013" size="lg" />
          <Text variant="s2" color="danger.800">
            {failedMsg}
          </Text>
        </>
      );
    case TemplateTitleValidationStatusType.ERROR:
      return (
        <>
          <Icon name="close-circle-outlined" color="#C10013" size="lg" />
          <Text variant="s2" color="danger.800">
            {errorMsg}
          </Text>
        </>
      );
    default:
      return <></>;
  }
};

export default TemplateUniqueTitleValidationMsg;
