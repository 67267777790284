import { LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';

export const NewLink: React.FC<
  ChakraLinkProps & {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    isDisabled?: boolean;
  }
> = ({ children, leftIcon, rightIcon, isDisabled, ...props }) => {
  return (
    <ChakraLink
      {...props}
      {...(isDisabled
        ? {
            pointerEvents: 'none',
            cursor: 'not-allowed',
            opacity: 0.4,
          }
        : {})}
    >
      {leftIcon}
      {children}
      {rightIcon}
    </ChakraLink>
  );
};

export default NewLink;
