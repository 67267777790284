import { string } from 'yup';
import { specialCharacters } from '@lon/shared/constants';
import { getValidationMessages } from './getValidationMessages';

const getPasswordValidation = (isAdmin: boolean) => {
  const min = isAdmin ? 16 : 12;

  return string()
    .required(getValidationMessages().required)
    .matches(/^(?!.* )/, getValidationMessages().noSpaces)
    .min(min, getValidationMessages(min).passwordLength)
    .matches(/\d+/, getValidationMessages().atLeastOneDigit)
    .matches(/[a-z]+/, getValidationMessages().atLeastOneLowerCase)
    .matches(/[A-Z]+/, getValidationMessages().atLeastOneUpperCase)
    .matches(
      specialCharacters,
      getValidationMessages().atLeastOneSpecialCharacter
    )
    .when(['firstName', 'lastName'], {
      is: (firstName: string, lastName: string) => !!firstName || !!lastName,
      then: (schema) =>
        schema.test(
          'password',
          getValidationMessages().noFirstOrLastName,
          function (value) {
            const { firstName, lastName } = this.parent;
            if (!value) {
              return true;
            }

            if (firstName && lastName) {
              return new RegExp(`^((?!${firstName}|${lastName}).)*$`, 'i').test(
                value
              );
            } else if (firstName) {
              return !new RegExp(firstName, 'i').test(value);
            } else {
              return !new RegExp(lastName, 'i').test(value);
            }
          }
        ),
    });
};

export default getPasswordValidation;
