import { Box, VStack } from '@chakra-ui/react';
import { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { WorkingLocation } from '@lon/shared/contexts';
import { ShareResults, SignAssignmentServices } from '@lon/shared/requests';
import {
  useGetFeedbacksAndScores,
  useRenderReportsLearnosity,
} from '@lon/suit/hooks';
import { resizeInputs } from '@lon/suit/utils';
import { AssessmentReportProps, reportId } from './duck';
import { Grading } from './components';
import styles from './AssessmentReport.module.css';

const AssessmentReport: React.FC<AssessmentReportProps> = ({
  setLearnosityLoading,
  learnosityLoading,
  id,
  shareResults,
  isDistrictAssignment,
  userId,
  isParent,
  parentStudentId,
}) => {
  const location = useLocation();
  const { currentSchoolId: schoolId } = useContext(WorkingLocation);

  const studentId = isParent ? parentStudentId : userId;
  const currentSchoolId = isParent ? location.state?.schoolId : schoolId;

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let prevWidth = 0;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.borderBoxSize?.[0].inlineSize;
        if (typeof width === 'number' && width !== prevWidth) {
          prevWidth = width;
          resizeInputs();
        }
      }
    });

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, {
        box: 'border-box',
      });
    }

    return () => {
      if (wrapperRef.current) {
        observer?.unobserve(wrapperRef.current);
      }
    };
  }, []);

  const shouldHideCorrectAnswers =
    shareResults === ShareResults.WithoutCorrectAnswers;

  useRenderReportsLearnosity({
    shouldHideCorrectAnswers,
    reportId,
    setIsLearnosityLoading: setLearnosityLoading,
    signRequestOptions: {
      variables: {
        params: {
          reportSignedRequestInput: {
            service: SignAssignmentServices.Reports,
            studentId,
            sessionId: id,
            // uncomment for local env
            // devDomain: "local.stem.release.stemscopes-v4-dev.aws.acceleratelearning.com",
            reports: JSON.stringify([
              {
                id: reportId,
                type: 'session-detail-by-item',
                user_id: studentId,
                session_id: id,
                questions_api_init_options: {
                  showCorrectAnswers:
                    shareResults === ShareResults.WithCorrectAnswers,
                },
              },
            ]),
          },
        },
      },
    },
  });

  const { loading, items, feedbacks } = useGetFeedbacksAndScores({
    isDa: isDistrictAssignment,
    studentId: userId,
    sessionId: id as string,
    schoolId: currentSchoolId,
  });

  return (
    <VStack
      ref={wrapperRef}
      background="white"
      width="100%"
      flexGrow={1}
      gridGap="16px"
      p={4}
      borderRadius="md"
      className={styles.wrapper}
    >
      <Box id="assessment-report" />
      {loading || learnosityLoading ? null : (
        <Grading items={items} feedbacks={feedbacks} />
      )}
    </VStack>
  );
};

export default AssessmentReport;
