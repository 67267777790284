import { t } from 'i18next';
import { TabKey } from './types';

export const TABS = [
  {
    key: TabKey.generalSettings,
    title: t('calendarTemplate.generalSettings'),
    shortTitle: t('calendarTemplate.general'),
    icon: 'settings',
  },
  {
    key: TabKey.gradingPeriods,
    title: t('calendarTemplate.gradingPeriods'),
    shortTitle: t('calendarTemplate.periods'),
    icon: 'calendar-outlined',
  },
  {
    key: TabKey.holidays,
    title: t('calendarTemplate.holidays'),
    shortTitle: t('calendarTemplate.holidays'),
    icon: 'star',
  },
];

export const defaultSchoolDay = {
  eventId: '',
  startDate: null,
};

export const defaultEventField = {
  eventId: '',
  name: '',
  dateRange: {
    startDate: null,
    endDate: null,
  },
};

export const dateDisplayFormat = 'MM-dd-yyyy';
export const dateValueFormat = 'yyyy-MM-dd';

export const eventFieldKeys: { [key: string]: string } = {
  FirstDayOfSchool: 'firstDayOfSchool',
  LastDayOfSchool: 'lastDayOfSchool',
  GradingPeriod: 'gradingPeriods',
  Holiday: 'holidays',
};

export const multiEventFields = ['GradingPeriod', 'Holiday'];

export const inputFieldStyles: { [key: string]: {} } = {
  SUCCESS: {
    borderColor: 'olive.100',
    color: 'olive.100',
    bg: 'olive.50',
  },
  FAILED: {
    borderColor: 'danger.800',
    color: 'danger.800',
    bg: 'danger.100',
  },
  ERROR: {
    borderColor: 'danger.800',
    color: 'danger.800',
    bg: 'danger.100',
  },
};

export const fieldLocations: { [key: string]: number } = {
  title: 0,
  firstDayOfSchool: 0,
  lastDayOfSchool: 0,
  gradingPeriods: 1,
  holidays: 2,
};
