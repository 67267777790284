import { isProdEnv } from '@lon/shared/constants';

export const REASON_OPTIONS = (
  isAdmin?: boolean,
  reasons?: { issues: any }
) => {
  const issues = isAdmin ? reasons?.issues?.admin : reasons?.issues?.staff;
  return (
    issues?.map((v: { issue: string; description: string }) => ({
      value: v?.issue,
      label: v?.description ? `${v?.issue}<br>${v?.description}` : v?.issue,
    })) || [{ value: '', label: '' }]
  );
};

export const FEEDBACK_URL = isProdEnv()
  ? 'https://alioms.stemscopesngss.com/papi/v1/sf/lonestar/case'
  : 'https://oms-dev.acceleratelearning.com/papi/v1/sf/lonestar/case';
