import { Tfoot } from '@chakra-ui/react';
import { tFootTypes } from './duck';

const TFoot = <Data extends object>({
  table,
  enableClientSidePagination,
  serverSidePagination,
  ...rest
}: tFootTypes.TFootProps<Data>) => {
  return (
    <Tfoot height="full" verticalAlign="bottom" tabIndex={0} {...rest}></Tfoot>
  );
};

export default TFoot;
