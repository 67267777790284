// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import newColors from '../new-colors';
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getNewInput = (theme: ThemeEnum) => {
  return {
    defaultProps: {
      size: 'md',
    },
    baseStyle: {
      field: {
        borderRadius: '4px',
        border: '1px',
        borderColor: getThemeStyleValue('primary.800', 'secondary.1000')(theme),
        color: getThemeStyleValue('primary.800', 'secondary.1000')(theme),

        '&::placeholder': {
          color: getThemeStyleValue('primary.400', 'secondary.1000')(theme),
          fontStyle: 'italic',
        },

        '::-webkit-search-cancel-button': {
          WebkitAppearance: 'none',
        },
        _hover: {
          opacity: 1,
          borderColor: newColors.sky['600'],
          color: newColors.primary['800'],
        },

        _focusVisible: {
          borderColor: newColors.primary['800'],
          outline: 'none',
          boxShadow: 'none',
          color: newColors.primary['800'],
        },

        _disabled: {
          opacity: 1,
          borderColor: newColors.secondary['300'],
          color: newColors.secondary['400'],
          backgroundColor: newColors.secondary['50'],

          '&::placeholder': {
            color: newColors.secondary['300'],
          },

          _hover: {
            borderColor: newColors.secondary['300'],
            color: newColors.secondary['400'],
          },
        },
      },
      element: {
        color: newColors.primary['200'],
      },
    },
    variants: {
      light: {
        field: {
          backgroundColor: 'white',
          borderColor: newColors.primary['200'],

          _invalid: {
            borderColor: newColors.red['700'],
          },

          _hover: {
            _invalid: {
              borderColor: newColors.red['900'],
            },
          },

          _focusVisible: {
            boxShadow: 'focus.newBlue',
            _invalid: {
              borderColor: newColors.red['900'],
              boxShadow: 'focus.error',
            },
          },
        },
      },
      dark: {
        field: {
          backgroundColor: 'white',
          borderColor: newColors.primary['200'],

          _invalid: {
            borderColor: newColors.orange['600'],
            backgroundColor: newColors.orange['100'],
          },

          _hover: {
            _invalid: {
              borderColor: newColors.orange['700'],
              backgroundColor: newColors.orange['200'],
            },
          },

          _focusVisible: {
            boxShadow: 'focus.newBlue',
            _invalid: {
              borderColor: newColors.red['900'],
              boxShadow: 'focus.error',
            },
          },
        },
      },
      'empty-light': {
        field: {
          backgroundColor: 'transparent',
          border: 'none',

          '&::placeholder': {
            display: 'none',
          },

          _focusVisible: {
            boxShadow: 'none',
            border: 'none',
          },

          _disabled: {
            border: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
      'empty-dark': {
        field: {
          backgroundColor: 'transparent',
          border: 'none',

          '&::placeholder': {
            display: 'none',
          },

          _focusVisible: {
            boxShadow: 'none',
            border: 'none',
          },

          _disabled: {
            border: 'none',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    sizes: {
      xxs: {
        field: {
          minHeight: '32px',
          paddingLeft: 2,
        },
      },
      xs: {
        field: {
          minHeight: '36px',
        },
        addon: {
          minHeight: '36px',
        },
      },
      sm: {
        field: {
          minHeight: '40px',
        },
      },
      md: {
        field: {
          minHeight: '44px',
        },
      },
    },
  };
};
