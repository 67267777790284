import { useEffect, useRef, useState } from 'react';

const useScrollExisting = ({
  vertical = true,
  horizontal = true,
  dependencies = [],
}: {
  vertical?: boolean;
  horizontal?: boolean;
  dependencies?: any[];
} = {}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [verticalScroll, setVerticalScroll] = useState(false);
  const [horizontalScroll, setHorizontalScroll] = useState(false);

  useEffect(() => {
    if (!contentRef.current) return;

    const resizeLeftContentObserver = new ResizeObserver(() => {
      if (contentRef?.current) {
        if (vertical) {
          setVerticalScroll(
            contentRef?.current?.scrollHeight > contentRef.current.clientHeight
          );
        }

        if (horizontal) {
          setHorizontalScroll(
            contentRef?.current?.scrollWidth > contentRef.current.clientWidth
          );
        }
      }
    });

    resizeLeftContentObserver.observe(contentRef.current);

    return () => {
      resizeLeftContentObserver.disconnect();
    };
  }, dependencies);

  return {
    contentRef,
    contentScrolls: {
      vertical: verticalScroll,
      horizontal: horizontalScroll,
    },
  };
};

export default useScrollExisting;
