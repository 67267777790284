import { Flex } from '@chakra-ui/react';
import Spinner from '../spinner';
import {
  IMPERSONATE_WRAPPER_HEIGHT_REM,
  IMPERSONATE_WRAPPER_WIDTH_REM,
} from '@lon/shared/constants';

const Loader = ({ isImpersonate = false }: { isImpersonate?: boolean }) => {
  return (
    <Flex
      direction="column"
      h={
        isImpersonate
          ? `calc(100vh - ${IMPERSONATE_WRAPPER_HEIGHT_REM}rem)`
          : '100vh'
      }
      w={
        isImpersonate
          ? `calc(100vw - ${IMPERSONATE_WRAPPER_WIDTH_REM}rem)`
          : '100vw'
      }
      maxH="100vh"
      overflow="hidden"
      backgroundColor="white"
      position="relative"
    >
      <Flex justifyContent="center" alignItems="center" flex="1">
        <Spinner variant="dark" />
      </Flex>
    </Flex>
  );
};

export default Loader;
