export const Progress = {
  variants: {
    blue: {
      track: {
        bg: '#F2F9FF',
      },
      filledTrack: {
        bg: '#8FB7DC',
      },
    },
    green: {
      track: {
        bg: '#F1F8EC',
      },
      filledTrack: {
        bg: '#AABF98',
      },
    },
    red: {
      track: {
        bg: '#FFF5F5',
      },
      filledTrack: {
        bg: '#FFA5A5',
      },
    },
    orange: {
      track: {
        bg: '#FFF1DC',
      },
      filledTrack: {
        bg: '#FFBE5E',
      },
    },
    yellow: {
      track: {
        bg: '#FFF7DC',
      },
      filledTrack: {
        bg: '#FFE58B',
      },
    },
    teal: {
      track: {
        bg: 'secondary.900',
      },
      filledTrack: {
        bg: 'teal.500',
      },
    },
    fuchsia: {
      track: {
        bg: 'secondary.900',
      },
      filledTrack: {
        bg: 'fuchsia.500',
      },
    },
    yellowHc: {
      track: {
        bg: 'secondary.900',
      },
      filledTrack: {
        bg: 'yellow.hc',
      },
    },
    orange600: {
      track: {
        bg: 'secondary.900',
      },
      filledTrack: {
        bg: 'orange.600',
      },
    },
  },
};
