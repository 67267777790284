import snakeCase from 'lodash-es/snakeCase';
import { ContentItemLinkData } from '@lon/shared/requests';

export const closeLtiDialog = (data: ContentItemLinkData): void => {
  const { fields = {}, url, ltiVersion } = data;
  const form = document.createElement('form');

  form.action = url;
  form.method = 'POST';

  form.innerHTML = Object.entries(fields)
    .map(
      ([key, value]) =>
        `<input type="hidden" name="${
          ltiVersion === 'LTI-1p3' ? key.toUpperCase() : snakeCase(key)
        }" value="${value}"/>`
    )
    .join('');

  document.body.append(form);

  form.submit();
};
