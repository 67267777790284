import { routes } from '@lon/suit/configs';
import { TFunction } from 'i18next';
import { Resource as Card } from '@lon/suit/types';

const getLinks = (t: TFunction): Card[] => [
  {
    id: 'stemscopesStreaming',
    url: routes.home,
    color: 'primary',
    title: t('scopes.stemscopesStreaming'),
    description: t('scopes.browseOurScienceCollection'),
    text: t('scopes.accessStreaming'),
  },
  {
    id: 'teacherToolbox',
    color: 'success',
    title: t('scopes.teacherToolbox'),
    description: t('scopes.accessResources'),
    text: t('scopes.useToolbox'),
  },
  {
    id: 'visualGlossary',
    url: routes.home,
    color: 'success',
    title: t('scopes.visualGlossary'),
    description: t('scopes.browseOurInstructionalResources'),
    text: t('scopes.accessGlossary'),
  },
  {
    id: 'stemcoachAction',
    url: routes.home,
    color: 'primary',
    title: t('scopes.stemcoachAction'),
    description: t('scopes.professionalDevelopment'),
    text: t('scopes.browseAction'),
  },
];

export default getLinks;
