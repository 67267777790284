export const colorSchema = {
  default: {
    background: 'secondary.50',
    text: 'primary.800',
  },
  success: {
    background: 'olive.50',
    text: 'olive.100',
  },
  error: {
    background: 'danger.extraLight',
    text: 'danger.800',
  },
};
