import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Message } from '@lon/shared/components';

const ErrorMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Message
      variant="formError"
      alertDescriptionProps={{
        display: 'block',
        padding: 0,
      }}
      message={
        <Text variant="s3">{t('reopenAssignmentModal.errorMessage')}</Text>
      }
    />
  );
};

export default ErrorMessage;
