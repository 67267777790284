export const Accordion = {
  variants: {
    informative: {
      container: {
        borderTop: 'none',
        borderBottom: 'none',
      },
      icon: {
        ml: 2,
      },
      button: {
        paddingX: 0,
        borderBottom: '1px dashed',
        fontWeight: 700,
        fontSize: 'md',
        width: 'auto',
        _disabled: {
          opacity: 1,
          backgroundColor: 'transparent',
          color: 'secondary.300',
        },
        _hover: {
          background: 'transparent',
          color: 'primary.400',
        },
        _active: {
          background: 'transparent',
          color: 'primary.900',
        },
      },
      panel: {
        bg: 'secondary.50',
        borderRadius: '6px',
        p: 6,
        mt: 4,
      },
    },
    withBorder: {
      container: {
        borderTop: 'none',
        borderBottom: 'none',
      },
      icon: {
        ml: 2,
      },
      button: {
        paddingX: 0,
        borderBottom: '1px dashed',
        fontWeight: 700,
        fontSize: 'md',
        width: 'auto',
        _disabled: {
          opacity: 1,
          backgroundColor: 'transparent',
          color: 'secondary.300',
        },
        _hover: {
          background: 'transparent',
          color: 'primary.400',
        },
        _active: {
          background: 'transparent',
          color: 'primary.900',
        },
      },
      panel: {
        border: '1px solid',
        borderColor: 'secondary.200',
        borderRadius: '6px',
        p: 6,
        mt: 4,
      },
    },
  },
};
