import { navigateNext, navigatePrev } from '@lon/suit/utils';
import * as actions from './actions';
import * as Types from './types';

export const focusChild: Types.FocusChild = (index, state) => {
  if (!state.items.length || !(index >= 0 && index < state.items.length))
    return;

  const { ref } = state.items[index];

  if (ref.current) {
    const menuItem = ref.current.firstElementChild as HTMLElement;
    menuItem?.focus();
  }
};

export const setActiveIndex: Types.SetActiveIndex = (
  direction,
  state,
  dispatch
) => {
  let newIndex = 0;

  if (direction === 'previous') {
    newIndex = navigatePrev(state.items, state.activeIndex).index;
  } else {
    newIndex = navigateNext(state.items, state.activeIndex).index;
  }

  dispatch(actions.setActiveIndex(newIndex));
  focusChild(newIndex, state);
};

export const returnFocus: Types.ReturnFocus = (direction, state, dispatch) => {
  if (!direction) {
    focusChild(state.activeIndex, state);
  } else {
    setActiveIndex(direction, state, dispatch);
  }
};
