export const frostJSON = {
  status: 200,
  data: {
    children: [
      {
        id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
        file: '',
        identifier: 'e531d336-3f6e-366f-9c4b-0a371bd132b7',
        root_type: 2,
        type: 5,
        section_page: 'section',
        assessment_ids: [],
        title: 'Structures and Functions of Organisms',
        content_type_identifier: 'fld',
        children: [
          {
            id: 'dd16c916-f28d-3242-bf02-f5d1cd26d1fd',
            file: '',
            identifier: 'uxji9461f4238f2b99ba10bb89b6e867',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Scope Image',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'c7e10f15-09f2-3d0d-97a2-1b1cb913cb95',
                file: '',
                identifier: 'd821b062-3e54-3d01-80ed-215b5af8378c',
                root_type: 2,
                type: 213,
                section_page: 'link',
                assessment_ids: [],
                title: 'EN_Structures and Functions of Organisms',
                ext_res_link:
                  'https://unifiedfrost-cdn-dev.learningmate.co/701fc443-9f0f-4929-93f5-50488f53623b_frost/assets/images/ceec691b-abf1-3841-9fe7-1249c279a2c8/StructuresandFunctionsofOrganisms.jpeg',
                ext_res_type: 'asset',
                assessment_source: '',
                assessment_id: '',
                asset_id: 'ceec691b-abf1-3841-9fe7-1249c279a2c8',
                assignment_id: '',
                assignment_source: '',
                forum_id: '',
                template_id: '',
                parent_lo_id: '',
                content_type_identifier: 'image',
                children: [],
                parent_id: 'dd16c916-f28d-3242-bf02-f5d1cd26d1fd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                s3_path:
                  'https://unifiedfrost-cdn-dev.learningmate.co/701fc443-9f0f-4929-93f5-50488f53623b_frost/assets/images/ceec691b-abf1-3841-9fe7-1249c279a2c8/StructuresandFunctionsofOrganisms.jpeg',
                meta_data: [],
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {},
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'da31a5ca-2710-3ef0-9d56-544e0a54c7e6',
            file: '',
            identifier: 'cac6bdad-4957-3e10-8205-b9ad7886d9ac',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Home',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'fed65357-03a1-37a7-a1d6-b6b2c0d7e4a7',
                file: '',
                identifier: 'ed2e8b54-06f6-3100-be6a-63d3259e9f2a',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title: 'TX',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'e6dc4c8b-fde8-3026-afc6-9cf49b6c7658',
                    file: '',
                    identifier: 'abb45bb8-19dc-3649-a312-f1fc1dd9ca9a',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Scope Landing',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'ca57531c-145e-3f1e-8970-91555283baa9',
                        file: 'ca57531c-145e-3f1e-8970-91555283baa9_structures_and_functions_of_organisms_s78d0.html',
                        identifier: 'c0aa4acb-62de-3e40-88a5-6e1604801b95',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Scope Landing',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'e6dc4c8b-fde8-3026-afc6-9cf49b6c7658',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://stem.dev.stemscopes-v4-dev.aws.acceleratelearning.com/content/Structures_and_Functions_of_Organisms_10242/Structures_and_Functions_of_Organisms_1723542351/web_resources/structures_and_functions_of_organisms_s78d0.html?userType=staff&userId=018a7019-1c7e-7248-bbe3-1bae1f94ffb7&v=0.63-beta.38&scopeId=ed2e8b54-06f6-3100-be6a-63d3259e9f2a&type=local_practice&v=0.63-beta.38',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'fed65357-03a1-37a7-a1d6-b6b2c0d7e4a7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Section Type',
                          value: 'Landing Page',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a937d27d-4284-32ad-8db1-f5d692f96af8',
                    file: '',
                    identifier: 'a87c34ba-4d8c-3168-a2b7-74bc6b3968fc',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Teacher Background',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'd36e57a4-f104-3ad3-8129-dc32ead71359',
                        file: 'd36e57a4-f104-3ad3-8129-dc32ead71359_d6616da9-6179-35d0-a6f5-e48d9216a174.html',
                        identifier: 'b992e130-7dc6-35b8-9d74-115ac2c216b0',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Teacher Background',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'a937d27d-4284-32ad-8db1-f5d692f96af8',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d36e57a4-f104-3ad3-8129-dc32ead71359_d6616da9-6179-35d0-a6f5-e48d9216a174.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'fed65357-03a1-37a7-a1d6-b6b2c0d7e4a7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Teacher Background',
                        },
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ff9bbd04-6fd2-3395-9427-94b056aeb6bd',
                    file: '',
                    identifier: 'b5b13f4f-5899-342c-953c-39336a2bff22',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Standards Planning',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'b0baf7f2-b445-35c7-a35e-383a7eee4ed8',
                        file: 'b0baf7f2-b445-35c7-a35e-383a7eee4ed8_structures_and_functions_of_organisms_saa2c.html',
                        identifier: 'c32c0c14-757f-3831-a608-442ccc1b479e',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Standards Planning',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'ff9bbd04-6fd2-3395-9427-94b056aeb6bd',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b0baf7f2-b445-35c7-a35e-383a7eee4ed8_structures_and_functions_of_organisms_saa2c.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'fed65357-03a1-37a7-a1d6-b6b2c0d7e4a7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Standards Planning',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b0018ba4-d846-3df8-9134-90776a06ebe8',
                    file: '',
                    identifier: 'b7d28cbf-32cd-3984-8782-3595da3796b3',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Science outside the Classroom',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'c4218ced-5a02-384f-95ec-6bda7b31731e',
                        file: 'c4218ced-5a02-384f-95ec-6bda7b31731e_ef4eddbe-8a6f-3ae7-ba4b-df631b3f9bce.html',
                        identifier: 'c4051d67-4aae-3928-b6e3-30583117781d',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Science outside the Classroom_Teacher',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'b0018ba4-d846-3df8-9134-90776a06ebe8',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c4218ced-5a02-384f-95ec-6bda7b31731e_ef4eddbe-8a6f-3ae7-ba4b-df631b3f9bce.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                      {
                        id: 'f44d7986-81d7-3c76-9ab3-1f7f61dcd92d',
                        file: 'f44d7986-81d7-3c76-9ab3-1f7f61dcd92d_ddd5d8cc-9e96-33bd-bf0a-9e7155752ad2.html',
                        identifier: 'cbef4239-ebb1-3173-999f-a8196549e87d',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          '10_5_Home_SOTC_StructuresandFunctionsofOrganisms_Home Handout',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'b0018ba4-d846-3df8-9134-90776a06ebe8',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f44d7986-81d7-3c76-9ab3-1f7f61dcd92d_ddd5d8cc-9e96-33bd-bf0a-9e7155752ad2.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'File Type',
                              value: 'epub',
                            },
                            {
                              key_name: 'File Title',
                              value: 'Home Handout',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'fed65357-03a1-37a7-a1d6-b6b2c0d7e4a7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Science outside the Classroom',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b0dadf36-141f-3ca3-ba89-a52209a68d10',
                    file: '',
                    identifier: 'hofmab546ff0b58830c12106bd98222e',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Student Data',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'e44d236d-08a8-3cbe-8e06-18fb77b597ff',
                        file: 'e44d236d-08a8-3cbe-8e06-18fb77b597ff_stx_structures_and_functions_of_organismb9b6.html',
                        identifier: 'e473c7f3-a507-3b53-97dd-c4a1eb28d7f1',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'STX_Structures and Functions of Organisms: Student Data_Teacher',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'b0dadf36-141f-3ca3-ba89-a52209a68d10',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e44d236d-08a8-3cbe-8e06-18fb77b597ff_stx_structures_and_functions_of_organismb9b6.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                      {
                        id: 'd9c72156-19b2-3393-9335-771c4d28d386',
                        file: 'd9c72156-19b2-3393-9335-771c4d28d386_ebadf98b-81e9-3ceb-b5e0-95ec2faf6ea0.html',
                        identifier: 'cd7168f9-3dd4-3f90-a93d-2d437ae38664',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title: '35_Home_StudentDataSheet_Generic',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'b0dadf36-141f-3ca3-ba89-a52209a68d10',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d9c72156-19b2-3393-9335-771c4d28d386_ebadf98b-81e9-3ceb-b5e0-95ec2faf6ea0.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'File Type',
                              value: 'epub',
                            },
                            {
                              key_name: 'File Title',
                              value: 'Student Data Sheet',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'fed65357-03a1-37a7-a1d6-b6b2c0d7e4a7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Student Data Sheet',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'da31a5ca-2710-3ef0-9d56-544e0a54c7e6',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Position Number',
                      value: '5065',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                    {
                      key_name: 'Internal Name',
                      value: 'Structures and Functions of Organisms',
                    },
                    {
                      key_name: 'Edition Name',
                      value: 'Texas Science',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Display Name',
                      value: 'Structures and Functions of Organisms',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                file: '',
                identifier: 'ldzsdf2cd237b9568b753df63cd36b59',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title: 'FL',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'f492cfe4-47e2-374d-a107-23b08fdfecda',
                    file: '',
                    identifier: 'zyuy8414779cee63ba69190b1a0983e2',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Scope Landing',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'f9fd4a7a-5331-3153-824a-44c304a05f5f',
                        file: 'f9fd4a7a-5331-3153-824a-44c304a05f5f_structures_and_functions_of_organisms_s9044.html',
                        identifier: 'c53271c3-45f6-3726-91f5-ecdf0b5aa29f',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Scope Landing',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'f492cfe4-47e2-374d-a107-23b08fdfecda',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f9fd4a7a-5331-3153-824a-44c304a05f5f_structures_and_functions_of_organisms_s9044.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Section Type',
                          value: 'Landing Page',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd303c076-ae39-3638-90f3-4f3218d9fe07',
                    file: '',
                    identifier: 'xfvx205d69bfb813a163e1ae663a991a',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Teacher Background',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'a32bd8ed-4592-38ea-b152-0edccacc27db',
                        file: 'a32bd8ed-4592-38ea-b152-0edccacc27db_a00cff9b-7478-3e47-9f92-7aa8e55dbcf6.html',
                        identifier: 'd7aa2814-f15a-3d74-be01-93e29417b47f',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Teacher Background',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'd303c076-ae39-3638-90f3-4f3218d9fe07',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a32bd8ed-4592-38ea-b152-0edccacc27db_a00cff9b-7478-3e47-9f92-7aa8e55dbcf6.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Teacher Background',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Content Group',
                          value: '5',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b28c7739-a32e-3769-b29f-f77a06f0f3fc',
                    file: '',
                    identifier: 'sbdz743e7b70a29804d4ad937824c9d0',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Standards Planning',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'dc8025f6-a7eb-3b56-904a-482596945ab6',
                        file: 'dc8025f6-a7eb-3b56-904a-482596945ab6_structures_and_functions_of_organisms_s5558.html',
                        identifier: 'c8f306e1-dd48-3ff5-8abc-a7da32cee188',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Standards Planning',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'b28c7739-a32e-3769-b29f-f77a06f0f3fc',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dc8025f6-a7eb-3b56-904a-482596945ab6_structures_and_functions_of_organisms_s5558.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Standards',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a476dce2-3f4b-3a86-bace-35608fc4004f',
                    file: '',
                    identifier: 'glae49a139ca8f55f8c20a12892556eb',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science outside the Classroom',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'c8ecf923-678c-3c0b-a394-4c87fce0c115',
                        file: 'c8ecf923-678c-3c0b-a394-4c87fce0c115_structures_and_functions_of_organisms_s2174.html',
                        identifier: 'c66f8771-6551-3f76-b273-41ac0ac4b03c',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Science outside the Classroom_Teacher',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'a476dce2-3f4b-3a86-bace-35608fc4004f',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c8ecf923-678c-3c0b-a394-4c87fce0c115_structures_and_functions_of_organisms_s2174.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                      {
                        id: 'bcb9a216-029e-39e7-956c-8ab729ef3f87',
                        file: 'bcb9a216-029e-39e7-956c-8ab729ef3f87_c3461802-060a-30ca-a09c-39c6ec8baa41.html',
                        identifier: 'da46e7d5-d45f-313c-91e7-766f58f2bde2',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          '10_5_Home_SOTC_StructuresandFunctionsofOrganisms_HomeHandout',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'a476dce2-3f4b-3a86-bace-35608fc4004f',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bcb9a216-029e-39e7-956c-8ab729ef3f87_c3461802-060a-30ca-a09c-39c6ec8baa41.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'File Type',
                              value: 'epub',
                            },
                            {
                              key_name: 'File Title',
                              value: 'Home Handout',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Science outside the Classroom',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f03fba41-9a06-374c-8531-2fa1ad691194',
                    file: '',
                    identifier: 'dunv548833d9265c3838907d0418c803',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Planning Guide',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'f3ae1051-7e14-3f8d-b2cf-4019f85c7adf',
                        file: 'f3ae1051-7e14-3f8d-b2cf-4019f85c7adf_cd38134c-3b92-37f6-9b04-09398e60d725.html',
                        identifier: 'be93e6f6-36c8-3fb3-bbec-a759c2697365',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Planning Guide_Teacher',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'f03fba41-9a06-374c-8531-2fa1ad691194',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f3ae1051-7e14-3f8d-b2cf-4019f85c7adf_cd38134c-3b92-37f6-9b04-09398e60d725.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Planning Guide',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'edb0d601-def5-3db3-ae2e-22e71d4f601e',
                    file: '',
                    identifier: 'tlaee5eb8a932d4956267532d1639d4c',
                    root_type: 2,
                    type: 5,
                    section_page: 'section',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Question Prompts',
                    content_type_identifier: 'fld',
                    children: [
                      {
                        id: 'd28ba375-7c7c-35e1-97fb-b83a4a77b38d',
                        file: 'd28ba375-7c7c-35e1-97fb-b83a4a77b38d_structures_and_functions_of_organisms_qc292.html',
                        identifier: 'ed42a2e2-77c2-3313-a2ee-41d2d4033755',
                        root_type: 2,
                        type: 6,
                        section_page: 'page',
                        assessment_ids: [],
                        title:
                          'Structures and Functions of Organisms: Question Prompts_Teacher',
                        ext_res_type: '',
                        assignment_id: '',
                        assignment_source: '',
                        assessment_source: '',
                        assessment_id: '',
                        asset_id: '',
                        template_id: '',
                        parent_lo_id: '',
                        forum_id: '',
                        content_type_identifier: 'contentscreen',
                        page_orientation: '',
                        paper_size: '',
                        children: [],
                        parent_id: 'edb0d601-def5-3db3-ae2e-22e71d4f601e',
                        lti_version: '',
                        source_action: 'generateToTraverseDFS',
                        is_edited: 0,
                        s3_path:
                          'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d28ba375-7c7c-35e1-97fb-b83a4a77b38d_structures_and_functions_of_organisms_qc292.html',
                        meta_data: {
                          kvp: [
                            {
                              key_name: 'Audience',
                              value: 'Teacher',
                            },
                          ],
                        },
                        course_metadata: {
                          kvp: [
                            {
                              key_name: 'Project Type',
                              value: '5E Content Scope',
                            },
                          ],
                          taxonomy: [],
                          tag: [],
                        },
                      },
                    ],
                    parent_id: 'e8b83245-cce5-35f3-b282-1e389b9504c2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path: 'test',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Priority',
                          value: '1',
                        },
                        {
                          key_name: 'Curriculum Area',
                          value: 'Science',
                        },
                        {
                          key_name: 'Element Name',
                          value: 'Question Prompts',
                        },
                        {
                          key_name: 'Section Type',
                          value: 'Side Bar',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'da31a5ca-2710-3ef0-9d56-544e0a54c7e6',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Edition Name',
                      value: 'Florida Science',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Display Name',
                      value: 'Structures and Functions of Organisms',
                    },
                    {
                      key_name: 'Internal Name',
                      value: 'Structures and Functions of Organisms',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Position Number',
                      value: '5065',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            s3_path: 'test',
            meta_data: [],
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
            file: '',
            identifier: 'ab060fd2-e763-3a94-b8f5-d45285062c72',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Engage',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'aac17992-d52d-3705-8716-8837a69fbb35',
                file: '',
                identifier: 'c1918862-9321-3e7b-ba9b-a8cee3f0e792',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Pre-Assessment and Accessing Prior Knowledge',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'dede9850-7134-3fac-969e-48ea6ac08b18',
                    file: 'dede9850-7134-3fac-969e-48ea6ac08b18_a0f6fb1c-40c4-366a-b9c0-03f5fe56536b.html',
                    identifier: 'fb97639b-f3ec-3aa2-a7a2-6dd62100e92d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: ENGAGE_Accessing Prior Knowledge_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'aac17992-d52d-3705-8716-8837a69fbb35',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://stem.dev.stemscopes-v4-dev.aws.acceleratelearning.com/content/Structures_and_Functions_of_Organisms_10242/Structures_and_Functions_of_Organisms_1723542351/web_resources/fbeda631-517a-30a0-bff9-1ed4fed975b0.html?userType=staff&userId=018a7019-1c7e-7248-bbe3-1bae1f94ffb7&v=0.63-beta.38&scopeId=ed2e8b54-06f6-3100-be6a-63d3259e9f2a&type=local_practice&v=0.63-beta.38',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f8687783-5326-3c99-bcd2-c1c343d0f3b0',
                    file: 'f8687783-5326-3c99-bcd2-c1c343d0f3b0_d34320e0-ccd9-39f4-a6ac-9ca388d7f518.html',
                    identifier: 'effc8333-83e7-3a37-9d66-b4fadd1cfa7d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Pre-Assessment_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'aac17992-d52d-3705-8716-8837a69fbb35',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f8687783-5326-3c99-bcd2-c1c343d0f3b0_d34320e0-ccd9-39f4-a6ac-9ca388d7f518.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fbb9221e-fcaa-3adb-9fb5-410077353acb',
                    file: 'fbb9221e-fcaa-3adb-9fb5-410077353acb_10_5_engage_apk_structuresandfunctionsof1f48.html',
                    identifier: 'c8647eb8-5f03-3915-ac92-29e6ea3fa1d4',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_APK_StructuresandFunctionsofOrganisms_Agree or Disagree?',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'aac17992-d52d-3705-8716-8837a69fbb35',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fbb9221e-fcaa-3adb-9fb5-410077353acb_10_5_engage_apk_structuresandfunctionsof1f48.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Agree or Disagree?',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'cf5efbc4-b1b3-3633-97e7-f2ff5c053225',
                    file: 'cf5efbc4-b1b3-3633-97e7-f2ff5c053225_aa298c61-5252-32eb-8d47-68bf755a9077.html',
                    identifier: 'cc2b2ecd-5f57-37dd-9fde-f219574eb8e8',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_PreAssess_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'aac17992-d52d-3705-8716-8837a69fbb35',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/cf5efbc4-b1b3-3633-97e7-f2ff5c053225_aa298c61-5252-32eb-8d47-68bf755a9077.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Pre-Assessment-Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ef5f5207-0751-3816-94ba-0daadb774a19',
                    file: 'ef5f5207-0751-3816-94ba-0daadb774a19_aab9fe73-b480-3ab3-a823-762082f74f9f.html',
                    identifier: 'dcd89d9f-91d1-38e4-991b-2d898fdc64a9',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_PreAssess_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'aac17992-d52d-3705-8716-8837a69fbb35',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ef5f5207-0751-3816-94ba-0daadb774a19_aab9fe73-b480-3ab3-a823-762082f74f9f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Pre-Assessment-Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '15 minutes',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Pre-Assessment and Accessing Prior Knowledge',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Engage Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'ea6d17ef-5d94-32db-a0ef-76f3b83dc260',
                file: '',
                identifier: 'bcd7001c-51a3-3099-bdd8-537c3fa8aeba',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Scope Phenomenon',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a48e81f0-9b72-3086-a4d1-5266a312917d',
                    file: 'a48e81f0-9b72-3086-a4d1-5266a312917d_fbeda631-517a-30a0-bff9-1ed4fed975b0.html',
                    identifier: 'da451e19-64b9-339f-bd0a-4269ab79617e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Scope Phenomenon_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ea6d17ef-5d94-32db-a0ef-76f3b83dc260',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a48e81f0-9b72-3086-a4d1-5266a312917d_fbeda631-517a-30a0-bff9-1ed4fed975b0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bf8f98d9-d103-39db-91c4-df4396559318',
                    file: 'bf8f98d9-d103-39db-91c4-df4396559318_structures_and_functions_of_organisms_e5765.html',
                    identifier: 'aa5a8040-1360-3a38-8f16-34d91a739752',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Scope Phenomenon_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ea6d17ef-5d94-32db-a0ef-76f3b83dc260',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bf8f98d9-d103-39db-91c4-df4396559318_structures_and_functions_of_organisms_e5765.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ea72def2-2814-3a7c-bb59-381d0fe3cf07',
                    file: 'ea72def2-2814-3a7c-bb59-381d0fe3cf07_c3ab0bd9-f137-3070-997e-03383f75793c.html',
                    identifier: 'f23d26c1-1612-3848-9202-cdae8442946e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_ScpPhen_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ea6d17ef-5d94-32db-a0ef-76f3b83dc260',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ea72def2-2814-3a7c-bb59-381d0fe3cf07_c3ab0bd9-f137-3070-997e-03383f75793c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd394d2bb-315c-335b-b3be-e5986791b42e',
                    file: 'd394d2bb-315c-335b-b3be-e5986791b42e_f09512e5-3a34-38f4-a8be-be5610ea05fd.html',
                    identifier: 'e03de2fc-c7cf-39fe-a99f-d2bec7c71fd7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_ScpPhen_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ea6d17ef-5d94-32db-a0ef-76f3b83dc260',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d394d2bb-315c-335b-b3be-e5986791b42e_f09512e5-3a34-38f4-a8be-be5610ea05fd.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'af6f545c-0c02-3a68-b811-29877e6fc318',
                    file: 'af6f545c-0c02-3a68-b811-29877e6fc318_e5f189d5-1bee-348b-b918-4488c9428961.html',
                    identifier: 'dfa799d0-6d8a-3746-a6e7-fca5a7fb2e56',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '"I Wonder..." Investigation Template',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ea6d17ef-5d94-32db-a0ef-76f3b83dc260',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/af6f545c-0c02-3a68-b811-29877e6fc318_e5f189d5-1bee-348b-b918-4488c9428961.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: '"I Wonder... " Investigation Template',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '15 minutes',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Scope Phenomenon',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Engage Icon',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c4364dcf-3f48-316e-97d4-961623df4b64',
                file: '',
                identifier: 'b8a1ff3d-a00d-3410-9f50-dd7a38a428c9',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Progress Monitoring and Reflection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ec5cc2c8-1f28-303b-a00d-3334a6a60065',
                    file: 'ec5cc2c8-1f28-303b-a00d-3334a6a60065_a07d9a77-34a1-3f24-b8bf-1336c43c9ac0.html',
                    identifier: 'e9e0786c-638d-3c65-8704-5447a667d534',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Progress Monitoring and Reflection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c4364dcf-3f48-316e-97d4-961623df4b64',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ec5cc2c8-1f28-303b-a00d-3334a6a60065_a07d9a77-34a1-3f24-b8bf-1336c43c9ac0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fcee42a3-3702-33c7-a50d-445fe310a58e',
                    file: 'fcee42a3-3702-33c7-a50d-445fe310a58e_structures_and_functions_of_organisms_e4b59.html',
                    identifier: 'a7c0653d-b718-399e-80fc-e8389433f955',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: ENGAGE_Progress Monitoring and Reflection Log_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c4364dcf-3f48-316e-97d4-961623df4b64',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fcee42a3-3702-33c7-a50d-445fe310a58e_structures_and_functions_of_organisms_e4b59.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ac61ac11-2cfc-3ee8-b21e-cfff82d261e7',
                    file: 'ac61ac11-2cfc-3ee8-b21e-cfff82d261e7_b2737850-4ea5-397b-a6dc-7ab18943eec4.html',
                    identifier: 'ddd0f79c-2c11-3b45-96e9-7f471d1ee50b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_PMR_Structures and Functions of Organisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c4364dcf-3f48-316e-97d4-961623df4b64',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ac61ac11-2cfc-3ee8-b21e-cfff82d261e7_b2737850-4ea5-397b-a6dc-7ab18943eec4.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Progress Monitoring and Reflection Log',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '15 minutes',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Progress Monitoring and Reflection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Engage Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'bbc4419d-aa82-34a5-8299-6c61e44a82bc',
                file: '',
                identifier: 'nwwhfde7f880ad7ffb6ad204b6f679c5',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Pre-Assessment and APK',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a0afef7d-357a-37ff-94ce-61d2fbe3f547',
                    file: 'a0afef7d-357a-37ff-94ce-61d2fbe3f547_d546ea5c-e09e-35be-b37e-6349a7a0c023.html',
                    identifier: 'a884110a-390b-336d-8e5d-a6654417b0cf',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_Structures and Functions of Organisms: ENGAGE_Accessing Prior Knowledge_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bbc4419d-aa82-34a5-8299-6c61e44a82bc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a0afef7d-357a-37ff-94ce-61d2fbe3f547_d546ea5c-e09e-35be-b37e-6349a7a0c023.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a956ee0a-ece4-3c37-9e04-967084aaaba7',
                    file: 'a956ee0a-ece4-3c37-9e04-967084aaaba7_bca7cb04-7d1b-338e-b466-e3ce719803e9.html',
                    identifier: 'f2262132-9b11-306d-b562-f71137818cb7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_Structures and Functions of Organisms: ENGAGE_Pre-Assessment_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bbc4419d-aa82-34a5-8299-6c61e44a82bc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a956ee0a-ece4-3c37-9e04-967084aaaba7_bca7cb04-7d1b-338e-b466-e3ce719803e9.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f0951bfb-0554-393f-80c3-25e2b12a583a',
                    file: 'f0951bfb-0554-393f-80c3-25e2b12a583a_fd1d3a88-193d-3d54-84a0-2b0ca9c7791b.html',
                    identifier: 'cd3b9389-2a04-3d16-980d-160f738fd675',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Engage_APK_StructuresandFunctionsofOrganisms_AgreeorDisagree',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bbc4419d-aa82-34a5-8299-6c61e44a82bc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f0951bfb-0554-393f-80c3-25e2b12a583a_fd1d3a88-193d-3d54-84a0-2b0ca9c7791b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Agree or Disagree?',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'acc059f9-b853-3b7f-8c31-d939a0a00e1a',
                    file: 'acc059f9-b853-3b7f-8c31-d939a0a00e1a_dcd61055-3e23-3cd3-830a-a3722a24cd07.html',
                    identifier: 'd9c52cfc-5fb2-3990-8c2b-5fbcd75ef5c7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Engage_PreAssess_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bbc4419d-aa82-34a5-8299-6c61e44a82bc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/acc059f9-b853-3b7f-8c31-d939a0a00e1a_dcd61055-3e23-3cd3-830a-a3722a24cd07.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Pre-Assessment- Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'de430a2c-916a-3b76-9da6-a99681fd4bcd',
                    file: 'de430a2c-916a-3b76-9da6-a99681fd4bcd_20_5_engage_preassess_structuresandfunct271a.html',
                    identifier: 'ac531fcf-6831-39f3-8ad8-6067d5217468',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Engage_PreAssess_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bbc4419d-aa82-34a5-8299-6c61e44a82bc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/de430a2c-916a-3b76-9da6-a99681fd4bcd_20_5_engage_preassess_structuresandfunct271a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Pre-Assessment- Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Pre-Assessment and Accessing Prior Knowledge',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Engage Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '15 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'b0e39678-9122-3bf7-a938-3f4317bea6c5',
                file: '',
                identifier: 'lyue4dc0af4cc21a2e5d492b8163403e',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Scope Phenomenon',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'b53cfbe6-4fb3-3eb7-aef6-12d4198f1305',
                    file: 'b53cfbe6-4fb3-3eb7-aef6-12d4198f1305_d310db43-a4dd-35de-b5b7-96306163ab3a.html',
                    identifier: 'eb4b67cb-2d3e-3d9b-9181-f8fc7272b184',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Scope Phenomenon_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0e39678-9122-3bf7-a938-3f4317bea6c5',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b53cfbe6-4fb3-3eb7-aef6-12d4198f1305_d310db43-a4dd-35de-b5b7-96306163ab3a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b44fce47-bebd-3688-bc98-059d26e41cee',
                    file: 'b44fce47-bebd-3688-bc98-059d26e41cee_sfl_structures_and_functions_of_organismb07d.html',
                    identifier: 'd4ffa76f-cd74-323a-bfa6-e08f59b2e8b1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: ENGAGE_Scope Phenomenon_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0e39678-9122-3bf7-a938-3f4317bea6c5',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b44fce47-bebd-3688-bc98-059d26e41cee_sfl_structures_and_functions_of_organismb07d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fc46d4d2-42b0-3de9-81a7-288a64d85715',
                    file: 'fc46d4d2-42b0-3de9-81a7-288a64d85715_ddb243b4-5662-3d76-992a-3130e8e7a695.html',
                    identifier: 'd5932a98-4ead-3acf-a4b3-68c8b93ab6d9',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Engage_ScpPhen_AnimalStructuresandFunctions_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0e39678-9122-3bf7-a938-3f4317bea6c5',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fc46d4d2-42b0-3de9-81a7-288a64d85715_ddb243b4-5662-3d76-992a-3130e8e7a695.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd033f704-1cf4-3688-aecd-812511ad9414',
                    file: 'd033f704-1cf4-3688-aecd-812511ad9414_d93e8131-f9e3-3e6d-b40a-a97ab2a236c2.html',
                    identifier: 'ad43aafe-25a3-314a-9b6d-c7d7053f5296',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Engage_ScpPhen_AnimalStructuresandFunctions_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0e39678-9122-3bf7-a938-3f4317bea6c5',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d033f704-1cf4-3688-aecd-812511ad9414_d93e8131-f9e3-3e6d-b40a-a97ab2a236c2.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Engage Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Scope Phenomenon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '15 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'eb8cf730-c453-3ffc-abcd-6703f566450b',
                file: '',
                identifier: 'bvbn2e1ec8df3c92d3dc60f3ab5a1520',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Progress Monitoring and Reflection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ae56ee38-d9a1-333b-bef5-c5185a4e16b6',
                    file: 'ae56ee38-d9a1-333b-bef5-c5185a4e16b6_a7a8a4e6-5ccc-3b96-9dc0-0a88575ddc10.html',
                    identifier: 'ea03eba0-1daf-3139-91d0-03847b50e5dc',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Progress Monitoring and Reflection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'eb8cf730-c453-3ffc-abcd-6703f566450b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ae56ee38-d9a1-333b-bef5-c5185a4e16b6_a7a8a4e6-5ccc-3b96-9dc0-0a88575ddc10.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e3dd094f-e52a-3afd-b21c-d49ad82cce9c',
                    file: 'e3dd094f-e52a-3afd-b21c-d49ad82cce9c_sfl_structures_and_functions_of_organismacc6.html',
                    identifier: 'afc7b4e2-d05b-3fc3-9eed-809b24413921',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Progress Monitoring and Reflection_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'eb8cf730-c453-3ffc-abcd-6703f566450b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e3dd094f-e52a-3afd-b21c-d49ad82cce9c_sfl_structures_and_functions_of_organismacc6.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e3054468-995a-3efa-bde5-fd1692098c58',
                    file: 'e3054468-995a-3efa-bde5-fd1692098c58_fa235417-363c-356f-b723-21283642b311.html',
                    identifier: 'ea83e99a-bcd5-369c-97af-787be59ff1e1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_Structures and Functions of Organisms_Engage_PMR',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: 'none',
                    paper_size: 'letter',
                    children: [],
                    parent_id: 'eb8cf730-c453-3ffc-abcd-6703f566450b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e3054468-995a-3efa-bde5-fd1692098c58_fa235417-363c-356f-b723-21283642b311.html',
                    meta_data: [],
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'a9858c0b-bfaf-3d59-b13b-394d25d90d51',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Engage Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Progress Monitoring and Reflection',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Engage',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'e23e80a2-cbe8-330e-9245-e8f3743fcf79',
            file: '',
            identifier: 'bb0e19b5-6065-3958-9bef-56d79ecb9b6c',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Explore',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'ddee1345-02dd-3941-8486-f647f009e522',
                file: '',
                identifier: 'dbf14a86-a5ea-3a94-9d69-169d5dc9d3c1',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Hands On Explore: Structures for Different Environments',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'cfd9b9e3-cdac-388f-bae8-7a014ccfe080',
                    file: 'cfd9b9e3-cdac-388f-bae8-7a014ccfe080_b40c7c13-0b79-3fb3-b67f-c825f08e31bb.html',
                    identifier: 'dbb0e048-99b5-3ede-9269-1871ecb2c5a2',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Hands On Explore_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/cfd9b9e3-cdac-388f-bae8-7a014ccfe080_b40c7c13-0b79-3fb3-b67f-c825f08e31bb.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Activity Title',
                          value: 'Structures for Different Environments',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Activity Time',
                          value: '60 minutes',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c2949b1c-5a07-3f80-b774-5b40f9caf7df',
                    file: 'c2949b1c-5a07-3f80-b774-5b40f9caf7df_structures_and_functions_of_organisms_ed91b.html',
                    identifier: 'dc03f054-4a6b-3629-91ff-b2693298ceb2',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Hands On Explore_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c2949b1c-5a07-3f80-b774-5b40f9caf7df_structures_and_functions_of_organisms_ed91b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Activity Title',
                          value: 'Structures for Different Environments',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c3f926d9-b05d-34ed-8c1c-36f809330984',
                    file: 'c3f926d9-b05d-34ed-8c1c-36f809330984_f351e1fa-136f-3e2e-b7fd-6b6cfaceedba.html',
                    identifier: 'fc0f0233-fef1-32c3-adb4-d2b1b0cb6a2c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Ex1_StructuresforDifferentEnvironments_CER_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c3f926d9-b05d-34ed-8c1c-36f809330984_f351e1fa-136f-3e2e-b7fd-6b6cfaceedba.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ffb95c59-1d11-3989-8114-522b778cdd54',
                    file: 'ffb95c59-1d11-3989-8114-522b778cdd54_c171b208-6426-3a66-8d50-b57778bc81f5.html',
                    identifier: 'c572b62f-0db4-33a3-a42b-71768a5d4dd5',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Ex1_StructuresforDifferentEnvironments_CER_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ffb95c59-1d11-3989-8114-522b778cdd54_c171b208-6426-3a66-8d50-b57778bc81f5.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e25112f1-821b-349d-b04c-2f157d32b17e',
                    file: 'e25112f1-821b-349d-b04c-2f157d32b17e_10_5_structuresandfunctionsoforganisms_eb996.html',
                    identifier: 'a4736cdb-af1f-3fcc-bf2e-f3cd6098b6f7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Ex1_StructuresforDifferentEnvironments_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e25112f1-821b-349d-b04c-2f157d32b17e_10_5_structuresandfunctionsoforganisms_eb996.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Left ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b99f202e-353a-39b9-9f61-b28eba15bc8c',
                    file: 'b99f202e-353a-39b9-9f61-b28eba15bc8c_fbf6c609-fd7b-3f81-8e40-ccd8718f4eb2.html',
                    identifier: 'db2f086b-13de-34c7-98ad-2a915c8f3514',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Ex1_StructuresforDifferentEnvironments_RightISN_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b99f202e-353a-39b9-9f61-b28eba15bc8c_fbf6c609-fd7b-3f81-8e40-ccd8718f4eb2.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'acdaedb1-c2de-32e1-a7bf-6501db98ccca',
                    file: 'acdaedb1-c2de-32e1-a7bf-6501db98ccca_10_5_structuresandfunctionsoforganisms_efe79.html',
                    identifier: 'b50e1ff7-a2cc-3aae-a654-64884279f94d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Ex1_StructuresforDifferentEnvironments_RightISN_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/acdaedb1-c2de-32e1-a7bf-6501db98ccca_10_5_structuresandfunctionsoforganisms_efe79.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN- Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd43918a1-a1c7-363f-bbe5-bccd0e036e6d',
                    file: '',
                    identifier: 'd7e1337a-08d7-3407-810b-b17d2382a5f5',
                    root_type: 2,
                    type: 213,
                    section_page: 'link',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: EXPLORE_Hands On Explore_Structures for Different Environments_ISN Test Item Creation_Student Handout',
                    ext_res_link:
                      'https://unifiedfrost-cdn-dev.learningmate.co/701fc443-9f0f-4929-93f5-50488f53623b_frost/assets/other/a64d992a-9f63-3771-88d7-357238309732/STX_5_StructuresandFunctionsofOrganisms_EXPLORE_Explore1_ISN_LeftSide_TestItemCreation_SH.pdf',
                    ext_res_type: 'asset',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: 'a64d992a-9f63-3771-88d7-357238309732',
                    assignment_id: '',
                    assignment_source: '',
                    forum_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    content_type_identifier: 'document',
                    children: [],
                    parent_id: 'ddee1345-02dd-3941-8486-f647f009e522',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/701fc443-9f0f-4929-93f5-50488f53623b_frost/assets/other/a64d992a-9f63-3771-88d7-357238309732/STX_5_StructuresandFunctionsofOrganisms_EXPLORE_Explore1_ISN_LeftSide_TestItemCreation_SH.pdf',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Title',
                          value: 'Explore ISN Page 1',
                        },
                        {
                          key_name: 'File Type',
                          value: 'PDF File',
                        },
                        {
                          key_name: 'Content Group',
                          value: 'Hidden',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'e23e80a2-cbe8-330e-9245-e8f3743fcf79',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '60 minutes',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explore Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Explore',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                file: '',
                identifier: 'ae226e54-d70b-3cdd-8f98-c6da0ade3a1b',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Virtual Explore: Structures and Functions',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'efb03d72-7a83-33e3-a7d7-5bdc90ce0862',
                    file: 'efb03d72-7a83-33e3-a7d7-5bdc90ce0862_c50313d5-fbf3-38a9-bd9b-5484aaaa1d87.html',
                    identifier: 'd68206c0-2f82-37b8-823e-eca2c0b6d9e5',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Virtual Explore_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/efb03d72-7a83-33e3-a7d7-5bdc90ce0862_c50313d5-fbf3-38a9-bd9b-5484aaaa1d87.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Activity Title',
                          value: 'Structures and Functions',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b31500ad-8534-3a31-81d9-a241d441a3bf',
                    file: 'b31500ad-8534-3a31-81d9-a241d441a3bf_structures_and_functions_of_organisms_va9b7.html',
                    identifier: 'b01c12e5-95f2-36eb-8e18-fca37335417e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Virtual Explore_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b31500ad-8534-3a31-81d9-a241d441a3bf_structures_and_functions_of_organisms_va9b7.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Activity Title',
                          value: 'Structures and Functions',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c9eb875a-14c4-3641-98fe-bcc6cfe101f4',
                    file: 'c9eb875a-14c4-3641-98fe-bcc6cfe101f4_10_5_explore_vexp_structuresandfunctions607c.html',
                    identifier: 'db8a2e16-7e0e-34f6-9568-62514ead628a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explore_VEXP_StructuresandFunctionsofOrganisms_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c9eb875a-14c4-3641-98fe-bcc6cfe101f4_10_5_explore_vexp_structuresandfunctions607c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Left ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bb9809e6-d37d-3601-9a05-c7389f08c769',
                    file: 'bb9809e6-d37d-3601-9a05-c7389f08c769_10_5_explore_vexp_structuresandfunctionsbfe3.html',
                    identifier: 'e5428212-4ffd-3857-be26-a7258730ca83',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explore_VEXP_StructuresandFunctionsofOrganisms_RightISN_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bb9809e6-d37d-3601-9a05-c7389f08c769_10_5_explore_vexp_structuresandfunctionsbfe3.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c383e53e-0cf1-3f69-9119-4fa8861c3cd2',
                    file: 'c383e53e-0cf1-3f69-9119-4fa8861c3cd2_c9eb6111-2fdb-3a77-be83-f56434c2d875.html',
                    identifier: 'cbbac8b1-7701-3c90-905e-ec2b88a6f602',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explore_VEXP_StructuresandFunctionsofOrganisms_RightISN_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c383e53e-0cf1-3f69-9119-4fa8861c3cd2_c9eb6111-2fdb-3a77-be83-f56434c2d875.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN- Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'aa5d86dc-7ede-3779-b45e-46dbc02d994f',
                    file: 'aa5d86dc-7ede-3779-b45e-46dbc02d994f_10_5_explore_vexp_structuresandfunctionsfde2.html',
                    identifier: 'ce045919-ec05-3af3-8a24-d87ac2ff7254',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explore_VEXP_StructuresandFunctionsofOrganisms_ScienceTerminologyInventory',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'cecbfa88-c963-3098-9321-7f21872d51f6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/aa5d86dc-7ede-3779-b45e-46dbc02d994f_10_5_explore_vexp_structuresandfunctionsfde2.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Science Terminology Inventory',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'e23e80a2-cbe8-330e-9245-e8f3743fcf79',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explore Icon',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '20 minutes',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Virtual Explore',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                file: '',
                identifier: 'afd7d191-d712-347c-89d3-7765ae5243f7',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Hands On Explore: Made to Survive',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'e85f1862-706e-30ba-9da0-8734452b22ef',
                    file: 'e85f1862-706e-30ba-9da0-8734452b22ef_d4636c0c-bb35-35cc-b2a6-cf64573cc19a.html',
                    identifier: 'c7c8f365-5228-39e4-b78a-c6f8f690fb9e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Animal Structures and Functions: Hands On Explore_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e85f1862-706e-30ba-9da0-8734452b22ef_d4636c0c-bb35-35cc-b2a6-cf64573cc19a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Made to Survive',
                        },
                        {
                          key_name: 'Activity Time',
                          value: '30 minutes',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ffb8e9e3-51d4-3b66-8963-873680bc2df1',
                    file: 'ffb8e9e3-51d4-3b66-8963-873680bc2df1_d46db481-dd9b-3574-820a-f89c1c101757.html',
                    identifier: 'a91ed804-f92c-3057-b069-cc3859a64faa',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Animal Structures and Function: Hands On Explore_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ffb8e9e3-51d4-3b66-8963-873680bc2df1_d46db481-dd9b-3574-820a-f89c1c101757.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Made to Survive',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e85316a0-a809-358a-b02f-08e2e74f4783',
                    file: 'e85316a0-a809-358a-b02f-08e2e74f4783_c8fbd314-8ab4-3643-adb0-fb89b6863961.html',
                    identifier: 'c263d58b-6419-31e5-8246-3eac0e04609a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Structures and Functions of Organisms_EX1_MadetoSurvive_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e85316a0-a809-358a-b02f-08e2e74f4783_c8fbd314-8ab4-3643-adb0-fb89b6863961.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: null,
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd9f7af9b-7a70-39c4-87f8-f6e90f101d4c',
                    file: 'd9f7af9b-7a70-39c4-87f8-f6e90f101d4c_20_5_structures_and_functions_of_organis4e3b.html',
                    identifier: 'a6b886fb-815d-3598-812b-7832d27208d2',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Structures and Functions of Organisms_EX1_MadetoSurvive_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d9f7af9b-7a70-39c4-87f8-f6e90f101d4c_20_5_structures_and_functions_of_organis4e3b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ca0df570-5507-3dab-8391-eecbf74ec589',
                    file: 'ca0df570-5507-3dab-8391-eecbf74ec589_20_5_structures_and_functions_of_organise2ec.html',
                    identifier: 'e23280be-5f4b-32aa-b07c-7dd7514ee6e7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Structures and Functions of Organisms_EX1_MadetoSurvive_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ca0df570-5507-3dab-8391-eecbf74ec589_20_5_structures_and_functions_of_organise2ec.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Left ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f60865d2-6344-3934-96ea-068a9226da39',
                    file: 'f60865d2-6344-3934-96ea-068a9226da39_f141dd76-5385-3278-a17c-fa9817988102.html',
                    identifier: 'aab6251e-543f-3a6d-9470-519d1e2cee94',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Structures and Functions of Organisms_EX1_MadetoSurvive_RightISN_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f60865d2-6344-3934-96ea-068a9226da39_f141dd76-5385-3278-a17c-fa9817988102.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd60ed518-5a63-37d0-bdbe-150bc211777e',
                    file: 'd60ed518-5a63-37d0-bdbe-150bc211777e_20_5_structures_and_functions_of_organisdb4a.html',
                    identifier: 'ab42fd7f-04b4-388c-baf0-11d2db69b6fd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Structures and Functions of Organisms_EX1_MadetoSurvive_RightISN_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d60ed518-5a63-37d0-bdbe-150bc211777e_20_5_structures_and_functions_of_organisdb4a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN- Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ad7273c9-b601-3174-a612-9a3b1033190c',
                    file: 'ad7273c9-b601-3174-a612-9a3b1033190c_20_5_structures_and_functions_of_organise9ab.html',
                    identifier: 'c82c91d2-d591-312a-9ef7-c6bba9af01cd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Structures and Functions of Organisms_EX1_MadetoSurvive_AnimalCards',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2ec0f45-f530-3033-a45c-ed27de6cdb62',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ad7273c9-b601-3174-a612-9a3b1033190c_20_5_structures_and_functions_of_organise9ab.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Animal Cards',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'e23e80a2-cbe8-330e-9245-e8f3743fcf79',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explore Icon',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '30 minutes',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Explore',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'd3b43938-7fea-3bea-9ed3-f9ab8eceb4d4',
                file: '',
                identifier: 'xpqx14452f79ac0fed5b064c95668bfb',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Virtual Explore: Structures and Functions',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'b998bb57-b0a0-3925-929a-f7fb5516862a',
                    file: 'b998bb57-b0a0-3925-929a-f7fb5516862a_d238074e-221a-32fc-9b9d-c900c2127476.html',
                    identifier: 'cd8dfe45-b08b-3e01-816c-9223513ec145',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Animal Structures and Functions: Virtual Explore_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd3b43938-7fea-3bea-9ed3-f9ab8eceb4d4',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b998bb57-b0a0-3925-929a-f7fb5516862a_d238074e-221a-32fc-9b9d-c900c2127476.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Structures and Functions of Organisms',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'def401ff-c6db-3fcf-ae54-1dc582cf9e6d',
                    file: 'def401ff-c6db-3fcf-ae54-1dc582cf9e6d_cf41be02-34cd-32ff-8f5d-98c222629d0c.html',
                    identifier: 'e8bc5d80-3769-3e1b-8ed8-74350fb2d924',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Animal Structures and Functions: Virtual Explore_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd3b43938-7fea-3bea-9ed3-f9ab8eceb4d4',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/def401ff-c6db-3fcf-ae54-1dc582cf9e6d_cf41be02-34cd-32ff-8f5d-98c222629d0c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Activity Title',
                          value: 'Structures and Functions of Organisms',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: null,
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd6a02cf4-7b2c-3237-9479-8fd0a2d0c6a4',
                    file: 'd6a02cf4-7b2c-3237-9479-8fd0a2d0c6a4_ce2ecb5c-585f-30a0-b6f8-39ca29fc0b11.html',
                    identifier: 'e2b658f7-0846-3286-98ab-6c231f2c97d3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Explore_VEXP_AnimalStructuresAndFunctions_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd3b43938-7fea-3bea-9ed3-f9ab8eceb4d4',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d6a02cf4-7b2c-3237-9479-8fd0a2d0c6a4_ce2ecb5c-585f-30a0-b6f8-39ca29fc0b11.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Left ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'eca52933-6baa-32ab-aff3-05493ec0f7c7',
                    file: 'eca52933-6baa-32ab-aff3-05493ec0f7c7_d1bc4534-fa74-3ff6-802c-65172fd7e3ef.html',
                    identifier: 'ba78add4-e38a-30ff-89a1-7b96d75129ba',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Explore_VEXP_AnimalStructuresAndFunctions_RightISN_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd3b43938-7fea-3bea-9ed3-f9ab8eceb4d4',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/eca52933-6baa-32ab-aff3-05493ec0f7c7_d1bc4534-fa74-3ff6-802c-65172fd7e3ef.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'cd628374-a210-3032-8458-590c512952dc',
                    file: 'cd628374-a210-3032-8458-590c512952dc_e5db6a80-0ac3-300c-8593-2b25a8ede554.html',
                    identifier: 'f4676422-4fbe-3a60-89e0-adec198856f2',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Explore_VEXP_AnimalStructuresAndFunctions_RightISN_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd3b43938-7fea-3bea-9ed3-f9ab8eceb4d4',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/cd628374-a210-3032-8458-590c512952dc_e5db6a80-0ac3-300c-8593-2b25a8ede554.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN- Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'e23e80a2-cbe8-330e-9245-e8f3743fcf79',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explore Icon',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '30 minutes',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Virtual Explore',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Explore',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
            file: '',
            identifier: 'c5464e3c-cba9-3119-8e91-a05b5b0ff519',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Explain',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'e3226c4b-18ec-397f-8398-b287f7af0230',
                file: '',
                identifier: 'd3796ae9-f3f1-3df7-8ecc-3ec9b8db3b9d',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Interactive Science Notebook Facilitation',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'cdf96b82-d0da-3a7f-9dc7-b4068775f1fe',
                    file: 'cdf96b82-d0da-3a7f-9dc7-b4068775f1fe_fd2fe504-9566-334d-abff-98b3c1d3f236.html',
                    identifier: 'fff563b2-cd68-3e1c-92df-15704771fea3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Interactive Science Notebook_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e3226c4b-18ec-397f-8398-b287f7af0230',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/cdf96b82-d0da-3a7f-9dc7-b4068775f1fe_fd2fe504-9566-334d-abff-98b3c1d3f236.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Interactive Science Notebook',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                file: '',
                identifier: 'a414543b-3be9-39d5-aa44-4eb61efb675b',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: STEMscopedia',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'e78255eb-bb62-30d5-bbed-914fe203965b',
                    file: 'e78255eb-bb62-30d5-bbed-914fe203965b_cbc62e47-1c8a-3fab-9c94-c7d59381ba95.html',
                    identifier: 'aecf7173-127d-3f74-b3b8-8b213b742e56',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: STEMscopedia_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e78255eb-bb62-30d5-bbed-914fe203965b_cbc62e47-1c8a-3fab-9c94-c7d59381ba95.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd060489e-7717-3fe4-97b0-87690974d120',
                    file: 'd060489e-7717-3fe4-97b0-87690974d120_structures_and_functions_of_organisms_s72ad.html',
                    identifier: 'ce16eaa1-5720-3ef5-8d70-987f26961fed',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: STEMscopedia_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d060489e-7717-3fe4-97b0-87690974d120_structures_and_functions_of_organisms_s72ad.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a0486fce-94ab-3a57-88e3-56fd1278b35d',
                    file: 'a0486fce-94ab-3a57-88e3-56fd1278b35d_f35317fa-ff97-31b4-b61f-5771b1624a50.html',
                    identifier: 'ee0c705e-8a97-3f10-884a-68c3bb289964',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explain_Pedia_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a0486fce-94ab-3a57-88e3-56fd1278b35d_f35317fa-ff97-31b4-b61f-5771b1624a50.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b19e91ee-e3b1-3fa8-bbaa-d53ac31be469',
                    file: 'b19e91ee-e3b1-3fa8-bbaa-d53ac31be469_d47ae680-282a-3317-a431-2a381af40d83.html',
                    identifier: 'fda6e860-e522-31d2-9961-538b12eaf62a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explain_Pedia_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b19e91ee-e3b1-3fa8-bbaa-d53ac31be469_d47ae680-282a-3317-a431-2a381af40d83.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bd005678-7d70-30da-b019-3d67c59b66ab',
                    file: 'bd005678-7d70-30da-b019-3d67c59b66ab_10_5_explain_pedia_structuresandfunction23d6.html',
                    identifier: 'dc26fd50-721a-3997-bf77-2b177a37ee00',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explain_Pedia_StructuresandFunctionsofOrganisms_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bd005678-7d70-30da-b019-3d67c59b66ab_10_5_explain_pedia_structuresandfunction23d6.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Left ISN',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fef5a41d-3a8a-32d2-a10d-c434dd8390eb',
                    file: 'fef5a41d-3a8a-32d2-a10d-c434dd8390eb_10_5_explain_pedia_structuresandfunction4fa0.html',
                    identifier: 'd41e5b26-3a14-3c75-b05f-699d927c44f3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explain_Pedia_StructuresandFunctionsofOrganisms_RightISN_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fef5a41d-3a8a-32d2-a10d-c434dd8390eb_10_5_explain_pedia_structuresandfunction4fa0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ea615801-2feb-3a91-93d1-c8905585bf80',
                    file: 'ea615801-2feb-3a91-93d1-c8905585bf80_aab705b3-c3b4-39f6-a6ac-851f6e0c7de1.html',
                    identifier: 'bed4f24c-1bb0-3bae-9f0a-d46ff5fd1d79',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Explain_Pedia_StructuresandFunctionsofOrganisms_RightISN_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e61a0b7e-e860-3514-876a-bdc7c90114aa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ea615801-2feb-3a91-93d1-c8905585bf80_aab705b3-c3b4-39f6-a6ac-851f6e0c7de1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN - Answer Key',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'STEMscopedia',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '30 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'd5ec61ca-2c36-3b09-9b41-1d1a01c100d2',
                file: '',
                identifier: 'ffb21df6-9256-38f1-965b-592c4b9afd44',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Picture Vocabulary',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'c06ffbf2-e34c-312a-9ee2-90a484248dcc',
                    file: 'c06ffbf2-e34c-312a-9ee2-90a484248dcc_a464bbbf-4271-3459-b1fa-151f5dd7d8ec.html',
                    identifier: 'cd0a79cf-d00d-3a23-a812-4256f1515bb5',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Picture Vocabulary_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5ec61ca-2c36-3b09-9b41-1d1a01c100d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c06ffbf2-e34c-312a-9ee2-90a484248dcc_a464bbbf-4271-3459-b1fa-151f5dd7d8ec.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b27f496e-7bb5-3148-bdfc-b93196aa83fe',
                    file: 'b27f496e-7bb5-3148-bdfc-b93196aa83fe_structures_and_functions_of_organisms_p5e2c.html',
                    identifier: 'a93bfae5-d660-39fb-92e8-cfcc60d5f03f',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Picture Vocabulary_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5ec61ca-2c36-3b09-9b41-1d1a01c100d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b27f496e-7bb5-3148-bdfc-b93196aa83fe_structures_and_functions_of_organisms_p5e2c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a245e78f-c071-387e-83b3-fce1a38cdb32',
                    file: 'a245e78f-c071-387e-83b3-fce1a38cdb32_ab60cdd6-8d69-36df-a9f7-03ecd091656a.html',
                    identifier: 'b68b868e-c34d-33c3-8c81-57d7265a7dcd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_PictureVocabulary_PV_Slides',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5ec61ca-2c36-3b09-9b41-1d1a01c100d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a245e78f-c071-387e-83b3-fce1a38cdb32_ab60cdd6-8d69-36df-a9f7-03ecd091656a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Picture Vocabulary Slides',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ec76b833-b7bb-39cb-a405-04cce05f25e1',
                    file: 'ec76b833-b7bb-39cb-a405-04cce05f25e1_10_5_structuresandfunctionsoforganisms_p7cf4.html',
                    identifier: 'e4fbd9cb-3a60-310f-b034-751896c5c31b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_PictureVocabulary_PV_GameBoard',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5ec61ca-2c36-3b09-9b41-1d1a01c100d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ec76b833-b7bb-39cb-a405-04cce05f25e1_10_5_structuresandfunctionsoforganisms_p7cf4.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Game Board',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'de3a1a6d-9a9b-3317-9221-241d1924f204',
                    file: 'de3a1a6d-9a9b-3317-9221-241d1924f204_10_5_structuresandfunctionsoforganisms_pc30a.html',
                    identifier: 'cbe90426-fd5a-31ac-932e-5a17562019c3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_PictureVocabulary_PV_Game Cards',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5ec61ca-2c36-3b09-9b41-1d1a01c100d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/de3a1a6d-9a9b-3317-9221-241d1924f204_10_5_structuresandfunctionsoforganisms_pc30a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Game Cards',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Picture Vocabulary',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '20 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'a5f54152-2023-3f98-b737-4139b2df7c47',
                file: '',
                identifier: 'fed19181-b951-3c64-b31e-12124cbce4dd',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title: 'STX_Structures and Functions of Organisms: Pulse Check',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a57f5e96-1384-3d60-9821-fbf3eddd125c',
                    file: 'a57f5e96-1384-3d60-9821-fbf3eddd125c_structures_and_functions_of_organisms_pcdf9.html',
                    identifier: 'e6e4fbae-4c79-3c8b-a6d7-ae0e9497e62a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Pulse Check_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a5f54152-2023-3f98-b737-4139b2df7c47',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a57f5e96-1384-3d60-9821-fbf3eddd125c_structures_and_functions_of_organisms_pcdf9.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd4d94469-ba36-3749-b5f9-16eb79dfc378',
                    file: 'd4d94469-ba36-3749-b5f9-16eb79dfc378_ff91a525-1178-391f-b57f-0b9a580dabfd.html',
                    identifier: 'db741bb9-0d0d-3a2a-ab60-77cb1d13a11d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Pulse Check_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a5f54152-2023-3f98-b737-4139b2df7c47',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d4d94469-ba36-3749-b5f9-16eb79dfc378_ff91a525-1178-391f-b57f-0b9a580dabfd.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Pulse Check',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'd5989ef4-a88e-3abd-bcea-001e2c57af06',
                file: '',
                identifier: 'ipvp9ad4d3123b2893409720befb0a7a',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Kahoot! Picture Vocabulary',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ee7e9006-a649-3853-bcf9-9404d687addd',
                    file: 'ee7e9006-a649-3853-bcf9-9404d687addd_f20630f6-2820-3fbb-8578-9b1b92731a41.html',
                    identifier: 'c77a00ad-7221-378e-8cf2-ab5699cccb4a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'GENERIC_3HS_KahootPictureVocabulary_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5989ef4-a88e-3abd-bcea-001e2c57af06',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ee7e9006-a649-3853-bcf9-9404d687addd_f20630f6-2820-3fbb-8578-9b1b92731a41.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ba14b4ec-4467-3785-8926-470c83bfa025',
                    file: 'ba14b4ec-4467-3785-8926-470c83bfa025_10_5_kahootpicturevocabulary_structuresad68a.html',
                    identifier: 'c35f31b4-13cd-3e47-917e-ecdf475caad8',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_KahootPictureVocabulary_StructuresandFunctionsofOrganisms_Student',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd5989ef4-a88e-3abd-bcea-001e2c57af06',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ba14b4ec-4467-3785-8926-470c83bfa025_10_5_kahootpicturevocabulary_structuresad68a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Kahoot! Picture Vocabulary',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'ALITXReview',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e3e7e49c-e846-3fc3-8a3f-41a9ba7b9877',
                file: '',
                identifier: 'bijb5b8865e07ecbdd52a1cac7fbca33',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Interactive Science Notebook Facilitation',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'aa9efbe2-43fd-3006-9f43-75a3c14d5579',
                    file: 'aa9efbe2-43fd-3006-9f43-75a3c14d5579_f2113852-2382-3e97-bbe5-ce506e41afe1.html',
                    identifier: 'f40d91b2-52b3-3568-a216-6a5f358cb254',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Interactive Science Notebook_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e3e7e49c-e846-3fc3-8a3f-41a9ba7b9877',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/aa9efbe2-43fd-3006-9f43-75a3c14d5579_f2113852-2382-3e97-bbe5-ce506e41afe1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Interactive Science Notebook',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                file: '',
                identifier: 'jhxde6c9d3f431c292056a10d08d2943',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: STEMscopedia',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'aeaebb82-1959-3533-9f46-02129eace8bd',
                    file: 'aeaebb82-1959-3533-9f46-02129eace8bd_fe356661-aa91-3c2d-9756-9ea74a7960aa.html',
                    identifier: 'f90a4152-4a8e-3dc4-b2ff-ed6077453086',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: STEMscopedia_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/aeaebb82-1959-3533-9f46-02129eace8bd_fe356661-aa91-3c2d-9756-9ea74a7960aa.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e44f77b0-2d3e-3e8a-97fe-848b2d73a7cd',
                    file: 'e44f77b0-2d3e-3e8a-97fe-848b2d73a7cd_sfl_structures_and_functions_of_organismd669.html',
                    identifier: 'e8530c84-35d6-331f-a661-79509cc838df',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: STEMscopedia_Student Digitization_PEDIA',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e44f77b0-2d3e-3e8a-97fe-848b2d73a7cd_sfl_structures_and_functions_of_organismd669.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fce4d561-b141-3b29-8b54-a5703721bd6d',
                    file: 'fce4d561-b141-3b29-8b54-a5703721bd6d_sfl_structures_and_functions_of_organismbcbf.html',
                    identifier: 'd93fd45c-a3c4-3cbc-921f-b9b4754da0ee',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: STEMscopedia_Student Digitization_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fce4d561-b141-3b29-8b54-a5703721bd6d_sfl_structures_and_functions_of_organismbcbf.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Left ISN',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b9ca5da7-a4b0-3c0e-94bc-f8915c6b5641',
                    file: 'b9ca5da7-a4b0-3c0e-94bc-f8915c6b5641_sfl_structures_and_functions_of_organism9b6a.html',
                    identifier: 'fc8dccec-6ebd-37c1-a763-af9e709c02e7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: STEMscopedia_Student Digitization_RightISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b9ca5da7-a4b0-3c0e-94bc-f8915c6b5641_sfl_structures_and_functions_of_organism9b6a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Right ISN ',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b4422316-ea8d-3894-a146-ae0e4534e82c',
                    file: 'b4422316-ea8d-3894-a146-ae0e4534e82c_bb26e5b8-996e-36f4-b892-c4d939ed1d10.html',
                    identifier: 'ddf12d0d-fab2-3964-9ae8-e0fabeed6b06',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '20_3_Explain_Pedia_AnimalStructuresandFunctions_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b4422316-ea8d-3894-a146-ae0e4534e82c_bb26e5b8-996e-36f4-b892-c4d939ed1d10.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a2db3fcc-f7b0-3c76-a6c7-02b3716ed7e9',
                    file: 'a2db3fcc-f7b0-3c76-a6c7-02b3716ed7e9_d2c336f8-c944-3a45-b32c-e28ece649ecf.html',
                    identifier: 'fd2d44b7-ea9f-35fd-93a9-d97abe8835cd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '20_3_Explain_Pedia_AnimalStructuresandFunctions_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a2db3fcc-f7b0-3c76-a6c7-02b3716ed7e9_d2c336f8-c944-3a45-b32c-e28ece649ecf.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c6f1a216-2d7d-3002-81e8-16060b4000a2',
                    file: 'c6f1a216-2d7d-3002-81e8-16060b4000a2_20_5_explain_pedia_structuresandfunctionf301.html',
                    identifier: 'a02cf58f-ea1b-3066-98e3-dfbd2f9022a1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Explain_Pedia_StructuresandFunctionsofOrganisms_LeftISN',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c6f1a216-2d7d-3002-81e8-16060b4000a2_20_5_explain_pedia_structuresandfunctionf301.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Left ISN - Student Handout',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ad836597-2fd5-3394-970f-3c28d6496347',
                    file: 'ad836597-2fd5-3394-970f-3c28d6496347_df76974a-5e76-326b-9b9b-00cd33e596c2.html',
                    identifier: 'd5d41ed3-1c71-3b13-9658-06c4bf602b92',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_3_Explain_Pedia_AnimalStructuresandFunctions_RightISN_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ad836597-2fd5-3394-970f-3c28d6496347_df76974a-5e76-326b-9b9b-00cd33e596c2.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN - Answer Key',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dc0a180e-b5b7-3c62-88f5-d54c847d54be',
                    file: 'dc0a180e-b5b7-3c62-88f5-d54c847d54be_c3caab40-364e-361c-bdcb-8926a56037ce.html',
                    identifier: 'd6a2f3af-3dc0-38bc-ac7b-4337348d929c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_3_Explain_Pedia_AnimalStructuresandFunctions_RightISN_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a1be0d29-417f-3844-84dd-fc20371f544f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dc0a180e-b5b7-3c62-88f5-d54c847d54be_c3caab40-364e-361c-bdcb-8926a56037ce.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Right ISN - Answer Key',
                        },
                        {
                          key_name: 'Editable',
                          value: 'No',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'STEMscopedia',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '30 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'ddffa867-7031-3cbe-847f-37a61f8db15e',
                file: '',
                identifier: 'zuazf4db42448e88a7433d61bc416969',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Picture Vocabulary',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'd1271e33-7f4c-3e14-a6d8-4a2164b287c3',
                    file: 'd1271e33-7f4c-3e14-a6d8-4a2164b287c3_sfl_structures_and_functions_of_organism843a.html',
                    identifier: 'eb33f618-bb7f-3b0c-822c-047dc4b09929',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Picture Vocabulary_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddffa867-7031-3cbe-847f-37a61f8db15e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d1271e33-7f4c-3e14-a6d8-4a2164b287c3_sfl_structures_and_functions_of_organism843a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c7c82490-dcff-3df5-af6b-5adaa13151c7',
                    file: 'c7c82490-dcff-3df5-af6b-5adaa13151c7_a2409737-10eb-3bff-a179-a93a566e6de1.html',
                    identifier: 'bf69edb8-e384-3582-a9d4-9e9ceeeadddb',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Picture Vocabulary_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddffa867-7031-3cbe-847f-37a61f8db15e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c7c82490-dcff-3df5-af6b-5adaa13151c7_a2409737-10eb-3bff-a179-a93a566e6de1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b7177df8-c0a4-35cf-9e73-8681248a56cc',
                    file: 'b7177df8-c0a4-35cf-9e73-8681248a56cc_e26d12f1-283d-31eb-ba27-1551026e996c.html',
                    identifier: 'f616678d-d38a-327b-85dc-3efb7ccf3893',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_StructuresandFunctionsofOrganisms_PictureVocabulary_PV_Slides',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddffa867-7031-3cbe-847f-37a61f8db15e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b7177df8-c0a4-35cf-9e73-8681248a56cc_e26d12f1-283d-31eb-ba27-1551026e996c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Picture Vocabulary Slides',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e6aabb4d-45cc-3d3f-b0bd-5393043b3b6b',
                    file: 'e6aabb4d-45cc-3d3f-b0bd-5393043b3b6b_c640deaf-b25c-3aab-81fb-51e0dc9f7dfc.html',
                    identifier: 'f256f28c-1eb9-3161-9f89-e802fbb8c626',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_StructuresandFunctionsofOrganisms_PictureVocabulary_PV_GameBoard',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddffa867-7031-3cbe-847f-37a61f8db15e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e6aabb4d-45cc-3d3f-b0bd-5393043b3b6b_c640deaf-b25c-3aab-81fb-51e0dc9f7dfc.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Game Board',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd7b5c9a9-1b87-367f-942b-fdbd53bc1b3c',
                    file: 'd7b5c9a9-1b87-367f-942b-fdbd53bc1b3c_b896c155-a871-373f-bb02-a38dd89360ad.html',
                    identifier: 'def86211-f0ca-3f1b-9707-9918668644e7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_StructuresandFunctionsofOrganisms_PictureVocabulary_PV_Game Cards',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ddffa867-7031-3cbe-847f-37a61f8db15e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d7b5c9a9-1b87-367f-942b-fdbd53bc1b3c_b896c155-a871-373f-bb02-a38dd89360ad.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Game Cards',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Picture Vocabulary',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explore Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'b82e68ee-cf2c-3b57-b398-44da7559394b',
                file: '',
                identifier: 'xqci8cc44413816440ec0f1e87614806',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title: 'SFL_Structures and Functions of Organisms: Pulse Check',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'acbfb843-c259-3b18-beb1-45cb336e5445',
                    file: 'acbfb843-c259-3b18-beb1-45cb336e5445_fdf21eaf-ec04-3824-a206-1ee08dd59db2.html',
                    identifier: 'ad0c6cfe-c97c-3c7e-99bf-580dfd045d64',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Pulse Check_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b82e68ee-cf2c-3b57-b398-44da7559394b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/acbfb843-c259-3b18-beb1-45cb336e5445_fdf21eaf-ec04-3824-a206-1ee08dd59db2.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dd865076-332d-3478-b7cc-99aadf43c50f',
                    file: 'dd865076-332d-3478-b7cc-99aadf43c50f_sfl_structures_and_functions_of_organisb3d1.html',
                    identifier: 'eaf7e568-3e15-3850-a40f-05f97a957dfc',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_ Structures and Functions of Organisms: Pulse Check_ Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b82e68ee-cf2c-3b57-b398-44da7559394b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dd865076-332d-3478-b7cc-99aadf43c50f_sfl_structures_and_functions_of_organisb3d1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Hide',
                          value: 'Yes',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Pulse Check',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'ec4683ef-d60a-3fee-aca3-2bf84a7d7d79',
                file: '',
                identifier: 'yzkdea6abf37f679d0ecf599161c5f55',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Kahoot! Picture Vocabulary',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'e62f5a00-ae47-31d2-a8e7-5120d82af52a',
                    file: 'e62f5a00-ae47-31d2-a8e7-5120d82af52a_b6046f71-7605-312c-a2a8-652404242c23.html',
                    identifier: 'bb015b25-47a8-3d8d-80b1-45903dee3bca',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'GENERIC_3HS_KahootPictureVocabulary_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ec4683ef-d60a-3fee-aca3-2bf84a7d7d79',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e62f5a00-ae47-31d2-a8e7-5120d82af52a_b6046f71-7605-312c-a2a8-652404242c23.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd19875df-407f-3257-95bc-602d30f3e916',
                    file: 'd19875df-407f-3257-95bc-602d30f3e916_ac59a60c-67c3-34b4-81f4-442dadf79fb8.html',
                    identifier: 'abe4a5cf-f4ec-3cdb-860a-c10737005c9b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_KahootPictureVocabulary_StructuresandFunctionsofOrganisms_Student',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ec4683ef-d60a-3fee-aca3-2bf84a7d7d79',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d19875df-407f-3257-95bc-602d30f3e916_ac59a60c-67c3-34b4-81f4-442dadf79fb8.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'df85c247-e3cb-3ee9-a33e-40ddc80850cd',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Explain Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Kahoot! Picture Vocabulary',
                    },
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Explain',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
            file: '',
            identifier: 'c4139092-e6ca-39b4-8254-e6d13c278701',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Elaborate',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'a3c23a27-0dbf-3d13-9597-ac5c2da1c03c',
                file: '',
                identifier: 'mjssd8ae76d7a4ac0d33067e8c8e4c54',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Content Connections Video',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'c3429636-21a1-3e5b-a1ee-c8ee8dc3f4c1',
                    file: 'c3429636-21a1-3e5b-a1ee-c8ee8dc3f4c1_stx_structures_and_functions_of_organism76ff.html',
                    identifier: 'fca496ac-6a0e-3a5f-adc7-a0c8ec88f915',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: ELABORATE_CCV_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a3c23a27-0dbf-3d13-9597-ac5c2da1c03c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c3429636-21a1-3e5b-a1ee-c8ee8dc3f4c1_stx_structures_and_functions_of_organism76ff.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f82049f8-9d6b-3b0e-80f7-a052164055de',
                    file: 'f82049f8-9d6b-3b0e-80f7-a052164055de_stx_structures_and_functions_of_organism9085.html',
                    identifier: 'd4e5b9c5-65a1-3638-9aee-5da0e3341671',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: CCV_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a3c23a27-0dbf-3d13-9597-ac5c2da1c03c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f82049f8-9d6b-3b0e-80f7-a052164055de_stx_structures_and_functions_of_organism9085.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f0b1190e-a0a9-349d-8dc0-b50e7aa13f5b',
                    file: 'f0b1190e-a0a9-349d-8dc0-b50e7aa13f5b_eaf58105-36c9-3e78-9be3-cdc4cfc1aed9.html',
                    identifier: 'b297e79a-a5b4-328f-9328-328f792533c1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_CCV_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a3c23a27-0dbf-3d13-9597-ac5c2da1c03c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f0b1190e-a0a9-349d-8dc0-b50e7aa13f5b_eaf58105-36c9-3e78-9be3-cdc4cfc1aed9.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fa7dd2ec-730f-3dea-8b00-0d1347073788',
                    file: 'fa7dd2ec-730f-3dea-8b00-0d1347073788_10_5_elaborate_ccv_structuresandfunction78a8.html',
                    identifier: 'ae684156-093a-3d9e-97e9-c9cbdc7763fd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_CCV_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a3c23a27-0dbf-3d13-9597-ac5c2da1c03c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fa7dd2ec-730f-3dea-8b00-0d1347073788_10_5_elaborate_ccv_structuresandfunction78a8.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Content Connections Video',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'ed54c620-2492-36c5-a854-e7c1322793d2',
                file: '',
                identifier: 'ce242b42-e40c-362a-8721-96caa4850aaf',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Science Connection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'f6a14ebe-07cc-382a-8789-822190450ca7',
                    file: 'f6a14ebe-07cc-382a-8789-822190450ca7_facd4b8b-8772-30ac-a301-90db61561f9e.html',
                    identifier: 'b0b3b4e9-1478-3ef4-8ece-1f477c440341',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Science Connection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ed54c620-2492-36c5-a854-e7c1322793d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f6a14ebe-07cc-382a-8789-822190450ca7_facd4b8b-8772-30ac-a301-90db61561f9e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd9478778-8fc5-378c-8d74-8d8bbad75a73',
                    file: 'd9478778-8fc5-378c-8d74-8d8bbad75a73_structures_and_functions_of_organisms_sc371.html',
                    identifier: 'ae1dd02b-be7c-302c-9d88-9a2052880bc3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Science Connection_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ed54c620-2492-36c5-a854-e7c1322793d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d9478778-8fc5-378c-8d74-8d8bbad75a73_structures_and_functions_of_organisms_sc371.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b31a7a84-6e04-3412-a396-31c0fe738a49',
                    file: 'b31a7a84-6e04-3412-a396-31c0fe738a49_d1b4fdd8-6dfd-34a7-8c1b-ef6ac7065c5d.html',
                    identifier: 'fc7df59e-69a9-3f34-b6ac-22a4611741f4',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_SC_StructuresandFunctionsofOrganisms_InformativeSpeech_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ed54c620-2492-36c5-a854-e7c1322793d2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b31a7a84-6e04-3412-a396-31c0fe738a49_d1b4fdd8-6dfd-34a7-8c1b-ef6ac7065c5d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Connection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'ae701e7c-42c5-3eee-97ed-dcc43ea6f3fc',
                file: '',
                identifier: 'f3a72b4e-37ff-35d7-afed-b217e391afc0',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Engineering Connection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'c629d472-c642-33cc-b4ac-0e9158a8166d',
                    file: 'c629d472-c642-33cc-b4ac-0e9158a8166d_c7345bf3-437c-3739-8237-a7c657b2658f.html',
                    identifier: 'aa44dc8e-9db0-3e81-8ea4-644b6f30b70c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Engineering Connection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ae701e7c-42c5-3eee-97ed-dcc43ea6f3fc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c629d472-c642-33cc-b4ac-0e9158a8166d_c7345bf3-437c-3739-8237-a7c657b2658f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fccf1531-1c28-3a3f-bf34-4c2869733c49',
                    file: 'fccf1531-1c28-3a3f-bf34-4c2869733c49_bfad653e-ea73-38ca-b385-13f04c74207b.html',
                    identifier: 'b399538d-be02-3df5-abeb-6aa612130504',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Engineering Connection_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ae701e7c-42c5-3eee-97ed-dcc43ea6f3fc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fccf1531-1c28-3a3f-bf34-4c2869733c49_bfad653e-ea73-38ca-b385-13f04c74207b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd4a75f17-d211-3ac2-a8dd-eb7646157770',
                    file: 'd4a75f17-d211-3ac2-a8dd-eb7646157770_fcfde67d-2fdb-30b1-8c32-6705bd6b7e97.html',
                    identifier: 'ab66dbc1-97d8-3e99-b83e-3169b87c0396',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_EC_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ae701e7c-42c5-3eee-97ed-dcc43ea6f3fc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d4a75f17-d211-3ac2-a8dd-eb7646157770_fcfde67d-2fdb-30b1-8c32-6705bd6b7e97.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b87dc689-5c26-31f1-837c-7b501be965c8',
                    file: 'b87dc689-5c26-31f1-837c-7b501be965c8_b67ffa1a-6143-3ea7-8f8a-5001a01245f8.html',
                    identifier: 'bb045369-0dc3-34d3-ac3d-cf485f56a1ab',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'K_HS: Elaborate_EDP_Generic',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ae701e7c-42c5-3eee-97ed-dcc43ea6f3fc',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b87dc689-5c26-31f1-837c-7b501be965c8_b67ffa1a-6143-3ea7-8f8a-5001a01245f8.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Engineering Design Process',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Engineering Connection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                file: '',
                identifier: 'c22b8c52-d9fd-3212-ad09-2ed7f5f6363a',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Science Today',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'bcabc215-ee82-3f90-a101-35fe2388e57b',
                    file: 'bcabc215-ee82-3f90-a101-35fe2388e57b_c400a3ba-50da-3cb5-9ccc-ea9aa0b6fcdf.html',
                    identifier: 'c58b251d-f5af-308c-861c-2ae5c12648d1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Science Today_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bcabc215-ee82-3f90-a101-35fe2388e57b_c400a3ba-50da-3cb5-9ccc-ea9aa0b6fcdf.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b66d898a-ce66-3a67-b32b-faf50b30a726',
                    file: 'b66d898a-ce66-3a67-b32b-faf50b30a726_structures_and_functions_of_organisms_s557d.html',
                    identifier: 'e52d9da0-6fb1-3980-a64e-c2333aea3441',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Science Today_OL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b66d898a-ce66-3a67-b32b-faf50b30a726_structures_and_functions_of_organisms_s557d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value:
                            "It's wrong to blame bats for the coronavirus epidemic - OL",
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dd043655-a030-32e4-82b9-c1f4ac3a11af',
                    file: 'dd043655-a030-32e4-82b9-c1f4ac3a11af_stx_structures_and_functions_of_organism34c1.html',
                    identifier: 'd61d4cbc-bd46-3b03-8e10-048b567b9779',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Science Today_BL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dd043655-a030-32e4-82b9-c1f4ac3a11af_stx_structures_and_functions_of_organism34c1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value:
                            "It's wrong to blame bats for the coronavirus epidemic - BL",
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a2a44c5b-f5e5-32bf-8ca1-1a651c9ad280',
                    file: 'a2a44c5b-f5e5-32bf-8ca1-1a651c9ad280_d8dfaa17-abf3-3b35-ada2-58e1f28c3aff.html',
                    identifier: 'c4ba273e-d837-3f09-b667-b5adc194b74b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_OL_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a2a44c5b-f5e5-32bf-8ca1-1a651c9ad280_d8dfaa17-abf3-3b35-ada2-58e1f28c3aff.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout- On Level',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dc60c982-ee97-3aef-9427-389f41e2dc4e',
                    file: 'dc60c982-ee97-3aef-9427-389f41e2dc4e_f4315eab-a0a9-3668-b718-3bb874f5dae7.html',
                    identifier: 'f1e1def3-d40d-3934-871a-df442269cef0',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_OL_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dc60c982-ee97-3aef-9427-389f41e2dc4e_f4315eab-a0a9-3668-b718-3bb874f5dae7.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key- OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f618a0b7-e439-30a0-bab2-07bca117a2f8',
                    file: 'f618a0b7-e439-30a0-bab2-07bca117a2f8_f8f7ad8f-efe1-3fd9-a7c0-f49e5ab51d41.html',
                    identifier: 'bfa4183a-3bda-3d26-89d3-66b15666a468',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_BL_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f618a0b7-e439-30a0-bab2-07bca117a2f8_f8f7ad8f-efe1-3fd9-a7c0-f49e5ab51d41.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout- BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c8820a26-71c2-3e58-b108-1f754072bd20',
                    file: 'c8820a26-71c2-3e58-b108-1f754072bd20_c3ba6a4b-2c35-3e36-8fe7-34ed96e32417.html',
                    identifier: 'f396d790-d770-3b55-bd5d-22172ce54c80',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_BL_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bea98147-f6bd-38dd-8337-48a50d572c72',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c8820a26-71c2-3e58-b108-1f754072bd20_c3ba6a4b-2c35-3e36-8fe7-34ed96e32417.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key- BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Today',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Grade Level',
                      value: null,
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'ab6d9cd1-c9f5-30a3-bca5-997e93a7955a',
                file: '',
                identifier: 'zzcffd1df82f77064fddad9688413110',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Writing Science',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'df2c4540-45d0-3720-846f-204d75fb8a88',
                    file: 'df2c4540-45d0-3720-846f-204d75fb8a88_stx_structures_and_functions_of_organism5c4c.html',
                    identifier: 'd1b2e089-63ee-38c8-8089-f946bf1c66f7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Writing Science _Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ab6d9cd1-c9f5-30a3-bca5-997e93a7955a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/df2c4540-45d0-3720-846f-204d75fb8a88_stx_structures_and_functions_of_organism5c4c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b43e9df4-41a9-36db-b990-c7a874cf1d63',
                    file: 'b43e9df4-41a9-36db-b990-c7a874cf1d63_stx_structures_and_functions_of_organisa1a4.html',
                    identifier: 'b5405157-85b0-3200-a733-363ddf8f86d9',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_ Structures and Functions of Organisms_Student Digization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ab6d9cd1-c9f5-30a3-bca5-997e93a7955a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b43e9df4-41a9-36db-b990-c7a874cf1d63_stx_structures_and_functions_of_organisa1a4.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'db1a254c-0dc8-3845-99ad-0d03db27affa',
                    file: 'db1a254c-0dc8-3845-99ad-0d03db27affa_b190af5e-70e8-3d07-8328-6cc5ccf49d1f.html',
                    identifier: 'ffc80497-cfa3-3335-b7d6-a8c0c3e74c43',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_Structures and Functions of Organisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ab6d9cd1-c9f5-30a3-bca5-997e93a7955a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/db1a254c-0dc8-3845-99ad-0d03db27affa_b190af5e-70e8-3d07-8328-6cc5ccf49d1f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ca40d230-f823-3a07-98cb-6fc95e79ac5b',
                    file: 'ca40d230-f823-3a07-98cb-6fc95e79ac5b_b664205d-7da1-3c9c-98cf-09881961ab23.html',
                    identifier: 'e163c0a6-c7a9-38d3-a62b-713841d5b522',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_Structures and Functions of Organisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'ab6d9cd1-c9f5-30a3-bca5-997e93a7955a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ca40d230-f823-3a07-98cb-6fc95e79ac5b_b664205d-7da1-3c9c-98cf-09881961ab23.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Writing Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSC1245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                file: '',
                identifier: 'bee9bbe3-0729-32ac-bf89-5743a394912b',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Reading Science',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ad9dc0f3-a3e7-3e56-9f8c-632b94999e72',
                    file: 'ad9dc0f3-a3e7-3e56-9f8c-632b94999e72_ffd0daba-07ac-37ed-a335-e0e11d003ab5.html',
                    identifier: 'e448f2d9-c6cf-30c3-ba7a-7c409e394028',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Reading Science_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ad9dc0f3-a3e7-3e56-9f8c-632b94999e72_ffd0daba-07ac-37ed-a335-e0e11d003ab5.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fb229e8d-7e72-39a5-b71a-352491ce1542',
                    file: 'fb229e8d-7e72-39a5-b71a-352491ce1542_structures_and_functions_of_organisms_r4c92.html',
                    identifier: 'd2a98d4e-db18-3d2d-b4f1-f2643721cbde',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Reading Science_OL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fb229e8d-7e72-39a5-b71a-352491ce1542_structures_and_functions_of_organisms_r4c92.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Life in the Desert - OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'af07b360-35fe-3bd3-8de1-28876b7b6036',
                    file: 'af07b360-35fe-3bd3-8de1-28876b7b6036_stx_structures_and_functions_of_organismff33.html',
                    identifier: 'fa8e2b88-4d9d-3a29-a8cc-b1c3e34b5a7d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Reading Science_BL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/af07b360-35fe-3bd3-8de1-28876b7b6036_stx_structures_and_functions_of_organismff33.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Life in the Desert - BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e0545ee8-5d26-3892-8e81-7514ea6d4c02',
                    file: 'e0545ee8-5d26-3892-8e81-7514ea6d4c02_a8826fd0-ac5e-32d7-8848-9768ee7bdc47.html',
                    identifier: 'ca3e7315-a67f-3d03-a109-2824e805a29f',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_OnLevel_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e0545ee8-5d26-3892-8e81-7514ea6d4c02_a8826fd0-ac5e-32d7-8848-9768ee7bdc47.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout - OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'de198a86-b924-3182-b0ef-89dd7f4ca7a5',
                    file: 'de198a86-b924-3182-b0ef-89dd7f4ca7a5_f8078a13-2131-3508-91e1-eb270c5b9f0d.html',
                    identifier: 'ffde3b00-cc0a-38fe-b2ed-987da37ae649',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_OnLevel_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/de198a86-b924-3182-b0ef-89dd7f4ca7a5_f8078a13-2131-3508-91e1-eb270c5b9f0d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key - OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dc6fd1eb-d27e-3e29-9364-5b800ac1dd8f',
                    file: 'dc6fd1eb-d27e-3e29-9364-5b800ac1dd8f_de46e35f-f547-3b3c-8a1f-a20f402fef00.html',
                    identifier: 'aba76e8c-9965-31e6-9e99-46d84cc77212',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_BelowLevel_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dc6fd1eb-d27e-3e29-9364-5b800ac1dd8f_de46e35f-f547-3b3c-8a1f-a20f402fef00.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout - BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a5580fe9-1c10-3021-92a0-611235e855a2',
                    file: 'a5580fe9-1c10-3021-92a0-611235e855a2_a8a3b2da-b822-33db-b21a-0e85222e1867.html',
                    identifier: 'c6f499a9-c756-34a9-b168-ad1a07e389b3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_BelowLevel_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a5580fe9-1c10-3021-92a0-611235e855a2_a8a3b2da-b822-33db-b21a-0e85222e1867.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key - BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dde0f61d-682b-3572-924d-f9f0c5b6523f',
                    file: 'dde0f61d-682b-3572-924d-f9f0c5b6523f_df295844-69d9-3cca-8ce4-1864004b8bf3.html',
                    identifier: 'bc00a2a9-5945-3037-b0de-f280e4a9a899',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'MSHS_IdeaBookMarks_Generic',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e70984a6-0cca-3a7f-8002-912b4ceef1a2',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dde0f61d-682b-3572-924d-f9f0c5b6523f_df295844-69d9-3cca-8ce4-1864004b8bf3.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Idea Bookmarks',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Reading Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '20 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c64ea825-07e4-3901-b9a7-4ae0486ba93a',
                file: '',
                identifier: 'sclv52f0ee6a8fdd187aa4cd10b3f7a9',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Math Connection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'f228f7c5-0cee-3a92-b5b4-e5f3327d7eb4',
                    file: 'f228f7c5-0cee-3a92-b5b4-e5f3327d7eb4_stx_structures_and_functions_of_organisma4ab.html',
                    identifier: 'd0af10c6-5fe9-3eec-bfcc-e3cd9371b79c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Math Connection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c64ea825-07e4-3901-b9a7-4ae0486ba93a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f228f7c5-0cee-3a92-b5b4-e5f3327d7eb4_stx_structures_and_functions_of_organisma4ab.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ffed55f7-fcc2-338d-a2d9-39e23bda097b',
                    file: 'ffed55f7-fcc2-338d-a2d9-39e23bda097b_stx_structures_and_functions_of_organismd7f7.html',
                    identifier: 'db19f550-67e4-3cd9-80a4-932127cd8506',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Math Connection_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c64ea825-07e4-3901-b9a7-4ae0486ba93a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ffed55f7-fcc2-338d-a2d9-39e23bda097b_stx_structures_and_functions_of_organismd7f7.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd80d3323-fa05-3b84-ad67-2e7ad4c516b8',
                    file: 'd80d3323-fa05-3b84-ad67-2e7ad4c516b8_a49ea910-b491-388b-bc57-8193ac856556.html',
                    identifier: 'efe5d30d-dd90-313a-9fb6-85f5548e7e29',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_MC_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c64ea825-07e4-3901-b9a7-4ae0486ba93a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d80d3323-fa05-3b84-ad67-2e7ad4c516b8_a49ea910-b491-388b-bc57-8193ac856556.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e57ab7c3-ec30-30d4-b54d-02b91aca6053',
                    file: 'e57ab7c3-ec30-30d4-b54d-02b91aca6053_be645c36-3594-389c-ad84-e2f6a89e3d3d.html',
                    identifier: 'eafe59ab-b54e-3aaa-bd49-63a2f89399a4',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_MC_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c64ea825-07e4-3901-b9a7-4ae0486ba93a',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e57ab7c3-ec30-30d4-b54d-02b91aca6053_be645c36-3594-389c-ad84-e2f6a89e3d3d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Math Connection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c11016c7-6dc3-36ea-b001-d7cd4669492f',
                file: '',
                identifier: 'cdf855fa-43af-345f-afeb-be499840fe41',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Virtual Experience',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'c6b790c0-50f7-3a5f-8f91-8de99ab2100f',
                    file: 'c6b790c0-50f7-3a5f-8f91-8de99ab2100f_dc061739-bf94-3997-bf90-d6c17385896e.html',
                    identifier: 'd1a3c915-337e-33cb-b484-a8e81ba0cfb6',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Virtual Experience_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c11016c7-6dc3-36ea-b001-d7cd4669492f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c6b790c0-50f7-3a5f-8f91-8de99ab2100f_dc061739-bf94-3997-bf90-d6c17385896e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fdca38b2-0c20-37c7-90dc-53b83b518eab',
                    file: 'fdca38b2-0c20-37c7-90dc-53b83b518eab_structures_and_functions_of_organisms_v0188.html',
                    identifier: 'd7d35f1f-61b0-387b-be6d-6657b615b34a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Virtual Experience_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c11016c7-6dc3-36ea-b001-d7cd4669492f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fdca38b2-0c20-37c7-90dc-53b83b518eab_structures_and_functions_of_organisms_v0188.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fbdc3362-2a38-3925-bc77-781d1217842c',
                    file: 'fbdc3362-2a38-3925-bc77-781d1217842c_f0dc7ffa-afc8-310f-9bca-afb3715457c3.html',
                    identifier: 'ab8d18a4-8b69-3acd-a422-48a5a64ac26f',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_VX_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c11016c7-6dc3-36ea-b001-d7cd4669492f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fbdc3362-2a38-3925-bc77-781d1217842c_f0dc7ffa-afc8-310f-9bca-afb3715457c3.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'de95590c-5b01-38a3-9bd2-6447826c56f7',
                    file: 'de95590c-5b01-38a3-9bd2-6447826c56f7_c8d732e7-e72d-3171-a731-d83ba97bd1db.html',
                    identifier: 'f89b62b1-f150-3400-9298-5c2f85f659e2',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_VX_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c11016c7-6dc3-36ea-b001-d7cd4669492f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/de95590c-5b01-38a3-9bd2-6447826c56f7_c8d732e7-e72d-3171-a731-d83ba97bd1db.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Virtual Experience',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'a30a3bdc-d1cf-3286-8eab-6543c1d3a46e',
                file: '',
                identifier: 'zzsse463756d6c3d09fe0e9e46879ee6',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title: 'SFL_Structures and Functions of Organisms: CCV',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ac33209b-c6a3-3fb6-bcc2-151be5d37337',
                    file: 'ac33209b-c6a3-3fb6-bcc2-151be5d37337_sfl_structures_and_functions_of_organism1d11.html',
                    identifier: 'f7484f8c-7fe4-3da8-80ea-235c2980020c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: CCV_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a30a3bdc-d1cf-3286-8eab-6543c1d3a46e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ac33209b-c6a3-3fb6-bcc2-151be5d37337_sfl_structures_and_functions_of_organism1d11.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a43ef8f1-8377-38e1-8d32-52a2113d419b',
                    file: 'a43ef8f1-8377-38e1-8d32-52a2113d419b_sfl_structures_and_functions_of_organism7d3a.html',
                    identifier: 'fa8fc65d-b96b-3dc5-a972-50d301cc0ffa',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: CCV_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a30a3bdc-d1cf-3286-8eab-6543c1d3a46e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a43ef8f1-8377-38e1-8d32-52a2113d419b_sfl_structures_and_functions_of_organism7d3a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd20d2cfb-36c8-3a6a-8adf-83c3060c1581',
                    file: 'd20d2cfb-36c8-3a6a-8adf-83c3060c1581_f32cc9f4-4bcf-3faa-9cb4-1633ed47a55a.html',
                    identifier: 'e7929c04-c217-3718-965d-f3ae0633e177',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Elaborate_CCV_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a30a3bdc-d1cf-3286-8eab-6543c1d3a46e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d20d2cfb-36c8-3a6a-8adf-83c3060c1581_f32cc9f4-4bcf-3faa-9cb4-1633ed47a55a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ca0e4320-75f2-3e7b-8be6-109bd0263beb',
                    file: 'ca0e4320-75f2-3e7b-8be6-109bd0263beb_20_5_elaborate_ccv_structuresandfunction09d1.html',
                    identifier: 'c0779d8d-8c6e-33d9-bc0a-0e04379c989e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Elaborate_CCV_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'a30a3bdc-d1cf-3286-8eab-6543c1d3a46e',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ca0e4320-75f2-3e7b-8be6-109bd0263beb_20_5_elaborate_ccv_structuresandfunction09d1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Content Connections Video',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e4fb6a0a-be28-33ed-8e31-732eb03aacb3',
                file: '',
                identifier: 'ntkw66bf0f5cc95af731e8ab906d8891',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Science Connection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'd1a4ce03-5252-316d-a18f-919c130dafec',
                    file: 'd1a4ce03-5252-316d-a18f-919c130dafec_d1783ea4-83fe-3693-b377-f210d860bd4e.html',
                    identifier: 'ddaaeb17-07c4-3b2b-aa04-8f933c824c4f',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science Connection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e4fb6a0a-be28-33ed-8e31-732eb03aacb3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d1a4ce03-5252-316d-a18f-919c130dafec_d1783ea4-83fe-3693-b377-f210d860bd4e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e9295e17-cc0b-381c-b21f-044f8896fef1',
                    file: 'e9295e17-cc0b-381c-b21f-044f8896fef1_sfl_structures_and_functions_of_organism9873.html',
                    identifier: 'f77efbcd-dac1-337c-aa60-c756c88a521c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science Connection_Student Digitalization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e4fb6a0a-be28-33ed-8e31-732eb03aacb3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e9295e17-cc0b-381c-b21f-044f8896fef1_sfl_structures_and_functions_of_organism9873.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ebe9cc20-42d5-3ac8-ab84-9e58d9b3d143',
                    file: 'ebe9cc20-42d5-3ac8-ab84-9e58d9b3d143_f7f3f0cd-ab57-36c5-8c25-b0ba2c44f3f9.html',
                    identifier: 'b7cd3415-4986-3f7d-8a97-600bf040c09b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Elaborate_SC_AnimalStructuresandFunctions_InformativeSpeech_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e4fb6a0a-be28-33ed-8e31-732eb03aacb3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ebe9cc20-42d5-3ac8-ab84-9e58d9b3d143_f7f3f0cd-ab57-36c5-8c25-b0ba2c44f3f9.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Connection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'f8bdfe5f-0bfe-3bae-9a9b-66548ac9118c',
                file: '',
                identifier: 'xsbg9c5b3dc65b9cc0d5381199cc8802',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Engineering Connection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'eb004087-a948-32e5-8f0b-2d0302e851bb',
                    file: 'eb004087-a948-32e5-8f0b-2d0302e851bb_c9cb54bd-afa1-3bac-8c40-964b98dc2703.html',
                    identifier: 'c88adeaf-a55a-3c59-abaf-ac3d16e52c69',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Engineering Connection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f8bdfe5f-0bfe-3bae-9a9b-66548ac9118c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/eb004087-a948-32e5-8f0b-2d0302e851bb_c9cb54bd-afa1-3bac-8c40-964b98dc2703.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a791ffd2-6be2-35c3-b58d-e3bfaea8d0d3',
                    file: 'a791ffd2-6be2-35c3-b58d-e3bfaea8d0d3_sfl_structures_and_functions_of_organisma102.html',
                    identifier: 'baa02b94-7ee3-35f0-b4d9-c7e053a4861c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Engineering Connection_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f8bdfe5f-0bfe-3bae-9a9b-66548ac9118c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a791ffd2-6be2-35c3-b58d-e3bfaea8d0d3_sfl_structures_and_functions_of_organisma102.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c54c1be6-344c-3b2e-91de-4429406bf390',
                    file: 'c54c1be6-344c-3b2e-91de-4429406bf390_a7a8deb1-bdda-3216-ac14-9bb6889ec87a.html',
                    identifier: 'e3c0ebf2-1d27-3b02-9b4b-e370ea6dc43a',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_EC_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f8bdfe5f-0bfe-3bae-9a9b-66548ac9118c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c54c1be6-344c-3b2e-91de-4429406bf390_a7a8deb1-bdda-3216-ac14-9bb6889ec87a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ff890f74-411d-32f4-8b8c-e8c9bbb2c21f',
                    file: 'ff890f74-411d-32f4-8b8c-e8c9bbb2c21f_f1b3b771-4138-3b49-b3a7-0df62a247c8b.html',
                    identifier: 'fbc4e397-7363-32f4-8f00-b0bcdbedf498',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'K_HS: Elaborate_EDP_Generic',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f8bdfe5f-0bfe-3bae-9a9b-66548ac9118c',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ff890f74-411d-32f4-8b8c-e8c9bbb2c21f_f1b3b771-4138-3b49-b3a7-0df62a247c8b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Engineering Design Process',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Connection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                file: '',
                identifier: 'yaxp88c391fe1901210d8b613c89325d',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Science Today',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a92dd5b0-7bf9-39f6-9c55-14ca1b8dd0bc',
                    file: 'a92dd5b0-7bf9-39f6-9c55-14ca1b8dd0bc_ee7f448a-1321-3219-8313-9d8493b6434c.html',
                    identifier: 'aab0719e-5c0c-3f84-a5c5-7de96907863e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science Today_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a92dd5b0-7bf9-39f6-9c55-14ca1b8dd0bc_ee7f448a-1321-3219-8313-9d8493b6434c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c5b817dd-3574-3966-bf57-df90fa687f12',
                    file: 'c5b817dd-3574-3966-bf57-df90fa687f12_sfl_structures_and_functions_of_organism4628.html',
                    identifier: 'c05c6d44-b5a6-3c99-80d6-11cd63c484ac',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science Today_OL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c5b817dd-3574-3966-bf57-df90fa687f12_sfl_structures_and_functions_of_organism4628.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value:
                            "It's wrong to blame bats for the coronavirus epidemic - OL",
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ef100045-0341-3190-8cd6-ba1e2a1fecb0',
                    file: 'ef100045-0341-3190-8cd6-ba1e2a1fecb0_a79ea542-920e-3752-b00b-ebfc4a700560.html',
                    identifier: 'd968d5c9-69d4-3a98-98a1-5bf809d24ecd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science Today_BL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ef100045-0341-3190-8cd6-ba1e2a1fecb0_a79ea542-920e-3752-b00b-ebfc4a700560.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value:
                            "It's wrong to blame bats for the coronavirus epidemic - BL",
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a3806239-5462-365b-9bd3-0824ea8d0c9c',
                    file: 'a3806239-5462-365b-9bd3-0824ea8d0c9c_fcf527a4-6f9f-3e14-a060-188477577d86.html',
                    identifier: 'd0a0bf20-dcfe-38e0-9fc2-964335890c78',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_OL_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a3806239-5462-365b-9bd3-0824ea8d0c9c_fcf527a4-6f9f-3e14-a060-188477577d86.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout- OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ee9dc63e-47e2-3304-87c6-9de7d4bf83c4',
                    file: 'ee9dc63e-47e2-3304-87c6-9de7d4bf83c4_ccafdd4d-67e9-3f5a-8380-064c04dfc991.html',
                    identifier: 'd3419101-30b7-3fb1-a298-9e55cbba77f4',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_OL_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ee9dc63e-47e2-3304-87c6-9de7d4bf83c4_ccafdd4d-67e9-3f5a-8380-064c04dfc991.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key- OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd8356650-9341-3a2a-a0e3-3705f115deb8',
                    file: 'd8356650-9341-3a2a-a0e3-3705f115deb8_c02b2fe1-5a86-3efc-824c-9988d00f6886.html',
                    identifier: 'a4b67130-a4b3-36e3-b87d-25c9bef137ad',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_BL_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d8356650-9341-3a2a-a0e3-3705f115deb8_c02b2fe1-5a86-3efc-824c-9988d00f6886.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout- BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd9a3289a-a32a-3257-a427-b41654aaa8ff',
                    file: 'd9a3289a-a32a-3257-a427-b41654aaa8ff_dbc7f54b-2254-367d-a3a5-45786f2e0f30.html',
                    identifier: 'ab1799f7-26da-3a3b-9fee-4c06e0a33155',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_ST_StructuresandFunctionsofOrganisms_BL_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd45464d9-5c88-3006-a9c1-991da51f94ca',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d9a3289a-a32a-3257-a427-b41654aaa8ff_dbc7f54b-2254-367d-a3a5-45786f2e0f30.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key- BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Today',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e14f0f56-32dd-3bf0-b011-402a1dcc13ff',
                file: '',
                identifier: 'b1492a25-1416-39c7-9b71-33cc9b007e7b',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Writing Science',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a7cf058c-5c8d-3bb4-a752-0cb5adac3959',
                    file: 'a7cf058c-5c8d-3bb4-a752-0cb5adac3959_b1063e74-5ce9-3c6e-8464-8a2c8e4bf401.html',
                    identifier: 'c0e5bf82-0841-375f-93a8-1c3c4c516ee0',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organims: Writing Science_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: 'none',
                    paper_size: 'letter',
                    children: [],
                    parent_id: 'e14f0f56-32dd-3bf0-b011-402a1dcc13ff',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a7cf058c-5c8d-3bb4-a752-0cb5adac3959_b1063e74-5ce9-3c6e-8464-8a2c8e4bf401.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fca8bf55-0722-3c2e-bdef-55f854f99cb1',
                    file: 'fca8bf55-0722-3c2e-bdef-55f854f99cb1_dd2210ea-2a80-3d9c-ba6a-968fe72e6fb0.html',
                    identifier: 'a1851121-d347-3391-adef-ad75fea934a8',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organims: Writing Science_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: 'none',
                    paper_size: 'letter',
                    children: [],
                    parent_id: 'e14f0f56-32dd-3bf0-b011-402a1dcc13ff',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fca8bf55-0722-3c2e-bdef-55f854f99cb1_dd2210ea-2a80-3d9c-ba6a-968fe72e6fb0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b4db3a9c-e928-3808-a9b6-2e8e8734e6c8',
                    file: 'b4db3a9c-e928-3808-a9b6-2e8e8734e6c8_a5a86876-a06d-33de-9f81-4ff09998cbe8.html',
                    identifier: 'a809c4e4-1ed0-3fe8-b3b7-2001018dec49',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Elaborate_WS_Animal Structures and Functions_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e14f0f56-32dd-3bf0-b011-402a1dcc13ff',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b4db3a9c-e928-3808-a9b6-2e8e8734e6c8_a5a86876-a06d-33de-9f81-4ff09998cbe8.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a619a624-95fa-327f-8dda-ddb916f1e1ae',
                    file: 'a619a624-95fa-327f-8dda-ddb916f1e1ae_ddfc630f-ee0a-3cb6-8112-30bf13b1a2c6.html',
                    identifier: 'd565bd90-9988-30e9-b10d-a3f5332134bf',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_3_Elaborate_WS_Animal Structures and Functions_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e14f0f56-32dd-3bf0-b011-402a1dcc13ff',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a619a624-95fa-327f-8dda-ddb916f1e1ae_ddfc630f-ee0a-3cb6-8112-30bf13b1a2c6.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Writing Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI243',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                file: '',
                identifier: 'uwew70c4013294b88b6c90ee1ca2a5ff',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Reading Science',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a2fd7345-10bc-33a3-aeed-b92786a8e499',
                    file: 'a2fd7345-10bc-33a3-aeed-b92786a8e499_ad895174-e429-396e-ad60-05d1def3e26c.html',
                    identifier: 'c49e0588-f357-31f8-8950-bce8c6accb67',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Reading Science_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a2fd7345-10bc-33a3-aeed-b92786a8e499_ad895174-e429-396e-ad60-05d1def3e26c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'effd1262-7554-385c-8c90-6155563ba1f9',
                    file: 'effd1262-7554-385c-8c90-6155563ba1f9_sfl_structures_and_functions_of_organismd90c.html',
                    identifier: 'c03641ab-dc1c-31d9-b827-4638318c4cd8',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Reading Science_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/effd1262-7554-385c-8c90-6155563ba1f9_sfl_structures_and_functions_of_organismd90c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Life in the Desert - OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'efb9fc55-b1c4-3a2a-9f03-23899e27eac0',
                    file: 'efb9fc55-b1c4-3a2a-9f03-23899e27eac0_c28edced-3423-38bb-bdd3-add1ec8aa509.html',
                    identifier: 'd6abc302-b53e-3731-b582-7586d3869170',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Reading Science_BL_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/efb9fc55-b1c4-3a2a-9f03-23899e27eac0_c28edced-3423-38bb-bdd3-add1ec8aa509.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Activity Title',
                          value: 'Life in the Desert - BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e46e1b7b-8d15-34fb-b91a-4dc419eb0896',
                    file: 'e46e1b7b-8d15-34fb-b91a-4dc419eb0896_a409b722-925c-3b3b-b65d-701ae31d2d8a.html',
                    identifier: 'b430f42e-b72f-37ef-beb8-2f8fd51b60a1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_OnLevel_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e46e1b7b-8d15-34fb-b91a-4dc419eb0896_a409b722-925c-3b3b-b65d-701ae31d2d8a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout - OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b6470b21-42e9-3229-8cc4-dcc5bfe78777',
                    file: 'b6470b21-42e9-3229-8cc4-dcc5bfe78777_ae20e9df-715d-3405-bbe5-805c793e6cdb.html',
                    identifier: 'a00163f0-ffa0-3304-9d40-a23c9084f51b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_OnLevel_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b6470b21-42e9-3229-8cc4-dcc5bfe78777_ae20e9df-715d-3405-bbe5-805c793e6cdb.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key - OL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a7e028cb-6bb0-313d-884c-70f1a867af82',
                    file: 'a7e028cb-6bb0-313d-884c-70f1a867af82_d0e12272-d534-31ab-b6e8-c383060f951b.html',
                    identifier: 'fa1dbb75-38d6-3fac-8c6f-b037903e4997',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_BelowLevel_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a7e028cb-6bb0-313d-884c-70f1a867af82_d0e12272-d534-31ab-b6e8-c383060f951b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout - BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ab3b168a-653f-3163-9f3f-7d29b0efff56',
                    file: 'ab3b168a-653f-3163-9f3f-7d29b0efff56_bd9176c3-010d-39c8-967d-39bfa95b8a64.html',
                    identifier: 'e3b9e96d-aa9a-3689-bdb1-c379120a99ff',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_ELABORATE_RS_StructuresandFunctionsofOrganisms_BelowLevel_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ab3b168a-653f-3163-9f3f-7d29b0efff56_bd9176c3-010d-39c8-967d-39bfa95b8a64.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key - BL',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c6d11c94-d780-3c57-9aac-31818a7e7d97',
                    file: 'c6d11c94-d780-3c57-9aac-31818a7e7d97_bbab8228-fb4f-3c5d-9847-9e7b3b9eea10.html',
                    identifier: 'd8ad9ba1-47f7-3b4f-8abe-8e65a965bffc',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'MSHS_IdeaBookMarks_Generic',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'caac614b-d22b-3517-ad39-17e9f7546ce8',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c6d11c94-d780-3c57-9aac-31818a7e7d97_bbab8228-fb4f-3c5d-9847-9e7b3b9eea10.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Idea Bookmarks',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Reading Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'f2edb9ce-cd2e-3bd3-90c7-3732650bc480',
                file: '',
                identifier: 'fe877a55-3852-345c-83b5-37973a2930c4',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Math Connection',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'dde64af5-304b-3161-8b3c-6f8a50054869',
                    file: 'dde64af5-304b-3161-8b3c-6f8a50054869_af3171c1-0526-3c7d-b92a-200f4395325b.html',
                    identifier: 'a0c9d271-8995-3259-a3a0-fee145e10dee',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Math Connection_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2edb9ce-cd2e-3bd3-90c7-3732650bc480',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dde64af5-304b-3161-8b3c-6f8a50054869_af3171c1-0526-3c7d-b92a-200f4395325b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fcc855da-117c-37ea-b6a9-9637f4611d41',
                    file: 'fcc855da-117c-37ea-b6a9-9637f4611d41_sfl_structures_and_functions_of_organism996c.html',
                    identifier: 'bb8b58e1-0ea9-36b5-951b-33369aa488ad',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Math Connection_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2edb9ce-cd2e-3bd3-90c7-3732650bc480',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fcc855da-117c-37ea-b6a9-9637f4611d41_sfl_structures_and_functions_of_organism996c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd31264e6-fad4-3821-a1b0-895c1eb13b2e',
                    file: 'd31264e6-fad4-3821-a1b0-895c1eb13b2e_c841f65c-9b07-3924-bdda-a98db0d78021.html',
                    identifier: 'd427f820-263e-3fa9-bf70-d956ec5f43fb',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_MC_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2edb9ce-cd2e-3bd3-90c7-3732650bc480',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d31264e6-fad4-3821-a1b0-895c1eb13b2e_c841f65c-9b07-3924-bdda-a98db0d78021.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ed33e35f-4e76-3f4b-a6f8-ead4aa4d0968',
                    file: 'ed33e35f-4e76-3f4b-a6f8-ead4aa4d0968_c27e6569-e585-3af8-9895-70d741479e80.html',
                    identifier: 'aefb957b-f254-3265-bd66-4b9cac0767c3',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Elaborate_MC_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f2edb9ce-cd2e-3bd3-90c7-3732650bc480',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ed33e35f-4e76-3f4b-a6f8-ead4aa4d0968_c27e6569-e585-3af8-9895-70d741479e80.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Math Connection',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'f6003477-fac1-3f2c-ba07-f71180d94daf',
                file: '',
                identifier: 'ca41b18c-47a0-30c4-9d90-1189c70bed8a',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Virtual Experience',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'cc82c8d2-c754-340a-9adc-9ab8421d8237',
                    file: 'cc82c8d2-c754-340a-9adc-9ab8421d8237_f45f38af-d6ae-31a9-a70d-cfa6d98c2ea0.html',
                    identifier: 'ba1b6710-6561-3b36-81fc-0bca98e0759c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Virtual Experience_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f6003477-fac1-3f2c-ba07-f71180d94daf',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/cc82c8d2-c754-340a-9adc-9ab8421d8237_f45f38af-d6ae-31a9-a70d-cfa6d98c2ea0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e80eeccd-628a-36e0-b65a-35c04550cebe',
                    file: 'e80eeccd-628a-36e0-b65a-35c04550cebe_ded4f04d-abd6-3cd9-ac33-279b15cdbf99.html',
                    identifier: 'b91e9306-b740-30c2-9dd0-1256e16398bd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Virtual Experience_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f6003477-fac1-3f2c-ba07-f71180d94daf',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e80eeccd-628a-36e0-b65a-35c04550cebe_ded4f04d-abd6-3cd9-ac33-279b15cdbf99.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'af1b3365-3e0b-3b9f-a894-9e6bff35d5cf',
                    file: 'af1b3365-3e0b-3b9f-a894-9e6bff35d5cf_b5bac97c-bc74-3bac-91b1-610ebb612fde.html',
                    identifier: 'efdd456d-9dd9-3b12-b089-589958b715ef',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_ELABORATE_VX_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f6003477-fac1-3f2c-ba07-f71180d94daf',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/af1b3365-3e0b-3b9f-a894-9e6bff35d5cf_b5bac97c-bc74-3bac-91b1-610ebb612fde.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c9d4ffac-f958-3f75-a9c1-1b6689f190c6',
                    file: 'c9d4ffac-f958-3f75-a9c1-1b6689f190c6_f2ac032e-3c53-3579-8d86-1ab6b858ae7d.html',
                    identifier: 'daa0206b-71f1-3ae0-a990-b0b6eb9c6db9',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_ELABORATE_VX_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f6003477-fac1-3f2c-ba07-f71180d94daf',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c9d4ffac-f958-3f75-a9c1-1b6689f190c6_f2ac032e-3c53-3579-8d86-1ab6b858ae7d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'fde7ee0c-6639-34d4-a007-bc12401c0076',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Virtual Experience',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Elaborate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Elaborate',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'c9343e39-7e9b-32f9-b20d-094d2ae9880f',
            file: '',
            identifier: 'dec77306-a99b-3fb7-b2bb-ca04b5014a90',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Evaluate',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'f835dfec-298e-32e4-af38-702f35ac0c05',
                file: '',
                identifier: 'ee38c28d-3906-33c8-8d87-5c71e2663a25',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Claim-Evidence-Reasoning',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'b8e6979f-a7a1-3253-9c22-f512188cbc88',
                    file: 'b8e6979f-a7a1-3253-9c22-f512188cbc88_eee98b97-bfca-3568-8012-b8aa07bfdcc9.html',
                    identifier: 'b17ce1fc-6f3f-350e-b966-d07c1c1e41a8',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Claim-Evidence-Reasoning_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f835dfec-298e-32e4-af38-702f35ac0c05',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b8e6979f-a7a1-3253-9c22-f512188cbc88_eee98b97-bfca-3568-8012-b8aa07bfdcc9.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fe12ca4e-427c-36b9-8299-2c9ba32937fc',
                    file: 'fe12ca4e-427c-36b9-8299-2c9ba32937fc_structures_and_functions_of_organisms_ede82.html',
                    identifier: 'd6d7b339-5c33-3a20-bb2a-13dacc7acecd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Claim-Evidence-Reasoning_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f835dfec-298e-32e4-af38-702f35ac0c05',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fe12ca4e-427c-36b9-8299-2c9ba32937fc_structures_and_functions_of_organisms_ede82.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f5b6da74-340e-3a6b-ac18-6b5801fc7b8f',
                    file: 'f5b6da74-340e-3a6b-ac18-6b5801fc7b8f_d59208a0-3af4-323f-b099-0681ad4a676f.html',
                    identifier: 'e8e392a7-353c-32ab-a000-f18429a087c6',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_EVALUATE_CER_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f835dfec-298e-32e4-af38-702f35ac0c05',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f5b6da74-340e-3a6b-ac18-6b5801fc7b8f_d59208a0-3af4-323f-b099-0681ad4a676f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fb8e73e6-1c66-3da8-b830-0bb3bb09dd92',
                    file: 'fb8e73e6-1c66-3da8-b830-0bb3bb09dd92_b3878675-8f8a-360e-8af1-ced7301a4206.html',
                    identifier: 'e8072396-73d8-3d6a-ae54-f2002588b075',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_EVALUATE_CER_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'f835dfec-298e-32e4-af38-702f35ac0c05',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fb8e73e6-1c66-3da8-b830-0bb3bb09dd92_b3878675-8f8a-360e-8af1-ced7301a4206.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'c9343e39-7e9b-32f9-b20d-094d2ae9880f',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Claim-Evidence-Reasoning',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Evaluate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'bae511f6-274c-3a04-a134-30fb7c9456c3',
                file: '',
                identifier: 'ab7f98c7-55b7-3f49-b1ae-42d71c01f40a',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Scope Assessment',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'd9b7a7f9-c771-3004-bba8-8400ce36fdcb',
                    file: 'd9b7a7f9-c771-3004-bba8-8400ce36fdcb_c9878b20-1490-3608-88df-743d02e9cf55.html',
                    identifier: 'd27a39d4-3f69-3cd3-8860-11243df4803b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Scope Assessment_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bae511f6-274c-3a04-a134-30fb7c9456c3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d9b7a7f9-c771-3004-bba8-8400ce36fdcb_c9878b20-1490-3608-88df-743d02e9cf55.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f2021b46-dff8-30b2-b18f-df8f0dd2950a',
                    file: 'f2021b46-dff8-30b2-b18f-df8f0dd2950a_e6295857-f506-3a1f-b562-1eb5924e8214.html',
                    identifier: 'b3402a96-071b-3322-85a5-8303f90f78d6',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Scope Assessment_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bae511f6-274c-3a04-a134-30fb7c9456c3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f2021b46-dff8-30b2-b18f-df8f0dd2950a_e6295857-f506-3a1f-b562-1eb5924e8214.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b975550c-94f7-3f7a-8f4a-10a761fdfe7f',
                    file: 'b975550c-94f7-3f7a-8f4a-10a761fdfe7f_c8446a37-c6d3-3680-a729-88cb8532471e.html',
                    identifier: 'd58c86bd-c92a-3c17-a1d0-f7b4c45f6f94',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Evaluation_ScopeAssessment_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bae511f6-274c-3a04-a134-30fb7c9456c3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b975550c-94f7-3f7a-8f4a-10a761fdfe7f_c8446a37-c6d3-3680-a729-88cb8532471e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c301b4c2-9295-3ea3-b423-eb16e5970bee',
                    file: 'c301b4c2-9295-3ea3-b423-eb16e5970bee_f7f14475-421e-33d1-97c8-54b2b2efa778.html',
                    identifier: 'affa7dc5-d47a-3646-97b6-f98b144d6b99',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_StructuresandFunctionsofOrganisms_Evaluation_ScopeAssessment_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'bae511f6-274c-3a04-a134-30fb7c9456c3',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c301b4c2-9295-3ea3-b423-eb16e5970bee_f7f14475-421e-33d1-97c8-54b2b2efa778.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'c9343e39-7e9b-32f9-b20d-094d2ae9880f',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '2',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Scope Assessment',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Evaluate Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'd7cc828c-a1be-31e7-a1bc-7900a3ea3615',
                file: '',
                identifier: 'dzqocbeb22b87860c84cb3ce0f5cf5ff',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Claim-Evidence-Reasoning',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'df51ec7e-427a-3b0c-9d83-d01aeaaf2563',
                    file: 'df51ec7e-427a-3b0c-9d83-d01aeaaf2563_sfl_structure_and_functions_of_organismsbbc3.html',
                    identifier: 'fc2f8364-081a-3086-aec4-7270f28d25cc',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structure and Functions of Organisms: Claim-Evidence-Reasoning_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd7cc828c-a1be-31e7-a1bc-7900a3ea3615',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/df51ec7e-427a-3b0c-9d83-d01aeaaf2563_sfl_structure_and_functions_of_organismsbbc3.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bd42ce87-9bd9-3251-92d1-48c3cd8788cd',
                    file: 'bd42ce87-9bd9-3251-92d1-48c3cd8788cd_sfl_structures_and_functions_of_organism32d9.html',
                    identifier: 'ff6ce8db-d01b-3dad-8c7b-4d716e584a82',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: CER_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd7cc828c-a1be-31e7-a1bc-7900a3ea3615',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bd42ce87-9bd9-3251-92d1-48c3cd8788cd_sfl_structures_and_functions_of_organism32d9.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'aaccf3e3-00ec-3492-8779-c720fd47f04b',
                    file: 'aaccf3e3-00ec-3492-8779-c720fd47f04b_b68b43c3-2cef-39f6-b042-bb9cd294c31f.html',
                    identifier: 'd976e76c-bcda-3547-ad20-fc9ce392f0cd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_EVALUATE_CER_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd7cc828c-a1be-31e7-a1bc-7900a3ea3615',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/aaccf3e3-00ec-3492-8779-c720fd47f04b_b68b43c3-2cef-39f6-b042-bb9cd294c31f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ad22851e-3538-396a-b3df-3923b49e077b',
                    file: 'ad22851e-3538-396a-b3df-3923b49e077b_b20b0098-8ac8-3cf1-9363-0acc6b0a81b0.html',
                    identifier: 'd4a11666-8b69-3c2f-a689-1e5bf1a19702',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_EVALUATE_CER_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'd7cc828c-a1be-31e7-a1bc-7900a3ea3615',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ad22851e-3538-396a-b3df-3923b49e077b_b20b0098-8ac8-3cf1-9363-0acc6b0a81b0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'c9343e39-7e9b-32f9-b20d-094d2ae9880f',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Evaluate Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Claim-Evidence-Reasoning',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '20 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'da6784e7-ba5b-3dcb-aaa6-be4e52114190',
                file: '',
                identifier: 'tmqj51ec3632defbf6a56c632d1cd047',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Scope Assessment',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'fdf776ed-202b-3288-9956-eb994724912a',
                    file: 'fdf776ed-202b-3288-9956-eb994724912a_sfl_structures_and_functions_of_organismfebc.html',
                    identifier: 'b6538659-cd30-3b7d-bffc-e2a4702a3ecf',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Scope Assessment_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'da6784e7-ba5b-3dcb-aaa6-be4e52114190',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fdf776ed-202b-3288-9956-eb994724912a_sfl_structures_and_functions_of_organismfebc.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a54a8316-fd3b-3de4-a3ed-cfd2826dbcee',
                    file: 'a54a8316-fd3b-3de4-a3ed-cfd2826dbcee_sfl_structures_and_functions_of_organism52b0.html',
                    identifier: 'f29b3f76-c600-3905-917a-fa7b4a435f6d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Scope Assessment_ Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'da6784e7-ba5b-3dcb-aaa6-be4e52114190',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a54a8316-fd3b-3de4-a3ed-cfd2826dbcee_sfl_structures_and_functions_of_organism52b0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bc54a99e-6667-3af7-9853-146375a3e0b2',
                    file: 'bc54a99e-6667-3af7-9853-146375a3e0b2_b206e87f-6acb-380c-9159-3ea0605082e4.html',
                    identifier: 'fa2d63ce-1598-353a-9358-98a351b34575',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_StructuresandFunctionsofOrganisms_Evaluate_ScopeAssessment_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'da6784e7-ba5b-3dcb-aaa6-be4e52114190',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bc54a99e-6667-3af7-9853-146375a3e0b2_b206e87f-6acb-380c-9159-3ea0605082e4.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f2fbd7f8-08ea-3e9c-a0d8-c01328ad2217',
                    file: 'f2fbd7f8-08ea-3e9c-a0d8-c01328ad2217_a71140e9-e78f-3d52-8dc2-e30b318e69b5.html',
                    identifier: 'edee8eea-9cfe-3cb0-8fc6-23ccddc71e79',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_StructuresandFunctionsofOrganisms_Evaluate_ScopeAssessment_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'da6784e7-ba5b-3dcb-aaa6-be4e52114190',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f2fbd7f8-08ea-3e9c-a0d8-c01328ad2217_a71140e9-e78f-3d52-8dc2-e30b318e69b5.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'c9343e39-7e9b-32f9-b20d-094d2ae9880f',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Evaluate Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Scope Assessment',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Activity Time',
                      value: '25 minutes',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Evaluate',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
            file: '',
            identifier: 'd407a076-ab81-3b6c-9adf-1629a9f99bc6',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Intervention',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'b358c89c-e590-38ae-9272-f3d6e42dcb1d',
                file: '',
                identifier: 'd3b24264-de9e-38fe-b8e8-b6ff25a4cfb4',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Small-Group Intervention',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'da968618-0cd1-3e24-a637-7d8c5066aaa2',
                    file: 'da968618-0cd1-3e24-a637-7d8c5066aaa2_f6a409bf-8f18-35f3-a7fd-fba9f55bd279.html',
                    identifier: 'dc4e6e73-c99c-3d1c-84d8-5c1399c6a635',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Small-Group Intervention_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b358c89c-e590-38ae-9272-f3d6e42dcb1d',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/da968618-0cd1-3e24-a637-7d8c5066aaa2_f6a409bf-8f18-35f3-a7fd-fba9f55bd279.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a7a15ec6-9e23-3239-9b22-a30331376657',
                    file: 'a7a15ec6-9e23-3239-9b22-a30331376657_10_5_intervention_sgi_structuresandfunct8300.html',
                    identifier: 'f1c93f21-479a-3dfd-a3b3-313b64c83660',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_SGI_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b358c89c-e590-38ae-9272-f3d6e42dcb1d',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a7a15ec6-9e23-3239-9b22-a30331376657_10_5_intervention_sgi_structuresandfunct8300.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'cb33bd38-118b-3e90-b234-33749ed3840e',
                    file: 'cb33bd38-118b-3e90-b234-33749ed3840e_10_5_intervention_sgi_structuresandfunctda30.html',
                    identifier: 'b48848a3-4bd8-3cfd-a2d1-207d4387aae6',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_SGI_StructuresandFunctionsofOrganisms_WhenISay,YouSay!Chants',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b358c89c-e590-38ae-9272-f3d6e42dcb1d',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/cb33bd38-118b-3e90-b234-33749ed3840e_10_5_intervention_sgi_structuresandfunctda30.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'When I Say, You Say! Chants',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Small-Group Intervention',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e56f241c-10fe-34b3-aa69-0ac87c2b5db6',
                file: '',
                identifier: 'af735396-df69-3090-8110-29d0439736ec',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Concept Attainment Quiz',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'c807a147-3db1-3dd3-a7d3-f8789acdbb9d',
                    file: 'c807a147-3db1-3dd3-a7d3-f8789acdbb9d_f8ebf378-05ab-3483-98df-46f5fcd2ef79.html',
                    identifier: 'a5a9f478-cd65-3f01-a826-16bb44e3fbf6',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Concept Attainment Quiz_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e56f241c-10fe-34b3-aa69-0ac87c2b5db6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c807a147-3db1-3dd3-a7d3-f8789acdbb9d_f8ebf378-05ab-3483-98df-46f5fcd2ef79.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bdf290e9-6229-3d4f-b4cc-d441f4f1f61a',
                    file: 'bdf290e9-6229-3d4f-b4cc-d441f4f1f61a_structures_and_functions_of_organisms_c97f4.html',
                    identifier: 'eedfc586-bd90-3492-aa0d-51af7f09aa87',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'STX_Structures and Functions of Organisms: Concept Attainment Quiz_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e56f241c-10fe-34b3-aa69-0ac87c2b5db6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bdf290e9-6229-3d4f-b4cc-d441f4f1f61a_structures_and_functions_of_organisms_c97f4.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a2099a6b-28bf-3bfe-85d6-c99582182e52',
                    file: 'a2099a6b-28bf-3bfe-85d6-c99582182e52_fbf4604f-95c2-3427-82a5-34aafd5da09e.html',
                    identifier: 'e1307b17-5a73-3fd7-9760-d124193a286d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_CAQ_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e56f241c-10fe-34b3-aa69-0ac87c2b5db6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a2099a6b-28bf-3bfe-85d6-c99582182e52_fbf4604f-95c2-3427-82a5-34aafd5da09e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e15d13e9-072d-36ed-ab1a-08afbaa64dc0',
                    file: 'e15d13e9-072d-36ed-ab1a-08afbaa64dc0_c65139ee-658a-377e-84f8-1cbbcba8f12b.html',
                    identifier: 'd1d2e5a0-d9e9-3890-8258-0a74b5b002c8',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_CAQ_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e56f241c-10fe-34b3-aa69-0ac87c2b5db6',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e15d13e9-072d-36ed-ab1a-08afbaa64dc0_c65139ee-658a-377e-84f8-1cbbcba8f12b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Concept Attainment Quiz',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c180e9c7-3648-31de-9756-9dfe732ffaaa',
                file: '',
                identifier: 'b13e70fb-a644-3f02-a637-f16c60e5aa83',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Science Rock',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ac1a1b37-2973-3bc0-8fee-a10cf0c73fde',
                    file: 'ac1a1b37-2973-3bc0-8fee-a10cf0c73fde_structures_and_functions_of_organisms_sbcce.html',
                    identifier: 'd85aa4d8-e999-35c2-8622-0869bc95fc57',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Science Rock_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c180e9c7-3648-31de-9756-9dfe732ffaaa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ac1a1b37-2973-3bc0-8fee-a10cf0c73fde_structures_and_functions_of_organisms_sbcce.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e9f54a7a-b78a-3efa-bd99-07db7a49acb3',
                    file: 'e9f54a7a-b78a-3efa-bd99-07db7a49acb3_structures_and_functions_of_organisms_s2fbb.html',
                    identifier: 'f1e82794-981d-35eb-bd16-c5f950bdde85',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: Science Rock_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c180e9c7-3648-31de-9756-9dfe732ffaaa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e9f54a7a-b78a-3efa-bd99-07db7a49acb3_structures_and_functions_of_organisms_s2fbb.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Hide',
                          value: 'Yes',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ef2e7782-fc77-36f7-a27d-d8f30901bfb7',
                    file: 'ef2e7782-fc77-36f7-a27d-d8f30901bfb7_db3b783f-45a7-3144-b071-a2973c4fb6b0.html',
                    identifier: 'beed9e01-46b7-3cd8-a9a9-a9d0a95eea67',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_SR_StructuresandFunctionsofOrganisms_LyricSheet',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c180e9c7-3648-31de-9756-9dfe732ffaaa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ef2e7782-fc77-36f7-a27d-d8f30901bfb7_db3b783f-45a7-3144-b071-a2973c4fb6b0.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Lyric Sheet',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Rock',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c34637ac-87b6-37a9-ba42-4f4f6364b4b7',
                file: '',
                identifier: 'c4552230-a266-317e-92dd-c6465ca8f529',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: Kahoot! Virtual Intervention',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'd0d6821d-3006-30f7-8574-c6643d1a4edd',
                    file: 'd0d6821d-3006-30f7-8574-c6643d1a4edd_decbecc5-7f38-3224-8a8d-bd1c31fab162.html',
                    identifier: 'd252301a-c3db-3517-83b4-da50eb01275e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'K12_KahootIntervention_Generic_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c34637ac-87b6-37a9-ba42-4f4f6364b4b7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d0d6821d-3006-30f7-8574-c6643d1a4edd_decbecc5-7f38-3224-8a8d-bd1c31fab162.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e8cf53a6-a589-359a-aad1-b73cde2bd971',
                    file: 'e8cf53a6-a589-359a-aad1-b73cde2bd971_db4495ee-eb27-3cb2-ba3a-2609eba06cb1.html',
                    identifier: 'e06721fd-7ae9-3761-ae96-020e09d6228d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_KahootIntervention_StructuresandFunctionsofOrganisms_Student',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c34637ac-87b6-37a9-ba42-4f4f6364b4b7',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e8cf53a6-a589-359a-aad1-b73cde2bd971_db4495ee-eb27-3cb2-ba3a-2609eba06cb1.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Kahoot! Virtual Intervention',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'fd6a371c-0153-3130-bea0-7bcfd7e78b4f',
                file: '',
                identifier: 'dfra57d7549c3afdd7e4c42b721f1787',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Small Group Intervention',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'dadecc5b-35a9-3e93-8b63-03f42a197398',
                    file: 'dadecc5b-35a9-3e93-8b63-03f42a197398_sfl_structures_and_functions_of_organism1390.html',
                    identifier: 'd789616c-ff72-31b6-a83e-3423f825e79c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Small-Group Intervention_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'fd6a371c-0153-3130-bea0-7bcfd7e78b4f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dadecc5b-35a9-3e93-8b63-03f42a197398_sfl_structures_and_functions_of_organism1390.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'aa2f489a-1932-3bd1-8eb0-12cf5a4be177',
                    file: 'aa2f489a-1932-3bd1-8eb0-12cf5a4be177_20_5_intervention_sgi_structuresandfuncte43b.html',
                    identifier: 'f4865ee2-5a7c-3753-8665-8e9914d05fc5',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Intervention_SGI_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'fd6a371c-0153-3130-bea0-7bcfd7e78b4f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/aa2f489a-1932-3bd1-8eb0-12cf5a4be177_20_5_intervention_sgi_structuresandfuncte43b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f2b83b2b-7b4a-373a-88a2-3235768af4b3',
                    file: 'f2b83b2b-7b4a-373a-88a2-3235768af4b3_20_5_intervention_sgi_structuresandfuncta424.html',
                    identifier: 'c03f19ab-f2c9-3f5b-a460-be9290377a91',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_Intervention_SGI_StructuresandFunctionsofOrganisms_HeadbandsTermCards',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'fd6a371c-0153-3130-bea0-7bcfd7e78b4f',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f2b83b2b-7b4a-373a-88a2-3235768af4b3_20_5_intervention_sgi_structuresandfuncta424.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Headbands Term Cards',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Small-Group Intervention',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c9a27483-5325-3d52-b29d-d12d9820cd49',
                file: '',
                identifier: 'rxwm351968fee2f4d06a04d11b28d84a',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: CONCEPT ATTAINMENT QUIZ (CAQ)',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'b4d0b425-043e-3119-a4a0-a639ef77c8bf',
                    file: 'b4d0b425-043e-3119-a4a0-a639ef77c8bf_f5afca35-3dca-34b5-95e9-85db23240ef3.html',
                    identifier: 'f671d9d5-a94d-3d28-a684-230773391169',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Concept Attainment Quiz_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c9a27483-5325-3d52-b29d-d12d9820cd49',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b4d0b425-043e-3119-a4a0-a639ef77c8bf_f5afca35-3dca-34b5-95e9-85db23240ef3.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f920588f-49c5-30bf-828d-5e10b0dd5caa',
                    file: 'f920588f-49c5-30bf-828d-5e10b0dd5caa_sfl_structures_and_functions_of_organism5a59.html',
                    identifier: 'e30d25c5-63c6-33b5-947f-1bd7d8bc5873',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: CAQ_Student',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c9a27483-5325-3d52-b29d-d12d9820cd49',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f920588f-49c5-30bf-828d-5e10b0dd5caa_sfl_structures_and_functions_of_organism5a59.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c7577182-3589-31ae-abca-a9e3af74b62d',
                    file: 'c7577182-3589-31ae-abca-a9e3af74b62d_f272d185-a5ba-38ae-a6ce-39d04a3c6864.html',
                    identifier: 'd7a59730-bdb1-3f07-902a-e6614c05505e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_CAQ_StructuresandFunctionsofOrganisms_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c9a27483-5325-3d52-b29d-d12d9820cd49',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c7577182-3589-31ae-abca-a9e3af74b62d_f272d185-a5ba-38ae-a6ce-39d04a3c6864.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Student Handout',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f9dcba31-7aee-32e0-8352-0ee54d703d5a',
                    file: 'f9dcba31-7aee-32e0-8352-0ee54d703d5a_f9a91272-a2e6-3399-896c-c40da8c629dd.html',
                    identifier: 'fc20de3b-4296-318b-8331-7dbc72ded3e9',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_CAQ_StructuresandFunctionsofOrganisms_AK',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c9a27483-5325-3d52-b29d-d12d9820cd49',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f9dcba31-7aee-32e0-8352-0ee54d703d5a_f9a91272-a2e6-3399-896c-c40da8c629dd.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Answer Key',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Concept Attainment Quiz',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'c5ad59dd-43b7-3815-b789-6b52dbe37987',
                file: '',
                identifier: 'rsds5026b1c8d25e67d8dd31f8c0ecfd',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Science Rock',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'ba58a20c-bc89-375e-992c-01808ab95dcb',
                    file: 'ba58a20c-bc89-375e-992c-01808ab95dcb_ff2ad01b-6701-33e3-ac7d-c29fcca86bf5.html',
                    identifier: 'fa1ec3e2-8c95-3d2b-a74b-52fc5c549dd5',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: Science Rock_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c5ad59dd-43b7-3815-b789-6b52dbe37987',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ba58a20c-bc89-375e-992c-01808ab95dcb_ff2ad01b-6701-33e3-ac7d-c29fcca86bf5.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f5f62484-ea70-35a6-a850-0bc99422a898',
                    file: 'f5f62484-ea70-35a6-a850-0bc99422a898_a9b8a882-8bae-3ef5-bdeb-2702041dd6fb.html',
                    identifier: 'f9326ab4-1c7e-3c5d-9833-c6687411d9d7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Intervention_SR_StructuresandFunctionsofOrganisms_LyricSheet',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'c5ad59dd-43b7-3815-b789-6b52dbe37987',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f5f62484-ea70-35a6-a850-0bc99422a898_a9b8a882-8bae-3ef5-bdeb-2702041dd6fb.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Lyric Sheet',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Science Rock',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'e0b5867e-37dc-3058-8fef-b00a92d9969d',
                file: '',
                identifier: 'qcxl5bea562584187ea4c3aab1b20433',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: Kahoot! Virtual Intervention',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'a4f5518b-0231-3727-9edb-05ae235213b2',
                    file: 'a4f5518b-0231-3727-9edb-05ae235213b2_aaca603b-9988-3903-9cb6-74a83bd410da.html',
                    identifier: 'cfa09164-5b09-3db8-a036-dda6ff2982a1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: 'K12_KahootIntervention_Generic_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e0b5867e-37dc-3058-8fef-b00a92d9969d',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a4f5518b-0231-3727-9edb-05ae235213b2_aaca603b-9988-3903-9cb6-74a83bd410da.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f0094032-12d2-350e-b78d-cbda691a0adc',
                    file: 'f0094032-12d2-350e-b78d-cbda691a0adc_20_5_kahootintervention_structuresandfun4c15.html',
                    identifier: 'c063cc61-3866-3c52-bf7d-575325a94072',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '20_5_KahootIntervention_StructuresandFunctionsofOrganisms_Student',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'e0b5867e-37dc-3058-8fef-b00a92d9969d',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f0094032-12d2-350e-b78d-cbda691a0adc_20_5_kahootintervention_structuresandfun4c15.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'aebd7315-154f-3f1b-b7d7-39bca8b8afcf',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                    {
                      key_name: 'Grade Level',
                      value: '5',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Intervention Icon',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'Kahoot! Virtual Intervention',
                    },
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Intervention',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
          {
            id: 'd94ab04c-52ac-3a16-ae93-60b2ef13323e',
            file: '',
            identifier: 'd1be4f96-0a8b-39ab-a8cd-d0bfb4edbb24',
            root_type: 2,
            type: 5,
            section_page: 'section',
            assessment_ids: [],
            title: 'Acceleration',
            content_type_identifier: 'fld',
            children: [
              {
                id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                file: '',
                identifier: 'abe7aaab-d498-3892-add2-5363b82bdd8e',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'STX_Structures and Functions of Organisms: STEAM Choice Board',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'e03587d1-177a-35b2-9b6c-cdd8aeae1b00',
                    file: 'e03587d1-177a-35b2-9b6c-cdd8aeae1b00_e9091062-b41f-390e-8069-e4424850a75e.html',
                    identifier: 'f7604635-bed3-3570-aebf-afef8f2a8e43',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: STEAM Choice Board_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e03587d1-177a-35b2-9b6c-cdd8aeae1b00_e9091062-b41f-390e-8069-e4424850a75e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dbca32c3-c0fc-3247-a6fa-a3d0271a54f5',
                    file: 'dbca32c3-c0fc-3247-a6fa-a3d0271a54f5_structures_and_functions_of_organisms_sa55e.html',
                    identifier: 'fd46b982-0b77-3ab7-a546-9abfa916b6c7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'Structures and Functions of Organisms: STEAM Choice Board_Student Digitization',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dbca32c3-c0fc-3247-a6fa-a3d0271a54f5_structures_and_functions_of_organisms_sa55e.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Hide',
                          value: 'Yes',
                        },
                        {
                          key_name: 'Audience',
                          value: 'Student',
                        },
                        {
                          key_name: 'Digital Aspects',
                          value: 'Autogradeable',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b0c5daa1-30b4-36ae-a43a-2a7e114ce6e0',
                    file: 'b0c5daa1-30b4-36ae-a43a-2a7e114ce6e0_f7144e54-b5be-37a8-83c4-c66e0a073d3d.html',
                    identifier: 'bee1e18a-a14d-3888-a645-2c1bd37320a7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Acceleration_SCB_Structures and Functions of Organisms_STEAMChoiceBoard_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b0c5daa1-30b4-36ae-a43a-2a7e114ce6e0_f7144e54-b5be-37a8-83c4-c66e0a073d3d.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'STEAM Choice Board',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f44a57e3-f20c-3498-9cdf-5bae032008b8',
                    file: 'f44a57e3-f20c-3498-9cdf-5bae032008b8_a5fd1728-25f4-3af5-b8c2-1860f93edd7f.html',
                    identifier: 'fdbcd464-9d08-3d6f-8eb1-fda6e78e3b9c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_DigitalPresentation_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f44a57e3-f20c-3498-9cdf-5bae032008b8_a5fd1728-25f4-3af5-b8c2-1860f93edd7f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Digital Presentation',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fa0b0bd2-926b-353b-a019-9a8227b33c76',
                    file: 'fa0b0bd2-926b-353b-a019-9a8227b33c76_f6a097bd-cdd0-304b-8e72-232a3c034ecb.html',
                    identifier: 'be5e961c-af5e-399b-9871-e7ce44e85fe7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_MovieAdvertisement_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fa0b0bd2-926b-353b-a019-9a8227b33c76_f6a097bd-cdd0-304b-8e72-232a3c034ecb.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Movie Advertisement',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a40e15ba-4f4f-32ff-ac1e-8c2838c649c8',
                    file: 'a40e15ba-4f4f-32ff-ac1e-8c2838c649c8_ec68f52b-0340-3981-9ef3-3fecb66f89df.html',
                    identifier: 'e2de284d-c6ef-38fb-abe3-6ac8a2804096',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_MovieConnection_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a40e15ba-4f4f-32ff-ac1e-8c2838c649c8_ec68f52b-0340-3981-9ef3-3fecb66f89df.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Movie Connection',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'e69dbd97-0709-3e9a-9464-99d6329bd9d0',
                    file: 'e69dbd97-0709-3e9a-9464-99d6329bd9d0_c1fc425a-8448-36b2-b52c-9445ab14fe84.html',
                    identifier: 'f36c9238-6826-3d49-b395-4f61fafa9429',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_GraphicOrganizer_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/e69dbd97-0709-3e9a-9464-99d6329bd9d0_c1fc425a-8448-36b2-b52c-9445ab14fe84.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: null,
                        },
                        {
                          key_name: 'File Title',
                          value: 'Graphic Organizer',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'ab835faf-48da-36f1-950a-a9bad2aa93de',
                    file: 'ab835faf-48da-36f1-950a-a9bad2aa93de_facdebcd-cf30-332b-bbb7-c0983ee5ffd3.html',
                    identifier: 'f92e1689-9ff8-3176-8d6b-f572778f947c',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Acceleration_SCB_Structures and Functions of Organisms_ScientistSpotlight_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/ab835faf-48da-36f1-950a-a9bad2aa93de_facdebcd-cf30-332b-bbb7-c0983ee5ffd3.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: null,
                        },
                        {
                          key_name: 'File Title',
                          value: 'Scientist Spotlight',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd98fe4d4-ba46-3e9f-844b-c679532cb1b2',
                    file: 'd98fe4d4-ba46-3e9f-844b-c679532cb1b2_a522bcce-aacf-3259-bc63-0a5607a61978.html',
                    identifier: 'f21ca2d1-150c-3a4b-9393-2bc6e648ec87',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_MakeaModel_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d98fe4d4-ba46-3e9f-844b-c679532cb1b2_a522bcce-aacf-3259-bc63-0a5607a61978.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Make a Model',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'fa30856e-8ca4-302b-a240-f6e578e3d6c1',
                    file: 'fa30856e-8ca4-302b-a240-f6e578e3d6c1_bfae6d1b-d80f-3ea5-ad4a-4d6554cb10b5.html',
                    identifier: 'a40e3b64-a0d0-3262-b4df-d20477be8a16',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_TeachIt_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/fa30856e-8ca4-302b-a240-f6e578e3d6c1_bfae6d1b-d80f-3ea5-ad4a-4d6554cb10b5.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Teach It',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bca6b0ec-2a14-3ba7-82cb-c58005eae842',
                    file: 'bca6b0ec-2a14-3ba7-82cb-c58005eae842_f6201be9-f5b5-3f54-aa1e-8f747f8ff4da.html',
                    identifier: 'a17b50d2-afd4-373d-93c2-15d9b33f9247',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_PuppetShow_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bca6b0ec-2a14-3ba7-82cb-c58005eae842_f6201be9-f5b5-3f54-aa1e-8f747f8ff4da.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Puppet Show',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'dd27cf9b-f625-3a2c-9c57-381b0f36d066',
                    file: 'dd27cf9b-f625-3a2c-9c57-381b0f36d066_cbf059c9-1b51-3281-9898-2b9eb9881820.html',
                    identifier: 'c52197cb-07e1-3cdc-8ee6-0e132a6a046d',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_WordCloud_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'efcde429-a61f-3726-b5d3-8f545d162faa',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/dd27cf9b-f625-3a2c-9c57-381b0f36d066_cbf059c9-1b51-3281-9898-2b9eb9881820.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Word Cloud',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'd94ab04c-52ac-3a16-ae93-60b2ef13323e',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '1',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'STEAM Choice Board',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Acceleration Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'TXSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
              {
                id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                file: '',
                identifier: 'lwxib652f65091b18bb3f53d011cbb40',
                root_type: 2,
                type: 5,
                section_page: 'section',
                assessment_ids: [],
                title:
                  'SFL_Structures and Functions of Organisms: STEAM Choice Boards',
                content_type_identifier: 'fld',
                children: [
                  {
                    id: 'df1a95df-48c9-3c34-8d0f-d641b9589689',
                    file: 'df1a95df-48c9-3c34-8d0f-d641b9589689_e19aa088-40b1-3382-aa23-22458c9f509a.html',
                    identifier: 'c8740c51-a6df-32b5-9e09-f47c59139d70',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      'SFL_Structures and Functions of Organisms: STEAM Choice Board_Teacher',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/df1a95df-48c9-3c34-8d0f-d641b9589689_e19aa088-40b1-3382-aa23-22458c9f509a.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'Audience',
                          value: 'Teacher',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f94444be-66fd-3305-8917-4be3ec86bef3',
                    file: 'f94444be-66fd-3305-8917-4be3ec86bef3_a55a9444-b4c3-326f-af68-f4839cc5d2e5.html',
                    identifier: 'd2988c6c-eb34-32df-94f0-95708acd004e',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Acceleration_SCB_Structures and Functions of Organisms_STEAMChoiceBoard_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f94444be-66fd-3305-8917-4be3ec86bef3_a55a9444-b4c3-326f-af68-f4839cc5d2e5.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'STEAM Choice Board',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'd7160025-ddb1-3735-860b-f881bc3a82be',
                    file: 'd7160025-ddb1-3735-860b-f881bc3a82be_e30fe710-e079-3c77-ab96-3925b022459c.html',
                    identifier: 'b605c92b-dda1-39e2-8266-03983718c4c1',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_DigitalPresentation_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/d7160025-ddb1-3735-860b-f881bc3a82be_e30fe710-e079-3c77-ab96-3925b022459c.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Digital Presentation',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'b4bf51fb-d74c-3f3c-8c4a-8ab917e2eaed',
                    file: 'b4bf51fb-d74c-3f3c-8c4a-8ab917e2eaed_d5d7c3de-049a-3180-bcab-e847c79fe487.html',
                    identifier: 'b249a145-45af-3e4f-9587-d5986f51a7c5',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_MovieAdvertisement_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/b4bf51fb-d74c-3f3c-8c4a-8ab917e2eaed_d5d7c3de-049a-3180-bcab-e847c79fe487.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Movie Advertisement',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a6b50117-4536-3ca3-a2fc-71f73ae20281',
                    file: 'a6b50117-4536-3ca3-a2fc-71f73ae20281_a452cce8-ee53-3079-b753-5dcd024ccf5b.html',
                    identifier: 'cff851fe-86ed-37a5-ae23-4f56991ca3b2',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_MovieConnection_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a6b50117-4536-3ca3-a2fc-71f73ae20281_a452cce8-ee53-3079-b753-5dcd024ccf5b.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Movie Connection',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a8ebe4c7-08e6-3444-ad7a-f31d95d1fab2',
                    file: 'a8ebe4c7-08e6-3444-ad7a-f31d95d1fab2_e142df54-1cad-38a4-b385-9c4906010c03.html',
                    identifier: 'bca58d51-3fe7-37c8-8b94-132c72c8cb9b',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_GraphicOrganizer_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a8ebe4c7-08e6-3444-ad7a-f31d95d1fab2_e142df54-1cad-38a4-b385-9c4906010c03.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Graphic Organizer',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f4a83ea7-c22f-3b2c-99d8-55759f30a0a9',
                    file: 'f4a83ea7-c22f-3b2c-99d8-55759f30a0a9_f4eac3de-31e4-3d2d-86ac-ceadda36f142.html',
                    identifier: 'de3de164-eeca-3faf-8368-220b546848db',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title:
                      '10_5_Acceleration_SCB_Structures and Functions of Organisms_ScientistSpotlight_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f4a83ea7-c22f-3b2c-99d8-55759f30a0a9_f4eac3de-31e4-3d2d-86ac-ceadda36f142.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Scientist Spotlight',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'bcd553e9-259c-3a2e-ba8c-48c98354ebce',
                    file: 'bcd553e9-259c-3a2e-ba8c-48c98354ebce_b6453e60-795e-32cb-905d-df2918232e20.html',
                    identifier: 'ebae0e91-43b4-327c-ad68-dc5aa0e279c7',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_MakeaModel_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/bcd553e9-259c-3a2e-ba8c-48c98354ebce_b6453e60-795e-32cb-905d-df2918232e20.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Make a Model',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'f29acab6-e765-3c29-8e45-513275ad3e76',
                    file: 'f29acab6-e765-3c29-8e45-513275ad3e76_d9356aac-2d87-3490-a01f-f5c8291c55d7.html',
                    identifier: 'ab10ca21-4f99-3646-9ef5-52f49b4535cd',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_TeachIt_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/f29acab6-e765-3c29-8e45-513275ad3e76_d9356aac-2d87-3490-a01f-f5c8291c55d7.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Teach It',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'c8419532-767b-3e9b-9fc8-2d6dbd8dddf4',
                    file: 'c8419532-767b-3e9b-9fc8-2d6dbd8dddf4_e329eb8c-bfb4-34c1-92c3-3ac0d456182f.html',
                    identifier: 'b7a0ac65-f1cc-3cd2-8e93-276a5b9c3423',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_PuppetShow_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/c8419532-767b-3e9b-9fc8-2d6dbd8dddf4_e329eb8c-bfb4-34c1-92c3-3ac0d456182f.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Puppet Show',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                  {
                    id: 'a4123410-f65b-3a9d-a9a2-5db037705e29',
                    file: 'a4123410-f65b-3a9d-a9a2-5db037705e29_b7857eb6-e525-3629-accb-afa2c3b08f80.html',
                    identifier: 'a550630e-d5b6-3c8f-92f4-edc973833ac4',
                    root_type: 2,
                    type: 6,
                    section_page: 'page',
                    assessment_ids: [],
                    title: '10_35_Acceleration_SCB_WordCloud_SH',
                    ext_res_type: '',
                    assignment_id: '',
                    assignment_source: '',
                    assessment_source: '',
                    assessment_id: '',
                    asset_id: '',
                    template_id: '',
                    parent_lo_id: '',
                    forum_id: '',
                    content_type_identifier: 'contentscreen',
                    page_orientation: '',
                    paper_size: '',
                    children: [],
                    parent_id: 'b0a5a09c-e6e2-304c-96e9-f694f349424b',
                    lti_version: '',
                    source_action: 'generateToTraverseDFS',
                    is_edited: 0,
                    s3_path:
                      'https://unifiedfrost-cdn-dev.learningmate.co/81cfde8c-ac58-4c74-b4df-1e8ae0d32481_frost/5137/html/a4123410-f65b-3a9d-a9a2-5db037705e29_b7857eb6-e525-3629-accb-afa2c3b08f80.html',
                    meta_data: {
                      kvp: [
                        {
                          key_name: 'File Type',
                          value: 'epub',
                        },
                        {
                          key_name: 'File Title',
                          value: 'Word Cloud',
                        },
                      ],
                    },
                    course_metadata: {
                      kvp: [
                        {
                          key_name: 'Project Type',
                          value: '5E Content Scope',
                        },
                      ],
                      taxonomy: [],
                      tag: [],
                    },
                  },
                ],
                parent_id: 'd94ab04c-52ac-3a16-ae93-60b2ef13323e',
                lti_version: '',
                source_action: 'generateToTraverseDFS',
                is_edited: 0,
                s3_path: 'test',
                meta_data: {
                  kvp: [
                    {
                      key_name: 'Priority',
                      value: '3',
                    },
                    {
                      key_name: 'Curriculum Area',
                      value: 'Science',
                    },
                    {
                      key_name: 'Element Name',
                      value: 'STEAM Choice Board',
                    },
                    {
                      key_name: 'Element Icon Type',
                      value: 'Acceleration Icon',
                    },
                    {
                      key_name: 'Content Group',
                      value: 'FLSCI245',
                    },
                  ],
                },
                course_metadata: {
                  kvp: [
                    {
                      key_name: 'Project Type',
                      value: '5E Content Scope',
                    },
                  ],
                  taxonomy: [],
                  tag: [],
                },
              },
            ],
            parent_id: 'b6f2b21c-bc6c-3cc1-8b50-0a3d11db02b6',
            lti_version: '',
            source_action: 'generateToTraverseDFS',
            is_edited: 0,
            s3_path: 'test',
            meta_data: {
              kvp: [
                {
                  key_name: 'Section Type',
                  value: 'Top Bar',
                },
                {
                  key_name: 'Section Title',
                  value: 'Acceleration',
                },
              ],
            },
            course_metadata: {
              kvp: [
                {
                  key_name: 'Project Type',
                  value: '5E Content Scope',
                },
              ],
              taxonomy: [],
              tag: [],
            },
          },
        ],
        parent_id: '',
        lti_version: '',
        source_action: 'generateToTraverseDFS',
        s3_path: 'test',
        meta_data: {
          taxonomy: [
            {
              HumanCodingScheme: '112.7.b.2.B',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.2 Scientific and engineering practices. The student analyzes and interprets data to derive meaning, identify features and patterns, and discover relationships or correlations to develop evidence-based arguments or evaluate designs. The student is expected to:',
              title:
                'analyze data by identifying any significant features, patterns, or sources of error;',
              uuid: '261bf7d9-f62c-4807-98fe-d5f0207f9adb',
            },
            {
              HumanCodingScheme: '74.4.c.5.F',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.5 ELPS Writing Strategies Cross-curricular second language acquisition/writing. The ELL writes in a variety of forms with increasing accuracy to effectively address a specific purpose and audience in all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in writing. In order for the ELL to meet grade-level learning expectations across foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency. For Kindergarten and Grade 1, certain of these student expectations do not apply until the student has reached the stage of generating original written text using a standard writing system.",
              title:
                'write using a variety of grade-appropriate sentence lengths, patterns, and connecting words to combine phrases, clauses, and sentences in increasingly accurate ways as more English is acquired',
              uuid: 'f3b22640-efc1-4ef6-91cd-6bd8be92caf8',
            },
            {
              HumanCodingScheme: '74.4.c.4.E',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.4 ELPS Reading Strategies Cross-curricular second language acquisition/reading. The ELL reads a variety of texts for a variety of purposes with an increasing level of comprehension in all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in reading. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency. For Kindergarten and Grade 1, certain of these student expectations apply to text read aloud for students not yet at the stage of decoding written text.",
              title:
                'read linguistically accommodated content area material with a decreasing need for linguistic accommodations as more English is learned',
              uuid: 'ef00aab8-aeda-4464-8ff1-2a9574d79511',
            },
            {
              HumanCodingScheme: '74.4.c.3.GÂ ',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.3Â ELPS Speaking Strategies Â Cross-curricular second language acquisition/speaking. The ELL speaks in a variety of modes for a variety of purposes with an awareness of different language registers (formal/informal) using vocabulary with increasing fluency and accuracy in language arts and all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in speaking. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title:
                'express opinions, ideas, and feelings ranging from communicating single words and short phrases to participating in extended discussions on a variety of social and grade-appropriate academic topics',
              uuid: 'e927dc51-5200-400a-b4b7-4f129c83fe34',
            },
            {
              HumanCodingScheme: '74.4.c.4.H',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.4 ELPS Reading Strategies Cross-curricular second language acquisition/reading. The ELL reads a variety of texts for a variety of purposes with an increasing level of comprehension in all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in reading. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency. For Kindergarten and Grade 1, certain of these student expectations apply to text read aloud for students not yet at the stage of decoding written text.",
              title:
                'read silently with increasing ease and comprehension for longer periods',
              uuid: 'e273b607-7ae3-4d6d-a723-94ae248e9409',
            },
            {
              HumanCodingScheme: '74.4.c.4.GÂ ',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.4 ELPS Reading Strategies Cross-curricular second language acquisition/reading. The ELL reads a variety of texts for a variety of purposes with an increasing level of comprehension in all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in reading. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency. For Kindergarten and Grade 1, certain of these student expectations apply to text read aloud for students not yet at the stage of decoding written text.",
              title:
                'demonstrate comprehension of increasingly complex English by participating in shared reading, retelling or summarizing material, responding to questions, and taking notes commensurate with content area and grade level needs',
              uuid: 'd6bd2c58-d5b7-477e-a9ae-3134678fa239',
            },
            {
              HumanCodingScheme: '74.4.c.1.AÂ ',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.1 ELPS Learning StrategiesÂ  Cross-curricular second language acquisition/learning strategies. The ELL uses language learning strategies to develop an awareness of his or her own learning processes in all content areas. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title:
                'use prior knowledge and experiences to understand meanings in English',
              uuid: 'd53e327f-ac07-479a-bdfb-7bc41fd1fe5f',
            },
            {
              HumanCodingScheme: '74.4.c.2.D',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.2 ELPS Listening Strategies Cross-curricular second language acquisition/listening. The ELL listens to a variety of speakers including teachers, peers, and electronic media to gain an increasing level of comprehension of newly acquired language in all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in listening. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title:
                'monitor understanding of spoken language during classroom instruction and interactions and seek clarification as needed',
              uuid: 'cafce6f2-ae79-4b85-84f3-7b64fb538de6',
            },
            {
              HumanCodingScheme: '74.4.c.3.EÂ ',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.3Â ELPS Speaking Strategies Â Cross-curricular second language acquisition/speaking. The ELL speaks in a variety of modes for a variety of purposes with an awareness of different language registers (formal/informal) using vocabulary with increasing fluency and accuracy in language arts and all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in speaking. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title: 'share information in cooperative learning interactions',
              uuid: 'c6131637-1241-408e-8ccb-8c98ad5ccfd8',
            },
            {
              HumanCodingScheme: '74.4.c.1.GÂ ',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.1 ELPS Learning StrategiesÂ  Cross-curricular second language acquisition/learning strategies. The ELL uses language learning strategies to develop an awareness of his or her own learning processes in all content areas. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title:
                'demonstrate an increasing ability to distinguish between formal and informal English and an increasing knowledge of when to use each one commensurate with grade-level learning expectations',
              uuid: 'c5c4989b-00d0-4ebe-ae7c-e877f726b3e0',
            },
            {
              HumanCodingScheme: '74.4.c.2.F',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.2 ELPS Listening Strategies Cross-curricular second language acquisition/listening. The ELL listens to a variety of speakers including teachers, peers, and electronic media to gain an increasing level of comprehension of newly acquired language in all content areas. ELLs may be at the beginning, intermediate, advanced, or advanced high stage of English language acquisition in listening. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title:
                'listen to and derive meaning from a variety of media such as audio tape, video, DVD, and CD ROM to build and reinforce concept and language attainment',
              uuid: '90a06bfe-289c-4e96-9f1f-e4b55fe24a5a',
            },
            {
              HumanCodingScheme: '74.4.c.1.CÂ ',
              breadcrumb:
                " Chapter 74.4 English Language Proficiency Standards > 74.4.c.1 ELPS Learning StrategiesÂ  Cross-curricular second language acquisition/learning strategies. The ELL uses language learning strategies to develop an awareness of his or her own learning processes in all content areas. In order for the ELL to meet grade-level learning expectations across the foundation and enrichment curriculum, all instruction delivered in English must be linguistically accommodated (communicated, sequenced, and scaffolded) commensurate with the student's level of English language proficiency.",
              title:
                'use strategic learning techniques such as concept mapping, drawing, memorizing, comparing, contrasting, and reviewing to acquire basic and grade-level vocabulary',
              uuid: '801f53c6-2d07-422c-bb4e-67811e02fbc0',
            },
            {
              HumanCodingScheme: '112.7.b.3.B',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.3 Scientific and engineering practices. The student develops evidence-based explanations and communicates findings, conclusions, and proposed solutions. The student is expected to:',
              title:
                'communicate explanations and solutions individually and collaboratively in a variety of settings and formats; and',
              uuid: 'a08ac917-ff25-4660-969e-0dff10b31cb4',
            },
            {
              HumanCodingScheme: '112.7.b.1.A',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.1 Scientific and engineering practices. The student asks questions, identifies problems, and plans and safely conducts classroom, laboratory, and field investigations to answer questions, explain phenomena, or design solutions using appropriate tools and models. The student is expected to:',
              title:
                'ask questions and define problems based on observations or information from text, phenomena, models, or investigations;',
              uuid: '305c1997-9eb0-4f7b-9620-aaac808269d6',
            },
            {
              HumanCodingScheme: '112.7.b.4.A',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.4 Scientific and engineering practices. The student knows the contributions of scientists and recognizes the importance of scientific research and innovation for society. The student is expected to:',
              title:
                'explain how scientific discoveries and innovative solutions to problems impact science and society; and',
              uuid: 'd1137a7d-fe7b-419e-b08f-6e83ddc36bc2',
            },
            {
              HumanCodingScheme: '112.7.b.1.E',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.1 Scientific and engineering practices. The student asks questions, identifies problems, and plans and safely conducts classroom, laboratory, and field investigations to answer questions, explain phenomena, or design solutions using appropriate tools and models. The student is expected to:',
              title: 'collect observations and measurements as evidence;',
              uuid: 'a6841615-fd97-48be-a7fd-11e0a261dc47',
            },
            {
              HumanCodingScheme: '112.7.b.5.A',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.5 Recurring themes and concepts. The student understands that recurring themes and concepts provide a framework for making connections across disciplines. The student is expected to:',
              title:
                'identify and use patterns to explain scientific phenomena or to design solutions;',
              uuid: '74520b6a-1d7b-47e9-a923-d5b6bcc2d23d',
            },
            {
              HumanCodingScheme: '112.7.b.5.F',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.5 Recurring themes and concepts. The student understands that recurring themes and concepts provide a framework for making connections across disciplines. The student is expected to:',
              title:
                'explain the relationship between the structure and function of objects, organisms, and systems; and',
              uuid: '8f946e07-f551-417f-bbb9-d801673a517d',
            },
            {
              HumanCodingScheme: '112.7.b.1.G',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.1 Scientific and engineering practices. The student asks questions, identifies problems, and plans and safely conducts classroom, laboratory, and field investigations to answer questions, explain phenomena, or design solutions using appropriate tools and models. The student is expected to:',
              title:
                'develop and use models to represent phenomena, objects, and processes or design a prototype for a solution to a problem.',
              uuid: '35abe102-2c2a-4fec-92b2-aabf832da593',
            },
            {
              HumanCodingScheme: '112.7.b.3.A',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.3 Scientific and engineering practices. The student develops evidence-based explanations and communicates findings, conclusions, and proposed solutions. The student is expected to:',
              title:
                'develop explanations and propose solutions supported by data and models;',
              uuid: '26adfeaf-8afd-41e8-aed4-7bf5e6f10f7e',
            },
            {
              HumanCodingScheme: '112.7.b.13.A',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.13 Organisms and environments. The student knows that organisms undergo similar life processes and have structures and behaviors that help them survive within their environments. The student is expected to:',
              title:
                'analyze the structures and functions of different species to identify how organisms survive in the same environment; and',
              uuid: 'daab684d-1012-4b69-86b3-d99868130f76',
            },
            {
              HumanCodingScheme: '112.7.b.2.A',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.2 Scientific and engineering practices. The student analyzes and interprets data to derive meaning, identify features and patterns, and discover relationships or correlations to develop evidence-based arguments or evaluate designs. The student is expected to:',
              title:
                'identify advantages and limitations of models such as their size, scale, properties, and materials;',
              uuid: '80a0b0e0-3095-4846-aa0a-4bf315868c13',
            },
            {
              HumanCodingScheme: '112.7.b.1.B',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.1 Scientific and engineering practices. The student asks questions, identifies problems, and plans and safely conducts classroom, laboratory, and field investigations to answer questions, explain phenomena, or design solutions using appropriate tools and models. The student is expected to:',
              title:
                'use scientific practices to plan and conduct descriptive and simple experimental investigations and use engineering practices to design solutions to problems;',
              uuid: '3eaa390a-4b27-475d-8cb8-76f8e3bab4d7',
            },
            {
              HumanCodingScheme: '112.7.b.1.D',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.1 Scientific and engineering practices. The student asks questions, identifies problems, and plans and safely conducts classroom, laboratory, and field investigations to answer questions, explain phenomena, or design solutions using appropriate tools and models. The student is expected to:',
              title:
                'use tools, including calculators, microscopes, hand lenses, metric rulers, Celsius thermometers, prisms, concave and convex lenses, laser pointers, mirrors, digital scales, balances, spring scales, graduated cylinders, beakers, hot plates, meter sticks, magnets, collecting nets, notebooks, timing devices, materials for building circuits, materials to support observations of habitats or organisms such as terrariums and aquariums, and materials to support digital data collection such as computers, tablets, and cameras to observe, measure, test, and analyze information;',
              uuid: '6dbf7d58-c594-4d8d-ad61-f7f384f50a80',
            },
            {
              HumanCodingScheme: '112.7.b.3.C',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.3 Scientific and engineering practices. The student develops evidence-based explanations and communicates findings, conclusions, and proposed solutions. The student is expected to:',
              title:
                "listen actively to others' explanations to identify relevant evidence and engage respectfully in scientific discussion.",
              uuid: '88daaebd-25f7-42db-ad6d-1f93f3c29a01',
            },
            {
              HumanCodingScheme: '112.7.b.1.F',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.1 Scientific and engineering practices. The student asks questions, identifies problems, and plans and safely conducts classroom, laboratory, and field investigations to answer questions, explain phenomena, or design solutions using appropriate tools and models. The student is expected to:',
              title:
                'construct appropriate graphic organizers used to collect data, including tables, bar graphs, line graphs, tree maps, concept maps, Venn diagrams, flow charts or sequence maps, and input-output tables that show cause and effect; and',
              uuid: '7c3cdf87-dee8-4c7a-a5cf-881b80f2327c',
            },
            {
              HumanCodingScheme: '112.7.b.5.D',
              breadcrumb:
                ' Chapter 112. Science > 112 Subchapter A. Elementary > 112.7. Science, Grade 5, Adopted 2021. > 112.7.b Knowledge and skills. > 112.7.b.5 Recurring themes and concepts. The student understands that recurring themes and concepts provide a framework for making connections across disciplines. The student is expected to:',
              title:
                'examine and model the parts of a system and their interdependence in the function of the system;',
              uuid: '7494c80c-2e54-40e6-a91f-ac9013921ab8',
            },
          ],
        },
        course_metadata: {
          kvp: [
            {
              key_name: 'Project Type',
              value: '5E Content Scope',
            },
          ],
          taxonomy: [],
          tag: [],
        },
      },
    ],
    course_metadata: {
      kvp: [
        {
          key_name: 'Project Type',
          value: '5E Content Scope',
        },
      ],
      taxonomy: [],
      tag: [],
    },
  },
  message: 'Retrieve Data successfully.',
};
