import { useContext } from 'react';
import { WorkingLocation } from '@lon/shared/contexts';
import { ApplicationEnum } from '@lon/shared/enums';

export const useGetIsLeaderSuitFlag = () => {
  const { application } = useContext(WorkingLocation);

  //TODO: need to update the strings to enums after other suits are supported in role switcher
  const leaderSuits = [
    ApplicationEnum.CAMPUS_LEADER_SUIT,
    ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
    ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
  ];

  return application ? Boolean(leaderSuits.includes(application)) : false;
};

export default useGetIsLeaderSuitFlag;
