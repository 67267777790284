import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { isTexasReviewEnv } from '@lon/shared/constants';

if (process.env.NX_TRACKING_ID && isTexasReviewEnv()) {
  ReactGA.initialize(process.env.NX_TRACKING_ID);
}

const useGoogleAnalytics = (
  app: 'suit' | 'admin' | 'user-management' | 'login'
): void => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    if (isTexasReviewEnv()) {
      ReactGA.set({
        app,
        page: pathname,
      });
      ReactGA.pageview(pathname + search);
    }
  }, [pathname, search]);
};

export default useGoogleAnalytics;
