import { Box, BoxProps } from '@chakra-ui/react';
import { Children, FC, cloneElement } from 'react';

const ButtonGroup: FC<BoxProps & { children: JSX.Element[] }> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      p={0.5}
      backgroundColor="white"
      borderRadius="base"
      display="flex"
      gap={0.5}
      {...rest}
    >
      {Children.map(children, (child, idx) =>
        cloneElement(
          child,
          idx === 0
            ? { borderRadius: '2px 0 0 2px' }
            : idx === children?.length - 1
            ? { borderRadius: '0 2px 2px 0' }
            : {}
        )
      )}
    </Box>
  );
};

export default ButtonGroup;
