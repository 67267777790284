import {
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { Icon } from '../../../icon'
import { AiOutlineDelete } from '@react-icons/all-files/ai/AiOutlineDelete';
import { AiOutlineRedo } from '@react-icons/all-files/ai/AiOutlineRedo';
import { BlockerFunction } from '@remix-run/router';
import { upperFirst } from 'lodash-es';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  matchPath,
  useBeforeUnload,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { FormPromptProps, usePrompt } from './duck';

const FormPrompt: FC<FormPromptProps> = ({
  mode,
  formRef,
  entity,
  ignoreRedirectPaths = [],
  basePath,
  hasPromptSubmit = true,
  isDirty: hasUnsavedChanges,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const { isSubmitting, isSubmitted, isSubmitSuccessful } = formState;
  const navigate = useNavigate();
  const nextPathname = useRef<string>('');
  const shouldConfirmation = useRef<boolean>(true);
  const shouldRedirect = useRef<boolean>(false);
  const wasShowed = useRef<boolean>(false);
  const [showPrompt, setShowPrompt] = useBoolean(false);
  const afterNavigateFn = useRef<(() => void) | null>(null);
  const isCreate = mode === 'create';
  const isSamePathname = location.pathname === nextPathname.current;

  const blockOnChangeLocation: BlockerFunction = useCallback(
    ({ nextLocation }) => {
      const isIgnoredPath = ignoreRedirectPaths.some((path) =>
        matchPath(
          { path: path },
          nextLocation.pathname.replace(basePath, '') || '/'
        )
      );

      // prevent redirect to ignored paths if prompt was showed
      if (isIgnoredPath && wasShowed.current) {
        return true;
      }

      if (isIgnoredPath) {
        return false;
      }

      // pass if form is submitting
      if (isCreate && isSubmitting) {
        return false;
      }

      if (nextLocation.state?.afterNavigateFn) {
        shouldConfirmation.current = true;
        afterNavigateFn.current = nextLocation.state?.afterNavigateFn;
      }

      if (hasUnsavedChanges && shouldConfirmation.current) {
        nextPathname.current =
          nextLocation.pathname.replace(basePath, '') || '/';
        shouldRedirect.current = true;
        setShowPrompt.on();
        return true;
      }
      return false;
    },
    [hasUnsavedChanges, isCreate, isSubmitting, ignoreRedirectPaths]
  );

  usePrompt(blockOnChangeLocation, hasUnsavedChanges);
  useBeforeUnload(
    useCallback(
      (event: any) => {
        if (
          hasUnsavedChanges &&
          shouldConfirmation.current &&
          !shouldRedirect.current &&
          !wasShowed.current
        ) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [hasUnsavedChanges]
    ),
    { capture: true }
  );

  const handleSave = () => {
    formRef.current?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true })
    );
    wasShowed.current = true;
    shouldRedirect.current = true;
    setShowPrompt.off();
  };

  const goToNextPage = useCallback(() => {
    const isIgnoredPath = ignoreRedirectPaths.some((path) =>
      matchPath({ path }, nextPathname.current)
    );
    if (!isIgnoredPath && wasShowed.current) {
      navigate(
        nextPathname.current?.startsWith('/')
          ? nextPathname.current
          : `/${nextPathname.current}`
      );
    }
  }, [ignoreRedirectPaths, navigate]);

  // if user clicks on "Don't Save" button, navigate to next page
  const handleNavigate = () => {
    setShowPrompt.off();
    shouldConfirmation.current = false;
    shouldRedirect.current = false;
    wasShowed.current = true;

    goToNextPage();

    if (afterNavigateFn.current) {
      if (isSamePathname) {
        shouldConfirmation.current = true;
      }
      afterNavigateFn.current();
    }
  };

  const handleCancel = () => {
    setShowPrompt.off();
    shouldConfirmation.current = true;
    shouldRedirect.current = false;
    wasShowed.current = false;
    afterNavigateFn.current = null;
  };

  // after successful submit, navigate to next page
  useEffect(() => {
    if (!isSubmitting) {
      if (isSubmitted && isSubmitSuccessful && shouldRedirect.current) {
        shouldConfirmation.current = false;
        shouldRedirect.current = false;

        goToNextPage();

        if (afterNavigateFn.current) {
          if (isSamePathname) {
            shouldConfirmation.current = true;
          }
          afterNavigateFn.current();
        }
      } else if (!isSubmitSuccessful && isSubmitted) {
        handleCancel();
      }
    }
  }, [isSubmitted, isSubmitSuccessful, isSubmitting]);

  return (
    <Modal isOpen={showPrompt} onClose={handleCancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={6}>
          <Flex
            gap={2}
            p={6}
            bgColor="info.100"
            borderRadius="6px"
            mb={4}
            direction="column"
            alignItems="flex-start"
          >
            <Heading variant="h5" as="h5" fontSize="1.1rem">
              {isCreate
                ? t('form.prompt.createTitle', { entity: upperFirst(entity) })
                : t('form.prompt.editTitle', { entity: upperFirst(entity) })}
            </Heading>
            <Text variant="n3">
              {isCreate
                ? t('form.prompt.createDesc', { entity })
                : t('form.prompt.editDesc')}
            </Text>
          </Flex>
          <Flex justifyContent="space-between">
            <HStack spacing={4}>
              <Button variant="outline" onClick={handleCancel}>
                {t('form.prompt.cancel')}
              </Button>
              {hasPromptSubmit && (
                <Button
                  variant="ghost"
                  onClick={handleNavigate}
                  leftIcon={isCreate ? <AiOutlineDelete /> : <AiOutlineRedo />}
                >
                  {isCreate
                    ? t('form.prompt.dontCreate')
                    : t('form.prompt.dontSave')}
                </Button>
              )}
            </HStack>

            {hasPromptSubmit ? (
              <Button
                variant="solid"
                isLoading={isSubmitting}
                onClick={handleSave}
                leftIcon={<Icon name="check-outlined" />}
              >
                {isCreate ? t('form.prompt.create') : t('form.prompt.save')}
              </Button>
            ) : (
              <Button
                variant="ghost"
                onClick={handleNavigate}
                leftIcon={isCreate ? <AiOutlineDelete /> : <AiOutlineRedo />}
              >
                {isCreate
                  ? t('form.prompt.dontCreate')
                  : t('form.prompt.dontSave')}
              </Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FormPrompt;
