import { SkipNavToProps } from '../../duck/types';
import { hasFocusableElement } from '../table-content/duck/utils';
import {
  Divider,
  Flex,
  Kbd,
  Text,
  UnorderedList,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lon/shared/components';
import { isMac } from '@lon/shared/constants';
import { ThemeEnum } from '@lon/shared/enums';
import { useGetTheme } from '@lon/shared/hooks';
import { setAnchor, simulateHotkeyCombination } from './duck/utils';
import { CustomListItem } from './components';

const showPosition = '16px';
const previousBtnIndex = 5;
const nextBtnIndex = 4;

const SkipNavTo: React.FC<SkipNavToProps> = ({
  tableId,
  tableOrder = 1,
  hasNextTable = false,
  hasPreviousTable = false,
  hasPagination = true,
}) => {
  const { t } = useTranslation();
  const [hasFocusableContentInCaption, setCaptionFlag] = useState(false);
  const [hasFocusableContentInHead, setHeadFlag] = useState(false);
  const [hasFocusableContentInBody, setBodyFlag] = useState(false);
  const [hasFocusableContentInFoot, setFootFlag] = useState(false);
  const selectedItem = useRef<null | number>(null);
  const linkRefs = [
    useRef<HTMLLIElement>(null),
    useRef<HTMLLIElement>(null),
    useRef<HTMLLIElement>(null),
    useRef<HTMLLIElement>(null),
    useRef<HTMLLIElement>(null),
    useRef<HTMLLIElement>(null),
  ];
  const containerRef = useRef(null);
  const [skipNavTop, setSkipNavTop] = useState('-999px');
  const theme = useGetTheme();
  const isHighContrast = theme === ThemeEnum.HIGH_CONTRAST;
  const optionIndexes = [
    hasFocusableContentInCaption,
    hasFocusableContentInHead,
    hasFocusableContentInBody,
    hasFocusableContentInFoot,
    hasNextTable,
    hasPreviousTable,
  ]
    .map((value, index) => ({ value, index }))
    .filter(({ value }) => value)
    .map(({ index }) => index);

  const hideSkipNav = () => {
    selectedItem.current = null;
    (containerRef.current as any)?.blur();
    setSkipNavTop('-999px');
  };

  useHotkeys(['alt+1', 'alt+2', 'alt+3', 'alt+4'], () => {
    hideSkipNav();
  });

  useHotkeys(`alt+${tableOrder + 1}`, () => {
    setSkipNavTop(showPosition);
  });

  useHotkeys('tab', () => {
    hideSkipNav();
  });

  useOutsideClick({
    ref: containerRef,
    handler: () => {
      hideSkipNav();
    },
  });

  useHotkeys('esc', () => {
    hideSkipNav();
  });

  useEffect(() => {
    const filters = document.getElementById('tableFilters');
    const actions = document.getElementById('tableActions');
    if (filters || actions) {
      setCaptionFlag(
        hasFocusableElement((filters || actions) as HTMLElement, true)
      );
    }
  });

  useEffect(() => {
    const body = document.getElementById(`tableBody_${tableId}`);
    if (body) {
      setBodyFlag(hasFocusableElement(body, false));
    }
  });

  useEffect(() => {
    const head = document.getElementById(`tableHead_${tableId}`);
    if (head) {
      setHeadFlag(hasFocusableElement(head, false));
    }
  });

  useEffect(() => {
    const foot = document.getElementById(`tableFoot_${tableId}`);

    if (foot && hasPagination) {
      setFootFlag(hasFocusableElement(foot, true));
    }
  });

  const handleClickAnchor = (id: string) => {
    setAnchor(id);
    hideSkipNav();
  };

  useEffect(() => {
    if (skipNavTop === showPosition) {
      linkRefs[optionIndexes[0]]?.current?.focus();
    }
  }, [skipNavTop]);

  const sectionsLibrary = useMemo(
    () => [
      { id: `tableFilters_${tableId}`, label: t('table.skipToCaption') },
      { id: `tableHead_${tableId}`, label: t('table.skipToSorting') },
      { id: `tableBody_${tableId}`, label: t('table.skipToRecords') },
      { id: `tableFoot_${tableId}`, label: t('table.skipToPagination') },
    ],
    [tableId]
  );

  const sections = useMemo(() => {
    return optionIndexes
      .filter((index) => ![previousBtnIndex, nextBtnIndex].includes(index))
      .map((index) => ({
        ...sectionsLibrary[index],
        ref: linkRefs[index],
      }));
  }, [optionIndexes]);

  const handleArrowKey = (direction: 'up' | 'down') => {
    if (optionIndexes.length === 0) return;
    setSkipNavTop(showPosition);

    const index = optionIndexes.findIndex(
      (value) => value === selectedItem.current
    );

    const lastItemIndex = optionIndexes.length - 1;

    // if the selected item is the last one and the direction is down
    if (direction === 'down' && index + 1 > lastItemIndex) {
      selectedItem.current = optionIndexes[0];
      return linkRefs[optionIndexes[0]]?.current?.focus();
    }

    // if the selected item is the first one and the direction is up
    if (direction === 'up' && index - 1 < 0) {
      selectedItem.current = optionIndexes[lastItemIndex];
      return linkRefs[optionIndexes[lastItemIndex]]?.current?.focus();
    }

    // if the selected item is not the first or the last one
    if (selectedItem.current !== null && direction === 'up') {
      selectedItem.current = optionIndexes[index - 1];
    }

    // if the selected item is not the first or the last one
    if (selectedItem.current !== null && direction === 'down') {
      selectedItem.current = optionIndexes[index + 1];
    }

    // if the selected item is the first one and the direction is up
    if (selectedItem.current === null && direction === 'up') {
      selectedItem.current = optionIndexes[lastItemIndex];
    }

    // if the selected item is the last one and the direction is down
    if (selectedItem.current === null && direction === 'down') {
      selectedItem.current = optionIndexes[0];
    }

    //@ts-ignore
    linkRefs[selectedItem.current]?.current?.focus();
  };

  const handleEnterOrSpace = () => {
    if (selectedItem.current === null) return;

    const index = optionIndexes.findIndex(
      (value) => value === selectedItem.current
    );

    const selectedOptionId = sections[index];

    if (selectedOptionId) {
      handleClickAnchor(selectedOptionId.id);
    }
    if (selectedItem.current === nextBtnIndex) {
      simulateHotkeyCombination('alt', String(tableOrder + 2));
    }
    if (selectedItem.current === previousBtnIndex) {
      simulateHotkeyCombination('alt', String(tableOrder));
    }
    hideSkipNav();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        handleArrowKey('down');
        break;
      case 'ArrowUp':
        e.preventDefault();
        handleArrowKey('up');
        break;
      case 'Enter':
      case 'Space':
      case ' ':
        handleEnterOrSpace();
        break;
      case 'Escape': {
        hideSkipNav();
        break;
      }

      default:
        break;
    }
  };

  if (
    !hasFocusableContentInBody &&
    !hasFocusableContentInCaption &&
    !hasFocusableContentInHead &&
    !hasFocusableContentInFoot
  )
    return null;

  return (
    <Flex
      id="skipTo"
      textAlign="left"
      tabIndex={0}
      direction="column"
      position="absolute"
      top={skipNavTop}
      overflowX="auto"
      left={4}
      bg={isHighContrast ? 'secondary.1000' : 'white'}
      zIndex={3}
      _focus={{ top: showPosition }}
      borderRadius="sm"
      boxShadow="0px 3px 10px 5px rgba(43, 54, 70, 0.2)"
      ref={containerRef}
      onKeyDown={handleKeyDown}
      maxW="270px"
      pt={4}
      pb={2}
    >
      <Text
        color={isHighContrast ? 'white' : 'primary.500'}
        variant="s2"
        fontSize="xs"
        px={6}
      >
        {t('table.skipListTitle')}

        <Text display="flex" as="span" mt={1} gap={2} alignItems="center">
          <Kbd>{!isMac ? 'ALT' : '⌥'}</Kbd>
          <Text as="span" variant="s2">
            +
          </Text>
          <Kbd>{tableOrder + 1}</Kbd>
        </Text>
      </Text>
      <Divider my={2} />
      <UnorderedList styleType="none" marginInlineStart={0}>
        {sections?.map((v: any) => (
          <CustomListItem
            key={v.id}
            internalRef={v.ref}
            onClick={() => handleClickAnchor(v.id)}
          >
            {v.label}
          </CustomListItem>
        ))}
        {hasNextTable || hasPreviousTable ? (
          <>
            <Divider my={2} />
            {hasNextTable && (
              <CustomListItem
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                internalRef={linkRefs[nextBtnIndex]}
                onClick={() => {
                  simulateHotkeyCombination('alt', String(tableOrder + 2));
                }}
              >
                {t('table.nextTable')}
                <Icon name="arrow-right-outlined" />
              </CustomListItem>
            )}
            {hasPreviousTable && (
              <CustomListItem
                display="flex"
                alignItems="center"
                gap={3}
                internalRef={linkRefs[previousBtnIndex]}
                onClick={() => {
                  simulateHotkeyCombination('alt', String(tableOrder));
                }}
              >
                <Icon name="arrow-left-outlined" />
                {t('table.previousTable')}
              </CustomListItem>
            )}
          </>
        ) : null}
      </UnorderedList>
    </Flex>
  );
};

export default SkipNavTo;
