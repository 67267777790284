import { Icon } from '../icon';
import {
  Badge,
  Button,
  Flex,
  HStack,
  IconButton,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FormMeta } from '@lon/shared/contexts';
import { FormWrapperProps, ISubmitButton } from './duck';
import { FormPrompt } from './components';

export const FormWrapper: React.FC<FormWrapperProps> = ({
  children,
  title = '',
  mode = 'view',
  isDirty = false,
  onSubmit,
  submitButton,
  addNewButton,
  cancelButton,
  toListButton,
  entity,
  ignoreRedirectPaths = [],
  basePath = '/',
  icon,
  hasPromptSubmit = true,
  formProps,
  badge,
  leftAdditionalButton,
  rightAdditionalButton,
  hideFooterLeftIcon,
  hideFooter,
  topAdditionalButton,
}) => {
  const { needFormPrompt } = useContext(FormMeta);
  const formRef = React.useRef(null);
  const isMobile = useBreakpointValue(
    {
      base: true,
      lg: false,
    },
    {
      ssr: false,
    }
  );
  const { t } = useTranslation();

  const badgeByMode: {
    [key: string]: React.ReactNode;
  } = {
    create: (
      <Badge variant="greenBadge" fontWeight={400}>
        {t('form.new')}
      </Badge>
    ),
    edit: (
      <Badge variant="yellowBadge" fontWeight={400}>
        {t('form.edit')}
      </Badge>
    ),
  };

  const renderBadge =
    mode === 'create' ? badgeByMode.create : isDirty && badgeByMode[mode];

  const renderAddNewButton = isMobile ? (
    <IconButton
      aria-label={addNewButton?.label || ''}
      variant="outline"
      isDisabled={addNewButton?.isDisabled}
      onClick={addNewButton?.onClick}
    >
      <Icon name="plus-outlined" />
    </IconButton>
  ) : (
    <Button
      onClick={addNewButton?.onClick}
      variant="outline"
      leftIcon={<Icon name="plus-outlined" />}
      isDisabled={addNewButton?.isDisabled}
    >
      <Text as="span">{addNewButton?.label}</Text>
    </Button>
  );

  useEffect(() => {
    needFormPrompt.current = isDirty;

    return () => {
      needFormPrompt.current = false;
    };
  }, [isDirty]);

  return (
    <Flex
      bg="white"
      borderRadius="6px"
      flexGrow="1"
      flexDirection="column"
      color="primary.800"
      as="form"
      noValidate
      onSubmit={onSubmit}
      ref={formRef}
      {...formProps}
    >
      {title && (
        <Flex
          borderBottom="1px solid"
          borderColor="secondary.200"
          px={{ base: 4, xl: 8 }}
          py={5}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack spacing={6}>
            {icon}
            <Text variant="h4" color="primary.800">
              {title}
            </Text>
          </HStack>

          {badge ? badge : renderBadge}
          {topAdditionalButton}
        </Flex>
      )}

      <FormPrompt
        mode={mode}
        formRef={formRef}
        entity={entity}
        ignoreRedirectPaths={ignoreRedirectPaths}
        basePath={basePath}
        hasPromptSubmit={hasPromptSubmit}
        isDirty={isDirty}
      />

      {children}

      {!hideFooter && (
        <Flex
          borderTop="1px solid"
          borderColor="secondary.200"
          px={{ base: 4, xl: 8 }}
          py={6}
          justifyContent={hideFooterLeftIcon ? 'flex-end' : 'space-between'}
          wrap="wrap"
          gap={4}
        >
          {!hideFooterLeftIcon && (
            <Flex gap={4} wrap="wrap">
              {isMobile ? (
                <>
                  <IconButton
                    aria-label={toListButton?.label || ''}
                    as={Link}
                    variant="outline"
                    isDisabled={toListButton?.isDisabled}
                    to={toListButton?.href || '/'}
                  >
                    <Icon name="arr-left-outlined" />
                  </IconButton>
                  {leftAdditionalButton}
                </>
              ) : (
                <Flex gap={4}>
                  <Button
                    variant="outline"
                    leftIcon={<Icon name="arr-left-outlined" />}
                    as={Link}
                    isDisabled={toListButton?.isDisabled}
                    to={toListButton?.href || '/'}
                  >
                    <Text as="span">{toListButton?.label}</Text>
                  </Button>
                  {leftAdditionalButton}
                </Flex>
              )}
            </Flex>
          )}

          <Flex gap={4} wrap="wrap">
            {rightAdditionalButton}
            {addNewButton ? renderAddNewButton : null}
            {cancelButton ? (
              <Button
                variant="outline"
                as={Link}
                to={cancelButton?.href || '/'}
              >
                <Text as="span">{cancelButton?.label}</Text>
              </Button>
            ) : null}
            {React.isValidElement(submitButton) ? (
              submitButton
            ) : (
              <Button
                variant="solid"
                type="submit"
                isDisabled={(submitButton as ISubmitButton)?.isDisabled}
                leftIcon={(submitButton as ISubmitButton)?.leftIcon}
                rightIcon={(submitButton as ISubmitButton)?.rightIcon}
                isLoading={(submitButton as ISubmitButton)?.isLoading}
              >
                <Text as="span">{(submitButton as ISubmitButton)?.label}</Text>
              </Button>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
