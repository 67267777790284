import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { sxDarkScrollBar } from '@lon/shared/constants';

const HelpPageSkeleton = () => {
  const { t } = useTranslation();

  return (
    <Flex>
      <Flex width="full" gap={4} height="calc(100vh - 225px)" overflow="hidden">
        <Flex
          flexDirection="column"
          background={'white'}
          borderRadius="10px 10px"
        >
          <Flex
            boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.1)"
            width="full"
            p={'16px 24px'}
            minWidth="252px"
            borderRadius="10px 10px 0px 0px"
          >
            <Flex
              justifyContent="space-between"
              width="full"
              height="100%"
              alignItems="center"
              dropShadow="rgba(43, 54, 70, 0.1)"
            >
              <Text variant="h5" color="blue.500">
                {t('helpPage.topic')}
              </Text>
            </Flex>
          </Flex>

          <Flex
            flexDirection="column"
            gap={2}
            p={'16px 24px'}
            overflowY="auto"
            css={sxDarkScrollBar}
          >
            <Skeleton height={14} width={200} />
            <Skeleton height={14} width={200} />
            <Skeleton height={14} width={200} />
            <Skeleton height={14} width={200} />
            <Skeleton height={14} width={200} />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          background={'white'}
          borderRadius="10px 10px"
          width="full"
          height="100%"
        >
          <Flex
            boxShadow="0px 2px 7px 0px rgba(43, 54, 70, 0.1)"
            width="full"
            p={'16px 24px'}
            borderRadius="10px 10px 0px 0px"
          >
            <Flex
              width="full"
              alignItems="center"
              dropShadow="rgba(43, 54, 70, 0.1)"
            >
              <Text variant="h5" color="blue.500">
                {t('helpPage.Articles')}
              </Text>
            </Flex>
          </Flex>

          <Flex
            height="100%"
            flexDirection="column"
            gap={2}
            p={'16px 24px'}
            overflowY="auto"
            css={sxDarkScrollBar}
          >
            <Flex
              height="100%"
              flexDirection="column"
              width="full"
              background="white"
              gap={4}
            >
              <Skeleton height={14} width="full" />
              <Skeleton height={14} width="full" />
              <Skeleton height={14} width="full" />
              <Skeleton height={14} width="full" />
              <Skeleton height={14} width="full" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HelpPageSkeleton;
