export const Heading = {
  defaultProps: {
    size: 'lg',
  },
  variant: {
    'rl-h2': {
      position: 'relative',
      zIndex: 5,
      textTransform: 'uppercase',
      fontSize: '2.2em',
      fontWeight: 800,
      lineHeight: '1.3em',
    },
  },
  sizes: {
    sm: {
      fontSize: 'md',
      lineHeight: '1.625rem',
      fontWeight: '700',
    },
    md: {
      fontSize: 'lg',
      lineHeight: '2xl',
      fontWeight: '700',
    },
    lg: {
      fontSize: '1.375rem',
      lineHeight: '3xl',
      fontWeight: '600',
    },
    xl: {
      fontSize: '1.625rem',
      lineHeight: '2.125rem',
      fontWeight: '600',
    },
    '2xl': {
      fontSize: '2rem',
      lineHeight: '2.75rem',
      fontWeight: '600',
    },
    '3xl': {
      fontSize: '4xl',
      lineHeight: '5xl',
      fontWeight: '700',
    },
  },
};
