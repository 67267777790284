import { useGetSchoolIdsForPlanner } from "@lon/shared/hooks";
import { useSelector } from "react-redux";
import { useUpdateUserSelectionMutation } from "@lon/shared/requests";
import * as plannerSelectors from "@lon/suit/selectors";

export function useUpdateSelectedPlan() {
  const accessibleSchoolIds = useGetSchoolIdsForPlanner();
  const { teamIds: userTeamIds } = useSelector(plannerSelectors.teams);
  const userRoleCode = useSelector(plannerSelectors.userRoleCode);
  const [updateSelection] = useUpdateUserSelectionMutation();

  const updateSelectedPlan = (planId: string) => {
    updateSelection({
      variables: {
        input: {
          selectedScopePlan: [planId],
          schoolIds: accessibleSchoolIds,
          userRoleCode,
          userTeamIds,
        },
      },
    });
  };
  return [updateSelectedPlan];
}
