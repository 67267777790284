import { State } from './types';

const state: State = {
  items: [],
  activeIndex: 0,
  popup: false,
  hoverable: false,
  orientation: 'horizontal',
  returnFocus: () => {},
};

export default state;
