//@ts-nocheck
import { Content } from '../content';
import clsx from 'clsx';
import React from 'react';
import Loader from 'react-content-loader';
import { useThemeStyleValue } from '@lon/shared/hooks';
import { types } from './duck';
import styles from './ContentLoader.module.css';

const ContentLoader: React.FC<types.ContentLoaderProps> = ({
  className,
  dark = false,
  withContent = false,
  ...rest
}) => {
  return React.createElement(
    withContent ? Content : React.Fragment,
    null,
    <Loader
      {...rest}
      gradientRatio={0.8}
      className={clsx(styles.wrapper, className)}
      backgroundColor={useThemeStyleValue(
        'var(--basic-200)',
        'var(--basic-400)'
      )}
      foregroundColor="var(--basic-100)"
    />
  );
};

export default ContentLoader;
