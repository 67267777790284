import React from 'react';
import { RadioButtonListProps } from './duck';
import { BaseRadioButtonList, FormRadioButtonList, Loader } from './components';

const RadioButtonList: React.FC<RadioButtonListProps> = ({
  isLoading,
  loadingMessage = '',
  name,
  ...rest
}) => {
  if (isLoading) {
    return <Loader isLoading={isLoading} message={loadingMessage} />;
  }

  if (name && !rest.onChange) {
    return <FormRadioButtonList {...rest} name={name} />;
  }

  return <BaseRadioButtonList {...rest} name={name} />;
};

export default RadioButtonList;
