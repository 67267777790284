export const planIdPrefix = 'api/scope_plans/';
export const teacherView = 'teacherView';
export const studentView = 'studentView';
export const CHILD_PAGE_HEADER_HEIGHT = 65;
export const showAnswers = 'ON';
export const hideAnswers = 'OFF';
export const showTTS = 'ON';
export const hideTTS = 'OFF';
export const notebookCoverImageSrc = {
  Science: {
    Biology: 'suit/assets/EN_Cover_Biology_Dark.svg',
    Chemistry: 'suit/assets/EN_Cover_Chemistry_Dark.svg',
    IPC: 'suit/assets/EN_Cover_IPC_Dark.svg',
    K: 'suit/assets/EN_Cover_Science_Kindergarten.svg',
    '1': 'suit/assets/EN_Cover_Science_1.svg',
    '2': 'suit/assets/EN_Cover_Science_2.svg',
  },
  Math: {
    Algebra: 'suit/assets/EN_Cover_Algebra_Dark.svg',
    Geometry: 'suit/assets/EN_Cover_Geometry_Dark.svg',
    K: 'suit/assets/EN_Cover_Math_Kindergarten.svg',
    '1': 'suit/assets/EN_Cover_Math_1.svg',
    '2': 'suit/assets/EN_Cover_Math_2.svg',
  },
  OtherAssignments: {
    'k-2': 'suit/assets/EN_Cover_Assignments_Dark_K-2.svg',
    '3-12': 'suit/assets/EN_Cover_Assignments_Dark.svg',
  },
};
export const studentNoteMaxLength = 255;
export const SKELETONS_COUNT = 9;
export const maxScore = 1;
