import { Pilar, langEnum } from '../../duck';
import Cookies from 'js-cookie';
import React from 'react';

interface ReadSpeakerButtonProps {
  speakingRate: string;
  highlightWords: boolean;
  englishVoice: string;
  spanishVoice: string;
}

const ReadspeakerButton = ({
  speakingRate,
  highlightWords,
  englishVoice,
  spanishVoice,
}: ReadSpeakerButtonProps) => {
  const documentLanguage = window.document.documentElement.lang;
  const uri = encodeURIComponent(window.location.href);
  const voice = documentLanguage === 'es' ? spanishVoice : englishVoice;
  const languageEnum =
    documentLanguage === 'es'
      ? voice === Pilar
        ? langEnum.spanish
        : langEnum.spanish_us
      : langEnum.english;

  const getSpeakingRate = (speak: string) => {
    switch (speak) {
      case 'slow':
        return '75';
      case 'regular':
        return '100';
      case 'fast':
        return '125';
    }

    return '100';
  };

  const readspeakerSrc =
    'https://cdn-na.readspeaker.com/script/12474/webReader/webReader.js?pids=wr';

  React.useEffect(() => {
    (window as any).rsConf = {
      general: {
        usePost: true,
      },
      settings: {
        hlScroll: 'scrolloff',
      },
      ui: {
        tools: {
          controlpanel: false,
          skipbuttons: false,
        },
      },
    };

    const scripts = document.querySelectorAll('script');

    const hasReadspeakerScript = [...scripts].find(
      (script) => script.src === readspeakerSrc
    );

    if (hasReadspeakerScript === undefined) {
      const readspeakerScript = document.createElement('script');
      readspeakerScript.type = 'text/javascript';
      readspeakerScript.src = readspeakerSrc;
      readspeakerScript.id = 'rs_req_Init';
      document.head.appendChild(readspeakerScript);
    }

    document
      .getElementById('readspeaker_anchor')
      ?.addEventListener('click', function (event) {
        event.preventDefault();
      });
  }, []);

  React.useEffect(() => {
    Cookies.set(
      'ReadSpeakerSettings',
      `hltoggle=${!highlightWords ? 'hloff' : 'hlon'}${
        speakingRate && `&hlspeed=${getSpeakingRate(speakingRate)}`
      }`
    );
  }, [speakingRate, highlightWords]);

  return (
    <div id="readspeaker_button" className="rs_skip rsbtn rs_preserve">
      <a
        id="readspeaker_anchor"
        rel="nofollow"
        className="rsbtn_play"
        title="Listen to this page using ReadSpeaker webReader"
        href={
          `https://app-na.readspeaker.com/cgi-bin/rsent?customerid=12474&amp;lang=${languageEnum}&amp;` +
          `readid=reading-accessibility-example&amp;voice=${voice}&amp;url=${uri}`
        }
      >
        <span className="rsbtn_left rsimg rspart">
          <span className="rsbtn_text">
            <span>Listen</span>
          </span>
        </span>
        <span className="rsbtn_right rsimg rsplay rspart"></span>
      </a>
    </div>
  );
};

export default ReadspeakerButton;
