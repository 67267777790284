import { Icon } from '../../../icon';
import { MenuItem } from '../../../menu';
import actionsStyles from '../../ToolbarActions.module.css';
import { IconButton } from '@chakra-ui/react';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './FontLarger.module.css';

const FontLarger: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <MenuItem className={styles.separator} {...props}>
      <IconButton
        size="md"
        variant="solidDark"
        className={clsx(actionsStyles.action, styles.button)}
        title={t('toolbar.fontLarger')}
        aria-label={t('toolbar.fontLarger')}
      >
        <Icon name="a-large-outlined" size="lg" />
      </IconButton>
    </MenuItem>
  );
};

export default FontLarger;
