import { FormCheckboxListProps } from '../duck';
import { orderArray } from '../duck/utils';
import { get, isEqual } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BaseCheckboxList } from './BaseCheckboxList';

const FormCheckboxList: React.FC<FormCheckboxListProps> = (props) => {
  const formContext = useFormContext();
  const errorMessage = useMemo(
    () => get(formContext.formState.errors, `${props.name}.message`),
    [formContext.formState.errors, props.name]
  );
  const value = useWatch({ name: props.name });
  const handleChangeIndeterminateCheckbox = useCallback(
    (checked: boolean) => {
      formContext.setValue(
        props.name,
        checked
          ? props?.data?.map((s) => s?.value || '')
          : new Array(props?.data?.length).fill(''),
        { shouldDirty: true }
      );
    },
    [props.name, props?.data]
  );

  const handleChangeCheckboxList = useCallback(
    (value: number, checked: boolean, index: number) => {
      formContext.setValue(`${props.name}.${index}`, checked ? value : '', {
        shouldDirty: true,
      });
    },
    [props.name, formContext.setValue]
  );

  useEffect(() => {
    const result = orderArray(
      props.data.map(({ value }) => value),
      value
    );
    if (!isEqual(result, value)) {
      formContext.setValue(`${props.name}`, result);
    }
  }, [props.name, props.data]);

  return (
    <BaseCheckboxList
      {...props}
      errorMessage={errorMessage as any}
      selectedCount={value?.filter(Boolean)?.length}
      onIndeterminateCheckboxChange={handleChangeIndeterminateCheckbox}
      onChange={handleChangeCheckboxList}
      values={value}
    />
  );
};

export default FormCheckboxList;
