import { createStandardAction } from 'typesafe-actions';
import { Pagination } from '@lon/shared/types';
import { FiltersReducer } from './types';

export const setFilters =
  createStandardAction('APP/SET_FILTERS')<Partial<FiltersReducer>>();
export const setPagination = createStandardAction('APP/SET_PAGINATION')<{
  filtersKey: string;
  pagination: Pagination;
}>();
export const removeFilter = createStandardAction('APP/REMOVE_FILTER')<{
  field: string;
  filtersKey: string;
}>();
export const removeAllFilters = createStandardAction('APP/REMOVE_ALL_FILTERS')<{
  filtersKey: string;
}>();
export const disableAllFilters = createStandardAction(
  'APP/DISABLE_ALL_FILTERS'
)<{ filtersKey: string }>();
