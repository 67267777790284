import $ from 'jquery';
import * as React from 'react';
import {
  replaceImagesBasedOnLanguage,
  replaceVideosBasedOnLanguage,
} from '@lon/shared/utils';

interface Score {
  max_score: number;
  score: number | null;
}

const rulesForCssText = function (styleContent: string): CSSRuleList {
  const doc = document.implementation.createHTMLDocument('');
  const styleElement = document.createElement('style');

  styleElement.textContent = styleContent;
  doc.body.appendChild(styleElement);

  setTimeout(() => {
    doc.body.removeChild(styleElement);
  }, 400);
  return (styleElement as any).sheet.cssRules;
};

const useProcessLearnosity = <T extends HTMLElement>({
  showQuestionId,
}: { showQuestionId?: boolean } = {}) => {
  const ref = React.useRef<T>(null);

  return {
    ref,
    process: () => {
      if (!ref.current) {
        return;
      }

      const styles = Array.from(
        ref.current.querySelectorAll('div.style') || []
      ) as HTMLDivElement[];

      styles.forEach((style, index) => {
        const table = style.nextElementSibling as HTMLTableElement;
        if (!table) {
          return;
        }

        const classNamePrefix = `edgeXLtable${index}`;
        const cssRules = Array.from(rulesForCssText(style.innerText) || []);

        const stylesheet = document.createElement('style');
        stylesheet.type = 'text/css';
        /* Mark the stylesheet a temporary one that must be cleaned up later */
        stylesheet.dataset.removable = 'true';

        cssRules.forEach((rule) => {
          const content = `${rule.cssText.replace(
            /\.tg/g,
            `.${classNamePrefix}`
          )}`;
          stylesheet.innerText = `${stylesheet.innerText} ${content} `;
        });

        table.classList.add(classNamePrefix);
        document.head.appendChild(stylesheet);
      });

      const allTables = Array.from(
        ref.current.querySelectorAll('table') || []
      ) as unknown as HTMLTableElement[];

      const allTablesWithBorder = allTables.filter(
        (table) =>
          typeof table.getAttribute('border') === 'string' ||
          table.classList.contains('tg')
      );

      allTablesWithBorder.forEach((table) => {
        table.style.border = '1px solid black';

        const tds = Array.from(
          table.querySelectorAll<HTMLTableCellElement>('td,th') || []
        );

        tds.forEach((td) => {
          td.style.border = '1px solid black';
        });
      });

      const tablesColGroups = Array.from(
        ref.current.querySelectorAll('table colgroup') || []
      ) as HTMLTableColElement[];

      tablesColGroups.forEach((colGroup) => {
        colGroup.style.display = 'none';
      });

      const brElements = ref.current.querySelectorAll('br');

      brElements.forEach((br) => {
        let nextElement = br.nextSibling;

        while (
          nextElement &&
          (nextElement.nodeName === 'BR' ||
            (nextElement.nodeName === '#text' &&
              nextElement.textContent?.trim() === ''))
        ) {
          const toRemove = nextElement;
          nextElement = nextElement.nextSibling;
          toRemove.parentNode?.removeChild(toRemove);
        }
      });

      const images = ref.current.querySelectorAll('img');
      images.forEach((img) => {
        if (img.style.width) {
          img.setAttribute(
            'style',
            `max-width: 100% !important;${img.style.cssText}`
          );
        }
      });

      if (showQuestionId) {
        const learnosityItems =
          ref.current.querySelectorAll<HTMLDivElement>('.learnosity-item');
        learnosityItems.forEach((item) => {
          const hasQuestionId = item.querySelector('.question-id-hint');

          if (!hasQuestionId) {
            const questionId = item.getAttribute('data-reference') || '';
            item.insertAdjacentHTML(
              'beforeend',
              `<div class="question-id-hint" style="text-align: right">Question ID: ${questionId}</div>`
            );
          }
        });
      }

      setTimeout(() => {
        replaceVideosBasedOnLanguage();
        replaceImagesBasedOnLanguage();
      }, 0);
    },

    cleanup: () => {
      const cleanupStylesheets = Array.from(
        document.head.querySelectorAll('style[data-removable]') || []
      ) as HTMLStyleElement[];

      cleanupStylesheets.forEach((stylesheet) => {
        document.head.removeChild(stylesheet);
      });
    },

    processReview: (scores: Record<string, Score>) => {
      $('.lrn_widget').each((index, el) => {
        const isCorrect = scores[el.id].score === scores[el.id].max_score;

        $(el).append(
          `<div class="score-result">Result: <span class="${
            isCorrect ? 'correct' : 'incorrect'
          }">${isCorrect ? 'Correct' : 'Incorrect'}</span></div>`
        );
      });
    },
  };
};

export default useProcessLearnosity;
