import newColors from '../new-colors';
import { formErrorAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(formErrorAnatomy.keys);

const baseStyle = definePartsStyle({
  text: {
    mt: 2,
    color: newColors.red['700'],
  },
});
const dark = definePartsStyle({
  text: {
    mt: 2,
    color: newColors.orange['400'],
  },
});
export const NewFormError = defineMultiStyleConfig({
  baseStyle,
  variants: { dark },
});
