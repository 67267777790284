import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { Link as RouterLink } from '@lon/shared/components';
import { isDevMode } from '@lon/shared/constants';
import { useGetIconQuery } from '@lon/shared/requests';
import { styles, types } from './duck';

const ElementCard: React.FC<types.ElementCardProps> = ({
  id = 0,
  title,
  image,
  sectionId = 0,
  elementPlacementUrl,
}) => {
  const { t } = useTranslation();
  const {
    scopeId = 0,
    unitId,
    projectId = 0,
  } = useParams<{ [key: string]: string }>();

  const baseUrl = isDevMode()
    ? `https://${window.location.hostname.replace('local.', '')}`
    : '';

  const { data } = useGetIconQuery({
    skip: !image,
    variables: {
      filter: {
        type: image,
      },
    },
  });

  return (
    <Box sx={styles.wrapper}>
      <Flex align="flex-start">
        <Box sx={styles.imageWrapper}>
          <Image
            src={
              data?.icons?.collection[0]?.filePath
                ? `${baseUrl}${data?.icons?.collection[0]?.filePath}`
                : undefined
            }
            alt=""
            sx={styles.image}
          />
        </Box>
        <Text as="h3" fontSize="sm" fontWeight="700" mr="4">
          <Link
            as={RouterLink}
            withoutStyles
            to={{
              pathname: generatePath(elementPlacementUrl, {
                scopeId,
                unitId,
                sectionId,
                elementId: id || 0,
                projectId,
              }),
            }}
            title={t('scope.openElement', { title })}
            sx={styles.link}
          >
            {title}
          </Link>
        </Text>
      </Flex>
    </Box>
  );
};

export default ElementCard;
