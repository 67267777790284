import {
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { GradeTag, Icon } from '@lon/shared/components';
import { StudentGradeContext } from '@lon/shared/contexts';
import { useMediaQuery } from '@lon/shared/hooks';
import { useTranslation } from 'react-i18next';

const AssignmentLegend = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const { isStudentBelowK3 } = useContext(StudentGradeContext);

  return (
    <>
      <IconButton
        size="sm"
        minW={isStudentBelowK3 ? '44px' : '42px'}
        minH={isStudentBelowK3 ? '44px' : '32px'}
        height="unset"
        variant="outline"
        aria-label={t('assignmentLegend.openLegend')}
        onClick={onOpen}
        borderRadius={isStudentBelowK3 ? '10px' : '4px'}
      >
        <Icon
          name={isStudentBelowK3 ? 'infoCircleOutlinedK12' : 'info-circle'}
        />
      </IconButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        {...(isMobile ? { size: 'full' } : {})}
      >
        <ModalContent
          data-focus-visible-disabled
          maxW={{ b768: isStudentBelowK3 ? '800px' : '700px' }}
          borderWidth={{ base: 0, b768: '15px' }}
          borderColor="secondary.50"
          borderRadius={{ base: 0, b768: '20px' }}
        >
          <ModalHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={6}
            py={isStudentBelowK3 ? 3 : 4}
            maxH="unset"
          >
            <Text variant="h5" fontSize={isStudentBelowK3 ? '26px' : '20px'}>
              {t('assignmentLegend.keys')}
            </Text>
            <ModalCloseButton position="unset" w="50px" h="50px" />
          </ModalHeader>
          <ModalBody p={0}>
            <Flex direction={{ base: 'column', b768: 'row' }}>
              <Flex
                px={6}
                py={4}
                direction="column"
                gap={isStudentBelowK3 ? '0.9rem' : '1.125rem'}
                flex={3}
                borderRightWidth={{ base: 0, b768: '1px' }}
                borderBottomWidth={{ base: '1px', b768: 0 }}
                borderColor="secondary.200"
              >
                <Text variant="n1" color="primary.800">
                  {t('assignmentLegend.status')}
                </Text>
                <Flex
                  direction="column"
                  gap={isStudentBelowK3 ? '0.4rem' : '0.5rem'}
                >
                  <HStack gap={1}>
                    <GradeTag
                      flexShrink={0}
                      backgroundColor="olive.50"
                      borderColor="green.extraLight"
                      color="olive.100"
                      height="unset"
                    >
                      <Icon
                        name={
                          isStudentBelowK3
                            ? 'checkSimpleK12'
                            : 'checkSimpleOutlined'
                        }
                        width="16px"
                        height="16px"
                      />
                    </GradeTag>
                    <Text variant="s2" color="primary.800">
                      {t('assignmentLegend.grade')}
                    </Text>
                  </HStack>
                  <HStack gap={1}>
                    <GradeTag
                      flexShrink={0}
                      height="unset"
                      backgroundColor="primary.30"
                      borderColor="primary.50"
                      color="primary.800"
                    >
                      <Icon
                        name={isStudentBelowK3 ? 'minusK12' : 'minus'}
                        width="16px"
                        height="16px"
                      />
                    </GradeTag>
                    <Text variant="s2" color="primary.800">
                      {t('assignmentLegend.notPassed')}
                    </Text>
                  </HStack>
                </Flex>
              </Flex>
              <Flex
                pl={isStudentBelowK3 ? '1.2rem' : '1.5rem'}
                pr={6}
                py={4}
                direction="column"
                gap={isStudentBelowK3 ? '0.9rem' : '1.125rem'}
                flex={5}
              >
                <Text variant="n1" color="primary.800">
                  {t('assignmentLegend.date')}
                </Text>
                <Flex direction="column" gap="16px">
                  <HStack gap={1}>
                    <Flex
                      minW={isStudentBelowK3 ? '165px' : '153px'}
                      maxH={isStudentBelowK3 ? '50px' : '38px'}
                      color="secondary.300"
                      borderWidth="1px"
                      borderColor="secondary.300"
                      py={2}
                      pl={4}
                      pr={5}
                      align="center"
                      justify="space-between"
                    >
                      <Text textAlign="center" w="93px" variant="s2">
                        - - -
                      </Text>
                      <Icon
                        name={
                          isStudentBelowK3 ? 'stopOutlinedK12' : 'stopOutlined'
                        }
                        width="16px"
                        height="16px"
                      />
                    </Flex>
                    <Text variant="s2" color="primary.800">
                      {t('assignmentLegend.notSubmitted')}
                    </Text>
                  </HStack>
                  <HStack gap={1}>
                    <Flex
                      minW={isStudentBelowK3 ? '165px' : '153px'}
                      maxH={isStudentBelowK3 ? '50px' : '38px'}
                      color="primary.800"
                      borderWidth="1px"
                      borderColor="secondary.300"
                      py={2}
                      pl={4}
                      pr={5}
                      align="center"
                      justify="space-between"
                    >
                      <Text variant="s2" color="primary.800">
                        04/27/2023
                      </Text>
                      <Icon
                        name={
                          isStudentBelowK3
                            ? 'checkCircleOutlinedK12'
                            : 'checkCircleOutlined'
                        }
                        width="16px"
                        height="16px"
                      />
                    </Flex>
                    <Text variant="s2" color="primary.800">
                      {t('assignmentLegend.submitted')}
                    </Text>
                  </HStack>
                  <HStack gap={1}>
                    <Flex
                      minW={isStudentBelowK3 ? '165px' : '153px'}
                      maxH={isStudentBelowK3 ? '50px' : '38px'}
                      color="danger.800"
                      borderWidth="1px"
                      borderColor="secondary.300"
                      py={2}
                      pl={4}
                      pr={5}
                      align="center"
                      justify="space-between"
                    >
                      <Text textAlign="center" w="93px" variant="s2">
                        - - -
                      </Text>
                      <Icon
                        name={isStudentBelowK3 ? 'hourGlassK12' : 'hourGlass'}
                        width="16px"
                        height="16px"
                      />
                    </Flex>
                    <Text variant="s2" color="primary.800">
                      {t('assignmentLegend.notSubmittedLate')}
                    </Text>
                  </HStack>
                  <HStack gap={1}>
                    <Flex
                      minW={isStudentBelowK3 ? '165px' : '153px'}
                      maxH={isStudentBelowK3 ? '50px' : '38px'}
                      color="danger.800"
                      borderWidth="1px"
                      borderColor="secondary.300"
                      py={2}
                      pl={4}
                      pr={5}
                      align="center"
                      justify="space-between"
                    >
                      <Text textAlign="center" w="93px" variant="s2">
                        04/27/2023
                      </Text>
                      <Icon
                        name={isStudentBelowK3 ? 'hourGlassK12' : 'hourGlass'}
                        width="16px"
                        height="16px"
                      />
                    </Flex>
                    <Text variant="s2" color="primary.800">
                      {t('assignmentLegend.submittedLate')}
                    </Text>
                  </HStack>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AssignmentLegend;
