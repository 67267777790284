import { CustomPropTypes } from '../../duck';
import { CloseButton, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const Header: React.FC<CustomPropTypes> = ({ onClose }) => {
  return (
    <Flex
      w="100%"
      justify="space-between"
      backgroundColor="#1E4176"
      color="white"
      p={4}
      borderTopRadius={4}
    >
      <Text fontSize="lg" fontWeight="bold" tabIndex={0}>
        Perry Learning Bot
      </Text>
      <CloseButton onClick={onClose} />
    </Flex>
  );
};

export default Header;
