import { FormCheckbox } from '../../../../../form-units';
import { ElementRowPropTypes } from '../../duck';
import { Badge, Flex, ListItem, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ElementRow: React.FC<ElementRowPropTypes> = ({
  name,
  item,
  handleChangeCheckbox,
  index,
  isDisabled,
  values,
}) => {
  const { t } = useTranslation();

  return (
    <ListItem
      key={item?.value}
      px={{ base: '3', sm: '4' }}
      py="2"
      borderTop="1px"
      borderColor="#E1E7F0"
    >
      <FormCheckbox
        key={item?.value}
        size="md"
        name={`${name}.${index}`}
        isChecked={values.includes(item.value)}
        defaultValue=""
        onInputChange={(e) => {
          handleChangeCheckbox(item.value, e.target.checked, index);
        }}
        isDisabled={isDisabled}
        css={{
          '& label.chakra-checkbox': {
            width: '100%',
          },
          '& .chakra-checkbox__label': {
            width: '100%',
          },
        }}
        label={
          <Flex
            gap={{ base: '3', sm: '6' }}
            justify="space-between"
            alignItems="center"
          >
            <Text variant="n3" ml={-2} color="primary.800">
              {item?.label}
            </Text>
            <Flex
              gap="3"
              alignItems="center"
              direction={{ base: 'column', sm: 'row' }}
            >
              {item?.isAssigned ? (
                <Badge
                  variant="greenBadge"
                  background="olive.50"
                  color="olive.100"
                  borderColor="olive.75"
                  borderRadius="12"
                  py="0"
                  px="2.5"
                  h="1.25rem"
                  textTransform="none"
                >
                  {t('elementCheckboxList.assigned')}
                </Badge>
              ) : (
                <Text
                  variant="s1"
                  color="secondary.300"
                  minW={{ sm: '5rem' }}
                  textAlign="center"
                  whiteSpace="nowrap"
                >
                  ---
                </Text>
              )}
            </Flex>
          </Flex>
        }
      />
    </ListItem>
  );
};

export default ElementRow;
