import { Icon, MenuItem, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { userAddOutlined } from '@lon/shared/icons';
import { Drawer } from './components';

const AddStudents: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip
        variant="dark"
        label={t('addStudents.tooltip')}
        whiteSpace="pre-line"
      >
        <MenuItem gap={3} py={2} onClick={onOpen}>
          <Icon as={userAddOutlined} boxSize="1.25rem" />
          <Text as="span" variant="s2">
            {t('addStudents.title')}
          </Text>
        </MenuItem>
      </Tooltip>

      <Drawer isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AddStudents;
