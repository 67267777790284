import { App } from '@suit/app';
import { SharedPages, StudentPages } from '@suit/pages';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { NotFound } from '@lon/shared/components';
import { ApplicationEnum } from '@lon/shared/enums';
import { ErrorBoundary } from '@lon/shared/pages';
import { routes } from '@lon/suit/configs';

const ParentRouter = createRoutesFromElements(
  <Route
    path={routes.home}
    element={<App />}
    errorElement={<ErrorBoundary application={ApplicationEnum.PARENT_SUIT} />}
  >
    <Route path={routes.resources.root}>
      <Route index element={<SharedPages.Resources />} />
      <Route path={routes.resources.show} element={<SharedPages.Resource />} />
    </Route>
    <Route path={routes.grades.root}>
      <Route index element={<SharedPages.Grades />} />
      <Route path={routes.grades.class} element={<SharedPages.Grades />} />
      <Route
        path={routes.grades.assignment}
        element={<StudentPages.StudentAssignment />}
      />
      <Route
        path={routes.grades.districtAssignment}
        element={<StudentPages.DistrictAssignment />}
      />
      <Route
        path={routes.grades.otherAssignment}
        element={<StudentPages.StudentAssignment isOtherAssignment />}
      />
    </Route>
    <Route path="*" element={<NotFound withoutSidebar />} />
  </Route>
);

export default ParentRouter;
