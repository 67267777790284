import { Icon } from '../icon';
import { ConfirmModal } from '../modals';
import { useToast } from '../use-toast';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty, uniq } from 'lodash-es';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OTHER_ASSIGNMENTS_ID, sxLightScrollBar } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { ProfileTypeEnum } from '@lon/shared/enums';
import {
  useGetIsLeaderSuitFlag,
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useMediaQuery,
} from '@lon/shared/hooks';
import {
  ClassOtherAssignment,
  DaAssignType,
  ImmediatelyShareResults,
  useGetAllOwnerAssignmentsLazyQuery,
} from '@lon/shared/requests';
import { extractUuidFromIri } from '@lon/shared/utils';
import {
  AssignmentFormContext,
  types,
  useGoogleClassroom,
  utils,
} from './duck';
import {
  DESKTOP_TAB_PANEL_PADDING,
  NO_CLASSES_MESSAGE,
  NO_GRADES_MESSAGE,
  TABLET_TAB_PANEL_PADDING,
} from './duck';
import { GeneralInputData } from './duck/types';
import {
  TABS as ASSIGNMENT_TABS,
  DISTRICT_ASSESSMENT_TABS,
} from './tabs/manage-assignees/duck/constants';

const AssignmentFormDrawer: FC<types.AssignmentFormDrawerProps> = ({
  isOpen,
  onClose: onCloseDrawer,
  createAssignment,
  assignmentLoading,
  createResource,
  resourceLoading,
  assessmentData,
  elementsData,
  drawerHeader,
  backBtnText,
  saveBtnText,
  dontSaveBtnText,
  confirmHeader,
  confirmText,
  assessmentAssignerType,
  assessmentTitle = '',
  defaultFormValues,
  isReadOnlyMode = false,
  isEditAssignmentMode = false,
  isAssignmentWithoutLearnosity = false,
  isPlanElement = false,
  hasManuallyGradableQuestions = false,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const { currentSchoolId: schoolId } = useContext(WorkingLocation);
  const drawerRef = useRef<HTMLDivElement>(null);
  const [mainTabIndex, setMainTabIndex] = useState(0);
  const [manageTabIndex, setManageTabIndex] = useState(0);
  const mainAssignmentTypeTabIndex = isAssignmentWithoutLearnosity
    ? types.AssignmentType.Resource
    : types.AssignmentType.GradeableAssignment;
  const [assignmentTypeTabIndex, setAssignmentTypeTabIndex] = useState(
    mainAssignmentTypeTabIndex
  );
  const immediatelyShareResultsDefaultValue = !!(
    defaultFormValues &&
    defaultFormValues.immediatelyShareResults !==
      ImmediatelyShareResults.Disabled
  );
  const [immediatelyShareResults, setImmediatelyShareResults] = useState(
    immediatelyShareResultsDefaultValue
  );
  const isMultipleAssignments = Array.isArray(elementsData);
  const [isTablet] = useMediaQuery('(max-width: 1024px)');
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const TABS = utils.getTabs({
    isMultipleAssignments,
    isMobile,
  });
  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const accessibleSchoolIds = useGetSchoolIds();

  const params = useParams();

  const isTeacherAssessment =
    assessmentAssignerType === ProfileTypeEnum.TEACHER;
  const isDistrictAssessment = [
    ProfileTypeEnum.CAMPUS_LEADER,
    ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER,
    ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER,
  ].includes(assessmentAssignerType!);

  const isAssessment =
    !!assessmentData?.assessmentId ||
    isTeacherAssessment ||
    isDistrictAssessment;

  const isDistrictAssessmentForOneSchool =
    isDistrictAssessment &&
    accessibleSchoolIds &&
    accessibleSchoolIds.length === 1;

  const currentManageTabName = (
    isDistrictAssessment ? DISTRICT_ASSESSMENT_TABS : ASSIGNMENT_TABS
  )[manageTabIndex]?.key;

  const loading = assignmentLoading || resourceLoading;

  const isResourceTab =
    mainTabIndex === 0 &&
    assignmentTypeTabIndex === types.AssignmentType.Resource;
  const withOtherAssignment =
    assignmentTypeTabIndex !== types.AssignmentType.Resource &&
    !isEditAssignmentMode;

  const [
    getAllOwnerAssignments,
    { data: ownerAssignments, loading: loadingOwnerAssignments },
  ] = useGetAllOwnerAssignmentsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...(isLeaderSuit ? {} : { schoolId }),
    },
  });

  const assignedElementIds = uniq(
    ownerAssignments?.ownerAssignments?.assignments.map(
      (assignment) => assignment.assignmentSource?.element?.elementId
    )
  );

  const hasAssignedElement = useMemo(
    () =>
      isMultipleAssignments
        ? elementsData.some((o) => assignedElementIds?.includes(o.elementId))
        : false,
    [elementsData, ownerAssignments]
  );

  const { validationSchema, defaultValues } = useMemo(
    () =>
      utils.getValidation(
        currentManageTabName,
        assignmentTypeTabIndex,
        isMultipleAssignments,
        withOtherAssignment,
        isTeacherAssessment,
        isDistrictAssessment,
        isEditAssignmentMode,
        defaultFormValues
      ),
    [
      manageTabIndex,
      assignmentTypeTabIndex,
      isMultipleAssignments,
      isEditAssignmentMode,
      defaultFormValues,
    ]
  );

  const formProviderProps = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'onSubmit',
  });

  const isDirty =
    formProviderProps.formState.isDirty ||
    immediatelyShareResultsDefaultValue !== immediatelyShareResults;

  const noAddedClassesWithOtherAssignment =
    withOtherAssignment &&
    !formProviderProps.formState.dirtyFields[currentManageTabName]?.length;

  useEffect(() => {
    if (isOpen && !isAssessment && isMultipleAssignments) {
      getAllOwnerAssignments();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isEmpty(formProviderProps.formState.errors)) {
      const [firstErrorFieldName, { message: firstErrorFieldMessage }] =
        Object.entries(formProviderProps.formState.errors)[0];

      const fieldLocation = utils.getFieldLocation(
        firstErrorFieldName,
        isMultipleAssignments
      );

      if (
        firstErrorFieldMessage === NO_CLASSES_MESSAGE ||
        firstErrorFieldMessage === NO_GRADES_MESSAGE
      ) {
        toast({
          title: t('createAssignmentDrawer.noAssigneesToastTitle'),
          status: 'error',
          description:
            firstErrorFieldMessage === NO_CLASSES_MESSAGE
              ? t('createAssignmentDrawer.noClassesToastDescription')
              : t('createAssignmentDrawer.noGradeLevelsToastDescription'),
          variant: 'error-light',
          isClosable: true,
          duration: null,
        });
      }
      setMainTabIndex(fieldLocation.mainTab);
      setManageTabIndex(fieldLocation.manageTab || manageTabIndex);

      if (isConfirmOpen) {
        onConfirmClose();
      }
    }
  }, [formProviderProps.formState.errors]);

  const onClose = () => {
    setMainTabIndex(0);
    setManageTabIndex(0);
    setAssignmentTypeTabIndex(mainAssignmentTypeTabIndex);
    setImmediatelyShareResults(false);
    formProviderProps.reset(defaultValues);
    onCloseDrawer();
    onConfirmClose();
  };

  const { handleSelectToGoogleClassroom } = useGoogleClassroom();

  const submitForm = async (values: types.FormValues, isGoogle?: boolean) => {
    const vals = values[currentManageTabName] as types.Classes[];
    console.log(vals);
    const { classes = [], classOtherAssignments = [] } =
      vals?.reduce<{
        classes: Omit<types.Classes, 'classId'> & { id: string }[];
        classOtherAssignments: ClassOtherAssignment[];
      }>(
        (acc, val) => {
          const { classId, subgroupIds, studentIds, scopeId } = val;
          const isAppropriateClass = scopeId === 'appropriate-class';
          const isOtherAssignment = scopeId === OTHER_ASSIGNMENTS_ID;
          const isOtherAssignmentScope =
            scopeId && !isAppropriateClass && !isOtherAssignment;

          const classData = {
            id: extractUuidFromIri(classId || ''),
            ...(subgroupIds
              ? { subgroupIds: subgroupIds.filter(Boolean) }
              : {}),
            ...(studentIds ? { studentIds: studentIds.filter(Boolean) } : {}),
          };

          const classOtherAssignmentsData = {
            classId: extractUuidFromIri(classId || ''),
            otherAssignment: {
              ...(isOtherAssignment
                ? {
                    otherAssignment: true,
                  }
                : {}),
              ...(isOtherAssignmentScope
                ? {
                    otherAssignmentScope: scopeId as string,
                  }
                : {}),
            },
          };

          return {
            classes: [...acc.classes, classData],
            classOtherAssignments: [
              ...acc.classOtherAssignments,
              ...(withOtherAssignment && !isAppropriateClass
                ? [classOtherAssignmentsData]
                : []),
            ],
          };
        },
        {
          classes: [],
          classOtherAssignments: [],
        }
      ) || {};

    const schoolGradeLevels =
      values.schools?.length &&
      values.schools.every(
        (school) => school.schoolGradeLevels.filter(Boolean).length
      )
        ? values.schools.map((school) => ({
            schoolId: school.schoolId,
            gradeLevels: school.schoolGradeLevels.filter(Boolean),
          }))
        : accessibleSchoolIds?.map((schoolId) => ({
            schoolId,
            gradeLevels: values.gradeLevels?.filter(Boolean),
          }));

    try {
      if (assignmentTypeTabIndex === types.AssignmentType.GradeableAssignment) {
        const generalInputData = {
          startDate: values.startDate
            ? utils.formatDate(values.startDate)
            : null,
          endDate: values.endDate ? utils.formatDate(values.endDate) : null,
          ...(isDistrictAssessment
            ? {
                schoolGradeLevels,
              }
            : {
                learnosityReferenceId: '',
                classes,
                labels: [],
                notes: '',
                lateTurnIn: values.lateTurnIn || false,
                immediatelyShareResults: immediatelyShareResults
                  ? values.immediatelyShareResults
                  : ImmediatelyShareResults.Disabled,
                releaseFeedback: false,
              }),
          ...(isTeacherAssessment
            ? { randomizeQuestionsOrder: values.randomizeQuestionsOrder }
            : {}),
          ...(classOtherAssignments.length && !isAssessment
            ? { classOtherAssignments }
            : {}),
        } as GeneralInputData;
        await createAssignment?.({
          generalInputData,
          assignType:
            values.schools?.length &&
            values.schools.every(
              (school) => school.schoolGradeLevels.filter(Boolean).length
            )
              ? DaAssignType.School
              : DaAssignType.GradeLevEl,
          values: {
            ...values,
            elementsIds: values.elementsIds.filter(Boolean),
          },
          isGoogle,
        }).then((res) => {
          if (isGoogle && !isPlanElement) {
            // for assignee, we can add multiple classess, but if user select Google Classroom we can only add one class
            const firstClass = values.classes?.[0];
            const isOtherAssignment =
              firstClass?.scopeId === OTHER_ASSIGNMENTS_ID;
            const isAppropriateClass =
              firstClass?.scopeId === 'appropriate-class';
            const isOtherAssignmentScope =
              firstClass?.scopeId && !isAppropriateClass && !isOtherAssignment;

            const dividedClassId = firstClass?.classId.split('/');

            handleSelectToGoogleClassroom({
              assignmentId: res?.data?.putAssignment?.[0]?.assignmentId,
              classId: dividedClassId?.[dividedClassId.length - 1] || '',
              queryParams: isOtherAssignment
                ? 'isOtherAssignment=true'
                : isOtherAssignmentScope
                ? `otherAssignmentScope=${firstClass?.scopeId}`
                : `scopeSourceId=${params.scopeId}`,
            });
          }
        });
      } else {
        const generalInputData = {
          learnosityReferenceId: '',
          classes,
          labels: [],
          notes: '',
        };
        await createResource?.({
          generalInputData,
          values: {
            ...values,
            elementsIds: values.elementsIds.filter(Boolean),
          },
        });
      }
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = formProviderProps.handleSubmit((values) =>
    submitForm(values)
  );

  const closeForm = () => {
    if (isDirty) {
      onConfirmOpen();
    } else {
      onClose();
    }
  };

  const subgroups = formProviderProps.watch('subgroups') || [];
  const individualStudents =
    formProviderProps.watch('individualStudents') || [];
  const isGoogleDisabled = !!subgroups.length || !!individualStudents.length;

  return (
    <AssignmentFormContext.Provider
      value={{
        immediatelyShareResults,
        setImmediatelyShareResults,
        assessmentData,
        elementsData,
        withOtherAssignment,
        isAssessment,
        isTeacherAssessment,
        isDistrictAssessment,
        defaultValues,
        isReadOnlyMode,
        isEditAssignmentMode,
        hasManuallyGradableQuestions,
        isLeaderSuit,
      }}
    >
      <Drawer
        closeOnEsc={false}
        closeOnOverlayClick={false}
        variant="formDrawer"
        isOpen={isOpen}
        placement="right"
        onClose={closeForm}
        onEsc={closeForm}
        onOverlayClick={closeForm}
      >
        <DrawerOverlay />
        <FormProvider {...formProviderProps}>
          <Box
            as="form"
            className="emotion-css-cache userInputForm"
            noValidate
            onSubmit={onSubmit}
          >
            <DrawerContent ref={drawerRef} boxShadow="none" maxW="1000px">
              <DrawerHeader {...(isTablet && { p: TABLET_TAB_PANEL_PADDING })}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  width={`calc(100% - 48px)`}
                >
                  <Text {...(isTablet && { fontSize: '1.25rem' })}>
                    {drawerHeader
                      ? drawerHeader
                      : isMultipleAssignments
                      ? t('createAssignmentDrawer.multipleAssignmentsHeader')
                      : t('createAssignmentDrawer.header')}
                  </Text>
                  {utils.getFormLabel({
                    isEditAssignmentMode,
                    isReadOnlyMode,
                    isDirty,
                  })}
                </Flex>
                <DrawerCloseButton
                  right={
                    isTablet
                      ? TABLET_TAB_PANEL_PADDING
                      : DESKTOP_TAB_PANEL_PADDING
                  }
                  {...(isTablet && { top: TABLET_TAB_PANEL_PADDING })}
                />
              </DrawerHeader>

              <DrawerBody>
                <Tabs
                  display="flex"
                  flexDir="column"
                  height="full"
                  align="center"
                  variant="drawer"
                  index={mainTabIndex}
                  onChange={setMainTabIndex}
                >
                  {!isEditAssignmentMode && (
                    <Box boxShadow="0px 2px 7px rgba(43, 54, 70, 0.1)">
                      <TabList
                        width="auto"
                        maxW={isTablet ? '100%' : '700px'}
                        {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}
                      >
                        {TABS.map(({ tab, key, title }) => (
                          <Tab key={key} title={title} overflowX="hidden">
                            {tab}
                          </Tab>
                        ))}
                      </TabList>
                    </Box>
                  )}
                  <TabPanels
                    flex="1"
                    overflowY="scroll"
                    position="relative"
                    css={sxLightScrollBar}
                  >
                    {TABS.map(({ TabPanel, key }) => (
                      <TabPanel
                        key={key}
                        manageTabIndex={manageTabIndex}
                        setManageTabIndex={setManageTabIndex}
                        setMainTabIndex={setMainTabIndex}
                        assignmentTypeTabIndex={assignmentTypeTabIndex}
                        setAssignmentTypeTabIndex={setAssignmentTypeTabIndex}
                        ownerAssignments={ownerAssignments}
                        loadingOwnerAssignments={loadingOwnerAssignments}
                        assessmentTitle={assessmentTitle}
                        closeForm={closeForm}
                        isDistrictAssessment={isDistrictAssessment}
                        isDistrictAssessmentForOneSchool={
                          isDistrictAssessmentForOneSchool
                        }
                        isAssignmentWithoutLearnosity={
                          isAssignmentWithoutLearnosity
                        }
                      />
                    ))}
                  </TabPanels>
                </Tabs>
              </DrawerBody>
              <DrawerFooter {...(isTablet && { px: TABLET_TAB_PANEL_PADDING })}>
                <HStack justify="space-between" width="100%">
                  <Button
                    variant="outline"
                    mr={3}
                    onClick={closeForm}
                    leftIcon={<Icon name="arr-left-outlined" />}
                    {...(isMobile && {
                      px: '3',
                      sx: {
                        '.chakra-button__icon': {
                          mr: 0,
                        },
                      },
                    })}
                  >
                    {!isMobile && (
                      <Text as="span" isTruncated>
                        {backBtnText
                          ? backBtnText
                          : t('createAssignmentDrawer.backToElement')}
                      </Text>
                    )}
                  </Button>
                  <HStack>
                    {!isReadOnlyMode &&
                      assignmentTypeTabIndex ===
                        types.AssignmentType.GradeableAssignment && (
                        <Button
                          display="inline-flex"
                          variant="solid"
                          isDisabled={
                            isGoogleDisabled ||
                            (isResourceTab ? false : !isDirty)
                          }
                          isLoading={loading}
                          onClick={async () => {
                            const valid = await formProviderProps.trigger();
                            if (valid) {
                              submitForm(formProviderProps.getValues(), true);
                            }
                          }}
                        >
                          {t('createAssignmentDrawer.googleClassroom')}
                        </Button>
                      )}
                    {!isReadOnlyMode && (
                      <Tooltip
                        variant="dark"
                        aria-hidden={true}
                        isDisabled={!isDirty}
                        label={
                          isAssessment
                            ? t(
                                'createAssignmentDrawer.assignAssessmentBtnTooltip'
                              )
                            : t(
                                'createAssignmentDrawer.createAssignmentBtnTooltip'
                              )
                        }
                      >
                        <Button
                          isDisabled={isResourceTab ? false : !isDirty}
                          isLoading={loading}
                          variant="solid"
                          type="submit"
                          leftIcon={<Icon name="check-outlined" size="md" />}
                        >
                          <Text as="span" isTruncated>
                            {saveBtnText
                              ? saveBtnText
                              : t(
                                  hasAssignedElement
                                    ? 'createAssignmentDrawer.save'
                                    : 'createAssignmentDrawer.assign'
                                )}
                          </Text>
                        </Button>
                      </Tooltip>
                    )}
                  </HStack>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
            <ConfirmModal
              modalVariant="simpleWithPortal"
              drawerRef={drawerRef}
              isCentered={false}
              primaryHeading={
                confirmHeader
                  ? confirmHeader
                  : t('createAssignmentDrawer.confirmHeader')
              }
              subHeading={
                confirmText ? (
                  confirmText
                ) : (
                  <>
                    <Text variant="n3" mb="3">
                      {t('createAssignmentDrawer.confirmText')}
                    </Text>
                    {noAddedClassesWithOtherAssignment ? (
                      <Text variant="n3">
                        {t('createAssignmentDrawer.confirmAdditionalText')}
                      </Text>
                    ) : null}
                  </>
                )
              }
              dontSaveBtnText={
                dontSaveBtnText
                  ? dontSaveBtnText
                  : t('createAssignmentDrawer.dontAssignBtn')
              }
              submitButtonText={
                saveBtnText ? saveBtnText : t('createAssignmentDrawer.assign')
              }
              hasDontSaveBtn
              isModalOpen={isConfirmOpen}
              handleSave={onSubmit}
              handleDontSave={onClose}
              handleCancel={onConfirmClose}
              isLoading={loading}
              dontSaveBtnProps={{
                variant: 'solidDark',
                leftIcon: <Icon name="delete-outlined" size="lg" />,
              }}
              submitBtnProps={{
                isDisabled: noAddedClassesWithOtherAssignment,
                leftIcon: <Icon name="check-outlined" size="md" />,
              }}
            />
          </Box>
        </FormProvider>
      </Drawer>
    </AssignmentFormContext.Provider>
  );
};

export default AssignmentFormDrawer;
