import { intersection, isEmpty } from 'lodash-es';

class ContentFilterer {
  private readonly VIEW_TYPES: string[] = ['teacherView', 'studentView'];
  private content: Record<string, any>;
  private subscriptionContentGroups: string[];

  constructor(
    content: Record<string, any>,
    subscriptionContentGroups: string[]
  ) {
    this.content = content;
    this.subscriptionContentGroups = subscriptionContentGroups;
  }

  public getFilteredContent(): Record<string, any> {
    this.filterSideBar();
    this.filterSections();

    return this.content;
  }

  private filterSideBar(): void {
    const sideBarElements = this.content.home.sideBar;
    if (!sideBarElements || isEmpty(sideBarElements)) {
      return;
    }

    this.content.home.sideBar = sideBarElements.filter(
      (element: Record<string, any>) => {
        const elementContentGroups = this.fetchContentGroup(element);
        if (this.presentAndNoneMatch(elementContentGroups)) {
          return false;
        }

        this.filterElementView(element, elementContentGroups);
        if (!elementContentGroups?.length) {
          this.filterElementFiles(element);
        }
        return true;
      }
    );
  }

  private filterSections(): void {
    this.content.sections = this.content.sections.filter((section: any) => {
      const sectionContentGroups = this.fetchContentGroup(section);
      if (this.presentAndNoneMatch(sectionContentGroups)) {
        return false;
      }

      section.elements = section?.elements?.filter((element: any) => {
        const elementContentGroups = this.fetchContentGroup(element);
        if (
          isEmpty(sectionContentGroups) &&
          this.presentAndNoneMatch(elementContentGroups)
        ) {
          return false;
        }

        this.filterElementView(
          element,
          elementContentGroups,
          sectionContentGroups
        );

        if (isEmpty(sectionContentGroups) && isEmpty(elementContentGroups)) {
          this.filterElementFiles(element);
        }

        return true;
      });

      return true;
    });
  }

  private fetchContentGroup(object: any): string[] {
    return object?.metadata?.contentGroup;
  }

  private presentAndNoneMatch(objectContentGroups: string[] = []): boolean {
    return (
      !isEmpty(objectContentGroups) &&
      !isEmpty(this.subscriptionContentGroups) &&
      isEmpty(intersection(objectContentGroups, this.subscriptionContentGroups))
    );
  }

  private filterElementView(
    element: Record<string, any>,
    elementContentGroups: string[],
    sectionContentGroups?: string[]
  ): void {
    this.VIEW_TYPES.forEach((viewType) => {
      const viewContentPages = Array.isArray(element[viewType])
        ? element[viewType]
        : [element[viewType]];

      const contentPage = viewContentPages.find((view: Record<string, any>) => {
        if (sectionContentGroups?.length || elementContentGroups?.length) {
          return true;
        }

        const viewContentGroups = this.fetchContentGroup(view);
        return !this.presentAndNoneMatch(viewContentGroups);
      });

      const contentPages = viewContentPages.filter(
        (view: Record<string, any>) => {
          if (sectionContentGroups?.length || elementContentGroups?.length) {
            return true;
          }

          const viewContentGroups = this.fetchContentGroup(view);
          return !this.presentAndNoneMatch(viewContentGroups);
        }
      );

      element[viewType] =
        (viewType === 'studentView' ? contentPages : contentPage) || {};
    });
  }

  private filterElementFiles = (element: Record<string, any>) => {
    element.elementFiles = element.elementFiles.filter((elementFile: any) => {
      const fileContentGroups = this.fetchContentGroup(elementFile);
      return !this.presentAndNoneMatch(fileContentGroups);
    });
  };
}

export default ContentFilterer;
