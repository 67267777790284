import React from 'react';
import { ContentLoader } from '@lon/shared/components';

export const ScopeSkeleton: React.FC = () => (
  <div data-testid="card-skeleton">
    <ContentLoader viewBox="0 0 250 208">
      <rect x="0" y="0" width="250" height="220" />
      <rect x="0" y="260" width="250" height="220" />
    </ContentLoader>
  </div>
);
