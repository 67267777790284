import { Root, useFetchJsonData } from '../../duck';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { arrowRight } from '@lon/shared/assets';
import { Link, PageContent, Spinner } from '@lon/shared/components';
import {
  useAuth,
  useGetIsLeaderSuitFlag,
  useQueryParams,
} from '@lon/shared/hooks';
import { Audience, useGetHelpPageQuery } from '@lon/shared/requests';
import { BackButton } from '@lon/suit/components';
import { routes } from '@lon/suit/configs';
import { ContentMenu, Print, Speech } from './components';

interface NestedItem {
  identifier: string;
  title: string;
  href?: string;
  items?: NestedItem[];
}

interface RootItem {
  identifier: string;
  title?: string;
  items?: NestedItem[];
  href?: string;
}

interface BreadcrumbType {
  href?: string;
  title: string;
  identifier: string;
}

const findItem = (data: RootItem[] | NestedItem[], id: string): any => {
  for (const item of data) {
    if (item.identifier === id && 'href' in item && item.href) {
      return item;
    }

    if (item.items) {
      const foundItem = findItem(item.items, id);

      if (foundItem) {
        return foundItem;
      }
    }
  }
};

function findFolderPath(
  rootItems: RootItem[] | NestedItem[],
  targetIdentifier: string,
  isFirstLevel = true,
  breadcrumbs: BreadcrumbType[] = []
): BreadcrumbType[] | null {
  for (const rootItem of rootItems) {
    if (!isFirstLevel && rootItem.identifier === targetIdentifier) {
      return breadcrumbs.concat({
        title: rootItem.title as string,
        identifier: rootItem.identifier,
        href: rootItem.href,
      });
    }

    if (rootItem.items && rootItem.items.length > 0) {
      const nestedPath: BreadcrumbType[] | null = findFolderPath(
        rootItem.items,
        targetIdentifier,
        false,
        isFirstLevel
          ? breadcrumbs
          : breadcrumbs.concat({
              title: rootItem.title as string,
              identifier: rootItem.identifier,
              href: rootItem.href,
            })
      );
      if (nestedPath) {
        return nestedPath;
      }
    }
  }

  return null;
}

const HelpPageDetails = () => {
  const iframeRef = React.useRef<HTMLIFrameElement>();
  const { t } = useTranslation();
  const [params] = useQueryParams();
  const [{ profileType }] = useAuth();
  const { id, articleId } = useParams();
  const { tts } = params;

  const isLeaderSuit = useGetIsLeaderSuitFlag();

  const { data, loading: loadingHelpPage } = useGetHelpPageQuery({
    skip: !profileType,
    variables: {
      input: {
        audience: isLeaderSuit
          ? Audience.Leader
          : (profileType as string)?.toLocaleLowerCase() ===
            (Audience.Teacher as string).toLocaleLowerCase()
          ? Audience.Teacher
          : Audience.Student,
      },
    },
  });

  const { data: jsonData, loading: loadingHelpPageData } = useFetchJsonData({
    url: (window.location.origin + data?.helpPage.link) as string,
    skip: !data?.helpPage.link,
  });

  const jsonDataTyped = jsonData as unknown as Root;

  const breadcrumbs = useMemo(() => {
    if (jsonDataTyped?.contentStructure?.length && id) {
      const targetIdentifier = id;
      const path = findFolderPath(
        jsonDataTyped.contentStructure,
        targetIdentifier
      );

      if (path) {
        return path;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [id, jsonDataTyped]);

  const currentPageConfig = React.useMemo(() => {
    if (!jsonData?.contentStructure) {
      return {
        url: '',
        title: '',
      };
    }

    const item = findItem(jsonDataTyped.contentStructure || [], id as string);
    const contentPath = jsonDataTyped.contentLocation.replace(
      /s3:\/\/.*?\//,
      ''
    );

    return {
      title: item?.title,
      url: `${window.origin}/content/${contentPath}/${item?.href}`,
    };
  }, [jsonData?.contentStructure, tts]);

  useEffect(() => {
    if (currentPageConfig.url && iframeRef.current) {
      iframeRef.current.src = currentPageConfig.url;
    }
  }, [tts, currentPageConfig]);

  const printFile = () => {
    window.open(
      `${currentPageConfig.url}?mode=print&printType=manual`,
      '_blank'
    );
  };

  return (
    <PageContent
      toolbar={
        <Flex
          justifyContent={{
            base: 'space-between',
            md: 'flex-end',
          }}
          backgroundColor="white"
          p={2}
          flexDirection="row"
          gap={2}
          boxShadow="pane"
        >
          <Speech />
          <Print printMethod={printFile} />
          <ContentMenu iframeRef={iframeRef} />
        </Flex>
      }
      withoutSidebar
      pageTitle={currentPageConfig.title}
      headerElements={
        <BackButton
          label={t('helpPage.goToHelpCenter')}
          tooltipLabel={t('helpPage.goToHelpCenterTooltip')}
          to={
            (profileType as string)?.toLocaleLowerCase() ===
              (Audience.Teacher as string).toLocaleLowerCase() || isLeaderSuit
              ? generatePath(routes.additionalResources.help.root, {
                  articleId,
                })
              : generatePath(routes.student.help.root, {
                  articleId,
                })
          }
        />
      }
      wrapperProps={{
        padding: '0px important',
      }}
    >
      <Flex
        width="full"
        justifyContent="center"
        alignItems="center"
        height="100%"
        backgroundColor="white"
      >
        {loadingHelpPage || loadingHelpPageData ? (
          <Spinner variant="dark" />
        ) : (
          <Flex flexDirection="column" gap={6} height="100%" width="full">
            <Flex
              backgroundColor="white"
              flexDirection="row"
              gap={2}
              px={8}
              py={4}
              borderRadius={6}
              boxShadow="pane"
            >
              <Breadcrumb
                spacing="2"
                separator={
                  <Icon
                    as={arrowRight}
                    boxSize="1.25rem"
                    verticalAlign="middle"
                    color="primary.800"
                  />
                }
                css={{
                  list: {
                    flexWrap: 'wrap',
                  },
                }}
              >
                {breadcrumbs.map((bc) => {
                  return (
                    <BreadcrumbItem my={1}>
                      <BreadcrumbLink
                        withoutStyles
                        w="fit-content"
                        color="primary.800"
                        textDecoration={
                          bc.identifier === id ? 'none' : 'underline'
                        }
                        disabled={bc.identifier === id}
                        _hover={{ textDecoration: 'none' }}
                        as={Link}
                        to={'/more/help/' + bc.identifier}
                        display="flex"
                        alignItems="center"
                      >
                        <Text variant="s2">{bc.title}</Text>
                      </BreadcrumbLink>
                    </BreadcrumbItem>
                  );
                })}
              </Breadcrumb>
            </Flex>

            <Flex
              backgroundColor="white"
              height="100%"
              borderRadius={6}
              boxShadow="pane"
            >
              <iframe
                src={currentPageConfig.url}
                width="100%"
                height="100%"
                ref={iframeRef as React.RefObject<HTMLIFrameElement>}
                data-id="content-iframe"
                title="Aticle"
                onLoad={() => {
                  if (iframeRef?.current) {
                    iframeRef?.current?.contentWindow?.postMessage(
                      {
                        type: 'applyStyles',
                      },
                      '*'
                    );
                  }
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </PageContent>
  );
};

export default HelpPageDetails;
