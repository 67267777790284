import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import React from 'react';
import { Icon } from '../icon';
import { userType } from './duck/types';

interface CheckboxDropdownProps {
  users: userType[];
  setUsers: (user: userType[]) => void;
}

const CheckboxDropdown: React.FC<CheckboxDropdownProps> = ({
  users,
  setUsers,
}) => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} colorScheme="blue" w="fit-content">
        <Icon name="users" width="24px" height="24px" />
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type="checkbox"
          value={users.map((user) => (user.isChecked ? user.id : ''))}
        >
          {users.map((user: userType) => (
            <MenuItemOption
              key={user.id}
              onClick={() => {
                const updateUsers = users.map((prevUser) => {
                  if (prevUser.id === user.id) {
                    return { ...prevUser, isChecked: !prevUser.isChecked };
                  }
                  return prevUser;
                });
                setUsers(updateUsers);
              }}
              value={user.id}
            >
              {user.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default CheckboxDropdown;
