import { MAX_NUMBER_OF_SCOPES_PER_QUERY } from '@lon/shared/constants';

export const DEFAULT_ITEMS_COUNT = 24;

export const paginationConfig = {
  rowOptions: [
    { value: 12, label: '12' },
    { value: 24, label: '24' },
    { value: 36, label: '36' },
    { value: 48, label: '48' },
  ],
  defaultPage: 1,
  itemsPerPageLimits: {
    12: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 12),
    24: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 24),
    36: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 36),
    48: Math.ceil(MAX_NUMBER_OF_SCOPES_PER_QUERY / 48),
  },
};
