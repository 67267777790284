import clsx from 'clsx';
import React from 'react';
import { types } from './duck';
import styles from './Content.module.css';

const Content = React.forwardRef<never, types.ContentProps>(
  (
    { className, containerClassName, stretch = false, children, ...rest },
    ref
  ) => {
    return (
      <div
        {...rest}
        ref={ref}
        className={clsx(
          styles.wrapper,
          { [styles.stretch]: stretch },
          className
        )}
      >
        {stretch ? (
          <div className={clsx(styles.container, containerClassName)}>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    );
  }
);
export default Content;
