import { saveAs } from 'file-saver';
import { Json2CsvOptions, json2csv } from 'json-2-csv';
import { startCase } from 'lodash-es';

type ConvertToCsvAndDownload = (data: any, options?: Json2CsvOptions) => void;

const DEFAULT_EXCLUDE_KEYS = ['__typename', '_id', 'id'];

const downloadAsCSV = (_: any, csv?: string) => {
  const blob = new Blob([csv || ''], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, 'data.csv');
};
const convertToCsvAndDownload: ConvertToCsvAndDownload = (data, options) => {
  json2csv(data, downloadAsCSV, {
    emptyFieldValue: 'null',
    keys: Object.keys(data[0] || []).map((key) => ({
      field: key,
      title: startCase(key),
    })),
    excludeKeys: DEFAULT_EXCLUDE_KEYS,
    ...options,
  });
};

export default convertToCsvAndDownload;
