import { isDevMode } from '@lon/shared/constants';

const injectLanguageSwitcher = () => {
  const imageTag = process.env?.['NX_IMAGE_TAG'] || 1;

  const scriptSrc = isDevMode()
    ? `public/language-switcher.js?v=${imageTag}`
    : `/suite/language-switcher.js?v=${imageTag}`;

  if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
    const scriptElement = document.createElement('script');

    scriptElement.src = scriptSrc;
    scriptElement.type = 'module';
    scriptElement.defer = true;

    scriptElement.onload = () => {
      const template = `
      <language-switcher default="en" class="notranslate" id="language-switcher">
        <option value="en" data-value="en">English</option>
        <option value="es" data-value="es">Español</option>
      </language-switcher>
    `;

      const langSwitcher = document.getElementById('language-switcher');
      if (!langSwitcher) {
        document.body.insertAdjacentHTML('afterbegin', template);
      }
    };

    document.body.appendChild(scriptElement);
  }
};

export default injectLanguageSwitcher;
